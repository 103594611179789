import { z } from 'zod';

export const JourneyEdgeSchema = z.object({
  id: z.number().int(),
  source_node_id: z.number().int(),
  target_node_id: z.number().int(),
  index: z.number().int(),
});

export type JourneyEdgeParsed = z.output<typeof JourneyEdgeSchema>;
