import { useEffect } from 'react';

import type { useFieldResult } from '../useField';

import {
  NAME_TAG_REGEX,
  URL_TAG_REGEX,
} from 'components/LineMessageEditor/components/RichTextEditor/Decorators/strategy';

const simulatedURLString = '#'.repeat(28); // 30字扣掉前後空白
const simulatedNameString = '#'.repeat(18); // 20字扣掉前後空白

export const useRichTextCheck = (
  validate: useFieldResult['validate'],
  value: useFieldResult['value'],
): void => {
  useEffect(() => {
    const replaced =
      value &&
      value.replace(URL_TAG_REGEX, simulatedURLString).replace(NAME_TAG_REGEX, simulatedNameString);
    validate(replaced);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
};
