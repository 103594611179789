export const getTextWidthLength = (s: string): number => {
  let len = 0;
  for (let i = 0; i < s.length; i++) {
    const code = s.charCodeAt(i);
    if (code <= 0x7f) {
      len += 1;
    } else if (code <= 0x7ff) {
      len += 2;
    } else if (code >= 0xd800 && code <= 0xdfff) {
      // Surrogate pair: These take 4 bytes in UTF-8 and 2 chars in UCS-2
      // (Assume next char is the other [valid] half and just skip it)
      len += 2;
    } else if (code < 0xffff) {
      len += 2;
    } else {
      len += 2;
    }
  }
  return len;
};

export const fromUtf16toUnixText = (t: string): string => {
  return t.replace(/\\\\u([0-9a-fA-F]{4})/g, (_, x) => {
    const w = parseInt(x, 16);
    return String.fromCharCode(w);
  });
};
