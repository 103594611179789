import { forwardRef, memo } from 'react';

import type { ComponentPropsWithRef, ReactNode } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { MotifIcon } from 'icons/motif';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { overrideCss } from 'shared/utils/styled/override';

type ExternalLinkProps = ComponentPropsWithRef<'a'> &
  OverrideComponentCssProps & {
    href: string;
    trailingIcon?: ReactNode | true;
  };

/**
 * This component is under TBD for overflow handling.
 */
export const ExternalLink = styled(
  memo(
    forwardRef<HTMLAnchorElement, ExternalLinkProps>(function ExternalLink(
      { children, trailingIcon, ...props },
      ref,
    ) {
      return (
        <a target="_blank" rel="noopener noreferrer" {...props} ref={ref}>
          <span>{children}</span>
          {trailingIcon === true ? (
            <MotifIcon un-i-motif="new_window" style={{ flex: '0 0 1em' }} />
          ) : (
            trailingIcon
          )}
        </a>
      );
    }),
  ),
).withConfig({ shouldForwardProp: shouldNotForwardProps(['styledCss']) })`
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: 0.5em;
  ${overrideCss}
`;
