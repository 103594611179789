import type { TextDataType } from './templateDataAndTypes/richtext';
import type {
  State,
  TextDataParameterCustom,
  TextDataParameterUrl,
} from './templateDataAndTypes/types';

import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';

import {
  createTextFormat,
  textData,
  textDataParameterCustomData,
  textDataParameterNameData,
  textDataParameterReferralActivityLink,
  textDataParameterReferralInvitationLinkV2,
  textDataParameterReferralInviteeV2,
  textDataParameterReferralInviterV2,
  textDataParameterUrlData,
} from './templateDataAndTypes/richtext';
import { dispatch } from './models';

export const richTextStore = {
  reducers: {
    addNameAndURLTag(
      preState: State,
      payload: { rowIndex: number; type: DecoratorType; urlKey: string },
    ): void {
      if (payload.type === DecoratorType.Name) {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterNameData },
        ];
      } else if (payload.type === DecoratorType.Url) {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterUrlData, key: payload.urlKey },
        ];
      } else {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterCustomData, mappingKey: payload.urlKey },
        ];
      }
      (preState.editorData[payload.rowIndex] as TextDataType).format.tagId++;
    },
    addReferralTag(preState: State, payload: { rowIndex: number; type: DecoratorType }): void {
      if (payload.type === DecoratorType.ReferralActivityLink) {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterReferralActivityLink },
        ];
      } else if (payload.type === DecoratorType.ReferralInvitationLink) {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterReferralInvitationLinkV2 },
        ];
      } else if (payload.type === DecoratorType.ReferralInviter) {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterReferralInviterV2 },
        ];
      } else if (payload.type === DecoratorType.ReferralInvitee) {
        (preState.editorData[payload.rowIndex] as TextDataType).parameters = [
          ...(preState.editorData[payload.rowIndex] as TextDataType).parameters,
          { ...textDataParameterReferralInviteeV2 },
        ];
      }
      (preState.editorData[payload.rowIndex] as TextDataType).format.tagId++;
    },
    setTextEditingUrlParametersDetail(
      preState: State,
      payload: { urlKey: string; data: Pick<TextDataParameterUrl, 'data'> },
    ): void {
      const rowIndex = (
        preState.editorData[preState.editorEditingRow] as TextDataType
      ).parameters.findIndex((d) => {
        return d.key === payload.urlKey;
      });
      (preState.editorData[preState.editorEditingRow] as TextDataType).parameters[rowIndex].data = {
        ...(preState.editorData[preState.editorEditingRow] as TextDataType).parameters[rowIndex]
          .data,
        ...payload.data,
      };
    },
    setTextEditingCustomParametersDetail(
      preState: State,
      payload: { customKey: string; data: string },
    ): void {
      const rowIndex = (
        preState.editorData[preState.editorEditingRow] as TextDataType
      ).parameters.findIndex((d) => {
        return (d as TextDataParameterCustom).mappingKey === payload.customKey;
      });
      (
        (preState.editorData[preState.editorEditingRow] as TextDataType).parameters[
          rowIndex
        ] as TextDataParameterCustom
      ).key = payload.data;
    },
    setTextEditingUrlPopupShow(
      preState: State,
      payload: { urlKey: string; isShow: boolean },
    ): void {
      const rowIndex = (
        preState.editorData[preState.editorEditingRow] as TextDataType
      ).parameters.findIndex((d) => {
        return d.key === payload.urlKey;
      });
      (
        (preState.editorData[preState.editorEditingRow] as TextDataType).parameters[
          rowIndex
        ] as TextDataParameterUrl
      ).isShowPopup = payload.isShow;
    },
    setTextEditingUrlTag(preState: State, payload: { urlKey: string; tagList: string[] }): void {
      const rowIndex = (
        preState.editorData[preState.editorEditingRow] as TextDataType
      ).parameters.findIndex((d) => {
        return d.key === payload.urlKey;
      });
      (
        (preState.editorData[preState.editorEditingRow] as TextDataType).parameters[
          rowIndex
        ] as TextDataParameterUrl
      ).data.tag_list = payload.tagList;
    },
    addEditorDataRichTextCase(preState: State, payload: { data: TextDataType }): void {
      preState.editorData = [...preState.editorData, payload.data];
      preState.richEditorId = preState.richEditorId + 1;
    },
    removeParametersByMappingKey(preState: State, payload: { mappingKey: string }): void {
      (preState.editorData[preState.editorEditingRow] as TextDataType).parameters = (
        preState.editorData[preState.editorEditingRow] as TextDataType
      ).parameters.filter((d) => (d as TextDataParameterCustom).mappingKey !== payload.mappingKey);
    },
  },
  effects: {
    async addTextModule(payload: { richEditorId: number }): Promise<void> {
      dispatch('addEditorDataRichTextCase', {
        data: {
          ...textData,
          format: createTextFormat({ richEditorId: payload.richEditorId }),
          parameters: [],
        },
      });
    },
  },
};
