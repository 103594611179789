import type { Area } from '@line/bot-sdk';
import type {
  ImageMapActionMessageType,
  ImageMapTriggerActionType,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import {
  createImageMapActionMessage,
  createImageMapTriggerAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMap';

export function createImageMapActions(
  baseSize: {
    width: number;
    height: number;
  },
  count: number,
  tagId: number,
): { result: ImageMapActionMessageType[]; triggers: ImageMapTriggerActionType[]; tagId: number } {
  const heightWidthRate = baseSize.height / baseSize.width;
  const fullArea: Area = {
    x: 0,
    y: 0,
    width: 1040,
    height: 1040 * heightWidthRate,
  };

  const result: ImageMapActionMessageType[] = [];
  const triggers: ImageMapTriggerActionType[] = [];
  let currentTagId = tagId;
  for (let i = 0; i < count; i++) {
    const key = 'message' + currentTagId;
    if (count === 1) {
      result.push(createImageMapActionMessage({ area: fullArea, key }));
    }
    if (count === 4) {
      const width = fullArea.width / 2;
      result.push(
        createImageMapActionMessage({
          area: {
            x: (i + 1) % 2 === 0 ? width : 0,
            y: i + 1 < 3 ? 0 : width * heightWidthRate,
            width,
            height: width * heightWidthRate,
          },
          key,
        }),
      );
    }
    if (count === 6) {
      const width = fullArea.width / 3;
      const height = fullArea.height / 2;
      result.push(
        createImageMapActionMessage({
          key,
          area: {
            x: (i + 1) % 3 === 0 ? width * 2 : (((i + 1) % 3) - 1) * width,
            y: i + 1 < 4 ? 0 : height,
            width,
            height,
          },
        }),
      );
    }

    if (count === 8) {
      const width = fullArea.width / 2;
      const height = fullArea.height / 4;
      result.push(
        createImageMapActionMessage({
          key,
          area: {
            x: (i + 1) % 2 === 0 ? width : 0,
            y: (Math.floor((i + 2) / 2) - 1) * height,
            width,
            height,
          },
        }),
      );
    }

    triggers.push(createImageMapTriggerAction({ key }));
    currentTagId++;
  }
  return { result, triggers, tagId: currentTagId };
}
