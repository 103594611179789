import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  PARAM_PLACEHOLDER,
  PARAM_RICH_TEXT_FIELD_NAME_INDEX,
} from 'components/LineMessageEditor/constants';

import type { ParamRichTextFieldName } from 'components/LineMessageEditor/constants';
import type {
  CardDataType,
  CardEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type {
  CarrouselData,
  EditorParameter,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { Text } from 'components';
import { Box } from 'components/layoutUtils';
import {
  isFlexButtonActionMessageRule,
  isFlexButtonActionUriRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import {
  isCustomParameter,
  isLinkParameter,
  isShareButtonParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import {
  findEditorStateObjectById,
  stringifyEditorStateObject,
} from 'components/LineMessageEditor/utils/editorState';
import { isMappingKey } from 'components/LineMessageEditor/utils/transformKey';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

import { parseParamRichText } from './RichTextPreviewUtils';

export interface CardPreviewProps {
  cardData: CardDataType;
  parameters: CardEditorData['parameters'] | CarrouselData['parameters'];
  editorStateArray: EditorStateObject[];
  onAddUserMessageText: (text: string) => void;
  src?: string;
  carrouselIndex?: number;
}

const Img = styled.img`
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const BottomWrapper = styled.div`
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin: auto;
  max-width: 90%;
  text-align: center;
`;

export function CardPreview(props: CardPreviewProps) {
  const { t } = useTranslation();
  const paramName = useMemo(() => {
    return (
      props.parameters.find((p) =>
        isMappingKey('customHero', p.mappingKey, props.cardData.contents.hero?.key),
      )?.key ?? ''
    );
  }, [props.parameters, props.cardData.contents.hero]);
  const toDisplayedText = useCallback(
    (fieldName: ParamRichTextFieldName) => {
      const fieldIndex = PARAM_RICH_TEXT_FIELD_NAME_INDEX[fieldName];
      return parseParamRichText(
        stringifyEditorStateObject(
          findEditorStateObjectById(
            props.cardData?.format?.[fieldIndex]?.richEditorId ?? -1,
            props?.editorStateArray ?? [],
          )?.editorState,
        ),
        props.parameters,
      );
    },
    [props.cardData?.format, props?.editorStateArray, props.parameters],
  );

  return (
    <Box
      data-test="card-preview"
      width={223}
      m="20px 0 8px 12px"
      borderRadius={16}
      backgroundColor={theme.colors.white001}
    >
      {props.src && props.src !== PARAM_PLACEHOLDER.image ? (
        <Img src={props.src} data-test="card-preview-local-upload-image" />
      ) : (
        <Tooltip
          trigger="hover"
          title={
            <TooltipDescription>
              {t('message.paramImageEditor.imagePreview.tooltip', { paramName })}
            </TooltipDescription>
          }
        >
          <Img src={props.src} />
        </Tooltip>
      )}
      <Box bg={theme.colors.white001} borderRadius={!!props.src ? '0 0 16px 16px' : '16px'}>
        <Box p="10px 26px 20px 20px">
          <Text as="div" fontSize={13} pb="6px" fontWeight={500} style={{ wordWrap: 'break-word' }}>
            {toDisplayedText('CardTitle')}
          </Text>
          <Text
            as="div"
            fontSize={13}
            color={theme.colors.neutral007}
            style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
          >
            {toDisplayedText('CardDescription')}
          </Text>
        </Box>
        <BottomWrapper>
          {props.cardData.contents.footer.contents.map((button, index) => {
            let text = '';
            let actionType = '';
            let clickCallback;
            if (isFlexButtonActionMessageRule(button.action)) {
              text = button.action.text;
              actionType = t('message.send');
              clickCallback = () => {
                if (text) props.onAddUserMessageText(text);
              };
            } else if (isFlexButtonActionUriRule(button.action)) {
              const parameter = props.parameters.find((p) => {
                return isLinkParameter(p) || isShareButtonParameter(p)
                  ? p.key === button.key
                  : isCustomParameter(p)
                    ? p.mappingKey === button.key
                    : false;
              }) as EditorParameter;
              if (parameter && isLinkParameter(parameter)) {
                text = parameter.data.url ?? '';
                actionType = t('common.openUrl');
                clickCallback = () => {
                  window.open(text);
                };
              } else if (parameter && isCustomParameter(parameter)) {
                text = parameter.key ?? '';
                actionType = t('message.apiUrl');
              } else if (parameter && isShareButtonParameter(parameter)) {
                text = parameter.data.name ?? '';
                actionType = t('message.shareButton.name');
              }
            }

            return (
              <Tooltip
                key={'card-actions' + index}
                trigger="hover"
                title={<TooltipDescription>{actionType + ': ' + text}</TooltipDescription>}
              >
                <Text
                  as="div"
                  fontSize={13}
                  color={theme.colors.blue006}
                  pb={12}
                  onClick={clickCallback}
                  style={{ cursor: 'pointer' }}
                >
                  {button.action.label}
                </Text>
              </Tooltip>
            );
          })}
        </BottomWrapper>
      </Box>
    </Box>
  );
}
