/* eslint-disable react-refresh/only-export-components -- Disabled for context files */
import createUseContext from 'constate';
import { useState } from 'react';

import type { ValidatorState } from '../context';
import type { ReactNode } from 'react';

function useFieldScope(): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (id: string, rules: any[], value: string) => void;
  unregister: (id: string) => void;
  fields: string[];
} {
  const [fields, setFields] = useState<string[]>([]);
  const register: ValidatorState['register'] = (id) => {
    setFields([...fields, id]);
  };
  const unregister: ValidatorState['unregister'] = (id) => {
    setFields(fields.filter((f) => f === id));
  };

  return { register, unregister, fields };
}
// eslint-disable-next-line react-refresh/only-export-components
export const [FieldScopeContextProvider, useFieldScopeContext] = createUseContext(useFieldScope);

export const FieldScopeProvider = ({ children }: { children?: ReactNode }) => {
  return <FieldScopeContextProvider>{children}</FieldScopeContextProvider>;
};
