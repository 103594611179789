import type { TryPropType } from 'utils/types';

import { isNullable } from './isNullable';

/**
 * Check if the given object has the given property.
 *
 * @param v - The object to check.
 * @param key - The property to check.
 * @returns Return true if the given object has the given property.
 */
export function hasOwnProperty<V, K extends string>(
  v: V,
  key: K,
): v is V & Record<K, TryPropType<V, K>> {
  return (
    /**
     * Check nullable first because null or undefined throw error with hasOwnProperty() .
     */
    !isNullable(v) && Object.prototype.hasOwnProperty.call(v, key)
  );
}
