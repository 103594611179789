export const InviterSvg = () => {
  return (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.723 9.13c.396-.212.85-.333 1.332-.333h.002c.05 0 .072-.06.036-.092a6.084 6.084 0 00-1.697-1.077l-.02-.008a4.06 4.06 0 001.678-3.292A4.055 4.055 0 007.002.268a4.054 4.054 0 00-4.05 4.06c0 1.354.662 2.553 1.68 3.292l-.02.008a6.1 6.1 0 00-1.952 1.32 6.113 6.113 0 00-1.316 1.955 6.115 6.115 0 00-.482 2.26.131.131 0 00.131.135h.98c.071 0 .13-.057.132-.128a4.884 4.884 0 011.434-3.344 4.86 4.86 0 013.465-1.439c.928 0 1.819.257 2.586.739a.133.133 0 00.133.005zM7.004 7.144c-.75 0-1.455-.293-1.987-.825a2.802 2.802 0 01-.824-1.99c0-.751.293-1.459.824-1.99a2.787 2.787 0 011.987-.826 2.79 2.79 0 011.987.825 2.802 2.802 0 01.824 1.99c0 .752-.293 1.46-.824 1.991a2.793 2.793 0 01-1.987.825zm6.02 3.896H11.65V9.664a.131.131 0 00-.131-.13h-.917a.131.131 0 00-.131.13v1.375H9.096a.131.131 0 00-.131.131v.917c0 .072.059.13.131.13h1.375v1.376c0 .072.059.13.13.13h.918a.131.131 0 00.13-.13v-1.375h1.376c.072 0 .13-.06.13-.131v-.917a.131.131 0 00-.13-.13z"
        fill="#fff"
      />
    </svg>
  );
};
