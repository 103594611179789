import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PARAM_PLACEHOLDER } from 'components/LineMessageEditor/constants';

import type { CustomParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { isMappingKey } from 'components/LineMessageEditor/utils/transformKey';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';

interface ImagePreviewProps {
  src: string;
  parameters?: CustomParameter[];
}

interface LineImageProps {
  src: string;
}

const LineImage = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['src']),
})<LineImageProps>`
  padding-bottom: 70%;
  max-width: 70%;
  margin-top: 20px;
  margin-left: 10px;
  border-radius: 6px;
  background-color: white;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ImagePreview = memo(function ImagePreview({
  src,
  parameters = [],
}: ImagePreviewProps) {
  const { t } = useTranslation();
  const paramName = useMemo(
    () => parameters.find((p) => isMappingKey('customImage', p.mappingKey))?.key ?? '',
    [parameters],
  );
  return src !== PARAM_PLACEHOLDER.image ? (
    <LineImage src={src} data-test="image-preview-local-upload-image" />
  ) : (
    <Tooltip
      trigger="hover"
      title={
        <TooltipDescription>
          {t('message.paramImageEditor.imagePreview.tooltip', { paramName })}
        </TooltipDescription>
      }
    >
      <LineImage src={src} />
    </Tooltip>
  );
});
