import type { AbstractEditorData, Parameter } from './types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';

export const DEFAULT_AREA_SIZE = 1040;

export const video = {
  originalContentUrl: '',
  previewImageUrl: '',
  area: {
    x: 0,
    y: 0,
    width: DEFAULT_AREA_SIZE,
    height: DEFAULT_AREA_SIZE,
  },
  expired: false,
  externalLink: {
    linkUri: '<$var:0-uri1>',
    label: '',
  },
  metadata: {
    width: 0,
    height: 0,
  },
};

const data = {
  base_url: '',
  video: video,
  notification_text: '',
  base_size: {
    width: DEFAULT_AREA_SIZE,
    height: DEFAULT_AREA_SIZE,
  },
  actions: [],
};

export const videoActionUrlParameter = {
  key: '0-uri1',
  type: 'standard',
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [] as string[],
  },
};

type VideoData = typeof data;

type VideoActionUrlParameter = Parameter<typeof videoActionUrlParameter>[];

export type VideoEditorData = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AbstractEditorData<VideoData, any, VideoActionUrlParameter>,
  'actions'
>;

export const videoEditor: VideoEditorData = {
  module_id: LineFieldType.Video,
  data,
  parameters: [videoActionUrlParameter],
};
