const mainPalette = {
  blue001: '#f0f8ff',
  blue001v2: '#f3f9ff',
  blue002: '#edf6ff',
  blue002v2: '#e7f2ff',
  blue003: '#c4e1ff',
  blue004: '#9cc8ff',
  blue005: '#72abfc',
  blue005v2: '#5998FF',
  blue006: '#4586f0',
  blue007: '#3066c9',
  blue008: '#1f49a3',
  blue009: '#11307d',
  blue010: '#0b1e57',
  cyan001: '#e6fff3',
  cyan002: '#d4fceb',
  cyan003: '#a1f7d5',
  cyan004: '#73ebbf',
  cyan005: '#45deab',
  cyan006: '#1fd19c',
  cyan007: '#11ab82',
  cyan008: '#078567',
  cyan009: '#005e4b',
  cyan010: '#00382f',
};

const neutralPalette = {
  neutral001: '#f7f8fa',
  neutral001v2: '#F8F9FB',
  neutral002: '#edeff2',
  neutral002v2: '#E7EBEF',
  neutral003: '#e6e8eb',
  neutral003v2: '#D7DEE4',
  neutral004: '#d5dae0',
  neutral004v2: '#C7D0D9',
  neutral005: '#c2c6cc',
  neutral005v2: '#BBC6D1',
  neutral006: '#b1b5ba',
  neutral006v2: '#AFBCC9',
  neutral007: '#a1a8b3',
  neutral007v2: '#8C9CAB',
  neutral008: '#858d99',
  neutral008v2: '#5C7388',
  neutral009: '#5c6066',
  neutral009v2: '#425A70',
  neutral010: '#2c3848',
  neutral010v2: '#223B53',
  neutral011: '#09172a',
};

const greyPalette = {
  grey001: '#404040',
  grey002: '#9b9b9b',
  grey003: '#e5e5e5',
  grey004: '#4a4a4a',
  grey005: '#a6a6a6',
  grey006: '#f5f5f5',
  grey006v2: '#afbcc9',
  grey007: '#b3b3b3',
  grey008: '#dbdbdb',
  grey009: '#808080',
  grey010: '#a9a9a9',
  grey011: '#d9d9d9',
  grey012: '#d7d7d7',
  grey013: '#cccccc',
  grey014: '#d8d8d8',
};

const greenPalette = {
  green001: '#e9f7eb',
  green001v2: '#F1FFF5',
  green002: '#c3ebcc',
  green002v2: '#E9FBED',
  green003: '#95dea8',
  green004: '#6bd189',
  green005: '#45c46f',
  green006: '#23b759',
  green006v2: '#13A849',
  green007: '#149146',
  green008: '#0a6b34',
  green009: '#034522',
  green010: '#011f10',
};

const yellowPalette = {
  yellow001: '#fffceb',
  yellow002: '#fff3c2',
  yellow002v2: '#FFF8D9',
  yellow003: '#ffe799',
  yellow004: '#ffd970',
  yellow005: '#ffc847',
  yellow006: '#ffb61f',
  yellow006v2: '#FFA902',
  yellow007: '#d98f0f',
  yellow008: '#b36d04',
  yellow009: '#8c4f00',
  yellow010: '#663600',
};

const redPalette = {
  red001: '#fff9f7',
  red002: '#fff3f0',
  red002v2: '#ffebe4',
  red003: '#ffcfc7',
  red004: '#ffa99e',
  red005: '#fc7f74',
  red005v2: '#E9573B',
  red006: '#f04f47',
  red006v2: '#d93721',
  red007: '#d1324d',
  red008: '#ab203c',
  red009: '#85132d',
  red010: '#5e0c22',
};

const purplePalette = {
  purple001: '#f9f0ff',
  purple002: '#f7edff',
  purple003: '#e3c4ff',
  purple004: '#cc9cff',
  purple005: '#b273ff',
  purple006: '#9048f8',
  purple007: '#7852c4',
  purple008: '#583a9e',
  purple009: '#3c2678',
  purple010: '#261952',
};

export const colors = {
  statusGrey: '#e6e6e6',
  statusYellow: '#ffe4aa',
  statusBlue: '#c9ecfb',
  statusPurple: '#edd6ff',
  statusGreen: '#bfefd7',
  statusRed: '#ffdddb',
  mainBlue: '#353e54',
  linkBlue: '#5496b2',
  white001: '#ffffff',
  dropdownHoverColor: 'rgba(216, 216, 216, 0.2)',
  iconHoverGreen: '#11ab7e',
  inputErrorRed: '#ff7c75',
  mainGreen: '#63c89e',
  requiredRed: '#cc585d',
  darkBlue: '#2c3848',
  lightBlue: '#4586f0',
  lineGreen: '#20D155',
  avatarFallbackBg: '#DDDFE6',
  ...mainPalette,
  ...neutralPalette,
  ...greyPalette,
  ...greenPalette,
  ...yellowPalette,
  ...redPalette,
  ...purplePalette,
};
