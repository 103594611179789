import type { RSS } from './model';

import { rss } from './model';
import * as utils from './utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ArrayItem<T extends Array<any>> = T extends (infer U)[] ? U : never;

export const parse = function (document: Document): RSS {
  const parsedFeed = Object.assign({}, rss);

  parsedFeed.type = 'line-today-v1';
  parsedFeed.items = mapItems(document);

  return parsedFeed;
};

function getItemTitle(node: Element) {
  return utils.getElementTextContent(node, 'title');
}

function getItemLinks(node: Element) {
  const links = utils.getChildElements(node, 'sourceUrl');

  return links.map(function (link) {
    return {
      url: link.textContent,
      rel: undefined,
    };
  });
}

function getItemAuthors(node: Element) {
  let authors = utils.getElementTextContentArray(node, 'author');

  if (authors.length === 0) {
    authors = utils.getElementTextContentArray(node, 'dc:creator');
  }

  return authors.map(function (author) {
    return {
      name: author,
    };
  });
}

function getItemCategories(node: Element) {
  const categories = utils.getElementTextContentArray(node, 'category');

  return categories.map(function (category) {
    return {
      name: category,
    };
  });
}

function getItemId(node: Element) {
  return utils.getElementTextContent(node, 'ID');
}

function getItemPublished(node: Element) {
  return utils.getElementTextContent(node, 'publishTimeUnix');
}

function mapItems(document: Document) {
  const itemNodes = utils.getElements(document, 'article');

  return itemNodes.map(function (item) {
    return {
      title: getItemTitle(item), //
      links: getItemLinks(item), //
      id: getItemId(item), //
      authors: getItemAuthors(item), //
      categories: getItemCategories(item), //
      published: getItemPublished(item), //
      description: undefined,
      content: undefined,
      enclosures: [
        {
          url: undefined,
          length: undefined,
          mimeType: undefined,
        },
      ],
      itunes: {
        authors: [
          {
            name: undefined,
          },
        ],
        block: undefined,
        duration: undefined,
        explicit: undefined,
        image: undefined,
        isClosedCaptioned: undefined,
        order: undefined,
        subtitle: undefined,
        summary: undefined,
      },
    } as ArrayItem<RSS['items']>;
  });
}
