import { memo, useCallback, useState } from 'react';
import { Trans } from 'react-i18next';

import type { ReactNode } from 'react';

import { Text } from 'components';
import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { Popover } from 'shared/components/Popover';
import { theme } from 'theme';

interface ConfirmDeletingPopoverProps {
  description: ReactNode;
  onConfirmDeleting: () => void;
}

export const ConfirmDeletingPopover = memo(
  ({ description, onConfirmDeleting }: ConfirmDeletingPopoverProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const onConfirm = useCallback(() => {
      setIsOpen(false);
      onConfirmDeleting();
    }, [onConfirmDeleting]);

    return (
      <Popover
        trigger="click"
        placement="bottom"
        open={isOpen}
        onOpenChange={setIsOpen}
        content={
          <>
            <Text display="block" mb={12}>
              {description}
            </Text>
            <Button type="primary" size="small" onClick={onConfirm} danger={true}>
              <Trans i18nKey="common.delete" />
            </Button>
          </>
        }
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        >
          <MotifIcon
            un-i-motif="bin"
            style={{ color: theme.colors.neutral007, fontSize: '16px' }}
          />
        </div>
      </Popover>
    );
  },
);
