import type { Rule, RuleResult } from './type';

export const createRule = (props: Partial<RuleResult>): Rule => {
  return () => ({
    handlesNull: false,
    hint: '',
    validate: () => true,
    ...props,
  });
};
