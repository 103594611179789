import styled from '@emotion/styled';
import { useMemo } from 'react';

import { EDITOR_TAG_REGEX } from 'shared/components/Editor/constants';

import type { SmsModule } from 'shared/components/Editor/LineMessageEditor/types';

import { transformKey } from 'shared/components/Editor/utils/helper';
import { theme } from 'theme';

const NameSpan = styled.span`
  position: relative;
  color: ${theme.colors.green005};
`;

const MessageBubble = styled.div`
  display: inline-block;
  border-radius: 16px;
  height: auto;
  width: auto;
  box-sizing: border-box;
  padding: 5px 10px;
  background-color: white;
  overflow-wrap: break-word;
  line-height: 1.5;
`;

export const SmsModulePreview = ({ message }: { message: SmsModule }) => {
  const content = useMemo(() => {
    return message.data.text
      .split(EDITOR_TAG_REGEX)
      .map((line, index) =>
        line.match(EDITOR_TAG_REGEX) ? <NameSpan key={index}>{transformKey(line)}</NameSpan> : line,
      );
  }, [message]);

  return <MessageBubble>{content}</MessageBubble>;
};
