import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { dayjs } from 'lib/dayjs';
import { getDateLocaleCode } from 'modules/G11n/i18n';

/**
 * Configure Dayjs locale; this can be used globally or in specific parts of the application
 */
export const useDayjsLocale = (): void => {
  const { i18n } = useTranslation();

  useEffect(
    function configureDayJs() {
      dayjs.locale(getDateLocaleCode(i18n.language));
    },
    [i18n.language],
  );
};
