import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITriggerCodeModule } from './types';

import { Text } from 'components';
import { useDebounce } from 'hooks/useDebounce';
import { TagPickerByName } from 'shared/components/TagPicker/TagPickerByName';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';
import { stripSpaces } from 'utils/string/whiteSpaces';

import { Skeleton } from './components/Skeleton';
import * as S from './components/styled';
import { createOrUpdateTriggerCode, getAllEditorTriggerList } from './service';

const TRIGGER_CODE_MAXLENGTH = 32;

export const TriggerCodeModule = ({
  currentChannel,
  triggerCode,
  ...styledRest
}: ITriggerCodeModule) => {
  const { t } = useTranslation();

  const { message } = useMessage();

  const [tagList, setTagList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTriggerCodeExceeded, setIsTriggerCodeExceeded] = useState(false);
  const debounceTriggerCode = useDebounce(triggerCode, 500);

  useEffect(() => {
    let isAbort = false;
    if (stripSpaces(debounceTriggerCode) === '') {
      return;
    }
    if (currentChannel !== null) {
      setIsLoading(true);
      getAllEditorTriggerList([], currentChannel?.id, debounceTriggerCode).then((res) => {
        if (res.length > 0 && !isAbort) {
          setTagList(res[0].tags);
        } else {
          setTagList([]);
        }
        setTimeout(() => {
          if (!isAbort) {
            setIsLoading(false);
            const isExceeded = triggerCode.length > TRIGGER_CODE_MAXLENGTH;
            setIsTriggerCodeExceeded(isExceeded);
          }
        }, 500);
      });
    }
    return () => {
      isAbort = true;
    };
  }, [currentChannel, debounceTriggerCode, triggerCode.length]);
  if (!isTriggerCodeExceeded) {
    return (
      <S.TriggerCodeModuleWrapper {...styledRest}>
        <TagPickerByName
          tagList={isLoading ? [] : tagList}
          onTagListChange={(tags) => {
            setTagList(tags);
            createOrUpdateTriggerCode(
              triggerCode,
              currentChannel?.id ?? -1,
              tags,
              setTagList,
              message,
            );
          }}
          disabled={false}
          loading={isLoading}
        />
        {isLoading && stripSpaces(triggerCode) !== '' ? <Skeleton /> : null}
      </S.TriggerCodeModuleWrapper>
    );
  } else {
    return <Text color={theme.colors.yellow007}>{t('message.traceTag.charCountLimit')}</Text>;
  }
};
