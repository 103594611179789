import cn from 'classnames';
import { Editor } from 'draft-js';
import { memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { ParamRichTextFieldName } from 'components/LineMessageEditor/constants';
import type {
  CardEditorData,
  CarrouselData,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorState } from 'draft-js';
import type { ValidatorNameHelper } from 'lib/validator/helper/validatorHelpers';
import type { Rule } from 'lib/validator/rules/type';

import { onUrlNameClick } from 'components/LineMessageEditor/components/RichTextEditor/Decorators/strategy/insertTag';
import { onUnicodeEmojiClick } from 'components/LineMessageEditor/components/RichTextEditor/Decorators/strategy/insertUnicodeEmoji';
import { RichTextFocusKit } from 'components/LineMessageEditor/components/RichTextEditor/RichTextFocusKit';
import { handlePastedText } from 'components/LineMessageEditor/components/RichTextEditor/utils/handlePastedText';
import { createParamRichTextFieldEntityKey } from 'components/LineMessageEditor/components/RichTextEditor/utils/paramRichTextField';
import { TextWrapper } from 'components/LineMessageEditor/components/Styled';
import { Context } from 'components/LineMessageEditor/models/models';
import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import {
  stringifyEditorStateObject,
  stringifyEditorStateObjectToUnixText,
} from 'components/LineMessageEditor/utils/editorState';
import { useHandler } from 'hooks/useEventCallback';
import { useRichTextCheck } from 'lib/validator';
import { Hint } from 'lib/validator/FieldInput';
import { EEditorType } from 'lib/validator/helper/validatorHelpers';
import { useField } from 'lib/validator/useField';
import { isInvalid } from 'lib/validator/utils/invalid';
import { generateDataTestId } from 'shared/components/Editor/LineMessageEditor/utils/generateDataTestId';
import { styled } from 'shared/utils/styled';

export interface ParamRichTextProps {
  rowIndex: number;
  message: CardEditorData | CarrouselData;
  editorStateObject: EditorStateObject;
  setEditorState: (editorState: EditorState) => void;
  allowedTextDataTag: DecoratorType[];
  onChange: (rowIndex: number, type: DecoratorType, mappingKey: string) => void;
  editorType: EEditorType.CardEditor | EEditorType.CarrouselEditor;
  rules: Rule[];
  fieldName: ParamRichTextFieldName;
  fieldNameValidatorHelper: (payload: ValidatorNameHelper) => string;
  isEmbed?: boolean;
  carouselIndex?: number;
  placeholder?: string;
}

const ParamRichTextWrapper = styled(TextWrapper)`
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  min-height: 80px;
`;

export const ParamRichText = memo(function ParamRichText(props: ParamRichTextProps) {
  const { t } = useTranslation();
  const { dispatch, store } = useContext(Context);

  const { update, invalid, validate, value } = useField({
    name: props.fieldNameValidatorHelper({
      rowIndex: props.rowIndex,
      editorType: props.editorType,
      editorIndex: props.carouselIndex,
      entityKey: '',
    }),
    rules: props.rules,
    checkOnChange: false,
    value: props.editorStateObject
      ? stringifyEditorStateObject(props?.editorStateObject?.editorState)
      : '',
    enableReinitialize: true,
  });
  useRichTextCheck(validate, value);

  const handleEditorChange = useHandler((editorState: EditorState) => {
    props.setEditorState(editorState);
    update(stringifyEditorStateObjectToUnixText(editorState));
  });
  const createEditingRowHandler = useCallback(
    (richEditorId: number) => {
      return function handleEditingRow() {
        dispatch('setEditingRow', { rowIndex: props.rowIndex, richEditorId });
      };
    },
    [dispatch, props.rowIndex],
  );

  const createUrlNameClickHandlerByType = useHandler((decoratorType: DecoratorType) => {
    return function handleUrlNameClick() {
      const entityKey = createParamRichTextFieldEntityKey(
        props.fieldName,
        props.message.format.tagId,
      );
      onUrlNameClick(
        entityKey,
        props.onChange,
        props.rowIndex,
        props.editorStateObject.editorState,
        props.setEditorState,
        decoratorType,
        EEditorType.ParamRichTextEditor,
      );
    };
  });
  const handleClickName = useHandler(createUrlNameClickHandlerByType(DecoratorType.Name));
  const handleClickParam = useHandler(createUrlNameClickHandlerByType(DecoratorType.Custom));
  const handleClickEmoji = useHandler((emoji: string): void => {
    onUnicodeEmojiClick(props?.editorStateObject?.editorState, props?.setEditorState, emoji);
  });

  return (
    <ParamRichTextWrapper
      onFocus={createEditingRowHandler(props?.editorStateObject?.id)}
      className={cn({
        'is-error': isInvalid(invalid),
        'is-drag-mode': store.isDragMode,
      })}
    >
      {props?.editorStateObject?.editorState ? (
        <Editor
          webDriverTestID={generateDataTestId(
            `${props.fieldName}-param-rich-text-editor`,
            props.carouselIndex,
          )}
          editorState={props.editorStateObject.editorState}
          onChange={handleEditorChange}
          handlePastedText={handlePastedText}
          placeholder={props.placeholder ?? t('validation.maxCharLength', { count: 1600 })}
        />
      ) : null}
      {isInvalid(invalid) ? <Hint>{invalid ? invalid.errorMessage : null}</Hint> : null}
      <RichTextFocusKit
        isFocus={
          store.editorEditingRow === props.rowIndex &&
          store.richEditorId === props?.editorStateObject?.id
            ? true
            : false
        }
        allowedTextDataTag={props.allowedTextDataTag}
        onClickName={handleClickName}
        onClickParam={handleClickParam}
        onClickEmoji={handleClickEmoji}
      />
    </ParamRichTextWrapper>
  );
});
