import {
  MESSAGE_MODULE_TYPE,
  PARAMETER_DATA_FUNCTION,
  PARAMETER_TYPE,
} from 'shared/components/Editor/LineMessageEditor/constants';

import type {
  CardModule,
  CarouselModule,
  CustomParameter,
  CustomParameterWithLink,
  FlexModule,
  ImageCarouselContentHeroAction,
  ImageCarouselModule,
  ImagemapCarouselModule,
  ImagemapContent,
  ImagemapContentBox,
  ImagemapContentImage,
  ImagemapFlexModule,
  ImagemapModule,
  ImageModule,
  Message,
  NewsModule,
  Parameter,
  ParameterData,
  PrizeModule,
  SmsModule,
  StandardParameter,
  StandardParameterDataWithSharelink,
  TextModule,
  UriAction,
  VideoModule,
} from 'shared/components/Editor/LineMessageEditor/types';

export const isCustomParameterWithSharelink = (
  data: ParameterData,
): data is StandardParameterDataWithSharelink => {
  return data.function === PARAMETER_DATA_FUNCTION.sharelink;
};

export const isCustomParameterWithLink = (data: ParameterData): data is CustomParameterWithLink => {
  return data.function === PARAMETER_DATA_FUNCTION.link;
};

export const isStandardParameter = (parameter: Parameter): parameter is StandardParameter => {
  return parameter.type === PARAMETER_TYPE.standard;
};

export const isCustomParameter = (parameter: Parameter): parameter is CustomParameter => {
  return parameter.type === PARAMETER_TYPE.custom_string;
};

export const isCustomImageParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === PARAMETER_TYPE.custom_string &&
    parameter.mappingKey.startsWith('customImage')
  );
};

export const isCustomHeroParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === PARAMETER_TYPE.custom_string && parameter.mappingKey.startsWith('customHero')
  );
};

export const isCustomTextParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === PARAMETER_TYPE.custom_string && parameter.mappingKey.startsWith('customText')
  );
};

export const isCustomLinkParameter = (parameter: Parameter): parameter is CustomParameter => {
  return parameter.type === PARAMETER_TYPE.standard && parameter.data.function === 'link';
};

export const isCustomUriParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === PARAMETER_TYPE.custom_string && parameter.mappingKey.startsWith('customUri')
  );
};

export const isUriAction = (action: ImageCarouselContentHeroAction): action is UriAction => {
  return action.type === 'uri';
};

export const isCustomUriAction = (action: ImageCarouselContentHeroAction): action is UriAction => {
  return 'key' in action;
};

export const isImagemapCarouselUriAction = (
  action: ImageCarouselContentHeroAction,
): action is UriAction => {
  return action.type === 'uri';
};

export const isImagemapCarouselCustomUriAction = (
  action: ImageCarouselContentHeroAction,
): action is UriAction => {
  return action.type === 'customUri';
};

export const isImagemapContentImage = (
  content: ImagemapContent,
): content is ImagemapContentImage => {
  return content.type === 'image';
};

export const isImagemapContentBox = (content: ImagemapContent): content is ImagemapContentBox => {
  return content.type === 'box';
};

export const isSmsModule = (message: Message): message is SmsModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.sms;
};

export const isFlexModule = (message: Message): message is FlexModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.flex;
};

export const isTextModule = (message: Message): message is TextModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.text;
};

export const isImageModule = (message: Message): message is ImageModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.image;
};

export const isImageCarouselModule = (message: Message): message is ImageCarouselModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.imageCarousel;
};

export const isVideoModule = (message: Message): message is VideoModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.video;
};

export const isImagemapModule = (message: Message): message is ImagemapModule => {
  return (
    message.module_id === MESSAGE_MODULE_TYPE.imagemap ||
    message.module_id === MESSAGE_MODULE_TYPE.imagemapFlex
  );
};

export const isImagemapFlexModule = (message: Message): message is ImagemapFlexModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.imagemapFlex;
};

export const isImagemapCarouselModule = (message: Message): message is ImagemapCarouselModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.imagemapCarousel;
};

export const isCardModule = (message: Message): message is CardModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.card;
};

export const isCarouselModule = (message: Message): message is CarouselModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.carousel;
};

export const isPrizeModule = (message: Message): message is PrizeModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.prize;
};

export const isNewsModule = (message: Message): message is NewsModule => {
  return message.module_id === MESSAGE_MODULE_TYPE.news;
};
