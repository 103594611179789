// Allow console for this debugging function.
/* eslint-disable no-console */

import type { AxiosResponse } from 'axios';

export const logger = (response: AxiosResponse, color: string): void => {
  /**
   * Allow `process.env.NODE_ENV` here to remove the unused block.
   * More details: https://github.com/chatbotgang/Grazioso/pull/2635#discussion_r856423932
   */
  // eslint-disable-next-line no-process-env
  if (process.env.NODE_ENV === 'development') {
    console.groupCollapsed(
      `%c ${response.config.method} -> ${response.config.url}`,
      `color: ${color}`,
    );
    console.info('REQUEST:', {
      url: response.config.url,
      params: response.config.params,
      headers: response.config.headers,
    });
    console.info('RESPONSE:', {
      code: response.status,
      data: { ...response.data },
    });
    console.groupEnd();
  }
};
