import type {
  Action,
  Background,
  FlexBox,
  FlexImage,
  MessageAction,
  Offset,
  URIAction,
} from '@line/bot-sdk';
import type {
  FlexData,
  NewsData,
  PrizeRedeemData,
} from 'components/LineMessageEditor/models/templateDataAndTypes';

export const scaleRatio = 0.65; // 把line preview比例 算成fit編輯器預覽的比例

export type AcceptedFlexMessage = FlexData | NewsData | PrizeRedeemData;

export interface FlexPreviewProps {
  rowIndex: number;
  message: AcceptedFlexMessage;
  onAddUserMessageText: (text: string) => void;
}

export const isFlexBox = (n: FlexBox | FlexImage): n is FlexBox => n.type === 'box';

type ExtraFields = {
  label: string;
};

export const isMessageAction = (
  action: Action | undefined,
): action is MessageAction & ExtraFields => action !== undefined && action.type === 'message';

export const isURIAction = (action: Action | undefined): action is URIAction & ExtraFields =>
  action !== undefined && action.type === 'uri';

export interface FlexBoxStyles extends Offset {
  background?: Background;
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  paddingAll?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingStart?: string;
  paddingEnd?: string;
  cornerRadius?: string;
}

export interface FlexBoxClasses {
  flex?: number;
  spacing?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  margin?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  borderWidth?: string | 'none' | 'light' | 'normal' | 'medium' | 'semi-bold' | 'bold';
  cornerRadius?: string | 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  position?: 'absolute' | 'relative';
}
