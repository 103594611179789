import { z } from 'zod';

import { MessageSchema } from 'shared/components/Editor/LineMessageEditor/types';

const MessageTriggerEnum = {
  NEW_FRIEND: 1,
  ORIGINAL_FRIEND: 2,
  BOUND_FRIEND: 3,
} as const;

export const EditorMessagePayloadSchema = z.object({
  id: z.number(),
  messages: MessageSchema.array(),
  enable: z.boolean(),
  trigger_type: z.nativeEnum(MessageTriggerEnum),
  setting: z.number(),
});
