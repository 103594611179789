import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import { useEffect, useReducer } from 'react';

import type { ValidatorState } from './context';
import type { Rule } from './rules/type';
import type { ReactNode } from 'react';

import {
  register,
  setInvalid,
  setTargetInvalid,
  unregister,
  updateValue,
  validateComponent,
} from './actions';
import { Context, initialContext } from './context';
import { reducer } from './reducer';

interface Props {
  children: ReactNode;
  checkOnBlur?: boolean;
}

export const Provider = (props: Props) => {
  const [{ components = [], invalid = {}, values = {} }, dispatch] = useReducer(reducer, {
    ...initialContext,
    checkOnBlur: props.checkOnBlur || false,
  });

  const ids = components.map((c) => c.id);
  const test = _filter(ids, (value, index, iteratee) => _includes(iteratee, value, index + 1));
  const { length } = test;

  useEffect(() => {
    if (length > 0) {
      test.forEach((fail) => {
        // eslint-disable-next-line no-console
        console.warn(`You have registered two instances with the same name (${fail})`);
        // eslint-disable-next-line no-console
        console.warn('Please correct to avoid two fields being mapped to the same property');
      });
    }
  }, [length, test]);

  const reRegister = (id: string, rules: Rule[], value: string) => {
    dispatch(unregister(id));
    dispatch(register(id, rules, value));
    const result = validateComponent(
      {
        id,
        rules,
      },
      value,
    );
    dispatch(setTargetInvalid(id, result.valid, result.errorMessage));
  };

  const validate: ValidatorState['validate'] = (target) => {
    if (target) {
      const comp = components.find((c) => c.id === target.name);
      if (comp) {
        const result = validateComponent(comp, target.value);
        dispatch(setTargetInvalid(comp.id, result.valid, result.errorMessage));
        return { valid: result.valid || false, firstError: { ...result, id: comp.id } };
      }
      return { valid: true, firstError: null };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const foundBroken: any = {};
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let firstError: any;
      components
        .filter((c) => c.rules && c.rules.length > 0)
        .forEach((comp) => {
          const result = validateComponent(comp, values[comp.id]);
          if (!result.valid) {
            foundBroken[comp.id] = result;
            if (!firstError) {
              firstError = { ...result, id: comp.id };
            }
          }
        });
      dispatch(setInvalid(foundBroken));

      return { valid: Object.keys(foundBroken).length === 0, firstError };
    }
  };

  const contextValues: ValidatorState = {
    register: (id, rules, value) => dispatch(register(id, rules, value)),
    unregister: (id) => dispatch(unregister(id)),
    updateValue: (id, value) => dispatch(updateValue(id, value)),
    reRegister,
    validate,
    invalid,
    values,
    components,
    checkOnBlur: props.checkOnBlur || false,
  };

  return <Context.Provider value={contextValues}>{props.children}</Context.Provider>;
};

Provider.displayName = 'ValidatorProvider';
