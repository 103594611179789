import { z } from 'zod';

export const DeeplinkReportSchema = z.object({
  click_count: z.number().int(),
  auth_count: z.number().int(),
  new_friend_count: z.number().int(),
  existing_friend_count: z.number().int(),
  unique_new_friend_count: z.number().int(),
  unique_existing_friend_count: z.number().int(),
  unique_auth_count: z.number().int(),
});
