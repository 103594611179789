import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  APNG_FILE_LIMIT,
  MAX_APNG_FILE_COUNT,
  MAX_IMAGE_DIMENSIONS_VALUE,
  MAX_IMAGE_SIZE,
  MAX_SIZE,
  SHOULD_CHECK_APNG_EDITOR_TYPES,
} from 'components/LineMessageEditor/constants';

import type {
  ImageEditor,
  ImageEditorProps,
} from 'components/LineMessageEditor/components/ImageEditor';
import type { ParamImageEditor } from 'components/LineMessageEditor/components/ParamImageEditor';
import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { useFieldResult } from 'lib/validator';
import type { ChangeEvent } from 'react';

import { Context } from 'components/LineMessageEditor/models';
import { countAPNGFileInEditorModule } from 'components/LineMessageEditor/utils/countAPNGFileInEditorModule';
import { useHandler } from 'hooks/useEventCallback';
import { useUpload } from 'lib/fileUpload';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { checkAPNG } from 'utils/detection/checkAPNG';
import { imageDecodeAsync } from 'utils/imageUtils';

interface UseImageEditorUploadImageParams {
  row: EditorDataType;
  imageEditorProps: Required<Pick<ImageEditorProps, 'rowIndex' | 'editorType'>> &
    Pick<ImageEditorProps, 'carrouselIndex' | 'onUploadDone'>;
  validatorProps: Required<Pick<useFieldResult, 'update'>>;
}

export interface UseImageEditorUploadImageResult {
  handleUploadImage: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  isLoading: boolean;
}

/**
 * The hook for getting upload-image handler and loading state
 * for either {@link ImageEditor} or {@link ParamImageEditor}.
 *
 * {@link UseImageEditorUploadImageParams.handleUploadImage} is copied and moved
 * from the old `handleUploadImage` callback defined in the scope of `ImageEditor`.
 * Therefore, a few utilities, hooks, and mutations are added into this hook.
 * Also, pass `ImageEditor`'s props, closures, and local variables as the props
 * for this hook.
 *
 * @see https://tinyurl.com/5cx2asb6
 */
export function useImageEditorUploadImage({
  row,
  imageEditorProps: { rowIndex, carrouselIndex, onUploadDone, editorType },
  validatorProps: { update },
}: UseImageEditorUploadImageParams): UseImageEditorUploadImageResult {
  const { t } = useTranslation();

  const { dispatch } = useContext(Context);
  const onUpload = useUpload();
  const { message: messageToast } = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const handleUploadImage = useHandler(async (e: ChangeEvent<HTMLInputElement>) => {
    let imgWidth = 1;
    let imgHeight = 1;
    if (e.currentTarget.files !== null && e.currentTarget.files.length === 1) {
      const file = e.currentTarget.files[0];

      try {
        const isApng = await checkAPNG(file);

        if (isApng && editorType === 'ImageEditor') {
          messageToast.warning(t('message.notSupportApng'));
          return;
        }

        if (SHOULD_CHECK_APNG_EDITOR_TYPES.includes(editorType) && isApng) {
          if (countAPNGFileInEditorModule(row) >= MAX_APNG_FILE_COUNT) {
            messageToast.warning(t('message.apngCountLimit'));
            return;
          }
          if (file.size > APNG_FILE_LIMIT) {
            messageToast.error(t('common.fileSizeExceedWithSize', { size: '300 kb' }));
            return;
          }
        }

        const img = new Image();
        img.src = window.URL.createObjectURL(file);

        img.onload = async () => {
          if (file.size > MAX_SIZE) {
            messageToast.error(t('common.fileSizeExceedWithSize', { size: '1 mb' }));
            return;
          }

          if (
            img.naturalWidth > MAX_IMAGE_DIMENSIONS_VALUE ||
            img.naturalHeight > MAX_IMAGE_DIMENSIONS_VALUE
          ) {
            messageToast.error(t('common.maximumSize', { size: MAX_IMAGE_SIZE }));
            return;
          }

          if (img.naturalHeight / img.naturalWidth > 3) {
            messageToast.error(t('validation.cardEditor.imageSize'));
            return;
          }

          imgWidth = img.naturalWidth;
          imgHeight = img.naturalHeight;
          setIsLoading(true);
          const uploadUrl = await onUpload(file, 'Broadcast');
          await imageDecodeAsync(uploadUrl);
          update('imageUpload');

          if (onUploadDone) {
            onUploadDone({
              url: uploadUrl,
              aspectRatio: imgWidth + ':' + imgHeight,
              isAnimated: isApng,
            });
          } else {
            dispatch('setImageUrl', {
              rowIndex,
              url: uploadUrl,
              carrouselIndex,
              aspectRatio: imgWidth + ':' + imgHeight,
              isAnimated: isApng,
            });
          }

          setIsLoading(false);
        };

        img.onerror = () => {
          messageToast.error(t('common.failToFetchModule', { module: t('glossary.image') }));
        };
      } catch (error) {
        messageToast.error(t('common.failToFetchModule', { module: t('glossary.image') }));
      }
    }
  });
  return { handleUploadImage, isLoading };
}
