import debug from 'debug';

import type { Component, InValid } from './context';
import type { Rule } from './rules/type';
import type { ReactText } from 'react';

const log = debug('validator:actions');

export const REGISTERED_FORM_ELEMENT = 'REGISTERED_FORM_ELEMENT';
export const UNREGISTERED_FORM_ELEMENT = 'UNREGISTERED_FORM_ELEMENT';
export const RE_REGISTERED_FORM_ELEMENT = 'RE_REGISTERED_FORM_ELEMENT';
export const FORM_ELEMENT_UPDATED = 'FORM_ELEMENT_UPDATED';
export const FORM_VALIDATION_RESULT = 'FORM_VALIDATION_RESULT';
export const FORM_VALIDATION = 'FORM_VALIDATION';

export const register = (
  id: string,
  rules: Rule[],
  value: string,
): { rules: Rule[]; id: string; type: string; value: string } => ({
  type: REGISTERED_FORM_ELEMENT,
  id,
  rules,
  value,
});

export const unregister = (id: string): { type: string; id: string } => ({
  type: UNREGISTERED_FORM_ELEMENT,
  id,
});

export const updateValue = (
  id: string,
  value: ReactText,
): { type: string; id: string; value: ReactText } => ({
  type: FORM_ELEMENT_UPDATED,
  id,
  value,
});

export const setInvalid = (invalid: InValid): { type: string; invalid: InValid } => ({
  type: FORM_VALIDATION_RESULT,
  invalid,
});

export const setTargetInvalid = (
  id: string,
  valid?: boolean,
  errorMessage?: string,
): { type: string; id: string; valid: boolean | undefined; errorMessage: string | undefined } => ({
  type: FORM_VALIDATION,
  id,
  valid,
  errorMessage,
});

export const validateComponent = (comp: Component, value: ReactText): InValid => {
  let valid = true;
  let rule = null;
  let actualRule = null;

  const { rules } = comp;

  log(comp, value, rules);

  if (!rules || rules.length === 0) return { valid, errorMessage: '' };

  if (rules) {
    for (let i = 0; i < rules.length; i += 1) {
      rule = rules[i];

      if (typeof rule === 'function') {
        actualRule = rule(comp.hint);
      } else {
        actualRule = rule;
      }

      if ((value === undefined || value === null) && !actualRule.handlesNull) {
        break;
      }

      valid = Boolean(actualRule.validate(value));

      if (!valid) {
        break;
      }
    }
  }

  const hint = !valid && !!actualRule ? actualRule.hint : null;
  let errorMessage = '';
  if (hint) {
    if (typeof hint === 'function') {
      errorMessage = hint();
    } else {
      errorMessage = hint;
    }
  }

  return { valid, errorMessage, errorKey: actualRule?.errorType };
};
