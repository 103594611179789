import { forwardRef, memo, useRef } from 'react';
import { Trans } from 'react-i18next';
import { useMergeRefs } from 'use-callback-ref';

import type { CopyButtonProps } from 'components/CopyButton';
import type { ComponentPropsWithRef, MouseEvent } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { CopyButton } from 'components/CopyButton';
import { useHandler } from 'hooks/useEventCallback';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { css, cx, overrideCss } from 'shared/utils/styled/override';
import { theme } from 'theme';

const Outer = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  --max-width: 100%;
  --min-width: 64px;
  --width: 100%;
  align-items: baseline;
  background-color: ${theme.colors.neutral001};
  border-radius: 4px;
  display: flex;
  font-size: 12px;
  gap: 0.5em;
  max-width: var(--max-width);
  min-width: var(--min-width);
  overflow: hidden;
  padding: 8px;
  width: clamp(var(--min-width), var(--width), var(--max-width));
  &::before {
    white-space: nowrap;
  }
  & > input {
    background-color: transparent;
    border: 0;
    flex: 1;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
    min-width: 64px;
    max-width: 100%;
    width: 100%;
  }
  ${overrideCss};
`;

type CopyFieldProps = {
  text: string;
  label?: string;
  copyButtonProps?: Omit<CopyButtonProps, 'text'>;
  inputProps?: Omit<ComponentPropsWithRef<'input'>, 'value' | 'readOnly'>;
} & ComponentPropsWithRef<'div'> &
  OverrideComponentCssProps;

/**
 * Do not remove the <Trans /> fallback children because error boundary might
 * display before i18next initialed and it use <CopyFiled /> for the event ID.
 **/
export const CopyField = memo(
  forwardRef<HTMLDivElement, CopyFieldProps>(function CopyField(
    { children, text, label, copyButtonProps = {}, inputProps = {}, ...props },
    ref,
  ) {
    const { message } = useMessage();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const mergedInputRef = useMergeRefs([inputRef, ...(inputProps.ref ? [inputProps.ref] : [])]);

    const copyButtonRef = useRef<HTMLButtonElement | null>(null);

    const mergedCopyButtonRef = useMergeRefs([
      copyButtonRef,
      ...(copyButtonProps.ref ? [copyButtonProps.ref] : []),
    ]);

    const clickHandler = useHandler((e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      mergedCopyButtonRef.current?.click();
    });

    const onCopiedHandler = useHandler(() => {
      inputRef.current?.select();
      message.success(<Trans i18nKey="common.copiedToClipboard" />);
      copyButtonProps.onCopied?.();
    });

    return (
      <Outer
        {...props}
        onClick={clickHandler}
        styledCss={cx(
          label &&
            css`
              &::before {
                content: '${CSS.escape(label)}:';
              }
            `,
          props.styledCss,
        )}
        ref={ref}
      >
        <input value={text} ref={mergedInputRef} {...inputProps} readOnly={true} />
        <Tooltip
          title={
            <TooltipDescription>
              <Trans i18nKey="common.copy" />
            </TooltipDescription>
          }
        >
          <CopyButton
            text={text}
            {...copyButtonProps}
            onCopied={onCopiedHandler}
            ref={mergedCopyButtonRef}
          />
        </Tooltip>
      </Outer>
    );
  }),
);
