import type { StoreApi } from 'zustand/vanilla';

export function registerCommand(
  store: StoreApi<{
    opened: boolean;
    open: () => void;
    close: () => void;
  }>,
): void {
  Object.defineProperty(window, 'devMode', {
    get() {
      return store.getState().opened ? 'on' : 'off';
    },
    set(value: unknown) {
      if (value === 'on') {
        store.getState().open();
      } else {
        store.getState().close();
      }
    },
  });
}
