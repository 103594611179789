import objectInspect from 'object-inspect';

/**
 * Transform the input to a string.
 *
 * @param value Value to be transformed.
 * @returns Transformed value.
 */
export function toString(value: unknown): string {
  return typeof value === 'string'
    ? value
    : typeof value === 'number' || typeof value === 'bigint' || typeof value === 'boolean'
      ? String(value)
      : value === null || value === undefined
        ? ''
        : objectInspect(value);
}

/**
 * Transform the input to a string.
 *
 * @example
 * ```ts
 * const parse = string();
 * const result = parse(123); // '123'
 * ```
 * @returns A parser
 */
export function string(): (input: unknown) => string {
  return toString;
}
