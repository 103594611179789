import { useTranslation } from 'react-i18next';
import { doNothing } from 'remeda';

import type { Action } from '@line/bot-sdk';
import type { LinkParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { getVariableName } from 'components/LineMessageEditor/utils/transformKey';
import { isUrl } from 'lib/validator/rules/url';

import { useProps } from './context';
import { isMessageAction, isURIAction } from './types';

type FlexAction =
  | Action<{
      label: string;
    }>
  | undefined;

export const useAction = (action: FlexAction): [string, () => void] => {
  const { t } = useTranslation();
  const {
    message: { parameters },
    onAddUserMessageText,
  } = useProps();
  let text = '';
  let actionType = '';
  let clickCallback;
  if (isMessageAction(action)) {
    text = action.text;
    actionType = t('message.send');
    clickCallback = () => {
      if (text) onAddUserMessageText(text);
    };
  } else if (isURIAction(action)) {
    actionType = t('common.openUrl');
    if (isUrl(action.uri)) {
      text = action.uri;
    } else {
      const key = getVariableName(action.uri);
      const parameter = (parameters as LinkParameter[]).find((d) => d.key === key);
      if (key && parameter) {
        text = parameter.data.url;
        // TODO: implement isAPIParameter
        // if (isAPIParameter(parameter)) {
        //   actionType = t`URL provided by API`;
        // }
      }
    }
    clickCallback = () => {
      if (isUrl(text)) {
        window.open(text);
      }
    };
  }
  // XXX: maybe useMemo
  return [`${actionType}: ${text}`, clickCallback ?? doNothing];
};
