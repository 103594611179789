import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { BroadcastSmsStatusSchema } from 'shared/models/broadcastSms';
import { AsyncTaskSchema, ListResponseSchema } from 'shared/models/common';
import { SegmentAudienceBaseSchema } from 'shared/models/segment/common';

export const BroadcastSmsAudienceSchema = SegmentAudienceBaseSchema.extend({
  id: z.number().int(),
});

const BroadcastSmsReportSchema = z.object({
  /** The count of messages delivered to end users. */
  delivered: z.number().int(),
  failed: z.number().int(),
  /** The count of messages delivered to the vendor. */
  sent: z.number().int(),
  updated_at: z.string(),
  undeliverable: z.number().int(),
  message_unit: z.number().int(),
});

const BroadcastSmsItemMessageSchema = z.object({
  text: z.string(),
  parameters: z.array(
    z.discriminatedUnion('action', [
      z.object({
        position_key: z.string(),
        action: z.literal('shorten_url'),
        kwargs: z.object({
          url: z.string(),
        }),
        tag_ids: z.number().int().array().optional(),
      }),
    ]),
  ),
});

const BroadcastSmsPerformanceShortenUrlSchema = z.object({
  position_key: z.string(),
  shorten_url: z.string(),
  url: z.string(),
  clicks: z.number().int(),
  tag_ids: z.number().int().array().optional(),
});

const BroadcastSmsDetailSchema = z.object({
  status: BroadcastSmsStatusSchema,
  id: z.number().int(),
  name: z.string(),
  audience: BroadcastSmsAudienceSchema,
  schedule_time: z.string(),
  last_edited_at: z.string(),
  message: BroadcastSmsItemMessageSchema,
  built_message: z.object({}).nullable(),
  report: BroadcastSmsReportSchema,
  performance: z.object({
    clicks: z.number().int(),
    shorten_urls: BroadcastSmsPerformanceShortenUrlSchema.array(),
  }),
  expected_size: z.number().int(),
  created_at: z.string(),
  updated_at: z.string(),
});

const BroadcastSmsListItemSchema = z.object({
  status: BroadcastSmsStatusSchema,
  id: z.number().int(),
  name: z.string(),
  audience: BroadcastSmsAudienceSchema,
  schedule_time: z.string(),
  last_edited_at: z.string(),
  report: BroadcastSmsReportSchema,
  performance: z.object({
    clicks: z.number().int(),
  }),
  expected_size: z.number().int(),
  created_at: z.string(),
  updated_at: z.string(),
});

const BroadcastSmsCreateParametersSchema = z.discriminatedUnion('status', [
  z
    .object({
      status: z.literal('draft'),
      bot_id: z.number().int(),
      schedule_time: z.string().optional(),
      name: z.string(),
      audience_id: z.number().int(),
      message: BroadcastSmsItemMessageSchema,
    })
    .strict(),
  z
    .object({
      status: z.literal('scheduled'),
      bot_id: z.number().int(),
      schedule_time: z.string(),
      name: z.string(),
      audience_id: z.number().int(),
      message: BroadcastSmsItemMessageSchema,
    })
    .strict(),
]);

export const broadcastSmsApi = makeApi([
  {
    alias: 'broadcastSmsList',
    method: 'get',
    path: '/sms/v1/broadcast/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: BroadcastSmsStatusSchema.or(z.enum(['search'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: BroadcastSmsListItemSchema.strict().array(),
    }),
  },
  {
    alias: 'broadcastSmsListMetrics',
    method: 'get',
    path: '/sms/v1/broadcast/count/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        all: z.number().int(),
        draft: z.number().int(),
        scheduled: z.number().int(),
        sending: z.number().int(),
        sent: z.number().int(),
        failed: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'broadcastSmsDetail',
    method: 'get',
    path: '/sms/v1/broadcast/:broadcastId/',
    response: BroadcastSmsDetailSchema.strict(),
  },
  {
    alias: 'broadcastSmsCreate',
    method: 'post',
    path: '/sms/v1/broadcast/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BroadcastSmsCreateParametersSchema,
      },
    ],
    response: z
      .object({
        id: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'broadcastSmsUpdate',
    method: 'patch',
    path: '/sms/v1/broadcast/:broadcastId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BroadcastSmsCreateParametersSchema,
      },
    ],
    response: z.never(),
  },
  {
    alias: 'broadcastSmsDelete',
    method: 'delete',
    path: '/sms/v1/broadcast/:broadcastId/',
    response: z.never(),
  },
  {
    alias: 'broadcastSmsExportUndeliverable',
    method: 'post',
    path: '/sms/v1/broadcast/:broadcastId/undeliverable/export/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z
          .object({
            bot_id: z.number().int(),
          })
          .strict(),
      },
    ],
    response: AsyncTaskSchema.strict(),
  },
]);

export type BroadcastSmsListResponse = ZodiosResponseByAlias<
  typeof broadcastSmsApi,
  'broadcastSmsList'
>;

export type BroadcastSmsListItem = z.output<typeof BroadcastSmsListItemSchema>;

export type BroadcastSmsListMetricsResponse = ZodiosResponseByAlias<
  typeof broadcastSmsApi,
  'broadcastSmsListMetrics'
>;

export type BroadcastSmsDetail = z.output<typeof BroadcastSmsDetailSchema>;

export type BroadcastSmsPerformanceShortenUrl = z.output<
  typeof BroadcastSmsPerformanceShortenUrlSchema
>;

export type BroadcastSmsItemMessage = z.output<typeof BroadcastSmsItemMessageSchema>;
