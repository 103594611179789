const TRANSPARENCY = 255; // 255為完全不透明

/**
 * Check if the image is transparent
 *
 * @param image image object
 * @returns if the image is transparent, return true, otherwise return false
 */
export const checkImageTransparency = (image: HTMLImageElement): boolean => {
  let isTransparency = false;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;

  if (ctx) {
    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] < TRANSPARENCY) {
        isTransparency = true;
        break;
      }
    }
  }
  return isTransparency;
};
