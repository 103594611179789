import Select from 'antd/es/select';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { SelectProps, SelectValue } from 'antd/es/select';
import type { Prize } from 'modules/Prize/types';

import { lowerFirst } from 'utils/string/changeCase';

type PrizeSelectProps<TValue> = Omit<
  SelectProps<TValue>,
  'showSearch' | 'optionFilterProp' | 'filterOption'
> & {
  /** A prize list which contains all prizes (activated & expired ones) */
  prizeList: Prize[];
  /** The default prize id. This is used in displaying the default selected option layout */
  defaultPrizeId: number;
};

const PurePrizeSelect = <TValue extends SelectValue = SelectValue>({
  defaultValue,
  placeholder,
  onChange,
  style,
  prizeList,
  defaultPrizeId,
  ...restProps
}: PrizeSelectProps<TValue>) => {
  const { t } = useTranslation();

  const isPrizeIdExistingInPrizeList = useMemo(
    () => prizeList.some((prize) => prize.id === defaultPrizeId),
    [prizeList, defaultPrizeId],
  );

  return (
    <Select
      {...restProps}
      showSearch={true}
      defaultValue={defaultValue}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      filterOption={true}
      style={style}
    >
      {prizeList.map((prize) => (
        <Select.Option key={prize.id} value={prize.id} disabled={prize.status === 'expired'}>
          {prize.name}
          {prize.status === 'expired'
            ? t('common.withParentheses', {
                text: lowerFirst(t('prizeManagement.status.expired')),
              })
            : null}
        </Select.Option>
      ))}
      {/* This additional option element is required. Because we want to display the hint for a non-existing prize option in the selected field, we need to provide a pseudo option for matching. */}
      {!isPrizeIdExistingInPrizeList ? (
        <Select.Option key={defaultPrizeId} value={defaultPrizeId} style={{ display: 'none' }}>
          {t('dpm.segmentDeleted')}
        </Select.Option>
      ) : null}
    </Select>
  );
};

// Workaround form here: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
export const PrizeSelect = memo(PurePrizeSelect) as typeof PurePrizeSelect;
