import throttle from 'lodash/throttle';
import { memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import type {
  ImageCarouselContentData,
  ImageCarouselEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import type { UIEvent } from 'react';

import { Box, Flex } from 'components/layoutUtils';
import {
  CarouselAddZone,
  CarouselScrollWrapper,
  CarouselThumbnail,
  CarouselThumbnailWrapper,
} from 'components/LineMessageEditor/components/Styled';
import { Context } from 'components/LineMessageEditor/models';
import { ImageData } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { isMappingKey } from 'components/LineMessageEditor/utils/transformKey';
import { StyledIcon } from 'components/StyledIcon';
import { Text } from 'components/Typography';
import { LineMessageHorizontalDrag, LineMessageImage } from 'icons';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';

import { ParamImageEditor } from './ParamImageEditor';

const CAROUSEL_CARD_ID_PREFIX = 'image-carousel-card-';
const CARD_MARGIN_LEFT = 40;
const ADD_CARD_WIDTH = 83;

interface SortablePointProps {
  rowIndex: number;
  activeIndex: number;
  carrouselIndex: number;
  imgUrl: string;
  setActiveIndex: (index: number) => void;
}
interface SortableContainerProps {
  contents: ImageCarouselContentData[];
  rowIndex: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

const SortableList = SortableContainer((props: SortableContainerProps) => {
  return (
    <Flex pt={32} justifyContent="center">
      {props.contents.map((content, index) => {
        return (
          <SortableItem
            rowIndex={props.rowIndex}
            carrouselIndex={index}
            activeIndex={props.activeIndex}
            setActiveIndex={props.setActiveIndex}
            imgUrl={content.hero.contents[0].url}
            index={index}
            key={`Row${props.rowIndex}ImageCarousel${index}Drag`}
          />
        );
      })}
    </Flex>
  );
});

const SortableItem = SortableElement((props: SortablePointProps) => <SortablePoint {...props} />);
const SortablePoint = (props: SortablePointProps) => {
  return (
    <CarouselThumbnailWrapper
      onClick={() => {
        scrollToCarouselTarget(props.carrouselIndex, props.rowIndex);
      }}
      key={props.carrouselIndex}
    >
      <Text fontSize={14} lineHeight="20px" mb="1px" color={theme.colors.neutral005}>
        {props.carrouselIndex + 1}
      </Text>
      <CarouselThumbnail active={props.carrouselIndex === props.activeIndex}>
        {!!props.imgUrl ? (
          <img
            style={{
              objectFit: 'contain',
            }}
            src={props.imgUrl}
            width={24}
            height={24}
            alt=""
          />
        ) : (
          <LineMessageImage width={20} height={20} />
        )}
      </CarouselThumbnail>
      <StyledIcon
        color={theme.colors.neutral007}
        width={24}
        height={24}
        className="icon-horizontal-drag"
        component={<LineMessageHorizontalDrag />}
        style={{ cursor: 'ew-resize' }}
      />
    </CarouselThumbnailWrapper>
  );
};

function scrollToCarouselTarget(index: number, rowIndex: number) {
  const target = document.getElementById(`${CAROUSEL_CARD_ID_PREFIX}${rowIndex}_${index}`);
  if (target) {
    window.requestAnimationFrame(() => {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    });
  }
}

export const ImageCarouselEditor = memo(function ImageCarouselEditor({
  rowIndex,
  message,
}: {
  rowIndex: number;
  message: ImageCarouselEditorData;
}) {
  const { t } = useTranslation();

  const { dispatch } = useContext(Context);
  const { message: messageToast } = useMessage();

  const [activeIndex, setActiveIndex] = useState(0);

  const calcActiveIndex = throttle((e: UIEvent<HTMLDivElement>) => {
    try {
      const eTarget = e.target as HTMLDivElement;
      setActiveIndex(
        Math.round(
          (eTarget.scrollLeft / (eTarget.scrollWidth - ADD_CARD_WIDTH - CARD_MARGIN_LEFT)) *
            message.data.contents.length,
        ),
      );
    } catch (error) {
      setActiveIndex(0);
    }
  }, 150);

  return (
    <Box>
      <CarouselScrollWrapper
        onScroll={(e) => {
          e.persist();
          calcActiveIndex(e);
        }}
      >
        {message.data.contents.map((content, index) => {
          return (
            <Box
              id={`${CAROUSEL_CARD_ID_PREFIX}${rowIndex}_${index}`}
              mr={`${CARD_MARGIN_LEFT}px`}
              mb="32px"
              key={`imageCarouselRow${rowIndex}-${index}`}
              onClick={() => {
                setActiveIndex(index);
              }}
            >
              <ParamImageEditor
                rowIndex={rowIndex}
                isEmbed={true}
                required={true}
                editorType="ImageCarouselEditor"
                carrouselIndex={index}
                message={new ImageData().setUrlWithKey(
                  isMappingKey('customImage', content.hero.contents[0].key),
                  content.hero.contents[0].url,
                )}
                onUploadDone={({ url, aspectRatio, isAnimated }) => {
                  dispatch('setImageCarouselImageUrlWithKey', {
                    isParam: false,
                    rowIndex,
                    carouselIndex: index,
                    url,
                    aspectRatio,
                    isAnimated,
                  });
                }}
                onDelete={() => {
                  dispatch('deleteImageCarousel', { rowIndex, carouselIndex: index });
                }}
              />
            </Box>
          );
        })}
        <CarouselAddZone
          onClick={() => {
            if (message.data.contents.length >= 10) {
              messageToast.warning(t('validation.carrousel.cardAmountLimit'));
              return;
            }
            dispatch('addImageCarouselCard', { rowIndex });
            setTimeout(() => {
              scrollToCarouselTarget(message.data.contents.length, rowIndex);
            }, 0);
          }}
          data-test="add-image-carousel"
        >
          +
        </CarouselAddZone>
      </CarouselScrollWrapper>
      <SortableList
        contents={message.data.contents}
        rowIndex={rowIndex}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        distance={1}
        helperClass="sortableHelper"
        lockAxis="x"
        axis="x"
        onSortEnd={({ oldIndex, newIndex }) => {
          scrollToCarouselTarget(newIndex, rowIndex);
          dispatch('sortImageCarousel', { rowIndex, oldIndex, newIndex });
        }}
      />
    </Box>
  );
});
