import type { CardRichEditorIds } from './templateDataAndTypes/card';
import type { State } from './templateDataAndTypes/types';

import { CarrouselData } from 'components/LineMessageEditor/models/templateDataAndTypes/carrousel';

import { dispatch } from './models';

export const carrouselStore = {
  reducers: {
    addCarrousel(
      prevState: State,
      { rowIndex, richEditorIds }: { rowIndex: number; richEditorIds?: CardRichEditorIds },
    ): void {
      const carrouselProps = richEditorIds === undefined ? undefined : { richEditorIds };
      (prevState.editorData[rowIndex] as CarrouselData).addCarrousel(carrouselProps);
    },
    removeCarrousel(prevState: State, payload: { rowIndex: number; index: number }): void {
      (prevState.editorData[payload.rowIndex] as CarrouselData).removeCarrousel(payload.index);
    },
    sortCarrousel(
      prevState: State,
      payload: { rowIndex: number; oldIndex: number; newIndex: number },
    ): void {
      const carrousel = prevState.editorData[payload.rowIndex] as CarrouselData;
      const target = carrousel.data.contents;
      [target[payload.oldIndex], target[payload.newIndex]] = [
        target[payload.newIndex],
        target[payload.oldIndex],
      ];
    },
  },
  effects: {
    async addCarrouselModule({
      richEditorIds,
    }: {
      richEditorIds?: CardRichEditorIds;
    }): Promise<void> {
      dispatch('addEditorData', {
        data: new CarrouselData({ richEditorIds }),
      });
    },
  },
};
