import throttle from 'lodash/throttle';
import { memo, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { PARAM_RICH_TEXT_FIELD_NAME_INDEX } from 'components/LineMessageEditor/constants';

import type { CardRichEditorIds } from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type {
  CarrouselData,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorState } from 'draft-js';
import type { SVGProps } from 'react';

import { StyledIcon, Text } from 'components';
import { Box, Flex } from 'components/layoutUtils';
import {
  CarouselAddZone,
  CarouselScrollWrapper,
  CarouselThumbnail,
  CarouselThumbnailWrapper,
} from 'components/LineMessageEditor/components/Styled';
import { CardEditorData } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { createRichEditorIdTuple } from 'components/LineMessageEditor/utils/richEditorId';
import { LineMessageHorizontalDrag } from 'icons';
import { EEditorType } from 'lib/validator/helper/validatorHelpers';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';

import { Context } from '../models';
import { CardData } from '../models/templateDataAndTypes/card';

import { CardEditor } from './CardEditor';

interface Props {
  message: CarrouselData;
  rowIndex: number;
  getEditorStateObject: (richEditorId: number) => EditorStateObject;
  createSetEditorStateHandler: (richEditorId: number) => (editorState: EditorState) => void;
  createDeleteEditorStateHandler: (richEditorId: number[]) => () => void;
  onAddCarousel?: (richEditorIds: CardRichEditorIds) => void;
}

const CARD_MARGIN_LEFT = 40;
const ADD_CARD_WIDTH = 83;
const { CardTitle: cardTitleIndex, CardDescription: cardDescriptionIndex } =
  PARAM_RICH_TEXT_FIELD_NAME_INDEX;

const CAROUSEL_CARD_ID_PREFIX = 'carousel-card-';

export const CarrouselEditor = memo((props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const showPagination = props.message.data.contents.length > 1;
  const { dispatch } = useContext(Context);

  return (
    <Box mb={32}>
      <Carrousel
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        showPagination={showPagination}
        {...props}
      />
      {showPagination ? (
        <SortablePagination
          distance={1}
          helperClass="sortableHelper"
          lockAxis="x"
          axis="x"
          onSortEnd={({ oldIndex, newIndex }) => {
            setActiveIndex(newIndex);
            dispatch('sortCarrousel', { rowIndex: props.rowIndex, oldIndex, newIndex });
            scrollToCarouselTarget(newIndex, props.rowIndex);
          }}
          images={props.message.data.contents.map((c) =>
            c.contents.hero ? c.contents.hero.url : '',
          )}
          total={props.message.data.contents.length}
          activeIndex={activeIndex}
          rowIndex={props.rowIndex}
        />
      ) : null}
    </Box>
  );
});

const Carrousel = (
  props: Props & {
    activeIndex: number;
    setActiveIndex: (v: number) => void;
    showPagination: boolean;
  },
) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(Context);
  const { message } = useMessage();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const calcActiveIndex = throttle((e: any) => {
    if (props.showPagination) {
      try {
        props.setActiveIndex(
          Math.round(
            (e.target.scrollLeft / (e.target.scrollWidth - ADD_CARD_WIDTH - CARD_MARGIN_LEFT)) *
              props.message.data.contents.length,
          ),
        );
      } catch (error) {
        props.setActiveIndex(0);
      }
    }
  }, 300);

  return (
    <CarouselScrollWrapper
      onScroll={(e) => {
        e.persist();
        calcActiveIndex(e);
      }}
    >
      {props.message.data.contents.map((card, index) => (
        <Box
          key={index}
          id={`${CAROUSEL_CARD_ID_PREFIX}${props.rowIndex}_${index}`}
          mr={40}
          pt={20}
          position="relative"
        >
          <CardEditor
            rowIndex={props.rowIndex}
            message={
              new CardEditorData({
                actions: [],
                parameters: props.message.parameters,
                data: new CardData({
                  ...card,
                  notification_text: props.message.data.notification_text,
                  richEditorIds: createRichEditorIdTuple(card.format),
                }),
              })
            }
            editorType={EEditorType.CarrouselEditor}
            titleEditorStateObject={props.getEditorStateObject(
              card.format[cardTitleIndex].richEditorId,
            )}
            setTitleEditorState={props.createSetEditorStateHandler(
              card.format[cardTitleIndex].richEditorId,
            )}
            descriptionEditorStateObject={props.getEditorStateObject(
              card.format[cardDescriptionIndex].richEditorId,
            )}
            setDescriptionEditorState={props.createSetEditorStateHandler(
              card.format[cardDescriptionIndex].richEditorId,
            )}
            onDelete={props.createDeleteEditorStateHandler(card.format.map((f) => f.richEditorId))}
            carrouselIndex={index}
          />
        </Box>
      ))}
      <CarouselAddZone
        data-test="add-carousel"
        onClick={() => {
          if (props.message.data.contents.length >= 10) {
            message.warning(t('validation.carrousel.cardAmountLimit'));
            return;
          }
          const richEditorIds = createRichEditorIdTuple();

          dispatch('addCarrousel', { rowIndex: props.rowIndex, richEditorIds });
          if (props.onAddCarousel) {
            props.onAddCarousel(richEditorIds);
          }

          // wait until dispatch
          setTimeout(() => {
            scrollToCarouselTarget(props.message.data.contents.length, props.rowIndex);
          }, 0);
        }}
      >
        +
      </CarouselAddZone>
    </CarouselScrollWrapper>
  );
};

const SortablePagination = SortableContainer(
  (props: { total: number; activeIndex: number; images: string[]; rowIndex: number }) => {
    const arrayFromTotal = useMemo(
      () => Array.from({ length: props.total }, (_, i) => i),
      [props.total],
    );

    return (
      <Flex pt={32} justifyContent="center">
        {arrayFromTotal.map((i) => (
          <SortablePoint
            image={props.images[i]}
            activeIndex={props.activeIndex}
            index={i}
            key={i}
            currentIndex={i}
            rowIndex={props.rowIndex}
          />
        ))}
      </Flex>
    );
  },
);

const SortablePoint = SortableElement(
  (props: { activeIndex: number; currentIndex: number; image: string; rowIndex: number }) => (
    <CarouselThumbnailWrapper
      data-test={`carrousel-pagination-${props.currentIndex}`}
      onClick={() => {
        scrollToCarouselTarget(props.currentIndex, props.rowIndex);
      }}
      key={props.currentIndex}
    >
      <Text fontSize={14} lineHeight="20px" mb="1px" color={theme.colors.neutral005}>
        {props.currentIndex + 1}
      </Text>
      <CarouselThumbnail active={props.currentIndex === props.activeIndex}>
        {!!props.image ? (
          <img
            style={{
              objectFit: 'contain',
            }}
            src={props.image}
            width={24}
            height={24}
            alt=""
          />
        ) : (
          <PointSvg width={24} height={24} />
        )}
      </CarouselThumbnail>
      <StyledIcon
        color={theme.colors.neutral007}
        width={24}
        height={24}
        className="icon-horizontal-drag"
        component={<LineMessageHorizontalDrag />}
        style={{ cursor: 'ew-resize' }}
      />
    </CarouselThumbnailWrapper>
  ),
);

function scrollToCarouselTarget(index: number, rowIndex: number) {
  const target = document.getElementById(`${CAROUSEL_CARD_ID_PREFIX}${rowIndex}_${index}`);
  if (target) {
    window.requestAnimationFrame(() => {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    });
  }
}

const PointSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width="96px" height="96px" viewBox="0 0 96 96" {...props}>
    <path
      d="M60 47.676V12a6 6 0 00-6-6H12a6 6 0 00-6 6v35.676h54zm0 5.838H6V60a6 6 0 006 6h42a6 6 0 006-6v-6.486zM12 0h42c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12H12C5.373 72 0 66.627 0 60V12C0 5.373 5.373 0 12 0z"
      transform="translate(-576 -576) translate(591 588)"
      fill="#c2c6cc"
      fillRule="nonzero"
      stroke="none"
      strokeWidth={1}
    />
  </svg>
);
