/**
 * @description
 * Transform an array with a specific type.
 * @example
 * ```ts
 * const a = toArray(
 *   [1, 3, 5],
 *   String
 * );
 *
 * type A = typeof a;
 * //   ^? type A = string[]
 * ```
 * @description
 * If the input is not an array, it will be wrapped in an array.
 *
 * If you what to transform all values in an array deeply with the same
 * structure with the original value, you can call it recursively:
 * @example
 * ```ts
 * type DeepArrayOrBoolean = boolean | DeepArrayOrBoolean[];
 *
 * function toDeepArray(input: unknown): DeepArrayOrBoolean {
 *   return Array.isArray(v) ? toArr(v, toDeepArray) : Boolean(v);
 * }
 *
 * const result = toDeepArray(original); // type DeepArrayOrBoolean
 * ```
 * @see https://github.com/chatbotgang/Grazioso/pull/2665#pullrequestreview-977902441
 * @param input Input to be parsed.
 * @param fn Transformer.
 * @returns Parsed object.
 */
export function toArray<T>(input: unknown, fn: (input: unknown) => T): T[] {
  const arr = Array.isArray(input) ? input : [input];
  return arr.map(fn);
}

/**
 * @param parser - Parser for each item in the array.
 * @returns An array parser with typed items.
 */
export function array<T>(parser: (input: unknown) => T): (input: unknown) => Array<T> {
  return (input: unknown) => toArray(input, parser);
}
