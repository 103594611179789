import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ImageCarouselContentHeroData,
  ImageCarouselEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import type { LinkParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { ExternalLink } from 'components/ExternalLink';
import { Box } from 'components/layoutUtils/Box';
import {
  isImageCarouselActionCustomUriRule,
  isImageCarouselActionMessageRule,
  isImageCarouselActionUriRule,
  isImageCarouselDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import { Text } from 'components/Typography/Text';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { css } from 'shared/utils/styled/override';
import { theme } from 'theme';

import { CarrouselScrollWrapper } from './Styled';

interface ImageCarouselPreviewProps {
  rowIndex: number;
  message: ImageCarouselEditorData;
  onAddUserMessageText: (text: string) => void;
}

interface ImageCarouselPreviewTooltipTitleProps {
  actionType: ImageCarouselContentHeroData['action']['type'];
  actionText: string;
  imageParamName: string;
}

const ImageCarouselPreviewTooltipTitle = memo(function ImageCarouselPreviewTooltipTitle({
  actionType,
  actionText,
  imageParamName,
}: ImageCarouselPreviewTooltipTitleProps) {
  const { t } = useTranslation();
  const actionAreaText = useMemo(() => {
    return t(`message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea.${actionType}`, {
      actionText,
    });
  }, [t, actionType, actionText]);
  return (
    <>
      {actionText ? (
        <>
          <Text fontWeight={500} fontSize={theme.sizes.textBodyMedium}>
            {t('message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea')}
          </Text>
          <br />
          {actionType === 'uri' ? (
            <ExternalLink
              href={actionText}
              styledCss={css`
                width: 100%;
                white-space: nowrap;
              `}
            >
              {actionText}
            </ExternalLink>
          ) : (
            <Text fontWeight={400} fontSize={theme.sizes.textBodyMedium}>
              {actionAreaText}
            </Text>
          )}
          <br />
        </>
      ) : null}
      {imageParamName ? (
        <>
          <Text fontWeight={500} fontSize={theme.sizes.textBodyMedium} mt="8px">
            {t('message.paramImageEditor.imageProvidedBy')}
          </Text>
          <br />
          <Text fontWeight={400} fontSize={theme.sizes.textBodyMedium}>
            {t(
              'message.paramImageEditor.imageCarrouselPreview.tooltip.imageProvidedBy.apiParameter',
              {
                paramName: imageParamName,
              },
            )}
          </Text>
        </>
      ) : null}
    </>
  );
});
export const ImageCarouselPreview = memo(function ImageCarouselPreview({
  rowIndex,
  message,
  onAddUserMessageText,
}: ImageCarouselPreviewProps) {
  const getImageParamName = useCallback(
    (index: number): string => {
      if (!isImageCarouselDataRule(message)) {
        return '';
      }
      return (
        message.parameters.find(
          (p) => p.mappingKey === message.data.contents[index].hero.contents[0].key,
        )?.key ?? ''
      );
    },
    [message],
  );
  const getActionText = useCallback(
    (action: ImageCarouselContentHeroData['action']) => {
      if (isImageCarouselActionUriRule(action)) {
        const actionText =
          (message.parameters.find((p) => p.key === action.uri) as LinkParameter)?.data?.url ?? '';
        return {
          actionText,
          clickCallback: () => {
            window.open(actionText);
          },
        };
      } else if (isImageCarouselActionMessageRule(action)) {
        return {
          actionText: action.text,
          clickCallback: () => {
            if (action.text) {
              onAddUserMessageText(action.text);
            }
          },
        };
      } else if (isImageCarouselActionCustomUriRule(action)) {
        return {
          actionText: action.uri,
        };
      }
      return {
        actionText: '',
      };
    },
    [message, onAddUserMessageText],
  );
  return (
    <Box mt="16px">
      <CarrouselScrollWrapper>
        {message.data.contents.map((content, index) => {
          const { actionText, clickCallback } = getActionText(content.hero.action);
          return (
            <Box
              mr="20px"
              borderRadius="16px"
              backgroundColor="white"
              key={`ImageCarouselPreview${rowIndex}-${index}`}
              overflow="hidden"
            >
              <Tooltip
                key={'actions' + index}
                trigger="hover"
                title={
                  <TooltipDescription>
                    <ImageCarouselPreviewTooltipTitle
                      actionType={content.hero.action.type}
                      actionText={actionText}
                      imageParamName={getImageParamName(index)}
                    />
                  </TooltipDescription>
                }
                overlayStyle={{ maxWidth: '280px' }}
              >
                {content.hero.contents[0].url ? (
                  <img
                    style={{
                      width: '101%',
                      borderRadius: '16px',
                      cursor: 'pointer',
                      display: 'block',
                    }}
                    src={content.hero.contents[0].url}
                    alt=""
                    onClick={clickCallback}
                    data-test={`image-carousel-preview-local-upload-image-${index}`}
                  />
                ) : null}
              </Tooltip>
            </Box>
          );
        })}
      </CarrouselScrollWrapper>
    </Box>
  );
});
