import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const npsApi = makeApi([
  {
    alias: 'trackingNps',
    method: 'post',
    path: '/system/v1/tracking/nps/',
    parameters: [
      {
        name: 'body',
        schema: z.object({
          product_name: z.string(),
          crm_id: z.string(),
          user: z.string().email(),
          score: z.number().int(),
          feedback: z.string().nullable(),
        }),
        type: 'Body',
      },
    ],
    response: z.never(),
  },
]);
