import Form from 'antd/es/form';
import AntRow from 'antd/es/row';

import { styled } from 'shared/utils/styled';

const { Item: AntItem } = Form;

export const Row = styled(AntRow)`
  margin-bottom: 0;
`;

export const Item = styled(AntItem)`
  margin-bottom: 0;
`;
