import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { SegmentItemSchema } from 'modules/Segment/types/item';

export const segmentApi = makeApi([
  {
    alias: 'segment',
    method: 'get',
    path: '/audience/v2/segment/:segmentId/',
    response: SegmentItemSchema,
  },
  {
    alias: 'segmentUpdate',
    method: 'patch',
    path: '/audience/v2/segment/:segmentId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          bot: z.number().int(),
          name: z.string(),
          description: z.string(),
        }),
      },
    ],
    response: SegmentItemSchema,
  },
]);
