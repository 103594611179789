import type { CardButtonType, CardRichEditorIds } from './templateDataAndTypes/card';
import type { ParamRichTextFieldName } from 'components/LineMessageEditor/constants';
import type { CarrouselData } from 'components/LineMessageEditor/models/templateDataAndTypes/carrousel';
import type { State, Utm } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { CardEditorData } from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import {
  isCardDataRule,
  isCarrouselDataRule,
  isRichTextCustomParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { DecoratorType } from './templateDataAndTypes/DecoratorType';
import {
  textDataParameterCustomData,
  textDataParameterNameData,
} from './templateDataAndTypes/richtext';
import { dispatch } from './models';

interface MaybeCarrousel {
  rowIndex: number;
  carrouselIndex?: number;
}

export const cardStore = {
  reducers: {
    deleteCardButton(state: State, payload: { key: string } & MaybeCarrousel): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        (state.editorData[payload.rowIndex] as CarrouselData).deleteButton(
          payload.key,
          payload.carrouselIndex,
        );
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).deleteButton(payload.key);
      }
    },
    addCardButton(state: State, payload: { rowIndex: number; index?: number }): void {
      if (payload.index !== undefined) {
        (state.editorData[payload.rowIndex] as CarrouselData).addButton(payload.index);
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).addButton();
      }
    },
    onNotificationTextChange(state: State, payload: { rowIndex: number; text: string }): void {
      (state.editorData[payload.rowIndex] as CarrouselData | CardEditorData).setNotificationText(
        payload.text,
      );
    },
    onCardBodyTextChange(
      state: State,
      payload: { bodyIndex: number; value: string } & MaybeCarrousel,
    ): void {
      const target =
        typeof payload.carrouselIndex !== 'undefined'
          ? (state.editorData[payload.rowIndex] as CarrouselData).getCardData(
              payload.carrouselIndex,
            )
          : (state.editorData[payload.rowIndex] as CardEditorData).data;
      target.onBodyTextChange(payload.bodyIndex, payload.value);
    },
    onCardBodyParamTextChange(
      state: State,
      payload: {
        rowIndex: number;
        fieldName: ParamRichTextFieldName;
        type: DecoratorType;
        mappingKey: string;
      } & MaybeCarrousel,
    ): void {
      const target = state.editorData[payload.rowIndex];
      const isTargetCard = isCardDataRule(target);
      const isTargetCarousel = payload.carrouselIndex !== undefined && isCarrouselDataRule(target);
      if (!isTargetCard && !isTargetCarousel) {
        return;
      }

      // Update editor parameters
      if (
        payload.type === DecoratorType.Name &&
        target.parameters.findIndex((p) => p.key === 'name') === -1
      ) {
        target.parameters.push({ ...textDataParameterNameData });
      } else if (payload.type === DecoratorType.Custom) {
        target.parameters.push({ ...textDataParameterCustomData, mappingKey: payload.mappingKey });
      }

      if (payload.type === DecoratorType.Name) {
        return;
      }
      // Update richEditor tagId
      if (isTargetCard || isTargetCarousel) {
        target.format.tagId++;
      }
    },
    onCardBodyCustomParamDecoratorChanged(
      state: State,
      payload: {
        rowIndex: number;
        mappingKey: string;
        key: string;
      },
    ): void {
      const target = state.editorData[payload.rowIndex];
      const isTargetCard = isCardDataRule(target);
      const isTargetCarousel = isCarrouselDataRule(target);
      if (!isTargetCard && !isTargetCarousel) {
        return;
      }
      const parameterIndex = target.parameters.findIndex(
        (p) => p.mappingKey === payload.mappingKey,
      );
      if (parameterIndex === -1 || !isRichTextCustomParameter(target.parameters[parameterIndex])) {
        return;
      }
      target.parameters[parameterIndex].key = payload.key;
    },
    onCardTypeChange(
      state: State,
      payload: {
        type: CardButtonType;
        actionIndex: number;
        previousType: CardButtonType;
      } & MaybeCarrousel,
    ): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        (state.editorData[payload.rowIndex] as CarrouselData).switchFooterActionType(
          payload.actionIndex,
          payload.type,
          payload.carrouselIndex,
          payload.previousType,
        );
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).switchFooterActionType(
          payload.actionIndex,
          payload.type,
          payload.previousType,
        );
      }
    },
    onCardMessageChange(
      state: State,
      payload: MaybeCarrousel & { actionIndex: number; value: string },
    ): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        (state.editorData[payload.rowIndex] as CarrouselData).setMessage(
          payload.carrouselIndex,
          payload.actionIndex,
          payload.value,
        );
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).setMessage(
          payload.actionIndex,
          payload.value,
        );
      }
    },
    onCardTagChange(
      state: State,
      payload: MaybeCarrousel & { tagList: string[]; actionIndex: number },
    ): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        const key = (state.editorData[payload.rowIndex] as CarrouselData).getFooterKey(
          payload.carrouselIndex,
          payload.actionIndex,
        );
        (state.editorData[payload.rowIndex] as CarrouselData).setTag(key, payload.tagList);
      } else {
        const key = (state.editorData[payload.rowIndex] as CardEditorData).getKey(
          payload.actionIndex,
        );
        (state.editorData[payload.rowIndex] as CardEditorData).setTag(key, payload.tagList);
      }
    },
    onCardUriChange(
      state: State,
      payload: MaybeCarrousel & {
        actionIndex: number;
        value: string;
        openExternal: boolean;
        utm: Utm;
      },
    ): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        (state.editorData[payload.rowIndex] as CarrouselData).setUri(
          payload.carrouselIndex,
          payload.actionIndex,
          payload.value,
          payload.openExternal,
          payload.utm,
        );
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).setUri(
          payload.actionIndex,
          payload.value,
          payload.openExternal,
          payload.utm,
        );
      }
    },
    onCardActionLabelChange(
      state: State,
      payload: { actionIndex: number; text: string } & MaybeCarrousel,
    ): void {
      const target =
        typeof payload.carrouselIndex !== 'undefined'
          ? (state.editorData[payload.rowIndex] as CarrouselData).getCardData(
              payload.carrouselIndex,
            )
          : (state.editorData[payload.rowIndex] as CardEditorData).data;
      target.onActionLabelChange(payload.actionIndex, payload.text);
    },
    onCardCustomUriChange(
      state: State,
      payload: MaybeCarrousel & {
        actionIndex: number;
        value: string;
        openExternal: boolean;
        utm: Utm;
      },
    ): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        (state.editorData[payload.rowIndex] as CarrouselData).setCustomUri(
          payload.carrouselIndex,
          payload.actionIndex,
          payload.value,
        );
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).setCustomUri(
          payload.actionIndex,
          payload.value,
        );
      }
    },
    onCardShareButtonChange(
      state: State,
      payload: MaybeCarrousel & {
        actionIndex: number;
        value: string;
      },
    ): void {
      if (typeof payload.carrouselIndex !== 'undefined') {
        (state.editorData[payload.rowIndex] as CarrouselData).setShareButton(
          payload.carrouselIndex,
          payload.actionIndex,
          payload.value,
        );
      } else {
        (state.editorData[payload.rowIndex] as CardEditorData).setShareButton(
          payload.actionIndex,
          payload.value,
        );
      }
    },
  },
  effects: {
    async addCardModule({ richEditorIds }: { richEditorIds: CardRichEditorIds }): Promise<void> {
      dispatch('addEditorData', {
        data: new CardEditorData({ richEditorIds }),
      });
    },
  },
};
