import { z } from 'zod';

export const TagMetadataItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  available_days: z.number().int().nullable(),
});

export type TagMetadataItem = z.output<typeof TagMetadataItemSchema>;

export type TagMetadataRecords = Record<string, TagMetadataItem>;

/**
 * Certain API responses (in Deeplink, Prize, and Widget) also include organization
 * Note: it is `organization_id` instead of `organization` as in the main detail
 */
export const TagMetadataItemWithOrgIdSchema = TagMetadataItemSchema.extend({
  organization_id: z.number().int(),
});

export type TagMetadataItemWithOrgId = z.output<typeof TagMetadataItemWithOrgIdSchema>;
