import { useEffect } from 'react';

import type { MutableRefObject } from 'react';

export function useClickOutside<T>(
  ref: MutableRefObject<T> | null | undefined,
  handler: (e: MouseEvent) => void,
): void {
  useEffect(() => {
    if (ref) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is mouse/touch event
      const listener = (event: any) => {
        // Do nothing if clicking ref's element or descendant elements
        if (ref.current instanceof HTMLElement && event.target) {
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }

          handler(event);
        }
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [ref, handler]);
}
