import { rgba } from 'polished';

import { css, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const RowToolBoxWrapper = styled.span`
  height: 53px;
  width: 16px;
  position: absolute;
  right: -16px;
  top: 0;
  opacity: 0;
  padding-top: 2px;
  transition: 0.25s opacity ease-in-out;
  .editor-delete-icon {
    cursor: pointer;
    margin-left: 4px;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.red006};
      }
    }
  }
  .editor-verticaldrag-icon {
    cursor: pointer;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.blue006};
      }
    }
  }
`;

export const EditorBlock = styled.div`
  border: 2px solid white;
  &:hover {
    border: 2px solid ${theme.colors.blue006};
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
  &.is-error {
    border-color: ${theme.colors.red006} !important;
  }
`;

export const FeedEditor = styled(EditorBlock)`
  :hover {
    border-radius: 0 0 10px 10px;
  }
`;

export const HeadlineEditor = styled(EditorBlock)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 212px;
  :hover {
    border-radius: 10px 10px 0 0;
  }
`;

export const NewsEditor = styled.div`
  overflow: hidden;
  width: 353px;
  margin: 0 auto 32px;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: white;
  user-select: none;
  cursor: pointer;
  .image-editor-icon {
    color: ${theme.colors.neutral006};
    font-size: 20px;
    position: absolute;
    top: 55px;
    left: calc(50% - 10px);
  }

  &.is-drag-mode {
    > div,
    label {
      pointer-events: none;
    }
  }
`;

// TODO: DRY
export const PopupSubTitle = styled.div`
  line-height: 20px;
  margin-bottom: 8px;
  color: ${theme.colors.neutral010};
  font-weight: 500;
`;

export const PopupSubContent = styled.div`
  margin-bottom: 20px;
`;

const uploadedStyle = (url: string) => css`
  height: 104px;
  width: 173px;
  border-radius: 4px;
  background-image: url(${url});
  background-size: cover;
  &:hover ${ImageHoverLayer} {
    opacity: 1;
  }
`;

interface UploadImageLabelProps {
  bgUrl: string;
  type: 'image' | 'imageMap'; // Note: this does not appear to be used
}

export const UploadImageLabel = styled.label.withConfig({
  shouldForwardProp: shouldNotForwardProps(['bgUrl']),
})<UploadImageLabelProps>`
  position: relative;
  display: inline-block;
  background-color: ${theme.colors.neutral003};
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 12px;
  ${({ bgUrl }) => bgUrl !== '' && uploadedStyle(bgUrl)}
  input {
    display: none;
  }
`;

export const ImageHoverLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(theme.colors.neutral009, 0.5)};
  border-radius: 4px;
  transition: opacity 0.35s ease-in-out;
  opacity: 0;
  color: ${theme.colors.white001};
`;

export const ToolBox = styled.div`
  .delete-icon {
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    .delete-icon {
      color: ${theme.colors.red006};
    }
  }
`;

export const HeadlineEditorWrapper = styled.div`
  width: 391px;
  max-height: 450px;
  overflow: scroll;
`;

// TODO: Move Picker and Popover to share components
export const Picker = styled.div`
  display: inline-block;
  margin-left: 5px;
  width: 32px;
  height: 20px;
  border: solid 1px ${theme.colors.neutral003};
  border-radius: 4px;
  background-color: red;
  cursor: pointer;
`;
