import { useCallback } from 'react';
import urlJoin from 'url-join';

import { useCategorizedFilePath } from 'lib/fileUpload/hooks/useCategorizedFilePath';
import { uploadFile } from 'lib/fileUpload/utils/uploadFile';

export const useUpload = (): ((file: File, functionName: string) => Promise<string>) => {
  const filePath = useCategorizedFilePath();

  return useCallback(
    async (file: File, functionName: string) => {
      const uploadedUrl = await uploadFile(file, urlJoin(filePath, functionName));

      return uploadedUrl;
    },
    [filePath],
  );
};
