import { memo } from 'react';

import type { FileInputProps } from 'components/Input/FileInput';
import type { ReactNode } from 'react';
import type { ButtonProps } from 'shared/components/Button';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { FileInput } from 'components/Input/FileInput';
import { Button } from 'shared/components/Button';
import { overrideCss, styled } from 'shared/utils/styled/override';

interface UploadImageButtonProps {
  buttonProps: ButtonProps;
  fileInputProps: FileInputProps & OverrideComponentCssProps;
  children?: ReactNode;
}

const StyledFileInput = styled(FileInput)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  ${overrideCss}
`;

export const UploadImageButton = memo(function UploadImageButton(props: UploadImageButtonProps) {
  return (
    <Button {...props.buttonProps}>
      {props.children}
      <StyledFileInput title="" {...props.fileInputProps} />
    </Button>
  );
});
