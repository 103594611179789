/* eslint-disable import/no-unused-modules -- Unfinished work; will be used when integrating FilterBox into Zodios */
import { z } from 'zod';

import {
  CampaignFilterOperator,
  Category,
  CdpTagFilterOperator,
  CreatedSourceValue,
  EngagementLevelValue,
  FilterOperator,
  GenderOptions,
  PageViewFilterOperator,
  TagFilterOperator,
  TagIntensityFilterOperator,
} from 'modules/FilterBox/types';
import { CdpDataSourceSchema } from 'shared/models/cdp';
import { DayJsSchema } from 'shared/models/common/date';

/**
 * Filter types refactoring strategy:
 * - This file needs to be completely removed
 * - Use types in `shared/models/filters` instead
 */

/**
 * For API data only
 */
const TagFilterSchema = z.object({
  category: z.literal(Category.TAG),
  parameter: z.object({
    op: z.nativeEnum(FilterOperator),
    tag_id: z.number().int(),
  }),
});

/**
 * For UI only
 */
const TagsFilterSchema = z.object({
  category: z.union([z.literal(Category.TAG), z.literal(Category.ANY_TAG)]),
  parameter: z.object({
    op: z.nativeEnum(TagFilterOperator),
    tag_id: z.number().int().array(),
  }),
});

/**
 * For API data only
 */
const AnyTagFilterSchema = z.object({
  category: z.literal(Category.ANY_TAG),
  parameter: z.object({
    op: z.nativeEnum(FilterOperator),
  }),
});

/**
 * For UI only
 */
const EngagementLevelFilterSchema = z.object({
  category: z.literal(Category.ENGAGEMENT_LEVEL),
  parameter: z.object({
    op: z.nativeEnum(FilterOperator),
    engagementLevel: z.nativeEnum(EngagementLevelValue),
  }),
});

const EngagementLevelFilterDataSchema = z.object({
  category: z.literal(Category.ENGAGEMENT_LEVEL),
  parameter: z.object({
    op: z.nativeEnum(FilterOperator),
    health: z.nativeEnum(EngagementLevelValue),
  }),
});

/**
 * Note: we'd rather use ObjectEmptySchema for the parameter but it requires reworking legacy filter types
 */
const JoinTimeFilterSchema = z.object({
  category: z.literal(Category.JOIN_TIME),
  parameter: z.object({}),
});

const LastEngagedFilterSchema = z.object({
  category: z.literal(Category.LAST_ENGAGED),
  parameter: z.object({}),
});

const GenderFilterSchema = z.object({
  category: z.literal(Category.GENDER),
  parameter: z.object({
    gender: z.nativeEnum(GenderOptions),
  }),
});

const BirthdayFilterSchema = z.object({
  category: z.literal(Category.BIRTHDAY),
  parameter: z.object({
    op: z.union([
      z.literal(FilterOperator.GREATER_THAN),
      z.literal(FilterOperator.EQUAL),
      z.literal(FilterOperator.LESS_THAN),
      z.literal(FilterOperator.IN_BETWEEN),
      z.string(),
    ]),
    value1: DayJsSchema.or(z.string()).nullable(),
    value2: DayJsSchema.or(z.string()).nullable().optional(),
  }),
});

const PhoneFilterSchema = z.object({
  category: z.literal(Category.PHONE),
  parameter: z.object({
    op: z.union([z.literal(FilterOperator.HAVE), z.literal(FilterOperator.NOT_HAVE)]),
  }),
});

const EmailFilterSchema = z.object({
  category: z.literal(Category.EMAIL),
  parameter: z.object({
    op: z.union([z.literal(FilterOperator.HAVE), z.literal(FilterOperator.NOT_HAVE)]),
  }),
});

const CustomerIdFilterSchema = z.object({
  category: z.literal(Category.CUSTOMER_ID),
  parameter: z.object({
    op: z.union([z.literal(FilterOperator.HAVE), z.literal(FilterOperator.NOT_HAVE)]),
  }),
});

const PageViewFilterSchema = z.object({
  category: z.literal(Category.PAGE_VIEW),
  parameter: z.object({
    op: z.nativeEnum(PageViewFilterOperator),
    url: z.string(),
  }),
});

const CampaignFilterSchema = z.object({
  category: z.union([
    z.literal(Category.BROADCAST_CLICK),
    z.literal(Category.TRACELINK_CLICK),
    z.literal(Category.CAMPAIGN_CLICK),
  ]),
  parameter: z.object({
    op: z.nativeEnum(CampaignFilterOperator),
    value: z.union([z.number().int(), z.string()]).optional(),
  }),
});

const AverageTransactionRevenueFilterSchema = z.object({
  category: z.literal(Category.AVERAGE_TRANSACTION_REVENUE),
  parameter: z.object({
    op: z.union([
      z.literal(FilterOperator.GREATER_THAN),
      z.literal(FilterOperator.EQUAL),
      z.literal(FilterOperator.LESS_THAN),
    ]),
    value: z.union([z.number().int().positive(), z.string()]),
  }),
});

const AccumulatedRevenueFilterSchema = z.object({
  category: z.literal(Category.ACCUMULATED_REVENUE),
  parameter: z.object({
    op: z.union([
      z.literal(FilterOperator.GREATER_THAN),
      z.literal(FilterOperator.EQUAL),
      z.literal(FilterOperator.LESS_THAN),
    ]),
    value: z.union([z.number().int().positive(), z.string()]),
  }),
});

const CreatedSourceFilterSchema = z.object({
  category: z.literal(Category.CREATED_SOURCE),
  parameter: z.object({
    op: z.union([z.literal(FilterOperator.NOT), z.literal(FilterOperator.ANY)]),
    source: z.nativeEnum(CreatedSourceValue),
  }),
});

const OpenCountFilterSchema = z.object({
  category: z.literal(Category.OPEN_COUNT),
  parameter: z.object({
    op: z.union([
      z.literal(FilterOperator.EQUAL),
      z.literal(FilterOperator.ANY),
      z.literal(FilterOperator.NOT_HAVE),
    ]),
    ref_id: z.union([z.number().int(), z.string()]),
  }),
});

const AutoSegmentFilterSchema = z.object({
  category: z.literal(Category.AUTO_SEGMENT),
  parameter: z.object({
    op: z.literal(FilterOperator.GREATER_THAN),
    top: z.number().int(),
  }),
});

/**
 * For API data only
 */
const TagIntensityFilterDataSchema = z.object({
  category: z.literal(Category.TAG_INTENSITY),
  parameter: z.object({
    op: z.nativeEnum(TagIntensityFilterOperator),
    tag_id: z.number().int(),
    value: z.number().int(),
  }),
});

/**
 * For UI only
 */
const TagIntensityFilterSchema = z.object({
  category: z.literal(Category.TAG_INTENSITY),
  parameter: z.object({
    op: z.nativeEnum(TagIntensityFilterOperator),
    tag_id: z.number().int().array(),
    value: z.number().int(),
  }),
});

/**
 * For API data only
 */
const CdpTagsFilterDataSchema = z.object({
  category: z.literal(Category.CDP_TAG),
  parameter: z.object({
    op: z.nativeEnum(CdpTagFilterOperator),
    source: CdpDataSourceSchema,
    tag_id: z.number().int(), // Note: this is NOT an array in the original typings
  }),
});

/**
 * For UI only
 */
const CdpTagsFilterSchema = z.object({
  category: z.literal(Category.CDP_TAG),
  parameter: z.object({
    op: z.nativeEnum(CdpTagFilterOperator),
    tags: z
      .object({
        source: CdpDataSourceSchema,
        id: z.number().int(),
      })
      .array(),
  }),
});

/**
 * Note: ideally we would use a discriminated union based on category
 * Unfortunately the existing types sometimes overlap, so Zod can't handle it
 */
export const FilterSchema = z.union([
  TagFilterSchema,
  TagsFilterSchema,
  AnyTagFilterSchema,
  EngagementLevelFilterSchema,
  EngagementLevelFilterDataSchema,
  JoinTimeFilterSchema,
  LastEngagedFilterSchema,
  GenderFilterSchema,
  BirthdayFilterSchema,
  PhoneFilterSchema,
  EmailFilterSchema,
  CustomerIdFilterSchema,
  PageViewFilterSchema,
  CampaignFilterSchema,
  AverageTransactionRevenueFilterSchema,
  AccumulatedRevenueFilterSchema,
  CreatedSourceFilterSchema,
  OpenCountFilterSchema,
  AutoSegmentFilterSchema,
  TagIntensityFilterSchema,
  TagIntensityFilterDataSchema,
  CdpTagsFilterSchema,
  CdpTagsFilterDataSchema,
]);

export type Filter = z.output<typeof FilterSchema>;
