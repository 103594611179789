import { z } from 'zod';

export const JourneyNodeCategoryEnum = {
  Trigger: 'trigger',
  Rule: 'rule',
  Action: 'action',
} as const;

const JourneyNodeCategorySchema = z.nativeEnum(JourneyNodeCategoryEnum);

export type JourneyNodeCategory = z.output<typeof JourneyNodeCategorySchema>;

export const JourneyNodeTriggerTypeEnum = {
  Empty: 'empty',
  TagAdded: 'tag_added',
  GaPageView: 'ga_page_view',
  GaAddToCart: 'ga_add_to_cart',
  GaPurchase: 'ga_purchase',
  LineMessageOpened: 'line_message_opened',
} as const;

const JourneyNodeTriggerTypeSchema = z.nativeEnum(JourneyNodeTriggerTypeEnum);

export type JourneyNodeTriggerType = z.output<typeof JourneyNodeTriggerTypeSchema>;

export const JourneyNodeRuleTypeEnum = {
  Exit: 'exit',
  TimeDelay: 'time_delay',
  TimeSchedule: 'time_schedule',
  YesNoBranch: 'yes_no_branch',
} as const;

const JourneyNodeRuleTypeSchema = z.nativeEnum(JourneyNodeRuleTypeEnum);

export type JourneyNodeRuleType = z.output<typeof JourneyNodeRuleTypeSchema>;

export const JourneyNodeActionTypeEnum = {
  SendLineMessage: 'send_line_message',
} as const;

const JourneyNodeActionTypeSchema = z.nativeEnum(JourneyNodeActionTypeEnum);

export type JourneyNodeActionType = z.output<typeof JourneyNodeActionTypeSchema>;

export const JourneyNodeTypeEnum = {
  ...JourneyNodeTriggerTypeEnum,
  ...JourneyNodeRuleTypeEnum,
  ...JourneyNodeActionTypeEnum,
} as const;

export const JourneyNodeTypeSchema = z.nativeEnum(JourneyNodeTypeEnum);

export type JourneyNodeType = z.infer<typeof JourneyNodeTypeSchema>;

export const JourneyNodeOpEnum = {
  With: 'with',
  Without: 'without',
  WithAny: 'with_any',
  WithoutAny: 'without_any',
} as const;

export const JourneyNodeOpSchema = z.nativeEnum(JourneyNodeOpEnum);

export type JourneyNodeOpType = z.infer<typeof JourneyNodeOpSchema>;
