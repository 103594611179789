import { MESSAGE_MODULE_TYPE } from 'shared/components/Editor/LineMessageEditor/constants';

import type {
  ImagemapAction,
  ImagemapFlexModule,
  ImagemapTrigger,
} from 'shared/components/Editor/LineMessageEditor/types';

export const IMAGEMAP_UI_IMAGE_WIDTH = 349;

export const defaultImagemapMessageAction: ImagemapAction = {
  type: 'message',
  key: 'message01',
  text: '',
  area: {
    x: 0,
    y: 0,
    width: 1040,
    height: 1040,
  },
};

export const defaultImagemapTriggerMessageAction: ImagemapTrigger = {
  key: 'message01',
  trigger_type: 'message',
  trigger_code: '',
  data: {
    type: 'standard',
    function: 'tag',
    tag_list: [],
  },
};

export const defaultImagemapFlexModule: ImagemapFlexModule = {
  module_id: MESSAGE_MODULE_TYPE.imagemapFlex,
  data: {
    notification_text: '',
    base_url: '',
    animated: false,
    base_size: {
      width: 1040,
      height: 1040,
    },
    actions: [defaultImagemapMessageAction],
  },
  actions: [defaultImagemapTriggerMessageAction],
  parameters: [],
  quick_reply: { items: [] },
  // UI related field
  activeBlockIndex: 1,
  isOpen: false,
  isCustom: false,
  format: {
    tagId: 1,
    isCustomBlock: false,
    /** only image with aspect ratio 1/3 and no alpha value in any pixel will be true */
    isFlex: true,
  },
};
