import type { ValidatorState } from './context';

import * as ActionTypes from './actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function reducer(state: ValidatorState, action: any): ValidatorState {
  switch (action.type) {
    case ActionTypes.REGISTERED_FORM_ELEMENT: {
      return {
        ...state,
        components: [...state.components, { id: action.id, rules: action.rules }],
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    }
    case ActionTypes.FORM_ELEMENT_UPDATED: {
      return {
        ...state,
        values: {
          ...state.values,
          [action.id]: action.value,
        },
      };
    }
    case ActionTypes.UNREGISTERED_FORM_ELEMENT: {
      return {
        ...state,
        invalid: {
          ...state.invalid,
          [action.id]: undefined,
        },
        components: state.components.filter((c) => c.id !== action.id),
      };
    }
    case ActionTypes.FORM_VALIDATION: {
      return {
        ...state,
        invalid: {
          ...state.invalid,
          [action.id]: {
            valid: action.valid,
            errorMessage: action.errorMessage,
          },
        },
      };
    }
    case ActionTypes.FORM_VALIDATION_RESULT: {
      return { ...state, invalid: action.invalid };
    }
    default: {
      return state;
    }
  }
}
