import { Key } from 'ts-key-enum';

import { createComboKeys } from 'utils/event/createComboKeys';

export function registerComboKeys(fn: () => void): void {
  createComboKeys({
    combo: [
      Key.ArrowUp,
      Key.ArrowUp,
      Key.ArrowDown,
      Key.ArrowDown,
      Key.ArrowLeft,
      Key.ArrowRight,
      Key.ArrowLeft,
      Key.ArrowRight,
      'b',
      'a',
    ],
    handler: fn,
    timeoutDuration: 1000,
  });
}
