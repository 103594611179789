import { z } from 'zod';

import { DayJsSchema } from 'shared/models/common/date';

/**
 * Filter category
 */
export const FilterCategoryEnum = {
  TAG: 1,
  ENGAGEMENT_LEVEL: 2,
  JOIN_TIME: 3,
  LAST_ENGAGED: 4,
  GENDER: 5,
  PAGE_VIEW: 6,
  BROADCAST_CLICK: 7,
  TRACELINK_CLICK: 8,
  AVERAGE_TRANSACTION_REVENUE: 9,
  ACCUMULATED_REVENUE: 10,
  BIRTHDAY: 13,
  CUSTOMER_ID: 14,
  PHONE: 15,
  EMAIL: 16,
  CAMPAIGN_CLICK: 17,
  ANY_TAG: 19,
  CREATED_SOURCE: 20,
  OPEN_COUNT: 21,
  AUTO_SEGMENT: 22,
  TAG_INTENSITY: 23,
  CDP_TAG: 24,
  CUSTOM: 99 /** Default value */,
} as const;

export const FilterCategorySchema = z.nativeEnum(FilterCategoryEnum);

export type FilterCategoryValue = z.output<typeof FilterCategorySchema>;

/**
 * These are operators used within individual filters
 * Take "EQUAL" in a tag filter for example; it means filtering the members with specific tag
 */
export const FilterOperatorEnum = {
  EQUAL: 1,
  NOT: 2,
  ANY: 3,
  GREATER_THAN: 4,
  LESS_THAN: 5,
  TOP: 6,
  IN_BETWEEN: 7,
  CONTAINED: 8,
  NOT_CONTAINED: 9,
  HAVE: 10,
  NOT_HAVE: 11,
} as const;

export const FilterOperatorSchema = z.nativeEnum(FilterOperatorEnum);

export type FilterOperatorValue = z.output<typeof FilterOperatorSchema>;

/**
 * Operator (between filters)
 */
export const FilterGroupOperatorEnum = {
  /** Intersection case */
  AND: 1,
  /** Union case */
  OR: 2,
} as const;

export const FilterGroupOperatorSchema = z.nativeEnum(FilterGroupOperatorEnum);

export type FilterGroupOperatorValue = z.output<typeof FilterGroupOperatorSchema>;

/**
 * Operator (between segments)
 */
export const SegmentGroupOperatorEnum = {
  /** Intersection case */
  AND: 1,
  /** Union case */
  OR: 2,
  /** None */
  NONE: 3,
} as const;

export const SegmentGroupOperatorSchema = z.nativeEnum(SegmentGroupOperatorEnum);

export type SegmentGroupOperatorValue = z.output<typeof SegmentGroupOperatorSchema>;

/**
 * Duration type
 */
export const DurationOperatorEnum = {
  /** All time */
  ALL_DURATION: 1,
  /** In the past time */
  ACTIVE_DURATION: 2,
  /** Specified time period */
  FIX_DURATION: 3,
  /** No duration specified */
  NO_DURATION: 4,
} as const;

export const DurationOperatorSchema = z.nativeEnum(DurationOperatorEnum);

export type DurationOperatorValue = z.output<typeof DurationOperatorSchema>;

/**
 * Duration
 */
export const DurationDataSchema = z.object({
  days: z.number().int(),
  start_date: DayJsSchema,
  end_date: DayJsSchema,
});

export type DurationDataValue = z.output<typeof DurationDataSchema>;

/**
 * Time period
 */
export const TimePeriodOptionsEnum = {
  A_WEEK: 7,
  TWO_WEEKS: 15,
  A_MONTH: 30,
  THREE_MONTHS: 90,
} as const;

export const EngagementLevelValueEnum = {
  LEVEL_5: 'level_5',
  LEVEL_4: 'level_4',
  LEVEL_3: 'healthy',
  LEVEL_2: 'normal',
  LEVEL_1: 'weak',
} as const;

export const CampaignFilterOperatorEnum = {
  EQUAL_BROADCAST: '1-7',
  EQUAL_TRACELINK: '1-8',
  EQUAL_DPM: '1-18', // Not implemented and might change in the future (old comment)
  ANY_CAMPAIGN: '3-17',
  NOT_ANY_CAMPAIGN: '2-17',
} as const;

export const CdpTagFilterOperatorEnum = {
  EQUAL: FilterOperatorEnum.EQUAL,
  NOT: FilterOperatorEnum.NOT,
} as const;

const CdpTagFilterOperatorSchema = z.nativeEnum(CdpTagFilterOperatorEnum);

export type CdpTagFilterOperatorValue = z.output<typeof CdpTagFilterOperatorSchema>;

export const CreatedSourceValueEnum = {
  BIND_LINK: 'bind_link',
  PNP: 'pnp',
} as const;

export const GenderOptionsEnum = {
  UNKNOWN: 'unknown',
  MALE: 'male',
  FEMALE: 'female',
} as const;

export const PageViewFilterOperatorEnum = {
  EQUAL: FilterOperatorEnum.EQUAL,
  NOT: FilterOperatorEnum.NOT,
  CONTAINED: FilterOperatorEnum.CONTAINED,
  NOT_CONTAINED: FilterOperatorEnum.NOT_CONTAINED,
} as const;

export const TagIntensityFilterOperatorEnum = {
  GREATER_THAN: FilterOperatorEnum.GREATER_THAN,
  NOT_HAVE: FilterOperatorEnum.NOT_HAVE,
  ANY: FilterOperatorEnum.ANY,
  NOT: FilterOperatorEnum.NOT,
} as const;

export const BirthDateFilterOperatorEnum = {
  EQUAL_MONTH_DAY: '1-MD',
  // cspell:disable-next-line - special data value
  IN_BETWEEN_MONTH_DAY: '7-MDMD',
} as const;

const AutoSegmentPossibilitySchema = z.union([z.literal(0), z.literal(30), z.literal(50)]);

export type AutoSegmentPossibilityValue = z.infer<typeof AutoSegmentPossibilitySchema>;
