import { memo } from 'react';

import { useHandler } from 'hooks/useEventCallback';
import { useDevModeStore } from 'layout/DevMode/useDevModeStore';
import { Modal } from 'shared/components/Modal';

import { registerClicks } from './register/registerClicks';
import { registerComboKeys } from './register/registerComboKeys';
import { registerCommand } from './register/registerCommand';
import { Content } from './Content';
import { FeatureEnabledHint } from './FeatureEnabledHint';

registerComboKeys(useDevModeStore.getState().open);
registerClicks(useDevModeStore.getState().open);
registerCommand(useDevModeStore);

export const DevMode = memo(function DevMode() {
  const { opened, close } = useDevModeStore(
    useHandler(({ opened, close }) => ({
      opened,
      close,
    })),
  );
  return (
    <>
      <Modal
        open={opened}
        maskClosable={false}
        destroyOnClose={true}
        onCancel={close}
        footer={null}
      >
        <Content />
      </Modal>
      <FeatureEnabledHint />
    </>
  );
});
