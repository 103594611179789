import Tag from 'antd/es/tag';

import { IMAGE_MAP_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type { ReactNode } from 'react';
import type { HeightProps } from 'styled-system';

import { Box } from 'components/layoutUtils';
import { flexCenter, hideScrollBar } from 'components/style-helper';
import { css, keyframes, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

import { TextFocusKitWrapper } from './RichTextEditor/RichTextFocusKit';

export const LineEditorWrapper = styled.div`
  display: inline-block;
  width: 433px;
  min-height: 700px;
  height: auto;
  border-radius: 8px;
  box-sizing: border-box;
`;

export const LineEditorHeader = styled.div`
  position: relative;
  padding: 18px 40px;
  min-height: 56px;
  background-color: ${theme.colors.neutral002};
  border-radius: 8px 8px 0 0;
  box-sizing: border-box;
`;

export const RowToolBoxWrapper = styled.span`
  height: 53px;
  width: 16px;
  position: absolute;
  right: -16px;
  top: 0;
  opacity: 0;
  padding-top: 2px;
  transition: 0.25s opacity ease-in-out;
  .editor-delete-icon {
    cursor: pointer;
    margin-left: 4px;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.red006};
      }
    }
  }
  .editor-verticaldrag-icon {
    cursor: pointer;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.blue006};
      }
    }
  }
`;

export const LineEditorContent = styled.div`
  min-height: calc(700px - 56px);
  height: auto;
  border-radius: 0 0 8px 8px;
  padding-top: 24px;
  box-sizing: border-box;
  background-color: ${theme.colors.neutral001};
  overflow: hidden;
`;

export const TextWrapper = styled.div`
  position: relative;
  left: 40px;
  width: calc(100% - 80px);
  min-height: 53px;
  height: auto;
  background-color: white;
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 32px;
  border-radius: 4px;
  > div.DraftEditor-root {
    height: auto;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${theme.colors.neutral004};
    line-height: 1.5;
    font-size: 14px;
    [data-block] {
      margin-bottom: 2px;
    }
  }
  &.is-error {
    > div {
      border-color: ${theme.colors.red006};
    }
    ${TextFocusKitWrapper} {
      position: relative;
      top: -20px;
      > span {
        margin-bottom: -4px;
      }
    }
  }
  &.is-drag-mode {
    > div,
    > span:nth-child(2) {
      pointer-events: none;
    }
  }
  z-index: 999;
  &:hover {
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
`;

export const ReferralTextWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 53px;
  height: auto;
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
  > div {
    height: auto;
    padding: 7px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid ${theme.colors.neutral004};
    line-height: 1.5;
    [data-block] {
      margin-bottom: 2px;
    }
  }
  z-index: 999;
  &:hover {
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
`;

interface TextFocusKitProps {
  isFocus: boolean;
}

export const TextFocusKit = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isFocus']),
})<TextFocusKitProps>`
  position: absolute;
  top: calc(100% - 4px);
  right: 8px;
  width: auto;
  height: 26px;
  border-radius: 4px;
  border: none;
  padding: 0;
  background-color: ${theme.colors.neutral009};
  box-sizing: border-box;
  opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
  transform: ${({ isFocus }) => (isFocus ? 'translateY(0px)' : 'translateY(10px)')};
  pointer-events: ${({ isFocus }) => (isFocus ? 'auto' : 'none')};
  display: flex;
  transition:
    0.25s opacity ease-in-out,
    0.25s transform ease-in-out;
  .toolkit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 5px;
    cursor: pointer;
    color: ${theme.colors.white001};
    transition: 0.25s color ease-in-out;
    path {
      fill: ${theme.colors.white001};
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      color: ${theme.colors.blue005};
      path {
        fill: ${theme.colors.blue005};
      }
    }
  }
`;

const IMAGE_WRAPPER_PADDING = 8;

interface ImageWrapperProps extends HeightProps {
  flatBorderBottom?: boolean;
}

export const ImageWrapper = styled(Box).withConfig({
  shouldForwardProp: shouldNotForwardProps(['flatBorderBottom']),
})<ImageWrapperProps>`
  position: relative;
  border-radius: ${({ flatBorderBottom = false }) => (flatBorderBottom ? '10px 10px 0 0' : '10px')};
  box-sizing: border-box;
  background-color: white;
  user-select: none;
  cursor: pointer;
  display: flex;
  padding: ${IMAGE_WRAPPER_PADDING}px;
  justify-content: center;
  align-items: center;
  transition:
    border 0.25s ease-in-out,
    height 0.55s ease-in-out;
  .image-editor-icon {
    color: ${theme.colors.neutral006};
    font-size: 20px;
    position: absolute;
    top: 55px;
    left: calc(50% - 10px);
  }
  &:hover {
    border-color: ${theme.colors.blue006};
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
  &.is-error {
    border-color: ${theme.colors.red006} !important;
  }
  &.is-drag-mode {
    > div,
    label {
      pointer-events: none;
    }
  }
`;

ImageWrapper.defaultProps = {
  border: `2px solid ${theme.colors.neutral004}`,
  width: '353px',
  height: '353px',
  mx: 'auto',
  mb: 32,
};

export const ImageCoverWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 56, 72, 0.8);
  z-index: 200;
  opacity: 0;
  transition: 0.45s opacity ease-in-out;
  border-radius: 8px;
  &:hover {
    opacity: 1;
    ${RowToolBoxWrapper} {
      opacity: 1;
    }
  }
`;

export const ImageMapScaledImage = styled.img`
  width: ${IMAGE_MAP_UI_IMAGE_WIDTH}px;
  height: auto;
  border-radius: 8px;
`;

export const ImageMapCarouselScaledImage = styled.img`
  width: calc(100% + ${IMAGE_WRAPPER_PADDING * 2}px);
  height: auto;
  border-radius: 8px;
`;

interface ImageMapCustomIconProps {
  isSmall?: boolean;
}

export const ImageMapCustomIcon = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isSmall']),
})<ImageMapCustomIconProps>`
  display: inline-block;
  width: 55px;
  height: 55px;
  box-sizing: border-box;
  vertical-align: top;
  border: 2px solid ${theme.colors.neutral004};
  color: ${theme.colors.neutral004};
  font-size: 13px;
  cursor: pointer;
  transition:
    border 0.25s ease-in-out 0s,
    color 0.25s ease-in-out 0s;
  text-align: center;

  ${({ isSmall }) =>
    isSmall
      ? css`
          width: 61px;
          height: 24px;
          line-height: 20px;
        `
      : null}

  &.is-active,
  &:hover {
    border: 2px solid ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }
`;
export const ImageMapCustomBlocks = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  vertical-align: top;
  border: 2px solid ${theme.colors.neutral004};
  color: ${theme.colors.neutral004};
  font-size: 13px;
  margin: 0 12px 12px 0;
  cursor: pointer;
  transition:
    border 0.25s ease-in-out 0s,
    color 0.25s ease-in-out 0s;
  &.is-active,
  &:hover {
    border: 2px solid ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }
  &.is-error {
    border-color: ${theme.colors.red006};
    color: ${theme.colors.red006};
  }
  line-height: 30px;
  text-align: center;
`;

interface UploadImageLabelProps {
  bgUrl: string;
  type: 'image' | 'imageMap';
  flatBorderBottom?: boolean;
}

export const UploadImageLabel = styled.label.withConfig({
  shouldForwardProp: shouldNotForwardProps(['bgUrl', 'type', 'flatBorderBottom']),
})<UploadImageLabelProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 100;
  background-image: ${({ bgUrl }) => (bgUrl === '' ? 'none' : `url(${bgUrl})`)};
  background-size: ${({ type }) => (type === 'image' ? 'cover' : 'contain')};
  background-repeat: no-repeat;
  border-radius: ${({ flatBorderBottom = false }) => (flatBorderBottom ? '8px 8px 0 0' : '8px')};
  background-position: center center;
  cursor: pointer;
  input[type='file'] {
    display: none;
  }
`;

const popupErrorAni = keyframes`
0% {
    transform: translateX(3px);
  }
  15% {
    transform: translateX(-3px);
  }
  30% {
    transform: translateX(3px);
  }
  45% {
    transform: translateX(-3px);
  }
  60% {
    transform: translateX(3px);
  }
  75% {
    transform: translateX(-3px);
  }
  90% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const ImageMapPopoverContent = styled.div`
  position: relative;
  border-radius: 10px;
  max-height: 540px;
  width: 374px;
  padding: 16px 27px 16px 16px;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: ${theme.colors.white001};
  box-shadow: 0 1px 5px 0 ${theme.colors.neutral004};
  &.is-error {
    animation: ${popupErrorAni} 0.4s linear forwards;
  }
`;

export const PrizeRedeemPopoverContent = styled.div`
  width: 374px;
  padding: 16px 27px 16px 16px;
  background-color: ${theme.colors.white001};
  border-radius: 10px;
  box-shadow: 0 1px 5px 0 ${theme.colors.neutral004};
`;

export const PopOverFieldsContent = styled.div`
  /* display: flex; */
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 8px;
  margin-bottom: 16px;
  .imagemap-icon {
    cursor: pointer;
    #cells-stroke-layer {
      transition: stroke 0.25s ease-in-out 0s;
    }
    #cells-fill-layer,
    #cells-text-layer {
      transition: fill 0.25s ease-in-out 0s;
    }
    &.is-active {
      #cells-stroke-layer {
        stroke: ${theme.colors.blue006};
      }
      #cells-fill-layer,
      #cells-text-layer {
        fill: ${theme.colors.blue006};
      }
    }
    &.is-error {
      #cells-stroke-layer {
        stroke: ${theme.colors.red006};
      }
      #cells-fill-layer,
      #cells-text-layer {
        fill: ${theme.colors.red006};
      }
    }
    &:hover {
      #cells-stroke-layer {
        stroke: ${theme.colors.blue006};
      }
      #cells-fill-layer,
      #cells-text-layer {
        fill: ${theme.colors.blue006};
      }
    }
  }
`;

export const ImageHoverBoxIconWrapper = styled.label`
  color: ${theme.colors.neutral003};
  text-align: center;
  cursor: pointer;
  display: block;
  margin: 10px;
  input[type='file'] {
    display: none;
  }
  &:hover {
    color: ${theme.colors.blue004};
  }
`;

export const PopOverFieldTitle = ({ children }: { children: ReactNode }) => (
  <Box margin="10px 0" fontWeight={600} fontSize={14} color={theme.colors.neutral010}>
    {children}
  </Box>
);

export const ImageMapScaledVideo = styled.video`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

export const FileNameTag = styled(Tag)`
  display: inline-flex;
  align-items: center;
  background-color: ${theme.colors.neutral002};
  border-color: ${theme.colors.neutral002};
  > span {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const VideoCoverUploadLabel = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  input {
    display: none;
  }
`;

export const CarouselScrollWrapper = styled.div`
  position: relative;
  margin-left: 40px;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  > div {
    scroll-snap-align: start;
  }

  ${hideScrollBar};
`;

interface CarouselAddZoneProps {
  height?: number;
}

export const CarouselAddZone = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['height']),
})<CarouselAddZoneProps>`
  border: 1px dashed ${theme.colors.neutral005};
  border-right: none;
  background-color: ${theme.colors.white001};
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: 83px;
  display: flex;
  font-size: 50px;
  font-weight: 300;
  color: ${theme.colors.neutral005};
  transition: all 0.2s ease-in;
  height: ${({ height }) => (height === undefined ? 'auto' : `${height}px`)};
  cursor: pointer;
  :hover {
    border-color: ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }

  ${flexCenter};
`;

interface CarouselThumbnailProps {
  active?: boolean;
}

export const CarouselThumbnail = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['active']),
})<CarouselThumbnailProps>`
  width: 32px;
  height: 36px;
  border-radius: 4px;
  background-color: ${theme.colors.white001};
  margin-left: 5px;
  margin-right: 5px;
  border: ${({ active }) => (active ? `solid 2px ${theme.colors.blue006}` : 'none')};
  cursor: pointer;
  display: flex;
  ${flexCenter};
`;

export const CarouselThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-horizontal-drag {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  :hover {
    .icon-horizontal-drag {
      opacity: 1;
    }
  }
`;
