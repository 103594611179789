import { forwardRef } from '@chatbotgang/etude/react/forwardRef';
import { Children, useRef, useState } from 'react';

import type { FlexPreviewProps } from './types';
import type { ComponentPropsWithRef, CSSProperties } from 'react';

import { useReplacedFlexContent } from 'components/LineMessageEditor/hooks/useReplacedFlexContent';
import { useEnhancedEffect } from 'hooks/useEnhancedEffect';

import { CarrouselScrollWrapper } from '../Styled';

import { Bubble } from './components';
import { PropsProvider } from './context';
import { scaleRatio } from './types';

type FlexPreviewComponentProps = FlexPreviewProps & Omit<ComponentPropsWithRef<'div'>, 'children'>;

/**
 * Only supports 'FlexBubble' now
 */
export const FlexPreview = forwardRef<HTMLDivElement, FlexPreviewComponentProps>(
  function FlexPreview({ rowIndex, onAddUserMessageText, message, ...restProps }, ref) {
    const scrollWrapperRef = useRef<HTMLDivElement>(null);
    const [wrapperMarginBottom, setWrapperMarginBottom] = useState(0);
    const replacedContents = useReplacedFlexContent(message, ['prize']);

    const style: CSSProperties = {
      transform: `scaleY(${scaleRatio})`,
      transformOrigin: 'top left',
      marginBottom: `${wrapperMarginBottom}px`,
    };

    // is first content size not equal 'giga'
    if (replacedContents.length > 0 && replacedContents[0].size !== 'giga') {
      style['paddingLeft'] = '5px';
    }

    useEnhancedEffect(() => {
      if (scrollWrapperRef.current) {
        setWrapperMarginBottom(scrollWrapperRef.current.offsetHeight * -(1 - scaleRatio));
      }
    }, []);

    return (
      <div style={{ marginTop: 16 }} ref={ref} {...restProps}>
        <PropsProvider value={{ onAddUserMessageText, rowIndex, message }}>
          <CarrouselScrollWrapper ref={scrollWrapperRef} style={style}>
            {Children.toArray(
              replacedContents.map((content, index) => <Bubble bubble={content} index={index} />),
            )}
          </CarrouselScrollWrapper>
        </PropsProvider>
      </div>
    );
  },
);
