import type { PrizeRedeemData } from '../models/templateDataAndTypes';
import type { FlexBox, FlexBubble, FlexText } from '@line/bot-sdk';

import { prizeNameKey } from 'components/LineMessageEditor/models/templateDataAndTypes/prizeRedeem';

export const replacePrizeRedeemWithKey = (
  content: FlexBubble,
  message: PrizeRedeemData,
): FlexBubble => {
  const prizeTarget = content.body?.contents[0] as FlexBox;
  const targetText = (prizeTarget.contents[0] as FlexText).text;

  if (targetText === prizeNameKey) {
    const value = message.format.prizeName;
    const rest = content.body?.contents.slice(1) ?? [];

    return {
      ...content,
      body: {
        ...content.body,
        contents: [
          {
            ...prizeTarget,
            contents: [{ ...prizeTarget.contents[0], text: value }],
          },
          ...rest,
        ],
      },
    } as FlexBubble;
  }
  return content;
};
