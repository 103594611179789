import type { CustomParameter, EditorDataType, LinkParameter } from './types';
import type { FlexImage, MessageAction, URIAction } from '@line/bot-sdk';
import type { QuickReplyItems } from 'components/LineMessageEditor/components/QuickReplyEditor';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';

export type CustomUriAction = {
  type: 'customUri';
  key: string;
  uri: string;
};

interface ImageCarouselContentHeroImage extends Omit<FlexImage, 'aspectRatio'> {
  aspectRatio: FlexImage['aspectRatio'] | string;
  key: string;
}

export interface ImageCarouselContentHeroData {
  type: string;
  layout: string;
  contents: [ImageCarouselContentHeroImage];
  action: MessageAction | URIAction | CustomUriAction;
}

export interface ImageCarouselContentData {
  type: string;
  direction: string;
  hero: ImageCarouselContentHeroData;
}

export interface ImageCarouselEditorData {
  module_id: LineFieldType.ImageCarousel;
  data: {
    notification_text: string;
    contents: Array<ImageCarouselContentData>;
  };
  parameters: Array<CustomParameter | LinkParameter>;
  quick_reply?: {
    items: QuickReplyItems;
  };
}

export function isImageCarouselDataRule(node: EditorDataType): node is ImageCarouselEditorData {
  return node.module_id === LineFieldType.ImageCarousel;
}

export function isImageCarouselActionMessageRule(
  node: ImageCarouselContentHeroData['action'],
): node is MessageAction {
  return node.type === 'message';
}

export function isImageCarouselActionUriRule(
  node: ImageCarouselContentHeroData['action'],
): node is URIAction {
  return node.type === 'uri';
}

export function isImageCarouselActionCustomUriRule(
  node: ImageCarouselContentHeroData['action'],
): node is CustomUriAction {
  return node.type === 'customUri';
}

export function createImageCarouselContent(): ImageCarouselContentData {
  return {
    type: 'bubble',
    direction: 'ltr',
    hero: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'image',
          url: '',
          key: '',
          size: 'full',
          aspectRatio: '1:1',
          aspectMode: 'cover',
          animated: false,
        },
      ],
      action: {
        type: 'message',
        label: 'action',
        text: '',
      } as ImageCarouselContentHeroData['action'],
    },
  };
}

export const initialData: ImageCarouselEditorData = {
  module_id: LineFieldType.ImageCarousel,
  data: {
    notification_text: '',
    contents: [createImageCarouselContent()],
  },
  parameters: [],
  quick_reply: { items: [] },
};
