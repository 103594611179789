import { useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Trans } from 'react-i18next';

import { IMAGE_MAP_CUSTOM_BLOCK_CLASSNAME, MAAC_SCROLL_CONTENT_ID } from 'AppConstants';

import type { ImageCustomBlockEditorProps } from './types';
import type { ReactPortal, SyntheticEvent } from 'react';

import { Box, StyledIcon, Text } from 'components';
import { useEnhancedEffect } from 'hooks/useEnhancedEffect';
import { DeleteSvg } from 'icons/delete';
import { Button } from 'shared/components/Button';
import { theme } from 'theme';

import { useBlockMove } from '../hooks/useBlockMove';
import { useCheckCustomBlockData } from '../hooks/useCheckCustomBlockData';
import { useDragNewBlock } from '../hooks/useDragNewBlock';

import { MxNLayoutEditor } from './MxNLayoutEditor';
import * as S from './Styled';

function getSrcImgData(img: HTMLImageElement): {
  top: number;
  left: number;
  width: number;
  height: number;
};
function getSrcImgData(img: null): undefined;
function getSrcImgData(img: HTMLImageElement | null):
  | {
      top: number;
      left: number;
      width: number;
      height: number;
    }
  | undefined;
function getSrcImgData(img: HTMLImageElement | null):
  | {
      top: number;
      left: number;
      width: number;
      height: number;
    }
  | undefined {
  if (!img) {
    return undefined;
  }
  const rect: DOMRect = img.getBoundingClientRect();

  return {
    top: rect.top - S.uiBorderWidth,
    left: rect.left - S.uiBorderWidth,
    width: rect.width + S.uiBorderWidth * 2,
    height: rect.height + S.uiBorderWidth * 2,
  };
}

const CustomBlockEditorBox = ({
  blocks,
  imageRef,
  maxBlockCount,
  onClose,
}: ImageCustomBlockEditorProps) => {
  /**
   * FIXME: This is not reactive. Should observe the element instead.
   */
  const rectData = useMemo(() => getSrcImgData(imageRef.current), [imageRef]);
  const [isMxNShow, setIsMxNShow] = useState<boolean>(false);
  const imageMapCustomWrapperRef = useRef<HTMLDivElement>(null);
  const outerScrollLayerRef = useRef<HTMLElement | null>();
  const outerScrollTopInit = useRef<number>();
  const { isDragging, previewBlocks, onMouseDown, onMouseUpOrLeave, setPreviewBlocks } =
    useDragNewBlock(imageMapCustomWrapperRef, blocks, maxBlockCount!, rectData!);
  const getBlocks = useCheckCustomBlockData(previewBlocks, {
    ...rectData!,
    width: rectData!.width - S.uiBorderWidth * 2,
    height: rectData!.height - S.uiBorderWidth * 2,
  });
  const { onBlockMouseUpOrLeave, onBlockMouseDown, isBlockDragging, movingIndex } = useBlockMove(
    imageMapCustomWrapperRef,
    previewBlocks,
    setPreviewBlocks,
    rectData!,
  );

  useEnhancedEffect(() => {
    const target = document.getElementById(MAAC_SCROLL_CONTENT_ID);
    outerScrollLayerRef.current = target;
    outerScrollTopInit.current = target?.scrollTop ?? 0;
  }, []);

  if (!rectData) {
    return null;
  }
  return (
    <S.ImageMapCustomWrapper
      className={IMAGE_MAP_CUSTOM_BLOCK_CLASSNAME}
      ref={imageMapCustomWrapperRef}
      onScroll={(e: SyntheticEvent<HTMLDivElement>) => {
        const top = e.currentTarget.scrollTop;
        if (outerScrollLayerRef.current) {
          outerScrollLayerRef.current.scrollTop = (outerScrollTopInit.current ?? 0) + top;
        }
      }}
    >
      <S.ImageWrapper
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUpOrLeave}
        onMouseLeave={onMouseUpOrLeave}
        {...rectData}
        position="absolute"
        backgroundSize="cover"
        backgroundImage={`url(${imageRef.current?.src})`}
        style={{ cursor: previewBlocks.length < maxBlockCount! ? 'crosshair' : 'auto' }}
      >
        {previewBlocks.map((block, index) => {
          return (
            <S.PreviewBlock
              key={'drag_block' + index}
              style={{
                position: 'absolute',
                width: block.width,
                height: block.height,
                top: 0,
                left: 0,
                transform: `translateX(${block.left}px) translateY(${block.top}px)`,
                pointerEvents:
                  isDragging || (isBlockDragging && movingIndex !== index) ? 'none' : 'auto',
              }}
              onMouseDown={(e) => {
                onBlockMouseDown(e, index);
              }}
              onMouseUp={onBlockMouseUpOrLeave}
              onMouseLeave={onBlockMouseUpOrLeave}
            >
              <Box
                position="absolute"
                top={0}
                left={0}
                width={500}
                height={500}
                background="rgba(254, 223, 237,0)"
                zIndex={0}
                style={{
                  transform: `translateX(${block.width / 2 - 250}px) translateY(${
                    block.height / 2 - 250
                  }px)`,
                  pointerEvents: isBlockDragging && movingIndex === index ? 'auto' : 'none',
                }}
              />

              <Box
                position="absolute"
                top={-2}
                right={-21}
                width={21}
                height={21}
                zIndex={20}
                backgroundColor={theme.colors.white001}
                borderRadius="4px"
                className="imageMap-delete-icon-wrapper"
                style={{
                  boxShadow: theme.boxShadow.default,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPreviewBlocks(previewBlocks.filter((d) => d.id !== block.id));
                }}
              >
                <StyledIcon
                  pl="2px"
                  fontSize={17}
                  width={21}
                  height={21}
                  component={<DeleteSvg />}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </S.PreviewBlock>
          );
        })}
        <MxNLayoutEditor
          open={isMxNShow}
          onOpenChange={setIsMxNShow}
          setBlocks={setPreviewBlocks}
          rectData={rectData}
          maxBlockCount={maxBlockCount}
        />
      </S.ImageWrapper>
      <S.FinishWrapper
        position="absolute"
        top={rectData.top + rectData.height + 15}
        width={rectData.width}
        left={rectData.left}
      >
        <Box mb="8px">
          <Trans i18nKey="message.imageMap.draggingHint">
            <Text
              color={theme.colors.blue006}
              cursor="pointer"
              onClick={() => {
                setIsMxNShow(!isMxNShow);
              }}
            >
              or use area gadget
            </Text>
          </Trans>
        </Box>
        <Box textAlign="right">
          <Button
            type="primary"
            onClick={() => {
              onClose(getBlocks());
            }}
          >
            <Trans i18nKey="glossary.done" />
          </Button>
        </Box>
      </S.FinishWrapper>
    </S.ImageMapCustomWrapper>
  );
};

CustomBlockEditorBox.defaultProps = {
  maxBlockCount: 20,
};

export const CustomBlockEditor = (props: ImageCustomBlockEditorProps): ReactPortal =>
  ReactDOM.createPortal(<CustomBlockEditorBox {...props} />, document.body);
