import { useTranslation } from 'react-i18next';

import type {
  ImagemapAction,
  ImagemapModule,
} from 'shared/components/Editor/LineMessageEditor/types';

import {
  isCustomParameter,
  isCustomParameterWithLink,
  isStandardParameter,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { transformKey } from 'shared/components/Editor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const IMAGEMAP_PREVIEW_IMAGE_WIDTH = 242;

const ImagemapPreviewContainer = styled.div`
  position: relative;
  border-radius: 10px;
  max-width: ${IMAGEMAP_PREVIEW_IMAGE_WIDTH}px;
`;

const ImagemapImg = styled.img`
  margin-bottom: 8px;
  width: 100%;
  height: auto;
  border-radius: 6px;
`;

const ImagemapActionsBlock = styled.div`
  position: absolute;
  background-color: ${theme.colors.neutral003};
  border: 1px dashed ${theme.colors.neutral005};
  transition: 0.5s background-color ease-in-out;
  cursor: pointer;
  opacity: 0;
  &:hover {
    opacity: 0.7;
  }
`;

export const LineMessageEditorImagemapModulePreview = ({
  message,
}: {
  message: ImagemapModule;
}) => {
  const { t } = useTranslation();

  const src = message.data.base_url;

  if (src === '') return null;

  const getPreviewTooltipTitle = (action: ImagemapAction) => {
    if (action.type === 'message') {
      return `${t('message.send')} : ${action.text}`;
    }
    if (action.type === 'uri' && action.linkUri.includes('url')) {
      const uriParameter = message.parameters.find(
        (parameter) =>
          isStandardParameter(parameter) && parameter.key === transformKey(action.linkUri),
      );
      const url =
        uriParameter && isCustomParameterWithLink(uriParameter.data) ? uriParameter.data.url : '';
      return `${t('common.openUrl')} : ${url}`;
    }
    if (action.type === 'uri' && action.linkUri.includes('customUri')) {
      const customUriParameter = message.parameters.find(
        (parameter) =>
          isCustomParameter(parameter) && parameter.mappingKey === transformKey(action.linkUri),
      );
      const key = customUriParameter?.key ?? '';
      return `${t('message.apiUrl')} : ${key}`;
    }
    return '';
  };

  const getUrl = (action: ImagemapAction) => {
    if (action.type === 'uri' && action.linkUri.includes('url')) {
      const uriParameter = message.parameters.find(
        (parameter) =>
          isStandardParameter(parameter) && parameter.key === transformKey(action.linkUri),
      );
      return uriParameter && isCustomParameterWithLink(uriParameter.data)
        ? uriParameter.data.url
        : '';
    }
    return '';
  };

  return (
    <ImagemapPreviewContainer
      style={{
        height:
          message.data.base_size.height !== 1040
            ? `calc( ${
                (message.data.base_size.height / message.data.base_size.width) *
                IMAGEMAP_PREVIEW_IMAGE_WIDTH
              }px  )`
            : undefined,
      }}
    >
      <ImagemapImg src={src} />
      {message.data.actions.map((action, index) => (
        <Tooltip
          key={index}
          trigger="hover"
          title={<TooltipDescription>{getPreviewTooltipTitle(action)}</TooltipDescription>}
        >
          <ImagemapActionsBlock
            onClick={() => {
              const url = getUrl(action);
              if (action.type === 'uri' && url) {
                window.open(url);
              }
            }}
            style={{
              top: (action.area.y / 1040) * IMAGEMAP_PREVIEW_IMAGE_WIDTH,
              left: (action.area.x / 1040) * IMAGEMAP_PREVIEW_IMAGE_WIDTH,
              width: (action.area.width / 1040) * IMAGEMAP_PREVIEW_IMAGE_WIDTH,
              height: (action.area.height / 1040) * IMAGEMAP_PREVIEW_IMAGE_WIDTH,
            }}
          />
        </Tooltip>
      ))}
    </ImagemapPreviewContainer>
  );
};
