import GatewayOutlined from '@ant-design/icons/GatewayOutlined';
import cn from 'classnames';
import { useCallback, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { MAAC_SCROLL_CONTENT_ID } from 'AppConstants';

import type { Dispatch, SetStateAction } from 'react';

import { Box, StyledIcon } from 'components';
import { Context } from 'components/LineMessageEditor/models';
import { SubText } from 'components/Typography/Text';
import {
  Cell1,
  Cell1Of1,
  Cells4,
  Cells4Of1,
  Cells4Of2,
  Cells4Of3,
  Cells4Of4,
  Cells6,
  Cells6Of1,
  Cells6Of2,
  Cells6Of3,
  Cells6Of4,
  Cells6Of5,
  Cells6Of6,
  Cells8,
  Cells8WithIndex,
} from 'icons';
import { SvgIconRichmenuS1Cell1 } from 'icons/richmenu/IconRichmenuS1Cell1';
import { SvgIconRichmenuS2Cell1 } from 'icons/richmenu/IconRichmenuS2Cell1';
import { SvgIconRichmenuS2Cell2 } from 'icons/richmenu/IconRichmenuS2Cell2';
import { SvgIconRichmenuS3Cell1 } from 'icons/richmenu/IconRichmenuS3Cell1';
import { SvgIconRichmenuS3Cell2 } from 'icons/richmenu/IconRichmenuS3Cell2';
import { SvgIconRichmenuS3Cell3 } from 'icons/richmenu/IconRichmenuS3Cell3';
import { SvgRichmenuSLayout1 } from 'icons/richmenu/RichmenuSLayout1';
import { SvgRichmenuSLayout2 } from 'icons/richmenu/RichmenuSLayout2';
import { SvgRichmenuSLayout3 } from 'icons/richmenu/RichmenuSLayout3';
import { Button } from 'shared/components/Button';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import { MessageConflictNotice } from '../MessageConflictNotice';
import * as S from '../Styled';

interface Props {
  size?: 'full' | 'compact';
  activeBlockIndex: number;
  setCustomEditorShow: Dispatch<SetStateAction<boolean>>;
  setImageMapActions: (value: number) => void;
  onIsCustomChange: () => void;
  setImageMapActiveBlock: (value: number) => void;
  isCustom?: boolean;
  actionCount: number;
  rowIndex?: number;
  actionError: boolean[];
  isRichmenu: boolean;
  carouselIndex: number;
}

const layoutIcons = (isRichMenu: boolean) => {
  const block8Icon = isRichMenu
    ? []
    : [
        {
          value: 8,
          component: <Cells8 />,
        },
      ];
  return [
    {
      value: 1,
      component: <Cell1 />,
    },
    {
      value: 4,
      component: <Cells4 />,
    },
    {
      value: 6,
      component: <Cells6 />,
    },
    ...block8Icon,
  ];
};

const smallLayoutIcons = [
  {
    value: 1,
    component: <SvgRichmenuSLayout1 />,
  },
  {
    value: 2,
    component: <SvgRichmenuSLayout2 />,
  },
  {
    value: 3,
    component: <SvgRichmenuSLayout3 />,
  },
];

const richmenu3Actions = [
  <SvgIconRichmenuS3Cell1 key="cell-1" />,
  <SvgIconRichmenuS3Cell2 key="cell-2" />,
  <SvgIconRichmenuS3Cell3 key="cell-3" />,
];
const richmenu2Actions = [
  <SvgIconRichmenuS2Cell1 key="cell-1" />,
  <SvgIconRichmenuS2Cell2 key="cell-2" />,
];
const action4 = [
  {
    value: 1,
    component: <Cells4Of1 />,
  },
  {
    value: 2,
    component: <Cells4Of2 />,
  },
  {
    value: 3,
    component: <Cells4Of3 />,
  },
  {
    value: 4,
    component: <Cells4Of4 />,
  },
];

const action6 = [
  {
    value: 1,
    component: <Cells6Of1 />,
  },
  {
    value: 2,
    component: <Cells6Of2 />,
  },
  {
    value: 3,
    component: <Cells6Of3 />,
  },
  {
    value: 4,
    component: <Cells6Of4 />,
  },
  {
    value: 5,
    component: <Cells6Of5 />,
  },
  {
    value: 6,
    component: <Cells6Of6 />,
  },
];

const action8 = [1, 2, 3, 4, 5, 6, 7, 8].map((d) => {
  return {
    value: d,
    component: Cells8WithIndex(d),
  };
});

export function ImageMapPopupSelection(props: Props) {
  const layout = props.size === 'full' ? layoutIcons(props.isRichmenu) : smallLayoutIcons;
  const layoutWidth = props.size === 'full' ? 55 : 61;
  const scrollLayerScrollToTop = useCallback(() => {
    const scrollLayer = document.getElementById(MAAC_SCROLL_CONTENT_ID);
    if (scrollLayer) scrollLayer.scrollTop = 0;
  }, []);
  const { t } = useTranslation();
  const { store } = useContext(Context);

  return (
    <>
      <S.PopOverFieldTitle>
        <Trans i18nKey="glossary.layout" />
      </S.PopOverFieldTitle>
      <S.PopOverFieldsContent>
        {layout.map((icon) => (
          <StyledIcon
            className={cn('imagemap-icon', {
              'is-active': props.actionCount === icon.value && !props.isCustom,
            })}
            color={
              props.actionCount === icon.value && !props.isCustom
                ? theme.colors.blue006
                : theme.colors.neutral004
            }
            key={icon.value}
            p={0}
            mr={16}
            fontSize={64}
            height={layoutWidth}
            width={layoutWidth}
            lineHeight="0px"
            display="inline-block"
            component={icon.component}
            onClick={() => {
              props.setImageMapActions(icon.value);
            }}
            marginBottom={16}
            data-test={`imagemap-settings-${icon.value}-action-area-${props.carouselIndex}`}
          />
        ))}
        <S.ImageMapCustomIcon
          isSmall={props.size === 'compact'}
          className={cn({ 'is-active': props.isCustom })}
          onClick={() => {
            scrollLayerScrollToTop();
            props.onIsCustomChange();
            props.setCustomEditorShow(true);
          }}
        >
          <Trans i18nKey="common.custom" />
        </S.ImageMapCustomIcon>
      </S.PopOverFieldsContent>
      {props.isCustom ? (
        <S.PopOverFieldsContent>
          <Button
            onClick={() => {
              scrollLayerScrollToTop();
              props.setCustomEditorShow(true);
            }}
          >
            <GatewayOutlined />{' '}
            <Trans
              i18nKey="common.editModule"
              values={{ module: lowerFirst(t('glossary.actionArea')) }}
            />
          </Button>
        </S.PopOverFieldsContent>
      ) : null}
      {props.actionCount > 0 ? (
        <>
          <S.PopOverFieldTitle>
            <Trans i18nKey="glossary.actionArea" />
          </S.PopOverFieldTitle>
          <SubText>{t('message.actionAreaHint')}</SubText>
          {store.hasShareableConflict ? (
            <Box my="16px">
              <MessageConflictNotice />
            </Box>
          ) : null}
        </>
      ) : null}
      <S.PopOverFieldsContent>
        {props.actionCount === 1 && !props.isCustom && props.size === 'full' ? (
          <StyledIcon
            className={cn('imagemap-icon', {
              'is-active': props.activeBlockIndex === 1,
            })}
            height={55}
            display="inline-block"
            width={55}
            component={<Cell1Of1 />}
            onClick={() => props.setImageMapActiveBlock(1)}
            data-test={`imagemap-settings-1-action-block-${props.carouselIndex}`}
          />
        ) : null}
        {props.size === 'compact' && !props.isCustom ? (
          <>
            {Array.from(Array(props.actionCount)).map((_, i) => (
              <StyledIcon
                key={i}
                className={cn('imagemap-icon', {
                  'is-active': props.activeBlockIndex === i + 1,
                })}
                fontSize={45}
                width={55}
                height={55}
                display="inline-block"
                color={
                  props.activeBlockIndex === i + 1 ? theme.colors.blue006 : theme.colors.neutral004
                }
                component={
                  props.actionCount === 2 ? (
                    richmenu2Actions[i]
                  ) : props.actionCount === 3 ? (
                    richmenu3Actions[i]
                  ) : (
                    <SvgIconRichmenuS1Cell1 />
                  )
                }
                onClick={() => props.setImageMapActiveBlock(i + 1)}
              />
            ))}
          </>
        ) : null}
        {props.actionCount === 4 && !props.isCustom ? (
          <>
            {action4.map((icon, index) => {
              return (
                <StyledIcon
                  key={icon.value}
                  className={cn('imagemap-icon', {
                    'is-active': props.activeBlockIndex === icon.value,
                    'is-error': props.actionError[index],
                  })}
                  height={55}
                  width={55}
                  display="inline-block"
                  component={icon.component}
                  onClick={() => {
                    props.setImageMapActiveBlock(icon.value);
                  }}
                />
              );
            })}
          </>
        ) : null}
        {props.actionCount === 6 && !props.isCustom ? (
          <>
            {action6.map((icon, index) => (
              <StyledIcon
                key={icon.value}
                className={cn('imagemap-icon', {
                  'is-active': props.activeBlockIndex === icon.value,
                  'is-error': props.actionError[index],
                })}
                height={55}
                width={55}
                display="inline-block"
                component={icon.component}
                onClick={() => {
                  props.setImageMapActiveBlock(icon.value);
                }}
              />
            ))}
          </>
        ) : null}
        {props.actionCount === 8 && !props.isCustom ? (
          <>
            {action8.map((icon, index) => (
              <Box
                key={icon.value}
                className={cn('imagemap-icon', {
                  'is-active': props.activeBlockIndex === icon.value,
                  'is-error': props.actionError[index],
                })}
                height={55}
                width={55}
                display="inline-block"
                onClick={() => {
                  props.setImageMapActiveBlock(icon.value);
                }}
              >
                {icon.component}
              </Box>
            ))}
          </>
        ) : null}
        {props.isCustom
          ? Array.from(Array(props.actionCount)).map((_, index) => {
              return (
                <S.ImageMapCustomBlocks
                  key={'customBlock' + index}
                  className={cn({
                    'is-active': props.activeBlockIndex === index + 1,
                    'is-error': props.actionError[index],
                  })}
                  onClick={() => {
                    props.setImageMapActiveBlock(index + 1);
                  }}
                >
                  {index + 1}
                </S.ImageMapCustomBlocks>
              );
            })
          : null}
      </S.PopOverFieldsContent>
    </>
  );
}

ImageMapPopupSelection.defaultProps = {
  rowIndex: 0,
  size: 'full',
  actionError: [],
  isRichmenu: false,
};
