import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import {
  isCardDataRule,
  isCarrouselDataRule,
  isImageCarouselDataRule,
  isImageMapCarouselDataRule,
  isImageMapDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

/**
 * Check how many animated files in one editor module
 *
 * @param editorData - Editor data of from each editor module
 * @returns the count of animated file
 */
export const countAPNGFileInEditorModule = (editorData: EditorDataType): number => {
  if (isImageMapDataRule(editorData)) {
    return editorData.data.animated ? 1 : 0;
  } else if (isImageMapCarouselDataRule(editorData)) {
    return editorData.data.contents.contents.reduce((prev, curr) => {
      if (curr.body.contents[0].animated) {
        prev++;
      }
      return prev;
    }, 0);
  } else if (isCardDataRule(editorData)) {
    return !!editorData.data.contents.hero?.animated ? 1 : 0;
  } else if (isCarrouselDataRule(editorData)) {
    return editorData.data.contents.reduce((prev, curr) => {
      if (!!curr.contents.hero?.animated) {
        prev++;
      }
      return prev;
    }, 0);
  } else if (isImageCarouselDataRule(editorData)) {
    return editorData.data.contents.reduce((prev, curr) => {
      if (curr.hero.contents[0].animated) {
        prev++;
      }
      return prev;
    }, 0);
  }

  return 0;
};
