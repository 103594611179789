import { IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type { ImageMapCustomPreviewBlock } from './templateDataAndTypes/imageMap';
import type { ImageMapCarousel } from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import type {
  LinkParameterData,
  State,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import {
  createImageMapCarouselCustomUrlParameter,
  createImageMapCarouselUrlParameter,
  defaultImageMapCarouselActionBlock,
  defaultImageMapCarouselItem,
  ImageMapCarouselActions,
  initialData,
  isImageMapCarouselCustomUriAction,
  isImageMapCarouselMessageAction,
  isImageMapCarouselUriAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import {
  isCustomParameter,
  isImageMapCarouselDataRule,
  isLinkParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import {
  createImageMapCarouselActionBlock,
  createImageMapCarouselPredefinedBlocks,
  parseWidthAndHeight,
} from 'components/LineMessageEditor/utils/imageMapCarousel';
import { generateMappingKey } from 'components/LineMessageEditor/utils/transformKey';

import { dispatch } from './models';

const filterOutCustomParameterByKey = (
  key: string,
  parameters: ImageMapCarousel['parameters'],
): ImageMapCarousel['parameters'] => {
  if (key === '') {
    return parameters;
  }
  return parameters.filter((p) => (isCustomParameter(p) ? p.mappingKey !== key : true));
};

const filterOutLinkParameterByKey = (
  key: string,
  parameters: ImageMapCarousel['parameters'],
): ImageMapCarousel['parameters'] => {
  if (key === '') {
    return parameters;
  }
  return parameters.filter((p) => (isLinkParameter(p) ? p.key !== key : true));
};

const pruneParameters = (message: ImageMapCarousel) => {
  const carouselItemKeys = message.data.contents.contents
    .map((item) => {
      const [_, ...blocks] = item.body.contents;
      return blocks.map(({ action }) => {
        if (isImageMapCarouselCustomUriAction(action) || isImageMapCarouselUriAction(action)) {
          return action.key;
        }

        return undefined;
      });
    })
    .flat()
    .filter(Boolean) as string[];

  message.parameters = message.parameters.filter(
    (p) => carouselItemKeys.includes(p.key) || carouselItemKeys.includes(p.mappingKey ?? ''),
  );
};

export const imageMapCarouselStore = {
  reducers: {
    setImageMapCarouselImageUrl(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; url: string; resetContent: boolean },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      message.data.contents.contents[payload.carouselIndex].body.contents[0].url = payload.url;

      /*
       * Since the image size might change, we don't know how to remap all the action blocks to the new image, we need to reset all action blocks here.
       * Clear all action blocks and add a default block and preserve the first item - the imagemap image.
       */
      if (payload.resetContent) {
        message.data.contents.contents[payload.carouselIndex].body.contents = [
          message.data.contents.contents[payload.carouselIndex].body.contents[0],
          defaultImageMapCarouselActionBlock,
        ];

        message.activeBlockIndices[payload.carouselIndex] = 1;
        message.customBlocks[payload.carouselIndex] = false;

        /*
         * Parameters are linked to action blocks.
         * Since we reset all action blocks, we need to prune the obsolete parameters.
         */
        pruneParameters(message);
      }
    },
    /**
     * We store original image width and height in the aspect ratio field, eg. "1920:1080" means the original image size is 1920x1080.
     * We use this information to calculate the image layout width, image layout height, and block size, block position in the editor UI.
     *
     * @param prevState - previous state
     * @param payload - payload
     */
    setImageMapCarouselImageAspectRatio(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; aspectRatio: string },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      message.data.contents.contents[payload.carouselIndex].body.contents[0].aspectRatio =
        payload.aspectRatio;
    },
    setImageMapCarouselImageAnimated(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; isAnimated: boolean },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      message.data.contents.contents[payload.carouselIndex].body.contents[0].animated =
        payload.isAnimated;
    },
    setImageMapCarouselPredefinedBlocks(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; count: number },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const originalSize = parseWidthAndHeight(
        message.data.contents.contents[payload.carouselIndex].body.contents[0].aspectRatio ?? '1:1',
      );

      const actionBlocks = createImageMapCarouselPredefinedBlocks(originalSize, payload.count);

      // We use new action blocks to override the old ones.
      message.data.contents.contents[payload.carouselIndex].body.contents = [
        message.data.contents.contents[payload.carouselIndex].body.contents[0],
        ...actionBlocks,
      ];

      message.activeBlockIndices[payload.carouselIndex] = 1;
      message.customBlocks[payload.carouselIndex] = false;

      /*
       * Parameters are linked to action blocks.
       * Since we reset all action blocks, we need to prune the obsolete parameters.
       */
      pruneParameters(message);
    },
    chooseImageMapCarouselCustomBlockMode(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; isCustom: true },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      /*
       * Start over the action blocks with custom block mode, we clear all existing action blocks and preserve the first item - the imagemap image.
       * For UI purposes, we don't create any action block and let the users create their own.
       */
      message.data.contents.contents[payload.carouselIndex].body.contents = [
        message.data.contents.contents[payload.carouselIndex].body.contents[0],
      ];

      message.customBlocks[payload.carouselIndex] = payload.isCustom;
      message.activeBlockIndices[payload.carouselIndex] = 1;

      /*
       * Parameters are linked to action blocks.
       * Since we reset all action blocks, we need to prune the obsolete parameters.
       */
      pruneParameters(message);
    },
    setImageMapCarouselCustomBlocks(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        previewBlocks: ImageMapCustomPreviewBlock[];
      },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const { width: originalImageWidth, height: originalImageHeight } = parseWidthAndHeight(
        message.data.contents.contents[payload.carouselIndex].body.contents[0].aspectRatio ?? '1:1',
      );

      const aspectRatioValue = originalImageWidth / originalImageHeight;

      const actionBlocks = payload.previewBlocks.map((block) => {
        return createImageMapCarouselActionBlock(
          { width: originalImageWidth, height: originalImageHeight },
          {
            width: (block.width / IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH) * originalImageWidth,
            height:
              (block.height / (IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH / aspectRatioValue)) *
              originalImageHeight,
            x: (block.left / IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH) * originalImageWidth,
            y:
              (block.top / (IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH / aspectRatioValue)) *
              originalImageHeight,
          },
        );
      });

      message.data.contents.contents[payload.carouselIndex].body.contents = [
        message.data.contents.contents[payload.carouselIndex].body.contents[0],
        ...actionBlocks,
      ];

      /*
       * Parameters are linked to action blocks.
       * Since we reset all action blocks, we need to prune the obsolete parameters.
       */
      pruneParameters(message);
    },
    /**
     * Change the action type of the action block.
     * Need to filter out the obsolete parameters if the action type is changed from URI or custom URI action to message action.
     *
     * @param prevState - previous state
     * @param payload - payload
     */
    setImageMapCarouselActionType(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        type: ImageMapCarouselActions;
      },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const activeBlockIndex = message.activeBlockIndices[payload.carouselIndex];
      const targetActionBlock =
        message.data.contents.contents[payload.carouselIndex].body.contents[activeBlockIndex];

      if (payload.type === 'message') {
        let previousActionParameterKey = '';

        if (
          isImageMapCarouselCustomUriAction(targetActionBlock.action) ||
          isImageMapCarouselUriAction(targetActionBlock.action)
        ) {
          previousActionParameterKey = targetActionBlock.action.key;
        }

        message.parameters = filterOutCustomParameterByKey(
          previousActionParameterKey,
          message.parameters,
        );

        message.parameters = filterOutLinkParameterByKey(
          previousActionParameterKey,
          message.parameters,
        );

        targetActionBlock.action = {
          type: 'message',
          text: '',
        };
      } else if (payload.type === 'uri') {
        let previousActionParameterKey = '';

        if (isImageMapCarouselCustomUriAction(targetActionBlock.action)) {
          previousActionParameterKey = targetActionBlock.action.key;
        }

        message.parameters = filterOutCustomParameterByKey(
          previousActionParameterKey,
          message.parameters,
        );

        const newActionParameterKey = generateMappingKey('standardLink');

        targetActionBlock.action = {
          type: 'uri',
          uri: '',
          key: newActionParameterKey,
        };
        message.parameters.push(createImageMapCarouselUrlParameter({ key: newActionParameterKey }));
      } else if (payload.type === 'customUri') {
        let previousActionParameterKey = '';

        if (isImageMapCarouselUriAction(targetActionBlock.action)) {
          previousActionParameterKey = targetActionBlock.action.key;
        }

        message.parameters = filterOutLinkParameterByKey(
          previousActionParameterKey,
          message.parameters,
        );

        const newActionParameterKey = generateMappingKey('customUri');

        targetActionBlock.action = {
          type: ImageMapCarouselActions.customUri,
          uri: '',
          key: newActionParameterKey,
        };
        message.parameters.push(
          createImageMapCarouselCustomUrlParameter({
            mappingKey: newActionParameterKey,
          }),
        );
      }
    },
    setImageMapCarouselActiveBlock(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; activeIndex: number },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      message.activeBlockIndices[payload.carouselIndex] = payload.activeIndex;
    },
    setImageMapCarouselActiveBlockMessageData(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        text: string;
      },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const activeBlockIndex = message.activeBlockIndices[payload.carouselIndex];
      const targetAction =
        message.data.contents.contents[payload.carouselIndex].body.contents[activeBlockIndex]
          .action;

      if (isImageMapCarouselMessageAction(targetAction)) {
        targetAction.text = payload.text;
      }
    },
    setImageMapCarouselActiveBlockUrlData(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        type: keyof Omit<LinkParameterData, 'function'>;
        text?: string;
        openExternalBrowser?: boolean;
        tagList?: string[];
      },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const activeBlockIndex = message.activeBlockIndices[payload.carouselIndex];
      const activeBlockAction =
        message.data.contents.contents[payload.carouselIndex].body.contents[activeBlockIndex]
          .action;

      if (isImageMapCarouselUriAction(activeBlockAction)) {
        const key = activeBlockAction.key;
        const targetParameter = message.parameters.find((d) => d.key === key);
        if (targetParameter && isLinkParameter(targetParameter)) {
          switch (payload.type) {
            case 'open_external_browser':
              targetParameter.data[payload.type] = Boolean(payload.openExternalBrowser);
              break;
            case 'tag_list':
              targetParameter.data[payload.type] = payload.tagList ?? [];
              break;
            default:
              targetParameter.data[payload.type] = payload.text ?? '';
              break;
          }
        }
      }
    },
    setImageMapCarouselActiveBlockCustomUrlData(
      preState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        text: string;
      },
    ): void {
      const message = preState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const activeBlockIndex = message.activeBlockIndices[payload.carouselIndex];
      const activeBlockAction =
        message.data.contents.contents[payload.carouselIndex].body.contents[activeBlockIndex]
          .action;

      if (isImageMapCarouselCustomUriAction(activeBlockAction)) {
        const key = activeBlockAction.key;
        const targetParameter = message.parameters.find((d) => d.mappingKey === key);
        if (targetParameter && isCustomParameter(targetParameter)) {
          targetParameter.key = payload.text;
        }
      }
    },
    setImageMapCarouselNotificationText(
      prevState: State,
      payload: { rowIndex: number; text: string },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      message.data.notification_text = payload.text;
    },
    addImageMapCarouselItem(prevState: State, payload: { rowIndex: number }): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      message.data.contents.contents.push(defaultImageMapCarouselItem);
      message.activeBlockIndices.push(1);
      message.customBlocks.push(false);
    },
    deleteImageMapCarouselItem(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      if (message.data.contents.contents.length === 1) {
        prevState.editorData = prevState.editorData.filter(
          (_, index) => index !== payload.rowIndex,
        );
      } else {
        message.data.contents.contents = message.data.contents.contents.filter(
          (_, index) => index !== payload.carouselIndex,
        );

        // Remember to remove the index in the activeBlocks and customBlocks of the deleted carousel item
        message.activeBlockIndices = message.activeBlockIndices.filter(
          (_, index) => index !== payload.carouselIndex,
        );
        message.customBlocks = message.customBlocks.filter(
          (_, index) => index !== payload.carouselIndex,
        );

        // Prune the obsolete parameters which were linked to action blocks in the deleted carousel
        pruneParameters(message);
      }
    },
    sortImageMapCarouselItem(
      prevState: State,
      payload: { rowIndex: number; newCarouselIndex: number; oldCarouselIndex: number },
    ): void {
      const message = prevState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapCarouselDataRule(message)) return;

      const contents = message.data.contents.contents;

      [contents[payload.oldCarouselIndex], contents[payload.newCarouselIndex]] = [
        contents[payload.newCarouselIndex],
        contents[payload.oldCarouselIndex],
      ];

      // "activeBlocks" and "customBlocks" should be sorted in the same way as the carousel items since they are linked to the carousel items
      [
        message.activeBlockIndices[payload.oldCarouselIndex],
        message.activeBlockIndices[payload.newCarouselIndex],
      ] = [
        message.activeBlockIndices[payload.newCarouselIndex],
        message.activeBlockIndices[payload.oldCarouselIndex],
      ];
      [
        message.customBlocks[payload.oldCarouselIndex],
        message.customBlocks[payload.newCarouselIndex],
      ] = [
        message.customBlocks[payload.newCarouselIndex],
        message.customBlocks[payload.oldCarouselIndex],
      ];
    },
  },
  effects: {
    async addImageMapCarouselModule(): Promise<void> {
      dispatch('addEditorData', {
        data: initialData,
      });
    },
  },
};
