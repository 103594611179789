import type { DOMAttributes } from 'react';

export const stopPropagationBundle = {
  onCopy: (e) => e.stopPropagation(),
  onCut: (e) => e.stopPropagation(),
  onPaste: (e) => e.stopPropagation(),
  onCompositionEnd: (e) => e.stopPropagation(),
  onCompositionStart: (e) => e.stopPropagation(),
  onCompositionUpdate: (e) => e.stopPropagation(),
  onFocus: (e) => e.stopPropagation(),
  onBlur: (e) => e.stopPropagation(),
  onChange: (e) => e.stopPropagation(),
  onBeforeInput: (e) => e.stopPropagation(),
  onInput: (e) => e.stopPropagation(),
  onReset: (e) => e.stopPropagation(),
  onSubmit: (e) => e.stopPropagation(),
  onInvalid: (e) => e.stopPropagation(),
  onLoad: (e) => e.stopPropagation(),
  onError: (e) => e.stopPropagation(), // also a Media Event
  onKeyDown: (e) => e.stopPropagation(),
  onKeyPress: (e) => e.stopPropagation(),
  onKeyUp: (e) => e.stopPropagation(),
  onAbort: (e) => e.stopPropagation(),
  onCanPlay: (e) => e.stopPropagation(),
  onCanPlayThrough: (e) => e.stopPropagation(),
  onDurationChange: (e) => e.stopPropagation(),
  onEmptied: (e) => e.stopPropagation(),
  onEncrypted: (e) => e.stopPropagation(),
  onEnded: (e) => e.stopPropagation(),
  onLoadedData: (e) => e.stopPropagation(),
  onLoadedMetadata: (e) => e.stopPropagation(),
  onLoadStart: (e) => e.stopPropagation(),
  onPause: (e) => e.stopPropagation(),
  onPlay: (e) => e.stopPropagation(),
  onPlaying: (e) => e.stopPropagation(),
  onProgress: (e) => e.stopPropagation(),
  onRateChange: (e) => e.stopPropagation(),
  onSeeked: (e) => e.stopPropagation(),
  onSeeking: (e) => e.stopPropagation(),
  onStalled: (e) => e.stopPropagation(),
  onSuspend: (e) => e.stopPropagation(),
  onTimeUpdate: (e) => e.stopPropagation(),
  onVolumeChange: (e) => e.stopPropagation(),
  onWaiting: (e) => e.stopPropagation(),
  onAuxClick: (e) => e.stopPropagation(),
  onClick: (e) => e.stopPropagation(),
  onContextMenu: (e) => e.stopPropagation(),
  onDoubleClick: (e) => e.stopPropagation(),
  onDrag: (e) => e.stopPropagation(),
  onDragEnd: (e) => e.stopPropagation(),
  onDragEnter: (e) => e.stopPropagation(),
  onDragExit: (e) => e.stopPropagation(),
  onDragLeave: (e) => e.stopPropagation(),
  onDragOver: (e) => e.stopPropagation(),
  onDragStart: (e) => e.stopPropagation(),
  onDrop: (e) => e.stopPropagation(),
  onMouseEnter: (e) => e.stopPropagation(),
  onMouseLeave: (e) => e.stopPropagation(),
  onMouseMove: (e) => e.stopPropagation(),
  onMouseOut: (e) => e.stopPropagation(),
  onMouseOver: (e) => e.stopPropagation(),
  onMouseUp: (e) => e.stopPropagation(),
  onSelect: (e) => e.stopPropagation(),
  onTouchCancel: (e) => e.stopPropagation(),
  onTouchEnd: (e) => e.stopPropagation(),
  onTouchMove: (e) => e.stopPropagation(),
  onTouchStart: (e) => e.stopPropagation(),
  onPointerDown: (e) => e.stopPropagation(),
  onPointerMove: (e) => e.stopPropagation(),
  onPointerUp: (e) => e.stopPropagation(),
  onPointerCancel: (e) => e.stopPropagation(),
  onPointerEnter: (e) => e.stopPropagation(),
  onPointerLeave: (e) => e.stopPropagation(),
  onPointerOver: (e) => e.stopPropagation(),
  onPointerOut: (e) => e.stopPropagation(),
  onScroll: (e) => e.stopPropagation(),
  onWheel: (e) => e.stopPropagation(),
  onAnimationStart: (e) => e.stopPropagation(),
  onAnimationEnd: (e) => e.stopPropagation(),
  onAnimationIteration: (e) => e.stopPropagation(),
  onTransitionEnd: (e) => e.stopPropagation(),
} as {
  onCopy: DOMAttributes<HTMLDivElement>['onCopy'];
  onCut: DOMAttributes<HTMLDivElement>['onCut'];
  onPaste: DOMAttributes<HTMLDivElement>['onPaste'];
  onCompositionEnd: DOMAttributes<HTMLDivElement>['onCompositionEnd'];
  onCompositionStart: DOMAttributes<HTMLDivElement>['onCompositionStart'];
  onCompositionUpdate: DOMAttributes<HTMLDivElement>['onCompositionUpdate'];
  onFocus: DOMAttributes<HTMLDivElement>['onFocus'];
  onBlur: DOMAttributes<HTMLDivElement>['onBlur'];
  onChange: DOMAttributes<HTMLDivElement>['onChange'];
  onBeforeInput: DOMAttributes<HTMLDivElement>['onBeforeInput'];
  onInput: DOMAttributes<HTMLDivElement>['onInput'];
  onReset: DOMAttributes<HTMLDivElement>['onReset'];
  onSubmit: DOMAttributes<HTMLDivElement>['onSubmit'];
  onInvalid: DOMAttributes<HTMLDivElement>['onInvalid'];
  onLoad: DOMAttributes<HTMLDivElement>['onLoad'];
  onError: DOMAttributes<HTMLDivElement>['onError'];
  onKeyDown: DOMAttributes<HTMLDivElement>['onKeyDown'];
  onKeyPress: DOMAttributes<HTMLDivElement>['onKeyPress'];
  onKeyUp: DOMAttributes<HTMLDivElement>['onKeyUp'];
  onAbort: DOMAttributes<HTMLDivElement>['onAbort'];
  onCanPlay: DOMAttributes<HTMLDivElement>['onCanPlay'];
  onCanPlayThrough: DOMAttributes<HTMLDivElement>['onCanPlayThrough'];
  onDurationChange: DOMAttributes<HTMLDivElement>['onDurationChange'];
  onEmptied: DOMAttributes<HTMLDivElement>['onEmptied'];
  onEncrypted: DOMAttributes<HTMLDivElement>['onEncrypted'];
  onEnded: DOMAttributes<HTMLDivElement>['onEnded'];
  onLoadedData: DOMAttributes<HTMLDivElement>['onLoadedData'];
  onLoadedMetadata: DOMAttributes<HTMLDivElement>['onLoadedMetadata'];
  onLoadStart: DOMAttributes<HTMLDivElement>['onLoadStart'];
  onPause: DOMAttributes<HTMLDivElement>['onPause'];
  onPlay: DOMAttributes<HTMLDivElement>['onPlay'];
  onPlaying: DOMAttributes<HTMLDivElement>['onPlaying'];
  onProgress: DOMAttributes<HTMLDivElement>['onProgress'];
  onRateChange: DOMAttributes<HTMLDivElement>['onRateChange'];
  onSeeked: DOMAttributes<HTMLDivElement>['onSeeked'];
  onSeeking: DOMAttributes<HTMLDivElement>['onSeeking'];
  onStalled: DOMAttributes<HTMLDivElement>['onStalled'];
  onSuspend: DOMAttributes<HTMLDivElement>['onSuspend'];
  onTimeUpdate: DOMAttributes<HTMLDivElement>['onTimeUpdate'];
  onVolumeChange: DOMAttributes<HTMLDivElement>['onVolumeChange'];
  onWaiting: DOMAttributes<HTMLDivElement>['onWaiting'];
  onAuxClick: DOMAttributes<HTMLDivElement>['onAuxClick'];
  onClick: DOMAttributes<HTMLDivElement>['onClick'];
  onContextMenu: DOMAttributes<HTMLDivElement>['onContextMenu'];
  onDoubleClick: DOMAttributes<HTMLDivElement>['onDoubleClick'];
  onDrag: DOMAttributes<HTMLDivElement>['onDrag'];
  onDragEnd: DOMAttributes<HTMLDivElement>['onDragEnd'];
  onDragEnter: DOMAttributes<HTMLDivElement>['onDragEnter'];
  onDragExit: DOMAttributes<HTMLDivElement>['onDragExit'];
  onDragLeave: DOMAttributes<HTMLDivElement>['onDragLeave'];
  onDragOver: DOMAttributes<HTMLDivElement>['onDragOver'];
  onDragStart: DOMAttributes<HTMLDivElement>['onDragStart'];
  onDrop: DOMAttributes<HTMLDivElement>['onDrop'];
  onMouseEnter: DOMAttributes<HTMLDivElement>['onMouseEnter'];
  onMouseLeave: DOMAttributes<HTMLDivElement>['onMouseLeave'];
  onMouseMove: DOMAttributes<HTMLDivElement>['onMouseMove'];
  onMouseOut: DOMAttributes<HTMLDivElement>['onMouseOut'];
  onMouseOver: DOMAttributes<HTMLDivElement>['onMouseOver'];
  onMouseUp: DOMAttributes<HTMLDivElement>['onMouseUp'];
  onSelect: DOMAttributes<HTMLDivElement>['onSelect'];
  onTouchCancel: DOMAttributes<HTMLDivElement>['onTouchCancel'];
  onTouchEnd: DOMAttributes<HTMLDivElement>['onTouchEnd'];
  onTouchMove: DOMAttributes<HTMLDivElement>['onTouchMove'];
  onTouchStart: DOMAttributes<HTMLDivElement>['onTouchStart'];
  onPointerDown: DOMAttributes<HTMLDivElement>['onPointerDown'];
  onPointerMove: DOMAttributes<HTMLDivElement>['onPointerMove'];
  onPointerUp: DOMAttributes<HTMLDivElement>['onPointerUp'];
  onPointerCancel: DOMAttributes<HTMLDivElement>['onPointerCancel'];
  onPointerEnter: DOMAttributes<HTMLDivElement>['onPointerEnter'];
  onPointerLeave: DOMAttributes<HTMLDivElement>['onPointerLeave'];
  onPointerOver: DOMAttributes<HTMLDivElement>['onPointerOver'];
  onPointerOut: DOMAttributes<HTMLDivElement>['onPointerOut'];
  onScroll: DOMAttributes<HTMLDivElement>['onScroll'];
  onWheel: DOMAttributes<HTMLDivElement>['onWheel'];
  onAnimationStart: DOMAttributes<HTMLDivElement>['onAnimationStart'];
  onAnimationEnd: DOMAttributes<HTMLDivElement>['onAnimationEnd'];
  onAnimationIteration: DOMAttributes<HTMLDivElement>['onAnimationIteration'];
  onTransitionEnd: DOMAttributes<HTMLDivElement>['onTransitionEnd'];
};
