export type DecimalAdjustType = 'floor' | 'ceil' | 'round' | 'trunc';

export const isDecimalAdjustType = (type: unknown): type is DecimalAdjustType => {
  return type === 'floor' || type === 'ceil' || type === 'round' || type === 'trunc';
};

/**
 * Decimal adjustment of a number.
 * This is a clone from mdn
 *
 * @param type - The type of adjustment.
 * @param value - The number.
 * @param exp - The exponent (the 10 logarithm of the adjustment base).
 * @returns The adjusted value.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment
 */
export function decimalAdjust(type: DecimalAdjustType, value: number, exp = 0): number {
  // If the exp is undefined or zero
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer
  if (isNaN(value) || !(exp % 1 === 0)) {
    return NaN;
  }
  const [magnitude, exponent = 0] = value.toString().split('e');
  const adjustedValue = Math[type](Number(`${magnitude}e${Number(exponent) - exp}`));
  // Shift back
  const [newMagnitude, newExponent = 0] = adjustedValue.toString().split('e');
  return Number(`${newMagnitude}e${+newExponent + exp}`);
}
