export const SvgIconRichmenuS2Cell1 = () => (
  <svg width="1em" height="1em" viewBox="0 0 44 14">
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" strokeWidth={2}>
        <path d="M12 1h31v12H12z" />
        <path d="M27.5 1v12" strokeLinecap="square" />
      </g>
      <path fill="currentColor" d="M11 0h16v13H11z" />
      <text
        fontFamily="Roboto-Medium, Roboto"
        fontSize={14}
        fontWeight={400}
        fill="currentColor"
        transform="translate(-1 -4)"
      >
        <tspan x={0} y={13}>
          1
        </tspan>
      </text>
    </g>
  </svg>
);
