import { createContext } from 'react';
import { doNothing } from 'remeda';

import type { Rule } from './rules/type';

export interface Component {
  id: string;
  rules: Rule[];
  hint?: string;
}

export type ErrorType =
  | 'EmptyField'
  | 'ExceedsMaxLength'
  | 'InvalidURL'
  | 'InvalidTel'
  | 'InvalidParameter'
  | 'PrizeOutdated';

export const errorKeyTranslationKeyMap: Record<ErrorType, string> = {
  EmptyField: 'common.unfilled',
  ExceedsMaxLength: 'validation.exceedMaxCharLength',
  InvalidURL: 'common.invalidFormat',
  InvalidTel: 'common.invalidFormat',
  InvalidParameter: 'common.invalidFormat',
  PrizeOutdated: 'validation.editor.prizeRedeem.selectAvailableOne',
};

export interface InValid {
  errorKey?: ErrorType;
  errorMessage?: string;
  valid?: boolean;
}

export type ValidateResult = {
  valid: boolean;
  firstError: InValid & { id: string };
};

export interface ValidatorState {
  components: Component[];
  values: {
    [key in string]: string;
  };
  invalid: {
    [key in string]?: InValid;
  };
  validate: (target?: { name: string; value: string }) => ValidateResult;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: (id: string, rules: any[], value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reRegister: (id: string, rules: any[], value: string) => void;
  unregister: (id: string) => void;
  updateValue: (id: string, value: string) => void;
  checkOnBlur: boolean;
}

export const initialContext: ValidatorState = {
  components: [],
  values: {},
  register: doNothing,
  unregister: doNothing,
  updateValue: doNothing,
  reRegister: doNothing,
  validate: () => ({ valid: true, firstError: { id: '' } }),
  invalid: {},
  checkOnBlur: false,
};

export const Context = createContext<ValidatorState>(initialContext);
