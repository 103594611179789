import LinkOutlined from '@ant-design/icons/LinkOutlined';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import { StyledIcon } from 'components/StyledIcon';
import { ApiSvg, HomeSvg, InviteeSvg, InviterSvg, ShareSvg, UserSvg } from 'icons';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

import { UnicodeEmojiPopover } from '../UnicodeEmojiPopover';

interface RichTextFocusKitProps {
  isFocus: boolean;
  allowedTextDataTag: DecoratorType[];
  onClickEmoji: (emoji: string) => void;
  onClickLink?: () => void;
  onClickName?: () => void;
  onClickParam?: () => void;
  onClickReferralActivityLink?: () => void;
  onClickReferralInvitationLink?: () => void;
  onClickReferralInviter?: () => void;
  onClickReferralInvitee?: () => void;
}

export const TextFocusKitWrapper = styled.div`
  display: flex;
  padding: 0;
  justify-content: flex-end;
  align-items: center;
`;

interface TextFocusKitProps {
  isFocus: boolean;
}

const TextFocusKit = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isFocus']),
})<TextFocusKitProps>`
  display: flex;
  margin: 4px 0 16px;
  padding: 0;
  background-color: ${theme.colors.neutral009};
  border-radius: 4px;
  border: none;
  opacity: ${({ isFocus }) => (isFocus ? 1 : 0)};
  pointer-events: ${({ isFocus }) => (isFocus ? 'auto' : 'none')};
  transform: ${({ isFocus }) => (isFocus ? 'translateY(0px)' : 'translateY(10px)')};
  transition:
    0.25s opacity ease-in-out,
    0.25s transform ease-in-out;
  .toolkit-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px 5px;
    cursor: pointer;
    color: ${theme.colors.white001};
    transition: 0.25s color ease-in-out;
    path {
      fill: ${theme.colors.white001};
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      color: ${theme.colors.blue005};
      path {
        fill: ${theme.colors.blue005};
      }
    }
  }
`;

/**
 * Refactored from the section of `TextFocus` in `./RichText.tsx` to make it more flexible
 * by decoupling the event handlers.
 */
export const RichTextFocusKit = memo(function RichTextFocusKit(props: RichTextFocusKitProps) {
  const { t } = useTranslation();

  return (
    <TextFocusKitWrapper>
      <TextFocusKit isFocus={props.isFocus}>
        {props.allowedTextDataTag.includes(DecoratorType.Url) ? (
          <StyledIcon
            data-test="rich-text-editor-toolbar-url"
            className="toolkit-icon"
            component={<LinkOutlined />}
            onClick={props.onClickLink}
            centerComponent={true}
          />
        ) : null}
        {props.allowedTextDataTag.includes(DecoratorType.Name) ? (
          <Tooltip
            placement="top"
            title={<TooltipDescription>{t('message.text.memberName.desc')}</TooltipDescription>}
          >
            <StyledIcon
              className="toolkit-icon"
              width={18}
              height={18}
              component={<UserSvg />}
              onClick={props.onClickName}
              centerComponent={true}
              data-test="rich-text-editor-toolbar-name"
            />
          </Tooltip>
        ) : null}
        {props.allowedTextDataTag.includes(DecoratorType.Custom) ? (
          <StyledIcon
            className="toolkit-icon"
            component={<ApiSvg />}
            onClick={props.onClickParam}
            data-test="rich-text-editor-toolbar-params"
          />
        ) : null}
        <UnicodeEmojiPopover onEmojiClick={props.onClickEmoji} />
        {props.allowedTextDataTag.includes(DecoratorType.ReferralActivityLink) ? (
          <Tooltip
            placement="top"
            title={
              <TooltipDescription>{t('message.text.activityPageLink.desc')}</TooltipDescription>
            }
          >
            <StyledIcon
              className="toolkit-icon"
              component={<HomeSvg />}
              onClick={props.onClickReferralActivityLink}
              centerComponent={true}
            />
          </Tooltip>
        ) : null}
        {props.allowedTextDataTag.includes(DecoratorType.ReferralInvitationLink) ? (
          <Tooltip
            placement="top"
            title={<TooltipDescription>{t('message.text.invitationLink.desc')}</TooltipDescription>}
          >
            <StyledIcon
              className="toolkit-icon"
              component={<ShareSvg />}
              display="flex"
              onClick={props.onClickReferralInvitationLink}
              centerComponent={true}
            />
          </Tooltip>
        ) : null}
        {props.allowedTextDataTag.includes(DecoratorType.ReferralInviter) ? (
          <Tooltip
            placement="top"
            title={<TooltipDescription>{t('message.text.inviterName.desc')}</TooltipDescription>}
          >
            <StyledIcon
              className="toolkit-icon"
              component={<InviterSvg />}
              onClick={props.onClickReferralInviter}
              centerComponent={true}
            />
          </Tooltip>
        ) : null}
        {props.allowedTextDataTag.includes(DecoratorType.ReferralInvitee) ? (
          <Tooltip
            placement="top"
            title={<TooltipDescription>{t('message.text.inviteeName.desc')}</TooltipDescription>}
          >
            <StyledIcon
              className="toolkit-icon"
              component={<InviteeSvg />}
              onClick={props.onClickReferralInvitee}
              centerComponent={true}
            />
          </Tooltip>
        ) : null}
      </TextFocusKit>
    </TextFocusKitWrapper>
  );
});
