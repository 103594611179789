import type { FlexSeparator } from '@line/bot-sdk';

import * as S from '../styled';
import { marginClassName } from '../utils';

interface FlexSeparatorComponentProps {
  data: FlexSeparator;
}

export const FlexSeparatorComponent = ({
  data: { margin, color },
}: FlexSeparatorComponentProps) => {
  /**
   * The icon's `flex` property is fixed to 0.
   */
  return (
    <div
      className={S.classNames('separator', 'flex0', marginClassName(margin, 't'))}
      style={{ borderColor: color }}
    ></div>
  );
};
