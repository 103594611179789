import type { FlexBubble } from '@line/bot-sdk';

import { Box } from 'components';

import { useProps } from '../context';
import { useAction } from '../hooks';
import * as S from '../styled';
import { isFlexBox, scaleRatio } from '../types';

import { FlexBoxComponent } from './box';
import { FlexImageComponent } from './image';

interface BubbleProps {
  index: number;
  bubble: FlexBubble;
}

const footerClassName = (bubble: FlexBubble) => {
  return {
    hasFooter: bubble.footer !== undefined,
  };
};

export const Bubble = ({ index, bubble }: BubbleProps) => {
  const { rowIndex } = useProps();
  const { header, hero, body, footer, size, direction, styles, action } = bubble;
  const [, onBubbleClick] = useAction(action);

  return (
    <Box
      // mr={'20px'}
      // overflow={'hidden'}
      key={`FlexPreview${rowIndex}-${index}`}
      style={{ transform: `scaleX(${scaleRatio})`, transformOrigin: 'top left' }}
    >
      <S.StyleProvider>
        <div className={S.classNames(size ?? 'mega')} onClick={onBubbleClick}>
          <div className={S.classNames('T1', direction ?? 'ltr')}>
            {header ? (
              <div
                className={S.classNames('header')}
                style={{
                  backgroundColor: styles?.header?.backgroundColor,
                }}
              >
                <FlexBoxComponent data={header} />
              </div>
            ) : null}
            {hero ? (
              <div
                className="t1Hero"
                style={{
                  backgroundColor: styles?.hero?.backgroundColor,
                }}
              >
                {isFlexBox(hero) ? (
                  <FlexBoxComponent data={hero} />
                ) : (
                  <FlexImageComponent data={hero} />
                )}
              </div>
            ) : null}
            {body ? (
              <div
                className={S.classNames('body', footerClassName(bubble))}
                style={{
                  backgroundColor: styles?.body?.backgroundColor,
                }}
              >
                <FlexBoxComponent data={body} />
              </div>
            ) : null}
            {footer ? (
              <div
                className={S.classNames('footer')}
                style={{
                  backgroundColor: styles?.footer?.backgroundColor,
                }}
              >
                <FlexBoxComponent data={footer} />
              </div>
            ) : null}
          </div>
        </div>
      </S.StyleProvider>
    </Box>
  );
};
