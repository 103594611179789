import type { QuickReplyItem } from '@line/bot-sdk';

export type QuickReplyItems = QuickReplyItem[];
export type SetQuickReplyItems = (items: QuickReplyItems) => void;

export interface QuickReplyItemsProps {
  quickReplyItems: QuickReplyItems;
  setQuickReplyItems: SetQuickReplyItems;
}

export const QUICK_REPLY_CARD_ID_PREFIX = 'quick-reply-card-';

export const quickReplyEmptyData: QuickReplyItem = {
  type: 'action',
  imageUrl: '',
  action: {
    type: 'message',
    label: '',
    text: '',
  },
};

export const quickReplyData: QuickReplyItem[] = [
  {
    type: 'action',
    imageUrl: 'https://media.giphy.com/media/pRN57fPeBvuYE/giphy.gif',
    action: {
      type: 'message',
      label: '點我啊笨蛋',
      text: '點我啊笨蛋',
    },
  },
  {
    type: 'action',
    imageUrl: 'https://media.giphy.com/media/Hw7O9Np7JpXK8/giphy.gif',
    action: {
      type: 'message',
      label: 'Tempura',
      text: 'Tempura',
    },
  },
  {
    type: 'action',
    imageUrl: 'https://media.giphy.com/media/KFgjvNw8GIxNXX5cUN/giphy.gif',
    action: {
      type: 'message',
      label: 'Tempura',
      text: 'Tempura',
    },
  },
  {
    type: 'action',
    action: {
      type: 'location',
      label: 'Send location',
    },
  },
  {
    type: 'action',
    action: {
      type: 'cameraRoll',
      label: 'Camera roll',
    },
  },
];
