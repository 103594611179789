import SmileOutlined from '@ant-design/icons/SmileOutlined';
import { useState } from 'react';

import { StyledIcon } from 'components';
import { EmojiPicker } from 'shared/components/EmojiPicker';
import { Popover } from 'shared/components/Popover';
import { theme } from 'theme';

type UnicodeEmojiPopoverProps = {
  /** A callback that consumes emoji string */
  onEmojiClick: (emoji: string) => void;
};

export const UnicodeEmojiPopover = ({ onEmojiClick }: UnicodeEmojiPopoverProps) => {
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

  return (
    <Popover
      open={emojiPickerVisible}
      trigger="click"
      placement="bottomLeft"
      overlayInnerStyle={{ padding: 0 }}
      zIndex={theme.zIndices.modalDefault}
      destroyTooltipOnHide={true}
      fresh={false}
      content={
        <EmojiPicker
          onEmojiClick={(emojiObject) => {
            onEmojiClick(emojiObject.emoji);
            setEmojiPickerVisible(false);
          }}
        />
      }
    >
      <StyledIcon
        component={<SmileOutlined />}
        className="toolkit-icon"
        onClick={() => {
          setEmojiPickerVisible((visible) => !visible);
        }}
      />
    </Popover>
  );
};
