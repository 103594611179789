import debug from 'debug';
import { useEffect } from 'react';

import type { ValidatorState } from '../context';
import type { Rule } from '../rules/type';

const log = debug('validator:hook');

interface Props {
  register: ValidatorState['register'];
  unregister: ValidatorState['unregister'];
  name: string;
  value: string;
  rules: Rule[];
  enableReinitialize?: boolean;
  reRegister: ValidatorState['reRegister'];
  values: ValidatorState['values'];
}

export function useRegister(props: Props): void {
  useEffect(() => {
    props.register && props.register(props.name, props.rules || [], props.value || '');
    log('register', props.name, props.rules, props.value);
    return function cleanup() {
      log('cleanup', props.name);
      props.unregister && props.unregister(props.name);
    };
  }, [props.name]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.enableReinitialize) {
      if (props.values[props.name] !== props.value) {
        log('re-register', {
          name: props.name,
          state: props.values[props.name],
          props: props.value,
        });
        props.reRegister(props.name, props.rules, props.value || '');
      }
    }
  }, [props.enableReinitialize, props.name, props.value]); // eslint-disable-line react-hooks/exhaustive-deps
}
