import type { MessageInstance } from 'antd/es/message/interface';
import type { PageResponse, WebhookTriggerSetting } from 'components/Types';

import { MessageType, WebhookEventType } from 'components/Types';
import { AuthAxios as axios } from 'lib/axios';
import { i18n } from 'modules/G11n/i18n';

const API = `/line/v1/editor_trigger/`;

export const createOrUpdateTriggerCode = async (
  triggerCode: string,
  botId: number,
  tags: string[],
  setTagList: (tags: string[]) => void,
  message: MessageInstance,
): Promise<void> => {
  try {
    const resp = await axios.get<PageResponse<WebhookTriggerSetting>>(`${API}`, {
      params: {
        bot: botId,
        trigger_code: triggerCode,
      },
    });
    const param: Partial<WebhookTriggerSetting> = {
      tags: tags,
      messages: [
        // @ts-expect-error Missing properties
        { enable: false, trigger_type: MessageType.ORIGINAL_FRIEND, messages: [] },
        // @ts-expect-error Missing properties
        { enable: false, trigger_type: MessageType.BOUND_FRIEND, messages: [] },
      ],
      enable: true,
      name: triggerCode,
      event_type: WebhookEventType.MESSAGE_EDITOR,
      trigger_code: triggerCode,
      bot: botId,
    };
    if (resp.data.results.length === 0) {
      // create
      await axios.post(`${API}`, param);
      setTagList(tags);
      message.success(
        i18n.t('common.createModuleSuccessfully', { module: i18n.t('glossary.tag') }),
      );
    } else {
      // update
      await axios.patch(`${API}${resp.data.results[0].id}/`, { tags });
      setTagList(tags);
      message.success(
        i18n.t('common.updateModuleSuccessfully', { module: i18n.t('glossary.tag') }),
      );
    }
  } catch {
    message.error(i18n.t('common.failToUpdateModule', { module: i18n.t('glossary.tag') }));
  }
};

export const getAllEditorTriggerList = async (
  triggerList: WebhookTriggerSetting[],
  botId: number,
  triggerCode: string,
): Promise<WebhookTriggerSetting[]> => {
  try {
    const resp = await axios.get<PageResponse<WebhookTriggerSetting>>(`${API}`, {
      params: {
        bot: botId,
        offset: triggerList.length,
        limit: 100,
        trigger_code: triggerCode,
      },
    });
    if (resp.data.next !== null) {
      return await getAllEditorTriggerList(
        [...triggerList, ...resp.data.results],
        botId,
        triggerCode,
      );
    } else return [...triggerList, ...resp.data.results];
  } catch {
    return [];
  }
};
