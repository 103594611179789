import Switch from 'antd/es/switch';

import type { BoxProps } from 'components/layoutUtils';

import { Box } from 'components/layoutUtils';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface ContainerProps {
  enable: boolean;
  isEditing: boolean;
}

export const Container = styled(Box).withConfig({
  shouldForwardProp: shouldNotForwardProps(['isEditing', 'enable']),
})<BoxProps & ContainerProps>`
  display: flex;
  flex-direction: column;
  min-height: 68px;
  border-radius: 4px;
  border: solid 1px
    ${({ isEditing, enable }) =>
      isEditing && enable ? theme.colors.blue006 : theme.colors.neutral005};
  background-color: ${theme.colors.white001};
`;

export const Header = styled('div')`
  padding: 18px 20px 18px 24px;
  display: flex;
  justify-content: space-between;
`;

export const Body = styled('div')`
  padding: 18px 20px 18px 24px;
  border-top: 1px solid ${theme.colors.neutral005};
`;

export const Title = styled('span')`
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  width: 240px;
  color: ${theme.colors.neutral010};
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 17px;
`;

interface NoticeContainerProps {
  enable: boolean;
}

export const NoticeContainer = styled('div').withConfig({
  shouldForwardProp: shouldNotForwardProps(['enable']),
})<NoticeContainerProps>`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const NoticeText = styled('span')`
  margin-left: 10px;
`;
