import { z } from 'zod';

export const JourneyItemStatusEnum = {
  Draft: 'draft',
  Paused: 'paused',
  Scheduled: 'scheduled',
  Ongoing: 'ongoing',
  Ended: 'ended',
} as const;

export const JourneyItemStatusSchema = z.nativeEnum(JourneyItemStatusEnum);

export type JourneyItemStatus = z.output<typeof JourneyItemStatusSchema>;

export const JourneyListFilterEnum = {
  ...JourneyItemStatusEnum,
  All: 'all',
} as const;

const JourneyListFilterSchema = z.nativeEnum(JourneyListFilterEnum);

export type JourneyListFilter = z.output<typeof JourneyListFilterSchema>;
