import type { ImageMap } from 'components/LineMessageEditor/models/templateDataAndTypes/imageMap';
import type {
  ImagemapActionBlock,
  ImageMapFlex,
  ImagemapItem,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapFlex';
import type { ImageMapActionType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { isImageMapDataActionMessageRule } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

export const parseImageMapToFlex = (imageMapData: ImageMap): ImageMapFlex => {
  const { module_id, data, actions, ...rest } = imageMapData;
  const flexData: ImagemapItem = {
    type: 'bubble',
    size: 'giga',
    body: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'image',
          url: data.base_url,
          size: 'full',
          aspectMode: 'cover',
          aspectRatio: `${data.base_size.width}:${data.base_size.height}`,
          gravity: 'center',
          animated: data.animated,
        },
        ...parseImageMapActionsToFlexBox(data.base_size.height, data.base_size.width, data.actions),
      ],
      paddingAll: '0px',
    },
  };
  return {
    module_id: LineFieldType.ImageMapFlex,
    data: { notification_text: data.notification_text, contents: flexData },
    ...rest,
    actions: [],
  };
};

const parseImageMapActionsToFlexBox = (
  height: number,
  width: number,
  actions: ImageMapActionType[],
): ImagemapActionBlock[] => {
  return actions.map((d) => {
    const flexItem: ImagemapActionBlock = {
      type: 'box',
      layout: 'vertical',
      contents: [],
      position: 'absolute',
      width: `${((d.area.width / width) * 100).toFixed(2)}%`,
      height: `${((d.area.height / height) * 100).toFixed(2)}%`,
      offsetTop: `${((d.area.y / height) * 100).toFixed(2)}%`,
      offsetStart: `${((d.area.x / width) * 100).toFixed(2)}%`,
      // @ts-expect-error Property `label` is missing
      action: isImageMapDataActionMessageRule(d)
        ? {
            type: 'message',
            text: d.text,
          }
        : { type: 'uri', uri: d.linkUri },
    };
    return flexItem;
  });
};
