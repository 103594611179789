import { forwardRef } from '@chatbotgang/etude/react/forwardRef';
import { ClassNames } from '@emotion/react';

import type { JSX } from 'react';

/**
 * This adapter file is intended for use with Ant Design components
 * In many cases you can use Motif icons as they are, but when interfacing with Button and others, this may be helpful to avoid repetitive styling (e.g. positioning)
 */
type AcceptedElementType = 'div' | 'span' | 'i';

type OverridableComponent<TComponent extends React.ElementType> =
  React.ComponentPropsWithoutRef<TComponent> & {
    /** Decide what element to be rendered */
    component?: TComponent;
  };

type MotifIconProps<TComponent extends AcceptedElementType> = OverridableComponent<TComponent> & {
  'un-i-motif': string;
};

/**
 * This is a wrapper for Motif icons that is compatible with Ant Design components
 *
 * @example
 * ```tsx
 * <MotifIcon un-i-motif="paper_plane" /> // by default it renders a div
 * <MotifIcon un-i-motif="paper_plane" component="span" /> // renders a span
 * ```
 */
export const MotifIcon = forwardRef(
  <TComponent extends AcceptedElementType>(
    props: MotifIconProps<TComponent>,
    ref: React.Ref<React.ElementRef<TComponent>>,
  ) => {
    const { component: Component = 'div', ...restProps } = props;

    return (
      <ClassNames>
        {({ css, cx }) => (
          <Component
            {...({ ...restProps } as unknown as JSX.LibraryManagedAttributes<
              TComponent,
              React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
            >)}
            className={cx(
              css`
                display: inline-flex;
                vertical-align: -0.125em;
              `,
              restProps.className,
            )}
            ref={ref}
          />
        )}
      </ClassNames>
    );
  },
);
