import Icon from '@ant-design/icons/es/components/Icon';
import { forwardRef } from 'react';

import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';
import type {
  ComponentProps,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';

/**
 * @description A wrapper for `@ant-design/icons`' `Icon` component.
 * @example
 * ```tsx
 * import { generateIcon } from './util';
 *
 * export const MyIcon = generateIcon(function MySvg() {
 *   return (
 *     <svg viewBox="0 0 20 20">
 *         <path
 *           // ...
 *           fill="currentColor"
 *         />
 *     </svg>
 *   );
 * });
 *
 * ```
 * @param component SVG component to wrap.
 * @returns Icon component.
 */
export function generateIcon(
  component: ComponentProps<typeof Icon>['component'],
): ForwardRefExoticComponent<
  PropsWithoutRef<Partial<CustomIconComponentProps>> & RefAttributes<HTMLSpanElement>
> {
  return forwardRef<HTMLSpanElement, Partial<CustomIconComponentProps>>(
    function generateIcon(props, ref) {
      return <Icon {...props} component={component} ref={ref} />;
    },
  );
}
