import { border, compose, space } from 'styled-system';

import type { BorderProps, SpaceProps } from 'styled-system';

import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

const styles = compose(space, border);

type DividerProps = SpaceProps & BorderProps;

export const Divider = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<DividerProps>`
  border-bottom: 1px solid ${theme.colors.statusGrey};
  ${styles}
`;

export const VerticalDivider = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<DividerProps>`
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid ${theme.colors.statusGrey};
  ${styles}
`;
