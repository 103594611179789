import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { QuickReplyItems, SetQuickReplyItems } from '../types';
import type { Action } from '@line/bot-sdk';

import { quickReplyEmptyData } from '../types';

export const useQuickReplyData = (
  quickReplyItems: QuickReplyItems,
  setQuickReplyItems: SetQuickReplyItems,
): {
  setImageUrl: (payload: { carrouselIndex: number; url: string }) => void;
  addQuickReplyCard: () => void;
  setMessage: (payload: { carrouselIndex: number; message: string }) => void;
  setActionType: (payload: {
    rowIndex: number;
    carrouselIndex: number;
    actionType: 'location' | 'message' | 'camera' | 'cameraRoll' | 'postback';
  }) => void;
  deleteQuickReply: (payload: { carrouselIndex: number }) => void;
  sortQuickReply: (payload: { oldIndex: number; newIndex: number }) => void;
  setLabel: (payload: { carrouselIndex: number; labelText: string }) => void;
} => {
  const { t } = useTranslation();

  const setImageUrl = useCallback(
    (payload: { carrouselIndex: number; url: string }) => {
      setQuickReplyItems(
        quickReplyItems.map((d, index) => {
          if (index === payload.carrouselIndex && d.action.type === 'message') {
            return { ...d, imageUrl: payload.url };
          }
          return d;
        }),
      );
    },
    [quickReplyItems, setQuickReplyItems],
  );

  const addQuickReplyCard = useCallback(() => {
    const data = JSON.parse(JSON.stringify(quickReplyEmptyData));
    data.action.label = t('common.reply') + `#${quickReplyItems.length + 1}`;
    setQuickReplyItems([...quickReplyItems, data]);
  }, [quickReplyItems, setQuickReplyItems, t]);

  const setMessage = useCallback(
    (payload: { carrouselIndex: number; message: string }) => {
      setQuickReplyItems(
        quickReplyItems.map((d, index) => {
          if (index === payload.carrouselIndex) {
            return { ...d, action: { ...d.action, text: payload.message } };
          }
          return d;
        }),
      );
    },
    [quickReplyItems, setQuickReplyItems],
  );

  const setLabel = useCallback(
    (payload: { carrouselIndex: number; labelText: string }) => {
      setQuickReplyItems(
        quickReplyItems.map((d, index) => {
          if (index === payload.carrouselIndex) {
            return { ...d, action: { ...d.action, label: payload.labelText } };
          }
          return d;
        }),
      );
    },
    [quickReplyItems, setQuickReplyItems],
  );

  const setActionType = useCallback(
    (payload: {
      rowIndex: number;
      carrouselIndex: number;
      actionType: 'location' | 'message' | 'camera' | 'cameraRoll' | 'postback';
    }) => {
      setQuickReplyItems(
        quickReplyItems.map((item, index) => {
          if (index === payload.carrouselIndex) {
            return {
              ...item,
              action: {
                ...item.action,
                type: payload.actionType,
              } as Action,
            };
          }
          return item;
        }),
      );
    },
    [quickReplyItems, setQuickReplyItems],
  );

  const deleteQuickReply = useCallback(
    (payload: { carrouselIndex: number }) => {
      setQuickReplyItems(quickReplyItems.filter((d, index) => index !== payload.carrouselIndex));
    },
    [quickReplyItems, setQuickReplyItems],
  );

  const sortQuickReply = useCallback(
    (payload: { oldIndex: number; newIndex: number }) => {
      const result = quickReplyItems.filter((d, index) => index !== payload.oldIndex);
      result.splice(payload.newIndex, 0, quickReplyItems[payload.oldIndex]);
      setQuickReplyItems(result);
    },
    [quickReplyItems, setQuickReplyItems],
  );

  return {
    setImageUrl,
    addQuickReplyCard,
    setMessage,
    setActionType,
    deleteQuickReply,
    sortQuickReply,
    setLabel,
  };
};
