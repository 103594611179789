import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TextDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type {
  State,
  TextDataParameterCustom,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ChangeEvent } from 'react';

import { Box, PopupContent } from 'components';
import { Context } from 'components/LineMessageEditor/models';
import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import { stopPropagationBundle } from 'lib/react-createPortal';
import * as Validator from 'lib/validator';
import { customNameHelper, EEditorType } from 'lib/validator/helper/validatorHelpers';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import * as LocalS from './Styled';

export const CustomDecorator = ({
  editorType = EEditorType.RichTextEditor,
  ...props
}: {
  decoratedText: string;
  entityKey: string;
  editorType: EEditorType.RichTextEditor | EEditorType.ParamRichTextEditor;
}) => {
  const { store, dispatch } = useContext(Context);
  const { t } = useTranslation();
  const validatorContext = useContext(Validator.Context);
  const entityKey = props.decoratedText
    .replace(`<$var:${DecoratorType.Custom}`, '')
    .replace('>', '');
  const [isShow, setIsShow] = useState(
    getCustomKey(store, entityKey) === undefined ? false : getCustomKey(store, entityKey) === '',
  );

  useEffect(() => {
    return () => {
      // dispatch('removeParametersByMappingKey', { mappingKey: transformKey(props.decoratedText) }); 這邊會導致刪除時造成問題 先拿掉拿到最後輸出時一起處理
      // when user delete the parameter unregister the validator from validatorContext
      validatorContext.unregister(
        customNameHelper({
          rowIndex: store.editorEditingRow,
          entityKey: props.entityKey + getCustomKey(store, entityKey),
          editorIndex: undefined,
          editorType,
        }),
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Validator.FormPopover
      popup={
        <PopupContent title={t('message.apiParameterName')}>
          <Box {...stopPropagationBundle}>
            <Validator.PopoverField
              name={customNameHelper({
                rowIndex: store.editorEditingRow,
                entityKey: props.entityKey + getCustomKey(store, entityKey),
                editorIndex: undefined,
                editorType,
              })}
              rules={[Validator.Rules.required, Validator.Rules.customParameter]}
              placeholder={t('common.inputFieldName', {
                fieldName: lowerFirst(t('message.apiParameterName')),
              })}
              value={getCustomKey(store, entityKey) ?? ''}
              enableReinitialize={true}
              checkOnChange={true}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch('setTextEditingCustomParametersDetail', {
                  customKey: DecoratorType.Custom + entityKey,
                  data: e.target.value,
                });
              }}
              data-test="text-module-popover-params-key"
            />
          </Box>
        </PopupContent>
      }
      popupAlign={{
        offset: [20, 0],
      }}
      placement="right"
      popupVisible={isShow}
      onPopupVisibleChange={setIsShow}
    >
      <LocalS.DecoratorTagWrapper
        contentEditable={false}
        onClick={() => {
          setIsShow(true);
        }}
        bg={
          validatorContext.invalid[
            customNameHelper({
              rowIndex: store.editorEditingRow,
              entityKey: props.entityKey,
              editorIndex: undefined,
              editorType,
            })
          ] === undefined
            ? theme.colors.purple006
            : validatorContext.invalid[
                  customNameHelper({
                    rowIndex: store.editorEditingRow,
                    entityKey: props.entityKey,
                    editorIndex: undefined,
                    editorType,
                  })
                ]?.valid === true
              ? theme.colors.purple006
              : theme.colors.red006
        }
      >
        <LocalS.DecoratorReplaceTextWrapper style={{ fontSize: 14 }} contentEditable={false}>
          {props.decoratedText
            .replace(`<$var:${DecoratorType.Custom}`, t('glossary.apiParameter'))
            .replace('>', '')}
        </LocalS.DecoratorReplaceTextWrapper>
      </LocalS.DecoratorTagWrapper>
    </Validator.FormPopover>
  );
};

function getCustomKey(store: State, entityKey: string) {
  if (store.editorData[store.editorEditingRow] as TextDataType) {
    const index = (store.editorData[store.editorEditingRow] as TextDataType).parameters.findIndex(
      (d) => {
        return (d as TextDataParameterCustom).mappingKey === DecoratorType.Custom + entityKey;
      },
    );
    if (store.editorData[store.editorEditingRow] === undefined) return undefined;
    return index === -1
      ? undefined
      : (
          (store.editorData[store.editorEditingRow] as TextDataType).parameters[
            index
          ] as TextDataParameterCustom
        ).key;
  }
  return undefined;
}
