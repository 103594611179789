import ReactGA4 from 'react-ga4';

import { GA_EVENT_NAME_LIMIT, GA_EVENT_PREFIX } from 'shared/lib/ga/constants';

import type { UaEventOptions } from 'react-ga4/types/ga4';

import { gaEventRecords } from 'shared/lib/ga/gaEvents';

type GaEventKey = keyof typeof gaEventRecords;

type GaEventParameters<TKey extends GaEventKey> =
  'labelDescription' extends keyof (typeof gaEventRecords)[TKey]
    ? 'valueDescription' extends keyof (typeof gaEventRecords)[TKey]
      ? Required<Pick<UaEventOptions, 'label' | 'value'>>
      : Required<Pick<UaEventOptions, 'label'>>
    : 'valueDescription' extends keyof (typeof gaEventRecords)[TKey]
      ? Required<Pick<UaEventOptions, 'value'>>
      : never;

type TrackGaEventArgs<TKey extends GaEventKey> =
  GaEventParameters<TKey> extends never ? [TKey] : [TKey, GaEventParameters<TKey>];

/**
 * Invoke this function to track a GA event, but be sure to define the event first
 */
export function trackGaEvent<TKey extends GaEventKey>(...args: TrackGaEventArgs<TKey>): void {
  const gaEventKey = args[0];
  const gaEventParameters = args[1];

  const gaEventData = gaEventRecords[gaEventKey];

  if (!gaEventData) {
    throw new Error(`Tracking event ${String(gaEventKey)} not found`);
  }

  const name = `${GA_EVENT_PREFIX}_${gaEventData.name}`;

  if (name.length > GA_EVENT_NAME_LIMIT) {
    throw new Error(`GA4 event name must be ${GA_EVENT_NAME_LIMIT} characters or less`);
  }

  ReactGA4.event(name, gaEventParameters);
}
