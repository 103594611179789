export function registerClicks(fn: () => void): {
  abort: () => void;
  destroy: () => void;
} {
  const total = 11;
  const timeoutDuration = 1000;
  const areaSize = 50;
  let timeout: null | ReturnType<typeof setTimeout> = null;
  let currentCount = 0;
  function clearTimeout() {
    if (!timeout) return;
    window.clearTimeout(timeout);
  }
  function clear() {
    currentCount = 0;
    clearTimeout();
  }
  function matchClick() {
    currentCount++;
    if (currentCount === total) {
      clear();
      fn();
      return;
    }
    clearTimeout();
    timeout = setTimeout(clear, timeoutDuration);
  }
  const clickHandler = (event: MouseEvent) => {
    if (
      event.clientX < window.innerWidth - areaSize ||
      event.clientY < window.innerHeight - areaSize
    ) {
      clear();
      return;
    }
    matchClick();
  };
  window.addEventListener('click', clickHandler, { capture: true });
  function destroy() {
    window.removeEventListener('click', clickHandler);
  }
  return {
    abort: clear,
    destroy,
  };
}
