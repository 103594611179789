import WarningOutlined from '@ant-design/icons/WarningOutlined';
import { useContext, useRef, useState } from 'react';
import { useAsync } from 'react-async';
import { Trans, useTranslation } from 'react-i18next';

import type { PrizeRedeemData } from 'components/LineMessageEditor/models/templateDataAndTypes/prizeRedeem';
import type { Prize } from 'modules/Prize/types';

import { Box, Flex, StyledIcon, Text } from 'components';
import { Context } from 'components/LineMessageEditor/models';
import { useBotId, useCurrentChannel } from 'context/authContext';
import { useNavigatePrompt } from 'hooks/useNavigatePrompt';
import * as Validator from 'lib/validator';
import {
  notificationTextNameHelper,
  selectPrizeHelper,
} from 'lib/validator/helper/validatorHelpers';
import { PrizeSelect } from 'modules/Prize/components/PrizeSelect';
import { getAllPrizesAsync } from 'modules/Prize/services';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';

interface EditorProps {
  message: PrizeRedeemData;
  rowIndex: number;
}

export const PrizeRedeemPopContent = ({ message, rowIndex }: EditorProps) => {
  const { t } = useTranslation();
  const botId = useBotId();
  const { language_code } = useCurrentChannel();
  const { dispatch } = useContext(Context);
  const [options, setOptions] = useState<Prize[]>([]);
  const activatedPrizeIdsRef = useRef<number[]>([]);
  const prizeId = message.parameters[0].data.prize_setting_id;
  const selectedPrizeIdString = prizeId > 0 ? prizeId.toString() : '';
  const { navigatePrompt } = useNavigatePrompt();

  const selectedPrizeNotExpireRule = Validator.Rules.createRule({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    validate: (value: any) => {
      return activatedPrizeIdsRef.current.includes(Number(value));
    },
    errorType: 'PrizeOutdated',
  });

  const { update } = Validator.useField({
    name: selectPrizeHelper({
      rowIndex: rowIndex,
      editorType: 'PrizeRedeemEditor',
      entityKey: '',
    }),
    rules: [Validator.Rules.required, selectedPrizeNotExpireRule],
    checkOnChange: true,
    value: selectedPrizeIdString,
    enableReinitialize: true,
  });

  const { isLoading } = useAsync({
    promiseFn: getAllPrizesAsync,
    onResolve: (data) => {
      const lastSelectedItem = data.find((p) => p.id === prizeId);
      const lastSelectedItemName = lastSelectedItem?.name ?? '';

      // Make sure the prize name in message data is aligned with the latest prize name
      if (prizeId && lastSelectedItemName && message.format.prizeName !== lastSelectedItemName) {
        dispatch('setPrize', {
          rowIndex,
          prize: {
            label: lastSelectedItemName,
            value: prizeId,
          },
        });
      }

      setOptions(data);
      activatedPrizeIdsRef.current = data.filter((p) => p.status === 'activated').map((p) => p.id);
    },
    watch: botId,
    botId,
  });

  return (
    <S.PrizeRedeemPopoverContent>
      <S.PopOverFieldTitle>
        <Trans i18nKey="message.notificationText" />
      </S.PopOverFieldTitle>
      <S.PopOverFieldsContent>
        <Validator.PopoverField
          name={notificationTextNameHelper({
            rowIndex: rowIndex,
            entityKey: '',
            editorType: 'PrizeRedeemEditor',
          })}
          rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
          onChange={(e) => {
            dispatch('setPrizeRedeemNotificationText', {
              rowIndex,
              text: e.currentTarget.value,
            });
          }}
          placeholder={t('message.inputWithLengthLimit', {
            fieldName: lowerFirst(t('message.notificationText')),
            length: 300,
          })}
          value={message.data.notification_text}
          checkOnChange={true}
          data-test="prize-settings-notification-input"
        ></Validator.PopoverField>
      </S.PopOverFieldsContent>
      <S.PopOverFieldTitle>
        <Flex>
          <Trans i18nKey="glossary.prize" />
          <Text
            ml="auto"
            fontWeight="normal"
            color={theme.colors.blue006}
            cursor="pointer"
            sentenceCase={true}
            onClick={() => {
              navigatePrompt('/prize');
            }}
          >
            <Trans i18nKey="message.prize.goToCreate" />
          </Text>
        </Flex>
      </S.PopOverFieldTitle>
      <S.PopOverFieldsContent>
        <PrizeSelect
          defaultValue={prizeId > 0 ? prizeId : undefined}
          placeholder={
            <Trans
              values={{ field: lowerFirst(t('glossary.prize')) }}
              i18nKey="common.selectField"
            />
          }
          onChange={(value) => {
            const selected = options.find((p) => p.id === value);
            update(value.toString());
            dispatch('changePrize', {
              rowIndex,
              prize: {
                label: selected?.name ?? '',
                value,
                category: selected?.category ?? 'online_coupon',
              },
              lang: language_code,
            });
          }}
          loading={isLoading}
          style={{ width: '100%' }}
          prizeList={options}
          defaultPrizeId={prizeId}
          // This is a workround for preventing the Select's Dropdown from being covered by our home-made Popup in a modal.
          // By changing the popup container, the zIndex reference is changed and the above issue is resolved.
          // We should remove this workaround after we remove the usage of rc-trigger in LINE message editor.
          getPopupContainer={(trigger) => trigger.parentElement}
          data-test="prize-settings-prize-select"
        />
        {selectedPrizeIdString ? (
          <Box mt="8px" lineHeight="1.4" color={theme.colors.yellow007}>
            <StyledIcon mr="4px" width={14} component={<WarningOutlined />} />
            {t('message.prize.stockWarning')}
          </Box>
        ) : null}
      </S.PopOverFieldsContent>
    </S.PrizeRedeemPopoverContent>
  );
};
