import type { Rule } from './type';

import { i18n } from 'modules/G11n/i18n';
import { stripSpaces } from 'utils/string/whiteSpaces';

const patternUrl = /^url/;
const patternAlphanumeric = /^[a-z\d\-_]+$/i;

export const customParameter = ((message) => ({
  handlesNull: true,
  validate: (value) => {
    return !(
      stripSpaces(value.toString()) !== '' &&
      (value === 'name' ||
        patternUrl.test(value.toString()) ||
        !patternAlphanumeric.test(value.toString()))
    );
  },
  hint: () => message || i18n.t('validation.apiParameter.inValid'),
  errorType: 'InvalidParameter',
})) as Rule;
