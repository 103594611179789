import { motion } from 'framer-motion';
import { rgba } from 'polished';

import { flexCenter, hideScrollBar } from 'components/style-helper';
import { css, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const CARD_MARGIN_LEFT = 8;
export const ADD_CARD_WIDTH = 30;

export const QuickReplyEditorWrapper = styled.div`
  position: relative;
`;
export const PillDeleteBox = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(100%);
`;

interface QuickReplyPillProps {
  isActive?: boolean;
  previewMode?: boolean;
}

export const QuickReplyPill = styled(motion.div).withConfig({
  shouldForwardProp: shouldNotForwardProps(['isActive', 'previewMode']),
})<QuickReplyPillProps>`
  position: relative;
  border-radius: 20px;
  padding: 0 8px;
  background-color: ${rgba(theme.colors.neutral010, 0.5)};
  color: ${theme.colors.white001};
  line-height: 36px;
  margin-right: ${CARD_MARGIN_LEFT}px;
  height: 36px;
  box-sizing: border-box;
  white-space: nowrap;
  transition: background-color 0.25s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${({ previewMode }) =>
      previewMode ? rgba(theme.colors.neutral010, 0.5) : theme.colors.blue006};
  }
  ${({ isActive }) => isActive && `background-color: ${theme.colors.blue006}; `}
  > i {
    margin-right: 5px;
  }
`;

export const QuickReplyPillImage = styled(motion.img)`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  margin-right: 8px;
`;

export const QuickReplyPillWrapper = styled(motion.div)`
  position: relative;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  > ${QuickReplyPill} {
    scroll-snap-align: start;
  }
  ${hideScrollBar};
`;

interface PointProps {
  active?: boolean;
}

export const Point = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['active']),
})<PointProps>`
  width: 32px;
  height: 36px;
  border-radius: 4px;
  background-color: ${theme.colors.white001};
  margin-left: 5px;
  margin-right: 5px;
  border: ${({ active }) => (active ? `solid 2px ${theme.colors.blue006}` : 'none')};
  cursor: pointer;
  display: flex;
  ${flexCenter};
`;

export const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PointIcon = styled.div`
  width: 26px;
  height: 18px;
  border-radius: 20px;
  border: solid 2px #4586f0;
  box-sizing: border-box;
`;

export const PopupSubTitle = styled.div`
  line-height: 20px;
  margin-bottom: 8px;
  color: ${theme.colors.neutral010};
  font-weight: 500;
`;

export const PopupSubContent = styled.div`
  margin-bottom: 20px;
`;

export const AddButton = styled.div`
  width: 108px;
  height: 36px;
  border-radius: 20px;
  border: dashed 1px ${theme.colors.neutral005};
  background-color: ${theme.colors.white001};
  text-align: center;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 auto;
  user-select: none;
  color: ${theme.colors.neutral005};
  transition:
    border-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  :hover {
    border-color: ${theme.colors.blue006};
    color: ${theme.colors.blue006};
  }
`;

export const ImageHoverLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(theme.colors.neutral009, 0.5)};
  border-radius: 25px;
  transition: opacity 0.35s ease-in-out;
  opacity: 0;
  color: ${theme.colors.white001};
`;

const uploadedStyle = (url: string) => css`
  height: 50px;
  width: 50px;
  border-radius: 25px;
  background-image: url(${url});
  background-size: cover;
  &:hover ${ImageHoverLayer} {
    opacity: 1;
  }
`;

interface UploadImageLabelProps {
  bgUrl: string;
  type: 'image' | 'imageMap';
}

export const UploadImageLabel = styled.label.withConfig({
  shouldForwardProp: shouldNotForwardProps(['bgUrl']),
})<UploadImageLabelProps>`
  position: relative;
  display: inline-block;
  background-color: ${theme.colors.neutral003};
  cursor: pointer;
  border-radius: 4px;
  padding: 2px 12px;
  ${({ bgUrl }) => bgUrl !== '' && uploadedStyle(bgUrl)}
  input {
    display: none;
  }
`;

export const DeleteBox = styled.div`
  i {
    transition: color 0.2s ease-in-out;
  }
  &:hover {
    i {
      color: ${theme.colors.red006};
    }
  }
`;
