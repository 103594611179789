/**
 * @param el html element
 * @see https://stackoverflow.com/a/38469855/4365196
 * @returns parents of the element
 */
export function getParents(el: Element): HTMLElement[] {
  const list = [];
  let current = el;

  while (current.parentElement != null && current.parentElement !== document.documentElement) {
    list.push(current.parentElement);
    current = current.parentElement;
  }

  return list;
}
