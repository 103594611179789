/**
 * Resize the original image and return a new one
 * @param path object URL, detail: https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 * @param width assigned new image width
 */
export const getResizedImage = (
  path: string,
  width: number,
): Promise<{ blob: Blob; width: number; height: number }> => {
  if (!path) {
    throw new Error('Image object path missed');
  }
  if (width <= 0) {
    throw new Error('Width must be greater than 0');
  }

  return new Promise<{ blob: Blob; width: number; height: number }>((resolve, reject) => {
    const img = new Image();
    const canvas = document.createElement('canvas');
    img.onload = () => {
      const height = (width * img.naturalHeight) / img.naturalWidth;
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve({
              blob,
              width,
              height,
            });
          } else {
            reject(new Error('Blob is null'));
          }
        });
      }
    };
    img.src = path;
  });
};
