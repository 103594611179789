import { z } from 'zod';

import { DayJsSchema } from 'shared/models/common/date';
import { DurationOperatorSchema } from 'shared/models/filters/common';

/**
 * This file exists as a temporary measure to collect filter UI types in one place
 * Eventually this should just be the main types file under `modules/FilterBox`
 */
export const DurationUiSchema = z.object({
  duration_type: DurationOperatorSchema,
  active_days: z.number().int(),
  fix_days: z.tuple([DayJsSchema, DayJsSchema]),
});

export const TagFilterOperator = {
  EQUAL: '1-1',
  NOT: '2-1',
  ANY: '3-19',
  NOT_ANY: '2-19',
} as const;

const TagFilterOperatorSchema = z.nativeEnum(TagFilterOperator);

export type TagFilterOperatorValue = z.output<typeof TagFilterOperatorSchema>;

/**
 * Filter errors only appear in the UI
 */
export type FilterError = string[] | undefined;

/**
 * The base filter component interface; extend this in individual component files
 */
export type FilterSetErrorFunction = (error: FilterError) => void;

export interface BaseFilterComponentProps<T> {
  visible: boolean;
  onVisibleChange: (visible: boolean) => void;
  data: T;
  onChange: (data: T) => void;
  errors?: string[];
  setErrors?: FilterSetErrorFunction;
  onRemove: () => void;
}
