/* eslint-disable no-restricted-imports */
import AntTable from 'antd/es/table';

import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const TableStyled = styled(AntTable)`
  /*
  * Though Ant Design allow developers to override styles through component design token, it still doesn't cover all use cases.
  * It's better to gather all overrides in one place, so we don't use component design token.
  */
  @layer styled-component {
    .ant-table-thead > tr > th,
    .ant-table-thead > tr > td {
      background-color: ${theme.colors.white001};
    }
    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row, .ant-table-row-selected) > td {
      background-color: ${theme.colors.white001};
    }

    /**
    * For virtual table cell hover, we want to remove the background color
    */
    .ant-table-cell-row-hover {
      background-color: ${theme.colors.white001};
    }

    .ant-table-footer {
      background-color: ${theme.colors.white001};
    }
  }
`;
