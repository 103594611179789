import type { RSS } from './model';

import * as atomParser from './atomV1';
import * as lineTodayParser from './lineTodayV1';
import * as rssParser from './rssV2';
import * as utils from './utils';

const parse = (document: Document): RSS => {
  if (utils.getElements(document, 'feed').length !== 0) {
    return atomParser.parse(document);
  } else if (utils.getElements(document, 'channel').length !== 0) {
    return rssParser.parse(document);
  } else if (utils.getElements(document, 'articles').length !== 0) {
    return lineTodayParser.parse(document);
  } else {
    throw new Error('Unsupported feed detected.');
  }
};

export const rss = {
  parse,
};
