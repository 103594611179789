import type { State } from './templateDataAndTypes/types';
import type { VideoEditorData } from 'components/LineMessageEditor/models/templateDataAndTypes/video';

import { videoEditor } from 'components/LineMessageEditor/models/templateDataAndTypes/video';

import { dispatch } from './models';

type ExternalLinkPayload = {
  rowIndex: number;
  url: string;
  open_external_browser: boolean;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
};

export const videoStore = {
  reducers: {
    setVideoCoverUrl(preState: State, payload: { rowIndex: number; coverUrl: string }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.base_url = payload.coverUrl;
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.previewImageUrl =
        payload.coverUrl;
    },
    setVideoHeight(preState: State, payload: { rowIndex: number; height: number }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.base_size.height =
        payload.height;
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.area.height =
        payload.height;
    },
    setVideoMetadata(
      preState: State,
      payload: { rowIndex: number; width: number; height: number },
    ): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.metadata.width =
        payload.width;
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.metadata.height =
        payload.height;
    },
    setVideoUrl(preState: State, payload: { rowIndex: number; url: string }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.originalContentUrl =
        payload.url;
    },
    setVideoExpired(preState: State, payload: { rowIndex: number; expired: boolean }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.expired =
        payload.expired;
    },
    setVideoNotificationText(preState: State, payload: { rowIndex: number; text: string }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.notification_text =
        payload.text;
    },
    setExternalLinkLabel(preState: State, payload: { rowIndex: number; label: string }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).data.video.externalLink.label =
        payload.label;
    },
    setExternalLink(preState: State, payload: ExternalLinkPayload): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).parameters[0].data.url =
        payload.url;
      (preState.editorData[payload.rowIndex] as VideoEditorData).parameters[0].data.utm_source =
        payload.utm_source;
      (preState.editorData[payload.rowIndex] as VideoEditorData).parameters[0].data.utm_medium =
        payload.utm_medium;
      (preState.editorData[payload.rowIndex] as VideoEditorData).parameters[0].data.utm_campaign =
        payload.utm_campaign;
      (preState.editorData[payload.rowIndex] as VideoEditorData).parameters[0].data.utm_content =
        payload.utm_content;
    },
    setExternalLinkOpen(preState: State, payload: { rowIndex: number; open: boolean }): void {
      (
        preState.editorData[payload.rowIndex] as VideoEditorData
      ).parameters[0].data.open_external_browser = payload.open;
    },
    setExternalLinkTags(preState: State, payload: { rowIndex: number; tags: [string] }): void {
      (preState.editorData[payload.rowIndex] as VideoEditorData).parameters[0].data.tag_list =
        payload.tags;
    },
  },
  effects: {
    async addVideoModule(): Promise<void> {
      dispatch('addEditorData', {
        data: videoEditor,
      });
    },
  },
};
