import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { CdpDataSourceSchema } from 'shared/models/cdp';
import { AsyncTaskSchema, ListResponseSchema, PageResponseSchema } from 'shared/models/common';
import { ISO8601DateSchema } from 'shared/models/common/date';
import {
  ImportMemberCountryCodeSchema,
  ImportMemberFileTypeEnum,
  MemberItemSchema,
  MemberListExportTypeEnum,
} from 'shared/models/members';

const MemberListMetricsSchema = z.object({
  auth: z.number().int(),
  follow: z.number().int(),
  unfollow: z.number().int(),
  others: z.number().int().optional(),
  total: z.number().int(),
});

/**
 * Import members by CSV file
 */
const ImportMemberCsvFileParamsSchema = z.object({
  bot_id: z.number().int(),
  gcs_uri: z.string(),
  import_key: z.nativeEnum(ImportMemberFileTypeEnum),
  tags: z.string().array(),
  sync_to_cdp: z.boolean(),
  country_code: ImportMemberCountryCodeSchema.optional(),
  segment_name: z.string().optional(),
  segment_description: z.string().optional(),
});

/**
 * Import members by input data
 */
const ImportMemberInputDataParamsSchema = z.object({
  bot_id: z.number().int(),
  tags: z.string().array(),
  mobiles: z.string().array(),
  segment_name: z.string().optional(),
  segment_description: z.string().optional(),
});

/**
 * Note that for "total" the `member_status` is not provided
 */
const MemberListExportParameters = z.object({
  bot_id: z.number().int(),
  member_status: z
    .enum([MemberListExportTypeEnum.Follow, MemberListExportTypeEnum.Unfollow])
    .optional(),
});

/**
 * Member profile note item
 */
const MemberProfileNoteItemSchema = z.object({
  id: z.number().int(),
  author_id: z.number().int().nullable(),
  author_source: CdpDataSourceSchema.nullable(),
  channel_id: z.number().int(),
  channel_type: z.enum(['line', 'fb', 'ig', 'others']),
  channel_name: z.string(),
  member_id: z.number().int(),
  channel_entity_source: CdpDataSourceSchema,
  org_id: z.number().int(),
  content: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
});

export const memberApi = makeApi([
  {
    alias: 'memberList',
    method: 'get',
    path: '/line/v1/member/',
    // TODO: we also need to add the search params here, but it is complicated
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      count: z.number().int().or(z.object({})),
      results: MemberItemSchema.array(),
    }).strict(),
  },
  {
    alias: 'memberListAsync',
    method: 'get',
    path: '/line/v1/member/async_query/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      // TODO: should we be converting this to a string at runtime?
      {
        name: 'payload',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        key: z.string(),
        next: z.string().nullable(),
        previous: z.string().nullable(),
        count: MemberListMetricsSchema,
        results: MemberItemSchema.pick({
          id: true,
          line_id: true,
          name: true,
          avatar: true,
          status: true,
          type: true,
          last_engaged: true,
          created_at: true,
          tags: true,
        }).array(),
        timestamp: ISO8601DateSchema.nullable(),
      })
      .strict(),
  },
  {
    alias: 'memberListMetrics',
    method: 'get',
    path: '/line/v1/member/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: MemberListMetricsSchema.strict(),
  },
  {
    alias: 'memberItem',
    method: 'get',
    path: '/line/v1/member/:memberId/',
    response: MemberItemSchema.strict(),
  },
  {
    alias: 'memberItemUpdate',
    method: 'patch',
    path: '/line/v1/member/:memberId/',
    parameters: [
      {
        name: 'body',
        schema: MemberItemSchema.partial().strict(),
        type: 'Body',
      },
    ],
    response: MemberItemSchema.strict(),
  },
  {
    alias: 'memberItemTagCreate',
    method: 'post',
    path: '/line/v1/member/:memberId/tags/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            tag_ids: z.number().int().array(),
          })
          .strict(),
        type: 'Body',
      },
    ],
    response: MemberItemSchema.strict(),
  },
  {
    alias: 'memberItemTagDelete',
    method: 'delete',
    path: '/line/v1/member/:memberId/tag/:tagId/',
    response: MemberItemSchema.strict(),
  },
  {
    alias: 'memberBatchTagUpdate',
    method: 'post',
    path: '/line/v1/member/tags/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            // TODO: this needs to be analyzed and hammered out
            payload: z.any(), // MemberFilterRequestPayloadSchema,
            tags: z.number().int().array(),
            operation: z.enum(['add', 'remove']),
          })
          .strict(),
        type: 'Body',
      },
    ],
    response: z.never(),
  },
  {
    alias: 'importMemberStatus',
    method: 'get',
    path: '/line/v1/member/import_member_status/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        is_running: z.boolean(),
      })
      .strict(),
  },
  {
    alias: 'memberImportLineApi',
    method: 'post',
    path: '/line/v1/member/oa_member/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          bot_id: z.number().int(),
        }),
      },
    ],
    response: z.object({ result: z.string() }).strict(),
  },
  {
    alias: 'memberImportCsvFile',
    method: 'post',
    path: '/line/v2/member/import_member/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ImportMemberCsvFileParamsSchema.strict(),
      },
    ],
    response: AsyncTaskSchema,
  },
  {
    alias: 'memberImportInputData',
    method: 'post',
    path: '/line/v2/member/import_member/mobile/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: ImportMemberInputDataParamsSchema.strict(),
      },
    ],
    response: AsyncTaskSchema,
  },
  {
    alias: 'memberListExport',
    method: 'post',
    path: '/line/v1/member/export_member/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.union([MemberListExportParameters, z.null()]),
      },
    ],
    response: z
      .object({
        messages: z.literal('export member task start'),
      })
      .strict(),
  },
  {
    alias: 'memberNote',
    method: 'get',
    path: '/line/v1/member/:sourceId/:memberId/note/',
    response: ListResponseSchema.extend({
      results: MemberProfileNoteItemSchema.array(),
    }).strict(),
  },
  {
    alias: 'memberNoteCreate',
    method: 'post',
    // Note: MAAC is hard-coded in the URL because we can't create CAAC tags from this interface
    path: '/line/v1/member/maac/:memberId/note/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          content: z.string(),
        }),
      },
    ],
    response: MemberProfileNoteItemSchema.strict(),
  },
  {
    alias: 'memberNoteUpdate',
    method: 'patch',
    path: '/line/v1/member/:sourceId/:memberId/note/:noteId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          content: z.string(),
        }),
      },
    ],
    response: MemberProfileNoteItemSchema.strict(),
  },
  {
    alias: 'memberNoteDelete',
    method: 'delete',
    path: '/line/v1/member/:sourceId/:memberId/note/:noteId/',
    response: z.never(),
  },
  {
    alias: 'memberUnifiedChannelEntities',
    method: 'get',
    path: '/line/v1/member/:memberId/unified_channel_entity/',
    response: ListResponseSchema.extend({
      results: z
        .object({
          source: CdpDataSourceSchema,
          member_id: z.number().int(),
        })
        .strict()
        .array(),
    }),
  },
]);
