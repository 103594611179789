import { memo } from 'react';

import { InitLoading } from 'components';
import { SloganBasic } from 'layout/components/SloganBasic';
import { styled } from 'shared/utils/styled';

const Wrapper = styled.div`
  text-align: center;
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  padding-top: calc(50vh - 79px);
`;

export const Loading = memo(() => (
  <Wrapper>
    <InitLoading />
    <SloganBasic>Every message counts</SloganBasic>
  </Wrapper>
));
