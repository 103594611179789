import PictureOutlined from '@ant-design/icons/PictureOutlined';
import { useTranslation } from 'react-i18next';

import type { ChangeEventHandler } from 'react';
import type { PopoverProps } from 'shared/components/Popover';

import { FileInput } from 'components/Input';
import { StyledIcon } from 'components/StyledIcon';
import { Text } from 'components/Typography';
import { EditSvg } from 'icons/edit';
import { generateDataTestId } from 'shared/components/Editor/LineMessageEditor/utils/generateDataTestId';
import { Popover } from 'shared/components/Popover';

import * as S from './Styled';

interface Props {
  index?: number;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onUploadImage?: ChangeEventHandler<HTMLInputElement>;
  popOverProps: Omit<PopoverProps, 'children'>;
}

export function ImageEditOverlay(props: Props) {
  const { t } = useTranslation();

  return (
    <S.ImageCoverWrapper>
      <S.ImageHoverBoxIconWrapper>
        <StyledIcon component={<PictureOutlined />} fontSize={24} position="relative" />
        <br />
        <Text position="relative">{t('message.paramImageEditor.editImage')}</Text>
        <FileInput accept="image/jpeg,image/png" onChange={props.onUploadImage} />
      </S.ImageHoverBoxIconWrapper>
      <Popover
        align={{
          offset: [150, -50],
        }}
        placement="right"
        overlayStyle={{ zIndex: 999 }}
        open={props.isOpen}
        onOpenChange={props.setIsOpen}
        trigger="click"
        destroyTooltipOnHide={false}
        // This ensures the content is rendered so that the validator can check all fields.
        forceRender={true}
        {...props.popOverProps}
      >
        <S.ImageHoverBoxIconWrapper>
          <StyledIcon
            component={<EditSvg />}
            fontSize={24}
            onClick={() => props.setIsOpen(true)}
            position="relative"
            data-test={generateDataTestId('line-editor-image-edit', props.index)}
          />
          <br />
          <Text position="relative" onClick={() => props.setIsOpen(true)}>
            {t('glossary.setting')}
          </Text>
        </S.ImageHoverBoxIconWrapper>
      </Popover>
    </S.ImageCoverWrapper>
  );
}
