export const ShareSvg = () => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.286 10.714c-.51 0-.979.179-1.347.477l-3.7-2.677a2.87 2.87 0 000-1.028l3.7-2.677a2.145 2.145 0 003.49-1.666C14.428 1.96 13.467 1 12.285 1a2.145 2.145 0 00-2.06 2.737L6.713 6.282a2.856 2.856 0 10-2.284 4.575c.934 0 1.763-.448 2.284-1.14l3.515 2.545A2.145 2.145 0 0012.286 15c1.182 0 2.142-.96 2.142-2.143 0-1.182-.96-2.143-2.142-2.143zm0-8.5a.929.929 0 11-.001 1.858.929.929 0 010-1.858zM4.428 9.571A1.574 1.574 0 012.857 8c0-.866.705-1.571 1.571-1.571C5.295 6.429 6 7.134 6 8s-.705 1.571-1.572 1.571zm7.858 4.215a.93.93 0 110-1.858.93.93 0 010 1.858z"
        fill="#fff"
      />
    </svg>
  );
};
