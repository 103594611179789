import { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { doNothing } from 'remeda';

import type { QuickReplyItemsProps } from './types';

import { PillsAndPopup } from './components/PillsAndPopup';
import { SortableBoxes } from './components/SortableBoxes';
import { useQuickReplyData } from './hooks/useQuickReplyData';
import { scrollToCarouselTarget } from './utils/scrollToTarget';
import * as S from './Styled';

const rowIndex = 6;

interface QuickReplyEditorProps extends QuickReplyItemsProps {
  messagesCount: number;
}

export const QuickReplyEditor = ({
  quickReplyItems,
  setQuickReplyItems,
  messagesCount,
}: QuickReplyEditorProps) => {
  const {
    sortQuickReply,
    setActionType,
    setMessage,
    addQuickReplyCard,
    setLabel,
    setImageUrl,
    deleteQuickReply,
  } = useQuickReplyData(quickReplyItems, setQuickReplyItems);
  const [activeIndex, setActiveIndex] = useState(0);
  const pillWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <S.QuickReplyEditorWrapper data-test="quickReply-editor">
      {messagesCount > 0 ? (
        <>
          {quickReplyItems.length === 0 ? (
            <S.AddButton
              data-test="quickReply"
              onClick={() => {
                addQuickReplyCard();
              }}
            >
              +<Trans i18nKey="message.quickReply" />
            </S.AddButton>
          ) : null}
          <PillsAndPopup
            rowIndex={rowIndex}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            setActionType={setActionType}
            setMessage={setMessage}
            setQuickReplyItems={setQuickReplyItems}
            quickReplyItems={quickReplyItems}
            setLabel={setLabel}
            addQuickReplyCard={addQuickReplyCard}
            setImageUrl={setImageUrl}
            deleteQuickReply={deleteQuickReply}
            pillWrapperRef={pillWrapperRef}
          />

          <SortableBoxes
            contents={quickReplyItems.map((d, index) => index)}
            rowIndex={rowIndex}
            activeIndex={activeIndex}
            setActiveIndex={doNothing}
            distance={1}
            helperClass="sortableHelper"
            lockAxis="xy"
            axis="xy"
            onSortEnd={({ oldIndex, newIndex }) => {
              scrollToCarouselTarget(pillWrapperRef, newIndex);
              sortQuickReply({ oldIndex, newIndex });
            }}
            pillWrapperRef={pillWrapperRef}
          />
        </>
      ) : null}
    </S.QuickReplyEditorWrapper>
  );
};
