import objectInspect from 'object-inspect';

/**
 * A template literal for inspector.
 *
 * Example:
 *
 * ```ts
 * logError(new Error(inspectMessage`Got unknown value: ${val}, user: ${user}`));
 * ```
 *
 * @param strArr Strings from template literal.
 * @param args Any types of arguments.
 * @returns A inspected string.
 */
export function inspectMessage(strArr: Readonly<string[]>, ...args: unknown[]): string {
  return ['', ...strArr].reduce(
    (previousValue, currentValue, currentIndex) =>
      previousValue +
      currentValue +
      (currentIndex - 1 in args ? objectInspect(args[currentIndex - 1]) : ''),
  );
}
