import * as Sentry from '@sentry/browser';
import debug from 'debug';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import ReactGA4 from 'react-ga4';

import {
  ENV,
  GA4_ID,
  MSW_ACTIVE,
  NODE_ENV,
  SENTRY_ALLOW_URLS,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_RELEASE,
} from 'env';

import type { BrowserOptions } from '@sentry/browser';

import { Loading } from 'layout/Loading';
import { initialFirebase } from 'lib/firebase/initialFirebase';
import { PageErrorBoundary } from 'modules/PageErrorBoundary';
import { reportWebVitals } from 'shared/lib/ga/reportWebVitals';
import { css } from 'shared/utils/styled/override';
import { PreventScrollBack } from 'utils/dom/preventScrollBack';

import '@fontsource-variable/noto-sans-tc';
import '@fontsource-variable/noto-sans-thai';
import './modules/G11n/i18n';

import { App } from './App';
import { AppProviders } from './context';

import 'virtual:uno.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './theme/css/index.css';

declare global {
  interface Window {
    debug: typeof debug;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    grecaptcha: any;
  }
}

window.debug = debug;

/**
 * Sentry setup
 */
const sentryOptions: BrowserOptions = {
  dsn: SENTRY_DSN,
  environment: ENV,
  release: SENTRY_RELEASE,
  allowUrls: SENTRY_ALLOW_URLS,
  debug: NODE_ENV === 'development',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      mask: ['.ant-input-password'],
      networkDetailAllowUrls: [
        window.location.origin,
        /^https:\/\/api\.cresclab\.com/,
        /^https:\/\/apistaging\.cresclab\.com/,
      ],
    }),
    Sentry.captureConsoleIntegration({ levels: ['error', 'debug', 'assert'] }),
  ],
  tracesSampleRate: 0.01,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    // This error is thrown by rc-resize-observer, which is used by Ant Design table. It's a known issue but won't impact UX, so we ignore it.
    'ResizeObserver loop completed with undelivered notifications',
    // This error is thrown by Userflow.js. It's a 3rd-party library and won't impact UX, so we ignore it.
    'Could not load Userflow.js',
    // Firebase Auth known errors.
    /^FirebaseError: Firebase: Error \(auth\/popup-closed-by-user\)\.$/,
    /^FirebaseError: Firebase: Error \(auth\/cancelled-popup-request\)\.$/,
    /^FirebaseError: Firebase: Error \(auth\/popup-blocked\)\.$/,
    /^FirebaseError: Firebase: Error \(auth\/network-request-failed\)\.$/,
  ],
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  /**
   * To disable truncation, set the `maxValueLength` to `Infinity`. The
   * default value is 250, which is often insufficient for ZodError.
   */
  maxValueLength: Infinity,
};

if (SENTRY_ENABLED) {
  Sentry.init(sentryOptions);
}

/**
 * Mock Service Worker (MSW) initialization
 * The first flag ensures this won't be active in production builds
 * The second flag ensures its use in development is opt-in for now
 * Run `pnpm run dev:msw` or add `REACT_APP_MSW_ACTIVE=true` to your `.env.local` file to activate
 */
// eslint-disable-next-line no-process-env -- Required for dead code elimination
if (process.env.NODE_ENV !== 'production') {
  if (MSW_ACTIVE) {
    (async function loadApiMockServer() {
      const { initializeMswBrowser } = await import('lib/msw/browser');

      initializeMswBrowser();
    })();
  }
}

/**
 * Google Analytics 4 setup
 * We followed the instruction here: https://support.google.com/analytics/answer/9744165?hl=en
 */
ReactGA4.initialize(GA4_ID);
reportWebVitals();

/**
 * React setup
 */
const rootElement = document.getElementById('root');

const cssErrorPadding = css`
  box-sizing: content-box;
  --px: 2em;
  padding-right: var(--px);
  padding-left: var(--px);
`;

ReactDOM.render(
  <PageErrorBoundary reloadWindow={true} styledCss={cssErrorPadding}>
    <Suspense fallback={<Loading />}>
      <AppProviders>
        <App />
      </AppProviders>
    </Suspense>
  </PageErrorBoundary>,
  rootElement,
);

const preventScrollBack = new PreventScrollBack(document.documentElement);
preventScrollBack.register();

/**
 * Firebase setup
 */
initialFirebase();
