import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { MessageSchema } from 'shared/components/Editor/LineMessageEditor/types';
import { PageResponseSchema } from 'shared/models/common';

export const TemplateCategoryEnum = {
  Dpm: 'dpm',
  Broadcast: 'broadcast',
  Retargeting: 'retargeting',
  WebhookTrigger: 'webhook_trigger',
  Tracelink: 'guide_link',
  OpenApi: 'openapi',
  Maac: 'maac',
  Salesforce: 'salesforce',
} as const;

const TemplateListItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  bot: z.number().int(),
  messages: MessageSchema.array().optional(),
  thumbnail_info: z.array(z.number()),
  category: z.nativeEnum(TemplateCategoryEnum),
});

export const templateApi = makeApi([
  {
    alias: 'templateList',
    method: 'get',
    path: '/line/v1/message/template/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({ results: TemplateListItemSchema.array() }).strict(),
  },
  {
    alias: 'templateDetail',
    method: 'get',
    path: '/line/v1/message/template/:templateId/',
    response: TemplateListItemSchema.strict(),
  },
  {
    alias: 'templateUpdate',
    method: 'patch',
    path: '/line/v1/message/template/:templateId/',
    parameters: [
      {
        name: 'body',
        schema: TemplateListItemSchema.partial(),
        type: 'Body',
      },
    ],
    response: TemplateListItemSchema.strict(),
  },
  {
    alias: 'templateCreate',
    method: 'post',
    path: '/line/v1/message/template/',
    parameters: [
      {
        name: 'body',
        schema: TemplateListItemSchema.partial(),
        type: 'Body',
      },
    ],
    response: TemplateListItemSchema.strict(),
  },
  {
    alias: 'templateDelete',
    method: 'delete',
    path: '/line/v1/message/template/:templateId/',
    response: z.never(),
  },
]);

export type TemplateListResponse = ZodiosResponseByAlias<typeof templateApi, 'templateList'>;
export type TemplateDetailResponse = ZodiosResponseByAlias<typeof templateApi, 'templateDetail'>;

export type TemplateListItem = z.output<typeof TemplateListItemSchema>;
