import { forwardRef, memo, useCallback, useMemo } from 'react';

import { TAG_COUNT_LIMIT_DEFAULT } from 'AppConstants';

import type { TagPickerByNameProps } from 'shared/components/TagPicker/types';

import { TagPicker } from 'shared/components/TagPicker/TagPicker';
import { useTagMetadata } from 'shared/hooks/tag/useTagMetadata';

/**
 * Adapter for legacy components, which previously consumed `TagsModule`
 * This component used strings instead of numbers for tag IDs
 * Use the standard TagPicker for all new development, as long as numeric IDs are used
 */
export const TagPickerByName = memo(
  forwardRef<HTMLDivElement, TagPickerByNameProps>(function TagPickerByName(
    {
      tagList,
      onTagListChange,
      loading,
      tagCountLimit = TAG_COUNT_LIMIT_DEFAULT,
      disabled = false,
      hideTagItemList = false,
      ...restProps
    },
    ref,
  ) {
    const { getTagIdsByNames, getTagNamesByIds } = useTagMetadata();

    const tagListMemoized = useMemo(() => getTagIdsByNames(tagList), [getTagIdsByNames, tagList]);

    const onTagListChangeNumeric = useCallback(
      (tagIdsList: number[]) => {
        onTagListChange(getTagNamesByIds(tagIdsList));
      },
      [getTagNamesByIds, onTagListChange],
    );

    return (
      <div {...restProps} ref={ref}>
        <TagPicker
          tagList={tagListMemoized}
          onTagListChange={onTagListChangeNumeric}
          tagCountLimit={tagCountLimit}
          disabled={disabled}
          loading={loading}
          hideTagItemList={hideTagItemList}
        />
      </div>
    );
  }),
);
