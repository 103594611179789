import { useEffect } from 'react';

export function useMount(mount: () => void): void {
  useEffect(mount, []); // eslint-disable-line react-hooks/exhaustive-deps
}

export function useUnmount(unmount: () => void | undefined): void {
  useEffect(
    () => () => {
      if (unmount) unmount();
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getActionName(action: string | any): string {
  if (typeof action === 'string') return action;

  try {
    const str: string = action.toString();
    const regAction = /return.*\.(.*)[;,}]/;
    const arr = str.match(regAction);

    if (arr) return arr[1];

    return '';
  } catch {
    throw new Error('action type or selector invalid');
  }
}
