import { z } from 'zod';

import { JourneyNodeFilterDataSchema } from 'shared/models/journey/nodeFilter';

export const JourneyBranchTypeEnum = {
  ConditionSplit: 'condition_split',
} as const;

// Note: for `yes_no_branch` the maximum number of branches is 1 (just the "yes" branch)
export const JourneyBranchConditionSplitSchema = z.object({
  type: z.literal(JourneyBranchTypeEnum.ConditionSplit),
  branches: JourneyNodeFilterDataSchema.array(),
});
