import { useMemo } from 'react';
import { useAsync } from 'react-async';
import urlJoin from 'url-join';

import { useBotId } from 'context/authContext';
import { getBotLiffChannelId } from 'services/rubato';
import { axiosToPromiseFn } from 'utils/function/axiosToReactAsync';

const LINE_DEVELOPER_BASE_URL = 'https://developers.line.biz/';

export const useLineDeveloperConsoleLink = (): string => {
  const botId = useBotId();

  const getBotLiffChannelIdFn = useMemo(() => {
    return axiosToPromiseFn(getBotLiffChannelId(botId));
  }, [botId]);

  const { data: { liff_channel_id: liffChannelId = '' } = {} } = useAsync({
    promiseFn: getBotLiffChannelIdFn,
  });

  return liffChannelId
    ? urlJoin(LINE_DEVELOPER_BASE_URL, 'console/channel', liffChannelId, 'liff')
    : LINE_DEVELOPER_BASE_URL;
};
