import { close } from 'features/customerSupport/zendesk/close';
import { hide } from 'features/customerSupport/zendesk/hide';
import { initialize } from 'features/customerSupport/zendesk/initialize';
import { loginUser } from 'features/customerSupport/zendesk/loginUser';
import { logoutUser } from 'features/customerSupport/zendesk/logoutUser';
import { open } from 'features/customerSupport/zendesk/open';
import { setLocale } from 'features/customerSupport/zendesk/setLocale';
import { show } from 'features/customerSupport/zendesk/show';

import { logError } from 'lib/logger';

export interface CustomerSupportService {
  initialized: boolean;
  previousLang?: string;
  /** Initialize the service */
  initialize(apiKey: string): Promise<void>;
  /** Set up locale */
  setLocale(lang: string): void;
  /** Set up user details */
  setUserDetail<T>(details: T): void;
  /** Clear user details */
  unsetUserDetail(): void;
  /** Send message to the third-party support service */
  sendMessage(message?: string): void;
  /** Open the chat layout */
  open(): void;
  /** Close the chat layout */
  close(): void;
  /** Display the widget of the support service */
  show(): void;
  /** Hide the of the support service */
  hide(): void;
}

class ZendeskCustomerSupportService implements CustomerSupportService {
  initialized = false;
  previousLang?: string;

  async initialize(apiKey: string) {
    // Ensure to initialize only once
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    await initialize(apiKey);
  }

  setLocale(lang: string) {
    // Avoid making the same requests if the language hasn't changed
    if (this.previousLang === lang) {
      return;
    }
    const hasUpdated = setLocale(lang);
    if (hasUpdated) {
      this.previousLang = lang;
    }
  }

  setUserDetail(details: unknown) {
    if (typeof details === 'string') {
      loginUser(details);
    } else {
      try {
        loginUser(JSON.stringify(details));
      } catch (error) {
        logError(error);
      }
    }
  }

  unsetUserDetail() {
    logoutUser();
  }

  sendMessage(message?: string) {
    open();
  }

  open() {
    open();
  }

  close() {
    close();
  }

  show() {
    show();
  }

  hide() {
    hide();
  }
}

const zendeskCustomerSupportService = new ZendeskCustomerSupportService();

export { zendeskCustomerSupportService as customerSupport };
