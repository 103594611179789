import { lazily } from 'react-lazily';

import { SESSION_STORAGE_CHUNK_ERROR_REFRESHED } from 'AppConstants';

import type { ComponentType } from 'react';

import { createZustandStorageStore } from 'utils/createZustandStorageStore';
import { boolean } from 'utils/pitch-shifter/boolean';

import { moduleWithErrorBoundary } from './withErrorBoundary';
import { moduleWithRoute } from './withRoute';

const { useStore } = createZustandStorageStore<boolean>(
  SESSION_STORAGE_CHUNK_ERROR_REFRESHED,
  boolean(),
  { storage: window.sessionStorage },
);

/**
 * Declare a lazy route component.
 *
 * ```ts
 * const { Page } = lazyRoute(() => import('/path/to/page'));
 * ```
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function lazyRoute<
  // Allowing any to inherit from lazy
  Module extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  },
>(factory: () => Promise<Module>) {
  return moduleWithErrorBoundary(moduleWithRoute(lazily(lazyRetry(factory))));
}

/**
 * Lazily load a React component with retry. If you are lazy loading a Reach Router route component, use `lazyRoute` instead.
 *
 * ```tsx
 * const { Component } = lazyWithRetry(() => import('/path/to/page'));
 * ```
 */
export function lazyWithRetry<
  // Allowing any to inherit from lazy
  Module extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  },
>(factory: () => Promise<Module>) {
  return lazily(lazyRetry(factory));
}

/**
 * Catch page chunk load error and reload the page. It only reloads once to avoid infinite reload.
 * Currently this function works with lazyRoute only.
 * Original code snippet from https://www.codemzy.com/blog/fix-chunkloaderror-react
 */
function lazyRetry<
  // Allowing any to inherit from lazy
  Module extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  },
>(factory: () => Promise<Module>): () => Promise<Module> {
  return () =>
    new Promise((resolve, reject) => {
      // Check if the window has already been refreshed
      const hasRefreshed = useStore.getState().value;

      // Try to import the component
      factory()
        .then((component) => {
          // Success so reset the refresh
          useStore.getState().setValue(false);
          resolve(component);
        })
        .catch((error) => {
          if (!hasRefreshed) {
            // Not been refreshed yet
            useStore.getState().setValue(true);
            return window.location.reload();
          }

          // Default error behavior as already tried refresh
          reject(error);
        });
    });
}
