import type { State } from './context';
import type { ActionTypes } from './types';

import * as Actions from './actions';

export const reducer = (state: State, action: ActionTypes): State => {
  switch (action.type) {
    case Actions.AuthAction.SET_STATE:
      return { ...state, ...action.payload };
    case Actions.AuthAction.SET_CURRENT_CHANNEL:
      return {
        ...state,
        currentChannel: {
          ...action.payload,
        },
      };
    case Actions.AuthAction.SET_CURRENT_BOT_DATA:
      return {
        ...state,
        currentBotInfo: action.payload,
      };
    default:
      return state;
  }
};
