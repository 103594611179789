import type { Box } from 'components/layoutUtils/Box';

/**
 * Warning: we plan to remove Styled System from the code base! Avoid using it
 * This is a list of props not to forward to the underling DOM node
 * Whereas we usually specify exactly what props to not forward for Styled Components
 * A blocklist is more appropriate for Styled System
 * Add additional props as needed; see {@link Box} component for examples
 *
 * @see https://github.com/styled-components/styled-components/releases/tag/v5.1.0
 */
const doNotForwardProps = [
  'alignContent',
  'alignItems',
  'backgroundColor',
  'bg',
  'borderBottom',
  'borderRadius',
  'boxShadow',
  'color',
  'component',
  'contentEditable',
  'display',
  'flexBasis',
  'flexDirection',
  'flexGrow',
  'flexWrap',
  'fontSize',
  'fontWeight',
  'justifyContent',
  'lineHeight',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'marginTop',
  'maxHeight',
  'maxWidth',
  'mb',
  'ml',
  'mr',
  'mt',
  'minHeight',
  'minWidth',
  'overflowY',
  'paddingRight',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'padding',
  'styledCss',
  'textAlign',
  'verticalAlign',
  'zIndex',
];

/**
 * This works the same as the standard `shouldNotForwardProps` function
 * But it adds the blocklist of Styled System specific props above
 */
export function shouldNotForwardSystemProps(...args: string[]) {
  return (prop: string) => ![...doNotForwardProps, ...args].includes(prop);
}
