import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';

import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import { theme } from 'theme';

import * as S from './Styled';

export const InvitationLinkDecoratorV2 = (props: {
  decoratedText: string;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  return (
    <S.DecoratorTagWrapper
      backgroundColor={theme.colors.green005}
      style={{
        pointerEvents: 'none',
      }}
      contentEditable={false}
    >
      <S.DecoratorReplaceTextWrapper>
        {props.decoratedText
          .replace('<$var:' + DecoratorType.ReferralInvitationLink, t('referralV2.invitationLink'))
          .replace('>', '')}
      </S.DecoratorReplaceTextWrapper>
      {props.children}
    </S.DecoratorTagWrapper>
  );
};
