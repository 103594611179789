// This code snippet is from https://github.com/davidmz/apng-js/blob/master/src/library/parser.js

// '\x89PNG\x0d\x0a\x1a\x0a'
const PNGSignature = new Uint8Array([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);

/**
 * Read the provided array of bytes as a string.
 *
 * @param {Uint8Array} bytes The array of bytes to read.
 * @param {number} offset Offset
 * @param {number} length Length
 * @returns {string} a string created from the specified sequence of UTF-16 code units
 */
function readString(bytes: Uint8Array, offset: number, length: number) {
  const chars = Array.prototype.slice.call(bytes.subarray(offset, offset + length));
  return String.fromCharCode(...chars);
}

/**
 * Iterate over the chunks in the APNG file.
 *
 * @param {Uint8Array} bytes The array of bytes to read.
 * @param {function(string, Uint8Array, number, number): boolean} callback The callback to call for each chunk.
 */
function eachChunk(
  bytes: Uint8Array,
  callback: (type: string, array: Uint8Array, offset: number, length: number) => boolean,
) {
  const dv = new DataView(bytes.buffer);

  let offset = 8,
    type = '',
    length = 0,
    res = true;

  do {
    length = dv.getUint32(offset);
    type = readString(bytes, offset + 4, 4);
    res = callback(type, bytes, offset, length);
    offset += 12 + length;
  } while (res !== false && type !== 'IEND' && offset < bytes.length);
}

/**
 * Check if the provided file is a valid APNG file.
 *
 * @param {File} file The file to check.
 * @returns if the file is a valid APNG file
 */
export const checkAPNG = (file: File): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    let isAPNG = true;
    const reader = new FileReader();

    reader.onerror = reject;

    reader.onload = () => {
      const bytes = new Uint8Array(reader.result as ArrayBuffer);

      // Check if it's a PNG
      if (PNGSignature.some((signature, index) => signature !== bytes[index])) {
        isAPNG = false;
        resolve(isAPNG);
      }

      // Check if it's an APNG
      let isAnimated = false;

      eachChunk(bytes, (type) => {
        isAnimated = type === 'acTL';
        return !isAnimated;
      });

      if (!isAnimated) {
        isAPNG = false;
        resolve(isAPNG);
      }

      resolve(isAPNG);
    };

    reader.readAsArrayBuffer(file);
  });
};
