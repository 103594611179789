import { useCallback, useEffect, useState } from 'react';

import type { Utm } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { AuthContextProps } from 'context/authContext';
import type { Dispatch, SetStateAction } from 'react';

import { useGaSettings } from 'context/authContext';
import { isUrl } from 'lib/validator/rules/url';
import { isTelUrl } from 'utils/url/isTelUrl';
import { checkIsGaTrackable } from 'utils/url/urlUTMModule';
import { extractParamsFromWebsiteUrl, sanitizeParams } from 'utils/url/utmParam';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAllNull = (obj: { [x: string]: any }): boolean => {
  let result = true;
  for (const key in obj) {
    if (!!obj[key] && obj[key] !== '') {
      result = false;
    }
  }
  return result;
};

export function useGATrack(
  authContext: AuthContextProps,
  urlValue: string,
  utm: Utm,
): {
  canUseUTMTracking: boolean | null;
  checkUrl: (
    urlValue: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trackableCallback?: ((fields: any) => void) | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    notTrackableCallback?: ((fields: any) => void) | undefined,
  ) => void;
  isGATrackable: boolean;
  setIsGATrackable: Dispatch<SetStateAction<boolean>>;
  isTracked: boolean;
  setIsTracked: Dispatch<SetStateAction<boolean>>;
} {
  const { withGaSettings } = useGaSettings();
  const [isGATrackable, setIsGATrackable] = useState(
    checkIsGaTrackable(authContext.state.ga, urlValue),
  );
  const [isTracked, setIsTracked] = useState(() => !isAllNull(utm));
  const checkUrl = useCallback(
    (
      urlValue: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      trackableCallback?: (fields: any) => void,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      notTrackableCallback?: (fields: any) => void,
    ) => {
      const fields: { [key: string]: string } = {};
      const searchParam = extractParamsFromWebsiteUrl(urlValue);
      const params = sanitizeParams(searchParam.params);
      const parses = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source'];
      parses.forEach((key) => {
        fields[key] = params[key] || '';
      });
      if (checkIsGaTrackable(authContext.state.ga, urlValue) && isUrl(urlValue)) {
        trackableCallback && trackableCallback(fields);
      } else {
        notTrackableCallback && notTrackableCallback(fields);
      }
    },
    [authContext.state.ga],
  );
  useEffect(() => {
    if (checkIsGaTrackable(authContext.state.ga, urlValue)) {
      setIsGATrackable(true);
      setIsTracked(true);
    } else {
      setIsTracked(false);
      setIsGATrackable(false);
    }
  }, [authContext.state.ga, urlValue]);

  return {
    canUseUTMTracking: authContext.state.currentChannel && withGaSettings && !isTelUrl(urlValue),
    checkUrl,
    isGATrackable,
    setIsGATrackable,
    isTracked,
    setIsTracked,
  };
}
