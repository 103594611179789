import { useCallback, useContext } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { PARAM_RICH_TEXT_FIELD_NAME_INDEX } from 'components/LineMessageEditor/constants';

import type { QuickReplyItemsProps } from './QuickReplyEditor';
import type { CardRichEditorIds } from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import type {
  EditorDataType,
  EditorStateObject,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorState } from 'draft-js';
import type { Dispatch, SetStateAction } from 'react';

import { Box } from 'components';
import { ImageMapCarouselEditor } from 'components/LineMessageEditor/components/ImageMapCarouselEditor/ImageMapCarouselEditor';
import {
  isCardDataRule,
  isCarrouselDataRule,
  isImageDataRule,
  isImageMapCarouselDataRule,
  isImageMapDataRule,
  isNewsDataRule,
  isPrizeRedeemRule,
  isTextDataRule,
  isVideoDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { findEditorStateObjectById } from 'components/LineMessageEditor/utils/editorState';
import { useHandler } from 'hooks/useEventCallback';
import { EEditorType } from 'lib/validator/helper/validatorHelpers';

import { Context } from '../models';
import { isImageCarouselDataRule } from '../models/templateDataAndTypes/imageCarousel';
import { populateEmptyEditorsByIds } from '../utils/toEditorState';

import { CarrouselEditor } from './CarrouselEditor';
import { ImageCarouselEditor } from './ImageCarouselEditor';
import { NewsEditor } from './NewsEditor';
import { ParamImageEditor } from './ParamImageEditor';
import { QuickReplyEditor } from './QuickReplyEditor';
import { CardEditor, ImageMapEditor, PrizeRedeemEditor, RichTextEditor, VideoEditor } from '.';

interface SortableWrapperProps extends QuickReplyItemsProps {
  editorStateArray: EditorStateObject[];
  setEditorStateArray: Dispatch<SetStateAction<EditorStateObject[]>>;
  allowedTextDataTag: DecoratorType[];
}

const { CardTitle: cardTitleIndex, CardDescription: cardDescriptionIndex } =
  PARAM_RICH_TEXT_FIELD_NAME_INDEX;

export const UnSortableList = ({
  editorStateArray,
  setEditorStateArray,
  quickReplyItems,
  setQuickReplyItems,
  allowedTextDataTag,
}: SortableWrapperProps) => {
  const { store } = useContext(Context);
  return (
    <div>
      {store.editorData.map((message, index) => {
        return (
          <SortableItems
            message={message}
            rowIndex={index}
            key={'LineEditorRow' + 0 + index}
            editorStateArray={editorStateArray}
            setEditorStateArray={setEditorStateArray}
            quickReplyItems={quickReplyItems}
            setQuickReplyItems={setQuickReplyItems}
            allowedTextDataTag={allowedTextDataTag}
          />
        );
      })}
      <QuickReplyEditor
        quickReplyItems={quickReplyItems}
        setQuickReplyItems={setQuickReplyItems}
        messagesCount={store.editorData.length}
      />
    </div>
  );
};

interface SortableItemsProps extends SortableWrapperProps, QuickReplyItemsProps {
  message: EditorDataType;
  rowIndex: number;
  allowedTextDataTag: DecoratorType[];
}

const SortableItems = ({
  message,
  rowIndex,
  editorStateArray,
  setEditorStateArray,
  allowedTextDataTag,
}: SortableItemsProps) => {
  // TODO: Refactor as a standalone hook for better React convention instead of passing as props
  const getEditorStateObject = useCallback(
    (richEditorId: number): EditorStateObject => {
      return findEditorStateObjectById(richEditorId, editorStateArray);
    },
    [editorStateArray],
  );
  const createSetEditorStateHandler = useCallback(
    (richEditorId: number) => {
      return function handleSetEditorState(editorState: EditorState): void {
        setEditorStateArray([
          ...editorStateArray.map((d) => {
            if (d.id === richEditorId) {
              return {
                ...d,
                editorState,
              };
            }
            return d;
          }),
        ]);
      };
    },
    [editorStateArray, setEditorStateArray],
  );
  const createDeleteEditorStateHandler = useCallback(
    (richEditorId: number[]) => {
      return function handleDeleteEditorState(): void {
        setEditorStateArray((arr) => {
          return arr.filter((item) => richEditorId.indexOf(item.id) === -1);
        });
      };
    },
    [setEditorStateArray],
  );
  const handleAddCarousel = useHandler((richEditorIds: CardRichEditorIds) => {
    setEditorStateArray((arr) => {
      populateEmptyEditorsByIds(richEditorIds, (item) => {
        arr.push(item);
      });
      return arr;
    });
  });

  return (
    <div>
      {isTextDataRule(message) ? (
        <RichTextEditor
          editorStateObject={getEditorStateObject(message.format.richEditorId)}
          setEditorState={createSetEditorStateHandler(message.format.richEditorId)}
          message={message}
          rowIndex={rowIndex}
          onDelete={createDeleteEditorStateHandler([message.format.richEditorId])}
          onDeleteCallBack={() => {
            setEditorStateArray([
              ...editorStateArray.filter((d) => {
                return d.id !== message.format.richEditorId;
              }),
            ]);
          }}
          allowedTextDataTag={allowedTextDataTag}
        />
      ) : null}
      {isImageDataRule(message) ? (
        <ParamImageEditor rowIndex={rowIndex} message={message} required={true} />
      ) : null}
      {isImageMapDataRule(message) ? (
        <ImageMapEditor message={message} rowIndex={rowIndex} />
      ) : null}
      {isImageMapCarouselDataRule(message) ? (
        <ImageMapCarouselEditor message={message} rowIndex={rowIndex} />
      ) : null}
      {isCardDataRule(message) ? (
        <Box ml={40} mb={32}>
          <CardEditor
            message={message}
            rowIndex={rowIndex}
            editorType={EEditorType.CardEditor}
            titleEditorStateObject={getEditorStateObject(
              message.data.format[cardTitleIndex].richEditorId,
            )}
            setTitleEditorState={createSetEditorStateHandler(
              message.data.format[cardTitleIndex].richEditorId,
            )}
            descriptionEditorStateObject={getEditorStateObject(
              message.data.format[cardDescriptionIndex].richEditorId,
            )}
            setDescriptionEditorState={createSetEditorStateHandler(
              message.data.format[cardDescriptionIndex].richEditorId,
            )}
            onDelete={createDeleteEditorStateHandler(
              message.data.format.map((f) => f.richEditorId),
            )}
          />
        </Box>
      ) : null}
      {isCarrouselDataRule(message) ? (
        <CarrouselEditor
          message={message}
          rowIndex={rowIndex}
          getEditorStateObject={getEditorStateObject}
          createSetEditorStateHandler={createSetEditorStateHandler}
          createDeleteEditorStateHandler={createDeleteEditorStateHandler}
          onAddCarousel={handleAddCarousel}
        />
      ) : null}
      {isImageCarouselDataRule(message) ? (
        <ImageCarouselEditor message={message} rowIndex={rowIndex} />
      ) : null}
      {isVideoDataRule(message) ? <VideoEditor message={message} rowIndex={rowIndex} /> : null}
      {isNewsDataRule(message) ? <NewsEditor message={message} rowIndex={rowIndex} /> : null}
      {isPrizeRedeemRule(message) ? (
        <PrizeRedeemEditor message={message} rowIndex={rowIndex} />
      ) : null}
    </div>
  );
};

const SortableItem = SortableElement((props: SortableItemsProps) => <SortableItems {...props} />);

export const SortableList = SortableContainer(
  ({
    editorStateArray,
    setEditorStateArray,
    quickReplyItems,
    setQuickReplyItems,
    allowedTextDataTag,
  }: SortableWrapperProps) => {
    const { store } = useContext(Context);
    return (
      <div>
        {store.editorData.map((message, index) => {
          return (
            <SortableItem
              rowIndex={index}
              message={message}
              index={index}
              key={'LineEditorRow' + index}
              editorStateArray={editorStateArray}
              setEditorStateArray={setEditorStateArray}
              quickReplyItems={quickReplyItems}
              setQuickReplyItems={setQuickReplyItems}
              allowedTextDataTag={allowedTextDataTag}
            />
          );
        })}
        <QuickReplyEditor
          quickReplyItems={quickReplyItems}
          setQuickReplyItems={setQuickReplyItems}
          messagesCount={store.editorData.length}
        />
      </div>
    );
  },
);
