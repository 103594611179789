import type { DraftDecorator } from 'draft-js';

import { CustomDecorator } from './CustomDecorator';
import { InvitationLinkDecoratorV2 } from './InvitationLinkDecorator';
import { InviteeNameDecoratorV2 } from './InviteeNameDecorator';
import { InviterNameDecoratorV2 } from './InviterNameDecorator';
import { NameDecorator } from './NameDecorator';
import { ParamRichTextCustomDecorator } from './ParamRichTextCustomDecorator';
import { ReferralActivityLinkDecorator } from './ReferralActivityLinkDecorator';
import {
  customStrategy,
  customTextStrategy,
  invitationLinkStrategy,
  inviteeNameStrategy,
  inviterNameStrategy,
  referralActivityLinkStrategy,
  urlStrategy,
  userNameStrategy,
} from './strategy';
import { UrlDecorator } from './UrlDecorator';

export const composedDecorator: Array<DraftDecorator> = [
  { strategy: userNameStrategy, component: NameDecorator },
  {
    strategy: urlStrategy,
    component: UrlDecorator,
  },
  { strategy: customStrategy, component: CustomDecorator },
  { strategy: referralActivityLinkStrategy, component: ReferralActivityLinkDecorator },
  { strategy: invitationLinkStrategy, component: InvitationLinkDecoratorV2 },
  { strategy: inviterNameStrategy, component: InviterNameDecoratorV2 },
  { strategy: inviteeNameStrategy, component: InviteeNameDecoratorV2 },
];

export const composedDecoratorWithParamRichTextEditor: Array<DraftDecorator> = [
  { strategy: userNameStrategy, component: NameDecorator },
  { strategy: customTextStrategy, component: ParamRichTextCustomDecorator },
];
