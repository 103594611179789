import ContentLoader from 'react-content-loader';

import { theme } from 'theme';

export const Skeleton = () => {
  return (
    <ContentLoader
      height={25}
      width={300}
      speed={2}
      backgroundColor={theme.colors.neutral002}
      foregroundColor={theme.colors.neutral001}
    >
      <rect x="0" y="3" rx="4" ry="4" width="66" height="18" />
      <rect x="74" y="3" rx="4" ry="4" width="52" height="18" />
      <rect x="134" y="3" rx="4" ry="4" width="50" height="18" />
    </ContentLoader>
  );
};
