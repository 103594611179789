import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { MessageSchema } from 'shared/components/Editor/LineMessageEditor/types';
import { PageResponseSchema } from 'shared/models/common';

export const BindlinkFilterEnum = {
  Enebled: 'enabled',
  Archived: 'archived',
} as const;

const BindlinkFilterSchema = z.nativeEnum(BindlinkFilterEnum);

export type BindlinkFilterType = z.output<typeof BindlinkFilterSchema>;

const BindlinkMemberMessageSchema = z.object({
  messages: MessageSchema.array(), // TODO: this should be equivalent to EditorDataType[]
  available: z.boolean(),
});

const BindlinkSchema = z.object({
  archived: z.boolean(),
  bot: z.number().int(),
  created_at: z.string(),
  description: z.string().nullable(),
  exist_member_message: BindlinkMemberMessageSchema,
  id: z.number().int(),
  link: z.string().nullable(),
  name: z.string(),
  new_member_message: BindlinkMemberMessageSchema,
  report: z.object({
    clicks: z.number().int(),
    join_new_member: z.number().int(),
    join_original_member: z.number().int(),
  }),
  shorten_url: z.string().nullable(),
  tag: z.array(z.string()),
  type: z.literal('bind_link'),
});

const BindlinkCountSchema = z.object({
  archived: z.boolean(),
  count: z.number().int(),
});

export const bindlinkApi = makeApi([
  {
    alias: 'bindlinkList',
    method: 'get',
    path: '/line/v1/guidelink/bindlink/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({ results: BindlinkSchema.array() }).strict(),
  },
  {
    alias: 'bindlinkListMetrics',
    method: 'get',
    path: '/line/v1/guidelink/bindlink/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: BindlinkCountSchema.array(),
  },
  {
    alias: 'bindlinkDetail',
    method: 'get',
    path: '/line/v1/guidelink/bindlink/:bindlinkId/',
    response: BindlinkSchema.strict(),
  },
  {
    alias: 'bindlinkUpdate',
    method: 'put',
    path: '/line/v1/guidelink/bindlink/:id/',
    parameters: [
      {
        name: 'body',
        schema: BindlinkSchema.partial(),
        type: 'Body',
      },
    ],
    response: BindlinkSchema.strict(),
  },
  {
    alias: 'bindlinkCreate',
    method: 'post',
    path: '/line/v1/guidelink/bindlink/',
    parameters: [
      {
        name: 'body',
        schema: BindlinkSchema.partial(),
        type: 'Body',
      },
    ],
    response: BindlinkSchema.strict(),
  },
]);

export type BindlinkListResponse = ZodiosResponseByAlias<typeof bindlinkApi, 'bindlinkList'>;

export type BindlinkListMetricsResponse = ZodiosResponseByAlias<
  typeof bindlinkApi,
  'bindlinkListMetrics'
>;

export type Bindlink = z.output<typeof BindlinkSchema>;

export const MessageMemberEnum = {
  New: 'new',
  Original: 'original',
} as const;

const MessageMemberSchema = z.nativeEnum(MessageMemberEnum);
export type MessageMemberType = z.infer<typeof MessageMemberSchema>;

export type MemberAvailable = {
  [MessageMemberEnum.New]: boolean;
  [MessageMemberEnum.Original]: boolean;
};
