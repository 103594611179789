import { border, layout, position } from 'styled-system';

import type { ActionBlockStyles } from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import type { BorderProps, LayoutProps, PositionProps } from 'styled-system';

import { keyframes, styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

interface ImageMapActionsBlockProps extends PositionProps, BorderProps, LayoutProps {
  blockIndex: number;
  isActive: boolean;
}
const show = keyframes`
  from {
    transform:scale(0.85) ;
    opacity:0.5;
  }

  to {
    transform:scale(1);
    opacity:1;
  }
`;

const ImageActionsBlock = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps('isActive', 'blockIndex'),
})<ImageMapActionsBlockProps>`
  ${position}
  ${layout}
  ${border}
  background-color:${({ isActive }) => (isActive ? theme.colors.cyan004 : 'transparent')};
  opacity: ${({ isActive }) => (isActive ? 0.7 : 1)};
  transition: 0.5s background-color ease-in-out;
  animation: ${show} 0.35s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: rgba(230, 232, 235, 0.7);
  }
  &::before {
    content: '${({ blockIndex }) => blockIndex}';
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    top: 8px;
    left: 8px;
    line-height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 255, 215, 0.7);
  }
`;

ImageActionsBlock.defaultProps = {
  position: 'absolute',
  border: `1px solid ${theme.colors.cyan004}`,
};

const ActionsBlockWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
`;

interface Props {
  activeBlockIndex: number;
  actionBlockStyles: ActionBlockStyles[];
}

export function ImageMapActiveBlock({ activeBlockIndex, actionBlockStyles }: Props) {
  return (
    <ActionsBlockWrapper>
      {actionBlockStyles.map((styles, index) => (
        <ImageActionsBlock
          blockIndex={index + 1}
          isActive={index + 1 === activeBlockIndex}
          {...styles}
          key={'imageMapActionActiveBlock' + index}
        />
      ))}
    </ActionsBlockWrapper>
  );
}
