import { logError } from 'lib/logger';

export const loginUser = (userDetailToken: string): void => {
  try {
    window.zE?.('messenger', 'loginUser', (callback) => {
      callback(userDetailToken);
    });
  } catch (error) {
    logError(error);
  }
};
