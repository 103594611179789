export interface RSS {
  type: string | undefined;
  title: string | undefined;
  links: {
    url: string | undefined;
    rel: string | undefined;
  }[];
  description: string | undefined;
  language: string | undefined;
  copyright: string | undefined;
  authors: {
    name: string | undefined;
  }[];
  lastUpdated: string | undefined;
  lastPublished: string | undefined;
  categories: {
    name: string | undefined;
  }[];
  image: {
    url: string | undefined;
    title: string | undefined;
    description: string | undefined;
    width: string | undefined;
    height: string | undefined;
  };
  itunes: {
    authors: {
      name: string | undefined;
    }[];
    block: string | undefined;
    categories: {
      name: string | undefined;
      subCategories: {
        name: string | undefined;
      }[];
    }[];
    image: string | undefined;
    explicit: string | undefined;
    complete: string | undefined;
    newFeedUrl: string | undefined;
    owner: {
      name: string | undefined;
      email: string | undefined;
    };
    subtitle: string | undefined;
    summary: string | undefined;
  };
  items: {
    title: string | undefined;
    links: {
      url: string | undefined;
      rel: string | undefined;
    }[];
    id: string | undefined;
    imageUrl: string | undefined;
    description: string | undefined;
    content: string | undefined;
    categories: {
      name: string | undefined;
    }[];
    authors: {
      name: string | undefined;
    }[];
    published: string | undefined;
    enclosures: {
      url: string | undefined;
      length: string | undefined;
      mimeType: string | undefined;
    }[];
    itunes: {
      authors: {
        name: string | undefined;
      }[];
      block: string | undefined;
      duration: string | undefined;
      explicit: string | undefined;
      image: string | undefined;
      isClosedCaptioned: string | undefined;
      order: string | undefined;
      subtitle: string | undefined;
      summary: string | undefined;
    };
  }[];
}

export const rss: RSS = {
  type: undefined,
  title: undefined,
  links: [],
  description: undefined,
  language: undefined,
  copyright: undefined,
  authors: [],
  lastUpdated: undefined,
  lastPublished: undefined,
  categories: [],
  image: {
    url: undefined,
    title: undefined,
    description: undefined,
    width: undefined,
    height: undefined,
  },
  itunes: {
    authors: [],
    block: undefined,
    categories: [],
    image: undefined,
    explicit: undefined,
    complete: undefined,
    newFeedUrl: undefined,
    owner: {
      name: undefined,
      email: undefined,
    },
    subtitle: undefined,
    summary: undefined,
  },
  items: [],
};
