import urlJoin from 'url-join';

import { maacFirebase } from 'lib/firebase/main';
import { uuid } from 'utils/uuid';

type ImageDetail = {
  file: Blob | File;
  functionName: string;
  width?: number;
};

/**
 * Upload multiple sizes of image with designated path to fit the requirement of LINE
 * @param files
 * @param path file path prefix, this is for categorizing files in the storage. It should be with a trailing slash, eg. 'path/to/image/'
 */
export const uploadMultipleImageSizes = async (
  files: ImageDetail[],
  path: string,
): Promise<string[]> => {
  const results = [];
  const uniqueName = uuid().split('-').join('');
  const widths = files.map((item) => item.width);
  const distinctWidths = widths.filter((value, index, arr) => arr.indexOf(value) === index);

  if (distinctWidths.length !== widths.length) {
    throw new Error('Potential duplicated image sizes, please double check your inputs');
  }

  for (const image of files) {
    const [fileType] = image.file.type.split('/');

    // For details of URL rule, see https://developers.line.biz/en/reference/messaging-api/#base-url
    const uploadResultUrl = await maacFirebase.doUpload(
      image.file,
      urlJoin(path, image.functionName, fileType, uniqueName, image.width ? `${image.width}` : ''),
    );
    results.push(uploadResultUrl);
  }

  return results;
};
