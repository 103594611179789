import { memo } from 'react';

import { generateIcon } from './util';

export const LineMessageText = generateIcon(function LineMessageTextSvg() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1667 2.625H1.66666V4.125H12.1667V2.625ZM12.1667 10.125H1.66666V11.625H12.1667V10.125ZM1.66666 6.375H16.6667V7.875H1.66666V6.375ZM16.6667 13.875H1.66666V15.375H16.6667V13.875Z"
        fill="#A1A8B3"
      />
    </svg>
  );
});

export const LineMessageImage = generateIcon(function LineMessageImageSvg() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4643 3H2.53578C2.23946 3 2.00006 3.24375 2.00006 3.54545V14.4545C2.00006 14.7563 2.23946 15 2.53578 15H16.4643C16.7607 15 17.0001 14.7563 17.0001 14.4545V3.54545C17.0001 3.24375 16.7607 3 16.4643 3ZM15.7947 13.7727H3.20544V13.0926L5.52408 10.292L8.03692 13.3261L11.946 8.60795L15.7947 13.2545V13.7727ZM12.0481 7.0362L15.7947 11.5601V4.22711H3.20544V11.3981L5.42196 8.722C5.47553 8.65723 5.57263 8.65723 5.6262 8.722L8.03692 11.6317L11.8438 7.0362C11.8974 6.97143 11.9945 6.97143 12.0481 7.0362ZM5.60006 8.25C6.42847 8.25 7.10006 7.57841 7.10006 6.75C7.10006 5.92159 6.42847 5.25 5.60006 5.25C4.77165 5.25 4.10006 5.92159 4.10006 6.75C4.10006 7.57841 4.77165 8.25 5.60006 8.25ZM6.35006 6.75C6.35006 6.33482 6.01524 6 5.60006 6C5.18488 6 4.85006 6.33482 4.85006 6.75C4.85006 7.16518 5.18488 7.5 5.60006 7.5C6.01524 7.5 6.35006 7.16518 6.35006 6.75Z"
        fill="#A1A8B3"
      />
    </svg>
  );
});

export const LineMessageCard = generateIcon(function LineMessageCardSvg() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44166 2.86364H13.8917C14.2644 2.86364 14.5667 3.1689 14.5667 3.54545V11.7273H3.76666V3.54545C3.76666 3.1689 4.06886 2.86364 4.44166 2.86364ZM2.41666 13.0909V11.7273V3.54545C2.41666 2.41578 3.32328 1.5 4.44166 1.5H13.8917C15.01 1.5 15.9167 2.41578 15.9167 3.54545V11.7273V13.0909V14.4545C15.9167 15.5842 15.01 16.5 13.8917 16.5H4.44166C3.32328 16.5 2.41666 15.5842 2.41666 14.4545V13.0909ZM14.5667 13.0909V14.4545C14.5667 14.8311 14.2644 15.1364 13.8917 15.1364H4.44166C4.06886 15.1364 3.76666 14.8311 3.76666 14.4545V13.0909H14.5667Z"
        fill="#B1B5BA"
      />
    </svg>
  );
});

export const LineMessageCarrousel = generateIcon(function LineMessageCarrouselSvg() {
  return (
    <svg width="55" height="18" viewBox="0 0 55 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.77506 2.86364H14.2251C14.5979 2.86364 14.9001 3.1689 14.9001 3.54545V11.7273H4.10006V3.54545C4.10006 3.1689 4.40227 2.86364 4.77506 2.86364ZM2.75006 13.0909V11.7273V3.54545C2.75006 2.41578 3.65668 1.5 4.77506 1.5H14.2251C15.3434 1.5 16.2501 2.41578 16.2501 3.54545V11.7273V13.0909V14.4545C16.2501 15.5842 15.3434 16.5 14.2251 16.5H4.77506C3.65668 16.5 2.75006 15.5842 2.75006 14.4545V13.0909ZM14.9001 13.0909V14.4545C14.9001 14.8311 14.5979 15.1364 14.2251 15.1364H4.77506C4.40227 15.1364 4.10006 14.8311 4.10006 14.4545V13.0909H14.9001Z"
        fill="#B1B5BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7751 2.86364H32.2251C32.5979 2.86364 32.9001 3.1689 32.9001 3.54545V11.7273H22.1001V3.54545C22.1001 3.1689 22.4023 2.86364 22.7751 2.86364ZM20.7501 13.0909V11.7273V3.54545C20.7501 2.41578 21.6567 1.5 22.7751 1.5H32.2251C33.3434 1.5 34.2501 2.41578 34.2501 3.54545V11.7273V13.0909V14.4545C34.2501 15.5842 33.3434 16.5 32.2251 16.5H22.7751C21.6567 16.5 20.7501 15.5842 20.7501 14.4545V13.0909ZM32.9001 13.0909V14.4545C32.9001 14.8311 32.5979 15.1364 32.2251 15.1364H22.7751C22.4023 15.1364 22.1001 14.8311 22.1001 14.4545V13.0909H32.9001Z"
        fill="#B1B5BA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.7751 2.86364H50.2251C50.5979 2.86364 50.9001 3.1689 50.9001 3.54545V11.7273H40.1001V3.54545C40.1001 3.1689 40.4023 2.86364 40.7751 2.86364ZM38.7501 13.0909V11.7273V3.54545C38.7501 2.41578 39.6567 1.5 40.7751 1.5H50.2251C51.3434 1.5 52.2501 2.41578 52.2501 3.54545V11.7273V13.0909V14.4545C52.2501 15.5842 51.3434 16.5 50.2251 16.5H40.7751C39.6567 16.5 38.7501 15.5842 38.7501 14.4545V13.0909ZM50.9001 13.0909V14.4545C50.9001 14.8311 50.5979 15.1364 50.2251 15.1364H40.7751C40.4023 15.1364 40.1001 14.8311 40.1001 14.4545V13.0909H50.9001Z"
        fill="#B1B5BA"
      />
    </svg>
  );
});

export const LineMessageImageCarousel = generateIcon(function LineMessageImageCarouselSvg() {
  return (
    <svg width="55" height="18" viewBox="0 0 55 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7977 3H2.86909C2.57277 3 2.33337 3.24375 2.33337 3.54545V14.4545C2.33337 14.7563 2.57277 15 2.86909 15H16.7977C17.094 15 17.3334 14.7563 17.3334 14.4545V3.54545C17.3334 3.24375 17.094 3 16.7977 3ZM16.128 13.7727H3.53876V13.0926L5.8574 10.292L8.37023 13.3261L12.2793 8.60795L16.128 13.2545V13.7727ZM12.3814 7.0362L16.128 11.5601V4.22711H3.53876V11.3981L5.75528 8.722C5.80885 8.65723 5.90595 8.65723 5.95952 8.722L8.37023 11.6317L12.1772 7.0362C12.2307 6.97143 12.3278 6.97143 12.3814 7.0362ZM5.93337 8.25C6.76178 8.25 7.43337 7.57841 7.43337 6.75C7.43337 5.92159 6.76178 5.25 5.93337 5.25C5.10496 5.25 4.43337 5.92159 4.43337 6.75C4.43337 7.57841 5.10496 8.25 5.93337 8.25ZM6.68337 6.75C6.68337 6.33482 6.34855 6 5.93337 6C5.5182 6 5.18337 6.33482 5.18337 6.75C5.18337 7.16518 5.5182 7.5 5.93337 7.5C6.34855 7.5 6.68337 7.16518 6.68337 6.75Z"
        fill="#A1A8B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.7977 3H20.8691C20.5728 3 20.3334 3.24375 20.3334 3.54545V14.4545C20.3334 14.7563 20.5728 15 20.8691 15H34.7977C35.094 15 35.3334 14.7563 35.3334 14.4545V3.54545C35.3334 3.24375 35.094 3 34.7977 3ZM34.128 13.7727H21.5388V13.0926L23.8574 10.292L26.3702 13.3261L30.2793 8.60795L34.128 13.2545V13.7727ZM30.3814 7.0362L34.128 11.5601V4.22711H21.5388V11.3981L23.7553 8.722C23.8088 8.65723 23.9059 8.65723 23.9595 8.722L26.3702 11.6317L30.1772 7.0362C30.2307 6.97143 30.3278 6.97143 30.3814 7.0362ZM23.9334 8.25C24.7618 8.25 25.4334 7.57841 25.4334 6.75C25.4334 5.92159 24.7618 5.25 23.9334 5.25C23.105 5.25 22.4334 5.92159 22.4334 6.75C22.4334 7.57841 23.105 8.25 23.9334 8.25ZM24.6834 6.75C24.6834 6.33482 24.3486 6 23.9334 6C23.5182 6 23.1834 6.33482 23.1834 6.75C23.1834 7.16518 23.5182 7.5 23.9334 7.5C24.3486 7.5 24.6834 7.16518 24.6834 6.75Z"
        fill="#A1A8B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.7977 3H38.8691C38.5728 3 38.3334 3.24375 38.3334 3.54545V14.4545C38.3334 14.7563 38.5728 15 38.8691 15H52.7977C53.094 15 53.3334 14.7563 53.3334 14.4545V3.54545C53.3334 3.24375 53.094 3 52.7977 3ZM52.128 13.7727H39.5388V13.0926L41.8574 10.292L44.3702 13.3261L48.2793 8.60795L52.128 13.2545V13.7727ZM48.3814 7.0362L52.128 11.5601V4.22711H39.5388V11.3981L41.7553 8.722C41.8088 8.65723 41.9059 8.65723 41.9595 8.722L44.3702 11.6317L48.1772 7.0362C48.2307 6.97143 48.3278 6.97143 48.3814 7.0362ZM41.9334 8.25C42.7618 8.25 43.4334 7.57841 43.4334 6.75C43.4334 5.92159 42.7618 5.25 41.9334 5.25C41.105 5.25 40.4334 5.92159 40.4334 6.75C40.4334 7.57841 41.105 8.25 41.9334 8.25ZM42.6834 6.75C42.6834 6.33482 42.3486 6 41.9334 6C41.5182 6 41.1834 6.33482 41.1834 6.75C41.1834 7.16518 41.5182 7.5 41.9334 7.5C42.3486 7.5 42.6834 7.16518 42.6834 6.75Z"
        fill="#A1A8B3"
      />
    </svg>
  );
});

export const LineMessageImagemap = generateIcon(function LineMessageImagemapSvg() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58337 3.75V6.825H7.65837V3.75H4.58337ZM4.08337 2.25C3.53109 2.25 3.08337 2.69772 3.08337 3.25V7.325C3.08337 7.87729 3.53109 8.325 4.08337 8.325H8.15837C8.71066 8.325 9.15837 7.87728 9.15837 7.325V3.25C9.15837 2.69772 8.71066 2.25 8.15837 2.25H4.08337ZM12.0084 6.825V3.75H15.0834V6.825H12.0084ZM10.5084 3.25C10.5084 2.69772 10.9561 2.25 11.5084 2.25H15.5834C16.1357 2.25 16.5834 2.69772 16.5834 3.25V7.325C16.5834 7.87728 16.1357 8.325 15.5834 8.325H11.5084C10.9561 8.325 10.5084 7.87729 10.5084 7.325V3.25ZM12.0084 14.25V11.175H15.0834V14.25H12.0084ZM10.5084 10.675C10.5084 10.1227 10.9561 9.675 11.5084 9.675H15.5834C16.1357 9.675 16.5834 10.1227 16.5834 10.675V14.75C16.5834 15.3023 16.1357 15.75 15.5834 15.75H11.5084C10.9561 15.75 10.5084 15.3023 10.5084 14.75V10.675ZM4.58337 14.25V11.175H7.65837V14.25H4.58337ZM3.08337 10.675C3.08337 10.1227 3.53109 9.675 4.08337 9.675H8.15837C8.71066 9.675 9.15837 10.1227 9.15837 10.675V14.75C9.15837 15.3023 8.71066 15.75 8.15837 15.75H4.08337C3.53109 15.75 3.08337 15.3023 3.08337 14.75V10.675Z"
        fill="#A1A8B3"
      />
    </svg>
  );
});

export const LineMessageImagemapCarousel = generateIcon(function LineMessageImagemapCarouselSvg() {
  return (
    <svg width="55" height="18" viewBox="0 0 55 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.91666 3.75V6.825H6.99166V3.75H3.91666ZM3.41666 2.25C2.86437 2.25 2.41666 2.69772 2.41666 3.25V7.325C2.41666 7.87729 2.86437 8.325 3.41666 8.325H7.49166C8.04394 8.325 8.49166 7.87728 8.49166 7.325V3.25C8.49166 2.69772 8.04394 2.25 7.49166 2.25H3.41666ZM11.3417 6.825V3.75H14.4167V6.825H11.3417ZM9.84166 3.25C9.84166 2.69772 10.2894 2.25 10.8417 2.25H14.9167C15.4689 2.25 15.9167 2.69772 15.9167 3.25V7.325C15.9167 7.87728 15.4689 8.325 14.9167 8.325H10.8417C10.2894 8.325 9.84166 7.87729 9.84166 7.325V3.25ZM11.3417 14.25V11.175H14.4167V14.25H11.3417ZM9.84166 10.675C9.84166 10.1227 10.2894 9.675 10.8417 9.675H14.9167C15.4689 9.675 15.9167 10.1227 15.9167 10.675V14.75C15.9167 15.3023 15.4689 15.75 14.9167 15.75H10.8417C10.2894 15.75 9.84166 15.3023 9.84166 14.75V10.675ZM3.91666 14.25V11.175H6.99166V14.25H3.91666ZM2.41666 10.675C2.41666 10.1227 2.86437 9.675 3.41666 9.675H7.49166C8.04394 9.675 8.49166 10.1227 8.49166 10.675V14.75C8.49166 15.3023 8.04394 15.75 7.49166 15.75H3.41666C2.86437 15.75 2.41666 15.3023 2.41666 14.75V10.675Z"
        fill="#A1A8B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9167 3.75V6.825H24.9917V3.75H21.9167ZM21.4167 2.25C20.8644 2.25 20.4167 2.69772 20.4167 3.25V7.325C20.4167 7.87729 20.8644 8.325 21.4167 8.325H25.4917C26.0439 8.325 26.4917 7.87728 26.4917 7.325V3.25C26.4917 2.69772 26.0439 2.25 25.4917 2.25H21.4167ZM29.3417 6.825V3.75H32.4167V6.825H29.3417ZM27.8417 3.25C27.8417 2.69772 28.2894 2.25 28.8417 2.25H32.9167C33.4689 2.25 33.9167 2.69772 33.9167 3.25V7.325C33.9167 7.87728 33.4689 8.325 32.9167 8.325H28.8417C28.2894 8.325 27.8417 7.87729 27.8417 7.325V3.25ZM29.3417 14.25V11.175H32.4167V14.25H29.3417ZM27.8417 10.675C27.8417 10.1227 28.2894 9.675 28.8417 9.675H32.9167C33.4689 9.675 33.9167 10.1227 33.9167 10.675V14.75C33.9167 15.3023 33.4689 15.75 32.9167 15.75H28.8417C28.2894 15.75 27.8417 15.3023 27.8417 14.75V10.675ZM21.9167 14.25V11.175H24.9917V14.25H21.9167ZM20.4167 10.675C20.4167 10.1227 20.8644 9.675 21.4167 9.675H25.4917C26.0439 9.675 26.4917 10.1227 26.4917 10.675V14.75C26.4917 15.3023 26.0439 15.75 25.4917 15.75H21.4167C20.8644 15.75 20.4167 15.3023 20.4167 14.75V10.675Z"
        fill="#A1A8B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.9167 3.75V6.825H42.9917V3.75H39.9167ZM39.4167 2.25C38.8644 2.25 38.4167 2.69772 38.4167 3.25V7.325C38.4167 7.87729 38.8644 8.325 39.4167 8.325H43.4917C44.0439 8.325 44.4917 7.87728 44.4917 7.325V3.25C44.4917 2.69772 44.0439 2.25 43.4917 2.25H39.4167ZM47.3417 6.825V3.75H50.4167V6.825H47.3417ZM45.8417 3.25C45.8417 2.69772 46.2894 2.25 46.8417 2.25H50.9167C51.4689 2.25 51.9167 2.69772 51.9167 3.25V7.325C51.9167 7.87728 51.4689 8.325 50.9167 8.325H46.8417C46.2894 8.325 45.8417 7.87729 45.8417 7.325V3.25ZM47.3417 14.25V11.175H50.4167V14.25H47.3417ZM45.8417 10.675C45.8417 10.1227 46.2894 9.675 46.8417 9.675H50.9167C51.4689 9.675 51.9167 10.1227 51.9167 10.675V14.75C51.9167 15.3023 51.4689 15.75 50.9167 15.75H46.8417C46.2894 15.75 45.8417 15.3023 45.8417 14.75V10.675ZM39.9167 14.25V11.175H42.9917V14.25H39.9167ZM38.4167 10.675C38.4167 10.1227 38.8644 9.675 39.4167 9.675H43.4917C44.0439 9.675 44.4917 10.1227 44.4917 10.675V14.75C44.4917 15.3023 44.0439 15.75 43.4917 15.75H39.4167C38.8644 15.75 38.4167 15.3023 38.4167 14.75V10.675Z"
        fill="#A1A8B3"
      />
    </svg>
  );
});

export const LineMessageVideo = generateIcon(function LineMessageVideoSvg() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50006 1.5C5.35832 1.5 2.00006 4.85826 2.00006 9C2.00006 13.1417 5.35832 16.5 9.50006 16.5C13.6418 16.5 17.0001 13.1417 17.0001 9C17.0001 4.85826 13.6418 1.5 9.50006 1.5ZM9.50006 15.2277C6.06144 15.2277 3.27238 12.4386 3.27238 9C3.27238 5.56138 6.06144 2.77232 9.50006 2.77232C12.9387 2.77232 15.7277 5.56138 15.7277 9C15.7277 12.4386 12.9387 15.2277 9.50006 15.2277ZM12.9722 8.78404L8.01513 5.18471C7.83767 5.0558 7.59158 5.18136 7.59158 5.40067V12.5993C7.59158 12.8186 7.83935 12.9425 8.01513 12.8153L12.9722 9.21596C13.1195 9.10882 13.1195 8.89118 12.9722 8.78404ZM8.65966 6.97266L11.4504 9L8.65966 11.0273V6.97266Z"
        fill="#B1B5BA"
      />
    </svg>
  );
});

export const LineMessagePrize = generateIcon(function LineMessagePrizeSvg() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3246 4.48387H11.8339C12.0634 4.13495 12.1984 3.72082 12.1984 3.27734C12.1984 2.03658 11.1538 1.02734 9.86962 1.02734C9.171 1.02734 8.54156 1.32734 8.11462 1.80017C7.68769 1.32734 7.05825 1.02734 6.35962 1.02734C5.07544 1.02734 4.03087 2.03658 4.03087 3.27734C4.03087 3.72082 4.16419 4.13495 4.39537 4.48387H1.90462C1.60594 4.48387 1.36462 4.71702 1.36462 5.0056V8.26647C1.36462 8.33821 1.42537 8.39691 1.49962 8.39691H2.17462V14.0056C2.17462 14.2942 2.41594 14.5273 2.71462 14.5273H13.5146C13.8133 14.5273 14.0546 14.2942 14.0546 14.0056V8.39691H14.7296C14.8039 8.39691 14.8646 8.33821 14.8646 8.26647V5.0056C14.8646 4.71702 14.6233 4.48387 14.3246 4.48387ZM8.68836 3.27734C8.68836 2.648 9.21823 2.13604 9.86961 2.13604C10.521 2.13604 11.0509 2.648 11.0509 3.27734C11.0509 3.90669 10.521 4.41865 9.86961 4.41865H8.68836V3.27734ZM7.54089 3.27734C7.54089 2.648 7.01101 2.13604 6.35964 2.13604C5.70827 2.13604 5.17839 2.648 5.17839 3.27734C5.17839 3.90669 5.70827 4.41865 6.35964 4.41865H7.54089V3.27734ZM2.51209 7.28821V5.59256H7.54084V7.28821H2.51209ZM7.54089 8.39691H3.32214V13.4186H7.54089V8.39691ZM12.9071 13.4186H8.68836V8.39691H12.9071V13.4186ZM8.68836 7.28821H13.7171V5.59256H8.68836V7.28821Z"
        fill="#A1A8B3"
      />
    </svg>
  );
});

export const LineMessageVerticalDrag = () => (
  <svg width="100%" height="100%" viewBox="0 0 96 96" version="1.1">
    <title>icon_vertical drag</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-192.000000, -576.000000)" fillRule="nonzero">
        <g id="icon_vertical-drag" transform="translate(192.000000, 577.000000)">
          <rect
            id="Rectangle-for-drag"
            fill="#000000"
            opacity="0"
            x="0"
            y="0"
            width="96"
            height="96"
          />
          <path
            d="M52.8020776,45.9779857 L52.8020776,23.7959825 L59.2768245,23.7959825 C59.8763381,23.7959825 60.2160624,23.09655 59.8463624,22.626931 L49.7745339,9.87727504 C49.484769,9.50757499 48.9252229,9.50757499 48.6454499,9.87727504 L38.5736214,22.626931 C38.2039214,23.09655 38.5336538,23.7959825 39.1431593,23.7959825 L45.6079143,23.7959825 L45.6079143,45.9779857 L45.5979224,53.172149 L45.5979224,75.3541522 L39.1231755,75.3541522 C38.5236619,75.3541522 38.1839376,76.0535848 38.5536376,76.5232037 L48.6254661,89.2628678 C48.915231,89.6325678 49.4747771,89.6325678 49.7545501,89.2628678 L59.8263786,76.5232037 C60.1960786,76.0535848 59.8663462,75.3541522 59.2568407,75.3541522 L52.8020776,75.3541522 L52.8020776,53.172149 L52.8020776,45.9779857 Z"
            id="Path-for-drag"
            fill="#A1A8B3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const LineMessageHorizontalDrag = () => (
  <svg width="100%" height="100%" viewBox="0 0 96 96" version="1.1">
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-288.000000, -576.000000)" fillRule="nonzero">
        <g transform="translate(336.000000, 625.000000) rotate(-270.000000) translate(-336.000000, -625.000000) translate(288.000000, 577.000000)">
          <rect id="h-darg-rect" fill="#000000" opacity="0" x="0" y="0" width="96" height="96" />
          <path
            d="M52.8020776,45.9779857 L52.8020776,23.7959825 L59.2768245,23.7959825 C59.8763381,23.7959825 60.2160624,23.09655 59.8463624,22.626931 L49.7745339,9.87727504 C49.484769,9.50757499 48.9252229,9.50757499 48.6454499,9.87727504 L38.5736214,22.626931 C38.2039214,23.09655 38.5336538,23.7959825 39.1431593,23.7959825 L45.6079143,23.7959825 L45.6079143,45.9779857 L45.5979224,53.172149 L45.5979224,75.3541522 L39.1231755,75.3541522 C38.5236619,75.3541522 38.1839376,76.0535848 38.5536376,76.5232037 L48.6254661,89.2628678 C48.915231,89.6325678 49.4747771,89.6325678 49.7545501,89.2628678 L59.8263786,76.5232037 C60.1960786,76.0535848 59.8663462,75.3541522 59.2568407,75.3541522 L52.8020776,75.3541522 L52.8020776,53.172149 L52.8020776,45.9779857 Z"
            id="Path"
            fill="#A1A8B3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const LineMessagePoint = () => (
  <svg width="100%" height="100%" viewBox="0 0 96 96" version="1.1">
    <title>icon_point</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-96.000000, -192.000000)" fillRule="nonzero">
        <g id="icon_point" transform="translate(96.000000, 192.000000)">
          <rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="96" height="96" />
          <path
            d="M48,6 C24.804,6 6,24.8040937 6,48 C6,71.1959062 24.804,90 48,90 C71.196,90 90,71.196 90,48 C90,24.804 71.196,6 48,6 Z M48,84 C28.1176875,84 12,67.8822187 12,48 C12,28.1177813 28.1176875,12 48,12 C67.8822187,12 84,28.1177813 84,48 C84,67.8822187 67.8822187,84 48,84 Z M51,24.09375 L42,24.09375 L39,24.09375 C37.3431562,24.09375 35.90625,25.3431562 35.90625,27 L35.90625,30 L35.90625,48 L35.90625,54 L35.90625,69 C35.90625,70.6568438 37.2494062,72 38.90625,72 C40.5630938,72 41.90625,70.6568438 41.90625,69 L41.90625,54.09375 L51,54.09375 C59.2843125,54.09375 66,47.3780625 66,39.09375 C66,30.8094375 59.2843125,24.09375 51,24.09375 Z M60,39.09375 C60,44.0564063 55.9626562,48.09375 51,48.09375 L41.90625,48.09375 L41.90625,48 L41.90625,30.09375 L42,30.09375 L42,30 L51,30 C55.970625,30 60,34.029375 60,39 C60,39.0156562 59.9994375,39.0312188 59.9994375,39.0469687 C59.9994375,39.0625312 60,39.0780938 60,39.09375 Z"
            id="Shape"
            fill="#A1A8B3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const LineMessageInvitation = () => (
  <svg width="100%" height="100%" viewBox="0 0 96 96" version="1.1">
    <title>icon_invitation</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard" transform="translate(-384.000000, -384.000000)" fillRule="nonzero">
        <g id="icon_invitation" transform="translate(384.000000, 384.000000)">
          <rect fill="#000000" opacity="0" x="0" y="0" width="96" height="96" />
          <path
            d="M48,5.439 L46.311,6.468 L10.311,30.468 L9,31.41 L9,84 L87,84 L87,31.407 L85.686,30.468 L49.686,6.468 L48,5.436 L48,5.439 Z M48,12.657 L78.75,33.186 L48,56.25 L17.25,33.186 L48,12.66 L48,12.657 Z M45,21 L45,30 L36,30 L36,36 L45,36 L45,45 L51,45 L51,36 L60,36 L60,30 L51,30 L51,21 L45,21 Z M15,39 L46.218,62.439 L48,63.75 L49.782,62.436 L81,39 L81,78 L15,78 L15,39 Z"
            id="Shape"
            fill="#A1A8B3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Cell1 = () => (
  <svg width="100%" height="100%" viewBox="0 0 55 55" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="cells-stroke-layer"
        transform="translate(-871.000000, -306.000000)"
        stroke="#D5DAE0"
        strokeWidth="2"
      >
        <rect id="Rectangle" x="872" y="307" width="53" height="53" />
      </g>
    </g>
  </svg>
);

export const Cell1Of1 = () => (
  <svg width="43px" height="32px" viewBox="0 0 43 32" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit"
        transform="translate(-278.000000, -500.000000)"
      >
        <g id="Group-7-Copy" transform="translate(277.000000, 496.000000)">
          <g
            id="cells-stroke-layer"
            transform="translate(12.000000, 4.000000)"
            stroke="#D5DAE0"
            strokeWidth="2"
          >
            <g id="Group-2-Copy">
              <rect id="Rectangle-Copy" x="1" y="1" width="30" height="30" />
            </g>
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              1
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells4 = () => (
  <svg width="100%" height="100%" viewBox="0 0 55 55" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="cells-stroke-layer"
        transform="translate(-942.000000, -306.000000)"
        stroke="#D5DAE0"
        strokeWidth="2"
      >
        <g id="Group-2" transform="translate(942.000000, 306.000000)">
          <rect id="Rectangle-Copy" x="1" y="1" width="53" height="53" />
          <path d="M27.5,3 L27.5,53" id="Line-2" strokeLinecap="square" />
          <path
            d="M28,3 L28,53"
            id="Line-2-Copy"
            strokeLinecap="square"
            transform="translate(28.000000, 28.000000) rotate(90.000000) translate(-28.000000, -28.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export const Cells4Of1 = () => (
  <svg width="43px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit"
        transform="translate(-904.000000, -500.000000)"
      >
        <g id="Group-7" transform="translate(903.000000, 496.000000)">
          <g id="Group-4" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <rect id="Rectangle-Copy" x="1" y="1" width="30" height="30" />
              <path d="M16,1.74545455 L16,29.5030305" id="Line-2" strokeLinecap="square" />
              <path
                d="M15.6242423,2.41212109 L15.6242423,30.1696971"
                id="Line-2-Copy"
                strokeLinecap="square"
                transform="translate(15.624242, 16.290909) rotate(90.000000) translate(-15.624242, -16.290909) "
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="0" y="0" width="16" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              1
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells4Of2 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit"
        transform="translate(-963.000000, -500.000000)"
      >
        <g id="Group-6" transform="translate(963.000000, 496.000000)">
          <g id="Group-4-Copy" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#E6E8EB" strokeWidth="2">
              <rect id="Rectangle-Copy" x="1" y="1" width="29.2195122" height="30" />
              <path
                d="M15.6097561,1.74545455 L15.6097561,29.5030305"
                id="Line-2"
                strokeLinecap="square"
              />
              <path
                d="M14.5602365,3.43355509 L14.5602365,29.1482631"
                id="Line-2-Copy"
                strokeLinecap="square"
                transform="translate(14.560237, 16.290909) rotate(90.000000) translate(-14.560237, -16.290909) "
              />
            </g>
            <rect id="cells-fill-layer" fill="#E6E8EB" x="14" y="0" width="16" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              2
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells4Of3 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit"
        transform="translate(-1023.000000, -500.000000)"
      >
        <g id="Group-8" transform="translate(1023.000000, 496.000000)">
          <g id="Group-4-Copy-2" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#E6E8EB" strokeWidth="2">
              <rect id="Rectangle-Copy" x="1" y="1" width="29.2195122" height="30" />
              <path
                d="M15.6097561,1.74545455 L15.6097561,29.5030305"
                id="Line-2"
                strokeLinecap="square"
              />
              <path
                d="M15.5602365,2.43355509 L15.5602365,30.1482631"
                id="Line-2-Copy"
                strokeLinecap="square"
                transform="translate(15.560237, 16.290909) rotate(90.000000) translate(-15.560237, -16.290909) "
              />
            </g>
            <rect id="cells-fill-layer" fill="#E6E8EB" x="0" y="15" width="16" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              3
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells4Of4 = () => (
  <svg width="43px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit"
        transform="translate(-1083.000000, -500.000000)"
      >
        <g id="Group-9" transform="translate(1083.000000, 496.000000)">
          <g id="Group-4-Copy-2" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#E6E8EB" strokeWidth="2">
              <rect id="Rectangle-Copy" x="1" y="1" width="29.2195122" height="30" />
              <path
                d="M15.6097561,1.74545455 L15.6097561,29.5030305"
                id="Line-2"
                strokeLinecap="square"
              />
              <path
                d="M15.5602365,2.43355509 L15.5602365,30.1482631"
                id="Line-2-Copy"
                strokeLinecap="square"
                transform="translate(15.560237, 16.290909) rotate(90.000000) translate(-15.560237, -16.290909) "
              />
            </g>
            <rect id="cells-fill-layer" fill="#E6E8EB" x="15" y="15" width="16" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              4
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6 = () => (
  <svg width="100%" height="100%" viewBox="0 0 55 55" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="cells-stroke-layer"
        transform="translate(-1013.000000, -306.000000)"
        stroke="#D5DAE0"
        strokeWidth="2"
      >
        <g id="Group-5" transform="translate(1013.000000, 306.000000)">
          <g id="Group">
            <rect id="Rectangle-Copy-2" x="1" y="1" width="53" height="53" />
            <path d="M35.5,3 L35.5,53" id="Line-2-Copy-4" strokeLinecap="square" />
            <path
              d="M28,3 L28,53"
              id="Line-2-Copy-3"
              strokeLinecap="square"
              transform="translate(28.000000, 28.000000) rotate(90.000000) translate(-28.000000, -28.000000) "
            />
          </g>
          <path d="M18.5,3 L18.5,53" id="Line-2-Copy-2" strokeLinecap="square" />
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6Of1 = () => (
  <svg width="43px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit_add-tag"
        transform="translate(-897.000000, -500.000000)"
      >
        <g id="Group-6" transform="translate(896.000000, 496.000000)">
          <g id="Group-4" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <g id="Group-Copy-4">
                <rect id="Rectangle-Copy-2" x="1" y="1" width="30" height="30" />
                <path
                  d="M20.6545455,1.74545455 L20.6545455,30.8363636"
                  id="Line-2-Copy-4"
                  strokeLinecap="square"
                />
                <path
                  d="M16.2909091,1.74545455 L16.2909091,30.8363636"
                  id="Line-2-Copy-3"
                  strokeLinecap="square"
                  transform="translate(16.290909, 16.290909) rotate(90.000000) translate(-16.290909, -16.290909) "
                />
              </g>
              <path
                d="M10.7636364,1.74545455 L10.7636364,30.8363636"
                id="Line-2-Copy-5"
                strokeLinecap="square"
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="1" y="0" width="10" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              1
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6Of2 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit_add-tag"
        transform="translate(-956.000000, -500.000000)"
      >
        <g id="Group-7" transform="translate(956.000000, 496.000000)">
          <g id="Group-4-Copy" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <g id="Group-Copy-4">
                <rect id="Rectangle-Copy-2" x="1" y="1" width="30" height="30" />
                <path
                  d="M20.6545455,1.74545455 L20.6545455,30.8363636"
                  id="Line-2-Copy-4"
                  strokeLinecap="square"
                />
                <path
                  d="M16.2909091,1.74545455 L16.2909091,30.8363636"
                  id="Line-2-Copy-3"
                  strokeLinecap="square"
                  transform="translate(16.290909, 16.290909) rotate(90.000000) translate(-16.290909, -16.290909) "
                />
              </g>
              <path
                d="M10.7636364,1.74545455 L10.7636364,30.8363636"
                id="Line-2-Copy-5"
                strokeLinecap="square"
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="11" y="0" width="10" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              2
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6Of3 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit_add-tag"
        transform="translate(-1016.000000, -500.000000)"
      >
        <g id="Group-8" transform="translate(1016.000000, 496.000000)">
          <g id="Group-4-Copy-2" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <g id="Group-Copy-4">
                <rect id="Rectangle-Copy-2" x="1" y="1" width="30" height="30" />
                <path
                  d="M20.6545455,1.74545455 L20.6545455,30.8363636"
                  id="Line-2-Copy-4"
                  strokeLinecap="square"
                />
                <path
                  d="M16.2909091,1.74545455 L16.2909091,30.8363636"
                  id="Line-2-Copy-3"
                  strokeLinecap="square"
                  transform="translate(16.290909, 16.290909) rotate(90.000000) translate(-16.290909, -16.290909) "
                />
              </g>
              <path
                d="M10.7636364,1.74545455 L10.7636364,30.8363636"
                id="Line-2-Copy-5"
                strokeLinecap="square"
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="21" y="0" width="10" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              3
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6Of4 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit_add-tag"
        transform="translate(-1076.000000, -500.000000)"
      >
        <g id="Group-9" transform="translate(1076.000000, 496.000000)">
          <g id="Group-4-Copy-3" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <g id="Group-Copy-4">
                <rect id="Rectangle-Copy-2" x="1" y="1" width="30" height="30" />
                <path
                  d="M20.6545455,1.74545455 L20.6545455,30.8363636"
                  id="Line-2-Copy-4"
                  strokeLinecap="square"
                />
                <path
                  d="M16.2909091,1.74545455 L16.2909091,30.8363636"
                  id="Line-2-Copy-3"
                  strokeLinecap="square"
                  transform="translate(16.290909, 16.290909) rotate(90.000000) translate(-16.290909, -16.290909) "
                />
              </g>
              <path
                d="M10.7636364,1.74545455 L10.7636364,30.8363636"
                id="Line-2-Copy-5"
                strokeLinecap="square"
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="1" y="15" width="10" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              4
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6Of5 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit_add-tag"
        transform="translate(-1136.000000, -500.000000)"
      >
        <g id="Group-10" transform="translate(1136.000000, 496.000000)">
          <g id="Group-4-Copy-4" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <g id="Group-Copy-4">
                <rect id="Rectangle-Copy-2" x="1" y="1" width="30" height="30" />
                <path
                  d="M20.6545455,1.74545455 L20.6545455,30.8363636"
                  id="Line-2-Copy-4"
                  strokeLinecap="square"
                />
                <path
                  d="M16.2909091,1.74545455 L16.2909091,30.8363636"
                  id="Line-2-Copy-3"
                  strokeLinecap="square"
                  transform="translate(16.290909, 16.290909) rotate(90.000000) translate(-16.290909, -16.290909) "
                />
              </g>
              <path
                d="M10.7636364,1.74545455 L10.7636364,30.8363636"
                id="Line-2-Copy-5"
                strokeLinecap="square"
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="11" y="15" width="10" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              5
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells6Of6 = () => (
  <svg width="44px" height="32px" viewBox="0 -2 43 34" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1.4-Broadcast_create_message_imagemap_edit_add-tag"
        transform="translate(-302.000000, -500.000000)"
      >
        <g id="Group-10-Copy" transform="translate(302.000000, 496.000000)">
          <g id="Group-4-Copy-4" transform="translate(12.000000, 4.000000)">
            <g id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth="2">
              <g id="Group-Copy-4">
                <rect id="Rectangle-Copy-2" x="1" y="1" width="30" height="30" />
                <path
                  d="M20.6545455,1.74545455 L20.6545455,30.8363636"
                  id="Line-2-Copy-4"
                  strokeLinecap="square"
                />
                <path
                  d="M16.2909091,1.74545455 L16.2909091,30.8363636"
                  id="Line-2-Copy-3"
                  strokeLinecap="square"
                  transform="translate(16.290909, 16.290909) rotate(90.000000) translate(-16.290909, -16.290909) "
                />
              </g>
              <path
                d="M10.7636364,1.74545455 L10.7636364,30.8363636"
                id="Line-2-Copy-5"
                strokeLinecap="square"
              />
            </g>
            <rect id="cells-fill-layer" fill="#D5DAE0" x="20" y="15" width="10" height="17" />
          </g>
          <text
            id="cells-text-layer"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="14"
            fontWeight="400"
            fill="#D5DAE0"
          >
            <tspan x="0" y="13">
              6
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export const Cells8 = () => (
  <svg width="100%" height="100%" viewBox="0 0 32 32">
    <g
      transform="translate(-904 -500) translate(892 496)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <path id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth={1} d="M13 5H43V35H13z" />
      <path
        id="cells-stroke-layer"
        stroke="#D5DAE0"
        strokeWidth={1}
        strokeLinecap="square"
        d="M28 5.74545455L28 34.8363636"
      />
      <path
        id="cells-stroke-layer"
        stroke="#D5DAE0"
        strokeWidth={1}
        strokeLinecap="square"
        transform="rotate(90 28 20)"
        d="M28 6L28 34"
      />
      <path
        id="cells-stroke-layer"
        stroke="#D5DAE0"
        strokeWidth={1}
        strokeLinecap="square"
        transform="rotate(90 28 27)"
        d="M27.9997829 12.9997829L27.9997829 41.0002171"
      />
      <path
        id="cells-stroke-layer"
        stroke="#D5DAE0"
        strokeWidth={1}
        strokeLinecap="square"
        transform="rotate(90 29 13)"
        d="M29 -1L29 28.0004341"
      />
    </g>
  </svg>
);

export function Cells8WithIndex(cellIndex: number) {
  const x = cellIndex % 2 === 0 ? 16 : 0;
  const y = (Math.floor((cellIndex + 1) / 2) - 1) * 7.5;
  return (
    <svg width="43px" height="32px" viewBox="0 0 43 32">
      <g
        transform="translate(-904 -500) translate(903 496)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path
          fill="#D5DAE0"
          id="cells-fill-layer"
          d="M12 5H28V12H12z"
          transform={`translate(${x} ${y})`}
        />
        <path id="cells-stroke-layer" stroke="#D5DAE0" strokeWidth={2} d="M13 5H43V35H13z" />
        <path
          id="cells-stroke-layer"
          stroke="#D5DAE0"
          strokeWidth={2}
          strokeLinecap="square"
          d="M28 5.74545455L28 34.8363636"
        />
        <path
          id="cells-stroke-layer"
          stroke="#D5DAE0"
          strokeWidth={2}
          strokeLinecap="square"
          transform="rotate(90 28 20)"
          d="M28 6L28 34"
        />
        <path
          id="cells-stroke-layer"
          stroke="#D5DAE0"
          strokeWidth={2}
          strokeLinecap="square"
          transform="rotate(90 28 27)"
          d="M27.9997829 12.9997829L27.9997829 41.0002171"
        />
        <path
          id="cells-stroke-layer"
          stroke="#D5DAE0"
          strokeWidth={2}
          strokeLinecap="square"
          transform="rotate(90 29 13)"
          d="M29 -1L29 27.0004341"
        />

        <text
          fontFamily="Roboto-Medium, Roboto"
          id="cells-text-layer"
          fontSize={14}
          fontWeight={400}
          fill="#D5DAE0"
        >
          <tspan x={0.04296875} y={15}>
            {cellIndex}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export const TemplateIconText = () => (
  <svg width="61px" height="25px" viewBox="0 0 61 25" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1.1-Broadcast_create_template" transform="translate(-1048.000000, -196.000000)">
        <g id="img_template-module_text" transform="translate(1048.000000, 196.000000)">
          <rect id="Rectangle" fill="#FFFFFF" x="0" y="0" width="61" height="25" rx="2" />
          <rect id="Rectangle" fill="#E6E8EB" x="7" y="5" width="46" height="5" />
          <rect id="Rectangle-Copy-4" fill="#E6E8EB" x="7" y="15" width="46" height="5" />
        </g>
      </g>
    </g>
  </svg>
);

export const TemplateIconImage = () => (
  <svg width="49px" height="49px" viewBox="0 0 49 49" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1.1-Broadcast_create_template" transform="translate(-1156.000000, -229.000000)">
        <g id="img_template-module_img" transform="translate(1156.000000, 229.000000)">
          <rect id="Rectangle-Copy-2" fill="#D5DAE0" x="0" y="0" width="49" height="49" rx="2" />
          <g id="image-fill" transform="translate(17.000000, 17.000000)" fillRule="nonzero">
            <rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="15" height="15" />
            <path
              d="M13.2803121,2.64705882 L1.34153661,2.64705882 C1.08755065,2.64705882 0.882352941,2.85018382 0.882352941,3.10160428 L0.882352941,12.1925134 C0.882352941,12.4439338 1.08755065,12.6470588 1.34153661,12.6470588 L13.2803121,12.6470588 C13.5342981,12.6470588 13.7394958,12.4439338 13.7394958,12.1925134 L13.7394958,3.10160428 C13.7394958,2.85018382 13.5342981,2.64705882 13.2803121,2.64705882 Z M4.81411315,4.69251337 C5.32065014,4.69251337 5.73248049,5.10018382 5.73248049,5.60160428 C5.73248049,6.10302473 5.32065014,6.51069519 4.81411315,6.51069519 C4.30757616,6.51069519 3.8957458,6.10302473 3.8957458,5.60160428 C3.8957458,5.10018382 4.30757616,4.69251337 4.81411315,4.69251337 Z M12.188316,10.9013202 C12.1682267,10.9183656 12.1409626,10.9283088 12.1136986,10.9283088 L2.50671519,10.9283088 C2.44357743,10.9283088 2.39191927,10.8771725 2.39191927,10.8146725 C2.39191927,10.7876838 2.40196391,10.7621156 2.4191833,10.7408088 L4.86290141,7.87149064 C4.90307998,7.82319519 4.97626238,7.81751337 5.02505065,7.8572861 C5.02935549,7.86154746 5.03509529,7.86580882 5.03940014,7.87149064 L6.46573942,9.54762701 L8.73439376,6.88427473 C8.77457233,6.83597928 8.84775473,6.83029746 8.89654299,6.87007019 C8.90084784,6.87433155 8.90658764,6.87859291 8.91089248,6.88427473 L12.2055353,10.7422293 C12.242844,10.7891043 12.2371042,10.8615475 12.188316,10.9013202 Z"
              id="Shape"
              fill="#B1B5BA"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TemplateIconImageMap = () => (
  <svg width="65px" height="65px" viewBox="0 0 65 65" version="1.1">
    <g id="Broadcast_mockup_英" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="1.1-Broadcast_create_template" transform="translate(-1048.000000, -229.000000)">
        <g id="img_template-module_imagemap" transform="translate(1048.000000, 229.000000)">
          <rect id="Rectangle-Copy-2" fill="#D5DAE0" x="0" y="0" width="65" height="65" rx="2" />
          <g id="image-fill" transform="translate(24.000000, 24.000000)" fillRule="nonzero">
            <rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="17" height="17" />
            <path
              d="M15.670068,3.23809524 L2.13945578,3.23809524 C1.85160502,3.23809524 1.61904762,3.46830357 1.61904762,3.75324675 L1.61904762,14.0562771 C1.61904762,14.3412202 1.85160502,14.5714286 2.13945578,14.5714286 L15.670068,14.5714286 C15.9579188,14.5714286 16.1904762,14.3412202 16.1904762,14.0562771 L16.1904762,3.75324675 C16.1904762,3.46830357 15.9579188,3.23809524 15.670068,3.23809524 Z M6.07504252,5.55627706 C6.64911777,5.55627706 7.11585884,6.01830357 7.11585884,6.58658009 C7.11585884,7.1548566 6.64911777,7.61688312 6.07504252,7.61688312 C5.50096726,7.61688312 5.03422619,7.1548566 5.03422619,6.58658009 C5.03422619,6.01830357 5.50096726,5.55627706 6.07504252,5.55627706 Z M14.4324724,12.5929248 C14.4097045,12.612243 14.3788053,12.6235119 14.347906,12.6235119 L3.4599915,12.6235119 C3.38843537,12.6235119 3.32988946,12.5655574 3.32988946,12.494724 C3.32988946,12.4641369 3.34127338,12.4351596 3.36078869,12.4110119 L6.13033588,9.15911797 C6.1758716,9.10438312 6.25881165,9.09794372 6.31410502,9.14301948 C6.31898384,9.14784903 6.32548895,9.15267857 6.33036777,9.15911797 L7.94688563,11.0587392 L10.5180272,8.04027327 C10.5635629,7.98553842 10.646503,7.97909903 10.7017963,8.02417478 C10.7066752,8.02900433 10.7131803,8.03383387 10.7180591,8.04027327 L14.4519877,12.4126218 C14.4942708,12.4657468 14.4877657,12.547849 14.4324724,12.5929248 Z"
              id="Shape"
              fill="#B1B5BA"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TemplateIconImageMapCarousel = () => (
  <svg width="82" height="54" viewBox="0 0 82 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="54" height="54" rx="2" fill="#D5DAE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.2857 19H17.7143C17.3192 19 17 19.325 17 19.7273V34.2727C17 34.675 17.3192 35 17.7143 35H36.2857C36.6808 35 37 34.675 37 34.2727V19.7273C37 19.325 36.6808 19 36.2857 19ZM23 22C24.1031 22 25 22.8969 25 24C25 25.1031 24.1031 26 23 26C21.8969 26 21 25.1031 21 24C21 22.8969 21.8969 22 23 22ZM34.8112 33C34.8555 33 34.8999 32.983 34.9326 32.9537C35.0119 32.8856 35.0212 32.7614 34.9606 32.6811L29.6023 26.0695C29.5975 26.0629 29.5917 26.0574 29.5862 26.0523C29.5837 26.0498 29.5812 26.0475 29.5789 26.0451C29.4996 25.977 29.3805 25.9867 29.3152 26.0695L25.6255 30.6338L23.3058 27.7613C23.301 27.7547 23.2952 27.7493 23.2898 27.7441C23.2872 27.7417 23.2847 27.7394 23.2824 27.737C23.2031 27.6688 23.0841 27.6786 23.0187 27.7613L19.0443 32.6787C19.0163 32.7152 19 32.759 19 32.8053C19 32.9124 19.084 33 19.1867 33H34.8112Z"
      fill="#B1B5BA"
    />
    <rect x="58" width="54" height="54" rx="2" fill="#D5DAE0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.2857 19H75.7143C75.3192 19 75 19.325 75 19.7273V34.2727C75 34.675 75.3192 35 75.7143 35H94.2857C94.6808 35 95 34.675 95 34.2727V19.7273C95 19.325 94.6808 19 94.2857 19ZM81 22C82.1031 22 83 22.8969 83 24C83 25.1031 82.1031 26 81 26C79.8969 26 79 25.1031 79 24C79 22.8969 79.8969 22 81 22ZM92.8112 33C92.8555 33 92.8999 32.983 92.9326 32.9537C93.0119 32.8856 93.0212 32.7614 92.9606 32.6811L87.6023 26.0695C87.5975 26.0629 87.5917 26.0574 87.5862 26.0523C87.5837 26.0498 87.5812 26.0475 87.5789 26.0451C87.4996 25.977 87.3805 25.9867 87.3152 26.0695L83.6255 30.6338L81.3058 27.7613C81.301 27.7547 81.2952 27.7493 81.2898 27.7441C81.2872 27.7417 81.2847 27.7394 81.2824 27.737C81.2031 27.6688 81.0841 27.6786 81.0187 27.7613L77.0443 32.6787C77.0163 32.7152 77 32.759 77 32.8053C77 32.9124 77.084 33 77.1867 33H92.8112Z"
      fill="#B1B5BA"
    />
  </svg>
);

export const TemplateIconCard = () => (
  <svg width="41px" height="65px" viewBox="0 0 41 65">
    <g
      transform="translate(-1048 -510) translate(1048 510)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <rect fill="#D5DAE0" y={7.10542736e-15} width={41} height={65} rx={2} />
      <path d="M0 41h41v22a2 2 0 01-2 2H2a2 2 0 01-2-2V41z" fill="#FFF" />
      <path
        d="M15.67 3.238H2.14c-.288 0-.521.23-.521.515v10.303c0 .285.233.515.52.515H15.67c.288 0 .52-.23.52-.515V3.753a.517.517 0 00-.52-.515zM6.075 5.556c.574 0 1.04.462 1.04 1.03 0 .569-.466 1.03-1.04 1.03-.574 0-1.04-.461-1.04-1.03 0-.568.466-1.03 1.04-1.03zm8.357 7.037a.133.133 0 01-.084.03H3.46a.13.13 0 01-.13-.128c0-.03.011-.06.03-.084l2.77-3.252a.132.132 0 01.184-.016l.016.016 1.617 1.9 2.571-3.019a.132.132 0 01.184-.016l.016.016 3.734 4.373a.13.13 0 01-.02.18z"
        fill="#B1B5BA"
        transform="translate(12 12)"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const TemplateIconCarrousel = () => (
  <svg width="81px" height="65px" viewBox="0 0 81 65">
    <defs>
      <path id="a" d="M0 0H34V65H0z" />
    </defs>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g transform="translate(-1048 -362) translate(1048 362)">
        <rect fill="#D5DAE0" x={0} y={7.10542736e-15} width={41} height={65} rx={2} />
        <path d="M0 41h41v22a2 2 0 01-2 2H2a2 2 0 01-2-2V41z" fill="#FFF" />
        <path
          d="M15.67 3.238H2.14c-.288 0-.521.23-.521.515v10.303c0 .285.233.515.52.515H15.67c.288 0 .52-.23.52-.515V3.753a.517.517 0 00-.52-.515zM6.075 5.556c.574 0 1.04.462 1.04 1.03 0 .569-.466 1.03-1.04 1.03-.574 0-1.04-.461-1.04-1.03 0-.568.466-1.03 1.04-1.03zm8.357 7.037a.133.133 0 01-.084.03H3.46a.13.13 0 01-.13-.128c0-.03.011-.06.03-.084l2.77-3.252a.132.132 0 01.184-.016l.016.016 1.617 1.9 2.571-3.019a.132.132 0 01.184-.016l.016.016 3.734 4.373a.13.13 0 01-.02.18z"
          fill="#B1B5BA"
          transform="translate(12 12)"
          fillRule="nonzero"
        />
      </g>
      <g transform="translate(-1048 -362) translate(1048 362) translate(47)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <rect fill="#D5DAE0" x={0} y={7.10542736e-15} width={41} height={65} rx={2} />
          <path d="M0 41h41v22a2 2 0 01-2 2H2a2 2 0 01-2-2V41z" fill="#FFF" />
          <path
            d="M15.67 3.238H2.14c-.288 0-.521.23-.521.515v10.303c0 .285.233.515.52.515H15.67c.288 0 .52-.23.52-.515V3.753a.517.517 0 00-.52-.515zM6.075 5.556c.574 0 1.04.462 1.04 1.03 0 .569-.466 1.03-1.04 1.03-.574 0-1.04-.461-1.04-1.03 0-.568.466-1.03 1.04-1.03zm8.357 7.037a.133.133 0 01-.084.03H3.46a.13.13 0 01-.13-.128c0-.03.011-.06.03-.084l2.77-3.252a.132.132 0 01.184-.016l.016.016 1.617 1.9 2.571-3.019a.132.132 0 01.184-.016l.016.016 3.734 4.373a.13.13 0 01-.02.18z"
            fill="#B1B5BA"
            transform="translate(12 12)"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const TemplateIconVideo = () => (
  <svg width="65px" height="43px" viewBox="0 0 65 43">
    <g
      transform="translate(-1155 -360) translate(1155 360)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <rect fill="#D5DAE0" x={0} y={0} width={65} height={43} rx={2} />
      <path
        d="M8 1a7 7 0 100 14A7 7 0 008 1zm2.252 7.108L6.839 10.59a.125.125 0 01-.198-.102V5.527a.125.125 0 01.198-.102l3.413 2.481a.123.123 0 010 .202z"
        fill="#B1B5BA"
        transform="translate(25 14)"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const TemplateIconImageCarousel = () => (
  <svg width="87px" height="48px" viewBox="0 0 87 48">
    <defs>
      <path id="a" d="M0 0H34V48H0z" />
    </defs>
    <g
      transform="translate(-1042 -488) translate(1042 488)"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <rect fill="#D5DAE0" x={0} y={0} width={48} height={48} rx={2} />
      <path
        d="M11.983 2.476H1.636a.396.396 0 00-.398.394v7.879c0 .218.178.394.398.394h10.347c.22 0 .398-.176.398-.394V2.87a.396.396 0 00-.398-.394zM4.646 4.25c.439 0 .796.353.796.788a.793.793 0 01-.796.788.793.793 0 01-.796-.788c0-.435.357-.788.796-.788zm6.39 5.38a.101.101 0 01-.064.024H2.646a.1.1 0 01-.1-.098c0-.024.01-.046.024-.064l2.118-2.487a.1.1 0 01.14-.012c.004.003.01.007.013.012l1.236 1.453 1.966-2.309a.1.1 0 01.14-.012l.013.012 2.856 3.344a.1.1 0 01-.015.138z"
        fill="#B1B5BA"
        transform="translate(18 18)"
        fillRule="nonzero"
      />
      <g transform="translate(53)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g mask="url(#b)">
          <g transform="translate(1)">
            <rect fill="#D5DAE0" x={0} y={0} width={48} height={48} rx={2} />
            <path
              d="M11.983 2.476H1.636a.396.396 0 00-.398.394v7.879c0 .218.178.394.398.394h10.347c.22 0 .398-.176.398-.394V2.87a.396.396 0 00-.398-.394zM4.646 4.25c.439 0 .796.353.796.788a.793.793 0 01-.796.788.793.793 0 01-.796-.788c0-.435.357-.788.796-.788zm6.39 5.38a.101.101 0 01-.064.024H2.646a.1.1 0 01-.1-.098c0-.024.01-.046.024-.064l2.118-2.487a.1.1 0 01.14-.012c.004.003.01.007.013.012l1.236 1.453 1.966-2.309a.1.1 0 01.14-.012l.013.012 2.856 3.344a.1.1 0 01-.015.138z"
              fill="#B1B5BA"
              fillRule="nonzero"
              transform="translate(18 18)"
              strokeWidth={1}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TemplateIconPrize = memo(() => (
  <svg width={61} height={26} viewBox="0 0 61 26" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <rect fill="#D5DAE0" width={61} height={26} rx={2} />
      <path
        d="M25.5 18.969a.5.5 0 00.5.5h4.469v-5.875H25.5v5.375zm6.031.5H36a.5.5 0 00.5-.5v-5.375h-4.969v5.875zm5.219-9.625h-2.306a2.15 2.15 0 00.337-1.156A2.154 2.154 0 0031 7.271a2.154 2.154 0 00-3.781 1.415c0 .426.123.822.337 1.157H25.25a.5.5 0 00-.5.5v2.187h5.719V9.844h1.062v2.687h5.719v-2.187a.5.5 0 00-.5-.5zm-6.281-.063h-1.094a1.095 1.095 0 010-2.187c.603 0 1.094.49 1.094 1.093v1.094zm2.156 0h-1.094V8.687a1.095 1.095 0 012.188 0c0 .604-.49 1.094-1.094 1.094z"
        fill="#B1B5BA"
        fillRule="nonzero"
      />
    </g>
  </svg>
));
