import type { ReactNode } from 'react';

import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

export interface ParamPopoverIconProps {
  title: ReactNode;
  onLight?: boolean;
}

export const ParamPopoverIcon = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['onLight']),
})<Omit<ParamPopoverIconProps, 'title'>>`
  position: relative;
  display: inline-flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ onLight }) => (onLight ? theme.colors.neutral006 : theme.colors.white001)};
  &:hover i,
  &:hover > span {
    color: ${({ onLight }) => (onLight ? theme.colors.blue006 : theme.colors.blue004)};
  }
  i {
    color: ${({ onLight }) => (onLight ? theme.colors.neutral007 : theme.colors.white001)};
  }
  & > span {
    margin-top: 14px;
    font-size: 14px;
  }
`;
