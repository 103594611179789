import { forwardRef, memo } from 'react';

import type { BoxProps } from 'components/layoutUtils';
import type { ComponentPropsWithRef, CSSProperties, MouseEventHandler, ReactElement } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { superBoxStyle } from 'components/layoutUtils';
import { styled } from 'shared/utils/styled';
import { css, overrideCss } from 'shared/utils/styled/override';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

interface StyledIconProps extends OverrideComponentCssProps, BoxProps, ComponentPropsWithRef<'i'> {
  bg?: string;
  className?: string;
  color?: string;
  component?: ReactElement;
  onClick?: MouseEventHandler<HTMLElement>;
  style?: CSSProperties;
  type?: string;
  centerComponent?: boolean;
}

const centeredStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Set `--icon-styled-color` or `--icon-styled-transition` on containers to override
// Note: Ant Design icons require height/width to be set with `fontSize`
const StyledIconWrap = styled.i.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps('centerComponent'),
})<StyledIconProps>`
  height: ${({ fontSize }) => fontSize ?? '20px'};
  width: ${({ fontSize }) => fontSize ?? '20px'};
  border-radius: ${theme.radii.iconStandard};
  color: var(--icon-styled-color);
  transition: var(--icon-styled-transition);
  user-select: none;
  vertical-align: ${({ verticalAlign }) => verticalAlign ?? 'inherit'};
  align-items: center;
  ${({ centerComponent }) => (centerComponent ? centeredStyle : undefined)}
  ${superBoxStyle};
  ${overrideCss}
`;

// Styled icon for use with Ant Design and CSS custom properties
export const StyledIcon = memo(
  forwardRef<HTMLElement, StyledIconProps>(function StyledIcon(props, ref) {
    return (
      <StyledIconWrap {...props} ref={ref}>
        {props.component}
      </StyledIconWrap>
    );
  }),
);
