import type { MutableRefObject } from 'react';

import { useUpload } from 'lib/fileUpload';

export const useImageMapCarouselImageUpload = (
  fileRef: MutableRefObject<File | undefined>,
  uploadedCallback: (uploadUrl: string, originalSize: { width: number; height: number }) => void,
): (() => Promise<void>) => {
  const onUpload = useUpload();

  return async () => {
    if (fileRef.current) {
      const file = fileRef.current;
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = async () => {
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;
        const uploadUrl = await onUpload(file, 'Broadcast');
        uploadedCallback(uploadUrl, { width: imgWidth, height: imgHeight });
      };
    }
  };
};
