export const InviteeSvg = () => {
  return (
    <svg width={14} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.786 12.641h-1.429v-1.428a.143.143 0 00-.143-.143h-.857a.143.143 0 00-.143.143v1.428H9.786a.143.143 0 00-.143.143v.857c0 .079.064.143.143.143h1.428v1.428c0 .08.065.143.143.143h.857a.143.143 0 00.143-.143v-1.428h1.429a.143.143 0 00.143-.143v-.857a.143.143 0 00-.143-.143zm-9.26-4.886a4.578 4.578 0 01.053-1.301.144.144 0 00-.08-.158 2.256 2.256 0 01-.66-.448 2.277 2.277 0 01-.69-1.703 2.274 2.274 0 01.647-1.529 2.274 2.274 0 011.665-.691 2.283 2.283 0 011.9 1.05.142.142 0 00.166.057 4.43 4.43 0 01.987-.221c.1-.011.158-.118.113-.207A3.578 3.578 0 005.489.64C3.511.611 1.861 2.234 1.861 4.213c0 1.12.516 2.12 1.325 2.776A5.369 5.369 0 001.64 8.068 5.311 5.311 0 00.073 11.71a.143.143 0 00.143.146h1.002c.077 0 .141-.06.143-.137a4.036 4.036 0 011.19-2.741 4.031 4.031 0 011.87-1.066.146.146 0 00.106-.158zm8.046-.471a3.573 3.573 0 00-3.517-3.571C7.077 3.683 5.43 5.305 5.43 7.284a3.57 3.57 0 001.325 2.777 5.375 5.375 0 00-1.543 1.078 5.31 5.31 0 00-1.568 3.641.143.143 0 00.143.147h1c.077 0 .14-.06.143-.138a4.036 4.036 0 011.19-2.74A4.044 4.044 0 019 10.854a3.572 3.572 0 003.572-3.571zM10.616 8.9A2.269 2.269 0 019 9.57a2.27 2.27 0 01-1.616-.67 2.272 2.272 0 01-.67-1.64 2.289 2.289 0 012.263-2.262 2.3 2.3 0 011.625.654 2.278 2.278 0 01.014 3.248z"
        fill="currentColor"
      />
    </svg>
  );
};
