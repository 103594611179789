import { useLocation, useNavigate } from '@reach/router';
// eslint-disable-next-line no-restricted-imports -- Necessary to use it here
import AntApp from 'antd/es/app';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { NavigateFn } from '@reach/router';

import { theme } from 'theme';
import { isCreatingOrEditingPage } from 'utils/url/urlGuard';

/**
 * Display a prompt warning the user "Your changes have not been saved"
 * This is a wrapper around the standard `navigate` function used by the Reach Router
 */
export const useNavigatePrompt = (): { navigatePrompt: NavigateFn } => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { modal } = AntApp.useApp();

  const pathname = location.pathname;
  const [visible, setVisible] = useState(false);

  const navigateWithGuardian = useCallback(
    (...args: Parameters<NavigateFn>) => {
      if (isCreatingOrEditingPage(pathname)) {
        modal.confirm({
          icon: null,
          width: theme.sizes.modalWidth,
          title: t('common.unsaved.title'),
          content: t('common.unsaved.desc'),
          open: visible,
          onOk: () => {
            setVisible(false);
          },
          onCancel: () => {
            navigate(...args);
          },
          okText: t('common.unsaved.keepEditing'),
          cancelText: t('common.unsaved.leave'),
        });
        return;
      }
      navigate(...args);
    },
    [modal, navigate, pathname, t, visible],
  );

  return {
    navigatePrompt: navigateWithGuardian as NavigateFn,
  };
};
