import EmojiPickerReact, { Categories, EmojiStyle } from 'emoji-picker-react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { EmojiClickData } from 'emoji-picker-react';
import type { ComponentProps } from 'react';

export const EmojiPicker = memo(function EmojiPicker({
  onEmojiClick,
  ...props
}: ComponentProps<typeof EmojiPickerReact>) {
  const { t } = useTranslation();

  const handleEmojiClick = useCallback(
    (emojiObject: EmojiClickData, event: MouseEvent) => {
      if (onEmojiClick) onEmojiClick(emojiObject, event);
    },
    [onEmojiClick],
  );

  return (
    <EmojiPickerReact
      height={300}
      previewConfig={{
        showPreview: false,
      }}
      emojiStyle={EmojiStyle.NATIVE}
      categories={[
        {
          category: Categories.SUGGESTED,
          name: t('message.editor.emoji.frequentlyUsed'),
        },
        {
          category: Categories.SMILEYS_PEOPLE,
          name: t('message.editor.emoji.people'),
        },
        {
          category: Categories.ANIMALS_NATURE,
          name: t('message.editor.emoji.nature'),
        },
        {
          category: Categories.FOOD_DRINK,
          name: t('message.editor.emoji.food'),
        },
        {
          category: Categories.TRAVEL_PLACES,
          name: t('message.editor.emoji.travel'),
        },
        {
          category: Categories.ACTIVITIES,
          name: t('message.editor.emoji.activities'),
        },
        {
          category: Categories.OBJECTS,
          name: t('message.editor.emoji.objects'),
        },
        {
          category: Categories.SYMBOLS,
          name: t('message.editor.emoji.symbols'),
        },
        {
          category: Categories.FLAGS,
          name: t('message.editor.emoji.flags'),
        },
      ]}
      onEmojiClick={handleEmojiClick}
      {...props}
    />
  );
});
