import { useMemo, useState } from 'react';

import { MAX_TAG_NAME_LENGTH } from 'AppConstants';

import type { Dispatch, SetStateAction } from 'react';

import { useTagMetadata } from 'shared/hooks/tag/useTagMetadata';
import { sanitizeTagName } from 'utils/tag';

/**
 * Business logic specific to the TagSelect component, which can be used outside of TagPicker
 */
export const useTagSelect = ({
  disableCreateNewTagOption,
}: {
  disableCreateNewTagOption?: boolean;
}): {
  setTagSelectSearchText: Dispatch<SetStateAction<string>>;
  trimmedTagSelectSearchText: string;
  showCreateTagOption: boolean;
  isTagLengthError: boolean;
} => {
  const { tagNameExists } = useTagMetadata();

  /**
   * Search handling
   */
  const [tagSelectSearchText, setTagSelectSearchText] = useState('');
  const trimmedTagSelectSearchText = useMemo(
    () => sanitizeTagName(tagSelectSearchText),
    [tagSelectSearchText],
  );

  /**
   * This controls whether the create tag option is displayed
   */
  const showCreateTagOption = useMemo(
    () =>
      disableCreateNewTagOption !== true &&
      trimmedTagSelectSearchText !== '' &&
      tagNameExists(trimmedTagSelectSearchText) === false,
    [disableCreateNewTagOption, tagNameExists, trimmedTagSelectSearchText],
  );

  const isTagLengthError = useMemo(
    () => trimmedTagSelectSearchText.length > MAX_TAG_NAME_LENGTH,
    [trimmedTagSelectSearchText.length],
  );

  return {
    setTagSelectSearchText,
    trimmedTagSelectSearchText,
    showCreateTagOption,
    isTagLengthError,
  };
};
