import { z } from 'zod';

export const ReportTotalSchema = z.object({
  clicks: z.number().int(),
  unique_clicks: z.number().int().nullable().optional(),
  adds_to_cart: z.number().int(),
  transactions: z.number().int(),
  transaction_revenue: z.number(), // Float
  open_count: z.number().int().optional().nullable(),
  clicks_of_share: z.number().int().optional().nullable(),
  unique_clicks_of_share: z.number().int().optional().nullable(),
});

export const ReportUrlSchema = z.object({
  id: z.number().int(),
  link: z.number().int(),
  url: z.string().nullable().optional(),
  utm_campaign: z.string(),
  utm_source: z.string(),
  utm_medium: z.string(),
  utm_content: z.string(),
  clicks: z.number().int(),
  unique_clicks: z.number().int().nullable().optional(),
  adds_to_cart: z.number().int(),
  transactions: z.number().int(),
  transaction_revenue: z.number(), // Float
  tags: z.string().array(),
});

export const ReportMessageSchema = z.object({
  id: z.number().int(),
  message: z.string(),
  tags: z.string().array(),
  clicks: z.number().int(),
});

export const ReportShareSchema = z.object({
  id: z.number().int(),
  key: z.string(),
  name: z.string(),
  tags: z.string().array(),
  clicks_of_share: z.number().int(),
  unique_clicks_of_share: z.number().int(),
});
