import { create } from 'zustand';

type Store = {
  opened: boolean;
  open: () => void;
  close: () => void;
};

export const useDevModeStore = create<Store>((set) => ({
  opened: false,
  open: () => set({ opened: true }),
  close: () => set({ opened: false }),
}));
