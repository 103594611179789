import { memo, useEffect, useMemo, useState } from 'react';

import { MILLISECONDS_IN_SECOND } from 'AppConstants';

import type { ButtonProps } from 'shared/components/Button';

import { useEventCallback } from 'hooks/useEventCallback';
import { Button } from 'shared/components/Button';

type ReloadButtonProps = ButtonProps;

/**
 * Can only click once.
 * setTimeout to prevent continuous clicks.
 */
export const ReloadButton = memo(function ReloadButton({
  disabled,
  onClick,
  ...props
}: ReloadButtonProps) {
  const [clicked, setClicked] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReady(true);
    }, MILLISECONDS_IN_SECOND);
    return function cleanUp() {
      clearTimeout(timeout);
    };
  }, []);

  const isDisabled = useMemo(() => {
    return disabled || clicked || !ready;
  }, [clicked, disabled, ready]);

  const clickHandler = useEventCallback<
    Parameters<NonNullable<ReloadButtonProps['onClick']>>,
    ReturnType<NonNullable<ReloadButtonProps['onClick']>>
  >((e) => {
    setClicked(true);
    onClick?.(e);
  });

  return <Button {...props} disabled={isDisabled} onClick={clickHandler} />;
});
