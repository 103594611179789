import { z } from 'zod';

import { DurationType as DurationTypeEnum } from 'modules/FilterBox/types';
import { SegmentGroupOperatorSchema } from 'shared/models/filters/common';
import {
  SegmentCategorySchema,
  SegmentFilterOperationGroupsSchema,
  SegmentStatusSchema,
} from 'shared/models/segment/common';

const SegmentItemParamsSchema = z.object({
  segmentId: z.number().int(),
});

export type SegmentItemParams = z.output<typeof SegmentItemParamsSchema>;

const SegmentItemUsageDataSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  schedule_time: z.string(),
  status: z.string(),
});

// Note: there are many commonalities between this and SegmentListItemSchema
// However, there are important differences, and despite the duplication, they should be defined separately
const SegmentItemBaseSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string().nullable(),
  category: SegmentCategorySchema,
  size: z.number().int(),
  bot: z.number().int(),
  size_updated_time: z.string().nullable(),
  status: SegmentStatusSchema,
  mobile_size: z.number().int(),
  line_id_size: z.number().int(),
  usage: z.record(z.enum(['broadcast', 'dpm']), SegmentItemUsageDataSchema.array()).optional(),
  operation_groups: SegmentFilterOperationGroupsSchema,
  grouping_operator: SegmentGroupOperatorSchema,
  exclude_segment_ids: z.number().int().array().optional(),
});

export const SegmentItemSchema = z.discriminatedUnion('duration_type', [
  SegmentItemBaseSchema.extend({
    duration_type: z.literal(DurationTypeEnum.ACTIVE_DURATION),
    duration: z.object({ days: z.number().int() }),
  }),
  SegmentItemBaseSchema.extend({
    duration_type: z.literal(DurationTypeEnum.FIX_DURATION),
    duration: z.object({ start_date: z.string(), end_date: z.string() }),
  }),
  SegmentItemBaseSchema.extend({
    duration_type: z.literal(DurationTypeEnum.ALL_DURATION),
    duration: z.object({}),
  }),
  SegmentItemBaseSchema.extend({
    duration_type: z.literal(DurationTypeEnum.NO_DURATION),
    duration: z.object({}),
  }),
]);

export type SegmentItemResponse = z.output<typeof SegmentItemSchema>;
