import urlJoin from 'url-join';

import { ENV } from 'env';

import { useBotId, useCurrentChannel, useOrganization } from 'context/authContext';

/**
 * Generate a categorized file path
 * @returns a file path prefix, eg. 'production/org_1/line/bot_1'
 */
export const useCategorizedFilePath = (): string => {
  const { id: orgId } = useOrganization();
  const { type } = useCurrentChannel();
  const botId = useBotId();

  return urlJoin(ENV, `org_${orgId}`, type, `bot_${botId}`);
};
