const openGraphKeys: OpenGraphKeys[] = [
  'title',
  'type',
  'url',
  'image',
  'audio',
  'description',
  'determiner',
  'locale',
  'site_name',
  'video',
];

type OpenGraphKeys =
  | 'title'
  | 'type'
  | 'url'
  | 'image'
  | 'audio'
  | 'description'
  | 'determiner'
  | 'locale'
  | 'site_name'
  | 'video';

type OpenGraphMeta = Partial<Record<OpenGraphKeys, string>>;

const parse = function (document: Document): OpenGraphMeta {
  const meta: OpenGraphMeta = {};
  openGraphKeys.forEach((key) => {
    const value = document
      .querySelector(`head > meta[property='og:${key}']`)
      ?.getAttribute('content');
    if (value) {
      meta[key] = value;
    }
  });
  return meta;
};

export const openGraph = {
  parse,
};
