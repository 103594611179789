import { array } from './array';
import { string } from './string';

type Options =
  | {
      falsyString?: string | Array<string>;
    }
  | undefined;

/**
 * Transform the input to a boolean
 *
 * @param input - Value to be transformed
 * @param options - falsyString - an optional string or array of strings to evaluate as false
 * Note 1: empty string always evaluates to false
 * Note 2: `toBoolean('false')` will return `true`; options are required in this case
 *
 * @returns Input transformed to a boolean
 */
export function toBoolean(input: unknown, { falsyString }: Options = {}): boolean {
  switch (typeof input) {
    case 'boolean':
      return input;
    case 'string':
      const falsyStringArray = array(string())(falsyString);
      return !['', ...falsyStringArray].includes(input);
    default:
      return Boolean(input);
  }
}

/**
 * Transform the input to a boolean
 *
 * @param options - Optional options
 * @returns a boolean parser
 */
export function boolean(options?: Options): (input: unknown) => boolean {
  return (input: unknown) => toBoolean(input, options);
}
