import { flexbox } from 'styled-system';

import type { FlexboxProps } from 'styled-system';

import { Box, FramerBox } from 'components/layoutUtils/Box';
import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

export const Flex = styled(Box).withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<FlexboxProps>`
  display: flex;
  ${flexbox};
`;

export const FramerFlex = styled(FramerBox).withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<FlexboxProps>`
  display: flex;
  place-content: baseline space-between;
  flex-wrap: wrap;
  margin-top: 10px;
  ${flexbox};
`;
