import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const QuickReplyEditorWrapper = styled.div`
  position: relative;
`;

interface QuickReplyPillProps {
  isActive?: boolean;
  isError?: boolean;
  isPreview?: boolean;
}

const getBackgroundColor = ({ isActive, isError, isPreview }: QuickReplyPillProps) => {
  if (isPreview) return theme.colors.neutral007;
  if (isError) return theme.colors.red006;
  if (isActive) return theme.colors.blue006;
  return theme.colors.neutral007;
};

export const QuickReplyPill = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isActive', 'isError', 'isPreview']),
})<QuickReplyPillProps>`
  position: relative;
  border-radius: 20px;
  padding: 0 8px;
  background-color: ${({ isActive, isError }) => getBackgroundColor({ isActive, isError })};
  color: ${({ theme }) => theme.colors.white001};
  line-height: 36px;
  margin-right: 8px;
  height: 36px;
  box-sizing: border-box;
  white-space: nowrap;
  transition: background-color 0.25s ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ isActive, isError, isPreview }) =>
      getBackgroundColor({ isActive, isError, isPreview })};
  }
`;

export const QuickReplyPillImage = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 10px;
`;

export const QuickReplyPillWrapper = styled.div`
  position: relative;
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  > ${QuickReplyPill} {
    scroll-snap-align: start;
  }

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface PointProps {
  isActive?: boolean;
}

export const Point = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isActive']),
})<PointProps>`
  width: 32px;
  height: 36px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.white001};
  margin: 0 4px;
  border: ${({ isActive, theme }) => (isActive ? `solid 2px ${theme.colors.blue006}` : 'none')};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface PointProps {
  isError?: boolean;
}

export const PointIcon = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isError']),
})<PointProps>`
  width: 26px;
  height: 18px;
  border-radius: 20px;
  border: solid 2px
    ${({ theme, isError }) => (isError ? theme.colors.red006 : theme.colors.blue006)};
  box-sizing: border-box;
`;

export const AddButton = styled.div`
  width: 108px;
  height: 36px;
  border-radius: 20px;
  border: dashed 1px ${({ theme }) => theme.colors.neutral005};
  background-color: ${({ theme }) => theme.colors.white001};
  text-align: center;
  line-height: 34px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 auto;
  user-select: none;
  color: ${({ theme }) => theme.colors.neutral005};
  transition:
    border-color 0.25s ease-in-out,
    color 0.25s ease-in-out;
  :hover {
    border-color: ${({ theme }) => theme.colors.blue006};
    color: ${({ theme }) => theme.colors.blue006};
  }
`;
