import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { ExportResponseSchema, PageResponseSchema } from 'shared/models/common';

const TracelinkReportStandardSchema = z.object({
  adds_to_cart: z.number().int().nullable(),
  clicks: z.number().int().nullable(),
  unique_clicks: z.number().int().nullable(),
  transaction_revenue: z.number().nullable(),
  transactions: z.number().int().nullable(),
});

const TracelinkItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  description: z.string().nullable(),
  bot: z.number().int(),
  archived: z.boolean(),
  type: z.literal('out_link'),
  shorten_url: z.string().url(),
  link: z.string().url(),
  tag: z.string().array(),
  out_link: z.string(), // Note: this is not always a URL!
  created_at: z.string(),
  utm: z
    .object({
      utm_campaign: z.string(),
      utm_source: z.string(),
      utm_medium: z.string(),
      utm_content: z.string(),
    })
    .partial(),
  report: TracelinkReportStandardSchema,
  open_external_browser: z.boolean(),
});

const TracelinkMetadataItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
});

export const tracelinkApi = makeApi([
  {
    alias: 'tracelinkList',
    method: 'get',
    path: '/line/v1/guidelink/outlink/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      results: TracelinkItemSchema.strict().array(),
    }),
  },
  {
    alias: 'tracelinkListMetrics',
    method: 'get',
    path: '/line/v1/guidelink/outlink/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        archived: z.boolean(),
        count: z.number().int(),
      })
      .strict()
      .array(),
  },
  {
    alias: 'tracelinkDetail',
    method: 'get',
    path: '/line/v1/guidelink/outlink/:tracelinkId/',
    response: TracelinkItemSchema.strict(),
  },
  {
    alias: 'tracelinkMetadata',
    method: 'get',
    path: '/line/v1/guidelink/outlink/raw_outlinks/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: TracelinkMetadataItemSchema.strict().array(),
  },
  {
    alias: 'tracelinkCreate',
    method: 'post',
    path: '/line/v1/guidelink/outlink/',
    parameters: [
      {
        name: 'body',
        schema: z.object({
          trace_links: TracelinkItemSchema.pick({
            bot: true,
            name: true,
            out_link: true,
            tag: true,
            open_external_browser: true,
            utm: true,
          })
            .strict()
            .array(),
        }),
        type: 'Body',
      },
    ],
    response: TracelinkItemSchema.strict().array(),
  },
  {
    alias: 'tracelinkUpdate',
    method: 'patch',
    path: '/line/v1/guidelink/outlink/:tracelinkId/',
    parameters: [
      {
        name: 'body',
        schema: TracelinkItemSchema.strict().partial(),
        type: 'Body',
      },
    ],
    response: TracelinkItemSchema.strict(),
  },
  {
    alias: 'tracelinkExport',
    method: 'post',
    path: '/line/v1/guidelink/outlink/export/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            bot_id: z.number().int(),
            start_date: z.string(),
            end_date: z.string(),
          })
          .strict(),
        type: 'Body',
      },
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ExportResponseSchema.strict(),
  },
]);

export type TracelinkItem = z.output<typeof TracelinkItemSchema>;

export type TracelinkMetadataItem = z.output<typeof TracelinkMetadataItemSchema>;

export type TracelinkListMetricsResponse = ZodiosResponseByAlias<
  typeof tracelinkApi,
  'tracelinkListMetrics'
>;
