import { memo } from 'react';

import type { TabsProps } from 'shared/components/Tabs';

import { Tabs } from 'shared/components/Tabs';

import { Feature } from './Feature';

export const Content = memo(function Content() {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Features',
      children: <Feature />,
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} />;
});
