import { useCallback } from 'react';

import type { ImageMapCustomPreviewBlock, RectData } from '../ImageMapCustomBlockEditor';

export function useCheckCustomBlockData(
  previewBlocks: ImageMapCustomPreviewBlock[],
  rectData: RectData,
): () => ImageMapCustomPreviewBlock[] {
  const getBlocks = useCallback(() => {
    function filterIllegalBlock(block: ImageMapCustomPreviewBlock) {
      return (
        block.left < rectData.width &&
        block.top < rectData.height &&
        block.height > 0 &&
        block.width > 0
      );
    }
    function removeAxisNegativeNumber(block: ImageMapCustomPreviewBlock) {
      return {
        ...block,
        left: block.left < 0 ? 0 : block.left,
        top: block.top < 0 ? 0 : block.top,
      };
    }
    function updateIllegalBlock(block: ImageMapCustomPreviewBlock) {
      return {
        ...block,
        width:
          block.width + block.left > rectData.width ? rectData.width - block.left : block.width,
        height:
          block.height + block.top > rectData.height ? rectData.height - block.top : block.height,
      };
    }
    return previewBlocks
      .filter(filterIllegalBlock)
      .map(removeAxisNegativeNumber)
      .map(updateIllegalBlock);
  }, [rectData, previewBlocks]);
  return getBlocks;
}
