import { useContext } from 'react';

import type { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';

import { StyledIcon } from 'components';
import { LineFieldTypeName } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { useHandler } from 'hooks/useEventCallback';
import { DeleteSvg, LineMessageVerticalDrag } from 'icons';
import { trackGaEvent } from 'shared/lib/ga/trackGaEvent';
import { theme } from 'theme';

import { Context } from '../models';

import * as S from './Styled';

interface RowToolBoxProps {
  moduleType: LineFieldType;
  carouselIndex?: number;
  onRemoveClick?: () => void;
}

export const RowToolBox = ({ onRemoveClick, moduleType }: RowToolBoxProps) => {
  const { store } = useContext(Context);

  const handleRemoveClick = useHandler(() => {
    if (onRemoveClick) {
      onRemoveClick();
    }
    trackGaEvent('messageEditorModuleDelete', { label: LineFieldTypeName[moduleType] });
  });

  return (
    <S.RowToolBoxWrapper style={store.isDragMode ? { opacity: 1 } : undefined}>
      {!store.isDragMode ? (
        <StyledIcon
          className="editor-delete-icon"
          color={theme.colors.neutral007}
          width={21}
          height={21}
          fontSize={21}
          component={<DeleteSvg />}
          onClick={handleRemoveClick}
        />
      ) : null}
      {store.isDragMode ? (
        <StyledIcon
          className="editor-verticaldrag-icon"
          color={theme.colors.neutral007}
          width={24}
          height={24}
          display="inline-block"
          component={<LineMessageVerticalDrag />}
          style={{ cursor: 'ns-resize' }}
        />
      ) : null}
    </S.RowToolBoxWrapper>
  );
};
