import jwt_decode from 'jwt-decode';

import {
  LOCAL_STORAGE_LAST_CHANNEL,
  LOCAL_STORAGE_LAST_CHANNEL_TYPE,
  LOCAL_STORAGE_TOKEN,
} from 'AppConstants';

import type { ChannelMap, FlatChannel, UserJWT } from '../types';

export const flatChannelMap = (channelMap: ChannelMap): FlatChannel[] => {
  return Object.entries(channelMap)
    .map(([channelName, channels]) => {
      return channels.map((channel) => ({ ...channel, type: channelName }));
    })
    .reduce((pre, cur) => {
      return pre.concat(cur);
    }, []);
};

interface User {
  token: string;
  id: number;
}

export const getUser = (): User | undefined => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  if (typeof token === 'string') {
    const payload = jwt_decode<UserJWT>(token);
    if (payload && payload.user) {
      return {
        token,
        id: payload.user,
      };
    }
  }
};

export const cacheChannel = (channel: { id: number; type: string }): void => {
  localStorage.setItem(LOCAL_STORAGE_LAST_CHANNEL, channel.id.toString());
  localStorage.setItem(LOCAL_STORAGE_LAST_CHANNEL_TYPE, channel.type);
};
