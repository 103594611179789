import { createVarCall } from '@chatbotgang/motif/tokens/colors';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { InfoBoxProps, InfoBoxStatus } from 'shared/components/InfoBox/types';

import { getFontWeight } from 'shared/components/Typography/Typography';

interface InfoBoxContainerProps {
  status: NonNullable<InfoBoxProps['status']>;
  transparent: NonNullable<InfoBoxProps['transparent']>;
}

const getInfoBoxStatusStyle = (status: InfoBoxStatus) => {
  switch (status) {
    case 'primary':
      return css`
        --notice-box-background-color: ${createVarCall('--infobox-bg-primary')};
        --notice-box-color: ${createVarCall('--infobox-fg-primary')};
        --notice-box-action-text: ${createVarCall('--infobox-action-primary')};
        --notice-box-action-text-hover: ${createVarCall('--infobox-action-primaryHover')};
        --notice-box-action-text-focus: ${createVarCall('--interactive-fg-focus')};
      `;
    case 'plain':
      return css`
        --notice-box-background-color: ${createVarCall('--infobox-bg-plain')};
        --notice-box-color: ${createVarCall('--infobox-fg-plain')};
        --notice-box-action-text: ${createVarCall('--infobox-action-plain')};
        --notice-box-action-text-hover: ${createVarCall('--infobox-action-plainHover')};
        --notice-box-action-text-focus: ${createVarCall('--interactive-fg-focus')};
      `;
    case 'success':
      return css`
        --notice-box-background-color: ${createVarCall('--infobox-bg-success')};
        --notice-box-color: ${createVarCall('--infobox-fg-success')};
        --notice-box-action-text: ${createVarCall('--infobox-action-success')};
        --notice-box-action-text-hover: ${createVarCall('--infobox-action-successHover')};
        --notice-box-action-text-focus: ${createVarCall('--interactive-fg-focus')};
      `;
    case 'caution':
    case 'warning':
      return css`
        --notice-box-background-color: ${createVarCall('--infobox-bg-warning')};
        --notice-box-color: ${createVarCall('--infobox-fg-warning')};
        --notice-box-action-text: ${createVarCall('--infobox-action-warning')};
        --notice-box-action-text-hover: ${createVarCall('--infobox-action-warningHover')};
        --notice-box-action-text-focus: ${createVarCall('--interactive-fg-focus')};
      `;
    case 'error':
      return css`
        --notice-box-background-color: ${createVarCall('--infobox-bg-Error')};
        --notice-box-color: ${createVarCall('--infobox-fg-Error')};
        --notice-box-action-text: ${createVarCall('--infobox-action-error')};
        --notice-box-action-text-hover: ${createVarCall('--infobox-action-errorHover')};
        --notice-box-action-text-focus: ${createVarCall('--interactive-fg-focus')};
      `;
  }
};

export const InfoBoxContainer = styled.div<InfoBoxContainerProps>`
  ${({ status }) => getInfoBoxStatusStyle(status)}
  box-sizing: border-box;
  color: var(--notice-box-color);
  display: flex;
  gap: 4px;
  text-align: left;
  ${({ transparent }) =>
    transparent
      ? null
      : css`
          background-color: var(--notice-box-background-color);
          border-radius: 4px;
          margin-bottom: 8px;
          padding: 8px 12px;
        `}
`;

export const InfoBoxIconContainer = styled.div`
  align-items: flex-start;
  display: flex;
  font-size: 16px;
  padding: 2px;
`;

// Action items need to have a little more spacing than the gap between icon and text
export const InfoBoxAction = styled.div`
  margin-left: 12px;
`;

// Action text styling; note that PDs requested these colors should be customizable
export const InfoBoxActionText = styled.div`
  color: var(--notice-box-action-text);
  cursor: pointer;
  transition: color 0.1s;
  text-decoration: underline;
  &:hover,
  &:active {
    color: var(--notice-box-action-text-hover);
  }
  &:focus {
    outline: 3px solid var(--notice-box-action-text-focus);
  }
`;

export const InfoBoxContents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const InfoBoxContentsContainer = styled(InfoBoxContents)`
  flex: 1 1 auto;
  strong {
    font-weight: ${getFontWeight('medium')};
  }
`;
