import { useCallback, useEffect, useRef, useState } from 'react';

import type { MutableRefObject } from 'react';

import { useClickOutside } from 'hooks/useClickOutside';
import { useRefMap } from 'hooks/useRefMap';

export function useLastHover(): {
  parentRef: MutableRefObject<undefined>;
  index: number | undefined;
  setHoverRef: (ref: HTMLElement | null, index: number) => void;
  clear: () => void;
} {
  const parentRef = useRef();
  const [refMap, setRefMap] = useRefMap();
  const [lastHoverIndex, setLastHoverIndex] = useState<number | undefined>(undefined);

  useClickOutside(parentRef, () => clear());

  const setHoverRef = useCallback(
    (ref: HTMLElement | null, index: number) => {
      setRefMap(ref, index);
    },
    [setRefMap],
  );

  const clear = useCallback(() => {
    if (typeof lastHoverIndex !== 'undefined') {
      setLastHoverIndex(undefined);
    }
  }, [lastHoverIndex]);

  useEffect(() => {
    if (refMap.current) {
      refMap.current.forEach((ref, i) => {
        if (ref) {
          ref.addEventListener('mouseover', () => {
            setLastHoverIndex(i);
          });
          ref.addEventListener('blur', () => {
            setLastHoverIndex(undefined);
          });
        }
      });

      return () => {
        // eslint-disable-next-line
        refMap.current?.forEach((ref) => {
          if (ref) {
            ref.removeEventListener('mouseover', clear);
          }
        });
      };
    }
  }, [setHoverRef, setLastHoverIndex, clear, refMap.current?.size, refMap]);

  return {
    parentRef,
    index: lastHoverIndex,
    setHoverRef,
    clear,
  };
}
