import { forwardRef, memo } from 'react';

import type { InfoBoxProps, InfoBoxStatus } from 'shared/components/InfoBox/types';

import { MotifIcon } from 'icons/motif';
import {
  InfoBoxAction,
  InfoBoxActionText,
  InfoBoxContainer,
  InfoBoxContents,
  InfoBoxContentsContainer,
  InfoBoxIconContainer,
} from 'shared/components/InfoBox/InfoBoxStyled';
import { Typography } from 'shared/components/Typography';

const InfoBoxIcon = memo(function InfoBoxIcon({ status }: { status: InfoBoxStatus }) {
  switch (status) {
    case 'primary':
    case 'plain':
      return <MotifIcon un-i-motif="circle_info" />;
    case 'success':
      return <MotifIcon un-i-motif="circle_check" />;
    case 'caution':
      return <MotifIcon un-i-motif="circle_caution" />;
    case 'warning':
      return <MotifIcon un-i-motif="warning" />;
    case 'error':
      return <MotifIcon un-i-motif="circle_cross" />;
  }
});

/*
 * Standard notice box component with an icon on the left
 */
export const InfoBox = memo(
  forwardRef<HTMLDivElement, InfoBoxProps>(function InfoBox(
    { status = 'primary', transparent = false, title, action, children, ...restProps },
    ref,
  ) {
    return (
      <InfoBoxContainer status={status} transparent={transparent} {...restProps} ref={ref}>
        <InfoBoxIconContainer>
          <InfoBoxIcon status={status} />
        </InfoBoxIconContainer>
        <InfoBoxContentsContainer>
          <InfoBoxContents>
            {title ? (
              <Typography variant="body" fontWeight="medium">
                {title}
              </Typography>
            ) : null}
            <Typography variant="body" fontWeight="regular" as="div">
              <InfoBoxContents>{children}</InfoBoxContents>
            </Typography>
          </InfoBoxContents>
        </InfoBoxContentsContainer>
        {action ? (
          <InfoBoxAction>
            <InfoBoxActionText>
              <Typography variant="body" fontWeight="medium">
                {action}
              </Typography>
            </InfoBoxActionText>
          </InfoBoxAction>
        ) : null}
      </InfoBoxContainer>
    );
  }),
);
