import { EditorState, Modifier } from 'draft-js';

import type { SelectionState } from 'draft-js';

import { isParamRichTextFieldPrefixed } from 'components/LineMessageEditor/components/RichTextEditor/utils/paramRichTextField';
import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import { generateMappingKey } from 'components/LineMessageEditor/utils/transformKey';
import { EEditorType } from 'lib/validator/helper/validatorHelpers';

import { URL_TAG_REGEX } from './strategy';

export function getFinalOffset(blockText: string, focusOffset: number): number {
  const start = focusOffset >= 11 ? focusOffset - 11 : 0;
  const end = blockText.length - focusOffset >= 11 ? focusOffset + 11 : blockText.length;
  const leftString = blockText.substring(start, focusOffset);
  const rightString = blockText.substring(focusOffset, end);
  const finalString = leftString + rightString;
  if (finalString.match(URL_TAG_REGEX) === null) return 0;
  else return rightString.indexOf('>') + 2;
}

export function parseEntityKeyDigit(entityKey: string): string {
  if (entityKey) return entityKey.length === 1 ? '0' + entityKey : entityKey;
  return entityKey;
}

/**
 * **[Backward-compatible]** Get either:
 *
 * (1) generated `mappingKey` for `ParamRichTextEditor`, or
 * (2) `type` + `entityKeyDigit` for `RichTextEditor`
 *
 * @param entity
 * @param type
 * @param editorType
 */
function getMappingKeyOrEntityKeyDigit(
  entity: string,
  type: DecoratorType,
  editorType: EEditorType,
): string {
  if (type === DecoratorType.Name) {
    return type;
  }
  if (
    editorType === 'ParamRichTextEditor' &&
    type === DecoratorType.Custom &&
    isParamRichTextFieldPrefixed(entity)
  ) {
    return generateMappingKey('customText', entity);
  }
  return type + parseEntityKeyDigit(entity);
}

type OnUrlNameClick = (
  /** message.format.tagId.toString() */
  entityKey: string,
  callback: (rowIndex: number, type: DecoratorType, urlKey: string) => void,
  rowIndex: number,
  editorState: EditorState,
  setEditorState: (editorState: EditorState, isFocus: boolean) => void,
  type: DecoratorType,
  editorType?: EEditorType.RichTextEditor | EEditorType.ParamRichTextEditor,
) => void;
export const onUrlNameClick: OnUrlNameClick = (
  entityKey,
  callback,
  rowIndex,
  editorState,
  setEditorState,
  type,
  editorType = EEditorType.RichTextEditor,
) => {
  let contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const contentStateWithEntity = contentState.createEntity('MY_ENTITY_TYPE', 'IMMUTABLE');
  const mappingKeyOrEntityKeyDigit = getMappingKeyOrEntityKeyDigit(entityKey, type, editorType);
  const generatedEntityKey = contentState.getLastCreatedEntityKey();
  // ---------------------------------------------------

  callback(rowIndex, type, mappingKeyOrEntityKeyDigit);

  // ---------------------------------------------------
  const anchorKey = selectionState.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  const blockText = currentBlock.getText();
  const focusOffset = editorState.getSelection().getFocusOffset();
  const offset = getFinalOffset(blockText, focusOffset);
  // console.log(
  //   'getFocusOffset: ' + focusOffset,
  //   'Block Text: #' + blockText + '#',
  //   'blockText.length: ' + blockText.length,
  //   'allText: ' +
  //     editorState
  //       .getCurrentContent()
  //       .getBlocksAsArray()
  //       .map(d => d.getText())
  //       .reduce((pre, now) => pre + '\n' + now),
  // );

  const updatedSelection = selectionState.merge({
    anchorOffset: selectionState.getFocusOffset() + offset,
    focusOffset: selectionState.getFocusOffset() + offset,
  }) as SelectionState;
  // ---------------------------------------------------

  contentState = Modifier.insertText(
    contentStateWithEntity,
    updatedSelection, // selectionState
    ' <$var:' + mappingKeyOrEntityKeyDigit + '> ',
    undefined,
    generatedEntityKey,
  );

  let newState = EditorState.push(editorState, contentState, 'insert-characters');

  if (!newState.getCurrentContent().equals(editorState.getCurrentContent())) {
    const sel = newState.getSelection();

    newState = EditorState.forceSelection(newState, sel);
  }
  setEditorState(newState, true);
};
