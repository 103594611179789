import PictureOutlined from '@ant-design/icons/PictureOutlined';
import Space from 'antd/es/space';
import cn from 'classnames';
import debug from 'debug';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MAX_IMAGE_SIZE } from 'components/LineMessageEditor/constants';

import type { ParamPopoverIconProps } from './ParamPopovers/ParamPopoverIcon';
import type { URIAction } from '@line/bot-sdk';
import type {
  ImageEditor,
  ImageEditorProps,
} from 'components/LineMessageEditor/components/ImageEditor';
import type { UseImageEditorUploadImageResult } from 'components/LineMessageEditor/hooks/useImageEditorUploadImage';
import type {
  CustomUriAction,
  ImageCarouselEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import type {
  CustomParameter,
  EditorDataType,
  EditorParameter,
  ImageSourceType,
  LinkParameter,
  Utm,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ChangeEvent, FocusEvent } from 'react';
import type { RadioChangeEvent } from 'shared/components/Radio';

import { Box } from 'components/layoutUtils/Box';
import { RowToolBox } from 'components/LineMessageEditor/components/RowToolBox';
import {
  ImageWrapper,
  PopOverFieldTitle,
  UploadImageLabel,
} from 'components/LineMessageEditor/components/Styled';
import { useImageEditorUploadImage } from 'components/LineMessageEditor/hooks/useImageEditorUploadImage';
import { Context } from 'components/LineMessageEditor/models/models';
import { isImageCarouselDataRule } from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import {
  isCardDataRule,
  isCarrouselDataRule,
  isCustomParameter,
  isImageDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { Loading } from 'components/Loading/Loading';
import { StyledIcon } from 'components/StyledIcon/StyledIcon';
import { SubText, Text } from 'components/Typography/Text';
import { useAuth } from 'context/authContext/context';
import { useHandler } from 'hooks/useEventCallback';
import { EditSvg } from 'icons/edit';
import {
  customNameHelper,
  messageNameHelper,
  notificationTextNameHelper,
  uploadImageHelper,
} from 'lib/validator/helper/validatorHelpers';
import { FieldScopeProvider } from 'lib/validator/hooks/useValidateScope';
import {
  PopoverField as ValidatorPopoverField,
  PopoverFieldTextArea as ValidatorPopoverFieldTextArea,
} from 'lib/validator/popover';
import { customParameter } from 'lib/validator/rules/customParameter';
import { maxLength } from 'lib/validator/rules/maxLength';
import { required } from 'lib/validator/rules/required';
import { useField } from 'lib/validator/useField';
import { isInvalid } from 'lib/validator/utils/invalid';
import { TriggerCodeModule } from 'modules/TriggerCodeModule/TriggerCodeModule';
import { UploadImageButton } from 'shared/components/Button/UploadImageButton';
import { generateDataTestId } from 'shared/components/Editor/LineMessageEditor/utils/generateDataTestId';
import { Radio, RadioGroup } from 'shared/components/Radio';
import {
  Tooltip,
  TooltipAction,
  TooltipContainer,
  TooltipDescription,
} from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import { ParamPopover, ParamPopoverContent } from './ParamPopovers/ParamPopover';
import { ParamPopoverIcon } from './ParamPopovers/ParamPopoverIcon';
import { ParamPopovers } from './ParamPopovers/ParamPopovers';
import { MessageConflictNotice } from './MessageConflictNotice';
import { UrlUTMModule } from './UrlUTMModule';

export type OnDeleteRowData = () => void;
type OnDeleteRowDataWithCallback = (callback: OnDeleteRowData) => void;

interface ParamImageEditorRow {
  row: EditorDataType;
}

interface ParamImageEditorProps extends Omit<ImageEditorProps, 'onDelete'> {
  onDelete?: OnDeleteRowData | OnDeleteRowDataWithCallback;
}

type ParamImageEditImagePopoverProps = ParamImageEditorRow &
  Required<Pick<ImageEditorProps, 'rowIndex' | 'editorType' | 'isEmbed'>> &
  Pick<ImageEditorProps, 'carrouselIndex'> &
  Pick<UseImageEditorUploadImageResult, 'handleUploadImage' | 'isLoading'> &
  ParamPopoverIconProps;

type ParamImageSettingsPopoverProps = ParamImageEditorRow &
  Required<Pick<ImageEditorProps, 'rowIndex' | 'carrouselIndex'>> &
  ParamPopoverIconProps;

const log = debug('imageEditor');

const ParamImageEditImagePopover = memo(function ParamImageEditImagePopover(
  props: ParamImageEditImagePopoverProps,
) {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const param: CustomParameter | undefined = useMemo(() => {
    return props.row.parameters.find((p: EditorParameter) => {
      if (!isCustomParameter(p)) {
        return false;
      }
      if (isImageDataRule(props.row)) {
        return props.row.data.key === p.mappingKey;
      } else if (isImageCarouselDataRule(props.row) && props.carrouselIndex !== undefined) {
        return props.row.data.contents[props.carrouselIndex].hero.contents[0].key === p.mappingKey;
      } else if (isCardDataRule(props.row)) {
        return props.row.data.contents.hero?.key === p.mappingKey;
      } else if (isCarrouselDataRule(props.row) && props.carrouselIndex !== undefined) {
        return props.row.data.contents[props.carrouselIndex].contents.hero?.key === p.mappingKey;
      }
      return false;
    });
  }, [props.row, props.carrouselIndex]);
  const imgSrcCategory: ImageSourceType = useMemo(() => {
    return param === undefined ? 'local' : 'param';
  }, [param]);
  const isImageCarouselEditor = useMemo(() => {
    return props.editorType === 'ImageCarouselEditor';
  }, [props.editorType]);

  const handleRadioChange = useHandler((e: RadioChangeEvent) => {
    const currentType = e.target.value;
    const isParam = currentType === 'param';
    if (!isImageCarouselEditor) {
      dispatch('switchImageSourceType', {
        rowIndex: props.rowIndex,
        currentSourceType: currentType,
        previousSourceType: imgSrcCategory,
        key: isParam ? param?.key ?? '' : '',
        carrouselIndex: props.carrouselIndex,
      });
    } else {
      dispatch('setImageCarouselImageUrlWithKey', {
        isParam,
        rowIndex: props.rowIndex,
        carouselIndex: props.carrouselIndex,
        url: isParam ? param?.key ?? '' : '',
      });
    }
  });
  const handleParamTextFieldChange = useHandler((e: ChangeEvent<HTMLInputElement>) => {
    if (!isImageCarouselEditor) {
      dispatch('setImageUrlBySourceType', {
        rowIndex: props.rowIndex,
        sourceType: 'param',
        aspectRatio: '1:1',
        url: e.target.value,
        carrouselIndex: props.carrouselIndex,
      });
    } else {
      dispatch('setImageCarouselImageUrlWithKey', {
        isParam: true,
        rowIndex: props.rowIndex,
        carouselIndex: props.carrouselIndex,
        url: e.target.value,
        aspectRatio: '1:1',
        isAnimated: false,
      });
    }
  });
  const handleOpenPopover = useHandler(() => {
    setIsOpen(true);
  });

  return (
    <ParamPopover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={props.title}
      content={
        <ParamPopoverContent>
          <PopOverFieldTitle>{t('message.paramImageEditor.imageProvidedBy')}</PopOverFieldTitle>
          <RadioGroup onChange={handleRadioChange} value={imgSrcCategory}>
            <Space direction="vertical">
              <Radio
                value="local"
                data-test={generateDataTestId('image-editor-popover-local', props.carrouselIndex)}
              >
                {t('message.paramImageEditor.yourComputer')}
              </Radio>
              <Radio
                value="param"
                data-test={generateDataTestId('image-editor-popover-param', props.carrouselIndex)}
              >
                {t('message.paramImageEditor.apiParameter')}
                <span style={{ marginLeft: 4 }}>
                  <Tooltip
                    title={
                      <TooltipContainer>
                        <TooltipDescription>
                          {t('message.paramImageEditor.tooltip.apiParam.title')}
                        </TooltipDescription>
                        <TooltipAction
                          href={t('message.paramImageEditor.tooltip.apiParam.linkHref')}
                        >
                          {t('message.paramImageEditor.tooltip.apiParam.linkText')}
                        </TooltipAction>
                      </TooltipContainer>
                    }
                  />
                </span>
              </Radio>
            </Space>
          </RadioGroup>
          <PopOverFieldTitle>
            {imgSrcCategory === 'local'
              ? t('message.paramImageEditor.uploadImage')
              : t('message.apiParameterName')}
          </PopOverFieldTitle>
          <div>
            {imgSrcCategory === 'local' ? (
              <Space direction="vertical">
                <SubText color={theme.colors.neutral007}>
                  {props.isEmbed
                    ? t('message.card.imageLimitWithApng', {
                        fileSize: '1 MB',
                      })
                    : t('message.image.imageLimitWithApng', {
                        fileSize: '1 MB',
                        widthHeight: MAX_IMAGE_SIZE,
                      })}
                </SubText>
                <UploadImageButton
                  buttonProps={{
                    disabled: props.isLoading,
                    'data-test': generateDataTestId(
                      'image-editor-popover-local-upload-image-button',
                      props.carrouselIndex,
                    ),
                  }}
                  fileInputProps={{
                    accept: 'image/jpeg,image/png',
                    onChange: props.handleUploadImage,
                    'data-test': generateDataTestId(
                      'image-editor-popover-local-upload-image-input',
                      props.carrouselIndex,
                    ),
                  }}
                >
                  {t('message.paramImageEditor.uploadImage')}
                </UploadImageButton>
              </Space>
            ) : (
              <ValidatorPopoverField
                rules={[required, customParameter]}
                name={customNameHelper({
                  rowIndex: props.rowIndex,
                  editorIndex: props.carrouselIndex,
                  editorType: props.editorType,
                  entityKey: '_imagePopover', // Bypass `errorLocationAndEditorTypes` splitting in src/lib/validator/helper/errorHelper.tsx
                })}
                value={param?.key ?? ''}
                onChange={handleParamTextFieldChange}
                placeholder={t('message.apiParameterName')}
                style={{ imeMode: 'disabled' }}
                enableReinitialize={true}
                data-test={generateDataTestId(
                  'image-editor-popover-param-key',
                  props.carrouselIndex,
                )}
              />
            )}
          </div>
        </ParamPopoverContent>
      }
    >
      <ParamPopoverIcon
        onClick={handleOpenPopover}
        onLight={props.onLight}
        data-test={generateDataTestId('image-editor-popover', props.carrouselIndex)}
      >
        <StyledIcon fontSize={24} component={<PictureOutlined />} />
        <span>{props.title}</span>
      </ParamPopoverIcon>
    </ParamPopover>
  );
});

const PopupSubTitle = styled.div`
  line-height: 20px;
  margin-bottom: 8px;
  color: ${theme.colors.neutral010};
  font-weight: 500;
`;

const PopupSubContent = styled.div`
  margin-bottom: 20px;
`;

const ParamImageSettingsPopover = memo(function ParamImageSettingsPopover({
  row,
  rowIndex,
  carrouselIndex: carouselIndex,
  ...props
}: ParamImageSettingsPopoverProps) {
  const { t } = useTranslation();
  const { store, dispatch } = useContext(Context);
  const [isOpen, setIsOpen] = useState(false);
  const authContext = useAuth();
  const message = useMemo(() => {
    return row as ImageCarouselEditorData;
  }, [row]);
  const content = useMemo(() => {
    return message.data.contents[carouselIndex];
  }, [carouselIndex, message]);
  const heroActionUriParam = useMemo(() => {
    return message?.parameters?.find(
      (d) => d.key === (content?.hero?.action as URIAction)?.uri,
    ) as LinkParameter;
  }, [content, message]);
  const heroActionCustomUriParam = useMemo(() => {
    return message?.parameters?.find(
      (p) => p.mappingKey === (content?.hero?.action as CustomUriAction)?.key,
    ) as CustomParameter;
  }, [content, message]);

  const handleOpenPopover = useHandler(() => {
    setIsOpen(true);
  });
  const handleNotificationTextChange = useHandler((e: ChangeEvent<HTMLInputElement>) => {
    dispatch('setImageCarouselNotificationText', {
      rowIndex,
      notificationText: e.target.value,
    });
  });
  const handleRadioChange = useHandler((e: RadioChangeEvent) => {
    dispatch('setImageCarouselActionType', {
      rowIndex,
      carouselIndex,
      actionType: e.target.value,
    });
  });
  const handleActionText = useHandler(
    (e: ChangeEvent<HTMLTextAreaElement> | FocusEvent<HTMLTextAreaElement>) => {
      dispatch('setImageCarouselMessage', {
        rowIndex,
        carouselIndex,
        message: e.target.value,
      });
    },
  );
  const handleOpenExternalChange = useHandler((checked: boolean) => {
    dispatch('setImageCarouselUrlData', {
      rowIndex,
      carouselIndex,
      openExternal: checked,
    });
  });
  const handleUrlAndUTMChange = useHandler((uri: string, utmFields: Utm) => {
    dispatch('setImageCarouselUrlAndUtm', {
      rowIndex,
      carouselIndex,
      url: uri,
      utmFields,
    });
  });
  const handleCustomUriChange = useHandler((e: ChangeEvent<HTMLInputElement>) => {
    dispatch('setImageCarouselCustomUri', {
      rowIndex,
      carouselIndex,
      customUri: e.target.value,
    });
  });
  const handleTagChange = useHandler((tags: string[]) => {
    dispatch('setImageCarouselUrlData', {
      rowIndex,
      carouselIndex,
      tagList: tags,
    });
  });
  const createUTMChangeHandler = useHandler(
    (type: 'url' | 'utm_campaign' | 'utm_medium' | 'utm_source' | 'utm_content') =>
      (value: string) =>
        dispatch('setImageCarouselUrlData', {
          rowIndex,
          carouselIndex,
          type,
          text: value,
        }),
  );

  if (!content || !message || carouselIndex === -1) {
    return null;
  }
  return (
    <ParamPopover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={t('message.paramImageEditor.imageCarrouselSetting')}
      content={
        <ParamPopoverContent>
          <PopupSubTitle>
            <Text>{t('message.notificationText')}</Text>
            <br />
            <SubText fontSize="14px" m="8px 0" color={theme.colors.neutral007}>
              {t('message.notificationHint')}
            </SubText>
          </PopupSubTitle>
          <PopupSubContent>
            <ValidatorPopoverField
              name={notificationTextNameHelper({
                rowIndex,
                entityKey: '_settingsPopover', // Bypass `errorLocationAndEditorTypes` splitting in src/lib/validator/helper/errorHelper.tsx
                editorIndex: carouselIndex,
                editorType: 'ImageCarouselEditor',
              })}
              rules={[maxLength(300), required]}
              onChange={handleNotificationTextChange}
              placeholder={t('message.inputWithLengthLimit', {
                fieldName: lowerFirst(t('message.notificationText')),
                length: 300,
              })}
              value={message.data.notification_text}
              enableReinitialize={true}
              defaultChecked={false}
              data-test="image-settings-editor-popover-notification-input"
            />
          </PopupSubContent>
          <PopupSubTitle>
            <Space direction="vertical">
              <Text>{t('glossary.actionArea')}</Text>
              <SubText fontSize="14px" m="8px 0" color={theme.colors.neutral007}>
                {t('message.actionAreaHint')}
              </SubText>
            </Space>
            {store.hasShareableConflict ? (
              <Box my="16px">
                <MessageConflictNotice />
              </Box>
            ) : null}
          </PopupSubTitle>
          <RadioGroup onChange={handleRadioChange} value={content.hero.action.type}>
            <Space direction="vertical">
              <Radio
                value="message"
                data-test={generateDataTestId(
                  'image-settings-editor-popover-message',
                  carouselIndex,
                )}
              >
                {t('message.send')}
              </Radio>
              <Radio
                value="uri"
                data-test={generateDataTestId('image-settings-editor-popover-uri', carouselIndex)}
              >
                {t('common.openUrl')}
              </Radio>
              <Radio
                value="customUri"
                data-test={generateDataTestId(
                  'image-settings-editor-popover-customUri',
                  carouselIndex,
                )}
              >
                {t('message.apiUrl')}
              </Radio>
            </Space>
          </RadioGroup>
          <Box m="8px 0 16px">
            {content.hero.action.type === 'message' ? (
              <>
                <ValidatorPopoverFieldTextArea
                  name={messageNameHelper({
                    rowIndex,
                    entityKey: '_settingsPopoverMessage', // Bypass `errorLocationAndEditorTypes` splitting in src/lib/validator/helper/errorHelper.tsx
                    editorIndex: carouselIndex,
                    editorType: 'ImageCarouselEditor',
                  })}
                  rules={[required, maxLength(300)]}
                  enableReinitialize={true}
                  placeholder={t('message.inputWithLengthLimit', {
                    fieldName: lowerFirst(t('glossary.message')),
                    length: 300,
                  })}
                  value={content.hero.action.text}
                  onChange={handleActionText}
                  onBlur={handleActionText}
                  defaultChecked={false}
                  data-test={generateDataTestId(
                    'image-settings-editor-popover-message-input',
                    carouselIndex,
                  )}
                />
                <TriggerCodeModule
                  triggerCode={content.hero.action.text}
                  currentChannel={authContext.state.currentChannel}
                  mt="16px"
                />
              </>
            ) : null}
            {content.hero.action.type === 'uri' ? (
              <FieldScopeProvider>
                <UrlUTMModule
                  rowIndex={rowIndex}
                  entityKey="_settingsPopoverUri" // Bypass `errorLocationAndEditorTypes` splitting in src/lib/validator/helper/errorHelper.tsx
                  editorIndex={carouselIndex}
                  urlValue={heroActionUriParam?.data.url ?? ''}
                  openExternal={heroActionUriParam?.data.open_external_browser ?? false}
                  onOpenExternalChange={handleOpenExternalChange}
                  onFieldsChange={handleUrlAndUTMChange}
                  UTMSourceValue={heroActionUriParam?.data.utm_source ?? ''}
                  onUTMSourceChange={createUTMChangeHandler('utm_source')}
                  UTMMediumValue={heroActionUriParam?.data.utm_medium ?? ''}
                  onUTMMediumChange={createUTMChangeHandler('utm_medium')}
                  UTMCampaignValue={heroActionUriParam?.data.utm_campaign ?? ''}
                  onUTMCampaignChange={createUTMChangeHandler('utm_campaign')}
                  UTMContentValue={heroActionUriParam?.data.utm_content ?? ''}
                  onUTMContentChange={createUTMChangeHandler('utm_content')}
                  tagList={heroActionUriParam?.data.tag_list ?? []}
                  onTagChange={handleTagChange}
                  editorType="ImageCarouselEditor"
                  data-test={generateDataTestId(
                    'image-settings-editor-popover-uri-input',
                    carouselIndex,
                  )}
                />
              </FieldScopeProvider>
            ) : null}
            {content.hero.action.type === 'customUri' ? (
              <ValidatorPopoverField
                rules={[required, customParameter]}
                name={customNameHelper({
                  rowIndex: rowIndex,
                  editorIndex: carouselIndex,
                  editorType: 'ImageCarouselEditor',
                  entityKey: '_settingsPopoverCustomUri', // Bypass `errorLocationAndEditorTypes` splitting in src/lib/validator/helper/errorHelper.tsx
                })}
                value={heroActionCustomUriParam?.key ?? ''}
                onChange={handleCustomUriChange}
                placeholder={t('message.apiParameterName')}
                style={{ imeMode: 'disabled' }}
                enableReinitialize={true}
                data-test={generateDataTestId(
                  'image-settings-editor-popover-customUri-input',
                  carouselIndex,
                )}
              />
            ) : null}
          </Box>
        </ParamPopoverContent>
      }
    >
      <ParamPopoverIcon
        onClick={handleOpenPopover}
        onLight={props.onLight}
        data-test={generateDataTestId('image-settings-editor-popover', carouselIndex)}
      >
        <StyledIcon fontSize={24} component={<EditSvg />} />
        <span>{props.title}</span>
      </ParamPopoverIcon>
    </ParamPopover>
  );
});

/**
 * `ParamImageEditor` provides end users with two options of an image source:
 *
 * (1) Upload image locally (2) Set a parameter key for Open API to pass the image
 * url.
 *
 * The above options are placed in a popover of `ParamImageEditor` instead of what
 * {@link ImageEditor} behaves as a single file-upload input, which is the option
 * (1) of `ParamImageEditor`.
 *
 * Use `ParamImageEditor` for multiple image sources.
 */
export const ParamImageEditor = memo(function ParamImageEditor({
  rowIndex,
  message,
  isEmbed = false,
  required: isRequired = false,
  carrouselIndex,
  onDelete,
  onUploadDone,
  editorType = 'ImageEditor',
}: ParamImageEditorProps) {
  const { t } = useTranslation();
  const { store, dispatch } = useContext(Context);
  const row = useMemo(() => store.editorData[rowIndex], [rowIndex, store.editorData]);
  const { update, invalid, validate, value } = useField({
    name: uploadImageHelper({
      rowIndex,
      editorIndex: carrouselIndex,
      editorType,
      entityKey: '',
    }),
    rules: isRequired ? [required] : [],
    checkOnChange: true,
    value: message.data.content_url === '' ? '' : 'imageUpload',
    enableReinitialize: true,
  });
  const { handleUploadImage, isLoading } = useImageEditorUploadImage({
    row,
    imageEditorProps: { rowIndex, carrouselIndex, editorType, onUploadDone },
    validatorProps: {
      update,
    },
  });
  const isInitial = useMemo(
    () => !isLoading && message.data.content_url === '',
    [isLoading, message],
  );
  log(rowIndex, store);

  // This is copied from ImageEditor
  useEffect(() => {
    validate(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickRow = useHandler(() => {
    dispatch('setEditingRow', { rowIndex });
  });
  const handleDeleteRowData = useHandler(() => {
    if (isCarrouselDataRule(row) && row.data.contents.length > 1) {
      dispatch('removeCarrousel', { rowIndex, index: carrouselIndex });
      return;
    }
    dispatch('deleteEditorData', { rowIndex });
  });
  const handleDeleteRow = useHandler(() => {
    if (onDelete) {
      onDelete(handleDeleteRowData);
      return;
    }
    handleDeleteRowData();
  });

  const flatBorderBottom = ['CardEditor', 'CarrouselEditor'].includes(editorType);
  return (
    <ImageWrapper
      className={cn({
        'is-error': isInvalid(invalid),
        'is-drag-mode': store.isDragMode,
      })}
      onClick={handleClickRow}
      {...(isEmbed
        ? {
            mb: 0,
            width: 272,
            height: 281,
          }
        : { height: 156 })}
      flatBorderBottom={flatBorderBottom}
    >
      <UploadImageLabel
        bgUrl={message.data.content_url}
        type="image"
        flatBorderBottom={flatBorderBottom}
      >
        {isInitial ? (
          <ParamPopovers
            isOverlay={false}
            hasLeftSlot={true}
            leftSlot={
              <ParamImageEditImagePopover
                row={row}
                rowIndex={rowIndex}
                editorType={editorType}
                carrouselIndex={carrouselIndex}
                isEmbed={isEmbed}
                title={t('message.paramImageEditor.uploadImage')}
                handleUploadImage={handleUploadImage}
                isLoading={isLoading}
                onLight={true}
              />
            }
            hasRightSlot={editorType === 'ImageCarouselEditor'}
            rightSlot={
              <ParamImageSettingsPopover
                row={row}
                rowIndex={rowIndex}
                carrouselIndex={carrouselIndex ?? -1}
                title={t('glossary.setting')}
                onLight={true}
              />
            }
          />
        ) : (
          <ParamPopovers
            isOverlay={true}
            hasLeftSlot={true}
            leftSlot={
              <ParamImageEditImagePopover
                row={row}
                rowIndex={rowIndex}
                editorType={editorType}
                carrouselIndex={carrouselIndex}
                isEmbed={isEmbed}
                title={t('message.paramImageEditor.editImage')}
                handleUploadImage={handleUploadImage}
                isLoading={isLoading}
              />
            }
            hasRightSlot={editorType === 'ImageCarouselEditor'}
            rightSlot={
              <ParamImageSettingsPopover
                row={row}
                rowIndex={rowIndex}
                carrouselIndex={carrouselIndex ?? -1}
                title={t('glossary.setting')}
              />
            }
          />
        )}
        {isLoading ? <Loading /> : null}
      </UploadImageLabel>
      <RowToolBox
        moduleType={row.module_id}
        carouselIndex={carrouselIndex}
        onRemoveClick={handleDeleteRow}
      />
    </ImageWrapper>
  );
});
