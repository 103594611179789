import { MESSAGE_MODULE_TYPE } from 'shared/components/Editor/LineMessageEditor/constants';

import type {
  ImagemapCarouselModule,
  ImagemapContentBox,
  ImagemapContents,
} from 'shared/components/Editor/LineMessageEditor/types';

export const IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH = 272;

export const defaultImagemapCarouselActionBlock: ImagemapContentBox = {
  type: 'box',
  layout: 'vertical',
  contents: [],
  position: 'absolute',
  width: '100%',
  height: '100%',
  offsetTop: '0%',
  offsetStart: '0%',
  action: {
    type: 'message',
    text: '',
  },
};

export const defaultImagemapCarouselContent: ImagemapContents = {
  type: 'bubble',
  size: 'giga',
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'image',
        url: '',
        size: 'full',
        aspectMode: 'cover',
        aspectRatio: '1:1',
        gravity: 'center',
        animated: false,
      },
      defaultImagemapCarouselActionBlock,
    ],
    paddingAll: '0px',
  },
};

export const defaultImagemapCarouselModule: ImagemapCarouselModule = {
  module_id: MESSAGE_MODULE_TYPE.imagemapCarousel,
  data: {
    notification_text: '',
    contents: {
      type: 'carousel',
      contents: [defaultImagemapCarouselContent],
    },
  },
  actions: [],
  parameters: [],
  quick_reply: { items: [] },
  // UI related field
  customBlocks: [false],
  activeBlockIndices: [1],
};
