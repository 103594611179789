import { memo } from '@chatbotgang/etude/react/memo';
import { useHandler } from '@chatbotgang/etude/react/useHandler';
import Flex from 'antd/es/flex';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { RubatoErrorEvents } from 'lib/axios/rubato/rubatoErrorEventEmitter';

import { useToggle } from 'hooks/useToggle';
import { NotFoundSvg } from 'layout/components/NotFoundSvg';
import { rubatoErrorEventEmitter } from 'lib/axios/rubato/rubatoErrorEventEmitter';
import { Button } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';

export const UnavailableFeatureErrorModal = memo(function UnavailableFeatureErrorModal() {
  const { t } = useTranslation();
  const { active: modalOpen, on: openModal, off: closeModal } = useToggle(false);
  const confirmCallbackRef = useRef<() => void>();

  const onConfirm = useHandler(() => {
    closeModal();
    confirmCallbackRef.current?.();
  });

  const eventHandler = useHandler<RubatoErrorEvents['savingUnavailableFeatureError']>(
    (_, conformCallback) => {
      openModal();
      confirmCallbackRef.current = conformCallback;
    },
  );

  useEffect(
    function bindRubatoErrorEvent() {
      rubatoErrorEventEmitter.on('savingUnavailableFeatureError', eventHandler);
      return function cleanup() {
        rubatoErrorEventEmitter.off('savingUnavailableFeatureError', eventHandler);
      };
    },
    [eventHandler],
  );

  return (
    <Modal
      title={t('featureSetting.unavailable.modal.title')}
      footer={[
        <Button key="confirm" type="primary" onClick={onConfirm}>
          {t('featureSetting.unavailable.modal.button.reEdit')}
        </Button>,
      ]}
      open={modalOpen}
      onCancel={onConfirm}
      centered={true}
      closable={false}
      maskClosable={false}
    >
      <Flex align="center" vertical={true} gap={32}>
        <NotFoundSvg />
        <Typography variant="body">{t('featureSetting.unavailable.modal.content')}</Typography>
      </Flex>
    </Modal>
  );
});
