import { memo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import type { ErrorFallbackPageProps } from './ErrorFallbackPage';
import type { ReactNode } from 'react';
import type { ErrorBoundaryPropsWithRender } from 'react-error-boundary';

import { ErrorFallbackPage } from './ErrorFallbackPage';

type InheritedPropsFromErrorFallbackPage = Omit<
  ErrorFallbackPageProps,
  /**
   * The prop, `FallbackProps` is superfluous because we should use the value
   * from the callback function, `fallbackRender` inside directly.
   **/
  keyof Parameters<ErrorBoundaryPropsWithRender['fallbackRender']>[0]
>;

type PageErrorBoundaryProps = Omit<
  ErrorBoundaryPropsWithRender,
  'FallbackComponent' | 'fallbackRender' | 'fallback' | 'onReset'
> &
  InheritedPropsFromErrorFallbackPage & {
    children: ReactNode;
  };

export const PageErrorBoundary = memo(function PageErrorBoundary({
  children,
  title,
  content,
  reloadWindow,
  styledCss,
  ...props
}: PageErrorBoundaryProps) {
  return (
    <ErrorBoundary
      fallbackRender={(fallbackProps) => (
        <ErrorFallbackPage {...{ ...fallbackProps, title, content, reloadWindow, styledCss }} />
      )}
      {...props}
    >
      {children}
    </ErrorBoundary>
  );
});
