import { useCallback, useRef } from 'react';

import type { RefObject } from 'react';

export function useRefMap(): [
  RefObject<Map<number, HTMLElement | null> | undefined>,
  (ref: HTMLElement | null, index: number) => void,
] {
  const refMap = useRef(new Map<number, HTMLElement | null>());

  const setRefMap = useCallback((ref: HTMLElement | null, index: number) => {
    refMap.current.set(index, ref);
  }, []);

  return [refMap, setRefMap] as [typeof refMap, typeof setRefMap];
}
