import type { TextProps } from 'components';
import type { SpaceProps } from 'styled-system';

import { Text } from 'components';
import { LoadingOutlined } from 'icons/ant-design-icons';
import { reactNodeToJsxElement } from 'utils/react/reactNodeToJsxElement';

interface TableReportColumnProps {
  value: number | null | undefined;
  prefix?: string;
  suffix?: string;
  isRenderReport?: boolean;
  isSent: boolean;
  defaultValue?: string | number;
}

export const TableReportColumn = ({
  value,
  isRenderReport,
  prefix,
  isSent,
  suffix,
  defaultValue,
}: TableReportColumnProps) => {
  if (isSent && (value === null || value === undefined)) {
    return reactNodeToJsxElement(defaultValue ?? 0);
  } else if (value === null || value === undefined || !isRenderReport || !isSent) {
    return <strong>—</strong>;
  } else if (value === -1) {
    return <LoadingOutlined />;
  } else {
    return <Text fontWeight="bold">{prefix + value.toLocaleString() + suffix}</Text>;
  }
};

TableReportColumn.defaultProps = {
  isRenderReport: true,
  prefix: '',
  suffix: '',
};

interface ColumnTextProps extends Pick<TextProps, 'fontWeight'>, SpaceProps {
  loading?: boolean;
  emptyValue?: string;
  value: number | string | Date | null | undefined;
  toFixed?: number;
  prefix?: string;
  suffix?: string;
}

export const ColumnText = ({
  loading = false,
  fontWeight = 'bold',
  emptyValue = '—',
  toFixed,
  value,
  prefix = '',
  suffix = '',
  ...styledSystemProps
}: ColumnTextProps) => {
  if (loading) {
    return <LoadingOutlined />;
  }
  if (value === null || value === undefined || (typeof value === 'number' && Number.isNaN(value))) {
    return (
      <Text fontWeight={fontWeight} {...styledSystemProps}>
        {prefix + emptyValue + suffix}
      </Text>
    );
  }
  if (typeof value === 'number' && toFixed !== undefined) {
    return (
      <Text fontWeight={fontWeight} {...styledSystemProps}>
        {prefix + value.toFixed(toFixed) + suffix}
      </Text>
    );
  }
  return (
    <Text fontWeight={fontWeight} {...styledSystemProps}>
      {prefix + value.toLocaleString() + suffix}
    </Text>
  );
};
