import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ImageMapCarousel,
  ImageMapCarouselActionBlock,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import type { LinkParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { FlatChannel } from 'layout/types';

import { Box } from 'components';
import { Context } from 'components/LineMessageEditor/models';
import {
  isImageMapCarouselCustomUriAction,
  isImageMapCarouselMessageAction,
  isImageMapCarouselUriAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import * as Validator from 'lib/validator';
import { customNameHelper, messageNameHelper } from 'lib/validator/helper/validatorHelpers';
import { TriggerCodeModule } from 'modules/TriggerCodeModule';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';
import { UrlUTMModule } from '../UrlUTMModule';

interface ImageMapTextOrUrlProps {
  rowIndex: number;
  carouselIndex: number;
  actionBlocks: ImageMapCarouselActionBlock[];
  activeBlockIndex: number;
  parameters: ImageMapCarousel['parameters'];
  currentChannel: FlatChannel | null;
}
export const ImageMapTextOrUrl = memo(function ImageMapTextOrUrl({
  rowIndex,
  carouselIndex,
  actionBlocks,
  activeBlockIndex,
  parameters,
  currentChannel,
}: ImageMapTextOrUrlProps) {
  const { dispatch } = useContext(Context);
  const { validate } = useContext(Validator.Context);
  const { t } = useTranslation();

  const currentActionBlock = useMemo(
    // Might be undefined due to no action block at the point the user switches to custom block mode
    () => actionBlocks[activeBlockIndex - 1]?.action,
    [actionBlocks, activeBlockIndex],
  );

  return actionBlocks.length > 0 ? (
    <>
      <S.PopOverFieldTitle>
        {isImageMapCarouselMessageAction(currentActionBlock)
          ? t('glossary.message')
          : isImageMapCarouselCustomUriAction(currentActionBlock)
            ? t('message.apiParameterName')
            : t('glossary.url')}
        #{activeBlockIndex}
      </S.PopOverFieldTitle>
      {actionBlocks.map(({ action }, index) => {
        if (isImageMapCarouselMessageAction(action)) {
          return (
            <Box key={'ImageMapCarouselEditor' + rowIndex + 'ActionContent' + index}>
              <S.PopOverFieldsContent
                style={{ display: index === activeBlockIndex - 1 ? 'block' : 'none' }}
              >
                <Validator.PopoverFieldTextArea
                  name={messageNameHelper({
                    rowIndex: rowIndex,
                    editorIndex: carouselIndex,
                    editorType: 'ImageMapCarouselEditor',
                    entityKey: index.toString(),
                  })}
                  rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
                  value={action.text}
                  onChange={(e) => {
                    dispatch('setImageMapCarouselActiveBlockMessageData', {
                      rowIndex,
                      carouselIndex,
                      text: e.currentTarget.value,
                    });
                    validate();
                  }}
                  onBlur={(e) => {
                    dispatch('setImageMapCarouselActiveBlockMessageData', {
                      rowIndex,
                      carouselIndex,
                      text: e.currentTarget.value,
                    });
                    validate();
                  }}
                  placeholder={t('message.inputWithLengthLimit', {
                    fieldName: lowerFirst(t('glossary.message')),
                    length: 300,
                  })}
                  checkOnChange={true}
                  enableReinitialize={true}
                  data-test={`imagemap-settings-action-block-${index + 1}-message-input-${carouselIndex}`}
                />
              </S.PopOverFieldsContent>
            </Box>
          );
        } else if (isImageMapCarouselCustomUriAction(action)) {
          return (
            <Box key={'ImageMapCarouselEditor' + rowIndex + 'ActionContent' + index}>
              <S.PopOverFieldsContent
                style={{ display: index === activeBlockIndex - 1 ? 'block' : 'none' }}
              >
                <Validator.PopoverField
                  name={customNameHelper({
                    rowIndex: rowIndex,
                    editorIndex: carouselIndex,
                    editorType: 'ImageMapCarouselEditor',
                    entityKey: index.toString(),
                  })}
                  rules={[Validator.Rules.required, Validator.Rules.customParameter]}
                  value={parameters.find((p) => p.mappingKey === action.key)?.key ?? ''}
                  onChange={(e) => {
                    dispatch('setImageMapCarouselActiveBlockCustomUrlData', {
                      rowIndex,
                      carouselIndex,
                      text: e.currentTarget.value,
                    });
                    validate();
                  }}
                  placeholder={t('common.inputFieldName', {
                    fieldName: lowerFirst(t('message.apiParameterName')),
                  })}
                  checkOnChange={true}
                  enableReinitialize={true}
                  data-test={`imagemap-settings-action-block-${index + 1}-customUri-input-${carouselIndex}`}
                />
              </S.PopOverFieldsContent>
            </Box>
          );
        } else if (isImageMapCarouselUriAction(action)) {
          return (
            <Box key={'ImageMapCarouselEditor' + rowIndex + 'ActionContent' + index}>
              <UrlUTMModule
                wrapperStyle={{
                  display: index === activeBlockIndex - 1 ? 'block' : 'none',
                }}
                rowIndex={rowIndex}
                editorIndex={carouselIndex}
                entityKey={index.toString()}
                urlValue={(parameters.find((p) => p.key === action.key) as LinkParameter).data.url}
                openExternal={
                  (parameters.find((p) => p.key === action.key) as LinkParameter).data
                    .open_external_browser
                }
                onOpenExternalChange={(checked) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'open_external_browser',
                    openExternalBrowser: checked,
                  });
                }}
                onFieldsChange={(uri, utmFields) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'url',
                    text: uri,
                  });
                  Object.entries(utmFields).forEach(([key, value]) => {
                    dispatch('setImageMapCarouselActiveBlockUrlData', {
                      rowIndex,
                      carouselIndex,
                      type: key,
                      text: value,
                    });
                  });
                }}
                UTMSourceValue={
                  (parameters.find((p) => p.key === action.key) as LinkParameter).data.utm_source
                }
                onUTMSourceChange={(value) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'utm_source',
                    text: value,
                  });
                }}
                UTMMediumValue={
                  (parameters.find((p) => p.key === action.key) as LinkParameter).data.utm_medium
                }
                onUTMMediumChange={(value) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'utm_medium',
                    text: value,
                  });
                }}
                UTMCampaignValue={
                  (parameters.find((p) => p.key === action.key) as LinkParameter).data.utm_campaign
                }
                onUTMCampaignChange={(value) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'utm_campaign',
                    text: value,
                  });
                }}
                UTMContentValue={
                  (parameters.find((p) => p.key === action.key) as LinkParameter).data.utm_content
                }
                onUTMContentChange={(value) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'utm_content',
                    text: value,
                  });
                }}
                tagList={
                  (parameters.find((p) => p.key === action.key) as LinkParameter).data.tag_list
                }
                onTagChange={(tags) => {
                  dispatch('setImageMapCarouselActiveBlockUrlData', {
                    rowIndex,
                    carouselIndex,
                    type: 'tag_list',
                    tagList: tags,
                  });
                }}
                editorType="ImageMapCarouselEditor"
                data-test={`imagemap-settings-action-block-${index + 1}-uri-input-${carouselIndex}`}
              />
            </Box>
          );
        }
        return null;
      })}
      <S.PopOverFieldTitle>
        {isImageMapCarouselMessageAction(currentActionBlock) ? (
          <TriggerCodeModule
            currentChannel={currentChannel}
            triggerCode={currentActionBlock.text}
          />
        ) : null}
      </S.PopOverFieldTitle>
    </>
  ) : null;
});
