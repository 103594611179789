import { getMainPath } from 'AppRoutes';

/**
 * Note: Journey handles its own unload events
 */
const pathExclusions = [getMainPath('journey'), getMainPath('receipt')];

const editPageUrlPattern = /(create|edit|bulk|batch|import)/;

export const isCreatingOrEditingPage = (pathname: string): boolean =>
  !!pathname.match(editPageUrlPattern) &&
  pathExclusions.every((str) => pathname.indexOf(str) === -1);
