import AntAvatar from 'antd/es/avatar';
import { memo, useCallback, useState } from 'react';

import type { AvatarProps } from 'antd/es/avatar';
import type { ReactNode } from 'react';

import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

import logo from 'static/logo_inv_v2.svg';

interface GAvatarProps extends AvatarProps {
  src?: null | string;
  children?: ReactNode;
}

const StyledAntAvatar = styled(AntAvatar)`
  background: ${theme.colors.avatarFallbackBg};
  flex-shrink: 0;
`;

const failedSrc: Array<string | null | undefined> = [];

export const GAvatar = memo(function GAvatar({ src, children, ...others }: GAvatarProps) {
  const [useName, setUseName] = useState(false);

  const handleError = useCallback(() => {
    setUseName(true);
    failedSrc.push(src);
    return false;
  }, [src]);

  if (!src || useName || failedSrc.includes(src)) {
    if (typeof children === 'string' && children.length > 0) {
      return <StyledAntAvatar {...others}>{children.substring(0, 1)}</StyledAntAvatar>;
    }
    return <StyledAntAvatar {...others} src={logo} />;
  }
  return <StyledAntAvatar {...others} src={src} onError={handleError} />;
});

/**
 * This component extracted from MembersTable
 */
export const MemberAvatar = memo(function MemberAvatar({
  label,
  src,
}: {
  label: string | null;
  src: string | null;
}) {
  return (
    <GAvatar size="small" src={src}>
      {label}
    </GAvatar>
  );
});
