import { useFeatureFlag } from 'features/featureFlag';
import { useMemo } from 'react';

import type { BotInfo } from 'layout/types';

import { useAuth } from 'context/authContext';

export const useServerRegion = (): {
  isJapanServer: boolean;
  isTaiwanServer: boolean;
  serverRegion: BotInfo['server_region'];
  shortLinkPreview: string;
} => {
  const {
    state: { currentBotInfo },
  } = useAuth();
  const enabledServerRegion = useFeatureFlag('enabled-server-region');
  const serverRegion = currentBotInfo?.server_region ?? 'tw';

  const isJapanServer = useMemo(
    () => (enabledServerRegion !== null ? enabledServerRegion === 'jp' : serverRegion === 'jp'),
    [enabledServerRegion, serverRegion],
  );

  const isTaiwanServer = useMemo(
    () =>
      serverRegion === 'tw' && (enabledServerRegion === 'global' || enabledServerRegion === null),
    [serverRegion, enabledServerRegion],
  );

  const shortLinkPreview = useMemo(
    () => (isJapanServer ? 'https://jp.maac.io/preview' : 'https://maac.io/preview'),
    [isJapanServer],
  );

  return {
    isJapanServer,
    isTaiwanServer,
    serverRegion,
    shortLinkPreview,
  };
};
