import { getParents } from 'utils/dom/getParents';

export class PreventScrollBack {
  isScrolling = false;
  private timerId: null | number = null;
  targetElement: HTMLElement | null = null;

  constructor(element: HTMLElement) {
    this.targetElement = element;
  }

  /**
   * Set the flag to true if any element is been scrolling, and reset to false after then.
   * Reference: https://gomakethings.com/detecting-scroll-distances-with-vanilla-js/
   *
   */
  private scrollHandler() {
    this.isScrolling = true;

    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.timerId = window.setTimeout(() => {
      this.isScrolling = false;
    }, 66);
  }

  /**
   * In this function, some calculations are made for prevent two finger scroll-back gesture.
   *
   * @param event the mouse wheel event
   */
  private preventScrollBack(event: WheelEvent) {
    const target = event.target as Element;
    const deltaX = event.deltaX;
    const deltaY = event.deltaY;

    // Run calculation only with target and scrolling horizontally
    if (target && Math.abs(deltaX) >= Math.abs(deltaY) - 1) {
      const parents = getParents(target);

      // Original resource from: https://github.com/micho/jQuery.preventMacBackScroll/blob/master/jquery.preventMacBackScroll.js
      const preventLeft = deltaX <= 0 && parents.filter((el) => el.scrollLeft > 0).length === 0;
      const preventUp = deltaY > 0 && parents.filter((el) => el.scrollTop > 0).length !== 0;

      // 'isScrolling' is required, or user may scroll back to previous page with two fingers gesture on a scrolling element
      if (preventLeft || preventUp || this.isScrolling) {
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  register(): void {
    this.targetElement?.addEventListener('scroll', this.scrollHandler, {
      passive: false,
      capture: true,
    });

    /**
     * `{ passive: false }` is for Chrome
     * Reference: https://github.com/inuyaksa/jquery.nicescroll/issues/799#issuecomment-482200470
     */
    this.targetElement?.addEventListener('wheel', this.preventScrollBack, { passive: false });
  }

  unregister(): void {
    this.targetElement?.removeEventListener('scroll', this.scrollHandler, { capture: true });

    /**
     * `{ passive: false }` is for Chrome
     * Reference: https://github.com/inuyaksa/jquery.nicescroll/issues/799#issuecomment-482200470
     */
    this.targetElement?.removeEventListener('wheel', this.preventScrollBack);
  }
}
