import { z } from 'zod';

import { GA_EVENT_NAME_LIMIT, GA_EVENT_PREFIX } from 'shared/lib/ga/constants';

import type { GaEventRecords } from 'shared/lib/ga/types';

const GaEventNameSchema = z.string().min(1).max(GA_EVENT_NAME_LIMIT);

export const validateGaEventRecords = (gaEventRecords: GaEventRecords) => {
  Object.values(gaEventRecords).forEach(({ name }) =>
    GaEventNameSchema.parse(`${GA_EVENT_PREFIX}_${name}`, {
      path: [`${GA_EVENT_PREFIX}_${name}`], // Report the full event name in the error message
    }),
  );
};

/**
 * A helper function to enforce type safety on event records
 */
export const createGaEventRecord = <T extends GaEventRecords>(gaEventRecord: T): T => {
  try {
    validateGaEventRecords(gaEventRecord);
  } catch (error) {
    if (error instanceof z.ZodError) {
      // eslint-disable-next-line no-console -- Useful for reporting long event names during development
      console.error(error.issues.map(({ message, path }) => `${message}: ${path[0]}`).join('\n'));
    }
  }

  return gaEventRecord;
};
