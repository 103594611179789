import type { StandardParameter } from 'shared/components/Editor/LineMessageEditor/types';

export const MAX_TEXT_MODULE_TEXT_LENGTH = 1600;

export const MAX_SIZE = 1024 * 1024;
export const MAX_IMAGE_DIMENSIONS_VALUE = 4096;
export const MAX_IMAGE_SIZE = '4096px x 4096px';
export const APNG_FILE_LIMIT = MAX_SIZE * 0.3;
export const MAX_APNG_FILE_COUNT = 10;
export const MAX_BUTTON_LABEL_LENGTH = 40;
export const PARAM_PLACEHOLDER = {
  image:
    'https://firebasestorage.googleapis.com/v0/b/cdn.maac.app/o/maac%2Fopen_api%2Fimage-placeholder-new.png?alt=media&token=dc77c7c8-13a3-4226-82d3-2b41f72f20ef',
  link: 'https://example.com',
  text: 'test param text',
};

export const UPLOAD_FILE_FUNCTION_NAME = 'lineMessageEditor';

export const TEXT_MODULE_TAG_TYPE = {
  url: 'url',
  name: 'name',
  custom: 'custom',
  referralInviter: 'referral_inviter',
  referralInvitee: 'referral_invitee',
  referralActivityLink: 'referral_activity_link',
  referralInvitationLink: 'referral_invitation_link',
} as const;

export const PARAMETER_DATA_FUNCTION = {
  link: 'link',
  memberName: 'member_name',
  sharelink: 'sharelink',
  prizeName: 'prize_name',
  prizeLiffPage: 'prize_liff_page',
  bindLink: 'bind_link',
  tag: 'tag',
  referralInviter: 'referral_inviter',
  referralInvitee: 'referral_invitee',
  referralActivity_link: 'referral_activity_link',
  referralInvitation_link: 'referral_invitation_link',
} as const;

export const PARAMETER_TYPE = {
  standard: 'standard',
  object: 'object',
  custom_string: 'custom_string',
  custom_object: 'custom_object',
} as const;

export const DEFAULT_MAPPING_KEY = {
  customImage: 'customImage',
  customHero: 'customHero',
  customText: 'customText',
  customUri: 'customUri',
  standardLink: 'standardLink',
  messageButton: 'messageButton',
  shareButton: 'shareButton',
} as const;

export const MESSAGE_MODULE_TYPE = {
  sms: 0,
  text: 1,
  image: 2,
  imagemap: 3,
  card: 4,
  carousel: 5,
  video: 8,
  imageCarousel: 9,
  news: 12,
  prize: 13,
  flex: 100001,
  imagemapFlex: 14,
  imagemapCarousel: 15,
} as const;

export const defaultUriParameter: StandardParameter = {
  key: '',
  type: 'standard',
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    isUtmTracked: false,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [],
  },
};
