import axios from 'axios';

import { API_HOST } from 'env';

import { isURLSameOrigin } from 'lib/helpers/isURLSameOrigin';

import { GREEN, RED } from '../enum';
import { logger } from '../utils/loggerHelper';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const anonymousAxios = axios.create();

anonymousAxios.interceptors.request.use((config) => {
  if (typeof config.url === 'string' && isURLSameOrigin(config.url)) {
    config.baseURL = API_HOST;
  }
  return config;
});

anonymousAxios.interceptors.response.use(
  (response) => {
    logger(response, GREEN);
    return response;
  },
  (error) => {
    logger(error.response, RED);

    return Promise.reject(error);
  },
);

export const AnonymousAxios = anonymousAxios;
