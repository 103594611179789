import { customerSupport } from 'features/customerSupport/customerSupport';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomerSupportService } from 'features/customerSupport/customerSupport';

import { useAuth } from 'context/authContext';

/**
 * Customer support logic
 */
export const useCustomerSupport = (): Pick<
  CustomerSupportService,
  'open' | 'close' | 'sendMessage'
> => {
  const { i18n } = useTranslation();

  const {
    state: { currentChannel, user },
  } = useAuth();

  const shouldHideCustomerSupport = useMemo(
    () => currentChannel?.language_code === 'ja' || i18n.language.toLowerCase() === 'ja',
    [i18n.language, currentChannel?.language_code],
  );

  useEffect(() => {
    if (shouldHideCustomerSupport) {
      customerSupport.hide();
      return;
    }

    if (!user?.zendesk_token) {
      customerSupport.unsetUserDetail();
      return;
    }

    if (customerSupport.initialized) {
      customerSupport.setUserDetail(user?.zendesk_token);
      customerSupport.show();
    } else {
      customerSupport.initialize('cee66b99-957e-4ec3-b790-ccf027b31f1f').then(() => {
        customerSupport.setUserDetail(user?.zendesk_token);
      });
    }
  }, [shouldHideCustomerSupport, user?.zendesk_token]);

  useEffect(
    function updateCustomerSupportWidgetLang() {
      customerSupport.setLocale(i18n.language.toLowerCase());
    },
    [i18n.language],
  );

  return {
    open: customerSupport.open,
    close: customerSupport.close,
    sendMessage: customerSupport.sendMessage,
  };
};
