import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ImageCarouselContentHeroAction,
  ImageCarouselModule,
} from 'shared/components/Editor/LineMessageEditor/types';

import { ExternalLink } from 'components/ExternalLink';
import { CarouselScrollPreviewContainer } from 'shared/components/Editor/LineMessageEditor/components/Styled';
import { generateDataTestId } from 'shared/components/Editor/LineMessageEditor/utils/generateDataTestId';
import {
  isCustomImageParameter,
  isCustomLinkParameter,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography';

interface ImageCarouselPreviewTooltipTitleProps {
  actionType: 'uri' | 'message' | 'customUri';
  actionText: string;
  imageParamName: string;
}

const ImageCarouselPreviewTooltipTitle = memo(function ImageCarouselPreviewTooltipTitle({
  actionType,
  actionText,
  imageParamName,
}: ImageCarouselPreviewTooltipTitleProps) {
  const { t } = useTranslation();

  const getActionTextByType = () => {
    if (actionType === 'uri') {
      return t('message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea.uri', {
        actionText,
      });
    }
    if (actionType === 'message') {
      return t('message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea.message', {
        actionText,
      });
    }
    if (actionType === 'customUri') {
      return t('message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea.customUri', {
        actionText,
      });
    }
    return t('message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea');
  };

  return (
    <>
      {actionText ? (
        <>
          <Typography variant="body" fontWeight={500}>
            {t('message.paramImageEditor.imageCarrouselPreview.tooltip.actionArea')}
          </Typography>
          {actionType === 'uri' ? (
            <ExternalLink href={actionText} style={{ width: '100%', wordBreak: 'break-all' }}>
              {actionText}
            </ExternalLink>
          ) : (
            <Typography variant="body" fontWeight={400}>
              {getActionTextByType()}
            </Typography>
          )}
        </>
      ) : null}
      {imageParamName ? (
        <>
          <Typography variant="body" fontWeight={500}>
            {t('message.paramImageEditor.imageProvidedBy')}
          </Typography>
          <Typography variant="body" fontWeight={400}>
            {t(
              'message.paramImageEditor.imageCarrouselPreview.tooltip.imageProvidedBy.apiParameter',
              {
                paramName: imageParamName,
              },
            )}
          </Typography>
        </>
      ) : null}
    </>
  );
});

export const LineMessageEditorImageCarouselModulePreview = ({
  message,
}: {
  message: ImageCarouselModule;
}) => {
  const getActionText = (action: ImageCarouselContentHeroAction) => {
    if (action.type === 'uri') {
      const customLinkParameter = message.parameters.find(
        (parameter) => isCustomLinkParameter(parameter) && parameter.key === action.uri,
      );
      return customLinkParameter?.data.function === 'link' ? customLinkParameter.data.url : '';
    }
    if (action.type === 'message') {
      return action.text;
    }
    if (action.type === 'customUri') {
      return action.uri;
    }
    return '';
  };

  const getImageParamName = (index: number) =>
    message.parameters.find(
      (parameter) =>
        isCustomImageParameter(parameter) &&
        parameter.mappingKey === message.data.contents[index].hero.contents[0].key,
    )?.key ?? '';

  return (
    <CarouselScrollPreviewContainer>
      {message.data.contents.map((content, index) => {
        const actionText = getActionText(content.hero.action);
        return (
          <div
            key={index}
            style={{
              marginRight: '20px',
              borderRadius: '16px',
              backgroundColor: 'white',
              overflow: 'hidden',
            }}
          >
            <Tooltip
              trigger="hover"
              title={
                actionText ? (
                  <TooltipDescription>
                    <ImageCarouselPreviewTooltipTitle
                      actionType={content.hero.action.type}
                      actionText={actionText}
                      imageParamName={getImageParamName(index)}
                    />
                  </TooltipDescription>
                ) : (
                  ''
                )
              }
              overlayStyle={{ maxWidth: '280px' }}
            >
              {content.hero.contents[0].url ? (
                <img
                  style={{
                    width: '100%',
                    borderRadius: '16px',
                    cursor: 'pointer',
                    display: 'block',
                  }}
                  src={content.hero.contents[0].url}
                  alt=""
                  onClick={() => {
                    if (content.hero.action.type === 'uri' && actionText) {
                      window.open(actionText);
                    }
                  }}
                  data-test={generateDataTestId('image-carousel-preview-local-upload-image', index)}
                />
              ) : null}
            </Tooltip>
          </div>
        );
      })}
    </CarouselScrollPreviewContainer>
  );
};
