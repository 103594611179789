import { z } from 'zod';

import { ObjectEmptySchema } from 'shared/models/common/object';
import { JourneyBranchConditionSplitSchema } from 'shared/models/journey/nodeBranchSettings';
import {
  JourneyNodeActionTypeEnum,
  JourneyNodeCategoryEnum,
  JourneyNodeRuleTypeEnum,
  JourneyNodeTriggerTypeEnum,
} from 'shared/models/journey/nodeCommon';
import { JourneyNodeFilterDataSchema } from 'shared/models/journey/nodeFilter';
import {
  JourneyNodeTypeGaAddToCartSettingsSchema,
  JourneyNodeTypeGaPageViewSettingsSchema,
  JourneyNodeTypeGaPurchaseSettingsSchema,
  JourneyNodeTypeLineMessageOpenedSettingsSchema,
  JourneyNodeTypeSendLineMessageSettingsSchema,
  JourneyNodeTypeTagAddedSettingsSchema,
  JourneyNodeTypeTimeDelaySettingsSchema,
  JourneyNodeTypeTimeScheduleSettingsSchema,
} from 'shared/models/journey/nodeSettings';

/**
 * Triggers
 */
export const JourneyNodeTypeEmptySchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Trigger),
  type: z.literal(JourneyNodeTriggerTypeEnum.Empty),
  settings: ObjectEmptySchema,
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeTagAddedSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Trigger),
  type: z.literal(JourneyNodeTriggerTypeEnum.TagAdded),
  settings: JourneyNodeTypeTagAddedSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterDataSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeGaPageViewSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Trigger),
  type: z.literal(JourneyNodeTriggerTypeEnum.GaPageView),
  settings: JourneyNodeTypeGaPageViewSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterDataSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeGaPurchaseSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Trigger),
  type: z.literal(JourneyNodeTriggerTypeEnum.GaPurchase),
  settings: JourneyNodeTypeGaPurchaseSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterDataSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeGaAddToCartSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Trigger),
  type: z.literal(JourneyNodeTriggerTypeEnum.GaAddToCart),
  settings: JourneyNodeTypeGaAddToCartSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterDataSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeLineMessageOpenedSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Trigger),
  type: z.literal(JourneyNodeTriggerTypeEnum.LineMessageOpened),
  settings: JourneyNodeTypeLineMessageOpenedSettingsSchema.or(ObjectEmptySchema),
  filters: JourneyNodeFilterDataSchema.or(ObjectEmptySchema),
  branch_settings: ObjectEmptySchema,
});

/**
 * Rules
 */
export const JourneyNodeTypeExitSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Rule),
  type: z.literal(JourneyNodeRuleTypeEnum.Exit),
  settings: ObjectEmptySchema,
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeTimeDelaySchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Rule),
  type: z.literal(JourneyNodeRuleTypeEnum.TimeDelay),
  settings: JourneyNodeTypeTimeDelaySettingsSchema.or(ObjectEmptySchema),
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeTimeScheduleSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Rule),
  type: z.literal(JourneyNodeRuleTypeEnum.TimeSchedule),
  settings: JourneyNodeTypeTimeScheduleSettingsSchema.or(ObjectEmptySchema),
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeYesNoBranchSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Rule),
  type: z.literal(JourneyNodeRuleTypeEnum.YesNoBranch),
  settings: ObjectEmptySchema,
  filters: ObjectEmptySchema,
  branch_settings: JourneyBranchConditionSplitSchema.or(ObjectEmptySchema).or(z.null()),
});

/**
 * Actions
 */
export const JourneyNodeTypeSendLineMessageSchema = z.object({
  name: z.string().nullable(),
  category: z.literal(JourneyNodeCategoryEnum.Action),
  type: z.literal(JourneyNodeActionTypeEnum.SendLineMessage),
  settings: JourneyNodeTypeSendLineMessageSettingsSchema.or(ObjectEmptySchema),
  filters: ObjectEmptySchema,
  branch_settings: ObjectEmptySchema,
});

export const JourneyNodeTypeSchemaSet = z.discriminatedUnion('type', [
  JourneyNodeTypeEmptySchema,
  JourneyNodeTypeTagAddedSchema,
  JourneyNodeTypeGaPageViewSchema,
  JourneyNodeTypeGaPurchaseSchema,
  JourneyNodeTypeGaAddToCartSchema,
  JourneyNodeTypeLineMessageOpenedSchema,
  JourneyNodeTypeExitSchema,
  JourneyNodeTypeTimeDelaySchema,
  JourneyNodeTypeTimeScheduleSchema,
  JourneyNodeTypeYesNoBranchSchema,
  JourneyNodeTypeSendLineMessageSchema,
]);
