import { IMAGE_MAP_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type { ImageMapCustomPreviewBlock } from './templateDataAndTypes/imageMap';
import type {
  ImageMapActionMessageType,
  ImageMapActionUrlType,
  ImageMapCustomParameterType,
  ImageMapDataType,
  ImageMapTriggerActionType,
  ImageMapUrlParameterType,
  State,
} from './templateDataAndTypes/types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { composeKey, generateMappingKey } from 'components/LineMessageEditor/utils/transformKey';

import { createImageMapActions } from '../utils/imageMapSize';

import {
  imageMapActionMessage,
  imageMapActionUrl,
  imageMapCustomParameter,
  imageMapData,
  imageMapTriggerAction,
  imageMapUrlParameter,
} from './templateDataAndTypes/imageMap';
import { ImageMapActions, isImageMapDataRule } from './templateDataAndTypes/types';
import { dispatch } from './models';

export const imageMapStore = {
  reducers: {
    setImageMapUrl(preState: State, payload: { rowIndex: number; url: string }): void {
      const message = preState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapDataRule(message)) return;

      message.data.base_url = payload.url;
    },
    setImageMapModuleSwitch(preState: State, payload: { rowIndex: number; isFlex: boolean }): void {
      (preState.editorData[payload.rowIndex] as ImageMapDataType).module_id = payload.isFlex
        ? LineFieldType.ImageMapFlex
        : LineFieldType.ImageMap;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).format.isFlex = payload.isFlex;
    },
    setImageMapHeight(preState: State, payload: { rowIndex: number; height: number }): void {
      const message = preState.editorData[payload.rowIndex];

      if (!message) return;
      if (!isImageMapDataRule(message)) return;

      const originHeight = message.data.base_size.height;
      if (!message.isCustom) {
        const blockWidth = message.data.base_size.width;
        const heightWidthRate = payload.height / blockWidth;
        const actionsCount = message.data.actions.length;

        message.data.actions = message.data.actions.map((d, index) => {
          if (actionsCount === 1)
            return { ...d, area: { ...d.area, height: blockWidth * heightWidthRate } };
          if (actionsCount === 4)
            return {
              ...d,
              area: {
                ...d.area,
                height: (blockWidth * heightWidthRate) / 2,
                y: index < 2 ? 0 : (blockWidth * heightWidthRate) / 2,
              },
            };
          if (actionsCount === 6)
            return {
              ...d,
              area: {
                ...d.area,
                height: (blockWidth * heightWidthRate) / 2,
                y: index < 3 ? 0 : (blockWidth * heightWidthRate) / 2,
              },
            };
          else return d;
        });
      } else {
        const heightRate = payload.height / originHeight;
        message.data.actions = message.data.actions.map((d) => {
          return {
            ...d,
            area: { ...d.area, y: d.area.y * heightRate, height: d.area.height * heightRate },
          };
        });
      }
      message.data.base_size.height = payload.height;
      message.data.base_url = '';
    },
    setImageMapImageAnimated(
      preState: State,
      payload: { rowIndex: number; isAnimated: boolean },
    ): void {
      (preState.editorData[payload.rowIndex] as ImageMapDataType).data.animated =
        payload.isAnimated;
    },
    setImageMapActions(preState: State, payload: { rowIndex: number; count: number }): void {
      const { result, triggers, tagId } = createImageMapActions(
        (preState.editorData[payload.rowIndex] as ImageMapDataType).data.base_size,
        payload.count,
        (preState.editorData[payload.rowIndex] as ImageMapDataType).format.tagId,
      );
      (preState.editorData[payload.rowIndex] as ImageMapDataType).format.tagId = tagId;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions = result;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).actions = triggers;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex = 1;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).isCustom = false;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters = [];
    },
    setImageMapCustom(preState: State, payload: { rowIndex: number; isCustom: boolean }): void {
      (preState.editorData[payload.rowIndex] as ImageMapDataType).isCustom = payload.isCustom;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions = [];
      (preState.editorData[payload.rowIndex] as ImageMapDataType).actions = [];
      (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters = [];
      (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex = 1;
    },
    setImageMapCustomBlocks(
      preState: State,
      payload: { rowIndex: number; previewBlocks: ImageMapCustomPreviewBlock[] },
    ): void {
      const resultArray: ImageMapActionMessageType[] = [];
      const triggerArray: ImageMapTriggerActionType[] = [];
      const realHeight = (preState.editorData[payload.rowIndex] as ImageMapDataType).data.base_size
        .height;
      const realWidth = (preState.editorData[payload.rowIndex] as ImageMapDataType).data.base_size
        .width;
      const heightWidthRate = realHeight / realWidth;

      payload.previewBlocks.forEach((block) => {
        const newObject = JSON.parse(
          JSON.stringify(imageMapActionMessage),
        ) as ImageMapActionMessageType;
        newObject.area.x = (block.left / IMAGE_MAP_UI_IMAGE_WIDTH) * realWidth;
        newObject.area.y = (block.top / (IMAGE_MAP_UI_IMAGE_WIDTH * heightWidthRate)) * realHeight;
        newObject.area.width = (block.width / IMAGE_MAP_UI_IMAGE_WIDTH) * realWidth;
        newObject.area.height =
          (block.height / (IMAGE_MAP_UI_IMAGE_WIDTH * heightWidthRate)) * realHeight;
        newObject.key =
          'message' + (preState.editorData[payload.rowIndex] as ImageMapDataType).format.tagId;
        const triggerObject = JSON.parse(
          JSON.stringify(imageMapTriggerAction),
        ) as ImageMapTriggerActionType;

        triggerObject.key =
          'message' + (preState.editorData[payload.rowIndex] as ImageMapDataType).format.tagId;
        (preState.editorData[payload.rowIndex] as ImageMapDataType).format.tagId++;
        triggerObject.data.tag_list = [];
        triggerArray.push(triggerObject);
        resultArray.push(newObject);
      });
      (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions = resultArray;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).actions = triggerArray;
      (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex = 1;
    },
    setImageMapActionType(
      preState: State,
      payload: {
        type: ImageMapActions;
        previousType: ImageMapActions;
        rowIndex: number;
      },
    ): void {
      const finalAction =
        payload.type === ImageMapActions.message
          ? (JSON.parse(JSON.stringify(imageMapActionMessage)) as ImageMapActionMessageType)
          : (JSON.parse(JSON.stringify(imageMapActionUrl)) as ImageMapActionUrlType);

      const activeBlockIndex = (preState.editorData[payload.rowIndex] as ImageMapDataType)
        .activeBlockIndex;

      const oldArea = (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
        activeBlockIndex - 1
      ].area;

      let key = '';
      let oldKey = '';

      if (payload.type === ImageMapActions.message) {
        oldKey = (
          (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
            activeBlockIndex - 1
          ] as ImageMapActionUrlType
        ).linkUri;

        key = oldKey.replace('<$var:', '').replace('>', '');
        if (payload.previousType === ImageMapActions.uri) key = key.replace('url', '');
        if (payload.previousType === ImageMapActions.customUri) key = key.replace('custom', '');
        (finalAction as ImageMapActionMessageType).key = 'message' + key;
        (finalAction as ImageMapActionMessageType).area = oldArea;
      } else if (
        payload.type === ImageMapActions.uri ||
        payload.type === ImageMapActions.customUri
      ) {
        oldKey = (
          (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
            activeBlockIndex - 1
          ] as ImageMapActionMessageType
        ).key;

        if (oldKey === undefined)
          oldKey = oldKey = (
            (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
              activeBlockIndex - 1
            ] as ImageMapActionUrlType
          ).linkUri;

        if (payload.previousType === ImageMapActions.message) {
          key = oldKey.replace('message', '');
        } else if (payload.previousType === ImageMapActions.uri) {
          key = oldKey.replace('<$var:url', '').replace('>', '');
        } else if (payload.previousType === ImageMapActions.customUri) {
          key = oldKey.replace('<$var:custom', '').replace('>', '');
        }

        if (payload.type === ImageMapActions.uri) {
          const parameterObject = JSON.parse(
            JSON.stringify(imageMapUrlParameter),
          ) as ImageMapUrlParameterType;

          parameterObject.key = 'url' + key;
          (finalAction as ImageMapActionUrlType).linkUri = '<$var:url' + key + '>';
          (finalAction as ImageMapActionUrlType).area = oldArea;

          (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters = [
            ...(preState.editorData[payload.rowIndex] as ImageMapDataType).parameters,
            parameterObject,
          ];
        } else if (payload.type === ImageMapActions.customUri) {
          const parameterObject = JSON.parse(
            JSON.stringify(imageMapCustomParameter),
          ) as ImageMapCustomParameterType;

          const mappingKey = generateMappingKey('customUri');
          const actionKey = composeKey(mappingKey);

          parameterObject.mappingKey = mappingKey;
          (finalAction as ImageMapActionUrlType).linkUri = actionKey;
          (finalAction as ImageMapActionUrlType).area = oldArea;

          (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters = [
            ...(preState.editorData[payload.rowIndex] as ImageMapDataType).parameters,
            parameterObject,
          ];
        }
      }

      (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
        activeBlockIndex - 1
      ] = finalAction;

      // Remove the superfluous parameter(s)
      if (payload.previousType === ImageMapActions.uri) {
        (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters = (
          preState.editorData[payload.rowIndex] as ImageMapDataType
        ).parameters.filter((d) => d.key !== oldKey.replace('<$var:', '').replace('>', ''));
      } else {
        (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters = (
          preState.editorData[payload.rowIndex] as ImageMapDataType
        ).parameters.filter((d) => d.mappingKey !== oldKey.replace('<$var:', '').replace('>', ''));
      }
    },
    setImageMapActiveBlock(preState: State, payload: { rowIndex: number; index: number }): void {
      (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex = payload.index;
    },
    setImageMapActiveBlockMessageData(
      preState: State,
      payload: {
        rowIndex: number;
        text: string;
        type: 'trigger_code' | 'tag_list';
        tagList?: string[];
      },
    ): void {
      const activeBlockIndex =
        (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex - 1;
      (
        (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
          activeBlockIndex
        ] as ImageMapActionMessageType
      ).text = payload.text;
    },
    setImageMapActiveBlockUrlData(
      preState: State,
      payload: {
        rowIndex: number;
        text: string;
        type:
          | 'url'
          | 'open_external_browser'
          | 'utm_campaign'
          | 'utm_medium'
          | 'utm_source'
          | 'utm_content'
          | 'tag_list';
        tagList?: string[];
        index?: number;
      },
    ): void {
      const activeBlockIndex =
        payload.index !== undefined
          ? payload.index
          : (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex - 1;
      const key = (
        (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
          activeBlockIndex
        ] as ImageMapActionUrlType
      ).linkUri
        .replace('<$var:', '')
        .replace('>', '');

      (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters.find(
        (d) => d.key === key,
      )!.data[payload.type] = payload.type !== 'tag_list' ? payload.text : payload.tagList ?? [];
    },
    setImageMapActiveBlockCustomUrlData(
      preState: State,
      payload: {
        rowIndex: number;
        text: string;
      },
    ): void {
      const activeBlockIndex =
        (preState.editorData[payload.rowIndex] as ImageMapDataType).activeBlockIndex - 1;
      const key = (
        (preState.editorData[payload.rowIndex] as ImageMapDataType).data.actions[
          activeBlockIndex
        ] as ImageMapActionUrlType
      ).linkUri
        .replace('<$var:', '')
        .replace('>', '');

      (preState.editorData[payload.rowIndex] as ImageMapDataType).parameters.find(
        (d) => d.mappingKey === key,
      )!.key = payload.text;
    },
    setImageMapNotificationText(
      preState: State,
      payload: { rowIndex: number; text: string },
    ): void {
      (preState.editorData[payload.rowIndex] as ImageMapDataType).data.notification_text =
        payload.text;
    },
  },
  effects: {
    async addImageMapModule(): Promise<void> {
      dispatch('addEditorData', {
        data: {
          ...imageMapData,
          data: {
            ...imageMapData.data,
            base_url: '',
            notification_text: '',
            actions: [imageMapActionMessage],
          },
          parameters: [],
          actions: [
            {
              ...imageMapTriggerAction,
              data: { ...imageMapTriggerAction.data, tag_list: [] },
            },
          ],
        },
      });
    },
    async addImageMapFlexModule(): Promise<void> {
      dispatch('addEditorData', {
        data: {
          ...imageMapData,
          module_id: LineFieldType.ImageMapFlex,
          data: {
            ...imageMapData.data,
            base_url: '',
            notification_text: '',
            actions: [imageMapActionMessage],
          },
          parameters: [],
          actions: [
            {
              ...imageMapTriggerAction,
              data: { ...imageMapTriggerAction.data, tag_list: [] },
            },
          ],
        },
      });
    },
  },
};
