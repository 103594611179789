export const HomeSvg = () => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0_499:3475)">
        <path
          d="M15.76 7.874L8.86.978 8.397.515a.562.562 0 00-.792 0L.242 7.874a1.141 1.141 0 00-.336.821c.007.629.53 1.13 1.16 1.13h.758v5.817h12.354V9.826h.775a1.138 1.138 0 001.057-.707 1.136 1.136 0 00-.25-1.245zM9 14.356h-2v-3.643h2v3.643zm3.891-5.816v5.816h-2.748v-4.071a.714.714 0 00-.714-.715H6.572a.714.714 0 00-.714.715v4.071H3.11V8.54H1.396l6.607-6.602.412.413 6.193 6.189h-1.716z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_499:3475">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
