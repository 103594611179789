import type { CSSProperties, ReactNode } from 'react';

import { MotifIcon } from 'icons/motif';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const Message = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${theme.colors.neutral008};
`;

const Wrapper = styled.div`
  padding-top: 90px;
  min-height: 247px;
  text-align: center;
`;

interface TableEmptyProps {
  style?: CSSProperties;
  message?: ReactNode;
}

/**
 * @deprecated Use the `shared/components/Table/TableEmpty` component instead
 */
export const TableEmpty = ({ message, style }: TableEmptyProps) => {
  return (
    <Wrapper style={style}>
      <MotifIcon un-i-motif="menu_list" />
      <Message>{message}</Message>
    </Wrapper>
  );
};
