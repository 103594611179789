import { EditorState, Modifier } from 'draft-js';

type OnUnicodeEmojiClick = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState, isFocus: boolean) => void,
  emoji: string,
) => void;

export const onUnicodeEmojiClick: OnUnicodeEmojiClick = (editorState, setEditorState, emoji) => {
  let contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const contentStateWithEntity = contentState.createEntity('MY_ENTITY_TYPE', 'IMMUTABLE');
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  contentState = Modifier.insertText(contentState, selectionState, ' ');

  contentState = Modifier.insertText(
    contentState,
    selectionState,
    ' ' + emoji,
    undefined,
    entityKey,
  );

  let newState = EditorState.push(editorState, contentState, 'insert-characters');

  if (!newState.getCurrentContent().equals(editorState.getCurrentContent())) {
    const sel = newState.getSelection();
    newState = EditorState.forceSelection(newState, sel);
  }

  setEditorState(newState, true);
};
