import { toNumber } from '@chatbotgang/etude/pitch-shifter/number';
import * as R from 'remeda';

import type { CdpDataSource, CdpTagUniqueId } from 'shared/models/cdp';

import { CdpDataSourceEnum } from 'shared/models/cdp';

export const isCdpTagUniqueId = (value: unknown): value is CdpTagUniqueId => {
  if (typeof value !== 'string') return false;

  const [source, id] = R.split(value, '-');

  const transformedId = toNumber(id);

  if (isNaN(transformedId) || transformedId <= 0) return false;

  return R.isIncludedIn(source, R.values(CdpDataSourceEnum));
};

// A simple utility for generating a unique ID for a CDP tag, mainly used for consistency across the app
export const getCdpTagUniqueId = ({
  id,
  source,
}: {
  id: number;
  source: CdpDataSource;
}): CdpTagUniqueId => `${source}-${id}`;

// Same as above except in reverse
export const getCdpTagIdSource = ({
  uniqueId,
}: {
  uniqueId: `${CdpDataSource}-${number}`;
}): {
  id: number;
  source: CdpDataSource;
} => {
  const [source, id] = R.split(uniqueId, '-');

  return {
    id: Number(id),
    source,
  };
};
