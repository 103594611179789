import { logError } from 'lib/logger';

export const setLocale = (locale: string): boolean => {
  try {
    window.zE?.('messenger:set', 'locale', locale);
    return true;
  } catch (error) {
    logError(error);
    return false;
  }
};
