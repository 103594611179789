import { z } from 'zod';

import {
  JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MAX,
  JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MIN,
} from 'modules/Journey/constants';

import { CdpDataSourceSchema } from 'shared/models/cdp';
import { JourneyNodeOpSchema } from 'shared/models/journey/nodeCommon';

export const JourneyNodeMatchEnum = {
  All: 'all',
  Any: 'any',
} as const;

export const JourneyNodeMatchSchema = z.nativeEnum(JourneyNodeMatchEnum);

export type JourneyNodeMatchType = z.infer<typeof JourneyNodeMatchSchema>;

export const JourneyNodeConditionTypeEnum = {
  Tag: 'tag',
  GaPageView: 'ga_page_view',
  GaPurchase: 'ga_purchase',
  GaAddToCart: 'ga_add_to_cart',
} as const;

const JourneyNodeConditionTypeSchema = z.nativeEnum(JourneyNodeConditionTypeEnum);

export type JourneyNodeConditionType = z.output<typeof JourneyNodeConditionTypeSchema>;

export const JourneyNodeConditionPageDurationSchema = z.number().nullable();
export const JourneyNodeConditionPageViewsSchema = z.number().nullable();
export const JourneyNodeConditionDaysWithinSchema = z
  .number()
  .gte(JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MIN)
  .lte(JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MAX)
  .nullable();

export type JourneyNodeConditionPageDuration = z.output<
  typeof JourneyNodeConditionPageDurationSchema
>;
export type JourneyNodeConditionPageViews = z.output<typeof JourneyNodeConditionPageViewsSchema>;
export type JourneyNodeConditionDaysWithin = z.output<typeof JourneyNodeConditionDaysWithinSchema>;

/**
 * Note: filter conditions are complex and we're running up against the limitations of Zod here
 * Limitation 1: we can't nest discriminated unions e.g. discriminate by `condition` AND `op`
 * In the future we'd like to be abe to additionally discriminate by `op` to better define `value`
 * Limitation 2: discriminated unions are difficult to transform
 * For this reason additional processing takes place when the schema is used by the editor UI
 */
const JourneyNodeConditionTagDataSchema = z.object({
  condition: z.literal(JourneyNodeConditionTypeEnum.Tag),
  op: JourneyNodeOpSchema,
  value: z.number().nullable(),
  extra: z
    .object({
      source: CdpDataSourceSchema,
    })
    .or(z.null()),
});

const JourneyNodeConditionGaPageViewDataSchema = z.object({
  condition: z.literal(JourneyNodeConditionTypeEnum.GaPageView),
  op: JourneyNodeOpSchema,
  value: z.string().nullable(),
  extra: z.object({
    page_duration: JourneyNodeConditionPageDurationSchema,
    page_views: JourneyNodeConditionPageViewsSchema,
    days_within: JourneyNodeConditionDaysWithinSchema,
  }),
});

const JourneyNodeConditionGaPurchaseDataSchema = z.object({
  condition: z.literal(JourneyNodeConditionTypeEnum.GaPurchase),
  op: JourneyNodeOpSchema,
  value: z.string().nullable(),
  extra: z.object({
    days_within: JourneyNodeConditionDaysWithinSchema,
  }),
});

const JourneyNodeConditionGaAddToCartDataSchema = z.object({
  condition: z.literal(JourneyNodeConditionTypeEnum.GaAddToCart),
  op: JourneyNodeOpSchema,
  value: z.string().nullable(),
  extra: z.object({
    days_within: JourneyNodeConditionDaysWithinSchema,
  }),
});

const JourneyNodeConditionDataSchema = z.discriminatedUnion('condition', [
  JourneyNodeConditionTagDataSchema,
  JourneyNodeConditionGaPageViewDataSchema,
  JourneyNodeConditionGaPurchaseDataSchema,
  JourneyNodeConditionGaAddToCartDataSchema,
]);

export const JourneyNodeFilterDataSchema = z.object({
  match: JourneyNodeMatchSchema,
  conditions: JourneyNodeConditionDataSchema.array(),
});

export type JourneyNodeFilterResponse = z.output<typeof JourneyNodeFilterDataSchema>;

/**
 * Note that this is the `input` of the schema e.g. what the API returns (and also accepts)
 */
export type JourneyNodeFilterRequestArgs = z.input<typeof JourneyNodeFilterDataSchema>;
