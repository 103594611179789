import Trigger from 'rc-trigger';
import { Component } from 'react';

import type { Placement } from './placements';
import type { TriggerProps } from 'rc-trigger';

import { placements } from './placements';
import { PopupContent } from './PopupContent';

import 'rc-trigger/assets/index.css';

export interface PopupProps extends TriggerProps {
  // our own placement preset
  placement?: Placement;
}

export class Popup extends Component<PopupProps> {
  static defaultProps: Partial<TriggerProps> = {
    action: ['click'],
    popupPlacement: 'bottom',
    popupClassName: 'Vital__Popup',
    destroyPopupOnHide: false,
    mouseEnterDelay: 0,
    mouseLeaveDelay: 0,
  };

  static Content = PopupContent;

  render() {
    const { children, popupPlacement, placement, builtinPlacements, ...rest } = this.props;
    return (
      <Trigger
        popupPlacement={placement || popupPlacement}
        builtinPlacements={{ ...placements, ...builtinPlacements }}
        {...rest}
        popupStyle={{
          transition: 'opacity 0.25s ease-in-out',
        }}
        zIndex={999}
      >
        {children}
      </Trigger>
    );
  }
}
