import { useCallback, useRef, useState } from 'react';

import type {
  ImageMapCustomPreviewBlock,
  ImageMapDragData,
  RectData,
} from 'components/LineMessageEditor/components/ImageMapeditor/ImageMapCustomBlockEditor/types';
import type {
  Dispatch,
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from 'react';

import { isStopMoving } from '../ImageMapCustomBlockEditor/utils/overlapChecking';

export const useDragNewBlock = (
  imageMapCustomWrapperRef: RefObject<HTMLDivElement>,
  blocks: ImageMapCustomPreviewBlock[],
  maxBlockCount: number,
  rectData: RectData,
): {
  mouseMoveFn: (e: MouseEvent) => void;
  imageDargDataRef: MutableRefObject<ImageMapDragData | undefined>;
  isDragging: boolean;
  previewBlocks: ImageMapCustomPreviewBlock[];
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  onMouseDown: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  onMouseUpOrLeave: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  setPreviewBlocks: Dispatch<SetStateAction<ImageMapCustomPreviewBlock[]>>;
} => {
  const imageDargDataRef = useRef<ImageMapDragData>();
  const [isDragging, setIsDragging] = useState(false);
  const [previewBlocks, setPreviewBlocks] = useState<ImageMapCustomPreviewBlock[]>(blocks);
  const mouseMoveFn = useCallback((e: MouseEvent) => {
    const scrollTop = imageMapCustomWrapperRef.current
      ? imageMapCustomWrapperRef.current.scrollTop
      : 0;
    const dragData = imageDargDataRef.current;

    if (dragData && dragData.isDarg) {
      const newBlock = axisHandler({
        id: dragData.newPreviewBlock.id,
        top: dragData.newPreviewBlock.top,
        left: dragData.newPreviewBlock.left,
        width: e.clientX - dragData.startX,
        height: e.clientY + scrollTop - dragData.startY,
      });
      e.preventDefault();
      const isStop = isStopMoving(newBlock, dragData.previewBlocks);
      if (!isStop) {
        dragData.lastSafeBlock = newBlock;
      }
      dragData.setPreviewBlocks([
        ...dragData.previewBlocks,
        isStop ? (dragData.lastSafeBlock ? dragData.lastSafeBlock : newBlock) : newBlock,
      ]);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onMouseDown = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    const scrollTop = imageMapCustomWrapperRef.current
      ? imageMapCustomWrapperRef.current.scrollTop
      : 0;
    if (previewBlocks.length === maxBlockCount) return;
    setIsDragging(true);
    e.preventDefault();
    const id = Date.now();
    const startX = e.clientX;
    const startY = e.clientY + scrollTop;
    const newPreviewBlock = {
      id,
      width: 0,
      height: 0,
      top: e.clientY + scrollTop - rectData.top,
      left: e.clientX - rectData.left,
    };

    imageDargDataRef.current = {
      id,
      startX,
      startY,
      newPreviewBlock,
      setPreviewBlocks,
      previewBlocks,
      isDarg: true,
    };
    (e.target as HTMLDivElement).addEventListener('mousemove', mouseMoveFn);
  };

  const onMouseUpOrLeave = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (imageDargDataRef.current) imageDargDataRef.current.isDarg = false;
    setIsDragging(false);
    (e.target as HTMLDivElement).removeEventListener('mousemove', mouseMoveFn);
  };

  return {
    mouseMoveFn,
    imageDargDataRef,
    isDragging,
    previewBlocks,
    setIsDragging,
    onMouseDown,
    onMouseUpOrLeave,
    setPreviewBlocks,
  };
};

/**
 * 處理座標系換算問題 將負值的寬和高轉換 並且更新基準點 讓拖拉可以在任何方向創造block
 * @param block ImageMapCustomPreviewBlock
 */
const axisHandler = (block: ImageMapCustomPreviewBlock): ImageMapCustomPreviewBlock => {
  return {
    ...block,
    left: block.width > 0 ? block.left : block.left + block.width,
    top: block.height > 0 ? block.top : block.top + block.height,
    width: block.width > 0 ? block.width : block.width * -1,
    height: block.height > 0 ? block.height : block.height * -1,
  };
};
