import type { MediaInfoResult, VideoTrack } from 'mediainfo.js';

export const VIDEO_LOAD_ERROR = 'Video error on loading';
export const CAN_NOT_GET_BLOB_ERROR = 'Blob is null';
export const CAN_NOT_GET_CANVAS_CONTEXT = 'Canvas getContext returned null';

export function matchYoutubeUrl(url: string): string | boolean {
  // cspell:disable
  const p =
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  // cspell:enable
  const matchResult = url.match(p);

  if (matchResult) {
    return matchResult[1];
  }
  return false;
}

type VideoMetadata = {
  width: number;
  height: number;
  aspectRatio: number;
  duration: number;
  encodeLibSupported: boolean;
};

export function getVideoMetadata(result: MediaInfoResult | undefined): VideoMetadata {
  if (result && result.media) {
    const track = result.media.track.find((track) => track['@type'] === 'Video') as
      | VideoTrack
      | undefined;
    const width = track ? Number(track.Width) : 0;
    const height = track ? Number(track.Height) : 0;
    const aspectRatio = width / height;
    const duration = track ? Number(track.Duration) : 0;
    const videoFormat = track?.Format ?? '';
    const encodeLibSupported = videoFormat ? videoFormat.search(/avc|AVC/) > -1 : false;

    return {
      width,
      height,
      aspectRatio,
      duration,
      encodeLibSupported,
    };
  }

  return {
    width: 0,
    height: 0,
    aspectRatio: 0,
    duration: 0,
    encodeLibSupported: false,
  };
}

type VideoFrame = {
  blob: Blob;
  sec: number;
  fileType: string;
  encoderOptions: number;
};

export const getImageFromVideoFrame = (path: string, sec: number): Promise<VideoFrame> => {
  return new Promise<VideoFrame>((resolve, reject) => {
    const video = document.createElement('video');
    const fileType = 'image/jpeg';
    const encoderOptions = 0.8;

    video.setAttribute('crossorigin', 'anonymous');

    video.onloadedmetadata = () => {
      video.currentTime = sec;
    };

    video.onseeked = () => {
      const canvas = document.createElement('canvas');
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve({
                blob,
                sec,
                fileType,
                encoderOptions,
              });
            } else {
              reject(new Error(CAN_NOT_GET_BLOB_ERROR));
            }
          },
          fileType,
          encoderOptions,
        );
      } else {
        reject(new Error(CAN_NOT_GET_CANVAS_CONTEXT));
      }
    };

    video.onerror = function () {
      reject(new Error(VIDEO_LOAD_ERROR));
    };

    video.src = path;
  });
};
