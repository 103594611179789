import { loadScript } from 'features/customerSupport/zendesk/loadScript';

export async function initialize(apiKey: string): Promise<void> {
  await loadScript(apiKey);

  // This is a workaround for a bug in Zendesk's widget.
  // The bug is that the widget remains open after the user reopens the page once they don't close it before closing the page.
  // I found that Zendesk SDK sets a session storage to record the open/close state of the widget.
  // I'll set the value to false when the page is unload to solve the issue.
  window.addEventListener('beforeunload', () => {
    const ZENDESK_WIDGET_OPEN_STORAGE_NAME = 'ZD-widgetOpen';
    const zendeskWidgetOpen = sessionStorage.getItem(ZENDESK_WIDGET_OPEN_STORAGE_NAME);

    if (zendeskWidgetOpen === 'true') {
      sessionStorage.setItem(ZENDESK_WIDGET_OPEN_STORAGE_NAME, 'false');
    }
  });
}
