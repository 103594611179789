import copy from 'copy-to-clipboard';
import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ComponentPropsWithRef } from 'react';

import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { theme } from 'theme';

export type CopyButtonProps = ComponentPropsWithRef<typeof Button> & {
  text: string;
  onCopied?: () => void;
};

export const CopyButton = memo(
  forwardRef<HTMLButtonElement | HTMLAnchorElement, CopyButtonProps>(function CopyButton(
    { text, onCopied, children, ...props },
    ref,
  ) {
    const { t } = useTranslation();
    return (
      <Tooltip title={<TooltipDescription>{t('common.copy')}</TooltipDescription>}>
        <Button
          ref={ref}
          type="link"
          size="small"
          onClick={() => {
            copy(text);
            onCopied?.();
          }}
          icon={
            <MotifIcon
              un-i-motif="copy"
              style={{ color: theme.colors.blue006, fontSize: '18px' }}
            />
          }
          {...props}
        />
      </Tooltip>
    );
  }),
);
