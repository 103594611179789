import type { RefObject } from 'react';

export function scrollToCarouselTarget(
  wrapperRef: RefObject<HTMLDivElement>,
  carrouselIndex: number,
): void {
  if (wrapperRef.current) {
    const target = wrapperRef.current.children[carrouselIndex];
    if (target) {
      window.requestAnimationFrame(() => {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        });
      });
    }
  }
}
