export async function loadScript(apiKey: string): Promise<void> {
  if (window.zE) {
    return;
  }

  return new Promise((resolve) => {
    const s = document.createElement('script');

    s.id = 'ze-snippet';
    s.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    s.async = true;
    s.onload = () => {
      resolve();
    };
    document.body.appendChild(s);
  });
}
