import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useFeatureFlag } from 'features/featureFlag';
import { useCallback } from 'react';

import type { ReactNode } from 'react';

import { isAxiosError } from 'lib/axios';
import { i18n } from 'modules/G11n/i18n';
import { useMessage } from 'shared/hooks/ui/useMessage';

const useQueryClient = () => {
  const { message } = useMessage();

  /**
   * This global error handler optionally updates users when a query has set `meta.errorMessage`
   * Or when `shouldHandleError` is set to true; in that case, some logic returns the server error
   * Reference: https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
   */
  const onQueryError: QueryCache['config']['onError'] = useCallback(
    (error, query) => {
      if (query.meta?.errorMessage) {
        message.error(query.meta.errorMessage);
      } else if (query.meta?.shouldHandleError) {
        if (isAxiosError<{ detail?: string }>(error) && error.response?.data?.detail) {
          message.error(error.response.data.detail);
        } else {
          message.error(i18n.t('common.unexpectedError'));
        }
      }
    },
    [message],
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 5, // Global default of 10 seconds
      },
    },
    queryCache: new QueryCache({
      onError: onQueryError,
    }),
  });

  return { queryClient };
};

/**
 * Note: activate React Query Devtools in dev mode
 */
export const QueryProvider = function (props: { children: ReactNode }) {
  const featureFlag = useFeatureFlag('react-query-devtool');

  const { queryClient } = useQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {props.children}
      {featureFlag ? <ReactQueryDevtools position={featureFlag} /> : null}
    </QueryClientProvider>
  );
};
