import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';

import { theme } from 'theme';

import * as S from './Styled';

export const NameDecorator = (props: { decoratedText: string; children?: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <S.DecoratorTagWrapper
      backgroundColor={theme.colors.green005}
      {...props}
      contentEditable={false}
    >
      <S.DecoratorReplaceTextWrapper>
        {props.decoratedText.replace('<$var:name', t('member.name')).replace('>', '')}
      </S.DecoratorReplaceTextWrapper>
      {props.children}
    </S.DecoratorTagWrapper>
  );
};
