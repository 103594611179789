import type { AbstractEditorData, CustomParameterData, LinkParameterData } from './types';
import type { Area } from '@line/bot-sdk';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { ParameterType } from 'components/LineMessageEditor/models/templateDataAndTypes/ParameterType';

export const createImageMapActionMessage = (
  props: Partial<ImageMapActionMessage> = {},
): ImageMapActionMessage => ({
  ...imageMapActionMessage,
  ...props,
});

export const createImageMapActionUrl = (
  props: Partial<typeof imageMapActionUrl> = {},
): typeof imageMapActionUrl => ({
  ...imageMapActionUrl,
  ...props,
});

export const createImageMapTriggerAction = (
  props: Partial<typeof imageMapTriggerAction> = {},
): typeof imageMapTriggerAction => ({
  ...imageMapTriggerAction,
  ...props,
});

export const createImageMapUrlParameter = (
  props: Partial<typeof imageMapUrlParameter> = {},
): typeof imageMapUrlParameter => ({
  ...imageMapUrlParameter,
  ...props,
});

interface ImageMapActionMessage {
  type: 'message' | 'uri' | 'postback';
  key: string;
  text: string;
  area: Area;
}

type ImagemapActionUri = {
  type: 'uri';
  linkUri: string;
  area: Area;
};

type ImagemapAction = ImageMapActionMessage | ImagemapActionUri;

export const isImagemapUriAction = (action: ImagemapAction): action is ImagemapActionUri => {
  return action.type === 'uri';
};

export const imageMapActionMessage: ImageMapActionMessage = {
  type: 'message',
  key: 'message01',
  text: '',
  area: {
    x: 0,
    y: 0,
    width: 1040,
    height: 1040,
  },
};
export const imageMapActionUrl: ImagemapActionUri = {
  type: 'uri',
  linkUri: '<$var:url02>',
  area: {
    x: 0,
    y: 0,
    width: 1040,
    height: 1040,
  },
};
export const imageMapUrlParameter = {
  key: 'url02',
  type: ParameterType.standard,
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [] as string[],
  } as LinkParameterData,
};

export const imageMapCustomParameter = {
  key: '',
  mappingKey: 'custom01',
  type: ParameterType.custom_string,
  data: {
    function: null,
  } as CustomParameterData,
};
export const imageMapTriggerAction = {
  key: 'message01',
  trigger_type: 'message',
  trigger_code: '',
  data: {
    type: ParameterType.standard,
    function: 'tag',
    tag_list: [] as string[],
  },
};

const data = {
  base_url: '',
  notification_text: '',
  actions: [imageMapActionMessage, imageMapActionUrl],
  animated: false,
  base_size: {
    width: 1040,
    height: 1040,
  },
};

export type ImageMapData = typeof data;

const localData = {
  activeBlockIndex: 1,
  isOpen: false,
  isCustom: false,
  format: {
    tagId: 1,
    isCustomBlock: false,
    /** only image with aspect ratio 1/3 and no alpha value in any pixel will be true */
    isFlex: true,
  },
};

type ImageMapLocalData = typeof localData;

export type ImageMap = AbstractEditorData<ImageMapData, ImageMapLocalData>;

export const imageMapData: ImageMap = {
  module_id: LineFieldType.ImageMap,
  data,
  parameters: [imageMapUrlParameter],
  actions: [],
  ...localData,
};

export interface ImageMapCustomPreviewBlock {
  id: number;
  width: number;
  height: number;
  top: number;
  left: number;
}
