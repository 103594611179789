import { safeString } from '@chatbotgang/etude/string/safeString';

import type { Rule } from './rules/type';
import type { useFieldResult } from './useField';
import type { ChangeEventHandler, CSSProperties } from 'react';
import type { InputProps, TextAreaProps } from 'shared/components/Input';

import { Input } from 'shared/components/Input';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

import { useField } from './useField';

export const Hint = styled.span`
  color: ${theme.colors.red006};
  font-size: 14px;
  line-height: 20px;
`;

interface FieldProps {
  checkOnChange?: boolean;
  /** This prop ensures layout displays the latest value. It is strongly recommended to add this. */
  enableReinitialize?: boolean;
  rules: Rule[];
  wrapperStyle?: CSSProperties;
}

export interface FieldInputProps extends Omit<InputProps, 'onError'>, FieldProps {
  name: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onError?: (error: boolean) => void;
  value: string;
}

export interface FieldTextAreaProps extends Omit<TextAreaProps, 'onError'>, FieldProps {
  name: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onError?: (error: boolean) => void;
  value: string;
}

export const FieldInput = ({
  wrapperStyle,
  onError,
  enableReinitialize,
  rules,
  checkOnChange,
  ...props
}: FieldInputProps) => {
  const { value, invalid, onBlur, onChange } = useField({
    onError,
    enableReinitialize,
    rules,
    checkOnChange,
    ...props,
  });
  return (
    <div style={wrapperStyle}>
      <Input
        {...props}
        id={props.name}
        value={typeof value === 'string' ? safeString(value) : value}
        onBlur={onBlur}
        onChange={(event) =>
          onChange({
            ...event,
            currentTarget: {
              ...event.currentTarget,
              value: safeString(event.currentTarget.value),
            },
            target: {
              ...event.target,
              value: safeString(event.target.value),
            },
          })
        }
        style={{
          ...props.style,
          ...(!!invalid && !invalid.valid && { border: '1px solid #f04f47' }),
        }}
        data-field-error={!!invalid && !invalid.valid ? 'true' : 'false'}
      />
      {!!invalid && !invalid.valid ? <Hint>{invalid.errorMessage}</Hint> : null}
    </div>
  );
};

export const FieldTextArea = ({
  wrapperStyle,
  onError,
  enableReinitialize,
  rules,
  checkOnChange,
  style,
  disabled,
  ...props
}: FieldTextAreaProps) => {
  const { value, invalid, onBlur, onChange } = useField({
    onError,
    enableReinitialize,
    rules,
    checkOnChange,
    ...props,
  });

  return (
    <div style={wrapperStyle}>
      <Input.TextArea
        {...props}
        disabled={disabled}
        id={props.name}
        name={props.name}
        onBlur={onBlur}
        onChange={(event) =>
          onChange({
            ...event,
            currentTarget: {
              ...event.currentTarget,
              value: safeString(event.currentTarget.value),
            },
            target: {
              ...event.target,
              value: safeString(event.target.value),
            },
          })
        }
        placeholder={props.placeholder}
        value={typeof value === 'string' ? safeString(value) : value}
        style={{
          ...style,
          ...(!!invalid && !invalid.valid && { border: '1px solid #f04f47' }),
        }}
        data-field-error={!!invalid && !invalid.valid ? 'true' : 'false'}
      />
      {!!invalid && !invalid.valid ? <Hint>{invalid.errorMessage}</Hint> : null}
    </div>
  );
};

export const UseFieldInput = (
  props: InputProps &
    useFieldResult & {
      wrapperStyle?: CSSProperties;
      name: string;
      placeholder?: string;
    },
) => (
  <div style={props.wrapperStyle}>
    <Input
      {...props}
      id={props.name}
      value={props.value}
      onBlur={props.onBlur}
      onChange={props.onChange}
      style={{
        ...(!!props.invalid && !props.invalid.valid && { border: '1px solid #f04f47' }),
      }}
      data-field-error={!!props.invalid && !props.invalid.valid ? 'true' : 'false'}
    />
    {!!props.invalid && !props.invalid.valid ? <Hint>{props.invalid.errorMessage}</Hint> : null}
  </div>
);
