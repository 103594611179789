import { createVarCall } from '@chatbotgang/motif';
import { Flex, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { TIME_FORMAT } from 'AppConstants';

import type { FC } from 'react';

import { useCurrentChannel } from 'context/authContext';
import { dayjs } from 'lib/dayjs';
import { LineMessageEditorFadedPreviewWrapper } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview/LineMessageEditorPreviewWrapper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography';
import { EllipsisText } from 'shared/components/Typography/EllipsisText';

export const LineMessageEditorPhoneNotificationPreview: FC<{ children: React.ReactNode }> =
  function LineMessageEditorPhoneNotificationPreview({ children }) {
    const { t } = useTranslation();
    const { name, picture_url } = useCurrentChannel();
    return (
      <Space direction="vertical">
        <Space size={4} align="center">
          <Typography variant="body" fontWeight="medium" color={createVarCall('--static-fg-title')}>
            {t('lineMessageEditor.preview.notification.phone')}
          </Typography>
          <Tooltip
            title={
              <TooltipDescription>
                {t('lineMessageEditor.preview.notification.phone.tooltip')}
              </TooltipDescription>
            }
          />
        </Space>
        <LineMessageEditorFadedPreviewWrapper>
          <Flex
            justify="center"
            style={{
              height: '100%',
              padding: '16px 12px',
              borderRadius: '4px',
              border: `1px solid ${createVarCall('--static-fg-line')}`,
              backgroundColor: createVarCall('--static-bg-page'),
            }}
          >
            <Flex gap={16} vertical={true} align="center" style={{ width: '100%' }}>
              <Space size={0} align="center" direction="vertical">
                <Typography variant="value" color={createVarCall('--color-grey-20')}>
                  {dayjs().format(TIME_FORMAT)}
                </Typography>
                <div
                  style={{
                    width: '160px',
                    height: '12px',
                    backgroundColor: createVarCall('--color-grey-10'),
                  }}
                />
              </Space>
              <Flex
                gap={8}
                align="center"
                style={{
                  width: '100%',
                  padding: '8px 12px',
                  borderRadius: '12px',
                  boxShadow: '0px 0px 8px 0px #223B5333',
                }}
              >
                <div
                  style={{
                    height: '24px',
                    width: '24px',
                    borderRadius: '50%',
                    flexShrink: 0,
                    backgroundColor: createVarCall('--static-fg-primary'),
                    ...(picture_url && {
                      backgroundImage: `url(${picture_url})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }),
                  }}
                />
                <Flex vertical={true} justify="space-between">
                  <Typography
                    variant="note"
                    fontWeight="medium"
                    color={createVarCall('--static-fg-body')}
                  >
                    <EllipsisText as="span">{name}</EllipsisText>
                  </Typography>
                  <Typography variant="note" color={createVarCall('--static-fg-note')}>
                    <EllipsisText as="span" maxLine={2}>
                      {children || t('lineMessageEditor.preview.notification.phone.placeholder')}
                    </EllipsisText>
                  </Typography>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </LineMessageEditorFadedPreviewWrapper>
      </Space>
    );
  };
