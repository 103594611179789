/**
 * useLayoutEffect alternative work on both client and server side.
 *
 * Copied from MUI:
 * https://github.com/mui/material-ui/blob/49b3469/packages/mui-utils/src/useEnhancedEffect.js
 */

import { useEffect, useLayoutEffect } from 'react';

export const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;
