import { motion } from 'framer-motion';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Variants } from 'framer-motion';
import type { AvailableLangs } from 'modules/G11n/i18n';
import type { CSSProperties } from 'react';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { LineFieldTypeName } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import {
  LineMessageCard,
  LineMessageCarrousel,
  LineMessageImage,
  LineMessageImageCarousel,
  LineMessageImagemap,
  LineMessageImagemapCarousel,
  LineMessagePrize,
  LineMessageText,
  LineMessageVideo,
} from 'icons';
import { formatLangCode } from 'modules/G11n/i18n';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { trackGaEvent } from 'shared/lib/ga/trackGaEvent';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

const buttonVariants: Variants = {
  init: { scale: 1 },
  hover: { scale: 1.1 },
  tap: { scale: 0.9 },
};

type ButtonWrapperProps = { disable: boolean };

const ButtonWrapper = styled(motion.div).withConfig({
  shouldForwardProp: shouldNotForwardProps(['disable']),
})<ButtonWrapperProps>`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 112px;
  height: 90px;
  cursor: pointer;
  border: dashed 1px ${theme.colors.neutral005};
  border-radius: 8px;
  margin-right: 16px;
  margin-bottom: 16px;
  background-color: ${theme.colors.white001};
  user-select: none;
  vertical-align: top;
  transition:
    0.3s color ease-in-out,
    0.3s border-color ease-in-out;
  pointer-events: ${({ disable }) => (disable ? 'none' : 'auto')};
  svg path:not(.image-carrousel-svg) {
    fill: ${theme.colors.neutral006};
    transition: 0.3s fill ease-in-out;
  }
  .image-carrousel-svg > g {
    fill: ${theme.colors.neutral006};
    transition: 0.3s fill ease-in-out 0s;
  }

  &:nth-child(3n) {
    margin-right: 0;
  }
  &:hover {
    border-color: ${theme.colors.blue006};
    svg path:not(.image-carrousel-svg) {
      fill: ${theme.colors.blue006};
    }
    .image-carrousel-svg > g {
      fill: ${theme.colors.blue006};
      transition: 0.3s fill ease-in-out 0s;
    }
    span {
      color: ${theme.colors.blue006};
    }
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 28px;
  transform: translateX(-50%);
`;

const ButtonTextWrap = styled.div`
  position: absolute;
  top: 46px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  height: 32px;
  color: ${theme.colors.neutral008};
  font-size: 12px;
  line-height: 16px;
  text-align: center;
`;

interface CompactTextProps {
  lang: AvailableLangs;
}

const CompactText = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['lang']),
})<CompactTextProps>`
  width: ${({ lang }) => (lang === 'en' ? 'min-content' : 'auto')};
`;

const OpenCountTrackableTag = styled.div`
  border-radius: 2px;
  background-color: ${theme.colors.green005};
  color: ${theme.colors.white001};
  position: absolute;
  left: 50%;
  top: -1px;
  transform: translateX(-50%);
  font-size: 12px;
  width: 96px;
  height: 20px;
  line-height: 20px;
  padding: 0 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface ModuleButtonProps {
  moduleType: LineFieldType;
  onClick?: () => void;
  text: string;
  id?: string;
  style?: CSSProperties;
  disable: boolean;
  openCountTrackable?: boolean;
}

export const ModuleButton = memo(function ModuleButton({
  moduleType,
  onClick,
  text,
  id,
  style,
  disable,
  openCountTrackable = false,
  ...props
}: ModuleButtonProps) {
  const { i18n } = useTranslation();

  return (
    <ButtonWrapper
      style={style}
      onClick={() => {
        trackGaEvent('messageEditorModuleAdd', { label: LineFieldTypeName[moduleType] });
        onClick && onClick();
      }}
      id={id}
      disable={disable}
      {...props}
      whileHover="hover"
      whileTap="tap"
      initial="init"
      variants={buttonVariants}
    >
      <IconWrapper>
        {moduleType === LineFieldType.Text ? <LineMessageText /> : null}
        {moduleType === LineFieldType.Image ? <LineMessageImage /> : null}
        {moduleType === LineFieldType.ImageCarousel ? <LineMessageImageCarousel /> : null}
        {moduleType === LineFieldType.Card ? <LineMessageCard /> : null}
        {moduleType === LineFieldType.Carrousel ? <LineMessageCarrousel /> : null}
        {moduleType === LineFieldType.ImageMapFlex ? <LineMessageImagemap /> : null}
        {moduleType === LineFieldType.ImageMapCarousel ? <LineMessageImagemapCarousel /> : null}
        {moduleType === LineFieldType.Video ? <LineMessageVideo /> : null}
        {moduleType === LineFieldType.Prize ? <LineMessagePrize /> : null}
      </IconWrapper>
      <ButtonTextWrap>
        <span>+</span>
        <CompactText lang={formatLangCode(i18n.language)}>{text}</CompactText>
      </ButtonTextWrap>
      {openCountTrackable ? (
        <Tooltip
          title={
            <TooltipDescription>
              <Trans i18nKey="message.module.trackOpenCount" />
            </TooltipDescription>
          }
        >
          <OpenCountTrackableTag>
            <Trans i18nKey="message.module.trackOpenCount" />
          </OpenCountTrackableTag>
        </Tooltip>
      ) : null}
    </ButtonWrapper>
  );
});
