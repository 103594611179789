import { z } from 'zod';

export const ListResponseSchema = z
  .object({
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: z.unknown().array(),
  })
  .strict();

export const PageResponseSchema = z
  .object({
    count: z.number(),
    next: z.string().nullable(),
    previous: z.string().nullable(),
    results: z.unknown().array(),
  })
  .strict();

export const AsyncTaskSchema = z
  .object({
    key: z.string(),
  })
  .strict();

export type AsyncTask = z.output<typeof AsyncTaskSchema>;

export const ExportResponseSchema = z
  .object({
    key: z.string(),
    messages: z.string(),
  })
  .strict();
