import { color } from 'styled-system';

import type { ColorProps } from 'styled-system';

import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

export const DecoratorTagWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(
    // Note: these are some props specific to draft-js
    'blockKey',
    'contentState',
    'decoratedText',
    'entityKey',
    'offsetKey',
  ),
})<ColorProps>`
  display: inline-block;
  border-radius: 4px;
  color: ${theme.colors.white001};
  padding: 0 4px;
  cursor: pointer;
  font-size: 0;
  ${color}
`;

export const DecoratorReplaceTextWrapper = styled.span`
  font-size: 14px;
`;
