import { commonConfig } from 'features/featureFlag/commonConfig';
import { createFeatureFlagApi } from 'features/featureFlag/createFeatureFlagApi';

import type { featureFlagConfigs } from 'config/featureFlagConfigs';
import type { Types } from 'features/featureFlag/createFeatureFlagApi';

/**
 * Use `commonConfig` instead of the actual `featureFlags` for easier debugging
 * with more common types.
 */

export const commonFeatureFlagsApi = createFeatureFlagApi(commonConfig);

/**
 * This is for internal common components or functions, not for the app.
 */
export type CommonFeatureFlagsTypes = Types<typeof commonConfig>;

export type FeatureFlagTypes = Types<typeof featureFlagConfigs>;

const api = commonFeatureFlagsApi as unknown as /**
 * Fully restore the original types from the configuration.
 */ ReturnType<typeof createFeatureFlagApi<typeof featureFlagConfigs>>;

const {
  useEnabledSomeFeatureFlags,
  useFeatureFlag,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
  useGetFeatureFlag,
  FeatureFlag,
  withFeatureFlagWrapper,
} = api;

export {
  FeatureFlag,
  useEnabledSomeFeatureFlags,
  useFeatureFlag,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
  useGetFeatureFlag,
  withFeatureFlagWrapper,
};
