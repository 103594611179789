import type {
  Action,
  FlexBox,
  FlexBubble,
  FlexImage,
  MessageAction,
  URIAction,
} from '@line/bot-sdk';
import type {
  AbstractEditorData,
  CustomParameterData,
  LinkParameterData,
  Parameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { ParameterType } from 'components/LineMessageEditor/models/templateDataAndTypes/ParameterType';

export enum ImageMapCarouselActions {
  message = 'message',
  uri = 'uri',
  customUri = 'customUri',
}

export type ImageMapCarouselUriAction = URIAction & {
  /** For mapping data in parameters. Should be unique */
  key: string;
};

export type ImageMapCarouselCustomUriAction = {
  type: ImageMapCarouselActions;
  /** For mapping data in parameters. Should be unique */
  key: string;
  uri: string;
};

/*
 * This is the data type used in editor UI
 * The custom URI action is only used in UI
 */
type ImageMapCarouselAction =
  | MessageAction
  | ImageMapCarouselUriAction
  | ImageMapCarouselCustomUriAction;

type PossibleImageMapCarouselAction =
  | ImageMapCarouselAction
  | Action<{
      label: string;
    }>
  | undefined;

export const isImageMapCarouselMessageAction = (
  action: PossibleImageMapCarouselAction,
): action is MessageAction => {
  return action !== undefined && action.type === 'message';
};

export const isImageMapCarouselUriAction = (
  action: PossibleImageMapCarouselAction,
): action is ImageMapCarouselUriAction => {
  return action !== undefined && action.type === 'uri';
};

export const isImageMapCarouselCustomUriAction = (
  action: PossibleImageMapCarouselAction,
): action is ImageMapCarouselCustomUriAction => {
  return action !== undefined && action.type === 'customUri';
};

export type ImageMapCarouselActionBlock = Omit<FlexBox, 'action'> & {
  action: ImageMapCarouselAction;
};

export type ActionBlockStyles = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export type ImagemapCarouselContents = [FlexImage, ...ImageMapCarouselActionBlock[]];

export type ImageMapCarouselItem = Required<Pick<FlexBubble, 'type'>> & { size: 'giga' } & {
  body: Pick<FlexBox, 'type' | 'paddingAll'> & {
    layout: 'vertical';
    contents: ImagemapCarouselContents;
  };
};
export const defaultImageMapCarouselActionBlock: ImageMapCarouselActionBlock = {
  type: 'box',
  layout: 'vertical',
  contents: [],
  position: 'absolute',
  width: '100%',
  height: '100%',
  offsetTop: '0%',
  offsetStart: '0%',
  action: {
    type: 'message',
    text: '',
  },
};

export const defaultImageMapCarouselItem: ImageMapCarouselItem = {
  type: 'bubble',
  size: 'giga',
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'image',
        url: '',
        size: 'full',
        aspectMode: 'cover',
        aspectRatio: '1:1',
        gravity: 'center',
        animated: false,
      },
      defaultImageMapCarouselActionBlock,
    ],
    paddingAll: '0px',
  },
};

const imageMapCarousel = {
  type: 'carousel',
  contents: [defaultImageMapCarouselItem],
};

const data = {
  notification_text: '',
  contents: imageMapCarousel,
};

type ImageMapCarouselData = typeof data;

const localData = {
  /**
   * For each carousel item, which action block is active. Starting from 1.
   * For example, if we have three carousel items and each of them have active block at first position, the data will be [1, 1, 1].
   * Only used in UI
   */
  activeBlockIndices: [1],
  /**
   * For each carouse item, use custom action blocks or not.
   * Custom block means the user can use draw a block with arbitrary size in any position on the image.
   * On the other hand, predefined block means the user can only use the predefined action blocks with predefined size.
   */
  customBlocks: [false],
};

type ImageMapCarouselLocalData = typeof localData;

export type ImageMapCarousel = AbstractEditorData<
  ImageMapCarouselData,
  ImageMapCarouselLocalData,
  never, // Always empty in imagemap carousel
  CustomParameterData | LinkParameterData
>;

export const initialData: ImageMapCarousel = {
  module_id: LineFieldType.ImageMapCarousel,
  data,
  activeBlockIndices: [1],
  customBlocks: [false],
  parameters: [],
  actions: [],
  quick_reply: { items: [] },
};

// TODO: [parameters] this should be a general function for all editor modules
export const createImageMapCarouselUrlParameter = (
  props: Partial<Parameter<LinkParameterData>> = {},
): Parameter<LinkParameterData> => ({
  key: '',
  type: ParameterType.standard,
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [],
  },
  ...props,
});

// TODO: [parameters] this should be a general function for all editor modules
export const createImageMapCarouselCustomUrlParameter = (
  props: Partial<Parameter<CustomParameterData>> = {},
): Parameter<CustomParameterData> => ({
  key: '',
  mappingKey: '',
  type: ParameterType.custom_string,
  data: { function: null },
  ...props,
});
