import PictureOutlined from '@ant-design/icons/PictureOutlined';
import { memo, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { ChangeEvent, ReactNode } from 'react';

import { BodyBold, Box, FileInput, Flex, Loading, StyledIcon, Text } from 'components';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from './Styled';

type ImageUploadBoxProps = {
  imageUrl: string;
  acceptedExtensions: string[];
  formatHint: ReactNode;
  onInputImage: (e: ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  width?: number;
  height?: number;
  disabled?: boolean;
};

export const ImageUploadBox = memo(function ImageUploadBox({
  imageUrl,
  acceptedExtensions,
  formatHint,
  onInputImage,
  isUploading,
  width = 348,
  height = 147,
  disabled = false,
}: ImageUploadBoxProps) {
  const acceptAttributes = useMemo(
    () => acceptedExtensions.map((ext) => `image/${ext}`).join(','),
    [acceptedExtensions],
  );
  const { t } = useTranslation();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      width={width}
      height={height}
      textAlign="center"
      border={`1px dashed ${theme.colors.neutral005}`}
      borderRadius="4px"
      opacity={disabled ? 0.5 : 1}
    >
      <S.UploadImageLabel type="imageMap" bgUrl={imageUrl}>
        <FileInput accept={acceptAttributes} onChange={onInputImage} />
        {isUploading ? <Loading /> : null}
      </S.UploadImageLabel>
      {imageUrl === '' ? (
        <Box>
          <StyledIcon
            mb={2}
            component={<PictureOutlined />}
            fontSize={21}
            color={theme.colors.neutral006}
          />
          <BodyBold mb="8px">
            {t('common.uploadObject', { object: lowerFirst(t('glossary.image')) })}
          </BodyBold>
          <Text>{formatHint}</Text>
        </Box>
      ) : null}
      {imageUrl !== '' ? (
        <S.ImageCoverWrapper>
          <S.ImageHoverBoxIconWrapper>
            <StyledIcon mb={2} component={<PictureOutlined />} fontSize={21} />
            <br />
            <Text>
              <Trans i18nKey="common.replace" />
            </Text>
            <FileInput accept={acceptAttributes} onChange={onInputImage} />
          </S.ImageHoverBoxIconWrapper>
        </S.ImageCoverWrapper>
      ) : null}
      {disabled ? (
        <Box
          position="absolute"
          top={0}
          left={0}
          zIndex={201}
          width="100%"
          height="100%"
          backgroundColor="rgba(44, 56, 72, 0.8)"
        ></Box>
      ) : null}
    </Flex>
  );
});
