import type { AsyncProps, DeferFn, PromiseFn } from 'react-async';

/**
 * @param axios a axios function
 * @returns a function that can be used to create a promise
 */
export function axiosToPromiseFn<T>(axios: Promise<T>): PromiseFn<T> {
  return (props: AsyncProps<T>, controller: AbortController) => axios;
}

/**
 * @param axios a axios function
 * @returns a function that can be used to create a promise
 */
export function axiosToDeferFn<T>(axios: Promise<T>): DeferFn<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (arg: any, props: AsyncProps<T>, controller: AbortController) => axios;
}
