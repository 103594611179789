/* eslint-disable tsdoc/syntax */
import { forwardRef, memo, useMemo } from 'react';
import { length, substring } from 'stringz';

import type { ComponentPropsWithRef } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { css, cx, overrideCss } from 'shared/utils/styled/override';

type TruncatedTextProps = Omit<ComponentPropsWithRef<'div'>, 'children'> &
  OverrideComponentCssProps & {
    str: string;
    offset?: number;
  };

const StyledSpan = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  ${overrideCss};
`;

const HiddenText = styled.span`
  inset: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
`;

/**
 * This component can make your text ellipsis in middle. This element is an
 * inline-flex div with 100% max-width as default. Emoji is supported.
 * @example
 * ```tsx
 * <TruncatedText str={fileName} offset={extname(filename).length + 2} />
 * ```
 * @param props -
 * @param props.str - The text to be truncated.
 * @param props.offset - The offset of the ellipsis. Positive value means
 *                       specific length for the trailing part of the text;
 *                       Negative value means specific length for the leading.
 *                       Negative value is not recommended because it might be
 *                       wrong with emoji.
 * @return React.JSX.Element
 */
export const TruncatedText = styled(
  memo(
    forwardRef<HTMLDivElement, TruncatedTextProps>(function TruncatedText(
      { str: rawStr, offset = 0, ...props },
      ref,
    ) {
      /**
       * No-Break Space is only for render here.
       */
      const str = useMemo(() => rawStr.replace(/\s+/g, ' '), [rawStr]);
      const isLeadingTruncated = useMemo(() => offset < 0, [offset]);
      const truncatedIndex = useMemo(
        () => (isLeadingTruncated ? 0 - offset : length(str) - offset),
        [offset, str, isLeadingTruncated],
      );
      const leading = useMemo(() => substring(str, 0, truncatedIndex), [str, truncatedIndex]);
      const cssLeading = useMemo(
        () =>
          cx(
            css`
              user-select: none;
            `,
            isLeadingTruncated
              ? css`
                  min-width: min-content;
                `
              : css`
                  overflow: hidden;
                  text-overflow: ellipsis;
                `,
          ),
        [isLeadingTruncated],
      );
      const tailing = useMemo(() => substring(str, truncatedIndex), [str, truncatedIndex]);
      const cssTailing = useMemo(
        () =>
          cx(
            css`
              user-select: none;
            `,
            isLeadingTruncated
              ? css`
                  direction: rtl;
                  overflow: hidden;
                  text-align: right;
                  text-overflow: ellipsis;
                `
              : css`
                  min-width: min-content;
                `,
          ),
        [isLeadingTruncated],
      );
      return (
        <div {...props} ref={ref}>
          <HiddenText>{rawStr}</HiddenText>
          <StyledSpan styledCss={cssLeading}>{leading}</StyledSpan>
          <StyledSpan styledCss={cssTailing}>
            <span>&rlm;</span>
            <span>{tailing}</span>
            <span>&lrm;</span>
          </StyledSpan>
        </div>
      );
    }),
  ),
)`
  display: inline-flex;
  line-height: 1.5em;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  user-select: all;
  white-space: pre;
  width: min-content;
  word-break: keep-all;
`;
