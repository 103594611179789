import { useEffect } from 'react';
import ReactGA4 from 'react-ga4';

import { useAuth } from 'context/authContext/context';

/**
 * These settings configure every subsequent event that is sent to GA4
 * Note 1: the custom user properties must be configured in the associated GA4 property
 * Note 2: GA4 parses `user_id` as a string, but the other properties are parsed as numbers
 */
export const useGaEventSettings = () => {
  const {
    state: { currentChannel, org, user },
  } = useAuth();

  useEffect(
    function setGaUserProperties() {
      if (currentChannel?.id && org?.id && user?.id) {
        ReactGA4.set({
          user_properties: {
            channel_id: currentChannel.id,
            organization_id: org.id,
            user_id: user.id,
          },
        });
      }
    },
    [currentChannel, org, user],
  );
};
