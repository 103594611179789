import { createVarCall } from '@chatbotgang/motif';
import { Flex, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import { useCurrentChannel } from 'context/authContext';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography';
import { EllipsisText } from 'shared/components/Typography/EllipsisText';
import { theme } from 'theme';

export const LineMessageEditorPhoneChatRoomPreview: FC<{ children: React.ReactNode }> =
  function LineMessageEditorPhoneChatRoomPreview({ children }) {
    const { t } = useTranslation();
    const { name, picture_url } = useCurrentChannel();

    return (
      <Space direction="vertical">
        <Space size={4} align="center">
          <Typography variant="body" fontWeight="medium" color={createVarCall('--static-fg-title')}>
            {t('lineMessageEditor.preview.notification.chatList')}
          </Typography>
          <Tooltip
            title={
              <TooltipDescription>
                {t('lineMessageEditor.preview.notification.chatList.tooltip')}
              </TooltipDescription>
            }
          />
        </Space>
        <div
          style={{
            height: '100%',
            padding: '16px 12px',
            width: theme.sizes.linePreviewWidth,
            backgroundColor: createVarCall('--static-bg-page'),
          }}
        >
          <Flex gap={16} align="center" style={{ height: '70px' }}>
            <div
              style={{
                height: '50px',
                width: '50px',
                borderRadius: '50%',
                flexShrink: 0,
                backgroundColor: createVarCall('--static-fg-primary'),
                ...(picture_url && {
                  backgroundImage: `url(${picture_url})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }),
              }}
            />
            <Flex vertical={true} justify="space-between" style={{ width: '100%' }}>
              <Flex align="center" justify="space-between">
                <Typography
                  variant="body"
                  fontWeight="medium"
                  color={createVarCall('--static-fg-body')}
                >
                  <EllipsisText as="span">{name}</EllipsisText>
                </Typography>
                <Typography variant="note" color={createVarCall('--static-fg-note')}>
                  {t('lineMessageEditor.preview.notification.chatList.now')}
                </Typography>
              </Flex>
              <Typography variant="note" color={createVarCall('--static-fg-note')}>
                <EllipsisText as="span" maxLine={2}>
                  {children || t('lineMessageEditor.preview.notification.chatList.placeholder')}
                </EllipsisText>
              </Typography>
            </Flex>
          </Flex>
          <LineMessageEditorPhoneChatItemSkeleton />
          <LineMessageEditorPhoneChatItemSkeleton />
        </div>
      </Space>
    );
  };

const LineMessageEditorPhoneChatItemSkeleton = function LineMessageEditorPhoneChatItemSkeleton() {
  return (
    <Flex gap={16} align="center" style={{ height: '70px' }}>
      <div
        style={{
          height: '50px',
          width: '50px',
          borderRadius: '50%',
          flexShrink: 0,
          backgroundColor: createVarCall('--color-grey-10'),
        }}
      />
      <Flex vertical={true} gap={4} justify="space-between" style={{ width: '100%' }}>
        <Flex align="center" justify="space-between">
          <div
            style={{
              width: '100px',
              height: '16px',
              backgroundColor: createVarCall('--color-grey-10'),
            }}
          />
          <div
            style={{
              width: '32px',
              height: '12px',
              backgroundColor: createVarCall('--color-grey-10'),
            }}
          />
        </Flex>
        <div
          style={{
            width: '140px',
            height: '12px',
            backgroundColor: createVarCall('--color-grey-10'),
          }}
        />
      </Flex>
    </Flex>
  );
};
