/**
 * Remove origin from input URL
 * This is primarily used when processing the "next" value from list APIs
 * Use it close to the API call (e.g. in services) to avoid confusion
 *
 * @param url - next page URL as returned by a list API
 * @returns URL without origin
 */
export const replaceUrlOrigin = (url: string): string => {
  const parsed = new URL(url);

  return parsed.href.replace(parsed.origin, '');
};
