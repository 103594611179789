import type { Rule } from './type';

import { i18n } from 'modules/G11n/i18n';
import { string } from 'utils/pitch-shifter/string';
import { stripSpaces } from 'utils/string/whiteSpaces';

export const required = ((message) => ({
  handlesNull: true,
  validate: (value) => {
    if (value === 0) {
      return true;
    }
    if (value === -1 || value === '-1') {
      return false;
    }
    const validate = !!value && !!stripSpaces(string()(value));
    return validate;
  },
  hint: () => message || i18n.t('common.messageCanNotBeEmpty'),
  errorType: 'EmptyField',
})) as Rule;
