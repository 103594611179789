import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const UploadLabel = styled.label`
  padding: 0 8px;
  display: inline-block;
  line-height: 28px;
  font-size: 14px;
  border-radius: 4px;
  background-color: ${theme.colors.neutral002};
  cursor: pointer;
  i {
    margin-top: 6px;
    margin-right: 8px;
  }
`;
