import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { dispatch as dispatchForType } from 'components/LineMessageEditor/models';
import type { TextDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type {
  State,
  TextDataParameterUrl,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { JSX, ReactNode } from 'react';

import * as S from 'components/LineMessageEditor/components/Styled';
import { UrlUTMModule } from 'components/LineMessageEditor/components/UrlUTMModule';
import { Context } from 'components/LineMessageEditor/models';
import * as Validator from 'lib/validator';
import * as ValidatorHelper from 'lib/validator/helper/validatorHelpers';
import { EEditorType } from 'lib/validator/helper/validatorHelpers';
import { theme } from 'theme';

import * as LocalS from './Styled';

interface URLDetailContentProps {
  entityKey: string;
  store: State;
  dispatch: typeof dispatchForType;
  decoratedText: string;
}
function getUrlData(store: State, entityKey: string) {
  if (store.editorData[store.editorEditingRow] === undefined) return undefined;
  return (store.editorData[store.editorEditingRow] as TextDataType).parameters.findIndex((d) => {
    return d.key === 'url' + entityKey;
  }) === -1
    ? undefined
    : (
        (store.editorData[store.editorEditingRow] as TextDataType).parameters[
          (store.editorData[store.editorEditingRow] as TextDataType).parameters.findIndex((d) => {
            return d.key === 'url' + entityKey;
          })
        ] as TextDataParameterUrl
      ).data;
}
const URLDetailContent = ({ entityKey, store, dispatch }: URLDetailContentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <S.ImageMapPopoverContent style={{ padding: '16px' }}>
      <S.PopOverFieldTitle>
        {t('glossary.url')}
        {entityKey}
      </S.PopOverFieldTitle>
      <UrlUTMModule
        urlValue={getUrlData(store, entityKey)?.url ?? ''}
        openExternal={getUrlData(store, entityKey)?.open_external_browser ?? false}
        onOpenExternalChange={(open) => {
          dispatch('setTextEditingUrlParametersDetail', {
            urlKey: 'url' + entityKey,
            data: { open_external_browser: open },
          });
        }}
        onFieldsChange={(value, utmFields) => {
          dispatch('setTextEditingUrlParametersDetail', {
            urlKey: 'url' + entityKey,
            data: {
              url: value,
              ...utmFields,
            },
          });
        }}
        tagList={getUrlData(store, entityKey)?.tag_list ?? []}
        onTagChange={(tags) => {
          dispatch('setTextEditingUrlTag', { urlKey: 'url' + entityKey, tagList: tags });
        }}
        UTMSourceValue={getUrlData(store, entityKey)?.utm_source ?? ''}
        onUTMSourceChange={(value) => {
          dispatch('setTextEditingUrlParametersDetail', {
            urlKey: 'url' + entityKey,
            data: { utm_source: value },
          });
        }}
        UTMMediumValue={getUrlData(store, entityKey)?.utm_medium ?? ''}
        onUTMMediumChange={(value) => {
          dispatch('setTextEditingUrlParametersDetail', {
            urlKey: 'url' + entityKey,
            data: { utm_medium: value },
          });
        }}
        UTMCampaignValue={getUrlData(store, entityKey)?.utm_campaign ?? ''}
        onUTMCampaignChange={(value) => {
          dispatch('setTextEditingUrlParametersDetail', {
            urlKey: 'url' + entityKey,
            data: { utm_campaign: value },
          });
        }}
        UTMContentValue={getUrlData(store, entityKey)?.utm_content ?? ''}
        onUTMContentChange={(value) => {
          dispatch('setTextEditingUrlParametersDetail', {
            urlKey: 'url' + entityKey,
            data: { utm_content: value },
          });
        }}
        rowIndex={store.editorEditingRow}
        entityKey={entityKey}
        editorType="RichTextEditor"
        data-test="text-module-popover-uri-input"
      ></UrlUTMModule>
      <S.PopOverFieldsContent />
    </S.ImageMapPopoverContent>
  );
};

interface UrlDecoratorProps {
  decoratedText: string;
  editorType: EEditorType.RichTextEditor | EEditorType.ParamRichTextEditor;
  children?: ReactNode;
}

export const UrlDecorator = ({
  editorType = EEditorType.RichTextEditor,
  ...props
}: UrlDecoratorProps) => {
  const { store, dispatch } = useContext(Context);
  const [isShow, setIsShow] = useState(
    getUrlData(store, props.decoratedText.replace('<$var:url', '').replace('>', ''))?.url === '',
  );
  const validatorContext = useContext(Validator.Context);
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      validatorContext.unregister(
        ValidatorHelper.urlNameHelper({
          rowIndex: store.editorEditingRow,
          entityKey: props.decoratedText.replace('<$var:url', '').replace('>', ''),
          editorIndex: undefined,
          editorType,
        }),
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Validator.FormPopover
      popup={
        <URLDetailContent
          entityKey={props.decoratedText.replace('<$var:url', '').replace('>', '')}
          decoratedText={props.decoratedText}
          store={store}
          dispatch={dispatch}
        />
      }
      popupAlign={{
        offset: [20, 200],
      }}
      placement="right"
      popupVisible={isShow}
      onPopupVisibleChange={(v) => !v && setIsShow(v)}
    >
      <LocalS.DecoratorTagWrapper
        backgroundColor={
          validatorContext.invalid[
            ValidatorHelper.urlNameHelper({
              rowIndex: store.editorEditingRow,
              editorType,
              entityKey: props.decoratedText.replace('<$var:url', '').replace('>', ''),
            })
          ] === undefined
            ? theme.colors.blue006
            : validatorContext.invalid[
                  ValidatorHelper.urlNameHelper({
                    rowIndex: store.editorEditingRow,
                    editorType,
                    entityKey: props.decoratedText.replace('<$var:url', '').replace('>', ''),
                  })
                ]?.valid === true
              ? theme.colors.blue006
              : theme.colors.red006
        }
        contentEditable={false}
        onClick={() => {
          setIsShow(true);
        }}
      >
        <LocalS.DecoratorReplaceTextWrapper style={{ fontSize: 14 }} contentEditable={false}>
          {t('glossary.url') + props.decoratedText.replace('<$var:url', '').replace('>', '')}
        </LocalS.DecoratorReplaceTextWrapper>
        {props.children}
      </LocalS.DecoratorTagWrapper>
    </Validator.FormPopover>
  );
};
