import { OPEN_COUNT_FEATURE_LAUNCH_DATE } from 'AppConstants';

import { dayjs } from 'lib/dayjs';

/**
 * Determine if a record has open count feature by its schedule time
 *
 * @param scheduleTime - A timestamp from API payload
 * @returns True if the scheduleTime is after 2021/03/29 or else
 */
export function isOpenCountLaunched(scheduleTime: dayjs.ConfigType): boolean {
  return dayjs(OPEN_COUNT_FEATURE_LAUNCH_DATE).isBefore(dayjs(scheduleTime));
}
