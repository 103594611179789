import Space from 'antd/es/space';
import cn from 'classnames';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { OnDeleteRowData } from './ParamImageEditor';
import type { ParamRichTextProps } from './RichTextEditor/ParamRichText';
import type { ParamRichTextFieldName } from 'components/LineMessageEditor/constants';
import type {
  ButtonWithKey,
  CardButtonType,
  TagAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import type {
  CardEditorData,
  CustomParameter,
  LinkParameter,
  ShareButtonParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { FlatChannel } from 'layout/types';
import type { Ref } from 'react';

import { StyledIcon } from 'components';
import { Box } from 'components/layoutUtils';
import {
  defaultAllowedParamTextDataTags,
  ImageData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { Text } from 'components/Typography/Text';
import { useAuth } from 'context/authContext';
import { useHandler } from 'hooks/useEventCallback';
import { useLastHover } from 'hooks/useLastHover';
import { DeleteSvg } from 'icons/delete';
import * as Validator from 'lib/validator';
import {
  customNameHelper,
  descriptionTextNameHelper,
  labelTextNameHelper,
  messageNameHelper,
  notificationTextNameHelper,
  shareButtonNameHelper,
  titleTextNameHelper,
} from 'lib/validator/helper/validatorHelpers';
import { maxLength } from 'lib/validator/rules/maxLength';
import { required } from 'lib/validator/rules/required';
import { TriggerCodeModule } from 'modules/TriggerCodeModule';
import { Button as AntButton } from 'shared/components/Button';
import { generateDataTestId } from 'shared/components/Editor/LineMessageEditor/utils/generateDataTestId';
import { Popover } from 'shared/components/Popover';
import { Radio } from 'shared/components/Radio';
import { TagPickerByName } from 'shared/components/TagPicker/TagPickerByName';
import {
  Tooltip,
  TooltipAction,
  TooltipContainer,
  TooltipDescription,
} from 'shared/components/Tooltip';
import { trackGaEvent } from 'shared/lib/ga/trackGaEvent';
import { css, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import { Context } from '../models';

import { ParamRichText } from './RichTextEditor/ParamRichText';
import { MessageConflictNotice } from './MessageConflictNotice';
import { MessageShareTargetPickerWarning } from './MessageShareTargetPickerWarning';
import { ParamImageEditor } from './ParamImageEditor';
import { UrlUTMModule } from './UrlUTMModule';

interface CardEditorProps {
  message: CardEditorData;
  rowIndex: number;
  /** for carrousel use as array of card editor */
  carrouselIndex?: number;
  editorType: ParamRichTextProps['editorType'];
  titleEditorStateObject: ParamRichTextProps['editorStateObject'];
  setTitleEditorState: ParamRichTextProps['setEditorState'];
  descriptionEditorStateObject: ParamRichTextProps['editorStateObject'];
  setDescriptionEditorState: ParamRichTextProps['setEditorState'];
  onDelete: () => void;
}

const baseButtonStyle = css`
  display: flex;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  height: 36px;
  font-size: 14px;
  margin-bottom: 8px;
  width: 100%;
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface ButtonProps {
  active?: boolean;
}

const Button = styled(AntButton).withConfig({
  shouldForwardProp: shouldNotForwardProps(['active']),
})<ButtonProps>`
  border: 2px solid ${theme.colors.neutral004};
  color: ${theme.colors.blue006};
  ${baseButtonStyle};
  ${({ active, theme: { colors } }) =>
    active &&
    css`
      border-color: ${colors.blue006};
      background-color: ${colors.blue002};
    `}
  &.is-error {
    border-color: ${theme.colors.red006};
    color: ${theme.colors.red006};
  }
`;

const AddButton = styled(AntButton)`
  color: ${theme.colors.neutral007};
  border-color: ${theme.colors.neutral004};
  border-style: dashed;
  ${baseButtonStyle};
`;

const CardEditorWrapper = styled.div`
  width: 272px;
  background-color: ${theme.colors.white001};
  border-radius: 8px;
  &.is-drag-mode {
    > div:not(.is-drag-mode),
    input {
      pointer-events: none;
    }
  }
`;

const IconWrapper = styled.span`
  position: absolute;
  right: -40px;
  top: 6px;
  opacity: 0;
  transition: 0.25s opacity ease-in-out;
  .editor-delete-icon {
    cursor: pointer;
    margin-left: 4px;
    path {
      transition: 0.25s fill ease-in-out;
    }
    &:hover {
      path {
        fill: ${theme.colors.red006};
      }
    }
  }
`;

const BoxWrapper = styled(Box)`
  position: relative;

  &:hover ${IconWrapper} {
    opacity: 1;
  }
`;
const SpaceWrapper = styled(Space)`
  display: flex;
`;

const Title = styled(Text)`
  font-weight: 500;
`;

const Hint = styled(Text)`
  font-weight: 400;
  color: ${theme.colors.neutral008};
`;

const MAX_BUTTON_COUNT = 4;
const MAX_SHARE_BUTTON_NAME_LENGTH = 40;

const ShareButtonTooltip = () => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={
        <TooltipContainer>
          <TooltipDescription>{t('message.shareButton.tooltip')}</TooltipDescription>
          <TooltipAction href={t('message.shareButton.tooltip.learnMoreUrl')}>
            {t('common.learnMore')}
          </TooltipAction>
        </TooltipContainer>
      }
    />
  );
};

export function CardEditor(props: CardEditorProps) {
  const { store, dispatch } = useContext(Context);
  const { t } = useTranslation();
  const lastHover = useLastHover();
  const authContext = useAuth();

  const createBodyParamTextChangeHandler = useHandler((fieldName: ParamRichTextFieldName) => {
    return function handleBodyParamTextChange(
      rowIndex: number,
      type: DecoratorType,
      mappingKey: string,
    ) {
      dispatch('onCardBodyParamTextChange', {
        rowIndex,
        type,
        mappingKey,
        fieldName,
        carrouselIndex: props.carrouselIndex,
      });
    };
  });
  const handleDeleteEditor = useHandler((deleteRowData: OnDeleteRowData) => {
    deleteRowData();
    props.onDelete();
  });

  return (
    <CardEditorWrapper className={cn({ 'is-drag-mode': store.isDragMode })}>
      <ParamImageEditor
        carrouselIndex={props.carrouselIndex}
        isEmbed={true}
        message={new ImageData().setUrl(props.message.data.contents?.hero?.url ?? '')}
        onUploadDone={({ url, aspectRatio, isAnimated }) => {
          dispatch('setImageUrlBySourceType', {
            rowIndex: props.rowIndex,
            carrouselIndex: props.carrouselIndex,
            url,
            aspectRatio,
            isAnimated,
          });
        }}
        rowIndex={props.rowIndex}
        editorType={props.editorType ?? 'CardEditor'}
        onDelete={handleDeleteEditor}
      />
      <Box padding="24px 16px">
        <Box bg={theme.colors.white001}>
          <SpaceWrapper direction="vertical">
            <Text fontWeight={500} color={theme.colors.neutral010}>
              {t('message.cardEditor.cardTitle')}
            </Text>
            <ParamRichText
              rowIndex={props.rowIndex}
              message={props.message}
              editorStateObject={props.titleEditorStateObject}
              setEditorState={props.setTitleEditorState}
              allowedTextDataTag={defaultAllowedParamTextDataTags}
              onChange={createBodyParamTextChangeHandler('CardTitle')}
              rules={[maxLength(1600), required]}
              fieldName="CardTitle"
              fieldNameValidatorHelper={titleTextNameHelper}
              editorType={props.editorType}
              carouselIndex={props.carrouselIndex}
            />
          </SpaceWrapper>
          <SpaceWrapper direction="vertical">
            <Text fontWeight={500} color={theme.colors.neutral010} mb="8px">
              {t('message.cardEditor.cardDescription')}
            </Text>
            <ParamRichText
              rowIndex={props.rowIndex}
              message={props.message}
              editorStateObject={props.descriptionEditorStateObject}
              setEditorState={props.setDescriptionEditorState}
              allowedTextDataTag={defaultAllowedParamTextDataTags}
              rules={[
                maxLength(1600).bind(null, t('validation.maxCharLength', { count: 1600 })),
                required.bind(
                  null,
                  t('validation.pleaseInputField', { field: lowerFirst(t('message.module.text')) }),
                ),
              ]}
              onChange={createBodyParamTextChangeHandler('CardDescription')}
              fieldName="CardDescription"
              fieldNameValidatorHelper={descriptionTextNameHelper}
              editorType={props.editorType}
              carouselIndex={props.carrouselIndex}
            />
          </SpaceWrapper>
        </Box>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <Box ref={lastHover.parentRef as any}>
          {props.message.data.contents.footer.contents.map(({ key, ...button }, index) => {
            return (
              <BoxWrapper key={index}>
                <CardEditorButton
                  wrapperRef={(s) => lastHover.setHoverRef(s, index)}
                  carrouselIndex={props.carrouselIndex}
                  messageAction={props.message.actions.find((action) => action.key === key)}
                  uriParameter={
                    props.message.parameters.find(
                      (param) =>
                        param.key === key &&
                        param.type === 'standard' &&
                        param.data.function === 'link',
                    ) as LinkParameter
                  }
                  customParameter={
                    props.message.parameters.find(
                      (param) => param.mappingKey === key && param.type === 'custom_string',
                    ) as CustomParameter
                  }
                  shareButtonParameter={
                    props.message.parameters.find(
                      (param) =>
                        param.key === key &&
                        param.type === 'standard' &&
                        param.data.function === 'sharelink',
                    ) as ShareButtonParameter
                  }
                  {...button}
                  notificationText={props.message.data.notification_text}
                  index={index}
                  rowIndex={props.rowIndex}
                  currentChannel={authContext.state.currentChannel}
                />
                {lastHover.index === index &&
                props.message.data.contents.footer.contents.length > 1 ? (
                  <IconWrapper>
                    <StyledIcon
                      className="editor-delete-icon"
                      color={theme.colors.neutral007}
                      width={21}
                      height={21}
                      fontSize={21}
                      component={<DeleteSvg />}
                      onClick={() => {
                        dispatch('deleteCardButton', {
                          rowIndex: props.rowIndex,
                          carrouselIndex: props.carrouselIndex,
                          key,
                        });
                      }}
                    />
                  </IconWrapper>
                ) : null}
              </BoxWrapper>
            );
          })}
          {props.message.data.contents.footer.contents.length < MAX_BUTTON_COUNT ? (
            <AddButton
              data-test="add-card-footer-button"
              onClick={() => {
                dispatch('addCardButton', {
                  rowIndex: props.rowIndex,
                  index: props.carrouselIndex,
                });
                lastHover.clear();
              }}
            >
              + {t('message.addButton')}
            </AddButton>
          ) : null}
        </Box>
      </Box>
    </CardEditorWrapper>
  );
}

interface CardEditorButtonProps extends Omit<ButtonWithKey, 'key'> {
  index: number;
  rowIndex: number;
  notificationText: string;
  messageAction?: TagAction;
  uriParameter?: LinkParameter;
  customParameter?: CustomParameter;
  shareButtonParameter?: ShareButtonParameter;
  carrouselIndex?: number;
  wrapperRef?: Ref<HTMLDivElement>;
  currentChannel: FlatChannel | null;
}

function CardEditorButton(props: CardEditorButtonProps) {
  const { store, dispatch } = useContext(Context);
  const { t } = useTranslation();
  const validatorCtx = useContext(Validator.Context);

  const utm = {
    utm_source: props.uriParameter?.data.utm_source ?? '',
    utm_medium: props.uriParameter?.data.utm_medium ?? '',
    utm_campaign: props.uriParameter?.data.utm_campaign ?? '',
    utm_content: props.uriParameter?.data.utm_content ?? '',
  };

  const uri = props.uriParameter?.data.url ?? '';
  const openExternal = props.uriParameter?.data.open_external_browser ?? false;
  const [open, setOpen] = useState(false);

  const baseDispatchProps = {
    rowIndex: props.rowIndex,
    actionIndex: props.index,
    carrouselIndex: props.carrouselIndex,
  };

  return (
    <Popover
      align={{
        offset: [150, -50],
      }}
      placement="right"
      open={open}
      onOpenChange={(v) => !v && setOpen(v)}
      trigger="click"
      destroyTooltipOnHide={false}
      // This ensures the content is rendered so that the validator can check all fields.
      forceRender={true}
      title={<Title>{t('message.button.setting')}</Title>}
      content={
        <Box width="350px" height="450px" overflow="auto">
          <Validator.FieldScopeProvider>
            <Box pb={24}>
              <SpaceWrapper direction="vertical">
                <Title>{t('message.button.name') + `#${props.index + 1}`}</Title>
                <Validator.PopoverField
                  rules={[Validator.Rules.maxLength(40), Validator.Rules.required]}
                  enableReinitialize={true}
                  name={labelTextNameHelper({
                    rowIndex: props.rowIndex,
                    editorIndex: props.carrouselIndex,
                    entityKey: props.index.toString(),
                    editorType:
                      props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor',
                  })}
                  value={props.action.label}
                  onChange={(e) =>
                    dispatch('onCardActionLabelChange', {
                      ...baseDispatchProps,
                      text: e.target.value,
                    })
                  }
                  placeholder={t(`message.inputWithLengthLimit`, {
                    fieldName: lowerFirst(t('message.button.name')),
                    length: 40,
                  })}
                />
              </SpaceWrapper>
            </Box>
            <Box pb={24}>
              <SpaceWrapper direction="vertical">
                <Title>{t('message.notificationText')}</Title>
                <Hint>{t('message.notificationHint')}</Hint>
                <Validator.PopoverField
                  rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
                  enableReinitialize={true}
                  name={notificationTextNameHelper({
                    rowIndex: props.rowIndex,
                    editorIndex: props.carrouselIndex,
                    entityKey: '',
                    editorType:
                      props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor',
                  })}
                  placeholder={t(`message.inputWithLengthLimit`, {
                    fieldName: lowerFirst(t('message.notificationText')),
                    length: 300,
                  })}
                  value={props.notificationText}
                  onChange={(e) =>
                    dispatch('onNotificationTextChange', {
                      rowIndex: props.rowIndex,
                      text: e.target.value,
                      carrouselIndex: props.carrouselIndex,
                    })
                  }
                  data-test="card-notification-text"
                />
              </SpaceWrapper>
            </Box>
            <Box pb={14}>
              <SpaceWrapper direction="vertical">
                <Title>{t('glossary.actionArea')}</Title>
                <Hint>{t('message.actionAreaHint')}</Hint>
                {isShareButton(props.action.type, props.shareButtonParameter) ? (
                  <MessageShareTargetPickerWarning />
                ) : null}
                {store.hasShareableConflict ? <MessageConflictNotice /> : null}
              </SpaceWrapper>
              <SpaceWrapper direction="vertical">
                <Box mt={16}>
                  <Radio
                    checked={isMessage(props.action.type)}
                    onClick={() => {
                      if (!isMessage(props.action.type))
                        dispatch('onCardTypeChange', {
                          type: 'message',
                          previousType: getPreviousType(props),
                          ...baseDispatchProps,
                        });
                    }}
                    data-test={generateDataTestId(
                      `card-action-button-message-${props.index}`,
                      props.carrouselIndex,
                    )}
                  >
                    {t('message.send')}
                  </Radio>
                </Box>
                <Radio
                  checked={isUri(props.action.type, props.uriParameter)}
                  onClick={() => {
                    if (!isUri(props.action.type, props.uriParameter)) {
                      dispatch('onCardTypeChange', {
                        type: 'uri',
                        previousType: getPreviousType(props),
                        ...baseDispatchProps,
                      });
                    }
                  }}
                  data-test={generateDataTestId(
                    `card-action-button-uri-${props.index}`,
                    props.carrouselIndex,
                  )}
                >
                  {t('common.openUrl')}
                </Radio>
                <Radio
                  checked={isCustomUri(props.action.type, props.customParameter)}
                  onClick={() => {
                    if (!isCustomUri(props.action.type, props.customParameter)) {
                      dispatch('onCardTypeChange', {
                        type: 'customUri',
                        previousType: getPreviousType(props),
                        ...baseDispatchProps,
                      });
                    }
                  }}
                  data-test={generateDataTestId(
                    `card-action-button-customUri-${props.index}`,
                    props.carrouselIndex,
                  )}
                >
                  {t('message.apiUrl')}
                </Radio>
                <Radio
                  checked={isShareButton(props.action.type, props.shareButtonParameter)}
                  onClick={() => {
                    trackGaEvent('messageEditorCardEditorShareButton');
                    if (!isShareButton(props.action.type, props.shareButtonParameter)) {
                      dispatch('onCardTypeChange', {
                        type: 'shareButton',
                        previousType: getPreviousType(props),
                        ...baseDispatchProps,
                      });
                    }
                  }}
                  data-test={generateDataTestId(
                    `card-action-button-share-button-${props.index}`,
                    props.carrouselIndex,
                  )}
                >
                  <SpaceWrapper>
                    <Text mr={1}>{t('message.shareButton')}</Text>
                    <ShareButtonTooltip />
                  </SpaceWrapper>
                </Radio>
              </SpaceWrapper>
            </Box>
            {props.action.type === 'message' && isMessage(props.action.type) ? (
              <>
                <Box pb={2}>
                  <Title>{t('glossary.message')}</Title>
                  <Validator.PopoverFieldTextArea
                    name={messageNameHelper({
                      rowIndex: props.rowIndex,
                      editorIndex: props.carrouselIndex,
                      entityKey: props.index.toString(),
                      editorType:
                        props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor',
                    })}
                    rules={[Validator.Rules.required, Validator.Rules.maxLength(200)]}
                    enableReinitialize={true}
                    placeholder={t(`message.inputWithLengthLimit`, {
                      fieldName: lowerFirst(t('glossary.message')),
                      length: 200,
                    })}
                    value={props.action.text}
                    onChange={(e) =>
                      dispatch('onCardMessageChange', {
                        ...baseDispatchProps,
                        value: e.target.value,
                      })
                    }
                    onBlur={(e) =>
                      dispatch('onCardMessageChange', {
                        ...baseDispatchProps,
                        value: e.target.value,
                      })
                    }
                    wrapperStyle={{ marginTop: 8 }}
                    data-test={generateDataTestId(
                      `card-action-button-message-input-${props.index}`,
                      props.carrouselIndex,
                    )}
                  />
                </Box>
                <TriggerCodeModule
                  currentChannel={props.currentChannel}
                  triggerCode={props.action.text}
                />
              </>
            ) : null}
            {isUri(props.action.type, props.uriParameter) ? (
              <Box>
                <Title>{t('glossary.url')}</Title>
                <UrlUTMModule
                  rowIndex={props.rowIndex}
                  entityKey={props.index.toString()}
                  editorIndex={props.carrouselIndex}
                  urlValue={props.uriParameter?.data.url ?? ''}
                  openExternal={openExternal}
                  onOpenExternalChange={(openExternalValue) => {
                    dispatch('onCardUriChange', {
                      ...baseDispatchProps,
                      value: uri,
                      openExternal: openExternalValue,
                      utm: {
                        ...utm,
                      },
                    });
                  }}
                  onFieldsChange={(uriValue, utmFields) =>
                    dispatch('onCardUriChange', {
                      ...baseDispatchProps,
                      value: uriValue,
                      openExternal,
                      utm: {
                        ...utmFields,
                      },
                    })
                  }
                  UTMSourceValue={utm.utm_source}
                  onUTMSourceChange={(value) =>
                    dispatch('onCardUriChange', {
                      ...baseDispatchProps,
                      value: uri,
                      openExternal,
                      utm: {
                        ...utm,
                        utm_source: value,
                      },
                    })
                  }
                  UTMMediumValue={utm.utm_medium}
                  onUTMMediumChange={(value) =>
                    dispatch('onCardUriChange', {
                      ...baseDispatchProps,
                      value: uri,
                      openExternal,
                      utm: {
                        ...utm,
                        utm_medium: value,
                      },
                    })
                  }
                  UTMCampaignValue={utm.utm_campaign}
                  onUTMCampaignChange={(value) =>
                    dispatch('onCardUriChange', {
                      ...baseDispatchProps,
                      value: uri,
                      openExternal,
                      utm: {
                        ...utm,
                        utm_campaign: value,
                      },
                    })
                  }
                  UTMContentValue={utm.utm_content}
                  onUTMContentChange={(value) =>
                    dispatch('onCardUriChange', {
                      ...baseDispatchProps,
                      value: uri,
                      openExternal,
                      utm: {
                        ...utm,
                        utm_content: value,
                      },
                    })
                  }
                  tagList={props.uriParameter?.data.tag_list ?? []}
                  onTagChange={(tagList) =>
                    dispatch('onCardTagChange', {
                      ...baseDispatchProps,
                      tagList,
                    })
                  }
                  editorType={props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor'}
                  data-test={generateDataTestId(
                    `card-action-button-uri-input-${props.index}`,
                    props.carrouselIndex,
                  )}
                />
              </Box>
            ) : null}
            {isCustomUri(props.action.type, props.customParameter) ? (
              <Box>
                <Title>{t('message.apiParameterName')}</Title>
                <Validator.PopoverField
                  rules={[Validator.Rules.customParameter, Validator.Rules.required]}
                  enableReinitialize={true}
                  name={customNameHelper({
                    rowIndex: props.rowIndex,
                    editorIndex: props.carrouselIndex,
                    entityKey: props.index.toString(),
                    editorType:
                      props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor',
                  })}
                  value={props.customParameter.key}
                  onChange={(e) =>
                    dispatch('onCardCustomUriChange', {
                      ...baseDispatchProps,
                      value: e.target.value,
                    })
                  }
                  style={{ imeMode: 'disabled' }}
                  placeholder={t('common.inputFieldName', {
                    fieldName: lowerFirst(t('message.apiParameterName')),
                  })}
                  wrapperStyle={{ marginTop: 8 }}
                  data-test={generateDataTestId(
                    `card-action-button-customUri-input-${props.index}`,
                    props.carrouselIndex,
                  )}
                />
              </Box>
            ) : null}
            {isShareButton(props.action.type, props.shareButtonParameter) ? (
              <SpaceWrapper direction="vertical">
                <Title>{t('message.shareButton.name')}</Title>
                <Hint>{t('message.shareButton.hint')}</Hint>
                <Validator.FieldInput
                  name={shareButtonNameHelper({
                    rowIndex: props.rowIndex,
                    editorIndex: props.carrouselIndex,
                    entityKey: props.index.toString(),
                    editorType:
                      props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor',
                  })}
                  rules={[
                    Validator.Rules.required,
                    Validator.Rules.maxLength(MAX_SHARE_BUTTON_NAME_LENGTH),
                  ]}
                  checkOnChange={true}
                  enableReinitialize={true}
                  placeholder={t('common.inputFieldName', {
                    fieldName: lowerFirst(t('message.shareButton.name')),
                    length: MAX_SHARE_BUTTON_NAME_LENGTH,
                  })}
                  value={props.shareButtonParameter.data.name}
                  onChange={(e) => {
                    dispatch('onCardShareButtonChange', {
                      ...baseDispatchProps,
                      value: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    dispatch('onCardShareButtonChange', {
                      ...baseDispatchProps,
                      value: e.target.value,
                    });
                  }}
                />
                <TagPickerByName
                  tagList={props.shareButtonParameter.data.tag_list}
                  onTagListChange={(tagList) => {
                    dispatch('onCardTagChange', {
                      ...baseDispatchProps,
                      tagList,
                    });
                  }}
                />
              </SpaceWrapper>
            ) : null}
          </Validator.FieldScopeProvider>
        </Box>
      }
    >
      <div
        data-test={generateDataTestId(`card-action-button-${props.index}`, props.carrouselIndex)}
        ref={props.wrapperRef}
        onClick={() => setOpen(true)}
      >
        <Button
          className={
            Validator.isInvalid(
              validatorCtx.invalid[
                messageNameHelper({
                  rowIndex: props.rowIndex,
                  editorIndex: props.carrouselIndex,
                  entityKey: props.index.toString(),
                  editorType: props.carrouselIndex === undefined ? 'CardEditor' : 'CarrouselEditor',
                })
              ],
            )
              ? 'is-error'
              : undefined
          }
        >
          {props.action.label}
        </Button>
      </div>
    </Popover>
  );
}

function isMessage(type: CardButtonType) {
  return type === 'message';
}
function isUri(type: CardButtonType, parameter?: LinkParameter): parameter is LinkParameter {
  return type === 'uri' && typeof parameter !== 'undefined';
}
function isCustomUri(
  type: CardButtonType,
  parameter?: CustomParameter,
): parameter is CustomParameter {
  return type === 'uri' && typeof parameter !== 'undefined';
}
function isShareButton(
  type: CardButtonType,
  parameter?: ShareButtonParameter,
): parameter is ShareButtonParameter {
  return type === 'uri' && typeof parameter !== 'undefined';
}
function getPreviousType({
  action: { type },
  uriParameter,
  customParameter,
  shareButtonParameter,
}: CardEditorButtonProps): CardButtonType {
  if (isUri(type, uriParameter)) return 'uri';
  if (isCustomUri(type, customParameter)) return 'customUri';
  if (isShareButton(type, shareButtonParameter)) return 'shareButton';
  return 'message';
}
