import styled from '@emotion/styled';

import type { ReactNode } from 'react';

import { theme } from 'theme';

interface FieldsProps {
  title?: ReactNode;
  style?: React.CSSProperties;
  children?: ReactNode;
}

interface FieldsWrapperProps {
  style?: React.CSSProperties;
  children?: ReactNode;
}

export const FieldTitleWrapper = styled.div`
  line-height: 20px;
  font-weight: 500;
  color: ${theme.colors.neutral010};
  text-align: left;
  margin-bottom: 8px;
`;

export const Fields = ({ children, title = '', ...props }: FieldsProps) => {
  return (
    <div
      style={{
        marginBottom: '24px',
        width: '338px',
        ...props.style,
      }}
    >
      <FieldTitleWrapper>{title}</FieldTitleWrapper>
      <div>{children}</div>
    </div>
  );
};

export const FieldsSecondaryTitle = ({ children = '', ...props }: FieldsWrapperProps) => {
  return (
    <div
      style={{
        marginBottom: '8px',
        lineHeight: '20px',
        color: theme.colors.neutral010,
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};

export const FieldsDescription = ({ children = '', ...props }: FieldsWrapperProps) => {
  return (
    <div
      style={{
        marginBottom: '8px',
        lineHeight: '20px',
        color: theme.colors.neutral008,
        ...props.style,
      }}
    >
      {children}
    </div>
  );
};
