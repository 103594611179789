/* eslint-disable @typescript-eslint/no-explicit-any -- fine */
import { useFormContext as vanilla_useFormContext } from 'react-hook-form';

import type { UseFormSetValue } from './types/form';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

/**
 * A fixed type for the `useFormContext` hook.
 *
 * - setValue: Prevented infinite recursion.
 *
 * @see {@link vanilla_useFormContext}
 */
function useFormContext<
  TFieldValues extends FieldValues,
  TContext = any,
  TransformedValues extends FieldValues | undefined = undefined,
>(): Omit<UseFormReturn<TFieldValues, TContext, TransformedValues>, 'setValue'> & {
  setValue: UseFormSetValue<TFieldValues>;
  onSubmit: () => Promise<TransformedValues>;
} {
  return vanilla_useFormContext() as any;
}

export { useFormContext };
