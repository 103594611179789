import type { Rule } from './type';

import { i18n } from 'modules/G11n/i18n';
import { isTelUrl } from 'utils/url/isTelUrl';

const pattern = new RegExp(
  '(^line://.{1})|(^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$)',
  'i',
);

export function isUrl(value: unknown): boolean {
  const str = String(value);
  return isTelUrl(str) || !!str.match(pattern);
}

export const url: Rule = (message) => ({
  validate: (value: string | number) => !value || isUrl(value),
  hint: () => message || i18n.t('validation.pleaseInputValidUrl'),
  errorType: 'InvalidURL',
});
