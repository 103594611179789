import axios from 'axios';

import { API_HOST } from 'env';
import {
  HEADER_BOT_ID,
  HEADER_ORG_ID,
  HEADER_USER_ID,
  LOCAL_STORAGE_LAST_CHANNEL,
  LOCAL_STORAGE_ORG_ID,
  LOCAL_STORAGE_TOKEN,
  LOCAL_STORAGE_USER_ID,
} from 'AppConstants';

import type { AxiosRequestConfig } from 'axios';

import { isUserDetailAPI } from 'context/authContext/utils';
import { isAxiosError } from 'lib/axios';
import { rubatoErrorEventEmitter } from 'lib/axios/rubato/rubatoErrorEventEmitter';
import { isURLSameOrigin } from 'lib/helpers/isURLSameOrigin';
import { logError } from 'lib/logger';

import { GREEN } from '../enum';
import { logger } from '../utils/loggerHelper';
import { isAuthenticateFail, isResourceGone } from '../utils/response';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const authAxios = axios.create();

const getAuthHeader = () => {
  const token = localStorage.getItem(LOCAL_STORAGE_TOKEN);
  if (!token || !token.length) {
    return;
  }
  return { Authorization: ['Bearer', token].join(' ') };
};

const getBotIdHeader = () => {
  const botId = localStorage.getItem(LOCAL_STORAGE_LAST_CHANNEL);
  if (!botId) {
    return;
  }
  return { [HEADER_BOT_ID]: botId };
};

const getUserIdHeader = () => {
  const userId = localStorage.getItem(LOCAL_STORAGE_USER_ID);
  if (!userId) {
    return;
  }
  return { [HEADER_USER_ID]: userId };
};

const getOrgIdHeader = () => {
  const orgId = localStorage.getItem(LOCAL_STORAGE_ORG_ID);
  if (!orgId) {
    return;
  }
  return { [HEADER_ORG_ID]: orgId };
};

const buildRequestHeaders = (headers: AxiosRequestConfig['headers']) => {
  return Object.assign(
    {},
    headers,
    getAuthHeader(),
    getBotIdHeader(),
    getUserIdHeader(),
    getOrgIdHeader(),
  );
};

authAxios.interceptors.request.use((config) => {
  if (typeof config.url === 'string' && isURLSameOrigin(config.url)) {
    config.headers = buildRequestHeaders(config.headers);

    config.baseURL = API_HOST;
  }
  return config;
});

authAxios.interceptors.response.use(
  (response) => {
    logger(response, GREEN);
    return response;
  },
  (error) => {
    if (isAxiosError(error)) {
      if (isAuthenticateFail(error) && !isUserDetailAPI(error.response.config.url ?? '')) {
        rubatoErrorEventEmitter.emit('authenticationFailedError', error);
      }
      if (isResourceGone(error)) {
        logError(error);
      }
    }
    return Promise.reject(error);
  },
);

export const AuthAxios = authAxios;
