import Form from 'antd/es/form';
import { Trans, useTranslation } from 'react-i18next';

import type { FormItemProps } from 'antd/es/form';
import type { BoxProps } from 'components/layoutUtils';
import type { ReactNode } from 'react';

import { Box } from 'components/layoutUtils';
import { Text } from 'components/Typography';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface FormFieldProps extends BoxProps {
  title?: ReactNode;
  description?: ReactNode;
  isOptional?: boolean;
  children?: ReactNode;
}

interface FormFieldDescriptionProps extends BoxProps {
  children?: ReactNode;
}

export const FormFieldTitle = styled.div`
  line-height: 20px;
  font-weight: 500;
  color: ${theme.colors.neutral010};
  text-align: left;
  margin-bottom: 8px;
`;

/**
 * Standard pattern for optional fields; not to be used directly
 */
const FormFieldOptional = ({ title }: { title: ReactNode }) => {
  const { t } = useTranslation();

  return (
    <Trans
      i18nKey="common.fieldLabelWithType"
      values={{
        fieldLabel: title,
        fieldType: t('common.fieldOptional'),
      }}
      components={{
        FieldType: <Text color={theme.colors.neutral007} />,
      }}
    />
  );
};

/**
 * The original `Fields` component is too narrow; this one is a little wider and more flexible
 * It also offers the `isOptional` prop
 */
export const FormField = ({
  children,
  title,
  description,
  isOptional,
  ...styledBoxRest
}: FormFieldProps) => {
  return (
    <Box mb="24px" width={theme.sizes.formFieldWidth} {...styledBoxRest}>
      <FormFieldTitle>{isOptional ? <FormFieldOptional title={title} /> : title}</FormFieldTitle>
      {description ? <FormFieldDescription>{description}</FormFieldDescription> : null}
      <div>{children}</div>
    </Box>
  );
};

/**
 * Standard form section; use this to show a form section subheading
 */
export const FormFieldSection = ({ title }: { title?: ReactNode }) => (
  <Text color={theme.colors.neutral010} fontSize={18} fontWeight={500} mb="12px" display="block">
    {title}
  </Text>
);

/**
 * Standard form field descriptions; use this to provide additional information
 */
export const FormFieldDescription = ({
  children = '',
  ...styledBoxRest
}: FormFieldDescriptionProps) => {
  return (
    <Box mb="8px" lineHeight="20px" color={theme.colors.neutral008} {...styledBoxRest}>
      {children}
    </Box>
  );
};

interface FormFieldItemProps extends FormItemProps {
  error?: string;
}

export const FormFieldItem = ({ error, children, ...props }: FormFieldItemProps) => (
  <Form.Item
    colon={false}
    help={error}
    style={{ marginBottom: 0 }}
    validateStatus={!!error ? 'error' : 'success'}
    {...props}
  >
    {children}
  </Form.Item>
);
