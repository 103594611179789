import Switch from 'antd/es/switch';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { MouseEvent, ReactNode } from 'react';

import { Flex } from 'components/layoutUtils';
import { Button } from 'shared/components/Button';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface ContainerProps {
  complete: boolean;
  enable: boolean;
  isEditing: boolean;
}

interface UnStyledContainerProps extends ContainerProps {
  className?: string;
}

const UnStyledContainer = ({
  className,
  children,
}: UnStyledContainerProps & { children: ReactNode }) => <div className={className}>{children}</div>;

const ToggleCollapseWrap = styled(UnStyledContainer).withConfig({
  shouldForwardProp: shouldNotForwardProps(['complete', 'enable', 'isEditing']),
})<ContainerProps>`
  background-color: ${theme.colors.white001};
  display: flex;
  flex-direction: column;
  min-height: 68px;
  border-radius: 4px;
  border: solid 1px
    ${({ isEditing, complete, enable }) => {
      if (isEditing && enable) return theme.colors.blue006;
      else if (!complete && enable) return '#f04f47';
      return theme.colors.neutral005;
    }};
`;

const ToggleCollapseBar = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 18px 20px 18px 24px;
`;

const ToggleCollapseTitle = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  max-width: 220px;
  color: ${theme.colors.neutral010};
`;

const ToggleCollapseContents = styled.div`
  padding: 18px 20px 18px 24px;
  border-top: 1px solid ${theme.colors.neutral005};
`;

const ToggleCollapseSwitch = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
`;

export const ToggleCollapse = memo(
  ({
    title,
    description,
    className,
    complete,
    showSwitch = true,
    switchTestId,
    handleToggle,
    handleClick,
    isEditing,
    enable,
    disable,
    children,
  }: {
    title?: ReactNode;
    description?: ReactNode;
    className?: string;
    complete: boolean;
    showSwitch?: boolean;
    switchTestId?: string;
    handleToggle: (checked: boolean) => void;
    handleClick: (e: MouseEvent<HTMLDivElement>) => void;
    isEditing: boolean;
    enable: boolean;
    disable: boolean;
    children: ReactNode;
  }) => {
    const { t } = useTranslation();

    return (
      <ToggleCollapseWrap
        className={className}
        complete={complete}
        enable={enable}
        isEditing={isEditing}
      >
        <ToggleCollapseBar>
          <ToggleCollapseSwitch>
            <ToggleCollapseTitle>{title}</ToggleCollapseTitle>
            {showSwitch ? (
              <Switch
                checked={enable}
                checkedChildren={t('glossary.switchOn')}
                unCheckedChildren={t('glossary.switchOff')}
                onChange={handleToggle}
                data-test={switchTestId}
              />
            ) : null}
            {description}
          </ToggleCollapseSwitch>
          <Flex alignItems="center">
            <Button
              type={isEditing ? 'primary' : 'default'}
              onClick={handleClick}
              disabled={disable}
            >
              {isEditing && enable ? t('glossary.done') : t('common.edit')}
            </Button>
          </Flex>
        </ToggleCollapseBar>
        {isEditing && enable ? <ToggleCollapseContents>{children}</ToggleCollapseContents> : null}
      </ToggleCollapseWrap>
    );
  },
);

export const ToggleCollapseWrapper = memo(
  ({
    title,
    children,
    enable,
    switchComponent,
  }: {
    title?: ReactNode;
    switchComponent?: ReactNode;
    enable: boolean;
    children: ReactNode;
  }) => {
    return (
      <ToggleCollapseWrap complete={false} enable={enable} isEditing={true}>
        <ToggleCollapseBar>
          <ToggleCollapseSwitch>
            <ToggleCollapseTitle>{title}</ToggleCollapseTitle>
            {switchComponent}
          </ToggleCollapseSwitch>
        </ToggleCollapseBar>
        <ToggleCollapseContents style={{ display: enable ? 'block' : 'none' }}>
          {children}
        </ToggleCollapseContents>
      </ToggleCollapseWrap>
    );
  },
);
