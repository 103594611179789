import type { MutableRefObject } from 'react';

import { getResizedImage } from 'components/LineMessageEditor/utils/getResizedImage';
import { uploadMultipleImageSizes, useCategorizedFilePath } from 'lib/fileUpload';

export const useImageMapImageUpload = (
  fileRef: MutableRefObject<File | undefined>,
  uploadedCallback: (uploadUrl: string, height: number) => void,
): (() => Promise<void>) => {
  const filePath = useCategorizedFilePath();
  return async () => {
    if (fileRef.current) {
      const file = fileRef.current;
      const img = new Image();
      const objectUrl = window.URL.createObjectURL(file);
      img.src = objectUrl;
      img.onload = async () => {
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;
        const height = (imgHeight / imgWidth) * 1040;
        const uploadUrl = await uploadImage(file, objectUrl, filePath);
        uploadedCallback(uploadUrl, height);
      };
    }
  };
};

const uploadImage = async (file: File, objectUrl: string, filePath: string) => {
  const { blob: resizedFile700 } = await getResizedImage(objectUrl, 700);
  const results = await uploadMultipleImageSizes(
    [
      { file, functionName: 'Broadcast', width: 1040 },
      {
        file: resizedFile700,
        functionName: 'Broadcast',
        width: 700,
      },
    ],
    filePath,
  );

  const uploadUrl = results[0];
  const firebaseImg = new Image();
  firebaseImg.src = uploadUrl;
  await firebaseImg.decode();
  return uploadUrl;
};
