/* eslint-disable @typescript-eslint/explicit-module-boundary-types -- Annoying rules. */

import type { JsonValue } from 'type-fest';

/**
 *
 * Parse a JSON string with strict type.
 * @param input - The JSON string to parse.
 * @param fallback - Optional. The fallback value to return if the JSON string is invalid.
 * @example
 *
 * ```ts
 *  // ✅ Returns type `JsonValue` which is stricter than `unknown`.
 *  // ✅ Fallback specified value is returned if `input` is not a valid JSON string.
 *  const output = parseJson(input, null);
 *  //    ^? const output: JsonValue
 *  if (typeof output === 'function') {
 *    output;
 *    // ^? const output: never
 *  }
 *  if (output === undefined) {
 *    output;
 *    // ^? const output: never
 *  }
 *
 *  // ❌ Return any type.
 *  // ❌ Might throw an error if `input` is not a valid JSON string.
 *  const vanillaOutput = JSON.parse(input);
 *  //    ^? const vanillaOutput: any
 * ```
 */
function parseJson(input: string): JsonValue;
function parseJson<T>(input: string, fallback: T): JsonValue | T;
function parseJson<T>(input: string, ...args: [] | [T]): JsonValue | T {
  try {
    return JSON.parse(input) as JsonValue;
  } catch (error) {
    if (args.length === 0) throw error;
    return args[0] as T;
  }
}

export { parseJson };
