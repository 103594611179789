import { safeString } from '@chatbotgang/etude/string/safeString';
import debug from 'debug';
import { useCallback, useContext, useEffect } from 'react';

import type { InValid, ValidateResult, ValidatorState } from './context';
import type { Rule } from './rules/type';
import type { ChangeEvent, ChangeEventHandler, FocusEvent, FocusEventHandler } from 'react';

import { usePrevious } from 'hooks/usePrevious';

import { useRegister } from './hooks/useRegister';
import { Context } from './context';

const log = debug('validator:useField');

interface useFieldProps {
  name: string;
  rules: Rule[];
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: FocusEventHandler;
  value?: string;
  onError?: (error: boolean) => void;
  enableReinitialize?: boolean;
  checkOnChange?: boolean;
}
export interface useFieldResult {
  update: (val: string, id?: string) => void;
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  validate: (value?: string) => {
    valid: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    firstError: any;
  };
  invalid: ValidatorState['invalid'][''];
  value: string;
}

export const useField = (props: useFieldProps): useFieldResult => {
  const context = useContext(Context);
  return useFieldContext(context, props);
};

const useFieldContext = (
  {
    register,
    unregister,
    updateValue,
    invalid,
    values,
    checkOnBlur,
    validate,
    reRegister,
  }: ValidatorState,
  props: useFieldProps,
): {
  onBlur: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  invalid: InValid | undefined;
  update: (val: string, id?: string) => void;
  value: string;
  validate: (value?: string) => ValidateResult;
} => {
  useRegister({
    reRegister,
    register,
    unregister,
    values,
    enableReinitialize: props.enableReinitialize,
    name: props.name,
    rules: props.rules,
    value: safeString(props.value || ''),
  });

  const previousError = usePrevious(invalid[props.name]);

  useEffect(() => {
    if (previousError !== invalid[props.name]) {
      if (props.onError) {
        let error = false;
        log('onError', props.name, invalid[props.name]);
        if (!!invalid[props.name] && !invalid[props.name]?.valid) {
          error = true;
        }
        props.onError(error);
      }
    }
  }, [invalid, previousError, props]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (props.onChange) {
        props.onChange({
          ...e,
          target: {
            ...e.target,
            value: safeString(e.target.value),
          },
        });
        updateValue(props.name, safeString(e.target.value));
      }
      if (props.checkOnChange) {
        validate({
          name: props.name,
          value: e.target.value,
        });
      }
    },
    [props, updateValue, validate],
  );

  const onBlur = useCallback(
    (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (props.onBlur) {
        props.onBlur(e);
      }
      if (checkOnBlur && validate) {
        validate({
          name: props.name,
          value: e.target.value,
        });
      }
    },
    [checkOnBlur, props, validate],
  );

  return {
    update: (val: string, id = props.name) => updateValue(id, safeString(val)),
    onBlur,
    onChange,
    validate: (value?: string) => validate({ name: props.name, value: value || props.value || '' }),
    invalid: invalid[props.name],
    value:
      typeof values[props.name] === 'string' ? safeString(values[props.name]) : values[props.name],
  };
};
