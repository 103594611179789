import { rgba } from 'polished';
import { background, layout, position, width } from 'styled-system';

import type { BackgroundProps, LayoutProps, PositionProps, WidthProps } from 'styled-system';

import { keyframes, styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

export const uiBorderWidth = 2;
const show = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

export const ImageMapCustomWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(44, 56, 72, 0.5);
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  animation: ${show} 0.5s ease-in-out;
  overflow-y: auto;
`;

export const ImageWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<LayoutProps & BackgroundProps & PositionProps>`
  ${layout}
  ${position}
  ${background}
  border-radius: 8px;
  border: ${uiBorderWidth}px solid ${theme.colors.blue006};
`;

export const FinishWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<PositionProps & WidthProps>`
  ${position}
  ${width}
  min-height: 104px;
  line-height: 22px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px;
  box-shadow: ${theme.boxShadow.default};
  background-color: ${theme.colors.white001};
  text-align: left;
`;

export const PreviewBlock = styled.div`
  border: 2px solid #00ffec;
  z-index: 1;
  background-color: ${rgba('#00ffec', 0)};
  transition: background-color 0.25s ease-in-out;
  cursor: grab;
  .imageMap-delete-icon-wrapper {
    transition: 0.25s opacity ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    z-index: 10;
    .imageMap-delete-icon-wrapper {
      z-index: 2;
      opacity: 1;
      pointer-events: auto;
    }
    &::before {
      pointer-events: auto;
    }
    background-color: ${rgba('#00ffec', 0.3)};
  }
  &::before {
    content: '';
    width: 21px;
    height: 100%;
    background-color: transparent;
    position: absolute;
    left: 100%;
    right: 0;
    z-index: 1;
    pointer-events: none;
  }
`;

export const MxNBlock = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  color: ${theme.colors.blue006};
  border: 1px solid ${theme.colors.blue006};
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 12px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
`;
