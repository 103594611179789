import { maacFirebase } from 'lib/firebase/main';

/**
 * Get downloadable file URL from storage service
 * @param appName specified storage App name
 * @param path specified file path in the storage
 * @returns downloadable file URL
 */
export const getDownloadURL = (appName: string, path: string): Promise<string> =>
  maacFirebase.getDownloadURL(appName, path);
