import { motion } from 'framer-motion';

import type { Variants } from 'framer-motion';

import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const cardVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: { opacity: 0, y: 20 },
};

export const EnlargeCard = styled(motion.div).attrs(() => ({ variants: cardVariants }))`
  position: relative;
  border-radius: 4px;
  border: solid 1px ${theme.colors.neutral004};
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 16px;
  transition:
    border-color 0.25s ease-in-out,
    transform 0.25s ease-in-out;
  will-change: border-color, transform;
  font-size: 14px;
  line-height: 20px;
  color: ${theme.colors.neutral010};
  &:hover {
    border-color: ${theme.colors.blue006};
    transform: scale(1.02);
  }
`;
