export const isURLSameOrigin = (requestURL: string): boolean => {
  const msie = /(msie|trident)/i.test(navigator.userAgent);
  const urlParsingNode = document.createElement('a');

  /**
   * Parse a URL to discover its components
   *
   * @param {String} url The URL to be parsed
   * @returns {Object}
   */
  function resolveURL(url: string) {
    let href = url;

    if (msie) {
      // IE needs attribute set twice to normalize properties
      urlParsingNode.setAttribute('href', href);
      href = urlParsingNode.href;
    }

    urlParsingNode.setAttribute('href', href);

    // urlParsingNode provides the UrlUtils interface - http://url.spec.whatwg.org/#urlutils
    return {
      href: urlParsingNode.href,
      protocol: urlParsingNode.protocol ? urlParsingNode.protocol.replace(/:$/, '') : '',
      host: urlParsingNode.host,
      search: urlParsingNode.search ? urlParsingNode.search.replace(/^\?/, '') : '',
      hash: urlParsingNode.hash ? urlParsingNode.hash.replace(/^#/, '') : '',
      hostname: urlParsingNode.hostname,
      port: urlParsingNode.port,
      pathname:
        urlParsingNode.pathname.charAt(0) === '/'
          ? urlParsingNode.pathname
          : '/' + urlParsingNode.pathname,
    };
  }

  const originURL = resolveURL(window.location.href);

  const parsed = typeof requestURL === 'string' ? resolveURL(requestURL) : requestURL;
  return parsed.protocol === originURL.protocol && parsed.host === originURL.host;
};
