import { styled } from 'shared/utils/styled';

/**
 * Specify the default type faces for the App slogan.
 * We specify different type faces for different languages.
 * This slogan's type face should be fixed and not affected by the language to avoid UI flicks
 */
export const SloganBasic = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;
`;
