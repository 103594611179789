import type { ValidateResult } from '../context';

export enum EEditorType {
  RichTextEditor = 'RichTextEditor',
  ParamRichTextEditor = 'ParamRichTextEditor',
  ImageEditor = 'ImageEditor',
  ImageMapEditor = 'ImageMapEditor',
  ImageMapCarouselEditor = 'ImageMapCarouselEditor',
  CardEditor = 'CardEditor',
  CarrouselEditor = 'CarrouselEditor',
  ImageCarouselEditor = 'ImageCarouselEditor',
  VideoEditor = 'VideoEditor',
  QuickReplyEditor = 'QuickReplyEditor',
  NewsEditor = 'NewsEditor',
  PrizeRedeemEditor = 'PrizeRedeemEditor',
}

export type EditorType = keyof typeof EEditorType;

export const editorTypeTranslationKeyMap: Record<EditorType, string> = {
  RichTextEditor: 'message.module.text',
  ParamRichTextEditor: 'message.module.text',
  ImageEditor: 'glossary.image',
  ImageMapEditor: 'message.module.imageMap',
  ImageMapCarouselEditor: 'message.module.imageMapCarousel',
  CardEditor: 'message.module.card',
  CarrouselEditor: 'message.module.carrousel',
  ImageCarouselEditor: 'message.module.imageCarrousel',
  VideoEditor: 'message.module.video',
  PrizeRedeemEditor: 'glossary.prize',
  NewsEditor: 'glossary.breakingNews',
  QuickReplyEditor: 'message.quickReply',
};

export enum EErrorField {
  'RichEditor' = 'RichEditor',
  'ParamRichTextEditor' = 'ParamRichTextEditor',
  'CustomParameter' = 'CustomParameter',
  'TitleText' = 'TitleText',
  'DescriptionText' = 'DescriptionText',
  'LabelText' = 'LabelText',
  'NotificationText' = 'NotificationText',
  'Message' = 'Message',
  'ActionButtonText' = 'ActionButtonText',
  'URL' = 'URL',
  'UTMSource' = 'UTMSource',
  'UTMMedium' = 'UTMMedium',
  'UTMCampaign' = 'UTMCampaign',
  'UTMContent' = 'UTMContent',
  'RSSUrl' = 'RSSUrl',
  'UploadImage' = 'UploadImage',
  'UploadVideo' = 'UploadVideo',
  'SelectPrize' = 'SelectPrize',
  'CardTitle' = 'CardTitle',
  'CardDescription' = 'CardDescription',
  'ShareButtonName' = 'ShareButtonName',
}

export type ErrorField = keyof typeof EErrorField;

export const errorFieldTranslationKey: Record<ErrorField, string> = {
  RichEditor: 'message.module.text',
  ParamRichTextEditor: 'message.module.text',
  CardTitle: 'message.cardEditor.cardTitle',
  CardDescription: 'message.cardEditor.cardDescription',
  CustomParameter: 'glossary.apiParameter',
  TitleText: 'glossary.title',
  DescriptionText: 'glossary.description',
  LabelText: 'message.button.name',
  NotificationText: 'message.notificationText',
  Message: 'glossary.message',
  ActionButtonText: 'message.button.name',
  URL: 'glossary.url',
  UTMSource: 'glossary.utmSource',
  UTMMedium: 'glossary.utmMedium',
  UTMCampaign: 'glossary.utmCampaign',
  UTMContent: 'glossary.utmContent',
  RSSUrl: 'message.rssUrl',
  UploadImage: 'glossary.image',
  UploadVideo: 'message.module.video',
  SelectPrize: 'glossary.prize',
  ShareButtonName: 'message.shareButton.name',
};

export interface ValidatorNameHelper {
  /** Indicate which row of the messages */
  rowIndex: number;
  /** Indicate which action in a editor in a message row */
  entityKey: string;
  editorType: EditorType;
  /** Which editor item in a message row. For example, "3" in CarrouselEditor means the third item in a Carrousel */
  editorIndex?: number;
}

export const richTextNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.RichEditor}${payload.entityKey}`;
};

export const paramRichTextNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.ParamRichTextEditor}${payload.entityKey}`;
};

export const messageNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.Message}${payload.entityKey}`;
};

export const shareButtonNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.ShareButtonName}${payload.entityKey}`;
};

export const notificationTextNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.NotificationText}${payload.entityKey}`;
};

export const rssUrlNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${payload.editorType}_${EErrorField.RSSUrl}${payload.entityKey}`;
};

export const uploadImageHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.UploadImage}${payload.entityKey}`;
};

export const uploadVideoHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.UploadVideo}${payload.entityKey}`;
};

export const videoActionButtonTextHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.ActionButtonText}${payload.entityKey}`;
};

export const titleTextNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.TitleText}${payload.entityKey}`;
};

export const descriptionTextNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.DescriptionText}${payload.entityKey}`;
};

export const labelTextNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.LabelText}${payload.entityKey}`;
};

export const customNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.CustomParameter}${payload.entityKey}`;
};

export const urlNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.URL}${payload.entityKey}`;
};

export const sourceUTMNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.UTMSource}${payload.entityKey}`;
};

export const mediumUTMNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.UTMMedium}${payload.entityKey}`;
};

export const campaignUTMNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.UTMCampaign}${payload.entityKey}`;
};

export const contentUTMNameHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.UTMContent}${payload.entityKey}`;
};

export const selectPrizeHelper = (payload: ValidatorNameHelper): string => {
  return `Row${payload.rowIndex}_${
    payload.editorType + (payload.editorIndex === undefined ? '' : payload.editorIndex)
  }_${EErrorField.SelectPrize}${payload.entityKey}`;
};

export const errorMessageHelper = (result: ValidateResult): string => {
  return result.valid
    ? ''
    : result.firstError.id +
        '|' +
        (result.firstError.errorKey || '') +
        '_' +
        result.firstError.errorMessage;
};
