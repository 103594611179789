/* eslint-disable no-restricted-imports -- Required for this component */
import styled from '@emotion/styled';
import AntCheckbox from 'antd/es/checkbox';

export type { CheckboxChangeEvent } from 'antd/es/checkbox';

export const Checkbox = styled(AntCheckbox)`
  @layer styled-component {
    .ant-checkbox {
      align-self: auto;
      top: 4px;
    }
  }
`;

/**
 * Standard wrapper for vertical groups of checkboxes
 * Note: if you don't want the vertical layout, use `Checkbox.Group` directly
 */
export const CheckboxGroup = styled(AntCheckbox.Group)`
  @layer styled-component {
    & {
      display: inline-block;
    }
  }
`;
