/* eslint-disable no-restricted-imports */
import Form from 'antd/es/form';
import AntRadio from 'antd/es/radio';

import type { FormItemProps } from 'antd/es/form';
import type { ReactNode } from 'react';

import { styled } from 'shared/utils/styled';

export type { RadioChangeEvent } from 'antd/es/radio';

export const Radio = styled(AntRadio)`
  @layer styled-component {
    .ant-radio {
      align-self: auto;
      top: 4px;
    }
  }
`;

/**
 * Standard wrapper for vertical groups of radio buttons
 * No need to individually space children
 * Note: if you don't want the vertical layout, use `Radio.Group` directly
 */
export const RadioGroup = styled(AntRadio.Group)`
  @layer styled-component {
    /**
   * Default display from antd is inline-block. Keep it inline as default.
   */
    & {
      display: inline-flex;
      flex-direction: column;
      gap: 12px;
      .ant-radio {
        /**
        * To make the radio input align with the first line of the text
        */
        align-self: auto;
        top: 4px;
      }
    }
  }
`;

/**
 * Standard wrapper for horizontal groups of radio buttons with additional spacing
 */
export const HoriZontalRadioGroup = styled(AntRadio.Group)`
  & {
    display: inline-flex;
    gap: 12px;
    .ant-radio {
      /**
      * To make the radio input align with the first line of the text
      */
      align-self: auto;
      top: 2px;
    }
  }
`;

/**
 * Use this to nest `Form.Item` components in `Radio` components
 * It is intended for cases where clicks propagate and revert user action
 * Fix for an Ant Design bug: https://github.com/ant-design/ant-design/issues/25959
 */
export const RadioNestedFormItem = ({
  children,
  ...props
}: FormItemProps & { children: ReactNode }) => (
  <span
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
    }}
  >
    <Form.Item {...props}>{children}</Form.Item>
  </span>
);
