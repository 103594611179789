/* eslint-disable no-restricted-imports */
import { LoadingOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge, Space } from 'antd';
import AntTabs from 'antd/es/tabs';
import { useMemo } from 'react';

import type { TabsProps as AntTabsProps } from 'antd/es/tabs';

import { useHandler } from 'hooks/useEventCallback';
import { Typography } from 'shared/components/Typography';
import { theme } from 'theme';

interface TabsProps<T extends string = ''> extends Omit<AntTabsProps, 'onTabClick' | 'activeKey'> {
  activeKey?: T;
  items: Array<{
    key: string;
    label: React.ReactNode;
    children?: React.ReactNode;
    count?: number;
    dot?: boolean;
  }>;
  onTabClick?: (activeKey: T) => void;
  isLoading?: boolean;
  variant?: 'standard' | 'fullWidth' | 'fitting';
}

const StyledTabs = styled(AntTabs)<{ variant: TabsProps['variant'] }>`
  @layer styled-component {
    .ant-tabs-tab {
      color: ${theme.colors.neutral007v2};
      padding: 8px ${({ variant }) => (variant === 'fitting' ? '0' : '12px')};
    }
    .ant-tabs-tab:hover {
      color: ${theme.colors.blue005v2};
    }
    .ant-badge-count {
      font-weight: 500;
    }
    .ant-tabs-tab .ant-badge-count {
      color: ${theme.colors.neutral009v2};
      background: ${theme.colors.neutral002v2};
    }
    .ant-tabs-tab-active .ant-badge-count {
      color: ${theme.colors.white001};
      background: ${theme.colors.blue006};
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 12px;
    }
    .ant-tabs-nav::before {
      opacity: 0;
    }
    .ant-tabs-ink-bar {
      border-radius: 2px;
      height: 3px;
    }

    ${({ variant }) =>
      variant === 'fullWidth'
        ? css`
            .ant-tabs-nav {
              width: 100% !important;
            }

            .ant-tabs-tab {
              display: block;
              flex: 1;
              text-align: center;
            }

            .ant-tabs-nav > div:nth-of-type(1) {
              display: unset !important;
              width: 100% !important;
            }

            /* Reference: https://github.com/ant-design/ant-design/issues/43541#issuecomment-1956267920 */
            .ant-tabs-nav-operations {
              display: none;
            }
          `
        : ''}
  }
`;

const TabContent = styled.div`
  padding: 20px 0;
`;

export function Tabs<T extends string = ''>({
  items = [],
  isLoading,
  onTabClick,
  variant = 'standard',
  ...props
}: TabsProps<T>) {
  const memorizedOnTabClick = useHandler((activeKey) => onTabClick?.(activeKey));

  const memorizedItems = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        label: (
          <Space size={4}>
            <Badge dot={item.dot} styles={{ root: { color: 'inherit' } }}>
              <Typography variant="body" fontWeight="medium">
                {item.label}
              </Typography>
            </Badge>
            {isLoading ? <LoadingOutlined /> : null}
            {item.count !== undefined ? (
              <Badge showZero={true} overflowCount={999} count={item.count} />
            ) : null}
          </Space>
        ),
        children: item.children ? <TabContent>{item.children}</TabContent> : null,
      })),
    [isLoading, items],
  );

  return (
    <StyledTabs
      items={memorizedItems}
      onTabClick={memorizedOnTabClick}
      variant={variant}
      {...props}
    />
  );
}

export type { TabsProps };
