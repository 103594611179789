import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import InfoCircleOutlined from '@ant-design/icons/InfoCircleOutlined';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { StyledIcon } from 'components';
import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface TrackableTagProps {
  trackable?: boolean;
}

const TrackableTag = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['trackable']),
})<TrackableTagProps>`
  position: relative;
  display: inline-block;
  line-height: 20px;
  font-size: 14px;
  color: ${theme.colors.white001};
  background-color: ${({ trackable }) =>
    trackable ? theme.colors.green005 : theme.colors.neutral008};
  padding: 0 9px;
  border-radius: 4px;
  &.not-trackable {
    cursor: pointer;
  }
`;

const openCountTrackableList = [
  LineFieldType.Image,
  LineFieldType.Card,
  LineFieldType.Prize,
  LineFieldType.ImageMap,
  LineFieldType.ImageMapFlex,
  LineFieldType.ImageMapCarousel,
  LineFieldType.ImageCarousel,
  LineFieldType.Carrousel,
];

export const OpenCountTrackableTag = ({ modules }: { modules: EditorDataType[] }) => {
  const { t } = useTranslation();

  const checkIsModuleTrackable = useCallback((d: EditorDataType) => {
    return openCountTrackableList.includes(d.module_id);
  }, []);

  if (modules.length === 0) return null;

  if (modules.some(checkIsModuleTrackable))
    return (
      <TrackableTag trackable={true}>
        <StyledIcon mr="5px" component={<CheckCircleOutlined />} />
        {t('message.openCountTrackable')}
      </TrackableTag>
    );
  else
    return (
      <Tooltip title={<TooltipDescription>{t('message.openCount.tooltip')}</TooltipDescription>}>
        <TrackableTag className="not-trackable">
          <StyledIcon mr="5px" component={<InfoCircleOutlined />} />
          {t('message.openCountNotTrackable')}
        </TrackableTag>
      </Tooltip>
    );
};
