import { useCallback, useMemo } from 'react';
import * as R from 'remeda';

import { apiHooks } from 'shared/api';
import {
  getTagIdByName,
  getTagIdsByNames,
  getTagNameById,
  getTagNamesByIds,
  tagNameExists,
} from 'utils/tag';

/**
 * A simple wrapper around the tag metadata query with some common utility functions
 * This returns the "raw" metadata as well as a lookup table using the index
 * Mostly created as a developer convenience; no need to invoke the main query every time
 */
export const useTagMetadata = (props?: { gcTime?: number }) => {
  const tagMetadataQuery = apiHooks.useTagMetadata(undefined, {
    staleTime: Infinity,
    ...(props?.gcTime ? { cacheTime: props.gcTime } : {}),
  });

  const tagMetadataRecords = useMemo(
    () =>
      tagMetadataQuery.data
        ? R.indexBy(tagMetadataQuery.data, (item) => String(item.id))
        : undefined,
    [tagMetadataQuery.data],
  );

  return {
    isTagMetadataLoading: tagMetadataQuery.isLoading,
    tagMetadataItems: tagMetadataQuery.data,
    getTagIdByName: useCallback(
      (tagName: string) => getTagIdByName(tagName, tagMetadataQuery.data),
      [tagMetadataQuery.data],
    ),
    getTagIdsByNames: useCallback(
      (tagNames: string[]) => getTagIdsByNames(tagNames, tagMetadataQuery.data),
      [tagMetadataQuery.data],
    ),
    getTagNameById: useCallback(
      (tagId: number) => getTagNameById(tagId, tagMetadataRecords),
      [tagMetadataRecords],
    ),
    getTagNamesByIds: useCallback(
      (tagIds: number[]) => getTagNamesByIds(tagIds, tagMetadataRecords),
      [tagMetadataRecords],
    ),
    tagNameExists: useCallback(
      (tagName: string) => tagNameExists(tagName, tagMetadataQuery.data),
      [tagMetadataQuery.data],
    ),
  };
};
