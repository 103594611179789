import styled from '@emotion/styled';

import { Loading } from 'components/Loading';

const TableLoadingWrapper = styled.div`
  position: relative;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 50px;
`;

export const TableLoading = () => (
  <TableLoadingWrapper>
    <Loading />
  </TableLoadingWrapper>
);
