import type {
  AbstractEditorData,
  RichTextParameterWithReferralData,
  TextDataParameterCustom,
  TextDataParameterName,
  TextDataParameterReferralActivityLink,
  TextDataParameterReferralInvitationLinkV2,
  TextDataParameterReferralInviteeV2,
  TextDataParameterReferralInviterV2,
  TextDataParameterUrl,
} from './types';
import type { SetOptional } from 'type-fest';

import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { ParameterType } from 'components/LineMessageEditor/models/templateDataAndTypes/ParameterType';
import { generateRichEditorId } from 'components/LineMessageEditor/utils/richEditorId';

export interface TextFormatData {
  richEditorId: number;
  rowData: string;
  tagId: number;
  isFocus: boolean;
}

interface TextFormat {
  format: TextFormatData;
}

export const textDataParameterNameData: TextDataParameterName = {
  key: 'name',
  type: ParameterType.standard,
  data: {
    function: 'member_name',
  },
};
export const textDataParameterCustomData: TextDataParameterCustom = {
  key: '',
  mappingKey: 'custom01',
  type: ParameterType.custom_string,
  data: {
    function: null,
  },
};
export const textDataParameterUrlData: TextDataParameterUrl = {
  key: 'url01',
  type: ParameterType.standard,
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [] as string[],
  },
  isShowPopup: true,
};

// TODO: Decouple Referral from RichText
export const textDataParameterReferralActivityLink: TextDataParameterReferralActivityLink = {
  key: DecoratorType.ReferralActivityLink,
  type: ParameterType.standard,
  data: {
    function: DecoratorType.ReferralActivityLink,
  },
};
export const textDataParameterReferralInvitationLinkV2: TextDataParameterReferralInvitationLinkV2 =
  {
    key: DecoratorType.ReferralInvitationLink,
    type: ParameterType.standard,
    data: {
      function: DecoratorType.ReferralInvitationLink,
    },
  };
export const textDataParameterReferralInviterV2: TextDataParameterReferralInviterV2 = {
  key: DecoratorType.ReferralInviter,
  type: ParameterType.standard,
  data: {
    function: DecoratorType.ReferralInviter,
  },
};
export const textDataParameterReferralInviteeV2: TextDataParameterReferralInviteeV2 = {
  key: DecoratorType.ReferralInvitee,
  type: ParameterType.standard,
  data: {
    function: DecoratorType.ReferralInvitee,
  },
};

export const createTextFormat = ({
  richEditorId,
  rowData,
  tagId,
  isFocus,
}: SetOptional<TextFormatData, 'rowData' | 'tagId' | 'isFocus'>): TextFormatData => ({
  richEditorId,
  rowData: rowData ?? '',
  tagId: tagId ?? 1,
  isFocus: isFocus ?? false,
});

type TextData = {
  text: string;
};

export type RichTextParameter =
  | TextDataParameterUrl
  | TextDataParameterName
  | TextDataParameterCustom;

export type TextDataType = Omit<
  AbstractEditorData<TextData, TextFormat, unknown, RichTextParameterWithReferralData>,
  'actions'
>;

export const textData: TextDataType = {
  module_id: LineFieldType.Text,
  data: {
    text: '哈囉你好#Name, <$var:url01>',
  },
  parameters: [textDataParameterNameData, textDataParameterUrlData, textDataParameterCustomData],
  format: createTextFormat({ richEditorId: generateRichEditorId() }),
};
