import type { DependencyList, EffectCallback } from 'react';

import { useEnhancedEffect } from 'hooks/useEnhancedEffect';

/**
 * Similar to `useEffect`.
 *
 * Use this hook only if you know what you are doing.
 *
 * @param deps - Dependency list
 * @param fn - Callback function
 * @returns
 */
export function useWatch(deps: DependencyList, fn: EffectCallback): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEnhancedEffect(fn, deps);
}
