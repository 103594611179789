import { Children } from 'react';

import type { FlexSpan, FlexText } from '@line/bot-sdk';
import type { CSSProperties } from 'react';

import { css, shouldNotForwardProps, styled } from 'shared/utils/styled';

import * as S from '../styled';
import { alignClassName, flexClassName, marginClassName, sizeClassName } from '../utils';

interface FlexTextComponentProps {
  data: FlexText;
  parentLayout: 'horizontal' | 'vertical' | 'baseline';
}

interface FlexSpanComponentProps {
  data: FlexSpan;
}

interface LineClampTextProps {
  styled: Pick<FlexText, 'wrap' | 'maxLines'>;
}

// Reference: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp#CSS
const LineClampText = styled('p').withConfig({
  shouldForwardProp: shouldNotForwardProps(['styled', 'wrap', 'maxLines']),
})<LineClampTextProps>`
  ${({ styled: { wrap, maxLines } }) =>
    wrap &&
    maxLines &&
    maxLines > 0 &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${maxLines};
      overflow: hidden;
    `}
`;

const FlexSpanComponent = ({ data }: FlexSpanComponentProps) => {
  const {
    text,
    size,
    color,
    weight,
    // style,
    // decoration
  } = data;
  return (
    <span className={S.classNames('span', sizeClassName(size), weight)} style={{ color }}>
      {text}
    </span>
  );
};

const wrapClassName = (wrap: boolean | undefined) => ({ wrap: !!wrap });

// line simulator 裡面有放 spacing, 應該是 bug, 因為文件上面沒有
// https://developers.line.biz/en/reference/messaging-api/#f-text
export const FlexTextComponent = ({ data, parentLayout }: FlexTextComponentProps) => {
  const { align, size, weight, color, wrap, maxLines, margin, contents, text } = data;
  const style: CSSProperties = { color, lineHeight: 1.2 };
  let flex = data.flex;
  if (parentLayout === 'baseline' && flex === undefined) {
    flex = 0;
  }
  if (flex !== undefined && flex > 3) {
    style['flex'] = `${flex} ${flex} auto`;
  }
  return (
    <div
      className={S.classNames(
        'text',
        sizeClassName(size),
        weight,
        wrapClassName(wrap),
        marginClassName(margin, parentLayout === 'baseline' ? 'l' : 't'),
        flexClassName(flex),
        alignClassName(align),
      )}
      style={style}
    >
      {contents && contents.length ? (
        contents.map((content, index) => <FlexSpanComponent key={`span_${index}`} data={content} />)
      ) : (
        <>
          {Children.toArray(
            text
              .split('\n')
              .map((t) => <LineClampText styled={{ wrap, maxLines }}>{t}</LineClampText>),
          )}
        </>
      )}
    </div>
  );
};
