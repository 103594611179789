import { memo } from 'react';
import { Trans } from 'react-i18next';

import type {
  EditorParameter,
  TextDataParameterCustom,
  TextDataParameterUrl,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { isRichTextCustomParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { transformKey } from 'components/LineMessageEditor/utils/transformKey';
import { i18n } from 'modules/G11n/i18n';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { theme } from 'theme';

import { ColoredSpan, CustomSpan, NameSpan, UrlSpan } from './Styled';

const URL_TAG_REGEX = /(<\$var:url\d*>+)/g;
const NAME_TAG_REGEX = /(<\$var:name>+)/g;
const CUSTOM_TAG_REGEX = /(<\$var:custom\d*>+)/g;
const CUSTOM_TEXT_TAG_REGEX = /(<\$var:customText\w*>+)/g;
const REFERRAL_ACTIVITY_LINK_REGEX = /(<\$var:referral_activity_link>+)/g;
const INVITATION_LINK_TAG_REGEX = /(<\$var:referral_invitation_link>+)/g;
const INVITER_NAME_TAG_REGEX = /(<\$var:referral_inviter>+)/g;
const INVITEE_NAME_TAG_REGEX = /(<\$var:referral_invitee>+)/g;

/* The following functions are initially copied from:
   `./RichTextPreview.tsx`: https://tinyurl.com/mm84dmtt
 */

function parseBreakLine(text: string) {
  const REGEX = /(<br\/>)/g;
  return text.split(REGEX).map((line, index) => {
    return line.match(REGEX) ? <br key={'br_key_' + index} /> : line;
  });
}

export function parseRichText(
  text: string,
  parameters: EditorParameter[],
): Array<string | React.JSX.Element> {
  return parseCustom(
    parseUrl(
      parseInviteeName(
        parseInviterName(
          parseInvitationLink(parseReferralActivityLink(parseName(parseBreakLine(text)))),
        ),
      ),
      parameters,
    ),
    parameters,
  );
}

function parseName(text: Array<React.JSX.Element | string>) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(NAME_TAG_REGEX).map((line, index) => {
          if (line.match(NAME_TAG_REGEX)) {
            return (
              <NameSpan key={index + 'NameSpan'}>
                <Trans i18nKey="member.name" />
              </NameSpan>
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });
  return finalArray;
}

function parseUrl(text: Array<string | React.JSX.Element>, parameters: EditorParameter[]) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(URL_TAG_REGEX).map((line, index) => {
          if (line.match(URL_TAG_REGEX)) {
            let targetUrl = '';
            const targetParam = parameters.find((p) => p.key === transformKey(line));

            if (targetParam) {
              targetUrl = (targetParam as TextDataParameterUrl)?.data.url;
            }
            return (
              <Tooltip
                key={`${index}${line}UrlSpan`}
                title={
                  <TooltipDescription>
                    <Trans
                      values={{
                        textBeforeColon: i18n.t('common.openUrl'),
                        textAfterColon: targetUrl,
                      }}
                      i18nKey="common.textsWithColon"
                    />
                  </TooltipDescription>
                }
              >
                <UrlSpan
                  onClick={() => {
                    window.open(targetUrl);
                  }}
                >
                  https://maac.io/+preview
                </UrlSpan>
              </Tooltip>
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });
  return finalArray;
}

// eslint-disable-next-line react-refresh/only-export-components
const ApiParameterPopover = memo(function ApiParameterPopover({
  spanKey,
  targetKey,
  isParamRichText = false,
}: {
  spanKey: string;
  targetKey?: string;
  isParamRichText?: boolean;
}) {
  if (isParamRichText) {
    return (
      <CustomSpan key={spanKey}>
        {targetKey
          ? i18n.t('common.textsWithColon', {
              textBeforeColon: i18n.t('glossary.apiParameter'),
              textAfterColon: targetKey,
            })
          : i18n.t('glossary.apiParameter')}
      </CustomSpan>
    );
  }
  return (
    <Tooltip
      title={
        <TooltipDescription>
          <Trans
            values={{
              textBeforeColon: i18n.t('glossary.apiParameter'),
              textAfterColon: targetKey,
            }}
            i18nKey="common.textsWithColon"
          />
        </TooltipDescription>
      }
    >
      <CustomSpan key={spanKey}>
        <Trans i18nKey="glossary.apiParameter" />
      </CustomSpan>
    </Tooltip>
  );
});

function parseCustomForParamRichText(
  text: Array<string | React.JSX.Element>,
  parameters: EditorParameter[],
) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(CUSTOM_TEXT_TAG_REGEX).map((line, index) => {
          if (line.match(CUSTOM_TEXT_TAG_REGEX)) {
            const mappingKey = transformKey(line);
            const targetParam = parameters.find(
              (p) => isRichTextCustomParameter(p) && p.mappingKey === mappingKey,
            );
            return (
              <ApiParameterPopover
                key={`APiParameterPopover${index}`}
                spanKey={mappingKey}
                targetKey={targetParam?.key}
                isParamRichText={true}
              />
            );
          } else {
            return line;
          }
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });
  return finalArray;
}

function parseCustom(text: Array<string | React.JSX.Element>, parameters: EditorParameter[]) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(CUSTOM_TAG_REGEX).map((line, index) => {
          if (line.match(CUSTOM_TAG_REGEX)) {
            let targetKey = '';
            const targetParam = parameters.find(
              (p) => isRichTextCustomParameter(p) && p.mappingKey === transformKey(line),
            );
            if (targetParam) {
              targetKey = (targetParam as TextDataParameterCustom)?.key;
            }
            return (
              <ApiParameterPopover
                key={index + 'ApiParameterPopover'}
                spanKey={index + 'CustomSpan'}
                targetKey={targetKey}
              />
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });
  return finalArray;
}

function parseReferralActivityLink(text: Array<React.JSX.Element | string>) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(REFERRAL_ACTIVITY_LINK_REGEX).map((line, index) => {
          if (line.match(REFERRAL_ACTIVITY_LINK_REGEX)) {
            return (
              <ColoredSpan key={index + 'ReferralActivityLinkSpan'} color={theme.colors.purple006}>
                <Trans i18nKey="referralV2.activityPageLink" />
              </ColoredSpan>
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });

  return finalArray;
}

function parseInvitationLink(text: Array<React.JSX.Element | string>) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(INVITATION_LINK_TAG_REGEX).map((line, index) => {
          if (line.match(INVITATION_LINK_TAG_REGEX)) {
            return (
              <ColoredSpan key={index + 'InvitationLinkSpan'}>
                <Trans i18nKey="referralV2.invitationLink" />
              </ColoredSpan>
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });

  return finalArray;
}

function parseInviterName(text: Array<React.JSX.Element | string>) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(INVITER_NAME_TAG_REGEX).map((line, index) => {
          if (line.match(INVITER_NAME_TAG_REGEX)) {
            return (
              <ColoredSpan key={index + 'InviterNameSpan'} color={theme.colors.blue005}>
                <Trans i18nKey="referralV2.inviterName" />
              </ColoredSpan>
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });

  return finalArray;
}

function parseInviteeName(text: Array<React.JSX.Element | string>) {
  const finalArray: Array<string | React.JSX.Element> = [];
  text
    .map((d) => {
      if (typeof d === 'string') {
        return d.split(INVITEE_NAME_TAG_REGEX).map((line, index) => {
          if (line.match(INVITEE_NAME_TAG_REGEX)) {
            return (
              <ColoredSpan key={index + 'InviteeNameSpan'} color={theme.colors.yellow006}>
                <Trans i18nKey="referralV2.inviteeName" />
              </ColoredSpan>
            );
          } else return line;
        });
      }
      return d;
    })
    .forEach((d) => {
      if (Array.isArray(d)) {
        finalArray.push(...d);
      } else {
        finalArray.push(d);
      }
    });

  return finalArray;
}

export function parseParamRichText(
  text: string,
  parameters: EditorParameter[],
): Array<string | React.JSX.Element> {
  return parseCustomForParamRichText(parseName(parseBreakLine(text)), parameters);
}
