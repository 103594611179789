import type { Rule } from './type';

import { i18n } from 'modules/G11n/i18n';

export const maxLength = (maxLength: number): Rule =>
  ((message) => ({
    handlesNull: true,
    validate: (value) => {
      return value.toString().length <= maxLength;
    },
    hint: () => message || i18n.t('validation.maxCharLength', { count: maxLength }),
    errorType: 'ExceedsMaxLength',
  })) as Rule;

export const maxLengthByURLEncoded = (maxLength: number): Rule =>
  ((message) => ({
    handlesNull: true,
    validate: (value) => {
      return encodeURIComponent(value).length <= maxLength;
    },
    hint: () => message || i18n.t('validation.exceedMaxCharLength'),
    errorType: 'ExceedsMaxLength',
  })) as Rule;
