import { EditorState, Modifier } from 'draft-js';

import type { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import type { EReferralTagTypes } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

type OnReferralAddTagClick = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState, isFocused: boolean) => void,
  type: EReferralTagTypes,
) => void;

export const onReferralAddTagClick: OnReferralAddTagClick = (editorState, setEditorState, type) => {
  let contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const contentStateWithEntity = contentState.createEntity('MY_ENTITY_TYPE', 'IMMUTABLE');
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  // ---------------------------------------------------

  contentState = Modifier.insertText(
    contentStateWithEntity,
    selectionState, // selectionState
    ' <$var:' + type + '> ',
    undefined,
    entityKey,
  );

  let newState = EditorState.push(editorState, contentState, 'insert-characters');

  if (!newState.getCurrentContent().equals(editorState.getCurrentContent())) {
    const sel = newState.getSelection();

    newState = EditorState.forceSelection(newState, sel);
  }
  setEditorState(newState, true);
};

type OnReferralAddTagClickV2 = (
  callback: (rowIndex: number, type: DecoratorType) => void,
  rowIndex: number,
  editorState: EditorState,
  setEditorState: (editorState: EditorState, isFocused: boolean) => void,
  type: DecoratorType,
) => void;

export const onReferralAddTagClickV2: OnReferralAddTagClickV2 = (
  callback,
  rowIndex,
  editorState,
  setEditorState,
  type,
) => {
  let contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();
  const contentStateWithEntity = contentState.createEntity('MY_ENTITY_TYPE', 'IMMUTABLE');
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  callback(rowIndex, type);

  contentState = Modifier.insertText(
    contentStateWithEntity,
    selectionState, // selectionState
    ' <$var:' + type + '> ',
    undefined,
    entityKey,
  );

  let newState = EditorState.push(editorState, contentState, 'insert-characters');

  if (!newState.getCurrentContent().equals(editorState.getCurrentContent())) {
    const sel = newState.getSelection();

    newState = EditorState.forceSelection(newState, sel);
  }
  setEditorState(newState, true);
};
