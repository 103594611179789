import { useTranslation } from 'react-i18next';

import type { ImagemapCarouselModule } from 'shared/components/Editor/LineMessageEditor/types';

import {
  getActionBlockStylesFromOffsets,
  parseWidthAndHeight,
} from 'shared/components/Editor/LineMessageEditor/components/ImagemapCarouselModule/utils';
import { CarouselScrollPreviewContainer } from 'shared/components/Editor/LineMessageEditor/components/Styled';
import {
  isCustomParameter,
  isCustomParameterWithLink,
  isStandardParameter,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

const IMAGEMAP_PREVIEW_IMAGE_WIDTH = 242;

interface ImagemapCarouselImageContainerProps {
  height: number | string;
}

export const ImagemapCarouselImageContainer = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['height']),
})<ImagemapCarouselImageContainerProps>`
  flex: 1 0 auto;
  position: relative;
  max-width: ${IMAGEMAP_PREVIEW_IMAGE_WIDTH}px;
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  line-height: 0;
  margin-left: 12px;
  border-radius: 6px;
  background: ${theme.colors.white001};
  overflow: hidden;
`;

export const ImagemapCarouselImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
`;

const ImagemapActionBlock = styled.div`
  position: absolute;
  background-color: ${theme.colors.neutral003};
  border: 1px dashed ${theme.colors.neutral005};
  transition: 0.5s background-color ease-in-out;
  cursor: pointer;
  opacity: 0;
  &:hover {
    opacity: 0.7;
  }
`;

export const ImagemapCarouselModulePreview = ({ message }: { message: ImagemapCarouselModule }) => {
  const { t } = useTranslation();
  return (
    <CarouselScrollPreviewContainer>
      {message.data.contents.contents.map((imagemap, index) => {
        const [imagemapContentImage, ...imagemapContentBoxes] = imagemap.body.contents;

        return (
          <ImagemapCarouselImageContainer
            key={`imagemap-carousel-${index}`}
            height={imagemapContentImage.url ? 'auto' : 0}
          >
            <ImagemapCarouselImage src={imagemapContentImage.url} />
            {imagemapContentBoxes.map(
              ({ width = '', height = '', offsetStart = '', offsetTop = '', action }, index) => {
                let actionType = '';
                let text = '';

                if (action.type === 'message') {
                  actionType = t('message.send');
                  text = action.text;
                }
                if (action.type === 'uri') {
                  actionType = t('common.openUrl');
                  const uriParameter = message.parameters.find(
                    (parameter) => isStandardParameter(parameter) && parameter.key === action.key,
                  );
                  text =
                    uriParameter && isCustomParameterWithLink(uriParameter.data)
                      ? uriParameter.data.url
                      : '';
                }
                if (action.type === 'customUri') {
                  actionType = t('message.apiUrl');
                  text =
                    message.parameters.find(
                      (parameter) =>
                        isCustomParameter(parameter) && parameter.mappingKey === action.key,
                    )?.key ?? '';
                }

                const { aspectRatioValue } = parseWidthAndHeight(
                  imagemapContentImage.aspectRatio ?? '1:1',
                );

                const styles = getActionBlockStylesFromOffsets(
                  {
                    width: IMAGEMAP_PREVIEW_IMAGE_WIDTH,
                    height: IMAGEMAP_PREVIEW_IMAGE_WIDTH / aspectRatioValue,
                  },
                  { width, height, offsetStart, offsetTop },
                );

                return (
                  <Tooltip
                    key={`action-${index}`}
                    title={
                      <TooltipDescription>
                        {t('common.textsWithColon', {
                          textBeforeColon: actionType,
                          textAfterColon: text,
                        })}
                      </TooltipDescription>
                    }
                  >
                    <ImagemapActionBlock
                      style={styles}
                      onClick={() => {
                        if (action.type === 'uri' && text) {
                          window.open(text);
                        }
                      }}
                    />
                  </Tooltip>
                );
              },
            )}
          </ImagemapCarouselImageContainer>
        );
      })}
    </CarouselScrollPreviewContainer>
  );
};
