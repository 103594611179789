import type { ReactNode } from 'react';

/**
 * A workaround to resolve the react/jsx-no-useless-fragment once when transform
 * the ReactNode to React.JSX.Element.
 *
 * ```ts
 * function Component({ children }) {
 *   return children; // got type error
 * }
 *
 * function Component({ children }) {
 *   return <>children</>; // got eslint warning
 * }
 *
 * function Component({ children }) {
 *   return reactNodeToJsxElement(children); // 🙍 okay...
 * }
 * ```
 *
 * There is an open issue about this: https://github.com/jsx-eslint/eslint-plugin-react/issues/2584
 *
 * @param reactNode - The reactNode you want to wrap.
 * @returns The React.JSX.Element .
 */
export function reactNodeToJsxElement(reactNode: ReactNode) {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{reactNode}</>;
}
