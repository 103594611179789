import { memo, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { doNothing } from 'remeda';

import type { ComponentProps, ReactNode } from 'react';
import type { FallbackProps } from 'react-error-boundary';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { CopyField } from 'components/CopyField';
import { useEventCallback } from 'hooks/useEventCallback';
import { logError } from 'lib/logger';
import { styled } from 'shared/utils/styled';
import { css, overrideCss } from 'shared/utils/styled/override';
import { preloadImage } from 'utils/dom/preload';
import { reactNodeToJsxElement } from 'utils/react/reactNodeToJsxElement';

import img from './error.png';
import { ReloadButton } from './ReloadButton';

const maxImageWidth = '304px';

const Outer = styled.div<OverrideComponentCssProps>`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  max-width: 789px;
  min-height: 100%;
  overflow-wrap: break-word;
  position: relative;
  ${overrideCss};
`;

const Inner = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  height: fit-content;
  justify-content: space-between;
  overflow-wrap: break-word;
  position: relative;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 40px;
  min-width: calc((100% - 4em) / 2);
  width: fit-content;
`;

const Action = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  > button {
    width: clamp(4em, 229px, 100%);
  }
`;

const Image = styled.img`
  display: flex;
  min-width: 6em;
  max-width: calc(100% - ${maxImageWidth} - 4em);
`;

const cssCopyField = css`
  --max-width: 315px;
`;

const Title = memo(styled(function Title({ children, ...props }: ComponentProps<'h1'>) {
  const titleNode = useMemo(
    () => children ?? <Trans i18nKey="errorBoundary.title.default" />,
    [children],
  );
  return <h1 {...props}>{titleNode}</h1>;
})`
  font-weight: 500;
  font-size: 29px;
`);

type ContentProps = {
  children?: ReactNode;
};

const Content = function Content({ children }: ContentProps) {
  const content = useMemo(
    () =>
      children ? (
        reactNodeToJsxElement(children)
      ) : (
        <p>
          <Trans i18nKey="errorBoundary.content.default">
            Don’t worry, we’re trying to figure out the issue.
          </Trans>
        </p>
      ),
    [children],
  );
  return reactNodeToJsxElement(content);
};

export type ErrorFallbackPageProps = {
  title?: ReactNode;
  content?: ReactNode;
  reloadWindow?: boolean;
} & Partial<FallbackProps> &
  OverrideComponentCssProps;

// Preload the image that make it able to display when disconnected.
preloadImage(img).catch(doNothing);

export const ErrorFallbackPage = memo(function ErrorFallbackPage({
  title,
  content,
  error,
  resetErrorBoundary,
  reloadWindow = false,
  styledCss,
}: ErrorFallbackPageProps) {
  const [eventId, setEventId] = useState('');
  const reload = useEventCallback(() => {
    if (reloadWindow) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
      return;
    }
    try {
      if (resetErrorBoundary) resetErrorBoundary();
    } catch (e) {
      // Record error and do nothing because ErrorBoundary should be the last error guard.
      logError(e);
    }
  });
  useEffect(() => {
    setEventId(!error ? '' : logError(error));
  }, [error]);

  return (
    <Outer styledCss={styledCss}>
      <Inner>
        <Main>
          <Title>{title}</Title>
          <Content>{content}</Content>
          <Action>
            {eventId ? (
              <CopyField text={eventId} label="Event ID" styledCss={cssCopyField} />
            ) : null}
            <ReloadButton onClick={reload} type="primary">
              <Trans i18nKey="errorBoundary.action.reload" />
            </ReloadButton>
          </Action>
        </Main>
        <Image src={img} alt="Error" />
      </Inner>
    </Outer>
  );
});
