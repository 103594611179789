import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { IMAGE_MAP_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type {
  ImageMapActionUrlType,
  ImageMapDataType,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { FlatChannel } from 'layout/types';
import type { MutableRefObject } from 'react';

import { Context } from 'components/LineMessageEditor/models';
import { ImageMapActions } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { SubText } from 'components/Typography/Text';
import * as Validator from 'lib/validator';
import {
  messageNameHelper,
  notificationTextNameHelper,
  urlNameHelper,
} from 'lib/validator/helper/validatorHelpers';
import { Radio } from 'shared/components/Radio';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';

import { CustomBlockEditor } from './ImageMapCustomBlockEditor';
import { ImageMapPopupSelection } from './ImageMapPopupSelection';
import { ImageMapTextOrUrl } from './ImageMapTextOrUrl';

interface ImageMapEditorProps {
  message: ImageMapDataType;
  rowIndex: number;
  imageMapRef: MutableRefObject<null>;
  currentChannel: FlatChannel | null;
}

export const ImageMapPopContent = ({
  message,
  rowIndex,
  imageMapRef,
  currentChannel,
}: ImageMapEditorProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const validatorCtx = useContext(Validator.Context);
  const [isCustomEditorShow, setIsCustomEditorShow] = useState(false);

  const actionError = Validator.getInvalid(
    validatorCtx.invalid,
    message.data.actions.map((_, i) => {
      if (_.type === 'message')
        return messageNameHelper({
          rowIndex: rowIndex,
          editorType: 'ImageMapEditor',
          entityKey: i.toString(),
        });
      else
        return urlNameHelper({
          rowIndex: rowIndex,
          editorType: 'ImageMapEditor',
          entityKey: i.toString(),
        });
    }),
  );

  return (
    <>
      <S.PopOverFieldTitle>
        <Trans i18nKey="message.notificationText" />
      </S.PopOverFieldTitle>
      <SubText>{t('message.notificationHint')}</SubText>
      <S.PopOverFieldsContent>
        <Validator.PopoverField
          name={notificationTextNameHelper({
            rowIndex: rowIndex,
            entityKey: '',
            editorType: 'ImageMapEditor',
          })}
          rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
          onChange={(e) => {
            dispatch('setImageMapNotificationText', {
              rowIndex,
              text: e.currentTarget.value,
            });
          }}
          placeholder={t('message.inputWithLengthLimit', {
            fieldName: lowerFirst(t('message.notificationText')),
            length: 300,
          })}
          value={message.data.notification_text}
          enableReinitialize={true}
          data-test="imagemap-settings-notification-input"
        />
      </S.PopOverFieldsContent>
      <ImageMapPopupSelection
        actions={message.data.actions}
        activeBlockIndex={message.activeBlockIndex}
        isCustom={message.isCustom}
        setCustomEditorShow={setIsCustomEditorShow}
        onIsCustomChange={() => dispatch('setImageMapCustom', { rowIndex, isCustom: true })}
        setImageMapActiveBlock={(index) => dispatch('setImageMapActiveBlock', { rowIndex, index })}
        setImageMapActions={(count) => dispatch('setImageMapActions', { rowIndex, count })}
        rowIndex={rowIndex}
        actionError={actionError}
      />
      {message.data.actions.length > 0 ? (
        <>
          <Radio
            checked={isMessage(message)}
            style={{ display: 'block' }}
            onClick={() => {
              if (!isMessage(message)) {
                dispatch('setImageMapActionType', {
                  rowIndex,
                  type: ImageMapActions.message,
                  previousType: isUrl(message) ? ImageMapActions.uri : ImageMapActions.customUri,
                });
              }
            }}
            data-test={`imagemap-settings-action-block-${message.activeBlockIndex}-message-radio`}
          >
            <Trans i18nKey="message.send" />
          </Radio>
          <Radio
            checked={isUrl(message)}
            style={{ display: 'block' }}
            onClick={() => {
              if (!isUrl(message)) {
                dispatch('setImageMapActionType', {
                  rowIndex,
                  type: ImageMapActions.uri,
                  previousType: isMessage(message)
                    ? ImageMapActions.message
                    : ImageMapActions.customUri,
                });
              }
            }}
            data-test={`imagemap-settings-action-block-${message.activeBlockIndex}-uri-radio`}
          >
            <Trans i18nKey="common.openUrl" />
          </Radio>
          <Radio
            checked={isCustomParameterUrl(message)}
            style={{ display: 'block' }}
            onClick={() => {
              if (!isCustomParameterUrl(message)) {
                dispatch('setImageMapActionType', {
                  rowIndex,
                  type: ImageMapActions.customUri,
                  previousType: isMessage(message) ? ImageMapActions.message : ImageMapActions.uri,
                });
              }
            }}
            data-test={`imagemap-settings-action-block-${message.activeBlockIndex}-customUri-radio`}
          >
            <Trans i18nKey="message.apiUrl" />
          </Radio>
        </>
      ) : null}
      <ImageMapTextOrUrl
        message={message}
        rowIndex={rowIndex}
        currentChannel={currentChannel}
      ></ImageMapTextOrUrl>
      {isCustomEditorShow ? (
        <CustomBlockEditor
          blocks={parseBlockData(message)}
          imageRef={imageMapRef}
          maxBlockCount={50}
          onClose={(blocks) => {
            dispatch('setImageMapCustomBlocks', { rowIndex, previewBlocks: blocks });
            setIsCustomEditorShow(false);
          }}
        />
      ) : null}
    </>
  );
};

const parseBlockData = (message: ImageMapDataType) => {
  const realHeight = message.data.base_size.height;
  const realWidth = message.data.base_size.width;
  const heightWidthRate = realHeight / realWidth;
  return message.data.actions.map((action, index) => {
    return {
      id: index + 1,
      width: (action.area.width / realWidth) * IMAGE_MAP_UI_IMAGE_WIDTH,
      height: (action.area.height / realHeight) * (IMAGE_MAP_UI_IMAGE_WIDTH * heightWidthRate),
      top: (action.area.y / realHeight) * (IMAGE_MAP_UI_IMAGE_WIDTH * heightWidthRate),
      left: (action.area.x / realWidth) * IMAGE_MAP_UI_IMAGE_WIDTH,
    };
  });
};

function isMessage(message: ImageMapDataType) {
  return message.data.actions[message.activeBlockIndex - 1].type === 'message';
}
function isUrl(message: ImageMapDataType) {
  return (
    message.data.actions[message.activeBlockIndex - 1].type === 'uri' &&
    (message.data.actions[message.activeBlockIndex - 1] as ImageMapActionUrlType).linkUri.indexOf(
      '$var:url',
    ) !== -1
  );
}
function isCustomParameterUrl(message: ImageMapDataType) {
  return (
    message.data.actions[message.activeBlockIndex - 1].type === 'uri' &&
    (message.data.actions[message.activeBlockIndex - 1] as ImageMapActionUrlType).linkUri.indexOf(
      '$var:custom',
    ) !== -1
  );
}
