/**
 * The number of journeys a customer can have
 */
export const JOURNEY_ITEM_COUNT_LIMIT = 1000;

/**
 * The number of nodes a journey can have
 */
export const JOURNEY_NODE_LIMIT = 30;

/**
 * A special class used to designate elements that trigger the unsaved changes modal
 */
export const JOURNEY_FORM_UNSAVED_CHANGES_TRIGGER = 'journey-form-unsaved-trigger';

/**
 * The number of characters a journey name can have
 */
export const JOURNEY_FORM_NAME_CHARACTER_LIMIT = 32;

/**
 * Limit on the number of messages a journey can send
 */
export const JOURNEY_FORM_MESSAGE_SENDING_LIMIT = 10000000;

/**
 * The number of filter conditions a node can have
 */
export const JOURNEY_EDITOR_FILTER_CONDITION_LIMIT = 5;

/**
 * Limitation on the days within filter condition property
 */
export const JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MIN = 1;
export const JOURNEY_EDITOR_FILTER_CONDITION_DAYS_WITHIN_MAX = 90;

/**
 * Constants related to the yes/no branching edges
 */
export const JOURNEY_EDGE_CUSTOM_MERGED_LENGTH = 36;
export const JOURNEY_EDGE_BORDER_RADIUS_LEGACY = 16; // To be removed after ELK goes live
export const JOURNEY_EDGE_BORDER_RADIUS = 8;
export const JOURNEY_EDGE_HANDLE_OFFSET = 4;

/**
 * Constants related to the Journey canvas
 */
export const JOURNEY_CANVAS_NODE_MENU_BUTTON_HEIGHT = 20;
export const JOURNEY_CANVAS_NODE_MENU_BUTTON_WIDTH = 20;
export const JOURNEY_CANVAS_BRANCHING_LABEL_HEIGHT = 20;
export const JOURNEY_CANVAS_BRANCHING_LABEL_WIDTH = 50;

/**
 * We collect data after a journey ends; this specifies how many extra days can be selected in a Report
 */
export const JOURNEY_REPORT_GRACE_PERIOD_DAYS = 60;
