import styled from '@emotion/styled';

export const TagPickerPopoverWrapper = styled.div`
  height: auto;
  width: 338px;
`;

export const TagPickerPopoverFooter = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 12px;
`;

export const TagItemListWrapper = styled.div`
  padding-top: 12px;
  width: 100%;
`;
