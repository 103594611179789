import { createContext, useContext } from 'react';

import type { Provider } from 'react';

/**
 * Create context with a hook and provider
 *
 * @returns hook and provider
 */
export function createCtx<A>(): readonly [() => A, Provider<A | undefined>] {
  const ctx = createContext<A | undefined>(undefined);

  /**
   * Use context hook
   *
   * @returns context
   */
  function useCtx(): A {
    const c = useContext(ctx);
    if (!c) throw new Error('useCtx must be inside a Provider with a value');
    return c;
  }
  return [useCtx, ctx.Provider] as const;
}
