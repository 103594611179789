import { getApp } from 'firebase/app';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';

import {
  FIREBASE_DATA_STORAGE_NAME,
  FIREBASE_TEMP_STORAGE_NAME,
  USER_UPLOAD_CDN_ORIGIN,
} from 'env';

import type { UploadMetadata } from 'firebase/storage';

import { initialFirebase } from './initialFirebase';

class MaacFirebase {
  constructor() {
    initialFirebase();
  }

  doUpload = (file: Blob | File, path: string): Promise<string> => {
    const metadata = {
      contentType: file.type,
    };

    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const storageRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(storageRef, file, metadata);
      const fullpath = uploadTask.snapshot.ref.fullPath;

      uploadTask.on(
        'state_changed',
        (_snapshot) => {
          // do nothing
        },
        (error: Error) => {
          reject(error);
        },
        () => {
          // https://cloud.google.com/storage/docs/json_api/v1/objects/get?hl=Zh-TW
          resolve(`${USER_UPLOAD_CDN_ORIGIN}/${fullpath}?alt=media`);
        },
      );
    });
  };

  /**
   * Uploads file to content storage.
   * @param file - The file to upload.
   * @param path - path to upload
   * @param uploadMetadata - Metadata for the newly uploaded object.
   */
  uploadToContentStorage = async (
    file: Blob | File,
    path: string,
    uploadMetadata?: UploadMetadata,
  ) => {
    const { type: contentType } = file;
    const meta = { ...uploadMetadata, contentType };
    const storage = getStorage();
    const storageRef = ref(storage, path);
    await uploadBytesResumable(storageRef, file, meta);
    return `${USER_UPLOAD_CDN_ORIGIN}/${path}?alt=media`;
  };

  /**
   * Uploads file to data storage
   * @param file - The file to upload
   * @param path - path to upload
   * @param uploadMetadata - Metadata for the newly uploaded object
   */
  uploadToDataStorage = async (
    file: Blob | File,
    path: string,
    uploadMetadata?: UploadMetadata,
  ) => {
    const app = getApp(FIREBASE_DATA_STORAGE_NAME);
    const { type: contentType } = file;
    const metadata = { ...uploadMetadata, contentType };
    const storage = getStorage(app);
    const storageRef = ref(storage, path);
    await uploadBytesResumable(storageRef, file, metadata);
    return `gs://${storageRef.bucket}/${storageRef.fullPath}`;
  };

  /**
   * Uploads file to temp storage
   * @param file - The file to upload
   * @param path - path to upload
   * @param uploadMetadata - Metadata for the newly uploaded object
   */
  uploadToTempStorage = async (file: File, path: string, uploadMetadata?: UploadMetadata) => {
    const app = getApp(FIREBASE_TEMP_STORAGE_NAME);
    const { type: contentType } = file;
    const metadata = {
      ...uploadMetadata,
      contentType,
      customMetadata: { originalFileName: file.name },
    };
    const storage = getStorage(app);
    const storageRef = ref(storage, path);
    await uploadBytesResumable(storageRef, file, metadata);
    return `gs://${storageRef.bucket}/${storageRef.fullPath}`;
  };

  getDownloadURL = async (appName: string, path: string) => {
    const app = getApp(appName);
    const storage = getStorage(app);
    const storageRef = ref(storage, path);
    return getDownloadURL(storageRef);
  };
}

const maacFirebase = new MaacFirebase();

export { maacFirebase };
