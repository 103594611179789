import { forwardRef, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TAG_COUNT_LIMIT_DEFAULT } from 'AppConstants';

import type { TagPickerProps } from 'shared/components/TagPicker/types';

import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { Popover } from 'shared/components/Popover';
import { TagItemList } from 'shared/components/TagPicker/components/TagItemList';
import {
  TagItemListWrapper,
  TagPickerPopoverFooter,
  TagPickerPopoverWrapper,
} from 'shared/components/TagPicker/components/TagPickerStyled';
import { TagSelect } from 'shared/components/TagPicker/components/TagSelect';
import { useTagPicker } from 'shared/components/TagPicker/hooks/useTagPicker';

/**
 * Standard tag picker component implemented with a popover
 */
export const TagPicker = memo(
  forwardRef<HTMLDivElement, TagPickerProps>(function TagPicker(
    {
      tagList,
      onTagListChange,
      tagCountLimit = TAG_COUNT_LIMIT_DEFAULT,
      disabled = false,
      loading,
      hideTagItemList = false,
      ...restProps
    },
    ref,
  ) {
    const { t } = useTranslation();

    const {
      selectedTagList,
      isOverTagCountLimit,
      changeTagHandler,
      createTagHandler,
      tagCreateMutation,
      tagPickerAddHandler,
      tagPickerCancelHandler,
    } = useTagPicker({
      tagList,
      onTagListChange,
      tagCountLimit,
      disabled,
    });

    const [isPopoverVisible, setPopoverVisible] = useState(false);

    return (
      <div {...restProps} ref={ref}>
        <Popover
          // Workround for avoid the popover be covered by Popup in the modal
          // 1030 is the default for Popover zIndex.
          // Should removed this workaround after we remove the usage of rc-trigger in LINE message editor
          zIndex={1030}
          id="tag-picker-popover"
          placement="bottomLeft"
          trigger="click"
          open={isPopoverVisible}
          onOpenChange={setPopoverVisible}
          arrow={{
            pointAtCenter: true,
          }}
          content={
            <TagPickerPopoverWrapper>
              <TagSelect
                selectedTagList={selectedTagList}
                tagList={tagList}
                tagCountLimit={tagCountLimit}
                isOverTagCountLimit={isOverTagCountLimit}
                changeTagHandler={changeTagHandler}
                createTagHandler={createTagHandler}
                loading={tagCreateMutation.isLoading}
                disabled={tagCreateMutation.isLoading || disabled}
              />
              <TagPickerPopoverFooter>
                <Button
                  onClick={() => {
                    tagPickerCancelHandler();
                    setPopoverVisible(false);
                  }}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    tagPickerAddHandler();
                    setPopoverVisible(false);
                  }}
                  loading={tagCreateMutation.isLoading}
                  disabled={selectedTagList.length === 0 || disabled}
                >
                  {t('common.add')}
                </Button>
              </TagPickerPopoverFooter>
            </TagPickerPopoverWrapper>
          }
        >
          <Button
            type="link"
            disabled={disabled}
            loading={loading}
            icon={<MotifIcon un-i-motif="plus" />}
            onClick={() => {
              if (!disabled) setPopoverVisible(true);
            }}
          >
            {t('common.addTag')}
          </Button>
        </Popover>
        <TagItemListWrapper>
          {!hideTagItemList ? (
            <TagItemList
              tagItems={tagList}
              onClose={(tagId) => {
                onTagListChange(tagList.filter((tagListId) => tagListId !== tagId));
              }}
              closable={true}
            />
          ) : null}
        </TagItemListWrapper>
      </div>
    );
  }),
);
