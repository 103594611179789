import { useAsync } from 'react-async';

import type { BootstrapData } from 'context/authContext/types';
import type { AsyncState } from 'react-async';

import { flatChannelMap } from 'layout/utils';
import { getUser as getToken } from 'layout/utils/layout';
import { isAxiosError } from 'lib/axios';
import { getBotInfo, getCaacActivationStatus } from 'services/rubato';

import { getGoogleAnalyticSetting, getUserDetail, logout } from '../services';
import { findChannel } from '../utils';

const getUser = async () => {
  const token = getToken();

  if (!token) {
    return Promise.resolve(null);
  }

  return getUserDetail(token.id).catch((error) => {
    logout();
    if (isAxiosError(error)) {
      // token expired.
      if (error.response && error.response.status === 403) {
        return Promise.resolve(null);
      }
    }
    return Promise.reject(error);
  });
};

const bootstrapData = async (): Promise<BootstrapData> => {
  const data = await getUser();

  if (!data) {
    return {
      user: null,
      org: null,
      channels: [],
      currentChannel: null,
      currentBotInfo: null,
      ga: [],
      switches: {},
      isCaacActivated: false,
    };
  }

  const { data: userData } = data;

  const {
    data: { results: gaSettings },
  } = await getGoogleAnalyticSetting();

  const { channels, ...others } = userData;
  const flattenedChannels = flatChannelMap(channels);
  const currentChannel = findChannel(flattenedChannels);
  const currentBotInfo = currentChannel ? await getBotInfo(currentChannel.id) : null;
  const isCaacActivated = currentBotInfo?.channel_id
    ? (await getCaacActivationStatus(currentBotInfo.channel_id)).is_caac_activate
    : false;

  return {
    ...others,
    channels: flattenedChannels,
    currentChannel,
    currentBotInfo,
    ga: gaSettings,
    isCaacActivated,
  };
};

export const useUserQuery = (): AsyncState<BootstrapData> => {
  return useAsync({
    promiseFn: bootstrapData,
  });
};
