/* eslint-disable @typescript-eslint/no-explicit-any */
import { RICH_EDITOR_ID_NOT_FOUND } from 'components/LineMessageEditor/constants';

import type { ParamRichTextParameter } from './card';
import type { FlexData } from './flex';
import type { ImageCarouselEditorData } from './imageCarousel';
import type {
  imageMapActionMessage,
  imageMapActionUrl,
  imageMapCustomParameter,
  imageMapData,
  imageMapTriggerAction,
  imageMapUrlParameter,
} from './imageMap';
import type { ImageMapCarousel as ImageMapCarouselData } from './imageMapCarousel';
import type { ImageMapFlex as ImageMapFlexData } from './imageMapFlex';
import type { NewsData } from './news';
import type { BindlinkParameter, PrizeRedeemDataParameter, StringParameter } from './parameters';
import type { PrizeRedeemData } from './prizeRedeem';
import type { RichTextParameter, TextDataType } from './richtext';
import type { SMSData } from './sms';
import type { videoEditor, VideoEditorData } from './video';
import type { EventMessage } from '@line/bot-sdk';
import type { QuickReplyItems } from 'components/LineMessageEditor/components/QuickReplyEditor';
import type { EditorState } from 'draft-js';

import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { ParameterType } from 'components/LineMessageEditor/models/templateDataAndTypes/ParameterType';

import { CardEditorData } from './card';
import { CarrouselData } from './carrousel';
import { ImageData } from './image';

export interface Utm {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
}

export interface EditorStateObject {
  id: number;
  editorState: EditorState;
}

export const defaultAllowedTextDataTags: DecoratorType[] = [
  DecoratorType.Name,
  DecoratorType.Url,
  DecoratorType.Custom,
];
export const defaultAllowedParamTextDataTags: DecoratorType[] = [
  DecoratorType.Name,
  DecoratorType.Custom,
];

export const referralTextDataTags: DecoratorType[] = [
  DecoratorType.ReferralActivityLink,
  DecoratorType.ReferralInvitationLink,
  DecoratorType.ReferralInviter,
  DecoratorType.ReferralInvitee,
];

export enum ImageMapActions {
  message = 'message',
  uri = 'uri',
  customUri = 'customUri',
}

export const allAllowedModules = [
  LineFieldType.Text,
  LineFieldType.Image,
  LineFieldType.ImageMap,
  LineFieldType.Card,
  LineFieldType.Carrousel,
  LineFieldType.Video,
  LineFieldType.ImageCarousel,
  LineFieldType.Prize,
  LineFieldType.ImageMapFlex,
  LineFieldType.ImageMapCarousel,
];

export const LineFieldTypeName = {
  [LineFieldType.SMS]: 'SMS',
  [LineFieldType.Text]: 'Text',
  [LineFieldType.Image]: 'Image',
  [LineFieldType.ImageMap]: 'Imagemap',
  [LineFieldType.Card]: 'Card',
  [LineFieldType.Carrousel]: 'Carousel',
  [LineFieldType.Video]: 'Video',
  [LineFieldType.ImageCarousel]: 'Image Carousel',
  [LineFieldType.Flex]: 'Flex',
  [LineFieldType.News]: 'Breaking News',
  [LineFieldType.Prize]: 'Prize',
  [LineFieldType.ImageMapFlex]: 'Imagemap Flex',
  [LineFieldType.ImageMapCarousel]: 'Imagemap Carousel',
};

export enum EReferralTagTypes {
  ReferralInvitee = 'referral_invitee',
  ReferralInviter = 'referral_inviter',
  ReferralJoinLink = 'referral_join_link',
  ReferralMemberScore = 'referral_member_score',
  ReferralMemberCode = 'referral_member_code',
}

const PARAMETER_DATA_FUNCTION = {
  link: 'link',
  memberName: 'member_name',
  sharelink: 'sharelink',
  prizeName: 'prize_name',
  prizeLiffPage: 'prize_liff_page',
  bindLink: 'bind_link',
  tag: 'tag',
  referralInviter: 'referral_inviter',
  referralActivity_link: 'referral_activity_link',
  referralInvitation_link: 'referral_invitation_link',
} as const;
export interface Parameter<T = any> {
  /**
   * The value entered from end users.
   * Use `transformKey` for UI displays
   * or `composeKey` for Backend API payloads.
   *
   * Recommend to do transform in `parseToState`
   * or compose in `useParseToSaveData`.
   */
  key: string;
  type: ParameterType;
  /** The data corresponding to a certain module using from `rubato`'s function or null */
  data: T;
  /**
   * For CustomParameter - adding `mappingKey` to map to content data `key`.
   * Most scenarios are: custom image url string, custom uri string, custom rich
   * text string.
   *
   * Format: use `generateMappingKey`
   */
  mappingKey?: string;
  /** For RichText URL */
  isShowPopup?: boolean;
}

export interface Action<T = any> {
  /** 觸發的 LINE event type */
  trigger_type: EventMessage['type'] | string;
  /** 觸發的關鍵字 */
  trigger_code: string;
  /** 模組名稱跟對應的資料 */
  data: T;
  key: string;
}

export type AbstractEditorData<
  DataType,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ReturnType = {},
  ActionType = any,
  ParameterType = any,
> = ReturnType & {
  /** module 的 id */
  module_id: LineFieldType;
  /** 要替換的 key 跟 內容 */
  data: DataType;
  /** 需要動態替換的內容資訊 */
  parameters: Array<Parameter<ParameterType>>;
  /** 訊息需要執行的附加功能  */
  actions: Array<Action<ActionType>>;
  /** quick reply   */
  quick_reply?: {
    items: QuickReplyItems;
  };
};

export type EditorDataType =
  | SMSData
  | TextDataType
  | ImageData
  | ImageMapDataType
  | ImageMapCarouselData
  | CardEditorData
  | CarrouselData
  | VideoEditorData
  | ImageCarouselEditorData
  | NewsData
  | FlexData
  | PrizeRedeemData
  | ImageMapFlexData;

const editorData: EditorDataType[] = [];
export const state = {
  editorData,
  richEditorId: RICH_EDITOR_ID_NOT_FOUND,
  editorEditingRow: -1,
  isDragMode: false,
  isShareable: false,
  hasShareableConflict: false,
};

export type ImageMapDataType = typeof imageMapData;
export type VideoDataType = typeof videoEditor;
export { CardEditorData, ImageData, CarrouselData };
export type State = typeof state;
export type ImageMapActionMessageType = typeof imageMapActionMessage;
export type ImageMapActionUrlType = typeof imageMapActionUrl;
export type ImageMapActionType = ImageMapActionMessageType | ImageMapActionUrlType;
export type ImageMapUrlParameterType = typeof imageMapUrlParameter;
export type ImageMapCustomParameterType = typeof imageMapCustomParameter;
export type ImageMapTriggerActionType = typeof imageMapTriggerAction;

export type LinkParameterData = {
  function: 'link';
  open_external_browser: boolean;
  url: string;
  utm_campaign: string;
  utm_source: string;
  utm_medium: string;
  utm_content: string;
  tag_list: string[];
};
type MemberNameParameterData = { function: 'member_name' };
type ReferralActivityLinkData = { function: DecoratorType.ReferralActivityLink };
type ReferralInvitationLinkV2Data = { function: DecoratorType.ReferralInvitationLink };
type ReferralInviterV2Data = { function: DecoratorType.ReferralInviter };
type ReferralInviteeV2Data = { function: DecoratorType.ReferralInvitee };
export type CustomParameterData = { function: null };
export type ShareButtonParameterData = {
  function: typeof PARAMETER_DATA_FUNCTION.sharelink;
  tag_list: string[];
  name: string;
};
export type TextDataParameterName = Parameter<MemberNameParameterData>;
export type TextDataParameterReferralActivityLink = Parameter<ReferralActivityLinkData>;
export type TextDataParameterReferralInvitationLinkV2 = Parameter<ReferralInvitationLinkV2Data>;
export type TextDataParameterReferralInviterV2 = Parameter<ReferralInviterV2Data>;
export type TextDataParameterReferralInviteeV2 = Parameter<ReferralInviteeV2Data>;

export type TextDataParameterUrl = Parameter<Required<LinkParameterData>>;
export type TextDataParameterCustom = Required<Omit<Parameter<CustomParameterData>, 'isShowPopup'>>;
export type LinkParameter = Parameter<LinkParameterData & { messagelink_id?: number }>;
export type CustomParameter = Parameter<CustomParameterData>;
export type ImageSourceType = 'local' | 'param';
type RichTextParameterData =
  | Required<LinkParameterData>
  | MemberNameParameterData
  | CustomParameterData;
export type ParamRichTextParameterData = MemberNameParameterData | CustomParameterData;
export type RichTextParameterWithReferralData =
  | RichTextParameterData
  | ReferralActivityLinkData
  | ReferralInvitationLinkV2Data
  | ReferralInviterV2Data
  | ReferralInviteeV2Data;
type RichTextWithReferralParameter = Parameter<RichTextParameterWithReferralData>;
export type ShareButtonParameter = Parameter<ShareButtonParameterData>;
export type EditorParameter =
  | RichTextParameter
  | ParamRichTextParameter
  | RichTextWithReferralParameter
  | LinkParameter
  | CustomParameter
  | ImageMapUrlParameterType
  | ImageMapCustomParameterType
  | BindlinkParameter
  | StringParameter
  | PrizeRedeemDataParameter
  | ShareButtonParameter;

export const createLinkParameter = (
  key: string,
  data?: Partial<LinkParameterData>,
): LinkParameter => ({
  type: ParameterType.standard,
  key,
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [],
    ...data,
  },
});

export const createCustomParameter = (key: string, mappingKey: string): CustomParameter => ({
  type: ParameterType.custom_string,
  key,
  mappingKey,
  data: {
    function: null,
  },
});

export const createShareButtonParameter = (key: string): ShareButtonParameter => ({
  type: ParameterType.standard,
  key,
  data: {
    function: PARAMETER_DATA_FUNCTION.sharelink,
    tag_list: [],
    name: '',
  },
});

export function isImageMapDataActionMessageRule(
  node: ImageMapActionType,
): node is ImageMapActionMessageType {
  return node.type === 'message';
}

export function isImageMapDataActionUrlRule(
  node: ImageMapActionType,
): node is ImageMapActionUrlType {
  return node.type === 'uri' && (node as ImageMapActionUrlType).linkUri.indexOf('custom') === -1;
}

export function isImageMapDataActionCustomUrlRule(
  node: ImageMapActionType,
): node is ImageMapActionUrlType {
  return node.type === 'uri' && (node as ImageMapActionUrlType).linkUri.indexOf('custom') !== -1;
}

export function isLinkParameter(parameter: EditorParameter): parameter is LinkParameter {
  return parameter.data.function === 'link';
}

export function isCustomParameter(parameter: EditorParameter): parameter is CustomParameter {
  return parameter.type === ParameterType.custom_string;
}

export function isShareButtonParameter(
  parameter: EditorParameter,
): parameter is ShareButtonParameter {
  return (
    parameter.type === ParameterType.standard &&
    parameter.data.function === PARAMETER_DATA_FUNCTION.sharelink
  );
}

export function isCustomObjectParameter(parameter: EditorParameter): parameter is CustomParameter {
  return parameter.type === ParameterType.custom_object;
}

export function isRichTextCustomParameter(
  parameter: EditorParameter,
): parameter is TextDataParameterCustom {
  return isCustomParameter(parameter) && parameter?.mappingKey !== undefined;
}

export function isSMSDataRule(node: EditorDataType): node is SMSData {
  return node.module_id === LineFieldType.SMS;
}

export function isLINEDataRule(node: EditorDataType): node is Exclude<EditorDataType, SMSData> {
  return node.module_id !== LineFieldType.SMS;
}

export function isTextDataRule(node: EditorDataType): node is TextDataType {
  return node.module_id === LineFieldType.Text;
}

export function isImageDataRule(node: EditorDataType): node is ImageData {
  return node.module_id === LineFieldType.Image;
}

export function isImageMapDataRule(node: EditorDataType): node is ImageMapDataType {
  return node.module_id === LineFieldType.ImageMap || node.module_id === LineFieldType.ImageMapFlex;
}

export function isImageMapFlexDataRule(node: EditorDataType): node is ImageMapFlexData {
  return node.module_id === LineFieldType.ImageMapFlex;
}

export function isImageMapCarouselDataRule(node: EditorDataType): node is ImageMapCarouselData {
  return node.module_id === LineFieldType.ImageMapCarousel;
}

export function isCardDataRule(node: EditorDataType): node is CardEditorData {
  return node.module_id === LineFieldType.Card;
}

export function isCarrouselDataRule(node: EditorDataType): node is CarrouselData {
  return node.module_id === LineFieldType.Carrousel;
}

export function isVideoDataRule(node: EditorDataType): node is VideoEditorData {
  return node.module_id === LineFieldType.Video;
}

export function isImageCarouselDataRule(node: EditorDataType): node is ImageCarouselEditorData {
  return node.module_id === LineFieldType.ImageCarousel;
}

export function isNewsDataRule(node: EditorDataType): node is NewsData {
  return node.module_id === LineFieldType.News;
}

export function isPrizeRedeemRule(node: EditorDataType): node is PrizeRedeemData {
  return node.module_id === LineFieldType.Prize;
}

export function isFlexDataRule(node: EditorDataType): node is FlexData {
  return node.module_id === LineFieldType.Flex;
}
