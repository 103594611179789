import { z } from 'zod';

import { FilterGroupOperatorSchema } from 'shared/models/filters/common';
import { FilterSchema } from 'shared/types/filterbox';

export const SegmentCategoryEnum = {
  CUSTOM_SEGMENT: 0,
  PREDEFINED_ALL_SEGMENT: 1,
  PREDEFINED_LATEST_JOIN_SEGMENT: 2,
  PREDEFINED_LATEST_ACTIVE_SEGMENT: 3,
  PREDEFINED_VALUABLE_SEGMENT: 4,
  PREDEFINED_LAPSED_SEGMENT: 5,
  CUSTOM_LINE_UID_SEGMENT: 6,
  PREDEFINED_AUTO_SEGMENT: 7,
  CUSTOM_SEGMENT_CDP: 8,
  AUTO_GENERATED_BY_IMPORT_MOBILE: 9,
} as const;

export const SegmentCategorySchema = z.nativeEnum(SegmentCategoryEnum);

export type SegmentCategoryValue = z.output<typeof SegmentCategorySchema>;

export const SegmentStatusEnum = {
  STATUS_PENDING: 1,
  STATUS_READY: 2,
} as const;

export const SegmentStatusSchema = z.nativeEnum(SegmentStatusEnum);

export type SegmentStatusValue = z.output<typeof SegmentStatusSchema>;

// This base schema is consumed by Broadcast and DPM
export const SegmentAudienceBaseSchema = z.object({
  name: z.string(),
  size: z.number().int(),
  line_id_size: z.number().int(),
  mobile_size: z.number().int(),
  status: SegmentStatusSchema,
  archive: z.boolean(),
});

/** Note: this is only used for payloads; for data returned by the API we use a discriminated union */
export const SegmentFilterDurationSchema = z
  .object({ days: z.number().int() })
  .or(z.object({ start_date: z.string(), end_date: z.string() }))
  .or(z.object({}));

/**
 * This default filter is equivalent to the built-in "all contacts" filter
 * The API returns this when no filters are specified
 * We are also required to send it with a Create mutation when no filters are specified
 */
const SegmentFilterOperationGroupsDefaultSchema = z
  .object({
    filters: z
      .object({
        parameter: z.object({}),
        category: z.literal(3),
      })
      .array(),
    filters_operator: FilterGroupOperatorSchema,
  })
  .array()
  .max(2);

export type SegmentFilterOperationGroupsDefault = z.output<
  typeof SegmentFilterOperationGroupsDefaultSchema
>;

export const SegmentFilterOperationGroupsSchema = z
  .object({
    filters: FilterSchema.array(),
    filters_operator: FilterGroupOperatorSchema,
  })
  .array()
  .max(2);
