import { memo } from 'react';
import { Trans } from 'react-i18next';

import { ExternalLink } from 'components/ExternalLink';
import { useLineDeveloperConsoleLink } from 'hooks/useLineDeveloperConsoleLink';
import { InfoBox } from 'shared/components/InfoBox';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const StyledExternalLink = styled(ExternalLink)`
  color: ${theme.colors.yellow008};
  text-decoration: underline;
  &:hover {
    color: ${theme.colors.blue006};
    text-decoration: underline;
  }
`;

export const MessageShareTargetPickerWarning = memo(function MessageConflictNotice() {
  const lineDeveloperConsoleLink = useLineDeveloperConsoleLink();

  return (
    <InfoBox status="warning">
      <Trans i18nKey="message.shareTargetPicker.hint">
        <StyledExternalLink href={lineDeveloperConsoleLink} />
      </Trans>
    </InfoBox>
  );
});
