/* eslint-disable no-restricted-imports */
import styled from '@emotion/styled';
import AntTooltip from 'antd/es/tooltip';
import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { TooltipProps, TooltipRef } from 'antd/es/tooltip';

import { ExternalLink } from 'components/ExternalLink';
import { MotifIcon } from 'icons/motif';
import { Typography } from 'shared/components/Typography';
import { theme } from 'theme';

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const TooltipTitle = ({ children }: { children: React.ReactNode }) => (
  <Typography variant="body" fontWeight="medium" color={theme.colors.neutral010v2}>
    {children}
  </Typography>
);

export const TooltipDescription = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <Typography variant="body" fontWeight="regular" color={theme.colors.neutral009v2} style={style}>
    {children}
  </Typography>
);

export const TooltipAction = ({ href, children }: { href: string; children?: React.ReactNode }) => {
  const { t } = useTranslation();
  return (
    <ExternalLink href={href} trailingIcon={true}>
      {children ?? t('common.learnMore')}
    </ExternalLink>
  );
};

/**
 * If you want title, description and action in the tooltip, you should use this component as the example shows to follow the design spec.
 * If there's only description, you can use `TooltipDescription` directly.
 *
 * @example
 * ```tsx
 *  <Tooltip
      title={
        <TooltipContainer>
          <TooltipContent>
            <TooltipTitle>Custom title</TooltipTitle>
            <TooltipDescription>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </TooltipDescription>
          </TooltipContent>
          <TooltipAction href="https://example.com">Learn more</TooltipAction>
        </TooltipContainer>
      }
    />
 * ```
 */
export const Tooltip = memo(
  forwardRef<TooltipRef, TooltipProps>(function Tooltip({ children, title, ...tooltipProps }, ref) {
    return (
      <AntTooltip
        ref={ref}
        title={title}
        color={theme.colors.white001}
        overlayStyle={{
          maxWidth: '300px',
        }}
        overlayInnerStyle={{ padding: '8px 12px', borderRadius: '4px' }}
        {...tooltipProps}
      >
        {children ? (
          children
        ) : (
          <MotifIcon
            un-i-motif="circle_info"
            style={{ color: theme.colors.neutral007v2, fontSize: '16px', minWidth: '16px' }}
          />
        )}
      </AntTooltip>
    );
  }),
);

export type { TooltipProps };
