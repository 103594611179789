import type { Dayjs } from 'lib/dayjs';
import type { CdpDataSource } from 'shared/models/cdp';

// TODO: reintegrate these types into this file after refactoring is complete
export type {
  FilterError,
  FilterSetErrorFunction,
  BaseFilterComponentProps,
} from 'modules/FilterBox/types/ui';

export { DurationUiSchema } from 'modules/FilterBox/types/ui';

/**
 * FilterBox types refactoring strategy:
 * - Most of this file needs to be refactored to use equivalent types in `shared/models/filters`
 * - Anything confirmed to be UI-specific should be moved to `./ui.ts` for now
 * - This will let us clear this file out step by step
 */

/**
 * Filter category
 * This must be consistent with the data from backend
 */
export enum Category {
  TAG = 1,
  ENGAGEMENT_LEVEL = 2,
  JOIN_TIME = 3,
  LAST_ENGAGED = 4,
  GENDER = 5,
  PAGE_VIEW = 6,
  BROADCAST_CLICK = 7,
  TRACELINK_CLICK = 8,
  AVERAGE_TRANSACTION_REVENUE = 9,
  ACCUMULATED_REVENUE = 10,
  BIRTHDAY = 13,
  CUSTOMER_ID = 14,
  PHONE = 15,
  EMAIL = 16,
  CAMPAIGN_CLICK = 17,
  ANY_TAG = 19,
  CREATED_SOURCE = 20,
  OPEN_COUNT = 21,
  AUTO_SEGMENT = 22,
  TAG_INTENSITY = 23,
  CDP_TAG = 24,
  CUSTOM = 99 /** Default value */,
}

/**
 * A map for categories and plain text.
 * We meed plain text in data transmission like recording data in Google Analytics, etc.
 */
export const CategoryLabel = {
  [Category.TAG]: 'Tag',
  [Category.ANY_TAG]: 'Tag',
  [Category.ENGAGEMENT_LEVEL]: 'Member interaction analysis',
  [Category.JOIN_TIME]: 'Join time',
  [Category.LAST_ENGAGED]: 'Last engaged',
  [Category.GENDER]: 'Gender',
  [Category.BIRTHDAY]: 'Birthday',
  [Category.PHONE]: 'Phone',
  [Category.EMAIL]: 'Email',
  [Category.CUSTOMER_ID]: 'Customer ID',
  [Category.PAGE_VIEW]: 'Pageview',
  [Category.BROADCAST_CLICK]: 'Broadcast clicks',
  [Category.TRACELINK_CLICK]: 'Tracelink clicks',
  [Category.CAMPAIGN_CLICK]: 'Campaign URL clicks',
  [Category.AVERAGE_TRANSACTION_REVENUE]: 'Revenue per transaction',
  [Category.ACCUMULATED_REVENUE]: 'Accumulated revenue',
  [Category.CREATED_SOURCE]: 'Created source',
  [Category.OPEN_COUNT]: 'Segment-Open',
  [Category.AUTO_SEGMENT]: 'Auto-Segment',
  [Category.TAG_INTENSITY]: 'Tag Intensity',
  [Category.CDP_TAG]: 'CDP Tag',
};

/**
 * These are operators used in filters.
 * Take "EQUAL" in a tag filter for example, it means filtering the members with specific tag
 */
export enum FilterOperator {
  EQUAL = 1,
  NOT = 2,
  ANY = 3,
  GREATER_THAN = 4,
  LESS_THAN = 5,
  TOP = 6,
  IN_BETWEEN = 7,
  CONTAINED = 8,
  NOT_CONTAINED = 9,
  HAVE = 10,
  NOT_HAVE = 11,
}

/**
 * A map for filter operators and plain text.
 */
export const FilterOperatorName = {
  [FilterOperator.EQUAL]: 'is',
  [FilterOperator.NOT]: 'is not',
  [FilterOperator.ANY]: 'any',
  [FilterOperator.GREATER_THAN]: 'greater than',
  [FilterOperator.LESS_THAN]: 'less than',
  [FilterOperator.TOP]: 'top',
  [FilterOperator.IN_BETWEEN]: 'in between',
  [FilterOperator.CONTAINED]: 'contains',
  [FilterOperator.NOT_CONTAINED]: 'does not contain',
  [FilterOperator.HAVE]: 'has any value',
  [FilterOperator.NOT_HAVE]: 'is unknown',
};

/**
 * A map for filter operators and translation keys.
 */
export const FilterOperatorNameTranslationKey = {
  [FilterOperator.EQUAL]: 'member.filter.is',
  [FilterOperator.NOT]: 'member.filter.not',
  [FilterOperator.ANY]: 'any',
  [FilterOperator.GREATER_THAN]: 'common.greaterThan',
  [FilterOperator.LESS_THAN]: 'common.lessThan',
  [FilterOperator.TOP]: 'top',
  [FilterOperator.IN_BETWEEN]: 'member.filter.inBetween',
  [FilterOperator.CONTAINED]: 'contains',
  [FilterOperator.NOT_CONTAINED]: 'does not contain',
  [FilterOperator.HAVE]: 'member.filter.have',
  [FilterOperator.NOT_HAVE]: 'member.filter.notHave',
};

export enum GenderOptions {
  UNKNOWN = 'unknown',
  MALE = 'male',
  FEMALE = 'female',
}

export enum TagFilterOperator {
  EQUAL = '1-1',
  NOT = '2-1',
  ANY = '3-19',
  NOT_ANY = '2-19',
}

/**
 * For UI only
 */
export type TagsFilter = {
  category: Category.TAG | Category.ANY_TAG;
  parameter: {
    op: TagFilterOperator;
    tag_id: number[];
  };
};

/**
 * For API data only
 */
export type TagFilter = {
  category: Category.TAG;
  parameter: {
    op: FilterOperator;
    tag_id: number;
  };
};

/**
 * For API data only
 */
export type AnyTagFilter = {
  category: Category.ANY_TAG;
  parameter: {
    op: FilterOperator;
  };
};

export enum EngagementLevelValue {
  LEVEL_5 = 'level_5',
  LEVEL_4 = 'level_4',
  LEVEL_3 = 'healthy',
  LEVEL_2 = 'normal',
  LEVEL_1 = 'weak',
}

/**
 * For UI only
 */
export type EngagementLevelFilter = {
  category: Category.ENGAGEMENT_LEVEL;
  parameter: {
    op: FilterOperator;
    engagementLevel: EngagementLevelValue;
  };
};

/**
 * For API data only
 */
export type EngagementLevelFilterData = {
  category: Category.ENGAGEMENT_LEVEL;
  parameter: {
    op: FilterOperator;
    health: EngagementLevelValue;
  };
};

export type JoinTimeFilter = {
  category: Category.JOIN_TIME;
  // eslint-disable-next-line @typescript-eslint/ban-types
  parameter: {};
};

export type LastEngagedFilter = {
  category: Category.LAST_ENGAGED;
  // eslint-disable-next-line @typescript-eslint/ban-types
  parameter: {};
};

export type GenderFilter = {
  category: Category.GENDER;
  parameter: {
    gender: GenderOptions;
  };
};

export enum BirthDateFilterOperator {
  EQUAL_MONTH_DAY = '1-MD',
  // cspell:disable-next-line - special data value
  IN_BETWEEN_MONTH_DAY = '7-MDMD',
}

export type BirthdayFilter = {
  category: Category.BIRTHDAY;
  parameter: {
    op:
      | FilterOperator.GREATER_THAN
      | FilterOperator.EQUAL
      | FilterOperator.LESS_THAN
      | FilterOperator.IN_BETWEEN
      | string;
    value1: Dayjs | string | null;
    value2?: Dayjs | string | null;
  };
};

export type PhoneFilter = {
  category: Category.PHONE;
  parameter: {
    op: FilterOperator.HAVE | FilterOperator.NOT_HAVE;
  };
};

export type EmailFilter = {
  category: Category.EMAIL;
  parameter: {
    op: FilterOperator.HAVE | FilterOperator.NOT_HAVE;
  };
};

export type CustomerIdFilter = {
  category: Category.CUSTOMER_ID;
  parameter: {
    op: FilterOperator.HAVE | FilterOperator.NOT_HAVE;
  };
};

export enum PageViewFilterOperator {
  EQUAL = FilterOperator.EQUAL,
  NOT = FilterOperator.NOT,
  CONTAINED = FilterOperator.CONTAINED,
  NOT_CONTAINED = FilterOperator.NOT_CONTAINED,
}

export type PageViewFilter = {
  category: Category.PAGE_VIEW;
  parameter: {
    op: PageViewFilterOperator;
    url: string;
  };
};

export enum CampaignFilterOperator {
  EQUAL_BROADCAST = '1-7',
  EQUAL_TRACELINK = '1-8',
  EQUAL_DPM = '1-18', // not implement and might change in the future
  ANY_CAMPAIGN = '3-17',
  NOT_ANY_CAMPAIGN = '2-17',
}

export type CampaignFilter = {
  category: Category.BROADCAST_CLICK | Category.TRACELINK_CLICK | Category.CAMPAIGN_CLICK;
  parameter: {
    op: CampaignFilterOperator;
    value?: number | string;
  };
};

export type AverageTransactionRevenueFilter = {
  category: Category.AVERAGE_TRANSACTION_REVENUE;
  parameter: {
    op: FilterOperator.GREATER_THAN | FilterOperator.EQUAL | FilterOperator.LESS_THAN;
    value: number | string; // must not be negative number or float
  };
};

export type AccumulatedRevenueFilter = {
  category: Category.ACCUMULATED_REVENUE;
  parameter: {
    op: FilterOperator.GREATER_THAN | FilterOperator.EQUAL | FilterOperator.LESS_THAN;
    value: number | string; // must not be negative number or float
  };
};

export enum CreatedSourceValue {
  BIND_LINK = 'bind_link',
  PNP = 'pnp',
}

export type CreatedSourceFilter = {
  category: Category.CREATED_SOURCE;
  parameter: {
    op: FilterOperator.NOT | FilterOperator.ANY;
    source: CreatedSourceValue;
  };
};

export type OpenCountFilter = {
  category: Category.OPEN_COUNT;
  parameter: {
    op: FilterOperator.EQUAL | FilterOperator.ANY | FilterOperator.NOT_HAVE;
    ref_id: number | string;
  };
};

export type AutoSegmentFilter = {
  category: Category.AUTO_SEGMENT;
  parameter: {
    op: FilterOperator.GREATER_THAN;
    top: number;
  };
};

export enum TagIntensityFilterOperator {
  GREATER_THAN = FilterOperator.GREATER_THAN,
  NOT_HAVE = FilterOperator.NOT_HAVE,
  ANY = FilterOperator.ANY,
  NOT = FilterOperator.NOT,
}

/**
 * For API data only
 */
export type TagIntensityFilterData = {
  category: Category.TAG_INTENSITY;
  parameter: {
    op: TagIntensityFilterOperator;
    tag_id: number;
    value: number;
  };
};

/**
 * For UI only
 */
export type TagIntensityFilter = {
  category: Category.TAG_INTENSITY;
  parameter: {
    op: TagIntensityFilterOperator;
    tag_id: number[];
    value: number;
  };
};

export enum CdpTagFilterOperator {
  EQUAL = FilterOperator.EQUAL,
  NOT = FilterOperator.NOT,
}

/**
 * For API data only
 */
export type CdpTagsFilterData = {
  category: Category.CDP_TAG;
  parameter: {
    op: CdpTagFilterOperator;
    source: CdpDataSource;
    tag_id: number;
  };
};

/**
 * For UI only
 */
export type CdpTagsFilter = {
  category: Category.CDP_TAG;
  parameter: {
    op: CdpTagFilterOperator;
    tags: { source: CdpDataSource; id: number }[];
  };
};

export type Filter =
  | TagFilter
  | TagsFilter
  | AnyTagFilter
  | EngagementLevelFilter
  | EngagementLevelFilterData
  | JoinTimeFilter
  | LastEngagedFilter
  | GenderFilter
  | BirthdayFilter
  | PhoneFilter
  | EmailFilter
  | CustomerIdFilter
  | PageViewFilter
  | CampaignFilter
  | AverageTransactionRevenueFilter
  | AccumulatedRevenueFilter
  | CreatedSourceFilter
  | OpenCountFilter
  | AutoSegmentFilter
  | TagIntensityFilter
  | TagIntensityFilterData
  | CdpTagsFilter
  | CdpTagsFilterData;

// #endregion filters

// #region filter components

/**
 * For time period filter
 */
export enum DurationType {
  /** All time */
  ALL_DURATION = 1,
  /** In the past time */
  ACTIVE_DURATION = 2,
  /** Specified time period */
  FIX_DURATION = 3,
  /** No duration specified */
  NO_DURATION = 4,
}

export enum TimePeriodOptions {
  A_WEEK = 7,
  TWO_WEEKS = 15,
  A_MONTH = 30,
  THREE_MONTHS = 90,
}

/**
 * Time period filter data
 */
export interface DurationUiType {
  duration_type: DurationType;
  active_days: number;
  fix_days: [Dayjs, Dayjs];
}

/**
 * For API data
 */
export interface DurationData {
  days: number;
  start_date: Dayjs;
  end_date: Dayjs;
}
