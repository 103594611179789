import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';

import { useUpload } from 'lib/fileUpload';
import { useMessage } from 'shared/hooks/ui/useMessage';

const MAX_SIZE = 1024 * 1024;

export const useUploadImage = (): {
  onUploadImage: (
    e: ChangeEvent<HTMLInputElement>,
    callback: (uploadUrl: string) => void,
  ) => Promise<void>;
  isLoading: boolean;
} => {
  const { t } = useTranslation();

  const upload = useUpload();
  const { message: messageToast } = useMessage();

  const [isLoading, setIsLoading] = useState(false);

  const onUploadImage = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, callback: (uploadUrl: string) => void) => {
      if (e.currentTarget.files !== null && e.currentTarget.files.length === 1) {
        const file = e.currentTarget.files[0];
        const img = new Image();
        const objectUrl = window.URL.createObjectURL(file);
        img.src = objectUrl;
        img.onload = async () => {
          if (file.size > MAX_SIZE) {
            messageToast.error(t('common.fileSizeExceedWithSize', { size: '1 mb' }));
            return;
          }
          setIsLoading(true);

          const uploadUrl = await upload(file, 'Broadcast');

          callback(uploadUrl);
          setIsLoading(false);
        };
      }
    },
    [messageToast, t, upload],
  );

  return { onUploadImage, isLoading };
};
