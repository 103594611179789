/**
 * @param src image source
 * @description safely fallback image.decode()
 */
export async function imageDecodeAsync(src: string): Promise<HTMLImageElement> {
  const image = new Image();
  image.src = src;

  if (image.decode) {
    await image.decode();
    return image;
  }

  // jest enter this block
  return new Promise<typeof image>((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
}
