import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { MILLISECONDS_IN_SECOND } from 'AppConstants';

const useStore = createWithEqualityFn<{
  now: Date;
}>()(
  () => ({
    now: new Date(),
  }),
  shallow,
);

const fps = 5;
const timeoutMs = (1 * MILLISECONDS_IN_SECOND) / fps;

function updateNow() {
  useStore.setState({ now: new Date() });
  setTimeout(updateNow, timeoutMs);
}

updateNow();

/**
 * Retrieves the current time.
 *
 * Use this hook with caution, as it can lead to the component re-rendering
 * with every frame.
 */
export function useNow() {
  return useStore((state) => state.now);
}
