import type { ComponentType, JSX } from 'react';

import { PageErrorBoundary } from 'modules/PageErrorBoundary';

function withErrorBoundary<
  // Allowing P = {} to inherit from ComponentType
  // eslint-disable-next-line @typescript-eslint/ban-types
  P extends JSX.IntrinsicAttributes = {},
>(Component: ComponentType<P>): ComponentType<P> {
  return function WithErrorBoundary(props: P) {
    return (
      <PageErrorBoundary reloadWindow={true}>
        <Component {...props} />
      </PageErrorBoundary>
    );
  };
}

export function moduleWithErrorBoundary<
  Module extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  },
>(module: Module): Module {
  return new Proxy(module, {
    get(target: Module, prop: string) {
      return withErrorBoundary(target[prop]);
    },
  });
}
