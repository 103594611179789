/* eslint-disable @typescript-eslint/no-explicit-any */
const getElements = function (node: Document, tagName: string): any[] {
  if (!node || !node.getElementsByTagName(tagName)) {
    return [];
  }
  const elements = node.getElementsByTagName(tagName);

  return Array.prototype.slice.call(elements);
};

const getChildElements = function (
  node: Document | Element,
  tagName: string,
  namespace?: string,
): any[] {
  if (!node) {
    return [];
  }

  const elements = namespace
    ? node.getElementsByTagNameNS(namespace, tagName)
    : node.getElementsByTagName(tagName);

  if (!elements) {
    return [];
  }

  return Array.prototype.filter.call(
    elements,
    (element) => element.parentNode.nodeName === node.nodeName,
  );
};

const getElementTextContentArray = function (
  node: Document | Element,
  tagName: string,
  namespace?: string,
): string[] {
  const nodes = getChildElements(node, tagName, namespace);

  if (!nodes || nodes.length === 0) {
    return [];
  }

  return nodes.map((node) => node.textContent);
};

const getElementTextContent = function (
  node: Document | Element,
  tagName: string,
  namespace?: string,
): string | undefined {
  const array = getElementTextContentArray(node, tagName, namespace);

  return array.length === 0 ? undefined : array[0];
};

export { getElements, getChildElements, getElementTextContentArray, getElementTextContent };
