import Image from 'antd/es/image';
import { useTranslation } from 'react-i18next';

import { PARAM_PLACEHOLDER } from 'shared/components/Editor/LineMessageEditor/constants';

import type { ImageModule } from 'shared/components/Editor/LineMessageEditor/types';

import { Tooltip } from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';

const ImagePreviewWrapper = styled.div`
  max-width: 70%;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.white001};
`;

const ImagePreview = ({ src }: { src: string }) => (
  <ImagePreviewWrapper>
    <Image src={src} preview={false} />
  </ImagePreviewWrapper>
);

export const LineMessageEditorImageModulePreview = ({ message }: { message: ImageModule }) => {
  const { t } = useTranslation();
  const src = message.data.content_url;

  if (src !== PARAM_PLACEHOLDER.image) return <ImagePreview src={src} />;

  const parameter = message.parameters.find((parameter) =>
    parameter.mappingKey.startsWith('customImage'),
  );

  return (
    <Tooltip
      trigger="hover"
      title={t('message.paramImageEditor.imagePreview.tooltip', { paramName: parameter?.key })}
    >
      <ImagePreview src={src} />
    </Tooltip>
  );
};
