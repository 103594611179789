/**
 * Store application-wide route paths here for easy reuse across modules and components
 * Ideally this will be the single source of truth about application routes
 */
enum APP_ROUTES {
  apibroadcast = 'apibroadcast',
  apitoken = 'apitoken',
  appmarketing = 'appmarketing',
  /**
   * This old path is deprecated and should not be used in new code
   * We still keep it because some users might bookmark it and we need to redirect them to the new path
   * TODO: remove this path once the auto-reply optimization (Q424) is filly release
   */
  autoreplyOld = 'autoreplyv2',
  autoreply = 'autoreply',
  beacon = 'beacon',
  bindlink = 'bindlink',
  broadcast = 'broadcast',
  dashboardAcquisition = 'insight/acquisition',
  dashboardConversion = 'insight/conversion',
  dashboardEngagement = 'insight/engagement',
  dashboardMaacAiInsight = 'insight/maac-ai-insight',
  dashboardNotifications = 'insight/notifications',
  dashboardOverview = 'insight/overview',
  deeplinkOld = 'deeplinkv2',
  deeplink = 'deeplink',
  dpm = 'dpm',
  insight = 'insight',
  integrations = 'integrations',
  journey = 'journey',
  members = 'members',
  prize = 'prize',
  receipt = 'receipt-register',
  referral = 'rapid-referral',
  retarget = 'retarget',
  richmenu = 'richmenu',
  segment = 'segments',
  SmsPlus = 'sms-plus',
  surveycake = 'form/survey-cake',
  tagmanager = 'tagmanagement',
  templatelibrary = 'templatelibrary',
  tracelink = 'tracelink',
  webhook = 'webhook',
  widget = 'widget',
  zapier = 'zapier',

  // Integration games
  capsulemachine = 'capsulemachine',
  dicemonopoly = 'dicemonopoly',
  lotterypoetry = 'lotterypoetry',
  luckydraw = 'luckydraw',
  slotmachine = 'slotmachine',

  account = 'account',
  organization = 'organization',
  organizationBusinessHoursEdit = 'organization/business-hours/edit',
  billing = 'billing',
  rolemanagement = 'rolemanagement',
  setup = 'setup',
}

type BasePath = keyof typeof APP_ROUTES;
type IdString = number | string;

/**
 * Helper functions for generating create, copy, edit, and report paths etc.
 */
export const getMainPath = (basePath: BasePath): string => `/${APP_ROUTES[basePath]}`;

export const getWildcardPath = (basePath: BasePath): string => `/${APP_ROUTES[basePath]}/*`;

export const getWelcomePath = (basePath: BasePath): string => `/${APP_ROUTES[basePath]}/welcome`;

export const getCreatePath = (basePath: BasePath): string => `/${APP_ROUTES[basePath]}/create`;

export const getDuplicatePath = (basePath: BasePath, id: IdString): string =>
  `/${APP_ROUTES[basePath]}/create/${id}`;

export const getEditPath = (basePath: BasePath, id: IdString): string =>
  `/${APP_ROUTES[basePath]}/edit/${id}`;

export const getReportPath = (basePath: BasePath, id: IdString): string =>
  `/${APP_ROUTES[basePath]}/report/${id}`;
