/* eslint-disable no-restricted-imports */
import styled from '@emotion/styled';
import AntButton from 'antd/es/button';

import type { ButtonProps } from 'antd/es/button';

export const Button = styled(AntButton)`
  @layer styled-component {
    & {
      padding: 8px 16px;
      gap: 4px;

      .ant-btn-icon {
        font-size: 16px;
      }

      &.ant-btn:has(.ant-btn-icon) {
        padding-left: 12px;
      }

      &.ant-btn:has(.ant-btn-icon:only-child) {
        padding: 8px;
      }

      &.ant-btn.ant-btn-link {
        padding: 0;
      }
    }
  }
`;

export type { ButtonProps };
