import { useMemo, useState } from 'react';

import { useHandler } from './useEventCallback';

/**
 * Simple hook to create a full feature toggle.
 * @param initialValue - The initial active state of the toggle.
 * @example
 * ```ts
 * const toggleDialog = useToggle(false);
 * toggleDialog.active; // State of the toggle.
 * toggleDialog.toggle(); // Toggle the toggle.
 * toggleDialog.on(); // Set the toggle to active.
 * toggleDialog.off(); // Set the toggle to inactive.
 * ```
 * @returns
 */
export function useToggle(initialValue = false): {
  active: boolean;
  toggle: () => void;
  on: () => void;
  off: () => void;
} {
  const [active, setActive] = useState(initialValue);
  const toggle = useHandler(() => setActive((active) => !active));
  const on = useHandler(() => setActive(true));
  const off = useHandler(() => setActive(false));
  const returnValue = useMemo(() => ({ active, toggle, on, off }), [active, toggle, on, off]);
  return returnValue;
}
