import type { FlexImage } from '@line/bot-sdk';

import * as S from '../styled';
import { absoluteClassName, parseFlexBoxStyle, sizeClassName } from '../utils';

interface FlexImageComponentProps {
  parentLayout?: 'horizontal' | 'vertical';
  data: FlexImage;
}

const calcRatio = (ratio: string | undefined) => {
  if (ratio && /^\d+(\.\d*)?:\d+(\.\d*)?$/.test(ratio)) {
    const [width, height] = ratio.split(':').map(Number);
    return `${((height / width) * 100).toFixed(1)}%`;
  }
};

export const FlexImageComponent = ({ data }: FlexImageComponentProps) => {
  const { aspectRatio, aspectMode, size, position, ...styles } = data;
  const paddingBottom = calcRatio(aspectRatio);
  const style = parseFlexBoxStyle(styles);

  return (
    <div
      className={S.classNames(
        'image',
        sizeClassName(size),
        aspectMode,
        absoluteClassName(position),
      )}
      style={style}
    >
      <div>
        <a style={{ paddingBottom }}>
          <span
            style={{
              backgroundImage: `url('${data.url}')`,
            }}
          ></span>
        </a>
      </div>
    </div>
  );
};
