import type { PrizeRedeemDataParameter } from './parameters';
import type { FlexBubble } from '@line/bot-sdk';
import type { PrizeCategory } from 'modules/Prize/types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { ParameterType } from 'components/LineMessageEditor/models/templateDataAndTypes/ParameterType';

export const prizeNameKey = '<$var:prize_name>';

/**
 * @deprecated the template data will be fetched from server now
 */
export const prizeRedeemContentTemplate: FlexBubble = {
  type: 'bubble',
  size: 'mega',
  header: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'image',
        url: '',
        position: 'absolute',
        animated: true,
        margin: 'none',
        size: 'full',
        aspectRatio: '10:1.4',
        offsetTop: '0px',
        aspectMode: 'cover',
      },
    ],
    spacing: 'md',
    paddingAll: '0px',
    height: '40px',
    alignItems: 'center',
  },
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'text',
            text: prizeNameKey,
            size: 'xl',
            color: '#000000',
            weight: 'bold',
            align: 'center',
            margin: 'none',
            wrap: true,
          },
        ],
        paddingStart: '15px',
        paddingEnd: '15px',
      },
      {
        type: 'box',
        layout: 'vertical',
        contents: [
          {
            type: 'text',
            text: '立 即 領 取',
            color: '#FFFFFF',
            align: 'center',
            size: 'lg',
            margin: 'none',
            decoration: 'none',
            weight: 'regular',
          },
        ],
        background: {
          type: 'linearGradient',
          angle: '90deg',
          startColor: '',
          endColor: '',
        },
        paddingAll: '10px',
        cornerRadius: '100px',
        width: '200px',
        alignItems: 'center',
        margin: 'lg',
        spacing: 'none',
        action: {
          type: 'uri',
          uri: '<$var:prize_liff_page>',
          label: '立 即 領 取',
        },
      },
      {
        type: 'text',
        text: '請盡快兌換，以免錯失兌換權益',
        align: 'center',
        color: '#B8B7B9',
        size: 'sm',
        margin: 'md',
        wrap: true,
      },
    ],
    justifyContent: 'center',
    alignItems: 'center',
    paddingStart: '0px',
    paddingEnd: '0px',
  },
};

export const PrizeRedeemDataParameterNameData: PrizeRedeemDataParameter = {
  key: 'prize_name',
  type: ParameterType.standard,
  data: {
    function: 'prize_name',
    prize_setting_id: 0,
  },
};

export const PrizeRedeemDataParameterPageData: PrizeRedeemDataParameter = {
  key: 'prize_liff_page',
  type: ParameterType.standard,
  data: {
    function: 'prize_liff_page',
    prize_setting_id: 0,
  },
};

export type SelectedPrize = {
  value: number;
  label: string;
  category: PrizeCategory;
};

export interface PrizeRedeemData {
  module_id: LineFieldType.Prize;
  data: {
    notification_text: string;
    contents: FlexBubble;
  };
  parameters: PrizeRedeemDataParameter[];
  format: {
    prizeName: SelectedPrize['label'];
  };
  quick_reply?: { items: [] };
}

/**
 * @deprecated the default data is served from server now
 */
export const PrizeRedeem: PrizeRedeemData = {
  module_id: LineFieldType.Prize,
  data: {
    notification_text: '',
    contents: prizeRedeemContentTemplate,
  },
  format: {
    prizeName: '',
  },
  parameters: [PrizeRedeemDataParameterNameData, PrizeRedeemDataParameterPageData],
};
