import type { BoxProps } from 'components';

import { shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const IMAGEMAP_PREVIEW_IMAGE_WIDTH = 242;

/**
 * This wrapper for animated preview components is getting a little old
 * Note: pass `ml={0}` to eliminate margins when using `FormStepsWithPreviewLayout`
 */
export const PreviewWrapperAniWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['ml']),
})<BoxProps>`
  width: ${theme.sizes.linePreviewWidth};
  height: ${theme.sizes.linePreviewHeight};
  perspective: 500px;
  vertical-align: top;
  margin-left: ${({ ml }) => ml ?? '120px'};
  display: inline-block;
`;

export const PreviewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 2px solid ${theme.colors.neutral003};
  border-radius: 50px;
  box-sizing: border-box;
  background-color: ${theme.colors.white001};
  padding: 72px 20px;
  box-shadow: 50px 40px 50px ${theme.colors.neutral002};
  &::before {
    content: '';
    width: 71px;
    height: 6px;
    background-color: ${theme.colors.neutral002};
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 6.5px;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 48px;
    border: 6px solid ${theme.colors.neutral001};
    z-index: 0;
  }
`;
export const PowerButton = styled.div`
  position: absolute;
  top: calc(18% + 12.5px);
  right: -12px;
  height: 85px;
  width: 10px;
  border-radius: 40%;
  background-color: #181818;
`;

export const LineContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: #d6deea;
  overflow: auto;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const LineMessageBubble = styled.div`
  box-sizing: border-box;
  display: inline-block;
  margin: 16px 0 0 12px;
  padding: 5px 10px;
  width: auto;
  min-width: 30px;
  max-width: calc(100% - 64px);
  height: auto;
  min-height: 30px;
  line-height: 1.5;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  background-color: white;
  border-radius: 16px;
`;
export const SMSBubble = styled.div`
  display: inline-block;
  margin-left: 12px;
  margin-top: 16px;
  min-height: 30px;
  min-width: 30px;
  max-width: calc(100% - 20px);
  border-radius: 16px;
  height: auto;
  width: auto;
  box-sizing: border-box;
  padding: 5px 10px;
  background-color: white;
  overflow-wrap: break-word;
  line-height: 1.5;
`;
export const LineUserBlockWrapper = styled.div`
  width: 100%;
  text-align: right;
  ${LineMessageBubble} {
    margin-left: 0;
    margin-right: 10px;
  }
`;

export const UrlSpan = styled.span`
  position: relative;
  color: rgba(53, 146, 250);
  text-decoration: underline;
  cursor: pointer;
`;

export const CustomSpan = styled.span`
  position: relative;
  color: ${theme.colors.purple006};
`;

export const NameSpan = styled.span`
  position: relative;
  color: ${theme.colors.green005};
`;

interface ColoredSpanProps {
  color?: string;
}

export const ColoredSpan = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['color']),
})<ColoredSpanProps>`
  position: relative;
  color: ${({ color }) => color ?? theme.colors.green005};
`;

export const LineImageMapPreviewWrapper = styled.div`
  position: relative;
  width: calc(100% - 24px);
  max-width: ${IMAGEMAP_PREVIEW_IMAGE_WIDTH}px;
  height: auto;
  margin-top: 16px;
  margin-left: 12px;
  margin-bottom: 8px;
  border-radius: 9px;
`;
export const LineImageMapImg = styled.img`
  margin-bottom: 8px;
  width: 100%;
  height: auto;
  border-radius: 6px;
`;

interface LineImageMapCarouselImageWrapperProps {
  height: number | string;
}

export const LineImageMapCarouselImageWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['height']),
})<LineImageMapCarouselImageWrapperProps>`
  flex: 1 0 auto;
  position: relative;
  max-width: ${IMAGEMAP_PREVIEW_IMAGE_WIDTH}px;
  height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
  line-height: 0;
  margin-left: 12px;
  border-radius: 6px;
  background: ${theme.colors.white001};
  overflow: hidden;
`;

export const LineImageMapCarouselImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
`;

export const ImageMapActionsBlock = styled.div`
  position: absolute;
  background-color: ${theme.colors.neutral003};
  border: 1px dashed ${theme.colors.neutral005};
  transition: 0.5s background-color ease-in-out;
  cursor: pointer;
  opacity: 0;
  &:hover {
    opacity: 0.7;
  }
`;
export const HomeButton = styled.div`
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  box-sizing: border-box;
  border-radius: 50%;
  border: 7px solid ${theme.colors.neutral001};
`;

export const CarrouselScrollWrapper = styled.div`
  display: flex;
  position: relative;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 12px;
  padding-right: 12px;
  > div {
    min-width: 223px;
  }
`;
