import { keyframes, styled } from 'shared/utils/styled';
import { theme } from 'theme';

const backgroundLayer = keyframes`
  0%    { transform:translateX(-120%) }
  50%   { transform:translateX(-10%) }
  100%  { transform:translateX(120%)  }
`;

const BackgroundShape = styled.div`
  position: absolute;
  inset: 0;
  background-color: ${theme.colors.blue006};
  z-index: 0;
  animation: ${backgroundLayer} 2.5s;
  animation-timing-function: cubic-bezier(0.67, 0.03, 0.33, 0.93);
  animation-iteration-count: infinite;
  will-change: transform;
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  background-color: ${theme.colors.neutral003};
  overflow: hidden;
  display: inline-block;
`;

const LoadingSvg = () => {
  return (
    <svg
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 80 80"
      style={{ position: 'relative', zIndex: 1, transform: `scale(1.05)` }}
    >
      <path
        fill={theme.colors.white001}
        d="M36.1 22.9c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.9 0 1.5-.7 1.5-1.5 0-.9-.7-1.5-1.5-1.5zm0 0c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.9 0 1.5-.7 1.5-1.5 0-.9-.7-1.5-1.5-1.5zM.2 0v80h79.5V0H.2zm23.2 59.5H12.6c-.5 0-1 .2-1.5.5l-1.8 1.4c-.5.4-1.2 0-1.2-.6V50.4c0-4.2 2.1-8 5.4-10.2l.1-.1.1-.1c.2-.1.5-.3.8-.4l.6-.3c.1 0 .2-.1.3-.1 0 0 .1 0 .1-.1.2-.1.3-.2.6-.2h.1c.2-.1.3-.1.5-.1h.1c.2-.1.3-.1.5-.1.1 0 .2 0 .3-.1.2 0 .4-.1.6-.1.2-.1.5-.1.8-.1h5.4c.1 0 .3 0 .4.1.2 0 .3 0 .5.1.1 0 .2 0 .3.1.1 0 .2 0 .3.1.1 0 .2 0 .2.1.1 0 .1 0 .2.1.2 0 .4.1.6.2h.1c.1 0 .2.1.3.1.1 0 .2.1.3.2h.1c.1 0 .2.1.3.1 0 0 .1 0 .1.1.1.1.2.1.4.2 0 0 .1 0 .1.1.2.1.4.3.7.4.1.1.3.2.4.3.1.1.2.1.2.2.2.1.4.3.5.4.1 0 .1.1.2.2l.6.6c.2.2.4.5.6.7v.1c.2.2.4.5.5.8.1.2.2.5.4.7.1.2.2.3.2.5.2.4.4.8.5 1.3.1.2.1.4.2.6 0 .2.1.3.1.5.1.3.1.6.1 1s.1.7.1 1.1c0 2.9-1.2 5.6-3.2 7.6-1.7 1.3-4.4 2.5-7.3 2.5zm25.5-13.8c0 .8-.2 1.6-.4 2.3-1 3.5-3.5 6.5-6.9 7.8-1.2.5-2.6.8-4 .8-1.3 0-2.6-.1-3.8-.5 0 0 .1-.1.1-.2 1.5-2 2.5-4.4 2.6-7v-.7c0-1.3-.3-2.5-.8-3.7-.3-.7-.6-1.4-1-2.1-1.3-2.3-3.2-4.5-4.8-6.8-1.3-1.9-2.4-3.9-2.9-6-.1-.2-.1-.5-.2-.7-.1-.6-.1-1.1-.1-1.7V27c0-1.1.3-2.3.7-3.3.4-1 1.1-1.9 1.8-2.7 1.5-1.5 3.4-2.3 5.4-2.5 1.5-.2 3 0 4.4.6.8.3 1.5.8 2.1 1.3h.1c-.1.2-2.3 3 1.3 10.2.2.4.5.8.7 1.2.2.4.4.8.6 1.1.5.8 1.3 2.1 1.4 2.2 1 1.7 1.8 3.1 2.4 4.5.8 1.8 1.2 3.4 1.2 5.4.2.3.2.5.1.7zm18.6-16.3c-2.5 2-5.9 3.3-10.2 3.3-9.9 0-14.5-7-14.6-11.8v-.2H72c0 3.1-1.6 6.4-4.5 8.7zm-31.4-6.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c.9 0 1.5-.7 1.5-1.5 0-.9-.7-1.5-1.5-1.5z"
      />
    </svg>
  );
};

export const InitLoading = () => {
  return (
    <LoadingWrapper>
      <LoadingSvg />
      <BackgroundShape />
    </LoadingWrapper>
  );
};
