import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { AxiosError } from 'axios';

import { isAxiosError } from 'lib/axios';
import { ListResponseSchema } from 'shared/models/common';

const VerifyOtpErrorEnum = {
  AUTH_OTP_MISMATCH: 'AUTH_OTP_MISMATCH',
  AUTH_OTP_MAX_ATTEMPTS_EXCEEDED: 'AUTH_OTP_MAX_ATTEMPTS_EXCEEDED',
  AUTH_OTP_EXPIRED: 'AUTH_OTP_EXPIRED',
} as const;

const VerifyOtpErrorSchema = z.object({
  error_code: z.nativeEnum(VerifyOtpErrorEnum),
  errors: z.tuple([]),
  message: z.string(),
});

export type VerifyOtpError = z.infer<typeof VerifyOtpErrorSchema>;

export const isVerifyOtpError = (error: unknown): error is AxiosError<VerifyOtpError> => {
  const parsedResult = isAxiosError(error) && VerifyOtpErrorSchema.safeParse(error.response?.data);
  return parsedResult && parsedResult.success;
};

const InviteUserErrorEnum = {
  USER_ALREADY_EXISTS: 'USER_ALREADY_EXISTS',
  INVALID_TOKEN: 'INVALID_TOKEN',
} as const;

const InviteUserErrorSchema = z.object({
  error_code: z.nativeEnum(InviteUserErrorEnum),
  errors: z.tuple([]),
  message: z.string(),
});

export type InviteUserError = z.infer<typeof InviteUserErrorSchema>;

export const isInviteUserError = (error: unknown): error is AxiosError<InviteUserError> => {
  const parsedResult = isAxiosError(error) && InviteUserErrorSchema.safeParse(error.response?.data);
  return parsedResult && parsedResult.success;
};

export const isTooManyRequestsError = (error: unknown): boolean => {
  return isAxiosError(error) && error.response?.status === 429;
};

const WrongPasswordSchema = z.object({
  current_password: z.array(z.enum(['wrong current_password'])).optional(),
  new_password: z
    .array(
      z.enum([
        'new_password and confirm_new_password do not match',
        'This password is entirely numeric.',
      ]),
    )
    .optional(),
});

type WrongPassword = z.infer<typeof WrongPasswordSchema>;

export const isWrongPasswordError = (error: unknown): error is AxiosError<WrongPassword> => {
  return isAxiosError(error) && WrongPasswordSchema.safeParse(error.response?.data).success;
};

const NonFieldErrorSchema = z.object({
  non_field_errors: z.array(z.enum(['Same current_password and new_password'])),
});

type NonFieldError = z.infer<typeof NonFieldErrorSchema>;

export const isNonFieldError = (error: unknown): error is AxiosError<NonFieldError> => {
  const parsedResult = isAxiosError(error) && NonFieldErrorSchema.safeParse(error.response?.data);
  return parsedResult && parsedResult.success;
};

const UserItemSchema = z.object({
  id: z.number().int(),
  username: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string(),
  mobile: z.string().nullable(),
  language_code: z.string(),
  date_joined: z.string(),
  status: z.enum(['enabled', 'disabled']),
});

export type UserItem = z.infer<typeof UserItemSchema>;

export const accountApi = makeApi([
  {
    alias: 'userLogin',
    method: 'post',
    path: '/accounts/v2/user/login/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          email: z.string(),
          password: z.string(),
          remember_token: z.string().nullable(),
        }),
      },
    ],
    response: z.object({
      token: z.string().nullable(),
      requires_two_factor: z.boolean(),
    }),
    errors: [
      {
        status: 429,
        schema: z.object({}),
      },
    ],
  },
  {
    alias: 'userVerifyOtp',
    method: 'post',
    path: '/accounts/v2/user/verify_otp/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          email: z.string(),
          otp_code: z.string(),
          remember_device: z.boolean(),
        }),
      },
    ],
    response: z.object({ token: z.string(), remember_token: z.string().nullable() }),
    errors: [
      {
        status: 400,
        schema: VerifyOtpErrorSchema,
      },
    ],
  },
  {
    alias: 'userSendOtp',
    method: 'post',
    path: '/accounts/v2/user/send_otp/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          email: z.string(),
        }),
      },
    ],
    response: z.object({}),
  },
  {
    alias: 'users',
    method: 'get',
    path: '/accounts/v2/user/',
    response: ListResponseSchema.extend({
      results: UserItemSchema.array(),
    }),
  },
  {
    alias: 'userInvite',
    method: 'post',
    path: '/accounts/v2/user/invite/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          email: z.string(),
        }),
      },
    ],
    response: UserItemSchema,
    errors: [
      {
        status: 400,
        schema: InviteUserErrorSchema,
      },
    ],
  },
  {
    alias: 'userSetPassword',
    method: 'post',
    path: '/accounts/v2/user/password/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          token: z.string(),
          password: z.string(),
          confirm_password: z.string(),
        }),
      },
    ],
    response: UserItemSchema,
  },
  {
    alias: 'userInvitation',
    method: 'get',
    path: '/accounts/v2/user/invitation/',
    parameters: [
      {
        name: 'token',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: z.object({
      email: z.string(),
    }),
    errors: [
      {
        status: 400,
        schema: InviteUserErrorSchema,
      },
    ],
  },
  {
    alias: 'ssoLogin',
    method: 'post',
    path: '/accounts/v1/sso/login/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          uid: z.string(),
          id_token: z.string(),
        }),
      },
    ],
    response: z.object({
      token: z.string(),
    }),
  },
  {
    alias: 'userProvider',
    method: 'get',
    path: '/accounts/v2/user/provider/',
    parameters: [
      {
        name: 'domain',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: z.object({
      /** the requested domain */
      domain: z.string(),
      /** if the requested domain is allowed to sign in with email/passward */
      allow_password: z.boolean(),
      /** Provider id (pid) list that indicates which provider(s) the requested doamin belongs to */
      firebase_auth_providers: z.array(z.string()),
    }),
  },
  {
    alias: 'userUpdatePassword',
    method: 'put',
    path: '/accounts/v1/user/:userId/password/',
    parameters: [
      {
        name: 'userId',
        type: 'Path',
        schema: z.number().int(),
      },
      {
        name: 'body',
        type: 'Body',
        schema: z.object({
          current_password: z.string(),
          new_password: z.string(),
          confirm_new_password: z.string(),
        }),
      },
    ],
    response: z.string(),
    errors: [
      {
        status: 400,
        schema: NonFieldErrorSchema.or(WrongPasswordSchema),
      },
    ],
  },
]);
