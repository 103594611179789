import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const DayOfWeekEnum = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
} as const;

const DayOfWeekSchema = z.nativeEnum(DayOfWeekEnum);
export type DayOfWeek = z.infer<typeof DayOfWeekSchema>;

export const daysOrder = {
  [DayOfWeekEnum.MONDAY]: 0,
  [DayOfWeekEnum.TUESDAY]: 1,
  [DayOfWeekEnum.WEDNESDAY]: 2,
  [DayOfWeekEnum.THURSDAY]: 3,
  [DayOfWeekEnum.FRIDAY]: 4,
  [DayOfWeekEnum.SATURDAY]: 5,
  [DayOfWeekEnum.SUNDAY]: 6,
};

const OrganizationItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  uuid: z.string(),
  enable_two_factor: z.boolean(),
});

const OrganizationbusinessHoursSchema = z.record(
  DayOfWeekSchema,
  z.array(
    z.object({
      start_time: z.string(),
      end_time: z.string(),
    }),
  ),
);

export const organizationApi = makeApi([
  {
    alias: 'organization',
    method: 'get',
    path: '/organization/v1/organization/:organizationId/',
    response: OrganizationItemSchema,
  },
  {
    alias: 'organizationUpdate',
    method: 'patch',
    path: '/organization/v1/organization/:organizationId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationItemSchema.omit({ id: true }).partial(),
      },
    ],
    response: z.never(),
  },
  {
    alias: 'organizationBusinessHours',
    method: 'get',
    path: '/organization/v1/organization/:organizationId/business_hours/',
    response: OrganizationbusinessHoursSchema,
  },
  {
    alias: 'organizationBusinessHoursUpdate',
    method: 'put',
    path: '/organization/v1/organization/:organizationId/business_hours/',
    parameters: [
      {
        name: 'body',
        schema: OrganizationbusinessHoursSchema,
        type: 'Body',
      },
    ],
    response: OrganizationbusinessHoursSchema,
  },
]);

export type OrganizationbusinessHoursPayload = z.infer<typeof OrganizationbusinessHoursSchema>;
