export const EditSvg = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 96 96" version="1.1">
      <title>icon_edit</title>
      <desc>Created with sketchtool.</desc>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-480.000000, 0.000000)" fillRule="nonzero">
          <g id="icon_edit" transform="translate(480.000000, 0.000000)">
            <rect id="Rectangle" fill="#000000" opacity="0" x="0" y="0" width="96" height="96" />
            <path
              d="M78.8627378,20.0460643 C73.4827036,14.6513119 64.2207766,14.6513119 58.8402621,20.0460643 L19,59.9925616 L19,80 L39.0224757,80 L78.8628338,40.0535028 C84.3790714,34.5223766 84.3790714,25.5770941 78.8627378,20.0460643 Z M36.1622043,73.1714944 L25.8102659,73.1714944 L25.8102659,62.7924589 L54.6178607,33.9760153 L64.9697031,44.3554362 L36.1622043,73.1714944 Z M74.0277093,35.2051117 L69.7371102,39.5070451 L59.3852678,29.1280094 L63.6758669,24.826076 C66.4679404,22.0945582 71.2352515,22.0945582 74.0277093,24.826076 C76.8878845,27.6938711 76.8878845,32.3372202 74.0277093,35.2051117 Z"
              id="Shape"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
