import { z } from 'zod';

export const SpecialSellerTypeEnum = {
  Name: 'name',
  UnifiedBusinessNumber: 'unified_business_number',
} as const;

export const SpecialSellerTypeSchema = z.nativeEnum(SpecialSellerTypeEnum);

export type SpecialSellerType = z.infer<typeof SpecialSellerTypeSchema>;
