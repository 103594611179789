import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { VideoDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { Context } from 'components/LineMessageEditor/models';
import * as Validator from 'lib/validator';
import {
  notificationTextNameHelper,
  videoActionButtonTextHelper,
} from 'lib/validator/helper/validatorHelpers';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';
import { UrlUTMModule } from '../UrlUTMModule';
// import { VideoCoverEditor } from './VideoCoverEditor';

interface EditorProps {
  message: VideoDataType;
  rowIndex: number;
}

export const VideoPopContent = ({ message, rowIndex }: EditorProps) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);

  return (
    <S.ImageMapPopoverContent>
      {/* TODO: Hide this feature for now. will reopen it if users need to customize video cover. */}
      {/* <VideoCoverEditor rowIndex={rowIndex} message={message} /> */}
      <S.PopOverFieldTitle>
        <Trans i18nKey="message.notificationText" />
      </S.PopOverFieldTitle>
      <S.PopOverFieldsContent>
        <Validator.PopoverField
          name={notificationTextNameHelper({
            rowIndex: rowIndex,
            entityKey: '',
            editorType: 'VideoEditor',
          })}
          rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
          onChange={(e) => {
            dispatch('setVideoNotificationText', {
              rowIndex,
              text: e.currentTarget.value,
            });
          }}
          placeholder={t('message.inputWithLengthLimit', {
            fieldName: lowerFirst(t('message.notificationText')),
            length: 300,
          })}
          value={message.data.notification_text}
          checkOnChange={true}
          data-test="video-settings-notification-input"
        />
      </S.PopOverFieldsContent>
      <S.PopOverFieldsContent>
        <S.PopOverFieldTitle>
          <Trans i18nKey="message.video.actionButton" />
        </S.PopOverFieldTitle>
      </S.PopOverFieldsContent>
      <S.PopOverFieldsContent>
        <S.PopOverFieldTitle>
          <Trans i18nKey="message.button.name" />
        </S.PopOverFieldTitle>
        <S.PopOverFieldsContent>
          <Validator.PopoverField
            name={videoActionButtonTextHelper({
              rowIndex: rowIndex,
              entityKey: '',
              editorType: 'VideoEditor',
            })}
            rules={[Validator.Rules.maxLength(20), Validator.Rules.required]}
            onChange={(e) => {
              dispatch('setExternalLinkLabel', {
                rowIndex,
                label: e.currentTarget.value,
              });
            }}
            placeholder={t('message.inputWithLengthLimit', {
              fieldName: lowerFirst(t('message.button.name')),
              length: 20,
            })}
            value={message.data.video.externalLink.label}
            data-test="video-settings-button-name-input"
          />
        </S.PopOverFieldsContent>
        <S.PopOverFieldTitle>
          <Trans i18nKey="glossary.url" />
        </S.PopOverFieldTitle>
        <UrlUTMModule
          rowIndex={rowIndex}
          entityKey="0"
          urlValue={message.parameters[0].data.url}
          openExternal={message.parameters[0].data.open_external_browser}
          onOpenExternalChange={(open) => {
            dispatch('setExternalLinkOpen', {
              rowIndex,
              open,
            });
          }}
          onFieldsChange={(uri, utmFields) => {
            dispatch('setExternalLink', {
              rowIndex,
              url: uri,
              utm_campaign: utmFields.utm_campaign,
              utm_content: utmFields.utm_content,
              utm_medium: utmFields.utm_medium,
              utm_source: utmFields.utm_source,
            });
          }}
          UTMSourceValue={message.parameters[0].data.utm_source}
          onUTMSourceChange={(value) => {
            dispatch('setExternalLink', {
              rowIndex,
              url: message.parameters[0].data.url,
              utm_campaign: message.parameters[0].data.utm_campaign,
              utm_content: message.parameters[0].data.utm_content,
              utm_medium: message.parameters[0].data.utm_medium,
              utm_source: value,
            });
          }}
          UTMMediumValue={message.parameters[0].data.utm_medium}
          onUTMMediumChange={(value) => {
            dispatch('setExternalLink', {
              rowIndex,
              url: message.parameters[0].data.url,
              utm_campaign: message.parameters[0].data.utm_campaign,
              utm_content: message.parameters[0].data.utm_content,
              utm_medium: value,
              utm_source: message.parameters[0].data.utm_source,
            });
          }}
          UTMCampaignValue={message.parameters[0].data.utm_campaign}
          onUTMCampaignChange={(value) => {
            dispatch('setExternalLink', {
              rowIndex,
              url: message.parameters[0].data.url,
              utm_campaign: value,
              utm_content: message.parameters[0].data.utm_content,
              utm_medium: message.parameters[0].data.utm_medium,
              utm_source: message.parameters[0].data.utm_source,
            });
          }}
          UTMContentValue={message.parameters[0].data.utm_content}
          onUTMContentChange={(value) => {
            dispatch('setExternalLink', {
              rowIndex,
              url: message.parameters[0].data.url,
              utm_campaign: message.parameters[0].data.utm_campaign,
              utm_content: value,
              utm_medium: message.parameters[0].data.utm_medium,
              utm_source: message.parameters[0].data.utm_source,
            });
          }}
          tagList={message.parameters[0].data.tag_list}
          onTagChange={(tagList) => {
            dispatch('setExternalLinkTags', {
              rowIndex,
              tags: tagList,
            });
          }}
          editorType="VideoEditor"
          data-test="video-settings-uri-input"
        />
      </S.PopOverFieldsContent>
    </S.ImageMapPopoverContent>
  );
};
