import Slider from 'antd/es/slider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImageMapCustomPreviewBlock, RectData } from './types';
import type { PopoverProps } from 'shared/components/Popover';

import { Box } from 'components/layoutUtils';
import { Text } from 'components/Typography';
import { Button } from 'shared/components/Button';
import { Popover } from 'shared/components/Popover';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';

export const MxNLayoutEditor = ({
  open,
  onOpenChange,
  rectData,
  setBlocks,
  maxBlockCount = 20,
}: Pick<PopoverProps, 'open' | 'onOpenChange'> & {
  setBlocks: (blocks: ImageMapCustomPreviewBlock[]) => void;
  rectData: RectData;
  maxBlockCount?: number;
}) => {
  const { t } = useTranslation();

  const { message } = useMessage();

  const [rows, setRows] = useState<number>(1);
  const [columns, setColumns] = useState<number>(1);

  return (
    <Popover
      placement="right"
      open={open}
      onOpenChange={onOpenChange}
      align={{ offset: maxBlockCount === 20 ? [500, 100] : [403, 177] }}
      trigger="click"
      title={t('glossary.areaGadget')}
      content={
        <Box>
          <Box mb={20}>
            <Text>{t('message.areaGadget.column')}</Text>
            <Slider
              value={columns}
              min={1}
              max={10}
              onChange={(counts) => {
                if (rows * counts > maxBlockCount) {
                  message.warning(t('validation.areaGadget.exceedLimit'));
                } else {
                  setColumns(counts);
                }
              }}
            />
          </Box>
          <Box mb={20}>
            <Text>{t('message.areaGadget.row')}</Text>
            <Slider
              value={rows}
              min={1}
              step={1}
              max={10}
              defaultValue={1}
              range={false}
              onChange={(counts) => {
                if (typeof counts === 'number') {
                  if (counts * columns > maxBlockCount) {
                    message.warning(t('validation.areaGadget.exceedLimit'));
                  } else {
                    setRows(counts);
                  }
                }
              }}
            />
          </Box>
          <Box
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${columns}, 1fr)`,
              gridGap: '1px',
            }}
            border={`1px solid ${theme.colors.neutral007}`}
            borderRadius="8px"
            minHeight={300}
            minWidth={300}
            mb={20}
            backgroundColor={theme.colors.neutral007}
            overflow="hidden"
          >
            {new Array(columns * rows).fill(1).map((d, index) => {
              return (
                <Box
                  display="flex"
                  style={{
                    justifyContent: `center`,
                    alignItems: `center`,
                  }}
                  backgroundColor={theme.colors.white001}
                  key={`mxn-${index}`}
                >
                  {index + 1}
                </Box>
              );
            })}
          </Box>
          <Box textAlign="right">
            <Button
              onClick={() => {
                const totalBlocks = columns * rows;
                const height = rectData.height / rows;
                const width = rectData.width / columns;
                setBlocks(
                  new Array(totalBlocks).fill(1).map((d, index) => {
                    const top = height * Math.floor(index / columns);
                    const left = (index % columns) * width;
                    return {
                      id: Date.now() + index,
                      top,
                      left,
                      height,
                      width,
                    } as ImageMapCustomPreviewBlock;
                  }),
                );
              }}
            >
              {t('common.confirm')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};
