import { border, layout, position } from 'styled-system';

import { IMAGE_MAP_UI_IMAGE_WIDTH } from 'components/LineMessageEditor/constants';

import type { Area } from '@line/bot-sdk';
import type { BorderProps, LayoutProps, PositionProps } from 'styled-system';

import { keyframes, styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

interface ImageMapActionsBlockProps extends PositionProps, BorderProps, LayoutProps {
  blockIndex: number;
  isActive: boolean;
}
const show = keyframes`
  from {
    transform:scale(0.85) ;
    opacity:0.5;
  }

  to {
    transform:scale(1);
    opacity:1;
  }
`;

const ImageActionsBlock = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps('isActive', 'blockIndex'),
})<ImageMapActionsBlockProps>`
  ${position}
  ${layout}
  ${border}
  background-color:${({ isActive }) => (isActive ? 'rgba(0,255,192,0.5)' : 'transparent')};
  transition: 0.5s background-color ease-in-out;
  animation: ${show} 0.35s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: rgba(230, 232, 235, 0.7);
  }
  &::before {
    content: '${({ blockIndex }) => blockIndex}';
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    top: 8px;
    left: 8px;
    line-height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 255, 215, 0.7);
  }
`;

ImageActionsBlock.defaultProps = {
  position: 'absolute',
  border: 'solid 2px #00ffec',
};

const ActionsBlockWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
`;

interface Props {
  layoutWidth?: number;
  layoutHeight?: number;
  width?: number;
  height?: number;
  area: Area[];
  activeBlockIndex?: number;
}
export function ImageMapActiveBlock({
  width = 1040,
  height = 1040,
  layoutHeight = IMAGE_MAP_UI_IMAGE_WIDTH,
  layoutWidth = IMAGE_MAP_UI_IMAGE_WIDTH,
  ...props
}: Props) {
  return (
    <ActionsBlockWrapper>
      {props.area.map((a, index) => {
        const styledProps = {
          top: (a.y / height) * layoutHeight + 'px',
          left: (a.x / width) * layoutWidth + 'px',
          width: (a.width / width) * layoutWidth + 'px',
          height: (a.height / height) * layoutHeight + 'px',
        };
        return (
          <ImageActionsBlock
            blockIndex={index + 1}
            isActive={props.activeBlockIndex && index + 1 === props.activeBlockIndex ? true : false}
            {...styledProps}
            key={'imageMapActionActiveBlock' + index}
          />
        );
      })}
    </ActionsBlockWrapper>
  );
}
