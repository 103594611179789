import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import type { RefObject } from 'react';

import { Flex, StyledIcon, Text } from 'components';
import { LineMessageHorizontalDrag } from 'icons';
import { theme } from 'theme';

import * as S from '../Styled';
import { scrollToCarouselTarget } from '../utils/scrollToTarget';

interface SortableContainerProps {
  contents: number[];
  rowIndex: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  pillWrapperRef: RefObject<HTMLDivElement>;
}

export const SortableBoxes = SortableContainer((props: SortableContainerProps) => {
  return (
    <Flex pt={32} justifyContent="center" flexWrap="wrap">
      {props.contents.map((content, index) => {
        return (
          <SortableItem
            rowIndex={props.rowIndex}
            carrouselIndex={index}
            activeIndex={props.activeIndex}
            setActiveIndex={props.setActiveIndex}
            imgUrl=""
            index={index}
            pillWrapperRef={props.pillWrapperRef}
            key={`Row${props.rowIndex}Quick${index}Drag`}
          />
        );
      })}
    </Flex>
  );
});

interface SortablePointProps {
  rowIndex: number;
  activeIndex: number;
  carrouselIndex: number;
  imgUrl: string;
  setActiveIndex: (index: number) => void;
  pillWrapperRef: RefObject<HTMLDivElement>;
}

const SortableItem = SortableElement((props: SortablePointProps) => <SortablePoint {...props} />);

const SortablePoint = (props: SortablePointProps) => {
  return (
    <S.PointWrapper
      onClick={() => {
        scrollToCarouselTarget(props.pillWrapperRef, props.carrouselIndex);
      }}
      key={`quickReply-${props.carrouselIndex}-SortableItem`}
      id={`quickReply-${props.carrouselIndex}-SortableItem`}
    >
      <Text fontSize={14} lineHeight="20px" mb="1px" color={theme.colors.neutral005}>
        {props.carrouselIndex + 1}
      </Text>
      <S.Point active={props.carrouselIndex === props.activeIndex}>
        {!!props.imgUrl ? (
          <img
            style={{
              objectFit: 'contain',
            }}
            src={props.imgUrl}
            width={24}
            height={24}
            alt=""
          />
        ) : (
          <S.PointIcon />
        )}
      </S.Point>
      <StyledIcon
        component={<LineMessageHorizontalDrag />}
        color={theme.colors.neutral007}
        width={24}
        height={24}
        cursor="ew-resize"
      />
    </S.PointWrapper>
  );
};
