import type { DecimalAdjustType } from './decimalAdjust';

import { decimalAdjust as decimalAdjustFn } from './decimalAdjust';

export type Options =
  | {
      defaultValue?: number;
      max?: number;
      min?: number;
      decimalAdjust?: {
        type: DecimalAdjustType;
        exponent?: number;
      };
    }
  | undefined;

/**
 * Parse the input to an integer.
 *
 * @param val - Value to be transformed.
 * @param options - Optional options.
 * @param options.defaultValue - Default value to be returned if the parsed value is NaN.
 * @param options.max - Maximum value to be returned.
 * @param options.min - Minimum value to be returned.
 * @param options.decimalAdjust - Optional decimal adjust options.
 * @returns The parsed value.
 */
export function toNumber(
  val: unknown,
  { defaultValue = NaN, max = Infinity, min = -Infinity, decimalAdjust }: Options = {},
): number {
  let num =
    typeof val === 'number'
      ? val
      : typeof val === 'string'
        ? parseFloat(val)
        : (function tryNumber() {
            try {
              return Number(val);
            } catch (e) {
              return NaN;
            }
          })();
  if (Number.isNaN(num)) {
    num = defaultValue;
  }
  if (decimalAdjust) {
    num = decimalAdjustFn(decimalAdjust.type, num, decimalAdjust.exponent);
  }
  return Math.min(Math.max(num, min), max);
}

/**
 * Transform the input to a string.
 *
 * @param options - Optional options.
 * @returns A parser.
 */
export function number(options?: Options): (input: unknown) => number {
  return (input: unknown) => toNumber(input, options);
}
