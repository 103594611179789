import cn from 'classnames';
import { Editor, EditorState } from 'draft-js';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { TextDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type { EditorStateObject } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { RowToolBox } from 'components/LineMessageEditor/components';
import {
  onReferralAddTagClickV2,
  onUnicodeEmojiClick,
  onUrlNameClick,
} from 'components/LineMessageEditor/components/RichTextEditor/Decorators/strategy';
import { TextWrapper } from 'components/LineMessageEditor/components/Styled';
import { Context } from 'components/LineMessageEditor/models/models';
import { DecoratorType } from 'components/LineMessageEditor/models/templateDataAndTypes/DecoratorType';
import * as Validator from 'lib/validator';
import { Hint } from 'lib/validator/FieldInput';
import { richTextNameHelper } from 'lib/validator/helper/validatorHelpers';
import { useRichTextCheck } from 'lib/validator/hooks';
import { lowerFirst } from 'utils/string/changeCase';
import { fromUtf16toUnixText } from 'utils/string/textUtils';

import { handlePastedText } from './utils/handlePastedText';
import { RichTextFocusKit } from './RichTextFocusKit';

interface RichTextEditorProps {
  rowIndex: number;
  message: TextDataType;
  editorStateObject: EditorStateObject;
  setEditorState: (editorState: EditorState) => void;
  onDelete: () => void;
  onDeleteCallBack: () => void;
  isEmbed?: boolean;
  allowedTextDataTag: DecoratorType[];
}

export const RichTextEditor = ({
  rowIndex,
  editorStateObject,
  setEditorState,
  onDelete,
  message,
  isEmbed,
  allowedTextDataTag,
}: RichTextEditorProps) => {
  const { dispatch, store } = useContext(Context);
  const { t } = useTranslation();

  const { update, invalid, validate, value } = Validator.useField({
    name: richTextNameHelper({
      rowIndex: rowIndex,
      editorType: 'RichTextEditor',
      entityKey: '',
    }),
    rules: [
      Validator.Rules.maxLength(1600).bind(null, t(`validation.maxCharLength`, { count: 1600 })),
      Validator.Rules.required.bind(
        null,
        t('validation.pleaseInputField', {
          field: lowerFirst(t('message.module.text')),
        }),
      ),
    ],
    checkOnChange: false,
    value: editorStateObject
      ? editorStateObject.editorState
          .getCurrentContent()
          .getBlocksAsArray()
          .map((block) => block.getText())
          .reduce((pre, now) => pre + '\n' + now)
      : '',
    enableReinitialize: true,
  });

  useRichTextCheck(validate, value);

  /**
   * Note: `webDriverTestID` is a draft.js prop for generating `data-testid`
   */
  // TODO: Refactor event handlers by using `useHandler` hooks
  return (
    <TextWrapper
      onClick={() => {
        dispatch('setEditingRow', { rowIndex, richEditorId: editorStateObject.id });
      }}
      className={cn({
        'is-error': Validator.isInvalid(invalid),
        'is-drag-mode': store.isDragMode,
      })}
    >
      <Editor
        webDriverTestID="rich-text-editor"
        editorState={editorStateObject ? editorStateObject.editorState : EditorState.createEmpty()}
        onChange={(eState) => {
          setEditorState(eState);
          const finalText = fromUtf16toUnixText(
            eState
              .getCurrentContent()
              .getBlocksAsArray()
              .map((block) => block.getText())
              .reduce((pre, now) => pre + '\n' + now),
          );
          update(finalText);
        }}
        handlePastedText={handlePastedText}
        placeholder={t(`validation.maxCharLength`, { count: 1600 })}
      />
      {Validator.isInvalid(invalid) ? <Hint>{invalid ? invalid.errorMessage : null}</Hint> : null}
      <RichTextFocusKit
        isFocus={store.editorEditingRow === rowIndex}
        allowedTextDataTag={allowedTextDataTag}
        onClickLink={() => {
          onUrlNameClick(
            message.format.tagId.toString(),
            (rowIndex, type, urlKey) => {
              dispatch('addNameAndURLTag', { rowIndex, type, urlKey });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.Url,
          );
        }}
        onClickName={() => {
          onUrlNameClick(
            message.format.tagId.toString(),
            (rowIndex, type, urlKey) => {
              dispatch('addNameAndURLTag', { rowIndex, type, urlKey });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.Name,
          );
        }}
        onClickParam={() => {
          onUrlNameClick(
            message.format.tagId.toString(),
            (rowIndex, type, urlKey) => {
              dispatch('addNameAndURLTag', { rowIndex, type, urlKey });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.Custom,
          );
        }}
        onClickEmoji={(emoji) => {
          onUnicodeEmojiClick(editorStateObject.editorState, setEditorState, emoji);
        }}
        onClickReferralActivityLink={() => {
          onReferralAddTagClickV2(
            (rowIndex, type) => {
              dispatch('addReferralTag', { rowIndex, type });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.ReferralActivityLink,
          );
        }}
        onClickReferralInvitationLink={() => {
          onReferralAddTagClickV2(
            (rowIndex, type) => {
              dispatch('addReferralTag', { rowIndex, type });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.ReferralInvitationLink,
          );
        }}
        onClickReferralInviter={() => {
          onReferralAddTagClickV2(
            (rowIndex, type) => {
              dispatch('addReferralTag', { rowIndex, type });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.ReferralInviter,
          );
        }}
        onClickReferralInvitee={() => {
          onReferralAddTagClickV2(
            (rowIndex, type) => {
              dispatch('addReferralTag', { rowIndex, type });
            },
            rowIndex,
            editorStateObject.editorState,
            setEditorState,
            DecoratorType.ReferralInvitee,
          );
        }}
      />
      {!isEmbed ? (
        <RowToolBox
          moduleType={store.editorData[rowIndex].module_id}
          onRemoveClick={() => {
            onDelete();
            dispatch('deleteEditorData', { rowIndex });
          }}
        />
      ) : null}
    </TextWrapper>
  );
};
