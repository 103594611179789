import get from 'lodash/get';

import type { TFunction } from 'i18next';
import type { TaskSource } from 'modules/Prize/importCodeTaskContext';
import type {
  PrizeCategory,
  PrizeDetail,
  PrizeDetailGameCoupon,
  PrizeDetailIntegrationCoupon,
  PrizeDetailOfflineCoupon,
  PrizeDetailOnlineCoupon,
  PrizeDetailPointsCoupon,
  PrizeFormValues,
  PrizeRedemptionMode,
} from 'modules/Prize/types';

/**
 * Utility function to compare multiple form fields at once; used by the coupon code component
 */
export const isValueChanged = (
  prev: PrizeFormValues,
  curr: PrizeFormValues,
  pathList: string[],
): boolean => {
  return pathList.some((path) => get(prev, path) !== get(curr, path));
};

/**
 * Standardize prize points min and max across the module
 */
export enum PrizePointsDefaultValues {
  min = 1,
  max = 9999999,
}

export enum PrizeCodeStatus {
  /** 未領取 */
  Unowned = 'unowned',
  /** 已歸屬 */
  Owned = 'owned',
  /** 已領取 */
  Received = 'received',
  /** 已兌換，目前兌換券才有此狀態 */
  Used = 'used',
}

export const prizeCategoryTranslationKey: Record<PrizeCategory, string> = {
  online_coupon: 'glossary.coupon',
  offline_coupon: 'glossary.voucher',
  line_coupon: 'glossary.linePoints',
  custom_coupon: 'glossary.customCoupon',
  game_coupon: 'glossary.gameCoupon',
  integration_coupon: 'glossary.integrationCoupon',
  points_coupon: 'glossary.pointsCoupon',
};

export const prizeCategoryPremium: PrizeCategory[] = [
  'offline_coupon',
  'game_coupon',
  'custom_coupon',
  'integration_coupon',
  'points_coupon',
];

export const prizeRedemptionModeDisplayName = (
  t: TFunction,
  count = 1,
): Record<PrizeRedemptionMode, string> => ({
  fixed_code: t('prizeManagement.codeType.fixedCode', { count }),
  changed_code: t('prizeManagement.redemption.mode.changed_code', { count }),
  no_code: t('prizeManagement.codeType.noCode', { count }),
  unique_code: t('prizeManagement.redemption.mode.uniqueCode', { count }),
  unique_url: t('prizeManagement.redemption.mode.uniqueUrl', { count }),
  unique_url_with_password: t(`prizeManagement.redemption.mode.uniqueUrlWithCode`, { count }),
});

export const prizeRedemptionModeDescription = (
  t: TFunction,
): Record<PrizeRedemptionMode, string> => ({
  fixed_code: t('prizeManagement.redemption.mode.fixed_code.description'),
  changed_code: t('prizeManagement.redemption.mode.changed_code.description'),
  no_code: t('prizeManagement.codeType.noCode.desc'),
  unique_code: t('prizeManagement.redemption.mode.unique_code.description'),
  unique_url: t('prizeManagement.redemption.mode.uniqueUrl.description'),
  unique_url_with_password: t('prizeManagement.redemption.mode.uniqueUrlWithCode.description'),
});

export const prizeRedemptionModeTrackingName: Record<PrizeRedemptionMode, string> = {
  fixed_code: 'Fixed Code',
  changed_code: 'Changed Code',
  no_code: 'No Code',
  unique_code: 'Unique Code',
  unique_url: '',
  unique_url_with_password: '',
};

export enum BarcodeType {
  'Code 128' = 'code128',
  'Code 39' = 'code39',
  'Code 39-Checksum' = 'code39-checksum',
  'PZN' = 'pzn',
  'EAN-13' = 'ean13',
  'EAN-8' = 'ean8',
  'JAN' = 'jan',
  'ISBN-13' = 'isbn13',
  'ISBN-10' = 'isbn10',
  'ISSN' = 'issn',
  'UPC-A' = 'upca',
}
export const isOnlineCoupon = (
  prizeDetail: PrizeDetail,
): prizeDetail is PrizeDetailOnlineCoupon => {
  return prizeDetail.category === 'online_coupon';
};

export const isOfflineCoupon = (
  prizeDetail: PrizeDetail,
): prizeDetail is PrizeDetailOfflineCoupon => {
  return prizeDetail.category === 'offline_coupon';
};

export const isGameCoupon = (prizeDetail: PrizeDetail): prizeDetail is PrizeDetailGameCoupon => {
  return prizeDetail.category === 'game_coupon';
};

export const isIntegrationCoupon = (
  prizeDetail: PrizeDetail,
): prizeDetail is PrizeDetailIntegrationCoupon => {
  return prizeDetail.category === 'integration_coupon';
};

export const isPointsCoupon = (
  prizeDetail: PrizeDetail,
): prizeDetail is PrizeDetailPointsCoupon => {
  return prizeDetail.category === 'points_coupon';
};

// TODO: remove this type guard in 2024. Preserved for backward compatibility.
export const isNewParameters = (
  parameter: PrizeDetail['parameter'],
): parameter is Extract<PrizeDetail['parameter'], { settings: Record<string, unknown> }> => {
  return (parameter as { settings: Record<string, unknown> }).settings !== undefined;
};

export const isRedemptionUrl = (redemptionMode: PrizeRedemptionMode) => {
  switch (redemptionMode) {
    case 'changed_code':
    case 'unique_code':
    case 'fixed_code':
    case 'no_code':
      return false;
    case 'unique_url':
    case 'unique_url_with_password':
      return true;
    default:
      redemptionMode satisfies never;
      return false;
  }
};

export function getRedemtionModeString(
  t: TFunction,
  redemptionMode: PrizeRedemptionMode,
  count = 1,
): string {
  return isRedemptionUrl(redemptionMode)
    ? prizeRedemptionModeDisplayName(t, count)[redemptionMode]
    : t('prizeManagement.code');
}

export function redemptionImportingNotification(
  t: TFunction,
  redemptionMode: PrizeRedemptionMode,
): string {
  if (!isRedemptionUrl(redemptionMode)) return t('prizeManagement.code.importing');
  else if (redemptionMode === 'unique_url') return t('prizeManagement.uniqueUrl.importing');
  else if (redemptionMode === 'unique_url_with_password')
    return t('prizeManagement.uniqueUrlWithCode.importing');
  return '';
}

export function getRedemptionSuccessTranslation(t: TFunction, taskSource: TaskSource): string {
  if (taskSource.page === 'report') {
    if (!isRedemptionUrl(taskSource.mode)) {
      return t('prizeManagement.code.add.success');
    } else if (taskSource.mode === 'unique_url') {
      return t('prizeManagement.uniqueUrl.add.success');
    } else if (taskSource.mode === 'unique_url_with_password') {
      return t('prizeManagement.uniqueUrlWithCode.add.success');
    }
  } else if (taskSource.page === 'create') {
    if (!isRedemptionUrl(taskSource.mode)) {
      return t('prizeManagement.code.create.success');
    } else if (taskSource.mode === 'unique_url') {
      return t('prizeManagement.uniqueUrl.create.success');
    } else if (taskSource.mode === 'unique_url_with_password') {
      return t('prizeManagement.uniqueUrlWithCode.create.success');
    }
  }
  return '';
}

export function getRedemptionFailTranslation(t: TFunction, taskSource: TaskSource): string {
  if (taskSource.page === 'report') {
    if (!isRedemptionUrl(taskSource.mode)) {
      return t('prizeManagement.codePack.failedToImport.notification');
    } else if (taskSource.mode === 'unique_url') {
      return t('prizeManagement.uniqueUrl.add.fail');
    } else if (taskSource.mode === 'unique_url_with_password') {
      return t('prizeManagement.uniqueUrlWithCode.add.fail');
    }
  } else if (taskSource.page === 'create') {
    if (!isRedemptionUrl(taskSource.mode)) {
      return t('prizeManagement.code.create.fail');
    } else if (taskSource.mode === 'unique_url') {
      return t('prizeManagement.uniqueUrl.create.fail');
    } else if (taskSource.mode === 'unique_url_with_password') {
      return t('prizeManagement.uniqueUrlWithCode.create.fail');
    }
  }
  return '';
}
