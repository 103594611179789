import { memo } from 'react';

import type { ParamPopover } from './ParamPopover';
import type { ReactNode } from 'react';

import { css, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { theme } from 'theme';

interface ParamPopoversProps {
  isOverlay?: boolean;
  hasLeftSlot?: boolean;
  hasRightSlot?: boolean;
  leftSlot?: ReactNode;
  rightSlot?: ReactNode;
}

const ParamPopoversContainer = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['isOverlay']),
})<Pick<ParamPopoversProps, 'isOverlay'>>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  ${({ isOverlay }) =>
    isOverlay
      ? css`
          opacity: 0;
          transition: 0.45s opacity ease-in-out;
          background-color: rgba(44, 56, 72, 0.8);

          &:hover {
            opacity: 1;
          }
        `
      : css`
          background-color: ${theme.colors.white001};
        `};
`;

/**
 * The `ParamImagePopovers` is a container taking two slots of {@link ParamPopover}.
 * The reason why it isn't designed to become a `children` to accept as many popovers
 * as possible are:
 *
 *   (1) Restrict that there's only a possibility of having two slots -
 *       {@link ParamPopoversProps.leftSlot} and {@link ParamPopoversProps.rightSlot}.
 *       If the design needs more than two slots, then the interface needs more explicit
 *       adjustments. Furthermore, having more than two slots may not be a good UI design
 *       for triggering multiple popovers in such a small-sized container anyway.
 *
 *   (2) Toggling the slots by having two flags - {@link ParamPopoversProps.hasLeftSlot}
 *       and {@link ParamPopoversProps.hasRightSlot} to encapsulate the logical
 *       presentations for lower cognitive complexity. Specifying the props at the caller
 *       level is also more descriptive.
 *
 * `ParamImagePopovers` can present as an overlay or a white-background container by
 * specifying the prop {@link ParamPopoversProps.isOverlay}.
 */
export const ParamPopovers = memo(function ParamImagePopovers(props: ParamPopoversProps) {
  return (
    <ParamPopoversContainer isOverlay={props.isOverlay}>
      {props.hasLeftSlot ? props.leftSlot : null}
      {props.hasRightSlot ? props.rightSlot : null}
    </ParamPopoversContainer>
  );
});
