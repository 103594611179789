import { TEL_URL_PREFIX } from 'AppConstants';

import { isTel } from 'utils/string/isTel';

/**
 * @description
 * In most cases, you should use `isValidTelUrl()` instead.
 * @param url - The URL to check.
 * @returns True if the URL is starts with `tel:`.
 */
export function isTelUrl(url: string): url is `${typeof TEL_URL_PREFIX}${string}` {
  return url.startsWith(TEL_URL_PREFIX);
}

/**
 * @param url - The URL to check.
 * @param options - The options.
 * @param options.checkTelFn - The function to check the phone number.
 * @returns True if the URL is a telephone URL.
 */
// eslint-disable-next-line import/no-unused-modules -- Utility function
export function isValidTelUrl(
  url: string,
  { checkTelFn = isTel } = {},
): url is `${typeof TEL_URL_PREFIX}${string}` {
  return isTelUrl(url) && checkTelFn(url.substring(TEL_URL_PREFIX.length));
}
