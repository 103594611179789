/**
 * Listen for a combination of keys.
 */
export function createComboKeys<El extends HTMLElement | Window = Window>({
  el,
  combo,
  handler,
  timeoutDuration = 1000,
}: {
  combo: [string, string, ...Array<string>];
  handler: () => void;
  timeoutDuration: number;
  el?: El;
}): {
  abort: () => void;
  destroy: () => void;
} {
  const currentEl: El = (el || window) as El;
  let currentIndex = 0;
  let timeout: null | ReturnType<typeof setTimeout> = null;

  function clearTimeout() {
    if (!timeout) return;
    window.clearTimeout(timeout);
  }

  function clear() {
    currentIndex = 0;
    clearTimeout();
  }

  function matchCombo() {
    currentIndex++;
    if (currentIndex === combo.length) {
      clear();
      handler();
      return;
    }

    clearTimeout();
    timeout = setTimeout(clear, timeoutDuration);
  }

  const keyupHandler: EventListenerOrEventListenerObject = (event) => {
    if (!(event instanceof KeyboardEvent)) return;
    if (event.key !== combo[currentIndex]) {
      clear();
      return;
    }
    matchCombo();
  };

  currentEl.addEventListener('keyup', keyupHandler, { capture: true });
  window.addEventListener('click', clear, { capture: true });

  function destroy() {
    currentEl.removeEventListener('keyup', keyupHandler);
    window.removeEventListener('click', clear);
  }

  return {
    abort: clear,
    destroy,
  };
}
