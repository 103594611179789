export const SvgIconRichmenuS1Cell1 = () => (
  <svg width="1em" height="1em" viewBox="0 0 44 14">
    <g fill="none" fillRule="evenodd">
      <path d="M12 1h31v12H12z" stroke="currentColor" strokeWidth={2} />
      <text
        fontFamily="Roboto-Medium, Roboto"
        fontSize={14}
        fontWeight={400}
        fill="currentColor"
        transform="translate(-1 -4)"
      >
        <tspan x={0} y={13}>
          1
        </tspan>
      </text>
    </g>
  </svg>
);
