import { z } from 'zod';

import { TagMetadataItemSchema } from 'shared/models/tag/metadata';

/**
 * Member status
 */
export const MemberStatusEnum = {
  Auth: 'auth',
  Follow: 'follow',
  Unfollow: 'unfollow',
} as const;

const MemberStatusSchema = z.nativeEnum(MemberStatusEnum);

/**
 * Member gender
 */
export const MemberGenderEnum = {
  Male: 'male',
  Female: 'female',
  Other: 'other',
  Unknown: 'unknown',
} as const;

const MemberGenderSchema = z.nativeEnum(MemberGenderEnum);

/**
 * Member source type
 */
export const MemberSourceTypeEnum = {
  Line: 'line',
  Others: 'others',
} as const;

const MemberSourceTypeSchema = z.nativeEnum(MemberSourceTypeEnum);

// TODO: confirm these props can be re-cased
export const MemberSearchTypeEnum = {
  name: 'name',
  line_id: 'line_id',
  mobile: 'mobile',
  email: 'email',
  bind_id: 'bind_id',
  original_name_q: 'original_name_q',
  name_q: 'name_q',
  line_ids: 'line_ids',
  email_q: 'email_q',
  bind_id_q: 'bind_id_q',
} as const;

// TODO: same as above
export const MemberSearchTypeOptionEnum = {
  name: 'name_q',
  line_id: 'line_id',
  mobile: 'mobile',
  email: 'email',
  bind_id: 'bind_id',
} as const;

/**
 * Member item; this is mostly a copy of the existing SuperMember type with new properties added
 */
export const MemberItemSchema = z.object({
  id: z.number(),
  name: z.string().nullable(),
  avatar: z.string().nullable(),
  line_id: z.string(),
  source: z.string(),
  original_name: z.string().nullable(),
  email: z.string().nullable(),
  original_email: z.string().nullable(),
  mobile: z.string().nullable(),
  original_mobile: z.string().nullable(),
  status: MemberStatusSchema,
  create_by: z.string(),
  is_tester: z.boolean(),
  bind_id: z.string().nullable(),
  gender: MemberGenderSchema,
  health: z.string(),
  birthday: z.string(),
  last_engaged: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  bot: z.number(),
  tags: TagMetadataItemSchema.array(),
  type: MemberSourceTypeSchema,
});

export const ImportMemberCountryCodeEnum = {
  TW: 'TW',
  TH: 'TH',
  JP: 'JP',
  E164: 'E164',
} as const;

export const ImportMemberCountryCodeSchema = z.nativeEnum(ImportMemberCountryCodeEnum);

export type ImportMemberCountryCode = z.output<typeof ImportMemberCountryCodeSchema>;

export const ImportMemberFileTypeEnum = {
  LineUid: 'line_uid',
  CustomerId: 'customer_id',
  MobileId: 'mobile',
} as const;

/**
 * Used in notifications to display the name of the import source
 * Note that it matches the column name provided on the right side, not the ID on the left!
 */
export const ImportMemberColumnName = {
  LineUid: 'LINE uid',
  CustomerId: 'Customer ID',
  Mobile: 'Mobile',
} as const;

export const MemberListExportTypeEnum = {
  Total: 'total', // All LINE members
  Follow: 'follow', // LINE followers
  Unfollow: 'unfollow', // LINE blocked
} as const;
