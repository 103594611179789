import type { State, Utm } from './templateDataAndTypes/types';
import type { FlexBox } from '@line/bot-sdk';
import type { NewsData } from 'components/LineMessageEditor/models/templateDataAndTypes/news';

const defaultLabelBackgroundColor = '#EE220Cdd';

const createLabel = ({
  text = '',
  backgroundColor = defaultLabelBackgroundColor,
}: {
  text?: string;
  backgroundColor?: string;
}): FlexBox => ({
  type: 'box',
  layout: 'vertical',
  contents: [
    {
      type: 'text',
      text: text,
      size: 'md',
      color: '#ffffff',
      wrap: false,
    },
  ],
  backgroundColor: backgroundColor,
  position: 'absolute',
  cornerRadius: '2px',
  offsetTop: '12px',
  offsetStart: '12px',
  paddingTop: '5px',
  paddingBottom: '4px',
  paddingStart: '8px',
  paddingEnd: '8px',
});

export const newsStore = {
  reducers: {
    setNewsHeadlineImageUrl(
      prevState: State,
      payload: {
        rowIndex: number;
        url: string;
      },
    ): void {
      const [image] = (prevState.editorData[payload.rowIndex] as NewsData).data.contents.header
        .contents[0].contents;
      image.url = payload.url;
    },
    setNewsHeadlineTitle(
      prevState: State,
      payload: {
        rowIndex: number;
        text: string;
      },
    ): void {
      const [, , target] = (prevState.editorData[payload.rowIndex] as NewsData).data.contents.header
        .contents[0].contents;
      if (target.type === 'box' && target.contents) {
        target.contents[0].text = payload.text;
      }
    },
    setNewsNotificationText(
      prevState: State,
      payload: {
        rowIndex: number;
        notificationText: string;
      },
    ): void {
      const target = (prevState.editorData[payload.rowIndex] as NewsData).data;
      target.notification_text = payload.notificationText;
    },
    setNewsUrlData(
      prevState: State,
      payload: {
        rowIndex: number;
        carrouselIndex: number;
        text?: string;
        type?: 'url' | 'utm_campaign' | 'utm_medium' | 'utm_source' | 'utm_content';
        tagList?: string[];
        openExternal?: boolean;
      },
    ): void {
      const target = (prevState.editorData[payload.rowIndex] as NewsData).parameters[0];
      if (payload.openExternal !== undefined) {
        target.data.open_external_browser = payload.openExternal;
      }
      if (payload.tagList) {
        target.data.tag_list = payload.tagList;
      }
      if (payload.type && payload.text) {
        target.data[payload.type] = payload.text;
      }
    },
    setNewsLabelText(
      prevState: State,
      payload: {
        rowIndex: number;
        text: string;
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex] as NewsData;
      const isLabelExisted = target.format.label !== undefined;
      const backgroundColor = isLabelExisted
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          target.format.label!.backgroundColor
        : defaultLabelBackgroundColor;
      // 找個地方存編輯器要顯示的值
      if (isLabelExisted) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target.format.label!.text = payload.text;
      } else {
        target.format.label = {
          text: payload.text,
          backgroundColor,
        };
      }

      // 儲存 simulator 要顯示的值
      const contents = (prevState.editorData[payload.rowIndex] as NewsData).data.contents.header
        .contents[0].contents;
      const [, , , labelBox] = contents;
      const hasLabelBox = labelBox !== undefined;
      if (payload.text === '') {
        // 空白不顯示標籤
        if (hasLabelBox) {
          contents.splice(3 /* the index */, 1);
        }
      } else {
        if (hasLabelBox) {
          if (labelBox.type === 'box' && labelBox.contents) {
            labelBox.contents[0].text = payload.text;
          }
        } else {
          // create label box
          // @ts-expect-error Complicated type errors; there's something wrong here
          contents.push(createLabel({ text: payload.text, backgroundColor }));
        }
      }
    },
    setNewsLabelBackgroundColor(
      prevState: State,
      payload: {
        rowIndex: number;
        backgroundColor: string;
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex] as NewsData;
      const isLabelExisted = target.format.label !== undefined;
      // 找個地方存編輯器要顯示的值
      if (isLabelExisted) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        target.format.label!.backgroundColor = payload.backgroundColor;
      } else {
        target.format.label = {
          text: '',
          backgroundColor: payload.backgroundColor,
        };
      }

      // 儲存 simulator 要顯示的值
      const contents = (prevState.editorData[payload.rowIndex] as NewsData).data.contents.header
        .contents[0].contents;
      const [, , , labelBox] = contents;
      if (labelBox === undefined) {
        // @ts-expect-error See above
        contents.push(createLabel({ text: payload.text, backgroundColor }));
      } else if (labelBox.type === 'box' && labelBox.contents) {
        labelBox.backgroundColor = payload.backgroundColor;
      }
    },
    setNewsUrlAndUTM(
      prevState: State,
      payload: {
        rowIndex: number;
        url: string;
        utmFields: Utm;
      },
    ): void {
      const target = (prevState.editorData[payload.rowIndex] as NewsData).parameters[0];
      const urlAndUTM = {
        url: payload.url,
        ...payload.utmFields,
      };
      target.data = { ...target.data, ...urlAndUTM };
    },
    setRssUrl(
      prevState: State,
      payload: {
        rowIndex: number;
        rss: string;
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex] as NewsData;
      target.format.rss = payload.rss;
    },
    setNewsFeeds(
      prevState: State,
      payload: {
        rowIndex: number;
        feeds: { title: string; url: string }[];
      },
    ): void {
      payload.feeds.forEach((feed, i) => {
        const total_counts = (prevState.editorData[payload.rowIndex] as NewsData).data.contents.body
          .contents.length;
        // 奇數的 content 為分隔線
        const j = i * 2;
        if (
          j < total_counts &&
          (prevState.editorData[payload.rowIndex] as NewsData).data.contents.body.contents[j]
            .contents
        ) {
          // @ts-expect-error Object possible undefined
          (prevState.editorData[payload.rowIndex] as NewsData).data.contents.body.contents[
            j
          ].action.uri = feed.url;
          // @ts-expect-error Object possible undefined
          (prevState.editorData[payload.rowIndex] as NewsData).data.contents.body.contents[
            j
          ].contents[0].text = feed.title;
        }
      });
    },
    deleteNews(
      prevState: State,
      payload: {
        rowIndex: number;
        carrouselIndex: number;
      },
    ): void {
      prevState.editorData = prevState.editorData.filter((_, index) => index !== payload.rowIndex);
    },
  },
  effects: {},
};
