import { forwardRef, memo, useMemo } from 'react';

import type { ComponentPropsWithRef } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { keyframes, shouldNotForwardProps, styled } from 'shared/utils/styled';
import { css, cx, overrideCss } from 'shared/utils/styled/override';
import { theme } from 'theme';

// eslint-disable-next-line react-refresh/only-export-components
export enum ProgressBarState {
  Infinite = 'Infinite',
  Success = 'Success',
  Error = 'Error',
}

export type ProgressBarProps = {
  progress?: ProgressBarState | number;
  indicatorProps?: ComponentPropsWithRef<'div'> & OverrideComponentCssProps;
} & ComponentPropsWithRef<'div'> &
  OverrideComponentCssProps;

const staticIndicatorAfterAnimation = keyframes`
from {
  transform: translate(-200%);
}
to {
  transform: translate(200%);
}
`;

const Indicator = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})<OverrideComponentCssProps>`
  ${overrideCss}
`;

const leftAnimation = keyframes`
  10% {
    left: 0;
  }
  100% {
    left: 100%;
  }
`;

const rightAnimation = keyframes`
  0% {
    right: 100%;
  }
  90% {
    right: 0;
  }
`;

/**
 * @param progress - Progress bar state. Use `ProgressBarState` or a number
 *                   between 0 and 1.
 */
export const ProgressBar = styled(
  memo(
    forwardRef<HTMLDivElement, ProgressBarProps>(function ProgressBar(
      { progress = ProgressBarState.Infinite, indicatorProps, ...props },
      ref,
    ) {
      const indicatorStyledCss = useMemo(
        () =>
          cx(
            css`
              background-color: var(--progress-bar-indicator-color);
              border-radius: var(--progress-bar-border-radius);
              inset: 0;
              overflow: hidden;
              position: absolute;
              transition:
                background-color 0.3s ease-in-out,
                left 0.3s ease-in-out,
                right 0.3s ease-in-out;
              will-change: background-color, left, right;
            `,
            progress === ProgressBarState.Infinite
              ? css`
                  animation:
                    var(--progress-bar-indicator-animation-name-left) 2s ease-in infinite,
                    var(--progress-bar-indicator-animation-name-right) 2s ease-out infinite;
                `
              : undefined,
            progress === ProgressBarState.Error
              ? css`
                  background-color: var(--progress-bar-indicator-color-error);
                `
              : undefined,
            progress === ProgressBarState.Success
              ? css`
                  background-color: var(--progress-bar-indicator-color-success);
                `
              : undefined,
            typeof progress === 'number'
              ? css`
                  left: 0;
                  right: ${100 - 100 * progress}%;
                `
              : undefined,
            typeof progress === 'number'
              ? css`
                  &::after {
                    content: '';
                    position: absolute;
                    background-image: linear-gradient(
                      90deg,
                      ${theme.colors.blue001} 0%,
                      ${theme.colors.blue006} 70%,
                      ${theme.colors.blue006} 100%
                    );
                    inset: 0;
                    animation: var(--progress-bar-static-indicator-after-animation-name) 3s linear
                      infinite;
                  }
                `
              : undefined,
            indicatorProps?.styledCss,
          ),
        [indicatorProps?.styledCss, progress],
      );
      return (
        <div {...props} ref={ref}>
          <Indicator {...indicatorProps} styledCss={indicatorStyledCss} />
        </div>
      );
    }),
  ),
).withConfig({
  shouldForwardProp: shouldNotForwardProps(['styledCss']),
})`
  --progress-bar-background-color: ${theme.colors.neutral002};
  --progress-bar-border-radius: calc(var(--progress-bar-height) / 2);
  --progress-bar-height: 6px;
  --progress-bar-indicator-animation-name-left: ${leftAnimation};
  --progress-bar-indicator-animation-name-right: ${rightAnimation};
  --progress-bar-indicator-color-error: ${theme.colors.red006};
  --progress-bar-indicator-color-success: ${theme.colors.green006};
  --progress-bar-indicator-color: ${theme.colors.blue006};
  --progress-bar-static-indicator-after-animation-name: ${staticIndicatorAfterAnimation};
  background-color: var(--progress-bar-background-color);
  border-radius: var(--progress-bar-border-radius);
  height: var(--progress-bar-height);
  overflow: hidden;
  position: relative;
  width: 100%;
`;
