/* eslint-disable tsdoc/syntax */
import { safeString } from '@chatbotgang/etude/string/safeString';
import { CompositeDecorator, ContentState, convertFromRaw, EditorState } from 'draft-js';

import { PARAM_RICH_TEXT_FIELD_NAME_INDEX } from 'components/LineMessageEditor/constants';

import type { FlexText } from '@line/bot-sdk';
import type {
  CardDataType,
  CardRichEditorIds,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type { CarrouselDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/carrousel';
import type {
  TextDataType,
  TextFormatData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type { EditorStateObject } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { DraftDecorator } from 'draft-js';

import {
  composedDecorator,
  composedDecoratorWithParamRichTextEditor,
} from 'components/LineMessageEditor/components/RichTextEditor/Decorators/ComposedDecorator';
import { isTextDataRule } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { generateRichEditorId } from './richEditorId';

const { CardTitle: cardTitleIndex, CardDescription: cardDescriptionIndex } =
  PARAM_RICH_TEXT_FIELD_NAME_INDEX;

type SupportedDecoratorTypes = 'rich-text' | 'param-rich-text';
function getDecoratorsByType(
  type: SupportedDecoratorTypes = 'param-rich-text',
): Array<DraftDecorator> {
  return type === 'param-rich-text' ? composedDecoratorWithParamRichTextEditor : composedDecorator;
}

export function createEmptyEditor(type?: SupportedDecoratorTypes): EditorState {
  return EditorState.createEmpty(new CompositeDecorator(getDecoratorsByType(type)));
}

/**
 * A factory function to create `EditorState` either from 'text' or 'raw' without
 * constantly re-writing redundant code lines
 *
 * @param type
 * @param text
 * @param decoratorType
 */
export function createEditorFrom(
  type: 'text' | 'raw',
  text: string,
  decoratorType?: SupportedDecoratorTypes,
): EditorState {
  try {
    const safeStringText = safeString(text);
    return EditorState.createWithContent(
      type === 'raw'
        ? convertFromRaw(JSON.parse(safeStringText))
        : ContentState.createFromText(safeStringText),
      new CompositeDecorator(getDecoratorsByType(decoratorType)),
    );
  } catch (e) {
    return createEmptyEditor(decoratorType);
  }
}

/**
 * Pass in {@link CardRichEditorIds richEditorIds} and provide a callback function
 * to get an iterator-like mechanism to retrieve a generated {@link EditorStateObject}
 *
 * @param richEditorIds
 * @param callback
 * @param decoratorType - the decoratorType to create an empty editor
 */
export function populateEmptyEditorsByIds(
  richEditorIds: CardRichEditorIds,
  callback: (item: EditorStateObject) => void,
  decoratorType: SupportedDecoratorTypes = 'param-rich-text',
): void {
  richEditorIds.forEach((id) => {
    callback({ id, editorState: createEmptyEditor(decoratorType) });
  });
}

// TODO: Add unit test for `deriveEditorStateFromCardData`
/**
 * Retrieve an {@link EditorState} array from parsing {@link CardDataType cardData}
 *
 * (1) After the release of `texts-as-params`, {@link CardDataType cardData}
 *     should have the property of {@link TextFormatData.rowData cardData.format[i].rowData}
 *     to contain `rawData`
 *
 * (2) Before the release of `texts-as-params`, this function will fall back
 *     to using {@link FlexText cardData.contents.body.contents[i].text}
 *     to create {@link EditorState} from the pure texts.
 *
 *     Because back then {@link CardDataType.format cardData.format} wasn't supported.
 *     There could be a lot of existing outdated `CardEditor`'s data structure.
 *
 * (3) Fall back to create from empty strings if (1) and (2) for some reason
 *     weren't fulfilled.
 *
 * @param cardData
 */
function deriveEditorStateFromCardData(cardData: CardDataType): [EditorState, EditorState] {
  if (cardData?.format?.length && cardData.format[cardTitleIndex].rowData.length) {
    return [
      createEditorFrom('raw', cardData.format[cardTitleIndex].rowData),
      createEditorFrom('raw', cardData.format[cardDescriptionIndex].rowData),
    ];
  } else if (Boolean(cardData.contents.body.contents.length)) {
    const [titleData, descriptionData] = cardData.contents.body.contents;
    return [
      createEditorFrom('text', titleData.text),
      createEditorFrom('text', descriptionData.text),
    ];
  }
  return [createEditorFrom('text', ''), createEditorFrom('text', '')];
}

type SupportedToEditorStatePayloadType = TextDataType | CardDataType;
export type SupportedToEditorStateReturnType<T> = T extends TextDataType
  ? EditorStateObject
  : T extends CardDataType
    ? [EditorStateObject, EditorStateObject]
    : undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isCardData(data: any): data is CardDataType {
  return data?.contents?.body?.contents?.[0].type === 'text';
}

// TODO: Add unit test for `toEditorState`
/**
 * Convert message payload of `rowData` (note: should've been `rawData`)
 * to draft-js {@link EditorState} with an ID.
 *
 * @param payload - `message` of either RichTextEditor or CardEditor;
 *   pass in {@link CarrouselDataType.contents CarrouselData.data.contents[i]}
 *   for the conversion
 */
function toEditorState<T extends SupportedToEditorStatePayloadType>(
  payload: T,
): SupportedToEditorStateReturnType<T>;
function toEditorState(
  payload: SupportedToEditorStatePayloadType,
): EditorStateObject | [EditorStateObject, EditorStateObject] | undefined {
  if (isTextDataRule(payload)) {
    // TODO: Use this branch to replace the section in `parseToState`
  } else if (isCardData(payload)) {
    const editorStateArray = deriveEditorStateFromCardData(payload);
    const createEditorStateObject = (i: number) => ({
      /* TODO: Reuse `payload.format[i].richEditorId` to avoid redundant computation.
       *       This is a side effect from:
       *         - asana: https://tinyurl.com/up7bcmw3
       *         - pr:    https://tinyurl.com/yc56htan
       */
      id: generateRichEditorId(i),
      editorState: editorStateArray[i],
    });
    return [createEditorStateObject(cardTitleIndex), createEditorStateObject(cardDescriptionIndex)];
  }
}

export { toEditorState };
