/* eslint-disable no-restricted-imports -- Primary entry point for Styled Components */
import isPropValid from '@emotion/is-prop-valid';

export {
  default as styled,
  keyframes,
  css,
  ThemeProvider,
  createGlobalStyle,
} from 'styled-components';

// This only works with Styled Components
export function shouldNotForwardProps(props: string[]) {
  return (prop: string) => !props.includes(prop);
}

// For Emotion; pass this as the second parameter, and spread it when setting other options
export function shouldNotForwardEmotionProps(propsBlocked: string[]) {
  return {
    shouldForwardProp: (prop: string) => isPropValid(prop) && !propsBlocked.includes(prop),
  };
}
