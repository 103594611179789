import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { CdpDataSourceSchema } from 'shared/models/cdp';

const CdpTagSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  source: CdpDataSourceSchema,
});

const CdpTagsSchema = z.object({
  tags: CdpTagSchema.array(),
});

const CdpSettingsSchema = z.object({
  organization_id: z.number(),
  bot_id: z.number(),
  enable: z.boolean(),
});

export const cdpApi = makeApi([
  {
    alias: 'cdpTagMetadata',
    method: 'get',
    path: '/cdp/v1/tags/',
    response: CdpTagsSchema.strict(),
  },
  {
    alias: 'cdpSettings',
    method: 'get',
    path: '/cdp/v1/settings/bots/:botId/',
    response: CdpSettingsSchema.strict(),
  },
  // Note: this API does not appear to be in use anywhere in the application
  {
    alias: 'cdpSettingsUpdate',
    method: 'patch',
    path: '/cdp/v1/settings/bots/:botId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: CdpSettingsSchema.partial().strict(),
      },
    ],
    response: CdpSettingsSchema.strict(),
  },
  {
    alias: 'cdpMemberTags',
    method: 'get',
    path: '/cdp/v1/members/:id/tags/',
    response: CdpTagsSchema.strict(),
  },
]);

export type CdpTagsMetadataResponse = ZodiosResponseByAlias<typeof cdpApi, 'cdpTagMetadata'>;
