import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { MessageSchema } from 'shared/components/Editor/LineMessageEditor/types';
import { BroadcastStatusSchema } from 'shared/models/broadcast';
import { ExportResponseSchema, ListResponseSchema } from 'shared/models/common';
import {
  ReportMessageSchema,
  ReportShareSchema,
  ReportTotalSchema,
  ReportUrlSchema,
} from 'shared/models/report';
import { SegmentAudienceBaseSchema, SegmentStatusSchema } from 'shared/models/segment/common';

const BroadcastDetailResponseSchema = z.object({
  id: z.number().int(),
  bot: z.number().int(),
  name: z.string(),
  status: BroadcastStatusSchema,
  schedule_time: z.string(),
  created_at: z.string(),
  last_edited_at: z.string(),
  audience: z.number(),
  audience_info: z.object({
    id: z.number().int(),
    name: z.string(),
    size: z.number().int(),
    size_updated_time: z.string().nullable(),
    status: SegmentStatusSchema,
    deleted: z.boolean(),
  }),
  audience_name: z.string(),
  /** This is AKA sent count or push count */
  count: z.number(),
  dynamic_send_time: z.boolean(),
  is_open_count_trackable: z.boolean(),
  messages: MessageSchema.array(), // TODO: this should be equivalent to EditorDataType[]
  smart_send: z.boolean(),
});

const BroadcastItemReportSchema = z.object({
  clicks: z.number().int(),
  open_count: z.number().int().nullable(),
  push_count: z.number().int().nullable(),
  transaction_revenue: z.number(), // Float
  unique_clicks: z.number().int().nullable(),
});

const BroadcastItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  /** Note: LINE broadcast list items use the standard segment schema; only SMS Broadcast modifies this */
  audience: SegmentAudienceBaseSchema,
  status: BroadcastStatusSchema,
  schedule_time: z.string(),
  last_edited_at: z.string(),
  smart_send: z.boolean(),
  report: BroadcastItemReportSchema,
});

const BroadcastMetadataItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  schedule_time: z.string(),
  status: BroadcastStatusSchema,
  audience_name: z.string(),
});

const BroadcastCreateUpdateParametersSchema = z.object({
  schedule_time: z.string().optional(),
  name: z.string(),
  bot: z.number().int(),
  audience: z.number().int(),
  messages: MessageSchema.array(),
  /** This is always false, based on existing code; this is a DPM feature */
  dynamic_send_time: z.boolean(),
  status: BroadcastStatusSchema,
  smart_send: z.boolean(),
});

const BroadcastCreateUpdateReportSchema = z.object({
  open_count: z.number().int().nullable(),
  unique_open_count: z.number().int().nullable(),
  clicks: z.number().int().nullable(),
  adds_to_cart: z.number().int().nullable(),
  transactions: z.number().int().nullable(),
  transaction_revenue: z.number().nullable(), // Float
});

const BroadcastCreateUpdateResponseSchema = BroadcastDetailResponseSchema.extend({
  report: BroadcastCreateUpdateReportSchema,
});

export const broadcastApi = makeApi([
  {
    alias: 'broadcastList',
    method: 'get',
    path: '/line/v2/message/broadcast/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        // TODO: integrate "search" into the actual schema or make another enum
        schema: BroadcastStatusSchema.or(z.enum(['search'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: BroadcastItemSchema.strict().array(),
    }),
  },
  {
    alias: 'broadcastListMetrics',
    method: 'get',
    path: '/line/v1/message/broadcast/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        status: BroadcastStatusSchema,
        count: z.number().int(),
      })
      .strict()
      .array(),
  },
  {
    alias: 'broadcastListSearchResultCount',
    method: 'get',
    path: '/line/v2/message/broadcast/count/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: BroadcastStatusSchema.or(z.enum(['search'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        count: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'broadcastDetail',
    method: 'get',
    path: '/line/v1/message/broadcast/:broadcastId/',
    response: BroadcastDetailResponseSchema.strict(),
  },
  {
    alias: 'broadcastReport',
    method: 'get',
    path: '/line/v1/message/broadcast/:broadcastId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        id: z.number().int(),
        name: z.string(),
        audience: z.number(),
        status: BroadcastStatusSchema,
        schedule_time: z.string(),
        opencountv2_enable: z.boolean(),
        report: z.object({
          total: ReportTotalSchema.merge(
            z.object({
              unique_open_count: z.number().int().nullable(),
            }),
          ),
          urls: ReportUrlSchema.array(),
          messages: ReportMessageSchema.array(),
          shares: ReportShareSchema.array(),
        }),
      })
      .strict(),
  },
  {
    alias: 'broadcastMetadata',
    method: 'get',
    path: '/line/v1/message/broadcast/raw_broadcasts/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: BroadcastStatusSchema.or(z.enum(['search', 'failed'])).optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'is_open_count_trackable',
        schema: z.boolean().optional(),
        type: 'Query',
      },
    ],
    response: BroadcastMetadataItemSchema.strict().array(),
  },
  {
    alias: 'broadcastCreate',
    method: 'post',
    path: '/line/v1/message/broadcast/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BroadcastCreateUpdateParametersSchema.strict(),
      },
    ],
    response: BroadcastCreateUpdateResponseSchema.strict(),
  },
  {
    alias: 'broadcastUpdate',
    method: 'patch',
    path: '/line/v1/message/broadcast/:broadcastId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: BroadcastCreateUpdateParametersSchema.strict(),
      },
    ],
    response: BroadcastCreateUpdateResponseSchema.strict(),
  },
  {
    alias: 'broadcastDelete',
    method: 'delete',
    path: '/line/v1/message/broadcast/:broadcastId/',
    response: z.never(),
  },
  {
    alias: 'broadcastExport',
    method: 'post',
    path: '/line/v1/message/broadcast/export/',
    // Note: the original Broadcast Export service is configured in a strange way
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            bot_id: z.number().int(),
            start_date: z.string(),
            end_date: z.string(),
            pk: z.number().int().optional(), // This is also the broadcast ID
          })
          .strict(),
        type: 'Body',
      },
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: BroadcastStatusSchema.optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ExportResponseSchema.strict(),
  },
]);

export type BroadcastListItem = z.output<typeof BroadcastItemSchema>;

export type BroadcastListResponse = ZodiosResponseByAlias<typeof broadcastApi, 'broadcastList'>;

export type BroadcastListMetricsResponse = ZodiosResponseByAlias<
  typeof broadcastApi,
  'broadcastListMetrics'
>;

export type BroadcastDetailResponse = ZodiosResponseByAlias<typeof broadcastApi, 'broadcastDetail'>;

export type BroadcastCreateUpdateParameters = z.input<typeof BroadcastCreateUpdateParametersSchema>;

export type BroadcastMetadataItem = z.output<typeof BroadcastMetadataItemSchema>;

export type BroadcastMetadataResponse = ZodiosResponseByAlias<
  typeof broadcastApi,
  'broadcastMetadata'
>;
