import CameraFilled from '@ant-design/icons/CameraFilled';
import EnvironmentFilled from '@ant-design/icons/EnvironmentFilled';
import PictureFilled from '@ant-design/icons/PictureFilled';
import PictureOutlined from '@ant-design/icons/PictureOutlined';
import throttle from 'lodash/throttle';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { QuickReplyItemsProps } from '../types';
import type { RefObject, UIEvent as ReactUIEvent } from 'react';

import { Box, Loading, StyledIcon, Text } from 'components';
import { FileInput } from 'components/Input';
import { useAuth } from 'context/authContext';
import { DeleteSvg } from 'icons/delete';
import * as Validator from 'lib/validator';
import { labelTextNameHelper, messageNameHelper } from 'lib/validator/helper/validatorHelpers';
import { TriggerCodeModule } from 'modules/TriggerCodeModule';
import { Popover } from 'shared/components/Popover';
import { Radio } from 'shared/components/Radio';
import { Tooltip } from 'shared/components/Tooltip';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

import { useUploadImage } from '../hooks/useUploadImage';
import * as S from '../Styled';
import { QUICK_REPLY_CARD_ID_PREFIX } from '../types';
import { scrollToCarouselTarget } from '../utils/scrollToTarget';

interface QuickReplyEditorProps extends QuickReplyItemsProps {
  rowIndex: number;
  activeIndex: number;
  setActiveIndex: (index: number) => void;
  setMessage: (payload: { carrouselIndex: number; message: string }) => void;
  setActionType: (payload: {
    rowIndex: number;
    carrouselIndex: number;
    actionType: 'location' | 'message' | 'camera' | 'cameraRoll';
  }) => void;
  setLabel: (payload: { carrouselIndex: number; labelText: string }) => void;
  addQuickReplyCard: () => void;
  setImageUrl: (payload: { carrouselIndex: number; url: string }) => void;
  deleteQuickReply: (payload: { carrouselIndex: number }) => void;
  pillWrapperRef: RefObject<HTMLDivElement>;
}

export const PillsAndPopup = ({
  rowIndex,
  quickReplyItems,
  setMessage,
  activeIndex,
  setActiveIndex,
  setActionType,
  setLabel,
  addQuickReplyCard,
  setImageUrl,
  deleteQuickReply,
  pillWrapperRef,
}: QuickReplyEditorProps) => {
  const { t } = useTranslation();

  const authContext = useAuth();
  const { onUploadImage, isLoading } = useUploadImage();
  const { message } = useMessage();

  const [open, setOpen] = useState(false);

  const calcActiveIndex = throttle((e: ReactUIEvent<HTMLDivElement, UIEvent>) => {
    try {
      const index = Array.from((e.target as HTMLDivElement).children).findIndex((d) => {
        return (
          (d as HTMLDivElement).offsetLeft <= (e.target as HTMLDivElement).scrollLeft + 2 &&
          (d as HTMLDivElement).offsetLeft >= (e.target as HTMLDivElement).scrollLeft - 2
        );
      });

      if (index !== -1) setActiveIndex(index);
    } catch (error) {
      // setActiveIndex(0);
    }
  }, 300);

  return (
    <>
      <S.QuickReplyPillWrapper
        onScroll={(e) => {
          e.persist();
          calcActiveIndex(e);
        }}
        ref={pillWrapperRef}
      >
        {quickReplyItems.map((item, index) => {
          return (
            <Tooltip
              key={`PillsAndPopupTooltip_${index}`}
              placement="bottom"
              overlayClassName="transparent"
              align={{ offset: [0, -10] }}
              title={
                <S.DeleteBox data-test={`quickReplyDeleteButton-${index}`}>
                  <StyledIcon
                    fontSize={20}
                    component={<DeleteSvg />}
                    color={theme.colors.neutral007}
                    cursor="pointer"
                    onClick={() => deleteQuickReply({ carrouselIndex: index })}
                  />
                </S.DeleteBox>
              }
            >
              <S.QuickReplyPill
                id={`${QUICK_REPLY_CARD_ID_PREFIX}${rowIndex}_${index}`}
                onClick={() => {
                  setActiveIndex(index);
                  setOpen(true);
                }}
                isActive={activeIndex === index}
              >
                {item.action.type === 'camera' ? (
                  <StyledIcon mr="3px" fontSize={14} component={<CameraFilled />} />
                ) : null}
                {item.action.type === 'cameraRoll' ? (
                  <StyledIcon mr="3px" fontSize={14} component={<PictureFilled />} />
                ) : null}
                {item.action.type === 'location' ? (
                  <StyledIcon mr="3px" fontSize={14} component={<EnvironmentFilled />} />
                ) : null}
                {item.action.type === 'message' && item.imageUrl ? (
                  <S.QuickReplyPillImage src={item.imageUrl} />
                ) : null}
                {textParser(item.action.label, 5)}
              </S.QuickReplyPill>
            </Tooltip>
          );
        })}

        {quickReplyItems.length > 0 ? <Box minWidth="100%" /> : null}
      </S.QuickReplyPillWrapper>
      {quickReplyItems.map((item, index) => {
        return (
          <Popover
            key={`PillsAndPopupPopover_${index}`}
            open={open && index === activeIndex ? true : false}
            onOpenChange={setOpen}
            trigger="click"
            placement="right"
            title={t('message.quickReply') + `#${index + 1}`}
            align={{ offset: [0, 0] }}
            destroyTooltipOnHide={false}
            // This ensures the content is rendered so that the validator can check all fields.
            forceRender={true}
            content={
              <Box width="391px">
                <S.PopupSubTitle>
                  <Text>{t('message.module.text')}</Text>
                </S.PopupSubTitle>
                <S.PopupSubContent>
                  <Validator.FieldInput
                    name={labelTextNameHelper({
                      rowIndex: rowIndex,
                      entityKey: '',
                      editorIndex: index,
                      editorType: 'QuickReplyEditor',
                    })}
                    rules={[Validator.Rules.required, Validator.Rules.maxLength(20)]}
                    enableReinitialize={true}
                    placeholder={t('message.inputWithLengthLimit', {
                      fieldName: lowerFirst(t('glossary.message')),
                      length: 20,
                    })}
                    value={item.action.label}
                    onChange={(e) =>
                      setLabel({
                        carrouselIndex: index,
                        labelText: e.target.value,
                      })
                    }
                    onBlur={(e) =>
                      setLabel({
                        carrouselIndex: index,
                        labelText: e.target.value,
                      })
                    }
                    defaultChecked={false}
                    checkOnChange={true}
                  />
                </S.PopupSubContent>
                <S.PopupSubTitle>
                  <Text>{t('glossary.actionArea')}</Text>
                </S.PopupSubTitle>
                <S.PopupSubContent>
                  <Radio
                    checked={item.action.type === 'message'}
                    onClick={() => {
                      setActionType({
                        rowIndex,
                        carrouselIndex: index,
                        actionType: 'message',
                      });
                    }}
                    style={{ display: 'block' }}
                  >
                    <Trans i18nKey="message.send" />
                  </Radio>
                  <Radio
                    checked={item.action.type === 'camera'}
                    onClick={() => {
                      setActionType({
                        rowIndex,
                        carrouselIndex: index,
                        actionType: 'camera',
                      });
                    }}
                    style={{ display: 'block' }}
                  >
                    <Trans i18nKey="message.openCamera" />
                  </Radio>
                  <Radio
                    checked={item.action.type === 'cameraRoll'}
                    onClick={() => {
                      setActionType({
                        rowIndex,
                        carrouselIndex: index,
                        actionType: 'cameraRoll',
                      });
                    }}
                    style={{ display: 'block' }}
                  >
                    <Trans
                      values={{ object: lowerFirst(t('glossary.image')) }}
                      i18nKey="common.uploadObject"
                    />
                  </Radio>
                  <Radio
                    checked={item.action.type === 'location'}
                    onClick={() => {
                      setActionType({
                        rowIndex,
                        carrouselIndex: index,
                        actionType: 'location',
                      });
                    }}
                    style={{ display: 'block' }}
                  >
                    <Trans i18nKey="common.shareLocation" />
                  </Radio>
                </S.PopupSubContent>
                <Box>
                  {item.action.type === 'message' ? (
                    <>
                      <Validator.FieldInput
                        name={messageNameHelper({
                          rowIndex: rowIndex,
                          entityKey: '',
                          editorIndex: index,
                          editorType: 'QuickReplyEditor',
                        })}
                        rules={[Validator.Rules.required, Validator.Rules.maxLength(20)]}
                        enableReinitialize={true}
                        placeholder={t('message.inputWithLengthLimit', {
                          fieldName: lowerFirst(t('glossary.message')),
                          length: 20,
                        })}
                        value={item.action.text}
                        onChange={(e) =>
                          setMessage({
                            carrouselIndex: index,
                            message: e.target.value,
                          })
                        }
                        onBlur={(e) =>
                          setMessage({
                            carrouselIndex: index,
                            message: e.target.value,
                          })
                        }
                        defaultChecked={false}
                        checkOnChange={true}
                      />
                      <TriggerCodeModule
                        triggerCode={item.action.text}
                        currentChannel={authContext.state.currentChannel}
                        mt="16px"
                      />
                      <S.PopupSubTitle>
                        <Text>{t('message.quickReply.icon.label')}</Text>
                      </S.PopupSubTitle>
                      <S.PopupSubContent>
                        <Box mb="7px" color={theme.colors.neutral007}>
                          {t('common.aspectRatioWithRatio', { ratio: '1:1' })}
                        </Box>
                        <Tooltip
                          placement="right"
                          overlayClassName="transparent"
                          align={{ offset: [0, 0] }}
                          title={
                            item.imageUrl ? (
                              <S.DeleteBox>
                                <StyledIcon
                                  fontSize={20}
                                  component={<DeleteSvg />}
                                  color={theme.colors.neutral007}
                                  cursor="pointer"
                                  onClick={() => setImageUrl({ carrouselIndex: index, url: '' })}
                                />
                              </S.DeleteBox>
                            ) : undefined
                          }
                        >
                          <S.UploadImageLabel type="imageMap" bgUrl={item.imageUrl ?? ''}>
                            <FileInput
                              id={`quickReply-imageUploadInput-${index}`}
                              accept="image/jpeg,image/png,image/gif"
                              onChange={(e) =>
                                onUploadImage(e, (uploadUrl) => {
                                  setImageUrl({ carrouselIndex: index, url: uploadUrl });
                                })
                              }
                            />
                            {!isLoading && item.imageUrl === '' ? t('common.upload') : null}
                            {isLoading ? <Loading /> : null}
                            {!isLoading && item.imageUrl !== '' ? (
                              <S.ImageHoverLayer>
                                <PictureOutlined />
                              </S.ImageHoverLayer>
                            ) : null}
                          </S.UploadImageLabel>
                        </Tooltip>
                      </S.PopupSubContent>
                    </>
                  ) : null}
                </Box>
              </Box>
            }
          >
            <Box
              position="absolute"
              top="0px"
              right="0px"
              zIndex={1}
              width="1px"
              height="36px"
              style={{ pointerEvents: 'none' }}
            />
          </Popover>
        );
      })}
      {quickReplyItems.length > 0 ? (
        <S.AddButton
          style={{
            margin: 0,
            fontSize: '30px',
            position: 'absolute',
            right: '-20px',
            top: '0',
            width: '62px',
            lineHeight: '30px',
            zIndex: 2,
          }}
          data-test="quickReplyAddButton"
          onClick={() => {
            if (quickReplyItems.length >= 13) {
              message.warning(t('message.quickReply.limit'));
              return;
            }
            addQuickReplyCard();
            setTimeout(() => {
              scrollToCarouselTarget(pillWrapperRef, quickReplyItems.length);
            }, 0);
          }}
        >
          +
        </S.AddButton>
      ) : null}
    </>
  );
};

function textParser(text: string, length: number) {
  if (text.length > length) return text.substring(0, length) + '...';
  return text;
}
