import styled from '@emotion/styled';

import type { Properties } from 'csstype';

interface EllipsisTextProps {
  maxLine?: Properties['WebkitLineClamp'];
}

export const EllipsisText = styled.span<EllipsisTextProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxLine }) => maxLine ?? 1};
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  hyphens: auto;
`;

// A pared down version of the component above for inline usage
export const EllipsisTextInline = styled.span`
  word-break: break-word;
  overflow: hidden;
  hyphens: auto;
  text-overflow: ellipsis;
`;
