import { space, width } from 'styled-system';

import type { SpaceProps, WidthProps } from 'styled-system';

import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

export const TriggerCodeModuleWrapper = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})<SpaceProps & WidthProps>`
  box-sizing: border-box;
  ${space}
  ${width}
`;

TriggerCodeModuleWrapper.defaultProps = {
  width: '100%',
};
