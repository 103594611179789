import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { AuthAxios } from 'lib/axios';
import { accountApi } from 'shared/api/account';
import { autoReplyApi } from 'shared/api/autoReply';
import { bindlinkApi } from 'shared/api/bindlink';
import { broadcastApi } from 'shared/api/broadcast';
import { broadcastSmsApi } from 'shared/api/broadcastSms';
import { cdpApi } from 'shared/api/cdp';
import { deeplinkApi } from 'shared/api/deeplink';
import { deeplinkBatchApi } from 'shared/api/deeplinkBatch';
import { integrationsApi } from 'shared/api/integrations';
import { journeyApi } from 'shared/api/journey';
import { memberApi } from 'shared/api/member';
import { npsApi } from 'shared/api/nps';
import { organizationApi } from 'shared/api/organization';
import { receiptRegisterApi } from 'shared/api/receiptRegister';
import { segmentApi } from 'shared/api/segment';
import { tagApi } from 'shared/api/tag';
import { templateApi } from 'shared/api/template';
import { testerApi } from 'shared/api/tester';
import { tracelinkApi } from 'shared/api/tracelink';
import { validationPlugin } from 'shared/lib/zodios/validationPlugin';

const apiClient = new Zodios(
  [
    ...accountApi,
    ...autoReplyApi,
    ...broadcastApi,
    ...broadcastSmsApi,
    ...cdpApi,
    ...deeplinkApi,
    ...deeplinkBatchApi,
    ...integrationsApi,
    ...journeyApi,
    ...memberApi,
    ...organizationApi,
    ...receiptRegisterApi,
    ...segmentApi,
    ...tagApi,
    ...testerApi,
    ...tracelinkApi,
    ...templateApi,
    ...bindlinkApi,
    ...npsApi,
  ],
  {
    axiosInstance: AuthAxios,
  },
);

apiClient.use(validationPlugin);

export { apiClient };

export const apiHooks = new ZodiosHooks('maac', apiClient);
