import { DEFAULT_MAPPING_KEY } from 'components/LineMessageEditor/constants';

import type {
  DefaultMappingKey,
  ParamRichTextFieldNamePrefixed,
} from 'components/LineMessageEditor/constants';

export function transformKey(key: string): string {
  return key.replace('<$var:', '').replace('>', '');
}

export function composeKey(key: string): string {
  return `<$var:${key}>`;
}

/**
 * Get a generated `mappingKey` for any kind of parameter in `LineMessageEditor`
 * This should be mapped back to a module's data `key` and its parameter `mappingKey`.
 *
 * DISCLAIMER: Using unix timestamp is only a temporary solution of pseudo randomness.
 *             In short term, it'll suffice current product user stories, but it won't
 *             be an appropriate way of generating ID-like values.
 *             This function shouldn't be maintained further for complex scenarios.
 *
 * @param prefix - The prefix is a key of {@link DEFAULT_MAPPING_KEY}
 * @param fieldName
 */
export function generateMappingKey(
  prefix: DefaultMappingKey,
  fieldName?: ParamRichTextFieldNamePrefixed,
): string {
  if (prefix === DEFAULT_MAPPING_KEY.customText && fieldName !== undefined) {
    return `${DEFAULT_MAPPING_KEY.customText}In${fieldName}_${Date.now()}`;
  }
  return `${DEFAULT_MAPPING_KEY[prefix]}_${Date.now()}`;
}

/**
 * Check if `predicate` matches a type of `mappingKey` or if it's an exact `mappingKey`
 *
 * @param type - A key of {@link DEFAULT_MAPPING_KEY}
 * @param predicate - A `mappingKey` value to determine if it matches the `type`
 * @param key - An ideally exact `mappingKey` to see if it's as same as `predicate`
 */
export function isMappingKey(type: DefaultMappingKey, predicate?: string, key?: string): boolean {
  if (!predicate) {
    return false;
  }
  return !key ? predicate.startsWith(type) : predicate === key;
}

/**
 * create variable regular expression pattern
 * @param key variable name
 * @returns RegExp
 */
export const createVariablePattern = (key: string): RegExp => new RegExp(`<\\$var:${key}>`, 'g');

/**
 * pattern for grouped variables
 */
export const variableRegexp = /^<\$var:(?<name>\w+)>$/;

/**
 * check input match variable regular expression pattern
 * @param value any
 */
export const isVariable = (value: unknown): boolean =>
  typeof value === 'string' && variableRegexp.test(value);

/**
 * find key in variable string
 * @param value string
 */
export const getVariableName = (value: string): string | undefined => {
  return variableRegexp.exec(value)?.groups?.name;
};
