import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CSSProperties, Dispatch, SetStateAction } from 'react';

import { Flex } from 'components/layoutUtils';
import { Text } from 'components/Typography';
import { Input } from 'shared/components/Input';
import { theme } from 'theme';

export const SearchBar = memo(function SearchBar({
  searchValue,
  setSearchValue,
  hasSearchValue,
  searchResultCount,
  onSearch,
  onSearchEmpty,
  placeholder,
  style,
}: {
  searchValue: string;
  setSearchValue: Dispatch<SetStateAction<string>>;
  hasSearchValue?: boolean;
  searchResultCount?: number;
  onSearch: () => void;
  onSearchEmpty?: () => void;
  placeholder?: string;
  style?: CSSProperties;
}) {
  const { t } = useTranslation();

  /**
   * Note: `allowClear` requires some additional handling; use `onSearchEmpty` if needed
   */
  return (
    <Flex alignItems="center" style={{ gap: '8px' }}>
      <Input.Search
        value={searchValue}
        placeholder={placeholder}
        onChange={(event) => setSearchValue(event.target.value)}
        onSearch={(value) => {
          if (value) {
            onSearch();
          } else if (onSearchEmpty) {
            onSearchEmpty(); // Triggered by clearing search
          }
        }}
        allowClear={true}
        style={{ width: '252px', ...style }}
      />
      {searchResultCount !== undefined && searchResultCount >= 0 && hasSearchValue ? (
        <Text display="block" color={theme.colors.neutral010} fontSize="14px">
          {t('common.searchResultWithCount', { count: searchResultCount })}
        </Text>
      ) : null}
    </Flex>
  );
});
