import type { ImageMapCustomPreviewBlock } from '../types';

export function blockOverlap(
  dragXStart: number,
  dragXEnd: number,
  dragYStart: number,
  dragYEnd: number,
  blockXStart: number,
  blockXEnd: number,
  blockYStart: number,
  blockYEnd: number,
): boolean {
  return (
    dragXStart < blockXEnd &&
    dragXEnd > blockXStart &&
    dragYStart > blockYEnd &&
    dragYEnd < blockYStart
  );
}

export const isStopMoving = (
  block: ImageMapCustomPreviewBlock,
  blocks: ImageMapCustomPreviewBlock[],
): boolean => {
  const { left, width, height, top } = block;
  const dragXStart = left;
  const dragXEnd = left + width;
  const dragYStart = top;
  const dragYEnd = top + height;
  let isOverLap = false;
  blocks.forEach((b) => {
    const bXStart = b.left;
    const bXEnd = b.left + b.width;
    const bYStart = b.top;
    const bYEnd = b.top + b.height;
    if (
      blockOverlap(
        dragXStart,
        dragXEnd,
        dragYStart * -1,
        dragYEnd * -1,
        bXStart,
        bXEnd,
        bYStart * -1,
        bYEnd * -1,
      )
    ) {
      isOverLap = true;
    }
  });
  return isOverLap;
};
