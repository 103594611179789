import { MESSAGE_MODULE_TYPE } from 'shared/components/Editor/LineMessageEditor/constants';

import type {
  CardContent,
  CardModule,
  ImageHero,
} from 'shared/components/Editor/LineMessageEditor/types';

import { i18n } from 'modules/G11n/i18n';
import { generateMappingKey } from 'shared/components/Editor/LineMessageEditor/utils/generateMappingKey';
import { theme } from 'theme';

export const MAX_FOOTER_BUTTON_COUNT = 4;
export const MAX_SHARE_BUTTON_NAME_LENGTH = 40;

export const defaultCardHero: ImageHero = {
  type: 'image',
  url: '',
  key: '',
  size: 'full',
  aspectRatio: '',
  aspectMode: 'cover',
  animated: false,
  action: {
    type: 'none',
  },
};

export const defaultCardContents: CardContent = {
  type: 'bubble',
  direction: 'ltr',
  hero: {
    type: 'image',
    url: '',
    key: '',
    size: 'full',
    aspectRatio: '',
    aspectMode: 'cover',
    animated: false,
    action: {
      type: 'none',
    },
  },
  body: {
    type: 'box',
    layout: 'vertical',
    contents: [
      {
        type: 'text',
        text: '',
        weight: 'bold',
        color: theme.colors.neutral010,
        wrap: true,
      },
      {
        type: 'text',
        text: '',
        wrap: true,
        color: theme.colors.neutral009,
        offsetTop: '8px',
      },
    ],
  },
  footer: {
    type: 'box',
    layout: 'vertical',
    spacing: 'none',
    contents: [
      {
        type: 'button',
        key: generateMappingKey('messageButton'),
        action: {
          type: 'message',
          text: '',
          label: `${i18n.t('glossary.button')} #1`,
        },
      },
    ],
  },
};

export const defaultCardModule: CardModule = {
  module_id: MESSAGE_MODULE_TYPE.card,
  data: {
    notification_text: '',
    contents: defaultCardContents,
  },
  actions: [],
  parameters: [],
  quick_reply: { items: [] },
};

// Reference: https://github.com/i18next/react-i18next/issues/1236#issuecomment-762039023
// To update the i18n translation since constant was outside of the component.
i18n.on('languageChanged', () => {
  defaultCardContents.footer.contents[0].action.label = `${i18n.t('glossary.button')} #1`;
});
