import mapValues from 'lodash/mapValues';

import type { AnyFunction } from 'utils/types';

/**
 * @param parsers - Parsers for the object.
 * @returns A parser.
 * @example
 * ```ts
 * const parse = object({
 *   a: string(),
 *   b: number(),
 * });
 * const result = parse({
 *   a: 123,
 *   b: '123',
 * }); // { a: '123', b: 123 }
 */
export function object<T extends Record<string, AnyFunction>>(
  parsers: T,
): (input: unknown) => { [K in keyof T]: ReturnType<T[K]> } {
  return (input) =>
    mapValues(parsers, (parse, key) =>
      parse(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (input as any)?.[key],
      ),
    );
}
