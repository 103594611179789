import type { ComponentProps, ElementType, JSX, JSXElementConstructor } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { css as styledCss, styled as styledStyled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

export const overrideCss = styledCss<OverrideComponentCssProps>`
  ${({ styledCss }) => styledCss ?? ''};
`;

export const css = String.raw;

export const cx = (...cssArr: Array<ReturnType<typeof css> | undefined>): string => {
  return cssArr.flatMap((v) => (v ? [v] : [])).join('\n');
};

/**
 * This is not working exactly as well as before, apparently due to SC 6 being more strict
 * The objective is to wrap `styled` to include props for `styledCss` to work as expected
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AddStyledCssProp<T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>> =
  ElementType<ComponentProps<T> & OverrideComponentCssProps>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styled = <T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>>(
  Component: T,
): ReturnType<typeof styledStyled<AddStyledCssProp<T>>> => {
  return styledStyled(Component).withConfig({
    /* stylelint-disable-next-line property-no-unknown -- Stylelint issue check https://github.com/stylelint/stylelint/issues/4639 */
    shouldForwardProp: shouldNotForwardSystemProps('styledCss'),
  }) as ReturnType<typeof styledStyled<AddStyledCssProp<T>>>;
};

/**
 * @see https://github.com/styled-components/styled-components/blob/b7c5983/packages/styled-components/src/utils/domElements.ts
 */
const domElements = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'big',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'data',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  'keygen',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'menu',
  'menuitem',
  'meta',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'picture',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'section',
  'select',
  'small',
  'source',
  'span',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'use',
  'var',
  'video',
  'wbr', // SVG
  'circle',
  'clipPath',
  'defs',
  'ellipse',
  'foreignObject',
  'g',
  'image',
  'line',
  'linearGradient',
  'marker',
  'mask',
  'path',
  'pattern',
  'polygon',
  'polyline',
  'radialGradient',
  'rect',
  'stop',
  'svg',
  'text',
  'tspan',
] as const;

/**
 * @see https://github.com/styled-components/styled-components/blob/b90a085/packages/styled-components/src/constructors/styled.tsx#L14-L17
 */
domElements.forEach((domElement) => {
  // @ts-expect-error Inherit the styled elements.
  styled[domElement] = styledStyled[domElement];
});

export { styled };
