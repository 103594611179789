import debug from 'debug';
import { useContext, useState } from 'react';

import type { FieldInputProps, FieldTextAreaProps } from './FieldInput';
import type { PopupProps } from 'components/Popup';

import { Popup } from 'components/Popup';
import { FieldScopeProvider, useFieldScopeContext } from 'lib/validator/hooks/useValidateScope';

import { useRegister } from './hooks/useRegister';
import { Context } from './context';
import { FieldInput, FieldTextArea } from './FieldInput';

const log = debug('validator:popover');

/**
 * @deprecated It is deprecated since it uses Popup which was implemented with `rc-trigger`, but `rc-trigger` doesn't work well with Ant Design Popover on zIndex
 */
export function FormPopover(props: Partial<PopupProps>) {
  return (
    <FieldScopeProvider>
      <InnerFormPopover {...props} />
    </FieldScopeProvider>
  );
}

function InnerFormPopover(props: Partial<PopupProps>) {
  const { fields } = useFieldScopeContext();
  const validatorCtx = useContext(Context);
  const [show, setShow] = useState(props.popupVisible);
  return (
    <Popup
      popupVisible={show}
      placement="right"
      {...props}
      forceRender={true}
      popupTransitionName="rc-trigger-popup-fade"
      onPopupVisibleChange={(v) => {
        if (v) {
          setShow(v);
          if (props.onPopupVisibleChange) {
            props.onPopupVisibleChange(v);
          }
        } else {
          log('fields', fields);
          const allPass = fields.every((f) => {
            return validatorCtx.validate({
              name: f,
              value: validatorCtx.values[f],
            }).valid;
          });
          log('allPass', allPass);
          if (allPass) {
            setShow(v);
            if (props.onPopupVisibleChange) {
              props.onPopupVisibleChange(v);
            }
          }
        }
      }}
    />
  );
}

export function PopoverField(props: FieldInputProps) {
  const ctx = useFieldScopeContext();
  const validatorCtx = useContext(Context);
  useRegister({
    enableReinitialize: props.enableReinitialize,
    name: props.name,
    rules: props.rules,
    value: props.value,
    values: validatorCtx.values,
    register: ctx.register,
    unregister: ctx.unregister,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reRegister: () => {},
  });

  return <FieldInput checkOnChange={true} {...props} />;
}

export const PopoverFieldTextArea = (props: FieldTextAreaProps) => {
  const ctx = useFieldScopeContext();
  const validatorCtx = useContext(Context);
  useRegister({
    enableReinitialize: props.enableReinitialize,
    name: props.name,
    rules: props.rules,
    value: props.value,
    values: validatorCtx.values,
    register: ctx.register,
    unregister: ctx.unregister,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    reRegister: () => {},
  });

  return <FieldTextArea checkOnChange={true} {...props} />;
};
