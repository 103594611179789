import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { DEFAULT_FALLBACK_TEXT } from 'AppConstants';
import {
  EDITOR_CUSTOM_TAG_REGEX,
  EDITOR_INVITATION_LINK_TAG_REGEX,
  EDITOR_INVITEE_NAME_TAG_REGEX,
  EDITOR_INVITER_NAME_TAG_REGEX,
  EDITOR_NAME_TAG_REGEX,
  EDITOR_REFERRAL_ACTIVITY_LINK_REGEX,
  EDITOR_TAG_REGEX,
  EDITOR_URL_TAG_REGEX,
} from 'shared/components/Editor/constants';

import type { TextModule } from 'shared/components/Editor/LineMessageEditor/types';
import type { CustomElementTypes } from 'shared/components/Editor/types/slate-custom';

import { ExternalLink } from 'components/ExternalLink';
import { getColorByTagType } from 'shared/components/Editor/LineMessageEditor/utils/getColorByTagType';
import {
  isCustomParameter,
  isCustomParameterWithLink,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { transformKey } from 'shared/components/Editor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { EllipsisText } from 'shared/components/Typography/EllipsisText';
import { useServerRegion } from 'shared/hooks/auth/useServerRegion';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';

interface TagProps {
  type: CustomElementTypes;
}

const Tag = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardProps(['type']),
})<TagProps>`
  color: ${({ type }) => getColorByTagType(type)};
  cursor: pointer;
`;

export const LineMessageEditorTextModulePreview = ({ message }: { message: TextModule }) => {
  const { t } = useTranslation();
  const { shortLinkPreview } = useServerRegion();

  return (
    <span>
      {message.data.text.split(EDITOR_TAG_REGEX).map((line, index) => {
        if (line.match(EDITOR_URL_TAG_REGEX)) {
          const parameter = message.parameters.find(
            (parameter) => parameter.key === transformKey(line),
          );
          const targetUrl =
            parameter && isCustomParameterWithLink(parameter.data) ? parameter?.data?.url : '';

          return (
            <Tooltip
              key={`url_${index}`}
              title={
                <TooltipDescription>
                  <Space size={2} direction="vertical">
                    {t('common.textsWithColon', {
                      textBeforeColon: t('common.openUrl'),
                    })}
                    <EllipsisText as="span" maxLine={2}>
                      {targetUrl ?? DEFAULT_FALLBACK_TEXT}
                    </EllipsisText>
                  </Space>
                </TooltipDescription>
              }
            >
              <Tag type="url">
                <ExternalLink href={targetUrl}>{shortLinkPreview}</ExternalLink>
              </Tag>
            </Tooltip>
          );
        }
        if (line.match(EDITOR_CUSTOM_TAG_REGEX)) {
          const parameter = message.parameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === transformKey(line),
          );
          return (
            <Tooltip
              key={`custom_${index}`}
              title={
                <TooltipDescription>
                  {t('common.textsWithColon', {
                    textBeforeColon: t('glossary.apiParameter'),
                    textAfterColon: parameter?.key,
                  })}
                </TooltipDescription>
              }
            >
              <Tag type="custom">{t('glossary.apiParameter')}</Tag>
            </Tooltip>
          );
        }
        if (line.match(EDITOR_NAME_TAG_REGEX)) {
          return (
            <Tag key={`name_${index}`} type="name">
              {t('member.name')}
            </Tag>
          );
        }
        if (line.match(EDITOR_INVITER_NAME_TAG_REGEX)) {
          return (
            <Tag key={`referral_inviter_${index}`} type="referral_inviter">
              {t('referralV2.inviterName')}
            </Tag>
          );
        }
        if (line.match(EDITOR_INVITEE_NAME_TAG_REGEX)) {
          return (
            <Tag key={`referral_invitee_${index}`} type="referral_invitee">
              {t('referralV2.inviteeName')}
            </Tag>
          );
        }
        if (line.match(EDITOR_REFERRAL_ACTIVITY_LINK_REGEX)) {
          return (
            <Tag key={`referral_activity_link_${index}`} type="referral_activity_link">
              {t('referralV2.activityPageLink')}
            </Tag>
          );
        }
        if (line.match(EDITOR_INVITATION_LINK_TAG_REGEX)) {
          return (
            <Tag key={`referral_invitation_link_${index}`} type="referral_invitation_link">
              {t('referralV2.invitationLink')}
            </Tag>
          );
        }
        return line;
      })}
    </span>
  );
};
