import type { AcceptedFlexMessage } from '../previewComponents/FlexPreview/types';
import type { FlexBubble } from '@line/bot-sdk';

import { isPrizeRedeemRule } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { replacePrizeRedeemWithKey } from '../utils/replaceFlexContent';

type ReplaceType = 'prize';

export const useReplacedFlexContent = (
  message: AcceptedFlexMessage,
  replaceTypes?: ReplaceType[],
): FlexBubble[] => {
  const contents = message.data.contents.type === 'bubble' ? [message.data.contents] : [];

  return contents.map((content) => {
    if (replaceTypes?.includes('prize') && isPrizeRedeemRule(message)) {
      return replacePrizeRedeemWithKey(content, message);
    }
    return content;
  });
};
