import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { RichTextParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type { EditorParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { ChangeEvent } from 'react';

import { Box } from 'components/layoutUtils/Box';
import { DecoratorTagWrapper } from 'components/LineMessageEditor/components/RichTextEditor/Decorators/Styled';
import { getFieldNameByMappingKey } from 'components/LineMessageEditor/components/RichTextEditor/utils/paramRichTextField';
import { Context } from 'components/LineMessageEditor/models';
import {
  isCarrouselDataRule,
  isRichTextCustomParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { transformKey } from 'components/LineMessageEditor/utils/transformKey';
import { PopupContent } from 'components/Popup/PopupContent';
import { useHandler } from 'hooks/useEventCallback';
import { stopPropagationBundle } from 'lib/react-createPortal';
import { Context as ValidatorContext } from 'lib/validator/context';
import { EEditorType, paramRichTextNameHelper } from 'lib/validator/helper/validatorHelpers';
import {
  FormPopover as ValidatorFormPopover,
  PopoverField as ValidatorPopoverField,
} from 'lib/validator/popover';
import { customParameter } from 'lib/validator/rules/customParameter';
import { required } from 'lib/validator/rules/required';
import { theme } from 'theme';
import { lowerFirst } from 'utils/string/changeCase';

interface ParamRichTextCustomDecoratorProps {
  decoratedText: string;
  entityKey: string;
}

interface ParamRichTextCustomDecoratorComputed {
  mappingKey: string;
  entityKey: string;
  editorType: EEditorType;
  editorIndex?: number;
  key: string;
}

export const ParamRichTextCustomDecorator = memo(function ParamRichTextCustomDecorator(
  props: ParamRichTextCustomDecoratorProps,
) {
  const { store, dispatch } = useContext(Context);
  const validatorContext = useContext(ValidatorContext);
  const { t } = useTranslation();
  const computed = useMemo<ParamRichTextCustomDecoratorComputed>(() => {
    const result = {
      mappingKey: '',
      entityKey: '',
      editorType: EEditorType.CardEditor,
      key: '',
      editorIndex: undefined,
    };
    result.mappingKey = transformKey(props.decoratedText);
    // Prefix underscore for errorHelper's errorParser to split out the fieldName
    result.entityKey = `_${getFieldNameByMappingKey(result.mappingKey)}`;
    store.editorData.forEach((message) => {
      if (result.key !== '') {
        return;
      }

      const currentParameterIndex = message.parameters.findIndex(
        (parameter: EditorParameter) =>
          isRichTextCustomParameter(parameter) && parameter.mappingKey === result.mappingKey,
      );

      const currentParameter: RichTextParameter | undefined =
        message.parameters[currentParameterIndex];

      if (currentParameter === undefined) {
        return;
      }

      result.editorIndex = currentParameterIndex;
      result.key = currentParameter.key;
      result.editorType = isCarrouselDataRule(message)
        ? EEditorType.CarrouselEditor
        : result.editorType;
    });
    return result;
  }, [props.decoratedText, store.editorData]);
  const [isShow, setIsShow] = useState(computed.key === undefined ? false : computed.key === '');
  const validatorName = useMemo(
    () =>
      paramRichTextNameHelper({
        rowIndex: store.editorEditingRow,
        entityKey: computed.entityKey,
        editorIndex: computed.editorIndex,
        editorType: computed.editorType,
      }),
    [computed.editorIndex, computed.editorType, computed.entityKey, store.editorEditingRow],
  );
  const handleChange = useHandler((e: ChangeEvent<HTMLInputElement>) => {
    dispatch('onCardBodyCustomParamDecoratorChanged', {
      rowIndex: store.editorEditingRow,
      mappingKey: computed.mappingKey,
      key: e.target.value,
    });
  });

  // Because draft-js isn't easy to listen to delete/backspace key events, therefore, this is a
  // worse practice of removing data while being unmounted.
  useEffect(() => {
    return () => {
      validatorContext.unregister(validatorName);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps -- copy what CustomDecorator.tsx did here

  return (
    <ValidatorFormPopover
      popup={
        <PopupContent title={t('message.apiParameterName')}>
          <Box {...stopPropagationBundle}>
            <ValidatorPopoverField
              name={validatorName}
              rules={[required, customParameter]}
              placeholder={t('common.inputFieldName', {
                fieldName: lowerFirst(t('message.apiParameterName')),
              })}
              onChange={handleChange}
              value={computed.key}
              enableReinitialize={true}
              checkOnChange={true}
            />
          </Box>
        </PopupContent>
      }
      popupAlign={{
        offset: [20, 0],
      }}
      placement="right"
      popupVisible={isShow}
      onPopupVisibleChange={setIsShow}
    >
      <DecoratorTagWrapper
        style={{ fontSize: 14 }}
        contentEditable={false}
        onClick={() => {
          setIsShow(true);
        }}
        bg={
          validatorContext.invalid[validatorName] === undefined
            ? theme.colors.purple006
            : validatorContext.invalid[validatorName]?.valid === true
              ? theme.colors.purple006
              : theme.colors.red006
        }
      >
        {computed.key
          ? t('common.textsWithColon', {
              textBeforeColon: t('glossary.apiParameter'),
              textAfterColon: computed.key,
            })
          : t('glossary.apiParameter')}
      </DecoratorTagWrapper>
    </ValidatorFormPopover>
  );
});
