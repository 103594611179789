/* eslint-disable react-refresh/only-export-components */
import { createContext, useContext, useEffect, useReducer } from 'react';

import type { JsonMessageEvent } from 'lib/websocket/types';
import type { WebSocketHeartbeat } from 'lib/websocket/WebSocketHeartbeat';
import type { ComponentProps } from 'react';

import { useEventCallback } from 'hooks/useEventCallback';

interface IContextProps {
  state: {
    socket: WebSocketHeartbeat | null;
  };
  dispatch: (action: ReduceAction) => void;
}

const initValue = {
  socket: null,
} as IContextProps['state'];

export const Context = createContext({
  state: initValue,
} as IContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export enum SocketAction {
  Init = 'INIT',
}

type ReduceAction = {
  type: SocketAction.Init;
  payload: WebSocketHeartbeat;
};

function reducer(state: IContextProps['state'], action: ReduceAction) {
  switch (action.type) {
    case SocketAction.Init:
      return { ...state, socket: action.payload };
    default:
      return state;
  }
}

type ContextProviderProps = Omit<ComponentProps<typeof Context.Provider>, 'value'>;

export const ContextProvider = (props: ContextProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initValue);
  return <Context.Provider {...props} value={{ state, dispatch }} />;
};

/**
 * @deprecated This function using any type will be removed in the future.
 */
function jsonMessageTypeGuard<T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  event: JsonMessageEvent<any>,
  typeGuard: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
  ) => data is T,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): event is JsonMessageEvent<any> {
  return typeGuard(event.data);
}

/**
 * Reference for the deprecation: https://www.notion.so/cresclab/2022-05-new-notification-525a1267e6bc4106a57e804912cf0c1c
 *
 * @deprecated This hook using **any** type will be removed; please use `useTaskDone` for tasks
 */
export function useJsonMessageEvent<T>(
  handler: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ev: JsonMessageEvent<any>,
  ) => // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  filter: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
  ) => data is T,
): void {
  const {
    state: { socket },
  } = useContext(Context);
  const savedHandler = useEventCallback((event: JsonMessageEvent) => {
    if (jsonMessageTypeGuard<T>(event, filter)) {
      handler(event);
    }
  });

  useEffect(() => {
    if (!socket) return;
    socket.addEventListener('jsonMessage', savedHandler);
    return () => {
      socket.removeEventListener('jsonMessage', savedHandler);
    };
  }, [savedHandler, socket]);
}
