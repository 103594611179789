import { z } from 'zod';

import type { Dayjs } from 'lib/dayjs';

import { dayjs } from 'lib/dayjs';

/**
 * Note: Zod accepts almost any input to coerced dates; this limits API response to a date string
 */
export const ISO8601DateSchema = z.string().datetime({ offset: true }).pipe(z.coerce.date());

/**
 * Use Dayjs objects in Zod schemas
 */
export const DayJsSchema = z.custom<Dayjs>(
  (value) => value instanceof dayjs,
  'Invalid Dayjs date object',
);
