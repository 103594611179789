import type { FlexButton } from '@line/bot-sdk';

import * as S from '../styled';
import { buttonStyleClassName } from '../utils';

interface FlexButtonComponentProps {
  parentLayout: 'horizontal' | 'vertical';
  data: FlexButton;
}

const smallButtonClassName = (height: FlexButton['height']) => {
  return { button_height_sm: height === 'sm' };
};

export const FlexButtonComponent = ({ data }: FlexButtonComponentProps) => {
  const { style, height, color } = data;
  return (
    <div
      className={S.classNames('button', buttonStyleClassName(style), smallButtonClassName(height))}
    >
      <a>
        <div style={{ color }}>{data.action.label}</div>
      </a>
    </div>
  );
};
