import styled from '@emotion/styled';

import { getFontWeight, Typography } from 'shared/components/Typography/Typography';
import { theme } from 'theme';

/**
 * @deprecated Use `<Typography variant="h1" />` instead.
 * @see {@link Typography}
 */
export const Subtitle = styled.div`
  color: ${theme.colors.neutral010};
  font-size: 24px;
  font-weight: 500;
  line-height: '28px';
`;

interface Heading3Props {
  weight: 'bold' | 'medium' | 'regular' | number;
}

/**
 * @deprecated Use `<Typography variant="h3" />` instead.
 * @see {@link Typography}
 */
export const Heading3 = styled.h3<Heading3Props>`
  font-size: 16px;
  font-weight: ${({ weight }) => getFontWeight(weight)};
  line-height: 1.5;
  color: ${theme.colors.neutral010};
`;
