import type { ReactNode } from 'react';

import { Box } from 'components/layoutUtils';
import { shouldNotForwardProps, styled } from 'shared/utils/styled';

interface GridProps {
  fixed: ReactNode;
  float: ReactNode;
  position?: 'left' | 'right';
  width?: number | string;
}

const GridWrap = styled.div`
  overflow: visible;
`;

interface GridFloatProps extends Pick<GridProps, 'position'> {
  floatWidth: string;
}

const GridFloat = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardProps(['position', 'floatWidth']),
})<GridFloatProps>`
  float: ${({ position }) => position};
  ${({ floatWidth }) => floatWidth};
  z-index: 1;
`;

export const Grid = ({ fixed, float, position = 'right', width }: GridProps) => {
  const floatWidth = width ? 'width: ' + (typeof width === 'number' ? width + 'px' : width) : '';

  return (
    <GridWrap>
      <GridFloat position={position} floatWidth={floatWidth}>
        {float}
      </GridFloat>
      <Box zIndex={2}>{fixed}</Box>
    </GridWrap>
  );
};
