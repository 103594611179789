import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

const LoadingBarBase = styled.div`
  height: 6px;
  border-radius: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
`;

export const FinishedBar = styled(LoadingBarBase)`
  background-color: ${theme.colors.green006};
`;

export const ErrorBar = styled(LoadingBarBase)`
  background-color: ${theme.colors.red006};
`;
