import { PARAM_PLACEHOLDER } from 'components/LineMessageEditor/constants';

import type { MessageAction, URIAction } from '@line/bot-sdk';
import type {
  CustomUriAction,
  ImageCarouselContentHeroData,
  ImageCarouselEditorData,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import type {
  CustomParameter,
  LinkParameter,
  LinkParameterData,
  State,
  Utm,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { dispatch } from 'components/LineMessageEditor/models/models';
import {
  createImageCarouselContent,
  initialData,
  isImageCarouselActionCustomUriRule,
  isImageCarouselActionUriRule,
  isImageCarouselDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import {
  createCustomParameter,
  createLinkParameter,
  isCustomParameter,
  isLinkParameter,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { generateMappingKey } from 'components/LineMessageEditor/utils/transformKey';

const getLinkParameter = (
  state: State,
  rowIndex: number,
  carouselIndex: number,
): LinkParameter | undefined => {
  const target = state.editorData[rowIndex];
  if (!isImageCarouselDataRule(target)) {
    return;
  }
  const parameterIndex = target.parameters.findIndex(
    (p) =>
      isLinkParameter(p) &&
      p.key === (target.data.contents[carouselIndex].hero.action as URIAction).uri,
  );
  if (parameterIndex === -1) {
    return;
  }
  if (isLinkParameter(target.parameters[parameterIndex])) {
    return target.parameters[parameterIndex] as LinkParameter;
  }
};

const getCurrentActionKeys = (currentAction: ImageCarouselContentHeroData['action']) => {
  const result = { currentActionUri: '', currentActionCustomKey: '' };
  if (isImageCarouselActionUriRule(currentAction)) {
    result.currentActionUri = currentAction.uri;
  } else if (isImageCarouselActionCustomUriRule(currentAction)) {
    result.currentActionCustomKey = currentAction.key;
  }
  return result;
};

/**
 * Filter out current carousel's custom parameter
 *
 * @param mappingKey - CustomParameter's mappingKey which maps back to:
 *                     (1) Hero image key
 *                     (2) Hero action key of a customUri
 * @param parameters - CarouselEditor's parameters
 */
const filterOutCustomParameterByMappingKey = (
  mappingKey: string,
  parameters: ImageCarouselEditorData['parameters'],
): ImageCarouselEditorData['parameters'] => {
  if (mappingKey === '') {
    return parameters;
  }
  return parameters.filter((p) => (isCustomParameter(p) ? p.mappingKey !== mappingKey : true));
};

/**
 * Filter out current carousel's link parameter
 *
 * @param key - LinkParameter's key which maps back to Hero action uri
 * @param parameters - CarouselEditor's parameters
 */
const filterOutLinkParameterByKey = (
  key: string,
  parameters: ImageCarouselEditorData['parameters'],
): ImageCarouselEditorData['parameters'] => {
  if (key === '') {
    return parameters;
  }
  return parameters.filter((p) => (isLinkParameter(p) ? p.key !== key : true));
};

export const imageCarouselStore = {
  reducers: {
    setImageCarouselImageUrlWithKey(
      prevState: State,
      payload: {
        isParam: boolean;
        rowIndex: number;
        carouselIndex: number;
        url: string;
        aspectRatio: string;
        isAnimated: boolean;
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      const currentKey = target.data.contents[payload.carouselIndex].hero.contents[0].key;
      const customParameterIndex = target.parameters.findIndex(
        (p) => isCustomParameter(p) && p.mappingKey === currentKey,
      );
      target.data.contents[payload.carouselIndex].hero.contents[0].url = payload.isParam
        ? PARAM_PLACEHOLDER.image
        : payload.url;
      target.data.contents[payload.carouselIndex].hero.contents[0].aspectRatio =
        payload.aspectRatio;
      target.data.contents[payload.carouselIndex].hero.contents[0].animated = payload.isAnimated;

      if (payload.isParam && customParameterIndex === -1) {
        // Create new custom parameter for image when it's param mode and couldn't find existing custom parameter
        const mappingKey = generateMappingKey('customImage');
        target.data.contents[payload.carouselIndex].hero.contents[0].key = mappingKey;
        target.parameters.push(createCustomParameter(payload.url, mappingKey));
      } else if (payload.isParam && customParameterIndex !== -1) {
        // Update existing custom parameter
        target.parameters[customParameterIndex].key = payload.url;
      } else if (!payload.isParam && customParameterIndex !== -1) {
        // Remove existing custom parameter for it's not param mode
        target.data.contents[payload.carouselIndex].hero.contents[0].key = '';
        target.parameters = filterOutCustomParameterByMappingKey(currentKey, target.parameters);
      }
    },
    addImageCarouselCard(prevState: State, payload: { rowIndex: number }): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      target.data.contents.push(createImageCarouselContent());
    },
    setImageCarouselNotificationText(
      prevState: State,
      payload: { rowIndex: number; notificationText: string },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      target.data.notification_text = payload.notificationText;
    },
    setImageCarouselMessage(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; message: string },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      (target.data.contents[payload.carouselIndex].hero.action as MessageAction).text =
        payload.message;
    },
    setImageCarouselUrlData(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        text?: string;
        type?: keyof Pick<
          LinkParameterData,
          'url' | 'utm_campaign' | 'utm_medium' | 'utm_source' | 'utm_content'
        >;
        tagList?: string[];
        openExternal?: boolean;
      },
    ): void {
      const parameter = getLinkParameter(prevState, payload.rowIndex, payload.carouselIndex);
      if (parameter === undefined) {
        return;
      }
      if (payload.openExternal !== undefined) {
        parameter.data.open_external_browser = payload.openExternal;
      }
      if (payload.tagList !== undefined) {
        parameter.data.tag_list = payload.tagList;
      }
      if (payload.type && payload.text !== undefined) {
        parameter.data[payload.type] = payload.text;
      }
    },
    setImageCarouselUrlAndUtm(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number; url: string; utmFields: Utm },
    ): void {
      const parameter = getLinkParameter(prevState, payload.rowIndex, payload.carouselIndex);
      if (parameter === undefined) {
        return;
      }
      parameter.data = { ...parameter.data, ...{ url: payload.url, ...payload.utmFields } };
    },
    /**
     * Invoked after `setImageCarouselActionType()` when switching types or updating the fields
     *
     * @param prevState
     * @param payload
     */
    setImageCarouselCustomUri(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        customUri: string;
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      const currentKey = (
        target.data.contents[payload.carouselIndex].hero.action as CustomUriAction
      )?.key;
      const parameterIndex = target.parameters.findIndex(
        (p) => isCustomParameter(p) && p.mappingKey === currentKey,
      );
      if (parameterIndex === -1) {
        return;
      }
      (target.data.contents[payload.carouselIndex].hero.action as CustomUriAction).uri =
        payload.customUri;
      (target.parameters[parameterIndex] as CustomParameter).key = payload.customUri;
    },
    /**
     * Switch different image carousel action type while:
     *   (1) creating a corresponding parameter
     *   (2) filtering out other types' parameters
     *
     * @param prevState
     * @param payload
     */
    setImageCarouselActionType(
      prevState: State,
      payload: {
        rowIndex: number;
        carouselIndex: number;
        actionType: ImageCarouselContentHeroData['action']['type'];
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      const { currentActionUri, currentActionCustomKey } = getCurrentActionKeys(
        target.data.contents[payload.carouselIndex].hero.action,
      );
      if (payload.actionType === 'uri') {
        const linkKey = generateMappingKey('standardLink');
        target.data.contents[payload.carouselIndex].hero.action = {
          type: 'uri',
          uri: linkKey,
        };
        target.parameters = filterOutCustomParameterByMappingKey(
          currentActionCustomKey,
          target.parameters,
        );
        target.parameters.push(createLinkParameter(linkKey, {}));
      } else if (payload.actionType === 'message') {
        target.data.contents[payload.carouselIndex].hero.action = {
          type: 'message',
          text: '',
        };
        target.parameters = filterOutCustomParameterByMappingKey(
          currentActionCustomKey,
          target.parameters,
        );
        target.parameters = filterOutLinkParameterByKey(currentActionUri, target.parameters);
      } else if (payload.actionType === 'customUri') {
        const mappingKey = generateMappingKey('customUri');
        target.data.contents[payload.carouselIndex].hero.action = {
          type: 'customUri',
          key: mappingKey,
          uri: '',
        };
        target.parameters = filterOutLinkParameterByKey(currentActionUri, target.parameters);
        target.parameters.push(createCustomParameter('', mappingKey));
      }
    },
    deleteImageCarousel(
      prevState: State,
      payload: { rowIndex: number; carouselIndex: number },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      const deletingItem = target.data.contents[payload.carouselIndex];
      const deletingItemAction = deletingItem.hero.action;
      const deletingItemImage = deletingItem.hero.contents[0];

      // Delete LinkParameter or CustomParameter of the action
      if (isImageCarouselActionUriRule(deletingItemAction)) {
        target.parameters = target.parameters.filter((p) => p.key !== deletingItemAction.uri);
      } else if (isImageCarouselActionCustomUriRule(deletingItemAction)) {
        target.parameters = target.parameters.filter(
          (p) => p.mappingKey !== deletingItemAction.key,
        );
      }

      // Delete CustomParameter of the image
      if (Boolean(deletingItemImage.key)) {
        target.parameters = target.parameters.filter((p) => p.mappingKey !== deletingItemImage.key);
      }

      // Delete the item or the entire module
      if (target.data.contents.length === 1) {
        prevState.editorData = prevState.editorData.filter(
          (_, index) => index !== payload.rowIndex,
        );
      } else {
        target.data.contents = target.data.contents.filter(
          (_, index) => index !== payload.carouselIndex,
        );
      }
    },
    sortImageCarousel(
      prevState: State,
      payload: {
        rowIndex: number;
        oldIndex: number;
        newIndex: number;
      },
    ): void {
      const target = prevState.editorData[payload.rowIndex];
      if (!isImageCarouselDataRule(target)) {
        return;
      }
      const contents = target.data.contents;

      // Borrowed the implementation from `./carrousel.ts#sortCarrousel()`
      [contents[payload.oldIndex], contents[payload.newIndex]] = [
        contents[payload.newIndex],
        contents[payload.oldIndex],
      ];
    },
  },
  effects: {
    async addImageCarouselModule(): Promise<void> {
      dispatch('addEditorData', { data: initialData });
    },
  },
};
