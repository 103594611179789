import { safeString } from '@chatbotgang/etude/string/safeString';
import { first, isEmpty } from 'remeda';

import type { Feed } from './types';
import type { RSS } from 'lib/parser/rss/model';
import type { DeferFn } from 'react-async';

import { AuthAxios as axios } from 'lib/axios';

export function join<T, K>(arr: Array<T>, separator: K): Array<T | K> {
  const result: Array<T | K> = [];
  const counts = arr.length;
  arr.forEach((value, index) => {
    result.push(value);
    if (index !== counts - 1) {
      result.push(separator);
    }
  });
  return result;
}

const getRss = (path: string): Promise<XMLDocument> =>
  axios
    .get<string>('/system/proxy/', { params: { url: path } })
    .then((resp) => resp.data)
    .then((str) => new DOMParser().parseFromString(str, 'application/xml'));

const getHtml = (path: string): Promise<Document> =>
  axios
    .get<string>('/system/proxy/', { params: { url: path } })
    .then((resp) => resp.data)
    .then((str) => new DOMParser().parseFromString(str, 'text/html'));

export const deferRss: DeferFn<Document> = ([path]) => getRss(path);
export const deferHtml: DeferFn<Document> = ([path]) => getHtml(path);

export const getDuplicates = (values: number[]): Record<string, number[]> => {
  const counts = values.length;
  const duplicates: Record<string, number[]> = {};
  for (let i = 0; i < counts; i++) {
    if (duplicates.hasOwnProperty(values[i])) {
      duplicates[values[i]].push(i);
    } else if (values.lastIndexOf(values[i]) !== i) {
      duplicates[values[i]] = [i];
    }
  }
  return duplicates;
};

type RSSItem = RSS['items'][number];

export const pickValidFeed = (item: RSSItem): boolean => {
  if (typeof item.title !== 'string' || isEmpty(item.links)) return false;

  if (safeString(item.title ?? '', { trim: true }).length === 0) return false;

  const link = first(item.links);

  if (typeof link?.url !== 'string') return false;

  const url = safeString(link.url, { trim: true, allowMultipleSpaces: false });

  return url.length > 0;
};

export const castItemToFeed = (item: RSSItem): Feed => {
  const title = safeString(item.title ?? '', {
    trim: true,
  });
  const url = safeString(first(item.links)?.url ?? '', {
    trim: true,
    allowMultipleSpaces: false,
  });
  return {
    title,
    url,
  };
};
