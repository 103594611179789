import MediaInfo from 'mediainfo.js';

import { SENTRY_RELEASE } from 'env';

import type { MediaInfoResult } from 'mediainfo.js';

import { logError } from 'lib/logger';

export { MediaInfoResult };

const readChunk = (file: File) => (chunkSize: number, offset: number) =>
  new Promise<Uint8Array>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.error) {
        reject(event.target.error);
      }
      resolve(new Uint8Array((event.target?.result ?? []) as ArrayBuffer));
    };

    reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
  });

export const parseFileMediainfo = (file: File): Promise<MediaInfoResult | null> => {
  return new Promise<MediaInfoResult | null>((resolve, reject) => {
    /*
     * Designate the path to "/MediaInfoModule.wasm" while loading the file.
     * This makes the path unified in development and production environments.
     * We must use the version hash to avoid caching issues.
     */
    MediaInfo({ locateFile: (path) => `/${path}?release=${SENTRY_RELEASE}` })
      .then((mediaInfo) => {
        mediaInfo
          .analyzeData(file.size, readChunk(file))
          .then((result) => {
            resolve(result);
            mediaInfo.close();
          })
          .catch((error) => {
            logError(error);
            reject(error);
          });
      })
      .catch((error) => {
        logError(error);
        reject(error);
      });
  });
};
