import { z } from 'zod';

import { CdpDataSourceSchema } from 'shared/models/cdp';
import { JourneyNodeOpEnum, JourneyNodeOpSchema } from 'shared/models/journey/nodeCommon';
import { EditorDataTypeSchema } from 'shared/types/editor/data';

/**
 * Trigger: Tag Added
 */
export const JourneyNodeTypeTagAddedSettingsSchema = z.object({
  tag_id: z.number().nullable(),
  tag_source: CdpDataSourceSchema.nullable().optional(),
});

export type JourneyNodeTypeTagAddedSettings = z.output<
  typeof JourneyNodeTypeTagAddedSettingsSchema
>;

/**
 * Trigger: LINE Message Opened
 */
export const JourneyNodeTypeTriggerMessageTypeEnum = {
  Broadcast: 'broadcast',
} as const;

export const JourneyNodeTypeLineMessageOpenedSettingsSchema = z.object({
  op: z.enum([JourneyNodeOpEnum.With, JourneyNodeOpEnum.WithAny]),
  msg_type: z.literal(JourneyNodeTypeTriggerMessageTypeEnum.Broadcast),
  msg_id: z.number().nullable(),
});

export type JourneyNodeTypeLineMessageOpenedSettings = z.output<
  typeof JourneyNodeTypeLineMessageOpenedSettingsSchema
>;

/**
 * Trigger: GA Page View
 */
export const JourneyNodeTypeGaPageViewSettingsSchema = z.object({
  op: JourneyNodeOpSchema,
  page_url: z.string().nullable().optional(),
  page_duration: z.number().nullable(),
  page_views: z.number().nullable(),
});

export type JourneyNodeTypeGaPageViewSettings = z.output<
  typeof JourneyNodeTypeGaPageViewSettingsSchema
>;

/**
 * Trigger: GA Purchase
 */
export const JourneyNodeTypeGaPurchaseSettingsSchema = z.object({
  op: JourneyNodeOpSchema,
  product_name: z.string().nullable(),
});

export type JourneyNodeTypeGaPurchaseSettings = z.output<
  typeof JourneyNodeTypeGaPurchaseSettingsSchema
>;

/**
 * Trigger: GA Add to Cart
 */
export const JourneyNodeTypeGaAddToCartSettingsSchema = z.object({
  op: JourneyNodeOpSchema,
  product_name: z.string().nullable(),
});

export type JourneyNodeTypeGaAddToCartSettings = z.output<
  typeof JourneyNodeTypeGaAddToCartSettingsSchema
>;

/**
 * Rule: Time Delay
 */
export const JourneyNodeTypeTimeDelaySettingsUnitEnum = {
  Minutes: 'minutes',
  Hours: 'hours',
  Days: 'days',
  Weeks: 'weeks',
} as const;

export const JourneyNodeTypeTimeDelaySettingsSchema = z.object({
  unit: z.nativeEnum(JourneyNodeTypeTimeDelaySettingsUnitEnum),
  value: z.number(),
});

/**
 * Rule: Time Schedule
 */
export const JourneyNodeTypeTimeScheduleTypeEnum = {
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
} as const;

export const JourneyNodeTypeTimeScheduleSettingsDailySchema = z.object({
  type: z.literal(JourneyNodeTypeTimeScheduleTypeEnum.Daily),
  schedules: z
    .object({
      time: z.string(),
    })
    .array(),
});

export const JourneyNodeTypeTimeScheduleSettingsWeeklySchema = z.object({
  type: z.literal(JourneyNodeTypeTimeScheduleTypeEnum.Weekly),
  schedules: z
    .object({
      weekday: z.number().min(1).max(7),
      time: z.string(),
    })
    .array(),
});

export const JourneyNodeTypeTimeScheduleSettingsMonthlySchema = z.object({
  type: z.literal(JourneyNodeTypeTimeScheduleTypeEnum.Monthly),
  schedules: z
    .object({
      day: z.number().min(1).max(31),
      time: z.string(),
    })
    .array(),
});

export const JourneyNodeTypeTimeScheduleSettingsSchema = z.discriminatedUnion('type', [
  JourneyNodeTypeTimeScheduleSettingsDailySchema,
  JourneyNodeTypeTimeScheduleSettingsWeeklySchema,
  JourneyNodeTypeTimeScheduleSettingsMonthlySchema,
]);

/**
 * Action: Send LINE Message
 */
export const JourneyNodeTypeSendLineMessageSettingsSchema = z.object({
  name: z.string(),
  messages: EditorDataTypeSchema.array(), // TODO: Messages type; this is currently a placeholder
});
