import z from 'zod';

import type { AxiosError } from 'axios';

import { hasOwnProperty } from 'utils/object/hasOwnProperty';

import { isAxiosError } from './isAxiosError';

type ForbiddenError = AxiosError & {
  response: {
    status: 403;
  };
};

function isForbidden(error: AxiosError): error is ForbiddenError {
  return Boolean(error.response && error.response.status === 403);
}

type ResourceGoneError = AxiosError & {
  response: {
    status: 410;
  };
};

export function isResourceGone(error: AxiosError): error is ResourceGoneError {
  return Boolean(error.response && error.response.status === 410);
}

type AuthenticateFailError = ForbiddenError & {
  response: {
    data: {
      detail: 'Authenticate fail';
    };
  };
};

export function isAuthenticateFail(err: AxiosError): err is AuthenticateFailError {
  return Boolean(
    isForbidden(err) &&
      err.response &&
      hasOwnProperty(err.response.data, 'detail') &&
      err.response.data.detail === 'Authenticate fail',
  );
}

type SavingUnavailableFeatureError = AxiosError<{
  error_code: 'FEATURE_SETTING_LEVEL_NOT_ALLOWED';
  message: string;
  errors: Array<unknown>;
}>;

export function isSavingUnavailableFeature(error: unknown): error is SavingUnavailableFeatureError {
  return (
    isAxiosError(error) && error.response?.data?.error_code === 'FEATURE_SETTING_LEVEL_NOT_ALLOWED'
  );
}

type SmsBalanceInsufficientError = AxiosError<{
  error_code: 'BALANCE_INSUFFICIENT';
  message: string;
  errors: Array<unknown>;
}>;

export function isSmsBalanceInsufficient(error: unknown): error is SmsBalanceInsufficientError {
  return (
    isAxiosError(error) &&
    error.response?.status === 400 &&
    error.response?.data?.error_code === 'BALANCE_INSUFFICIENT'
  );
}

type PasswordResetEmailNotFoundError = AxiosError<{
  error_code: 'EMAIL_NOT_FOUND';
  message: string;
  errors: Array<unknown>;
}>;

export const isPasswordRestEmailNotFoundError = (
  error: unknown,
): error is PasswordResetEmailNotFoundError => {
  return (
    isAxiosError(error) &&
    error.response?.status === 400 &&
    error.response?.data?.error_code === 'EMAIL_NOT_FOUND'
  );
};

type SsoPasswordResetNotallowedError = AxiosError<{
  error_code: 'SSO_PASSWORD_RESET_NOT_ALLOWED';
  message: string;
  errors: Array<unknown>;
}>;

export const isSsoPasswordResetNotAllowed = (
  error: unknown,
): error is SsoPasswordResetNotallowedError => {
  return (
    isAxiosError(error) &&
    error.response?.status === 400 &&
    error.response?.data?.error_code === 'SSO_PASSWORD_RESET_NOT_ALLOWED'
  );
};

type SsoLoginUserNotFoundError = AxiosError<{
  error_code: 'USER_NOT_FOUND';
  message: string;
  errors: Array<unknown>;
}>;

export const isSsoLoginUserNotFoundError = (error: unknown): error is SsoLoginUserNotFoundError => {
  return (
    isAxiosError(error) &&
    error.response?.status === 400 &&
    error.response?.data?.error_code === 'USER_NOT_FOUND'
  );
};

export const nonFieldErrorSchema = z.object({
  non_field_errors: z.array(
    z.enum([
      'Invalid token',
      'trigger_code already exist',
      'hw_id already exist',
      'message array should not be empty',
      'The page selected for rich menu switching does not exist.',
      'The fields organization, name must make a unique set.',
      'You reach the webhook amount limit.',
    ]),
  ),
});

export type NonFieldError = z.infer<typeof nonFieldErrorSchema>;

export const isNonFieldError = (error: unknown): error is AxiosError<NonFieldError> => {
  const parsedResult = isAxiosError(error) && nonFieldErrorSchema.safeParse(error.response?.data);
  return parsedResult && parsedResult.success;
};

const websiteWidgetExceedLimitErrorSchema = z.object({
  error_code: z.literal('WIDGET_LIMIT_EXCEEDED'),
  errors: z.tuple([]),
  message: z.string(),
});

type WebsiteWidgetExceedLimitError = z.infer<typeof websiteWidgetExceedLimitErrorSchema>;

export const isWebsiteWidgetExceedLimitError = (
  error: unknown,
): error is AxiosError<WebsiteWidgetExceedLimitError> => {
  const parsedResult =
    isAxiosError(error) && websiteWidgetExceedLimitErrorSchema.safeParse(error.response?.data);
  return parsedResult && parsedResult.success;
};
