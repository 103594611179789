import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { InfoBox } from 'shared/components/InfoBox';

export const MessageConflictNotice = memo(function MessageConflictNotice() {
  const { t } = useTranslation();

  return <InfoBox status="warning">{t('message.shareableConflict.hint')}</InfoBox>;
});
