import { z } from 'zod';

import { JourneyBranchConditionSplitSchema } from 'shared/models/journey/nodeBranchSettings';
import {
  JourneyNodeActionTypeEnum,
  JourneyNodeRuleTypeEnum,
  JourneyNodeTriggerTypeEnum,
} from 'shared/models/journey/nodeCommon';
import { JourneyNodeFilterDataSchema } from 'shared/models/journey/nodeFilter';
import {
  JourneyNodeTypeGaAddToCartSettingsSchema,
  JourneyNodeTypeGaPageViewSettingsSchema,
  JourneyNodeTypeGaPurchaseSettingsSchema,
  JourneyNodeTypeLineMessageOpenedSettingsSchema,
  JourneyNodeTypeSendLineMessageSettingsSchema,
  JourneyNodeTypeTagAddedSettingsSchema,
  JourneyNodeTypeTimeDelaySettingsSchema,
  JourneyNodeTypeTimeScheduleSettingsSchema,
} from 'shared/models/journey/nodeSettings';

/**
 * Triggers
 */
export const JourneyNodeTypeTagAddedRequestArgsSchema = z.object({
  type: z.literal(JourneyNodeTriggerTypeEnum.TagAdded),
  settings: JourneyNodeTypeTagAddedSettingsSchema.or(z.object({})),
  filters: JourneyNodeFilterDataSchema.or(z.object({})),
});

export const JourneyNodeTypeGaPageViewRequestArgsSchema = z.object({
  type: z.literal(JourneyNodeTriggerTypeEnum.GaPageView),
  settings: JourneyNodeTypeGaPageViewSettingsSchema.or(z.object({})),
  filters: JourneyNodeFilterDataSchema.or(z.object({})),
});

export const JourneyNodeTypeGaPurchaseRequestArgsSchema = z.object({
  type: z.literal(JourneyNodeTriggerTypeEnum.GaPurchase),
  settings: JourneyNodeTypeGaPurchaseSettingsSchema.or(z.object({})),
  filters: JourneyNodeFilterDataSchema.or(z.object({})),
});

export const JourneyNodeTypeGaAddToCartRequestArgsSchema = z.object({
  type: z.literal(JourneyNodeTriggerTypeEnum.GaAddToCart),
  settings: JourneyNodeTypeGaAddToCartSettingsSchema.or(z.object({})),
  filters: JourneyNodeFilterDataSchema.or(z.object({})),
});

export const JourneyNodeTypeLineMessageOpenedRequestArgsSchema = z.object({
  type: z.literal(JourneyNodeTriggerTypeEnum.LineMessageOpened),
  settings: JourneyNodeTypeLineMessageOpenedSettingsSchema.or(z.object({})),
  filters: JourneyNodeFilterDataSchema.or(z.object({})),
});

/**
 * Rules
 */
export const JourneyNodeTypeTimeDelayRequestArgsSchema = z.object({
  type: z.literal(JourneyNodeRuleTypeEnum.TimeDelay),
  settings: JourneyNodeTypeTimeDelaySettingsSchema.or(z.object({})),
});

export const JourneyNodeTypeTimeScheduleRequestArgsSchema = z.object({
  name: z.string().nullable(),
  type: z.literal(JourneyNodeRuleTypeEnum.TimeSchedule),
  settings: JourneyNodeTypeTimeScheduleSettingsSchema.or(z.object({})),
});

export const JourneyNodeTypeYesNoBranchRequestArgsSchema = z.object({
  name: z.string().nullable(),
  type: z.literal(JourneyNodeRuleTypeEnum.YesNoBranch),
  settings: z.object({}), // TODO: remove this after typesafe API client upgrade
  // Branch settings have a more convoluted typing as it reuses filter logic (which can return empty object) but actually we return null when there are no settings to pass in the request
  branch_settings: JourneyBranchConditionSplitSchema.or(z.object({})).or(z.null()),
});

/**
 * Actions
 */
export const JourneyNodeTypeSendLineMessageRequestArgsSchema = z.object({
  name: z.string().nullable(),
  type: z.literal(JourneyNodeActionTypeEnum.SendLineMessage),
  // Note: this is optional to allow for cases where the message field was not updated
  settings: JourneyNodeTypeSendLineMessageSettingsSchema.or(z.object({})).optional(),
});

export const JourneyNodeUpdateRequestArgsSchema = z.discriminatedUnion('type', [
  JourneyNodeTypeTagAddedRequestArgsSchema,
  JourneyNodeTypeGaPageViewRequestArgsSchema,
  JourneyNodeTypeGaPurchaseRequestArgsSchema,
  JourneyNodeTypeGaAddToCartRequestArgsSchema,
  JourneyNodeTypeLineMessageOpenedRequestArgsSchema,
  JourneyNodeTypeTimeDelayRequestArgsSchema,
  JourneyNodeTypeTimeScheduleRequestArgsSchema,
  JourneyNodeTypeYesNoBranchRequestArgsSchema,
  JourneyNodeTypeSendLineMessageRequestArgsSchema,
]);

export type JourneyNodeUpdateRequestArgs = z.input<typeof JourneyNodeUpdateRequestArgsSchema>;
