import { z } from 'zod';

// Note: SMS broadcasts use "failed" instead of "fail" as a status
const BroadcastSmsStatusEnum = {
  Draft: 'draft',
  Scheduled: 'scheduled',
  Sending: 'sending',
  Sent: 'sent',
  Fail: 'failed',
} as const;

export const BroadcastSmsStatusSchema = z.nativeEnum(BroadcastSmsStatusEnum);

export type BroadcastSmsStatus = z.output<typeof BroadcastSmsStatusSchema>;

// TODO: relocate to UI types or merge with BroadcastStatusEnum if possible
export const BroadcastSmsFilterEnum = {
  ...BroadcastSmsStatusEnum,
  All: 'all',
  Search: 'search',
} as const;

const BroadcastSmsFilterSchema = z.nativeEnum(BroadcastSmsFilterEnum);

export type BroadcastSmsFilterType = z.output<typeof BroadcastSmsFilterSchema>;
