/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */

export const langStrategies = {
  isZHLang: (lang: string, returnValue: any) => {
    if (lang.startsWith('zh')) {
      return returnValue;
    }
  },
  isENLang: (lang: string, returnValue: any) => {
    if (lang.startsWith('en')) {
      return returnValue;
    }
  },
  isTHLang: (lang: string, returnValue: any) => {
    if (lang.startsWith('th')) {
      return returnValue;
    }
  },
  isJALang: (lang: string, returnValue: any) => {
    if (lang.startsWith('ja')) {
      return returnValue;
    }
  },
  default: (lang: string, returnValue: any) => {
    return returnValue;
  },
};

export class LangsLocalesUtils<T extends () => any> {
  private _langStrategies: T[] = [];

  constructor(strategies: T[]) {
    this._langStrategies = strategies;
  }

  get langStrategies() {
    return this._langStrategies;
  }

  getResult(): ReturnType<T> | undefined {
    for (let index = 0; index < this._langStrategies.length; index++) {
      const result = this._langStrategies[index]();
      if (result !== undefined) return result;
    }

    return undefined;
  }
}
