import urlJoin from 'url-join';

import { inTest } from 'env';

import { maacFirebase } from 'lib/firebase/main';
import { uuid } from 'utils/uuid';

export const sanitizeFilePath = (file: File, path: string, useUniqueName = !inTest): string => {
  const [fileType, fileExtension] = file.type.split('/');
  const splitFileName = file.name.split('.');
  const withExtension = splitFileName.length > 1;
  const uniqueName = uuid().split('-').join('');
  const name = useUniqueName
    ? uniqueName
    : withExtension
      ? splitFileName.slice(0, -1).join('.')
      : splitFileName.join();
  const extension = fileExtension ?? withExtension ? splitFileName.pop() : '';
  const trailing = extension === '' ? '' : `.${extension}`;

  if (name === '') {
    throw new Error('Filename might be empty, please check the file');
  }

  return urlJoin(path, fileType, `${name}${trailing}`);
};

/**
 * Do uploading
 * @param file target file to upload
 * @param path file path prefix, this is for categorizing files in the storage.
 * @param useUniqueName use hash function to generate unique file name or not, default true
 */
export const uploadFile = async (
  file: File,
  path: string,
  useUniqueName = !inTest,
): Promise<string> => {
  const filePath = sanitizeFilePath(file, path, useUniqueName);
  const uploadResultUrl = await maacFirebase.doUpload(file, filePath);

  return uploadResultUrl;
};
