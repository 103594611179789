import ReactGA4 from 'react-ga4';
import { onCLS, onFCP, onINP, onLCP, onTTFB } from 'web-vitals/attribution';

import type {
  CLSMetricWithAttribution,
  FCPMetricWithAttribution,
  INPMetricWithAttribution,
  LCPMetricWithAttribution,
  TTFBMetricWithAttribution,
} from 'web-vitals';

type MetricWithAttribution =
  | CLSMetricWithAttribution
  | FCPMetricWithAttribution
  | INPMetricWithAttribution
  | LCPMetricWithAttribution
  | TTFBMetricWithAttribution;

// Collect some additional information that may be helpful in debugging issues
// @link: https://web.dev/articles/debug-performance-in-the-field
const getMetricAttributionDebugValue = (metric: MetricWithAttribution) => {
  switch (metric.name) {
    case 'CLS':
      return metric.attribution.largestShiftTarget;
    case 'INP':
      return metric.attribution.interactionTarget;
    case 'LCP':
      return metric.attribution.element;
    default:
      return null;
  }
};

const sendToGoogleAnalytics = (metric: MetricWithAttribution) => {
  const { name, delta, value, id } = metric;

  const debugValue = getMetricAttributionDebugValue(metric);

  ReactGA4.event(name, {
    value: delta, // Use `delta` so the value can be summed
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
    ...(debugValue ? { debug_value: debugValue } : {}),
  });
};

export const reportWebVitals = () => {
  onCLS(sendToGoogleAnalytics);
  onFCP(sendToGoogleAnalytics);
  onINP(sendToGoogleAnalytics);
  onLCP(sendToGoogleAnalytics);
  onTTFB(sendToGoogleAnalytics);
};
