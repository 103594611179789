import type { GoogleAnalyticSetting } from 'components/Types';

const isSameHostname = (urlA: string, urlB: string): boolean => {
  try {
    return new URL(urlA).hostname === new URL(urlB).hostname;
  } catch (err) {
    return false;
  }
};

export const checkIsGaTrackable = (ga: GoogleAnalyticSetting[], link: string): boolean => {
  if (ga === undefined || ga === null || ga.length === 0) return false;
  return ga.some((g) => (g.website_url ? isSameHostname(g.website_url, link) : false));
};
