import type { State } from './templateDataAndTypes/types';
import type { FlexBox, FlexImage } from '@line/bot-sdk';
import type {
  PrizeRedeemData,
  SelectedPrize,
} from 'components/LineMessageEditor/models/templateDataAndTypes/prizeRedeem';
import type { AvailableLangs } from 'modules/G11n/i18n';
import type { PrizeCategory } from 'modules/Prize/types';
import type { EditorDataTypeTemplateCategories } from 'services/rubato';

import { PrizeRedeem } from 'components/LineMessageEditor/models/templateDataAndTypes/prizeRedeem';
import { UniversalTemplateCategory } from 'components/Types';
import { getUniversalTemplate } from 'services/rubato';

import { dispatch } from './models';

const categoryMap: Record<PrizeCategory, EditorDataTypeTemplateCategories> = {
  online_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_ONLINE_COUPON,
  offline_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_OFFLINE_COUPON,
  line_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_LINE_COUPON,
  game_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_GAME_COUPON,
  custom_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_BRAND_COUPON,
  integration_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_INTEGRATION_COUPON,
  points_coupon: UniversalTemplateCategory.PRIZE_MESSAGE_POINTS_COUPON,
};

export const prizeRedeemStore = {
  reducer: {
    setPrizeRedeemNotificationText(
      prevState: State,
      payload: {
        rowIndex: number;
        text: string;
      },
    ): void {
      const target = (prevState.editorData[payload.rowIndex] as PrizeRedeemData).data;
      target.notification_text = payload.text;
    },
    setPrize(
      prevState: State,
      payload: {
        rowIndex: number;
        prize: SelectedPrize;
      },
    ): void {
      const parameters = (prevState.editorData[payload.rowIndex] as PrizeRedeemData).parameters;
      const format = (prevState.editorData[payload.rowIndex] as PrizeRedeemData).format;

      parameters.forEach((item) => {
        item.data = {
          ...item.data,
          prize_setting_id: payload.prize.value,
        };
      });

      format.prizeName = payload.prize.label;
    },
    setPrizeTemplate(
      prevState: State,
      payload: {
        rowIndex: number;
        data: PrizeRedeemData;
      },
    ): void {
      const contents = (prevState.editorData[payload.rowIndex] as PrizeRedeemData).data.contents;
      const headerContent = contents.header?.contents[0] as FlexImage;
      const actionButton = contents.body?.contents[1] as FlexBox;

      if (headerContent) {
        headerContent.url = (payload.data.data.contents.header?.contents[0] as FlexImage).url;
      }
      if (actionButton.background?.startColor) {
        actionButton.background.startColor =
          (payload.data.data.contents.body?.contents[1] as FlexBox).background?.startColor ?? '';
      }
      if (actionButton.background?.endColor) {
        actionButton.background.endColor =
          (payload.data.data.contents.body?.contents[1] as FlexBox).background?.endColor ?? '';
      }
    },
  },
  effects: {
    async changePrize(payload: {
      rowIndex: number;
      prize: SelectedPrize;
      lang: AvailableLangs;
    }): Promise<void> {
      const {
        rowIndex,
        prize: { label, value, category },
        lang,
      } = payload;

      dispatch('setPrize', {
        rowIndex,
        prize: {
          label,
          value,
        },
      });

      const data = await getUniversalTemplate(categoryMap[category], lang);

      dispatch('setPrizeTemplate', {
        rowIndex,
        data: data[0],
      });
    },
    async addPrizeRedeemModule(payload: { lang: AvailableLangs }): Promise<void> {
      try {
        const data = await getUniversalTemplate(
          UniversalTemplateCategory.PRIZE_MESSAGE_ONLINE_COUPON,
          payload.lang,
        );

        dispatch('addEditorData', {
          data: data[0],
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch('addEditorData', {
          data: PrizeRedeem,
        });
      }
    },
  },
};
