import type { EditorStateObject } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { EditorState } from 'draft-js';

import { fromUtf16toUnixText } from 'utils/string/textUtils';

export const stringifyEditorStateObject = (editorState?: EditorState, separator = '\n'): string => {
  return !editorState
    ? ''
    : editorState
        .getCurrentContent()
        .getBlocksAsArray()
        .map((block) => block.getText())
        .reduce((pre, now) => [pre, now].join(separator));
};

export const stringifyEditorStateObjectToUnixText = (editorState: EditorState): string => {
  return fromUtf16toUnixText(stringifyEditorStateObject(editorState));
};

export const findEditorStateObjectById = (
  id: EditorStateObject['id'],
  editorStateObjects: EditorStateObject[],
): EditorStateObject => {
  return editorStateObjects[editorStateObjects.findIndex((obj) => obj.id === id)];
};
