import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { AsyncTaskSchema, PageResponseSchema } from 'shared/models/common';
import { DeeplinkReportSchema } from 'shared/models/deeplink/common';

const DeeplinkImportParameters = z
  .object({
    bot_id: z.number().int(),
    import_type: z.literal('file'),
    gcs_uri: z.string(),
  })
  .strict();

const DeeplinkExportParameters = z
  .object({
    bot_id: z.number().int(),
    ids: z.number().int().array(),
  })
  .strict();

export const deeplinkBatchApi = makeApi([
  {
    alias: 'deeplinkBatchList',
    method: 'get',
    path: '/line/v1/deeplinks/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'page',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      // Note: the following parameter is supposedly implemented, but we do not use it
      {
        name: 'page_size',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      results: z
        .object({
          id: z.number().int(),
          name: z.string(),
          created_at: z.string(),
          shorten_url: z.string(),
          report: DeeplinkReportSchema,
        })
        .strict()
        .array(),
    }),
  },
  {
    alias: 'deeplinkBatchImportCreate',
    method: 'post',
    path: '/line/v1/deeplinks/',
    parameters: [
      {
        name: 'body',
        schema: DeeplinkImportParameters,
        type: 'Body',
      },
    ],
    response: AsyncTaskSchema,
  },
  {
    alias: 'deeplinkBatchImportUpdate',
    method: 'post',
    path: '/line/v1/deeplinks/batch_update/',
    parameters: [
      {
        name: 'body',
        schema: DeeplinkImportParameters,
        type: 'Body',
      },
    ],
    response: AsyncTaskSchema,
  },
  {
    alias: 'deeplinkBatchExportCsv',
    method: 'post',
    path: '/line/v1/deeplinks/setting/export/',
    parameters: [
      {
        name: 'body',
        schema: DeeplinkExportParameters,
        type: 'Body',
      },
    ],
    response: AsyncTaskSchema,
  },
  {
    alias: 'deeplinkBatchExportQrCode',
    method: 'post',
    path: '/line/v1/deeplinks/qr_code/export/',
    parameters: [
      {
        name: 'body',
        schema: DeeplinkExportParameters,
        type: 'Body',
      },
    ],
    response: AsyncTaskSchema,
  },
]);
