import { z } from 'zod';

import { HOURS_IN_DAY } from 'AppConstants';

import { MessageSchema } from 'shared/components/Editor/LineMessageEditor/types';
import { ReportMessageSchema, ReportTotalSchema, ReportUrlSchema } from 'shared/models/report';

export const WebhookEventTypeEnum = {
  Message: 1,
  Postback: 2,
  Follow: 3,
  Beacon: 4,
  Time: 100,
  MessageEditor: 101,
  PostbackEditor: 102,
} as const;

export const WebhookEventTypeSchema = z.nativeEnum(WebhookEventTypeEnum);

export type WebhookEventType = z.output<typeof WebhookEventTypeSchema>;

export const TriggerScheduleTypeEnum = {
  Daily: 'daily',
  BusinessHour: 'business_hour',
  NonBusinessHour: 'non_business_hour',
  Monthly: 'monthly',
  DateRange: 'date_range',
  NonDateRange: 'non_date_range',
} as const;

export const TriggerScheduleTypeSchema = z.nativeEnum(TriggerScheduleTypeEnum);

export type TriggerScheduleType = z.output<typeof TriggerScheduleTypeSchema>;

export const AutoReplyMessageTypeEnum = {
  NewFriend: 1,
  OriginalFriend: 2,
  BoundFriend: 3,
} as const;

export const AutoReplyMessageTypeSchema = z.nativeEnum(AutoReplyMessageTypeEnum);

export type AutoReplyMessageType = z.output<typeof AutoReplyMessageTypeSchema>;

const BaseCountsSchema = z.object({
  trigger_counts: z.number().int(),
  reply_counts: z.number().int(),
});

export const WebhookTriggerCountsSchema = z.object({
  [AutoReplyMessageTypeEnum.NewFriend]: BaseCountsSchema,
  [AutoReplyMessageTypeEnum.OriginalFriend]: BaseCountsSchema,
  [AutoReplyMessageTypeEnum.BoundFriend]: BaseCountsSchema,
  total: BaseCountsSchema,
});

export const WebhookTriggerReferenceEnum = {
  DpmMessage: '1',
  BroadcastMessage: '2',
  GuidelinkMessage: '3',
  RetargetMessage: '4',
  RiceMenu: '5',
  WebhookTriggerSettingMessage: '6',
} as const;

export const WebhookTriggerReferenceSchema = z.nativeEnum(WebhookTriggerReferenceEnum);

export type WebhookTriggerReference = z.output<typeof WebhookTriggerReferenceSchema>;

export const AutoReplyMessageSchema = z.object({
  enable: z.boolean(),
  id: z.number().int(),
  messages: z.array(MessageSchema).optional(),
  setting: z.number().int(),
  trigger_type: AutoReplyMessageTypeSchema,
});

export type AutoReplyMessage = z.output<typeof AutoReplyMessageSchema>;

export const BaseAutoReplySchema = z.object({
  archived: z.boolean(),
  bot: z.number().int(),
  counts: WebhookTriggerCountsSchema,
  created_at: z.string(),
  enable: z.boolean(),
  id: z.number().int(),
  messages: z.array(AutoReplyMessageSchema),
  name: z.string(),
  no_disturb_interval: z.number().int().nullable(),
  references: z.record(WebhookTriggerReferenceSchema, z.number().array().optional()).optional(), // Editor trigger API doesn't have this field
  tags: z.array(z.string()),
  trigger_code: z.string().nullable(),
  updated_at: z.string(),
});

export const AutoReplyDateRangeKeywordReplySchema = BaseAutoReplySchema.extend({
  trigger_code: z.string(),
  event_type: z.literal(WebhookEventTypeEnum.Message),
  trigger_schedule_type: z.enum([TriggerScheduleTypeEnum.DateRange]),
  trigger_schedule_settings: z
    .object({
      start_date: z.string(),
      end_date: z.string(),
    })
    .array(),
});

export const AutoReplyNonDateRangeKeywordReplySchema = BaseAutoReplySchema.extend({
  trigger_code: z.string(),
  event_type: z.literal(WebhookEventTypeEnum.Message),
  trigger_schedule_type: z.enum([TriggerScheduleTypeEnum.NonDateRange]),
  trigger_schedule_settings: z.null(),
});

export const AutoReplyKeywordReplySchema = z.discriminatedUnion('trigger_schedule_type', [
  AutoReplyDateRangeKeywordReplySchema,
  AutoReplyNonDateRangeKeywordReplySchema,
]);

export const isKeywordAutoReply = (
  autoReply: AutoReplyItem,
): autoReply is z.output<typeof AutoReplyKeywordReplySchema> => {
  return autoReply.event_type === WebhookEventTypeEnum.Message;
};

export const AutoReplyDailyFixedReplySchema = BaseAutoReplySchema.extend({
  event_type: z.literal(WebhookEventTypeEnum.Time),
  trigger_schedule_type: z.enum([TriggerScheduleTypeEnum.Daily]),
  trigger_schedule_settings: z
    .object({
      start_time: z.string(),
      end_time: z.string(),
    })
    .array(),
});

export const isDailyFixedReplyAutoReply = (
  autoReply: AutoReplyItem,
): autoReply is z.output<typeof AutoReplyDailyFixedReplySchema> => {
  return (
    autoReply.event_type === WebhookEventTypeEnum.Time &&
    autoReply.trigger_schedule_type === TriggerScheduleTypeEnum.Daily
  );
};

export const AutoReplyBusinessHourFixedReplySchema = BaseAutoReplySchema.extend({
  event_type: z.literal(WebhookEventTypeEnum.Time),
  trigger_schedule_type: z.enum([TriggerScheduleTypeEnum.BusinessHour]),
  trigger_schedule_settings: z.null(),
});

export const AutoReplyNonBusinessHourFixedReplySchema = BaseAutoReplySchema.extend({
  event_type: z.literal(WebhookEventTypeEnum.Time),
  trigger_schedule_type: z.enum([TriggerScheduleTypeEnum.NonBusinessHour]),
  trigger_schedule_settings: z.null(),
});

export const AutoReplyMonthlyFixedReplySchema = BaseAutoReplySchema.extend({
  event_type: z.literal(WebhookEventTypeEnum.Time),
  trigger_schedule_type: z.enum([TriggerScheduleTypeEnum.Monthly]),
  trigger_schedule_settings: z
    .object({
      day: z.number().int(),
      start_time: z.string(),
      end_time: z.string(),
    })
    .array(),
});

export const isMonthlyFixedReplyAutoReply = (
  autoReply: AutoReplyItem,
): autoReply is z.output<typeof AutoReplyMonthlyFixedReplySchema> => {
  return (
    autoReply.event_type === WebhookEventTypeEnum.Time &&
    autoReply.trigger_schedule_type === TriggerScheduleTypeEnum.Monthly
  );
};

export const isFixedReplyAutoReply = (
  autoReply: AutoReplyItem,
): autoReply is z.output<typeof AutoReplyFixedReplySchema> => {
  return autoReply.event_type === WebhookEventTypeEnum.Time;
};

const AutoReplyFixedReplySchema = z.discriminatedUnion('trigger_schedule_type', [
  AutoReplyDailyFixedReplySchema,
  AutoReplyBusinessHourFixedReplySchema,
  AutoReplyNonBusinessHourFixedReplySchema,
  AutoReplyMonthlyFixedReplySchema,
]);

export const AutoReplyWelcomeReplySchema = BaseAutoReplySchema.extend({
  event_type: z.literal(WebhookEventTypeEnum.Follow),
  trigger_schedule_type: z.null(),
  trigger_schedule_settings: z.null(),
});

// Editor triggers overlap with the keyword Auto-reply
export const AutoReplyEditorTriggerSchema = AutoReplyDateRangeKeywordReplySchema.omit({
  messages: true,
})
  .extend({
    messages: z.array(AutoReplyMessageSchema.omit({ messages: true })),
  })
  .or(
    AutoReplyNonDateRangeKeywordReplySchema.omit({ messages: true }).extend({
      messages: z.array(AutoReplyMessageSchema.omit({ messages: true })),
    }),
  )
  .or(
    BaseAutoReplySchema.omit({
      messages: true,
    }).extend({
      messages: z.array(AutoReplyMessageSchema.omit({ messages: true })),
      event_type: z
        .literal(WebhookEventTypeEnum.MessageEditor)
        .or(z.literal(WebhookEventTypeEnum.Message)), // some old editor trigger's `trigger_schedule_type` and `trigger_schedule_settings` are both null
      trigger_schedule_type: z.null(),
      trigger_schedule_settings: z.null(),
    }),
  );

export type AutoReplyEditorTrigger = z.output<typeof AutoReplyEditorTriggerSchema>;

export const AutoReplyItemSchema = AutoReplyWelcomeReplySchema.or(AutoReplyKeywordReplySchema).or(
  AutoReplyFixedReplySchema,
);

export type AutoReplyItem = z.output<typeof AutoReplyItemSchema>;

const AutoReplyMessageReportSchema = z.object({
  messages: ReportMessageSchema.array(),
  total: ReportTotalSchema.omit({
    unique_clicks: true,
    clicks_of_share: true,
    unique_clicks_of_share: true,
  }).extend({
    unique_open_count: z.number().int().nullable(),
  }),
  urls: ReportUrlSchema.omit({ url: true, unique_clicks: true }).array(),
});

export type AutoReplyMessageReport = z.output<typeof AutoReplyMessageReportSchema>;

const AutoReplyReportTotalSchema = ReportTotalSchema.omit({
  unique_clicks: true,
  open_count: true,
  clicks_of_share: true,
  unique_clicks_of_share: true,
});

const FollowReplyReportRespSchema = z.object({
  counts: WebhookTriggerCountsSchema,
  id: z.number().int(),
  report: z.object({
    [AutoReplyMessageTypeEnum.NewFriend]: AutoReplyMessageReportSchema,
  }),
});

const OriginalFriendReplyReportTypeSchema = z.object({
  counts: WebhookTriggerCountsSchema,
  id: z.number().int(),
  report: z.object({
    [AutoReplyMessageTypeEnum.OriginalFriend]: AutoReplyMessageReportSchema,
    [AutoReplyMessageTypeEnum.BoundFriend]: AutoReplyMessageReportSchema,
    total: AutoReplyReportTotalSchema,
  }),
});

export type FollowReplyReportResp = z.output<typeof FollowReplyReportRespSchema>;
export type OriginalFriendReplyReportType = z.output<typeof OriginalFriendReplyReportTypeSchema>;

export const AutoReplyReportSchema = FollowReplyReportRespSchema.or(
  OriginalFriendReplyReportTypeSchema,
);

export const AutoReplyTabEnum = {
  All: 'all',
  Message: 'message',
  Follow: 'follow',
  Time: 'time',
  Archived: 'archived',
} as const;

const AutoReplyTabEnumSchema = z.nativeEnum(AutoReplyTabEnum);

export type AutoReplyTab = z.infer<typeof AutoReplyTabEnumSchema>;

export const isFollowReplyReport = (
  replyResp: AutoReplyReport,
): replyResp is FollowReplyReportResp => {
  return AutoReplyMessageTypeEnum.NewFriend in replyResp.report;
};

export const isOriginalFriendReplyReport = (
  replyResp: AutoReplyReport,
): replyResp is OriginalFriendReplyReportType => {
  return !(AutoReplyMessageTypeEnum.NewFriend in replyResp.report);
};

export const ReplyLimitBaseUnitEnum = {
  Hours: 1,
  Days: HOURS_IN_DAY,
} as const;

export const ReplyLimitBaseUnitEnumSchema = z.nativeEnum(ReplyLimitBaseUnitEnum);

export type ReplyLimitBaseUnit = z.infer<typeof ReplyLimitBaseUnitEnumSchema>;

export type AutoReplyReport = z.output<typeof AutoReplyReportSchema>;
export type AutoReplyListItem = AutoReplyItem & {
  report?: AutoReplyReport;
};

export type AutoReplyParams = {
  archived?: boolean;
  event_type?: WebhookEventType;
};
