import { makeApi } from '@zodios/core';
import { z } from 'zod';

import {
  AutoReplyBusinessHourFixedReplySchema,
  AutoReplyDailyFixedReplySchema,
  AutoReplyDateRangeKeywordReplySchema,
  AutoReplyEditorTriggerSchema,
  AutoReplyItemSchema,
  AutoReplyMessageSchema,
  AutoReplyMonthlyFixedReplySchema,
  AutoReplyNonBusinessHourFixedReplySchema,
  AutoReplyNonDateRangeKeywordReplySchema,
  AutoReplyReportSchema,
  AutoReplyWelcomeReplySchema,
  TriggerScheduleTypeSchema,
  WebhookEventTypeSchema,
} from 'shared/models/autoReply';
import { ExportResponseSchema, PageResponseSchema } from 'shared/models/common';

const parametersOmittedFields = {
  counts: true,
  created_at: true,
  references: true,
  updated_at: true,
  messages: true,
} as const;

const createParametersOmittedFields = {
  ...parametersOmittedFields,
  id: true,
} as const;

const AutoReplyCreateParametersSchema = AutoReplyDateRangeKeywordReplySchema.omit(
  createParametersOmittedFields,
)
  .extend({ messages: z.array(AutoReplyMessageSchema.partial()) })
  .or(
    AutoReplyNonDateRangeKeywordReplySchema.omit(createParametersOmittedFields).extend({
      messages: z.array(AutoReplyMessageSchema.partial()),
    }),
  )
  .or(
    AutoReplyDailyFixedReplySchema.omit(createParametersOmittedFields).extend({
      messages: z.array(AutoReplyMessageSchema.partial()),
    }),
  )
  .or(
    AutoReplyBusinessHourFixedReplySchema.omit(createParametersOmittedFields).extend({
      messages: z.array(AutoReplyMessageSchema.partial()),
    }),
  )
  .or(
    AutoReplyNonBusinessHourFixedReplySchema.omit(createParametersOmittedFields).extend({
      messages: z.array(AutoReplyMessageSchema.partial()),
    }),
  )
  .or(
    AutoReplyMonthlyFixedReplySchema.omit(createParametersOmittedFields).extend({
      messages: z.array(AutoReplyMessageSchema.partial()),
    }),
  )
  .or(
    AutoReplyWelcomeReplySchema.omit(createParametersOmittedFields).extend({
      messages: z.array(AutoReplyMessageSchema.partial()),
    }),
  );

export type AutoReplyCreateParameters = z.output<typeof AutoReplyCreateParametersSchema>;

const AutoReplyUpdateParametersSchema = AutoReplyDateRangeKeywordReplySchema.omit(
  parametersOmittedFields,
)
  .extend({
    messages: z.array(AutoReplyMessageSchema.partial()),
  }) // `partial` doesn't exit in Zod union so we need to do it separately
  .partial()
  .or(
    AutoReplyNonDateRangeKeywordReplySchema.omit(parametersOmittedFields)
      .extend({
        messages: z.array(AutoReplyMessageSchema.partial()),
      })
      .partial(),
  )
  .or(
    AutoReplyDailyFixedReplySchema.omit(parametersOmittedFields)
      .extend({
        messages: z.array(AutoReplyMessageSchema.partial()),
      })
      .partial(),
  )
  .or(
    AutoReplyBusinessHourFixedReplySchema.omit(parametersOmittedFields)
      .extend({
        messages: z.array(AutoReplyMessageSchema.partial()),
      })
      .partial(),
  )
  .or(
    AutoReplyNonBusinessHourFixedReplySchema.omit(parametersOmittedFields)
      .extend({
        messages: z.array(AutoReplyMessageSchema.partial()),
      })
      .partial(),
  )
  .or(
    AutoReplyMonthlyFixedReplySchema.omit(parametersOmittedFields)
      .extend({
        messages: z.array(AutoReplyMessageSchema.partial()),
      })
      .partial(),
  )
  .or(
    AutoReplyWelcomeReplySchema.omit(parametersOmittedFields)
      .extend({
        messages: z.array(AutoReplyMessageSchema.partial()),
      })
      .partial(),
  );

const EditorTriggerUpdateParametersSchema = AutoReplyDateRangeKeywordReplySchema.omit(
  parametersOmittedFields,
) // `partial` doesn't exit in Zod union so we need to do it separately
  .partial()
  .extend({
    bot: z.number().int(),
  })
  .or(
    AutoReplyNonDateRangeKeywordReplySchema.omit(parametersOmittedFields).partial().extend({
      bot: z.number().int(),
    }),
  )
  .or(
    AutoReplyDailyFixedReplySchema.omit(parametersOmittedFields).partial().extend({
      bot: z.number().int(),
    }),
  )
  .or(
    AutoReplyBusinessHourFixedReplySchema.omit(parametersOmittedFields).partial().extend({
      bot: z.number().int(),
    }),
  )
  .or(
    AutoReplyNonBusinessHourFixedReplySchema.omit(parametersOmittedFields).partial().extend({
      bot: z.number().int(),
    }),
  )
  .or(
    AutoReplyMonthlyFixedReplySchema.omit(parametersOmittedFields).partial().extend({
      bot: z.number().int(),
    }),
  )
  .or(
    AutoReplyWelcomeReplySchema.omit(parametersOmittedFields).partial().extend({
      bot: z.number().int(),
    }),
  );

export const autoReplyApi = makeApi([
  {
    alias: 'autoReplyList',
    method: 'get',
    path: '/line/v1/autoreply/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'event_type',
        schema: WebhookEventTypeSchema.optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'trigger_schedule_type',
        schema: TriggerScheduleTypeSchema.optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      results: AutoReplyItemSchema.array(),
    }),
  },
  {
    alias: 'autoReplyMetrics',
    method: 'get',
    path: '/line/v1/autoreply/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        all: z.number().int(),
        archived: z.number().int(),
        follower: z.number().int(),
        keyword: z.number().int(),
        time: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'autoReplyDetail',
    method: 'get',
    path: '/line/v1/autoreply/:autoReplyId/',
    response: AutoReplyItemSchema,
  },
  {
    alias: 'autoReplyReport',
    method: 'get',
    path: '/line/v1/autoreply/:autoReplyId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: AutoReplyReportSchema,
  },
  {
    alias: 'editorTriggerList',
    method: 'get',
    path: '/line/v1/editor_trigger/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'offset',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      results: AutoReplyEditorTriggerSchema.array(),
    }),
  },
  {
    alias: 'editorTriggerUpdate',
    method: 'patch',
    path: '/line/v1/editor_trigger/:editorTriggerId/',
    parameters: [
      {
        name: 'editorTriggerId',
        schema: z.number().int(),
        type: 'Path',
      },
      {
        name: 'body',
        type: 'Body',
        schema: EditorTriggerUpdateParametersSchema,
      },
    ],
    response: AutoReplyItemSchema,
  },
  {
    alias: 'autoReplyMonthlySchedule',
    method: 'get',
    path: '/line/v1/autoreply/monthly_schedule/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z.array(
      z.object({
        day: z.number().int(),
        start_time: z.string(),
        end_time: z.string(),
      }),
    ),
  },
  {
    alias: 'autoReplyCreate',
    method: 'post',
    path: '/line/v1/autoreply/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: AutoReplyCreateParametersSchema,
      },
    ],
    response: AutoReplyItemSchema,
  },
  {
    alias: 'autoReplyUpdate',
    method: 'patch',
    path: '/line/v1/autoreply/:autoReplyId/',
    parameters: [
      {
        name: 'autoReplyId',
        schema: z.number().int(),
        type: 'Path',
      },
      {
        name: 'body',
        type: 'Body',
        schema: AutoReplyUpdateParametersSchema,
      },
    ],
    response: AutoReplyItemSchema,
  },
  {
    alias: 'autoReplyDelete',
    method: 'delete',
    path: '/line/v1/autoreply/:autoReplyId/',
    parameters: [
      {
        name: 'autoReplyId',
        schema: z.number().int(),
        type: 'Path',
      },
    ],
    response: z.never(),
  },
  {
    alias: 'autoReplyExport',
    method: 'post',
    path: '/line/v1/autoreply/export/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            bot_id: z.number().int(),
            start_date: z.string(),
            end_date: z.string(),
            pk: z.number().int().optional(), // This is also the Auto-reply ID
          })
          .strict(),
        type: 'Body',
      },
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'event_type',
        schema: WebhookEventTypeSchema.optional(),
        type: 'Query',
      },

      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
    ],
    response: ExportResponseSchema.strict(),
  },
]);
