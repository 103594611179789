import type { CustomElementTypes } from 'shared/components/Editor/types/slate-custom';

import { theme } from 'theme';

export const getColorByTagType = (tagType: CustomElementTypes) => {
  switch (tagType) {
    case 'url':
      return theme.colors.blue006;
    case 'name':
    case 'referral_invitation_link':
      return theme.colors.green005;
    case 'referral_inviter':
      return theme.colors.blue005;
    case 'referral_invitee':
      return theme.colors.yellow006;
    case 'custom':
    case 'referral_activity_link':
      return theme.colors.purple006;
    case 'paragraph':
      return 'inherit';
    default:
      tagType satisfies never;
      break;
  }
};
