import type { RouteComponentProps } from '@reach/router';
import type { ComponentProps, ComponentType } from 'react';

/**
 * This function do nothing but inject the RouteComponentProps into the
 * component.
 */
export function withRoute<
  // Allowing P = {} to inherit from ComponentType
  // eslint-disable-next-line @typescript-eslint/ban-types
  P = {},
  // Allowing TParams = {} to inherit from RouteComponentProps
  // eslint-disable-next-line @typescript-eslint/ban-types
  TParams = {},
>(component: ComponentType<P>): ComponentType<P & RouteComponentProps<TParams>> {
  return component as ComponentType<P & RouteComponentProps>;
}

type RouterModule<
  Module extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  },
> = {
  [K in keyof Module]: ComponentType<ComponentProps<Module[K]> & RouteComponentProps>;
};

/**
 * This function do nothing but inject the RouteComponentProps into the
 * component.
 */
export function moduleWithRoute<
  Module extends {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: ComponentType<any>;
  },
>(module: Module): RouterModule<Module> {
  return module as RouterModule<Module>;
}
