export const SvgIconRichmenuS3Cell2 = () => (
  <svg width="1em" height="1em" viewBox="0 0 45 14">
    <g fill="none" fillRule="evenodd">
      <g stroke="currentColor" strokeWidth={2}>
        <path d="M13 1h31v12H13z" />
        <path d="M23 1v12M34 1v12" strokeLinecap="square" />
      </g>
      <path fill="currentColor" d="M23 0h11v13H23z" />
      <text
        fontFamily="Roboto-Medium, Roboto"
        fontSize={14}
        fontWeight={400}
        fill="currentColor"
        transform="translate(0 -4)"
      >
        <tspan x={0} y={13}>
          2
        </tspan>
      </text>
    </g>
  </svg>
);
