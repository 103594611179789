import { z } from 'zod';

export const BroadcastStatusEnum = {
  Draft: 'draft',
  Scheduled: 'scheduled',
  Sending: 'sending',
  Sent: 'sent',
  Fail: 'fail',
} as const;

export const BroadcastStatusSchema = z.nativeEnum(BroadcastStatusEnum);

export type BroadcastStatus = z.output<typeof BroadcastStatusSchema>;

export const BroadcastChannelEnum = {
  Line: 'line',
  Sms: 'sms',
} as const;

const BroadcastChannelSchema = z.nativeEnum(BroadcastChannelEnum);

export type BroadcastChannel = z.output<typeof BroadcastChannelSchema>;

export const BroadcastFilterEnum = {
  ...BroadcastStatusEnum,
  All: 'all',
  Search: 'search',
} as const;

const BroadcastFilterSchema = z.nativeEnum(BroadcastFilterEnum);

export type BroadcastFilterType = z.output<typeof BroadcastFilterSchema>;
