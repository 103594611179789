import { z } from 'zod';

import { ISO8601DateSchema } from 'shared/models/common/date';
import { JourneyNodeTypeSchemaSet } from 'shared/models/journey/nodeType';

export const JourneyNodeSchema = z
  .object({
    id: z.number().int(),
    journey_id: z.number().int(),
    created_at: ISO8601DateSchema,
    updated_at: ISO8601DateSchema,
  })
  .and(JourneyNodeTypeSchemaSet);

export type JourneyNodeParsed = z.output<typeof JourneyNodeSchema>;
