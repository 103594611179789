import { action } from 'typesafe-actions';

import type { State } from './context';
import type { BotInfo, FlatChannel } from 'layout/types';

export enum AuthAction {
  SET_STATE = 'SET_STATE',
  SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL',
  SET_CURRENT_BOT_DATA = 'SET_CURRENT_BOT_DATA ',
}

export const setState = (state: State): { type: AuthAction.SET_STATE; payload: State } =>
  action(AuthAction.SET_STATE, state);
export const setCurrentChannel = (
  channel: FlatChannel,
): { type: AuthAction.SET_CURRENT_CHANNEL; payload: FlatChannel } =>
  action(AuthAction.SET_CURRENT_CHANNEL, channel);
export const setCurrentBotData = (
  data: BotInfo,
): { type: AuthAction.SET_CURRENT_BOT_DATA; payload: BotInfo } =>
  action(AuthAction.SET_CURRENT_BOT_DATA, data);
