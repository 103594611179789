import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import type { ReactNode } from 'react';
import type { ModalProps } from 'shared/components/Modal/types';

import { Text } from 'components/Typography';
import { Button } from 'shared/components/Button';
import { Modal } from 'shared/components/Modal';

export const DeletingModal = memo(function DeletingModal({
  title,
  description,
  loading,
  open,
  onRemove,
  onCancel,
}: Pick<ModalProps, 'open' | 'title' | 'onCancel'> & {
  description: ReactNode;
  loading: boolean;
  onRemove: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Modal title={title} footer={null} open={open} onCancel={onCancel}>
      {description}
      <Text display="block" textAlign="right">
        <Button style={{ marginRight: '16px' }} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button type="primary" danger={true} loading={loading} onClick={onRemove}>
          {t('common.delete')}
        </Button>
      </Text>
    </Modal>
  );
});
