import { useCallback, useRef, useState } from 'react';

import type {
  BlockDragMoveData,
  ImageMapCustomPreviewBlock,
  RectData,
} from 'components/LineMessageEditor/components/ImageMapeditor/ImageMapCustomBlockEditor/types';
import type { Dispatch, MouseEvent as ReactMouseEvent, RefObject, SetStateAction } from 'react';

import { uiBorderWidth } from '../ImageMapCustomBlockEditor/Styled';
import { isStopMoving } from '../ImageMapCustomBlockEditor/utils/overlapChecking';

export const useBlockMove = (
  imageMapCustomWrapperRef: RefObject<HTMLDivElement>,
  previewBlocks: ImageMapCustomPreviewBlock[],
  setPreviewBlocks: Dispatch<SetStateAction<ImageMapCustomPreviewBlock[]>>,
  rectData: RectData,
): {
  onBlockMouseUpOrLeave: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
  onBlockMouseDown: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>, index: number) => void;
  isBlockDragging: boolean;
  movingIndex?: number;
} => {
  const blockDargDataRef = useRef<BlockDragMoveData>();
  const [isBlockDragging, setIsBlockDragging] = useState(false);
  const onBlockMove = useCallback(
    (e: MouseEvent) => {
      const scrollTop = imageMapCustomWrapperRef.current
        ? imageMapCustomWrapperRef.current.scrollTop
        : 0;
      const dragData = blockDargDataRef.current;

      if (dragData && dragData.isDarg) {
        e.preventDefault();
        dragData.setPreviewBlocks(
          dragData.previewBlocks.map((block, index) => {
            if (index === dragData.blockIndex) {
              const newBlock = {
                ...block,
                left: block.left + e.clientX - dragData.startX,
                top: block.top + e.clientY + scrollTop - dragData.startY,
              };
              const isStop = isStopMoving(
                newBlock,
                dragData.previewBlocks.filter((d, fIndex) => fIndex !== index),
              );
              if (!isStop) {
                dragData.lastSafeBlock = newBlock;
              }
              return isStop
                ? dragData.lastSafeBlock
                  ? dragData.lastSafeBlock
                  : block
                : blockMoveArea(newBlock, rectData);
            } else return block;
          }),
        );
      }
    },
    [rectData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onBlockMouseDown = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    e.stopPropagation();
    e.preventDefault();
    const scrollTop = imageMapCustomWrapperRef.current
      ? imageMapCustomWrapperRef.current.scrollTop
      : 0;
    const startX = e.clientX;
    const startY = e.clientY + scrollTop;
    blockDargDataRef.current = {
      blockIndex: index,
      startX,
      startY,
      previewBlocks,
      setPreviewBlocks,
      isDarg: true,
    };
    setIsBlockDragging(true);
    (e.target as HTMLDivElement).addEventListener('mousemove', onBlockMove);
  };

  const onBlockMouseUpOrLeave = (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    if (blockDargDataRef.current) blockDargDataRef.current.isDarg = false;
    setIsBlockDragging(false);
    (e.target as HTMLDivElement).removeEventListener('mousemove', onBlockMove);
  };
  return {
    onBlockMouseUpOrLeave,
    onBlockMouseDown,
    isBlockDragging,
    movingIndex: blockDargDataRef.current?.blockIndex,
  };
};

const blockMoveArea = (
  block: ImageMapCustomPreviewBlock,
  rectData: RectData,
): ImageMapCustomPreviewBlock => {
  const { left, width, height, top } = block;
  const uiBorder = uiBorderWidth * 2;
  return {
    ...block,
    left:
      left < 0
        ? 0
        : left + width > rectData.width - uiBorder
          ? rectData.width - uiBorder - width
          : left,
    top:
      top < 0
        ? 0
        : top + height > rectData.height - uiBorder
          ? rectData.height - uiBorder - height
          : top,
  };
};
