import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';

export const OpenExternalTooltip = memo(function OpenExternalTooltip() {
  const { t } = useTranslation();
  return (
    <Tooltip title={<TooltipDescription>{t('common.openInExternal.desc')}</TooltipDescription>} />
  );
});
