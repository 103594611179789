import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { AsyncTaskSchema, ListResponseSchema } from 'shared/models/common';
import { SpecialSellerTypeEnum } from 'shared/models/receiptRegister/common';

export const ReceiptRegisterStatusEnum = {
  ENABLE: 'enable',
  DISABLE: 'disable',
} as const;

export const ReceiptRegisterStatusSchema = z.nativeEnum(ReceiptRegisterStatusEnum);

const receiptRegisterItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  status: ReceiptRegisterStatusSchema,
  start_time: z.string(),
  end_time: z.string(),
  updated_at: z.string(),
});

const receiptRegisterKeywords = z.object({
  results: z.array(z.string()),
});

export const receiptRegisterApi = makeApi([
  {
    alias: 'receiptRegisterList',
    method: 'get',
    path: '/receipt/v1/campaign/',
    parameters: [
      {
        name: 'bot_id',
        type: 'Query',
        schema: z.number().int(),
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: receiptRegisterItemSchema.array(),
    }),
  },
  {
    alias: 'receiptRegisterDetail',
    method: 'get',
    path: '/receipt/v1/campaign/:receiptRegisterId/',
    response: z.object({
      id: z.number().int(),
      name: z.string(),
    }),
  },
  {
    alias: 'receiptRegisterKeywords',
    method: 'get',
    path: '/receipt/v1/campaign/:receiptRegisterId/product_keywords/',
    response: receiptRegisterKeywords,
  },
  {
    alias: 'receiptRegisterKeywordsUpdate',
    method: 'put',
    path: '/receipt/v1/campaign/:receiptRegisterId/product_keywords/',
    parameters: [
      {
        name: 'body',
        schema: receiptRegisterKeywords,
        type: 'Body',
      },
    ],
    response: z.never(),
  },
  {
    alias: 'receiptRegisterSpecificSellers',
    method: 'get',
    path: '/receipt/v1/campaign/:receiptRegisterId/specific_sellers/',
    response: z.discriminatedUnion('type', [
      z.object({
        type: z.literal(SpecialSellerTypeEnum.Name),
        results: z.array(z.string()),
      }),
      z.object({
        type: z.literal(SpecialSellerTypeEnum.UnifiedBusinessNumber),
        results: z.array(z.string()),
      }),
    ]),
  },
  {
    alias: 'receiptRegisterSpecificSellersUpdate',
    method: 'put',
    path: '/receipt/v1/campaign/:receiptRegisterId/specific_sellers/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: z.discriminatedUnion('type', [
          z.object({
            type: z.literal(SpecialSellerTypeEnum.Name),
            results: z.array(z.string()),
          }),
          z.object({
            type: z.literal(SpecialSellerTypeEnum.UnifiedBusinessNumber),
            results: z.array(z.string()),
          }),
        ]),
      },
    ],
    response: z.never(),
  },
  {
    alias: 'receiptRegisterKeywordsExport',
    method: 'post',
    path: '/receipt/v1/campaign/:receiptRegisterId/product_keywords/export/',
    response: AsyncTaskSchema,
  },
  {
    alias: 'receiptRegisterSellersExport',
    method: 'post',
    path: '/receipt/v1/campaign/:receiptRegisterId/specific_sellers/export/',
    response: AsyncTaskSchema,
  },
]);

export type ReceiptRegisterListResponse = ZodiosResponseByAlias<
  typeof receiptRegisterApi,
  'receiptRegisterList'
>;

export type ReceiptKeywordResponse = ZodiosResponseByAlias<
  typeof receiptRegisterApi,
  'receiptRegisterKeywords'
>;

export type ReceiptSellerResponse = ZodiosResponseByAlias<
  typeof receiptRegisterApi,
  'receiptRegisterSpecificSellers'
>;
