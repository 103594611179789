import type {
  CardEditorData,
  CarrouselData,
  ImageData,
  ImageSourceType,
  State,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { imageData } from 'components/LineMessageEditor/models/templateDataAndTypes/image';
import {
  isCardDataRule,
  isCarrouselDataRule,
  isImageDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { dispatch } from './models';

export const imageStore = {
  reducers: {
    setImageUrl(
      preState: State,
      payload: {
        rowIndex: number;
        url: string;
        carrouselIndex?: number;
        aspectRatio: string;
        isAnimated: boolean;
      },
    ): void {
      const target = preState.editorData[payload.rowIndex];
      if (typeof payload.carrouselIndex !== 'undefined') {
        (target as CarrouselData)
          .getCardData(payload.carrouselIndex)
          .setUrl(payload.url, payload.aspectRatio, payload.isAnimated);
      } else {
        (target as ImageData | CardEditorData).setUrl(
          payload.url,
          payload.aspectRatio,
          payload.isAnimated,
        );
      }
    },
    setImageUrlBySourceType(
      preState: State,
      payload: {
        rowIndex: number;
        sourceType: ImageSourceType;
        aspectRatio: string;
        isAnimated: boolean;
        url: string;
        carrouselIndex?: number;
      },
    ): void {
      const target = preState.editorData[payload.rowIndex];
      const isParam = payload.sourceType === 'param';

      if (typeof payload.carrouselIndex !== 'undefined' && isCarrouselDataRule(target)) {
        target.setUrlWithKey(
          isParam,
          payload.carrouselIndex,
          payload.url,
          payload.aspectRatio,
          payload.isAnimated,
        );
      } else if (isCardDataRule(target)) {
        target.setUrlWithKey(isParam, payload.url, payload.aspectRatio, payload.isAnimated);
      } else if (isImageDataRule(target)) {
        target.setUrlWithKey(isParam, payload.url);
      }
    },
    switchImageSourceType(
      preState: State,
      payload: {
        rowIndex: number;
        currentSourceType: ImageSourceType;
        previousSourceType: ImageSourceType;
        key?: string;
        carrouselIndex?: number;
      },
    ): void {
      const target = preState.editorData[payload.rowIndex];
      if (typeof payload.carrouselIndex !== 'undefined' && isCarrouselDataRule(target)) {
        target.switchCarrouselImageSourceType(
          payload.carrouselIndex,
          payload.currentSourceType,
          payload.previousSourceType,
        );
      } else if (isImageDataRule(target)) {
        target.switchImageSourceType(payload.currentSourceType, payload.previousSourceType);
      } else if (isCardDataRule(target)) {
        target.switchHeroSourceType(payload.currentSourceType, payload.previousSourceType);
      }
    },
  },
  effects: {
    async addImageModule(): Promise<void> {
      dispatch('addEditorData', { data: imageData });
    },
  },
};
