import type { ContentBlock } from 'draft-js';

export const URL_TAG_REGEX = /<\$var:url\d*>+/g;
export const NAME_TAG_REGEX = /<\$var:name>+/g;
export const EMOJI_REGEX = /\\udbc0\\udc\w{2}/g;
export const CUSTOM_TEXT_TAG_REGEX = /(<\$var:customText\w*>+)/g;
export const CUSTOM_TAG_REGEX = /<\$var:custom\d*>+/g;
export const INVITER_NAME_TAG_REGEX = /<\$var:referral_inviter>+/g;
export const INVITEE_NAME_TAG_REGEX = /<\$var:referral_invitee>+/g;
export const INVITATION_LINK_TAG_REGEX = /<\$var:referral_invitation_link>+/g;
export const REFERRAL_ACTIVITY_LINK_REGEX = /<\$var:referral_activity_link>+/g;
export const MEMBER_SCORE_REGEX = /<\$var:referral_member_score>+/g;
export const MEMBER_CODE_REGEX = /<\$var:referral_member_code>+/g;

type DraftStrategyCallback = (start: number, end: number) => void;

function findWithRegex(regex: RegExp, contentBlock: ContentBlock, callback: DraftStrategyCallback) {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

export function userNameStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(NAME_TAG_REGEX, contentBlock, callback);
}
export function urlStrategy(contentBlock: ContentBlock, callback: DraftStrategyCallback): void {
  findWithRegex(URL_TAG_REGEX, contentBlock, callback);
}
export function customStrategy(contentBlock: ContentBlock, callback: DraftStrategyCallback): void {
  findWithRegex(CUSTOM_TAG_REGEX, contentBlock, callback);
}
export function customTextStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(CUSTOM_TEXT_TAG_REGEX, contentBlock, callback);
}

export function inviterNameStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(INVITER_NAME_TAG_REGEX, contentBlock, callback);
}
export function inviteeNameStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(INVITEE_NAME_TAG_REGEX, contentBlock, callback);
}
export function invitationLinkStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(INVITATION_LINK_TAG_REGEX, contentBlock, callback);
}
export function referralActivityLinkStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(REFERRAL_ACTIVITY_LINK_REGEX, contentBlock, callback);
}
export function memberScoreStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(MEMBER_SCORE_REGEX, contentBlock, callback);
}
export function memberCodeStrategy(
  contentBlock: ContentBlock,
  callback: DraftStrategyCallback,
): void {
  findWithRegex(MEMBER_CODE_REGEX, contentBlock, callback);
}
