import { useMemo } from 'react';

import { TEL_URL_PREFIX } from 'AppConstants';

const checkUrl = (link: string) => {
  const rules = [
    {
      schemas: [
        TEL_URL_PREFIX,
        'line://',
        'http://line.me',
        'https://line.me',
        'http://liff.line.me',
        'https://liff.line.me',
      ],
      hint: 'message.url.notTaggable',
      tooltip: 'message.url.notTaggable.tooltip',
    },
    {
      schemas: ['https://maac.io'],
      hint: 'message.url.maacLinkHint',
      tooltip: 'message.url.maacLinkHint.tooltip',
    },
  ];

  for (let i = 0; i < rules.length; i++) {
    const element = rules[i];
    if (element.schemas.some((schema) => link.startsWith(schema))) {
      return {
        checked: false,
        message: element.hint,
        tooltip: element.tooltip,
      };
    }
  }

  return {
    checked: true,
    message: '',
    tooltip: '',
  };
};

export function useTaggable(link: string): {
  taggable: boolean;
  message: string;
  tooltip: string;
} {
  const { checked, message, tooltip } = useMemo(() => checkUrl(link), [link]);
  return useMemo(
    () => ({
      taggable: checked,
      message,
      tooltip,
    }),
    [checked, message, tooltip],
  );
}
