import { inspectMessage } from '@chatbotgang/etude/debug/inspectMessage';
import { memo } from '@chatbotgang/etude/react/memo';
import { useHandler } from '@chatbotgang/etude/react/useHandler';
import { createVarCall } from '@chatbotgang/motif';
import { Divider, Flex, Form, Space } from 'antd';
import { commonFeatureFlagsApi, useEnabledSomeFeatureFlags } from 'features/featureFlag';
import { commonConfig } from 'features/featureFlag/commonConfig';
import deburr from 'lodash/deburr';
import { useMemo, useState } from 'react';
import { omitBy } from 'remeda';
import * as R from 'remeda';

import { APP_LOADED_DATE, GLOBAL_SEARCH_PARAM_FEATURE_FLAG } from 'AppConstants';

import type { CommonFeatureFlagsTypes } from 'features/featureFlag';
import type { BaseToggleFeatureFlagConfig } from 'features/featureFlag/baseTypes';
import type { ComponentProps, ReactNode } from 'react';
import type { RadioGroup } from 'shared/components/Radio';
import type { ConditionalKeys } from 'type-fest';

import { CopyButton } from 'components/CopyButton';
import { MotifIcon } from 'icons/motif';
import { Button } from 'shared/components/Button';
import { Checkbox } from 'shared/components/Checkbox';
import { CountDown } from 'shared/components/CountDown';
import { Input } from 'shared/components/Input';
import { Radio } from 'shared/components/Radio';
import { Typography } from 'shared/components/Typography';
import { useNow } from 'utils/react/useNow';

const {
  configs,
  isToggleFeatureFlagKey,
  isSingleSelectFeatureFlagKey,
  useFeatureFlagLocalStorageStore,
  useFeatureFlagStore,
} = commonFeatureFlagsApi;

function useShouldAutoEnable(feature: BaseToggleFeatureFlagConfig) {
  const now = useNow();
  return feature.autoEnableAt !== undefined && feature.autoEnableAt < now;
}

function getAutoEnabled(feature: BaseToggleFeatureFlagConfig) {
  return feature.autoEnableAt !== undefined && feature.autoEnableAt < APP_LOADED_DATE;
}

const AutoEnableMessage = memo(function AutoEnableMessage({
  featureFlagKey,
  autoEnableAt,
}: {
  featureFlagKey: CommonFeatureFlagsTypes['ToggleKey'];
  autoEnableAt: BaseToggleFeatureFlagConfig['autoEnableAt'];
}) {
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);
  const shouldAutoEnabled = useShouldAutoEnable(configs[featureFlagKey]);
  return (
    <>
      {autoEnableAt === undefined ? null : (
        <div>
          {shouldAutoEnabled ? (
            autoEnabled ? (
              'Enabled automatically'
            ) : (
              'Please refresh to enable automatically'
            )
          ) : (
            <>
              (Will be enabled in: <CountDown targetDate={autoEnableAt} />)
            </>
          )}
        </div>
      )}
    </>
  );
});

function getLabel(featureFlagKey: CommonFeatureFlagsTypes['Key']) {
  return commonConfig[featureFlagKey].label ?? featureFlagKey;
}

const FeatureItemToggle = memo(function FeatureItemToggle<
  Key extends CommonFeatureFlagsTypes['ToggleKey'],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const autoEnabled = getAutoEnabled(configs[featureFlagKey]);

  const checked: boolean = useFeatureFlagStore((state) => state.value[featureFlagKey]);

  const onChange = useHandler(() =>
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: !current[featureFlagKey],
    })),
  );

  return (
    <Checkbox checked={checked ?? false} onChange={onChange} disabled={autoEnabled}>
      {label}
      {feature.autoEnableAt === undefined ? null : (
        <AutoEnableMessage featureFlagKey={featureFlagKey} autoEnableAt={feature.autoEnableAt} />
      )}
    </Checkbox>
  );
});

const FeatureItemSingleSelect = memo(function FeatureItemSingleSelect<
  Key extends CommonFeatureFlagsTypes['SingleSelectKey'],
>({ featureFlagKey }: { featureFlagKey: Key }) {
  const label = getLabel(featureFlagKey);
  const feature = configs[featureFlagKey];
  const value = useFeatureFlagStore((state) => state.value[featureFlagKey]);
  const enabled = value !== null;

  const changeHandler = useHandler<ComponentProps<typeof RadioGroup>['onChange']>((e) => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: e.target.value as CommonFeatureFlagsTypes['Values'][Key],
    }));
  });

  const enable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: feature.options[0]?.value,
    }));
  });
  const disable = useHandler(() => {
    useFeatureFlagLocalStorageStore.getState().setValue((current) => ({
      ...current,
      [featureFlagKey]: null,
    }));
  });

  return (
    <Flex vertical={true}>
      <Checkbox checked={enabled} onChange={enabled ? disable : enable}>
        {label}
      </Checkbox>
      <Radio.Group onChange={changeHandler} value={value} style={{ paddingLeft: 24 }}>
        {feature.options.map(({ value: targetValue, label = targetValue }) => (
          <Radio key={targetValue} value={targetValue}>
            {label}
          </Radio>
        ))}
      </Radio.Group>
    </Flex>
  );
});

const FeatureItem = memo<{
  featureFlagKey: CommonFeatureFlagsTypes['FunctionalKey'];
}>(function FeatureItem({ featureFlagKey }) {
  if (isToggleFeatureFlagKey(featureFlagKey))
    return <FeatureItemToggle featureFlagKey={featureFlagKey} />;
  if (isSingleSelectFeatureFlagKey(featureFlagKey))
    return <FeatureItemSingleSelect featureFlagKey={featureFlagKey} />;
  featureFlagKey satisfies never;
  throw new Error(inspectMessage`Unhandled feature flag type: ${featureFlagKey}`);
});

const configsGroupedByDivider = (function configsGroupedByDivider() {
  const result: Array<{
    divider: {
      key: string;
      label: ReactNode;
    };
    configs: Partial<
      Pick<typeof configs, ConditionalKeys<typeof configs, { type: 'toggle' | 'singleSelect' }>>
    >;
  }> = [
    {
      divider: {
        key: '',
        label: 'Ungrouped',
      },
      configs: {},
    },
  ];
  Object.entries(configs).forEach(([key, value]) => {
    if (value.type === 'divider') {
      result.push({
        divider: {
          key,
          label: value.label,
        },
        configs: {},
      });
    } else {
      Object.defineProperty(result[result.length - 1].configs, key, {
        value,
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  });
  return result;
})();

/**
 * Normalize the search string.
 */
function searchNormalize(search: string) {
  return R.pipe(
    search,
    deburr,
    (search) => search.replaceAll(/[^a-zA-Z]+/g, ''),
    (search) => search.toLowerCase(),
  );
}

export const Feature = memo(function Feature() {
  const { value, clear } = useFeatureFlagLocalStorageStore(({ value, clear }) => ({
    value,
    clear,
  }));

  const simplifiedValue = useMemo(
    () =>
      R.pipe(
        value,
        R.entries,
        R.flatMap(([featureFlagKey, value]) => {
          const typedFeatureFlagKey = featureFlagKey as CommonFeatureFlagsTypes['Key'];
          const featureConfig = commonConfig[typedFeatureFlagKey];
          if (featureConfig.type === 'toggle' && !value) return [];

          if (featureConfig.type === 'singleSelect' && value === null) return [];

          return [[featureFlagKey, value]];
        }),
        R.fromEntries,
      ),
    [value],
  );

  const enabledSomeFeatureFlags = useEnabledSomeFeatureFlags();

  const url = useMemo(() => {
    const draftLocation = new URL(window.location.href);
    const params = new URLSearchParams(draftLocation.search);
    if (enabledSomeFeatureFlags) {
      params.set(GLOBAL_SEARCH_PARAM_FEATURE_FLAG, JSON.stringify(simplifiedValue));
    } else {
      params.set(GLOBAL_SEARCH_PARAM_FEATURE_FLAG, '');
    }

    draftLocation.search = params.toString();
    return draftLocation.toString();
  }, [enabledSomeFeatureFlags, simplifiedValue]);

  const [search, setSearch] = useState('');
  const normalizedSearch = useMemo(() => searchNormalize(search), [search]);

  const filteredGroupedConfigs = useMemo(() => {
    return configsGroupedByDivider.flatMap<(typeof configsGroupedByDivider)[number]>(
      (configsGroup) => {
        const filteredConfigs = omitBy(configsGroup.configs, (config, key) =>
          !normalizedSearch
            ? false
            : !searchNormalize(key).includes(normalizedSearch) &&
              !(config.label && searchNormalize(config.label).includes(normalizedSearch)),
        );
        if (Object.keys(filteredConfigs).length === 0) return [];
        return [
          {
            ...configsGroup,
            configs: filteredConfigs,
          },
        ];
      },
    );
  }, [normalizedSearch]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Flex gap={8}>
        <Input value={url} readOnly={true} suffix={<CopyButton text={url} />} />
        <Button onClick={clear} type="primary">
          Clear All
        </Button>
      </Flex>
      <ul
        style={{
          listStyleType: 'none',
        }}
      >
        <Form.Item label="Search" style={{ marginBottom: '0.75rem' }}>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            allowClear={true}
            prefix={<MotifIcon un-i-motif="magnifier" />}
          />
        </Form.Item>
        {filteredGroupedConfigs.length > 0 ? null : (
          <Typography color={createVarCall('--static-fg-body')}>No feature flag found.</Typography>
        )}
        {filteredGroupedConfigs.map(({ divider, configs }) => (
          <li key={divider.key}>
            {divider.label ? <Divider>{divider.label}</Divider> : null}
            <ul
              style={{
                listStyleType: 'none',
              }}
            >
              {Object.entries(configs).map(([key]) => {
                const featureFlagKey = key as CommonFeatureFlagsTypes['FunctionalKey'];
                return (
                  <li key={key}>
                    <FeatureItem featureFlagKey={featureFlagKey} />
                  </li>
                );
              })}
            </ul>
          </li>
        ))}
      </ul>
    </Space>
  );
});
