import { useCallback, useState } from 'react';

import type { Dispatch, SetStateAction } from 'react';

/**
 * ```js
 * const text = useTextInput();
 * const value = text.value
 * text.clear()
 * return (
 *  <input {...text.bind} />
 * )
 * ```
 */
export function useTextInput(defaultValue = ''): {
  bind: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (e: any) => void;
    value: string;
  };
  value: string;
  clear: () => void;
  setValue: Dispatch<SetStateAction<string>>;
} {
  const [value, setValue] = useState(defaultValue);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = useCallback<(e: any) => void>((e) => setValue(e.target.value), []);

  const clear = () => setValue('');

  return {
    bind: {
      onChange,
      value,
    },
    value,
    clear,
    setValue,
  };
}
