import { createVarCall } from '@chatbotgang/motif';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Tag from 'antd/es/tag';

import { theme } from 'theme';

interface TagItemProps {
  wrapTags?: boolean;
}

/**
 * A light wrapper around the Ant Design tag component
 * This is specifically for use with the tagging system, not as a generic tag component
 * Use it when displaying tags, do not use it for "recommended" or "purchased" or other things
 */
const TagItemBase = styled(Tag)<TagItemProps>`
  ${({ wrapTags }) =>
    wrapTags
      ? css`
          margin-bottom: 8px;
          white-space: normal;
          word-break: break-word;
        `
      : undefined}
`;

/**
 * A standard tag item element, lightly styled
 */
export const TagItem = styled(TagItemBase)<TagItemProps>`
  border-color: ${theme.colors.neutral002};
  color: ${theme.colors.neutral010};
  font-size: 14px;
`;

/**
 * A very basic implementation of a new tag item from the design system, 2024Q4
 */
export const TagFlatItem = styled(Tag)`
  background-color: ${createVarCall('--tag-bg-standard')};
  border: none;
  border-radius: 4px;
  color: ${createVarCall('--tag-fg-standard')};
  font-size: 14px;
`;

/**
 * A rounded tag used in more current designs (2024 onward)
 */
export const TagRounded = styled(Tag)`
  border-radius: 12px;
  display: flex;
  margin-inline-end: 0;
`;
