import { motion } from 'framer-motion';

import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

export const VerticalDrawerWrapper = styled(motion.div)`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background: ${theme.colors.white001};
  width: auto;
  position: absolute;
  right: 100px;
  z-index: 200;
  box-shadow: -10px -10px 30px ${theme.colors.neutral003};
  overflow: hidden;
`;

export const VerticalDrawerHeader = styled.div`
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  color: ${theme.colors.white001};
  cursor: pointer;
  background-color: ${theme.colors.blue006};
`;

export const VerticalDrawerContent = styled.div`
  overflow: hidden;
  padding: 20px;
`;
