import type { FlexIcon } from '@line/bot-sdk';

import * as S from '../styled';
import { sizeClassName } from '../utils';

interface FlexIconComponentProps {
  // parentLayout: 'baseline';
  data: FlexIcon;
}

export const FlexIconComponent = ({ data }: FlexIconComponentProps) => {
  /**
   * The icon's `flex` property is fixed to 0.
   */
  const { size, url } = data;
  return (
    <div className={S.classNames('icon', sizeClassName(size), 'flex0')}>
      <div>
        <span style={{ width: '1em', backgroundImage: `url(${url})` }}></span>
      </div>
    </div>
  );
};
