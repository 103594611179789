export enum ParameterType {
  /** MessageBuilder.DYNAMIC_TYPE_STANDARD */
  standard = 'standard',
  /** MessageBuilder.DYNAMIC_TYPE_OBJECT */
  object = 'object',
  /** MessageBuilder.DYNAMIC_TYPE_CUSTOM_STRING */
  custom_string = 'custom_string',
  /** MessageBuilder.DYNAMIC_TYPE_CUSTOM_OBJECT */
  custom_object = 'custom_object',
}
