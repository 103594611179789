import { PARAM_RICH_TEXT_FIELD_NAME_INDEX } from 'components/LineMessageEditor/constants';

import type {
  ParamRichTextFieldName,
  ParamRichTextFieldNamePrefixed,
} from 'components/LineMessageEditor/constants';

export const isParamRichTextFieldPrefixed = (
  field?: string,
  prefix?: ParamRichTextFieldName,
): field is ParamRichTextFieldNamePrefixed => {
  if (field !== undefined && prefix !== undefined) {
    return field.startsWith(prefix);
  }
  return (
    field !== undefined &&
    Object.keys(PARAM_RICH_TEXT_FIELD_NAME_INDEX).some((k) => field.startsWith(k))
  );
};

const isParamRichTextFieldCardTitle = (field: string): boolean => {
  return isParamRichTextFieldPrefixed(field, 'CardTitle');
};

const isParamRichTextFieldCardDescription = (field: string): boolean => {
  return isParamRichTextFieldPrefixed(field, 'CardDescription');
};

export const createParamRichTextFieldEntityKey = (
  field: ParamRichTextFieldName,
  id?: string | number,
): ParamRichTextFieldNamePrefixed => {
  return `${field}${id ?? ''}`;
};

/**
 * @example
 *
 * ```ts
 * getFieldNameByMappingKey('customTextInCardTitle0') // 'CardTitle'
 * getFieldNameByMappingKey('customImage_12345) // 'customImage_12345'
 * ```
 *
 * @param mappingKey
 */
export const getFieldNameByMappingKey = (mappingKey: string): ParamRichTextFieldName | string => {
  const [prefix, fieldNameLike] = mappingKey.split('In');
  if (isParamRichTextFieldCardTitle(fieldNameLike)) {
    return 'CardTitle';
  } else if (isParamRichTextFieldCardDescription(fieldNameLike)) {
    return 'CardDescription';
  }
  return prefix;
};
