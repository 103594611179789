import { z } from 'zod';

import { isCdpTagUniqueId } from 'shared/utils/cdp';

export const CdpDataSourceEnum = {
  Maac: 'maac',
  Caac: 'caac',
} as const;

export const CdpDataSourceSchema = z.nativeEnum(CdpDataSourceEnum);

export type CdpDataSource = z.output<typeof CdpDataSourceSchema>;

export type CdpTagUniqueId = `${CdpDataSource}-${number}`;

export const CdpTagUniqueIdSchema = z.custom<CdpTagUniqueId>(isCdpTagUniqueId);
