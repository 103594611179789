import { makeApi } from '@zodios/core';
import { z } from 'zod';

const integrationsItemSettingSchema = z.object({
  installation: z.boolean(),
  redirect_url: z.string(),
});

export const integrationsApi = makeApi([
  {
    alias: 'shopifySettings',
    method: 'get',
    path: '/shopify/v1/member_card_setting/installation_status/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number(),
        type: 'Query',
      },
    ],
    response: integrationsItemSettingSchema.strict(),
  },
  {
    alias: 'shoplineSettings',
    method: 'get',
    path: '/shopline/v1/member_card_setting/installation_status/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number(),
        type: 'Query',
      },
    ],
    response: integrationsItemSettingSchema.strict(),
  },
  {
    alias: 'ninetyOneAppSettings',
    method: 'get',
    path: '/91app/v1/member_card_setting/installation_status/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number(),
        type: 'Query',
      },
    ],
    response: integrationsItemSettingSchema.strict(),
  },
  {
    alias: 'cyberbizSettings',
    method: 'get',
    path: '/cyberbiz/v1/member_card_setting/installation_status/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number(),
        type: 'Query',
      },
    ],
    response: integrationsItemSettingSchema.strict(),
  },
]);
