import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  ImageMapActionMessageType,
  ImageMapActionUrlType,
  ImageMapDataType,
  ImageMapUrlParameterType,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { FlatChannel } from 'layout/types';

import { Box } from 'components';
import { Context } from 'components/LineMessageEditor/models';
import * as Validator from 'lib/validator';
import { customNameHelper, messageNameHelper } from 'lib/validator/helper/validatorHelpers';
import { TriggerCodeModule } from 'modules/TriggerCodeModule';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../Styled';
import { UrlUTMModule } from '../UrlUTMModule';

interface Props {
  message: ImageMapDataType;
  rowIndex: number;
  currentChannel: FlatChannel | null;
}
export const ImageMapTextOrUrl = memo(function ImageMapTextOrUrl({
  message,
  rowIndex,
  currentChannel,
}: Props) {
  const { dispatch } = useContext(Context);
  const { validate } = useContext(Validator.Context);
  const { t } = useTranslation();

  return message.data.actions.length > 0 ? (
    <>
      <S.PopOverFieldTitle>
        {t(
          message.data.actions[message.activeBlockIndex - 1].type === 'message'
            ? 'glossary.message'
            : (
                  message.data.actions[message.activeBlockIndex - 1] as ImageMapActionUrlType
                ).linkUri.indexOf('$var:custom') !== -1
              ? 'message.apiParameterName'
              : 'glossary.url',
        )}
        #{message.activeBlockIndex}
      </S.PopOverFieldTitle>
      {message.data.actions.map((action, index) => {
        if (action.type === 'message') {
          return (
            <Box key={'ImageMapEditor' + rowIndex + 'ActionContent' + index}>
              <S.PopOverFieldsContent
                style={{ display: index === message.activeBlockIndex - 1 ? 'block' : 'none' }}
              >
                <Validator.PopoverFieldTextArea
                  name={messageNameHelper({
                    rowIndex: rowIndex,
                    editorType: 'ImageMapEditor',
                    entityKey: index.toString(),
                  })}
                  rules={[Validator.Rules.maxLength(300), Validator.Rules.required]}
                  value={(action as ImageMapActionMessageType).text}
                  onChange={(e) => {
                    dispatch('setImageMapActiveBlockMessageData', {
                      rowIndex,
                      text: e.currentTarget.value,
                      type: 'trigger_code',
                    });
                    validate();
                  }}
                  onBlur={(e) => {
                    dispatch('setImageMapActiveBlockMessageData', {
                      rowIndex,
                      text: e.currentTarget.value,
                      type: 'trigger_code',
                    });
                    validate();
                  }}
                  placeholder={t('message.inputWithLengthLimit', {
                    fieldName: lowerFirst(t('glossary.message')),
                    length: 300,
                  })}
                  checkOnChange={true}
                  enableReinitialize={true}
                  autoFocus={true}
                  data-test={`imagemap-settings-action-block-${index + 1}-message-input`}
                />
              </S.PopOverFieldsContent>
            </Box>
          );
        } else if (
          action.type === 'uri' &&
          (action as ImageMapActionUrlType).linkUri.indexOf('custom') !== -1
        ) {
          return (
            <Box key={'ImageMapEditor' + rowIndex + 'ActionContent' + index}>
              <S.PopOverFieldsContent
                style={{ display: index === message.activeBlockIndex - 1 ? 'block' : 'none' }}
              >
                <Validator.PopoverField
                  name={customNameHelper({
                    rowIndex: rowIndex,
                    editorType: 'ImageMapEditor',
                    entityKey: index.toString(),
                  })}
                  rules={[Validator.Rules.required, Validator.Rules.customParameter]}
                  value={
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    message.parameters.find(
                      (d) =>
                        d.mappingKey ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    )!.key
                  }
                  onChange={(e) => {
                    dispatch('setImageMapActiveBlockCustomUrlData', {
                      rowIndex,
                      text: e.currentTarget.value,
                    });
                    validate();
                  }}
                  placeholder={t('common.inputFieldName', {
                    fieldName: lowerFirst(t('message.apiParameterName')),
                  })}
                  checkOnChange={true}
                  enableReinitialize={true}
                  data-test={`imagemap-settings-action-block-${index + 1}-customUri-input`}
                />
              </S.PopOverFieldsContent>
            </Box>
          );
        } else {
          return (
            <Box key={'ImageMapEditor' + rowIndex + 'ActionContent' + index}>
              <UrlUTMModule
                wrapperStyle={{
                  display: index === message.activeBlockIndex - 1 ? 'block' : 'none',
                }}
                rowIndex={rowIndex}
                entityKey={index.toString()}
                urlValue={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.url
                }
                openExternal={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.open_external_browser
                }
                onOpenExternalChange={(checked) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: checked,
                    type: 'open_external_browser',
                  });
                }}
                onFieldsChange={(uri, utmFields) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: uri,
                    type: 'url',
                  });
                  Object.entries(utmFields).forEach(([key, value]) => {
                    dispatch('setImageMapActiveBlockUrlData', {
                      rowIndex,
                      text: value,
                      type: key,
                    });
                  });
                }}
                UTMSourceValue={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.utm_source
                }
                onUTMSourceChange={(value) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: value,
                    type: 'utm_source',
                  });
                }}
                UTMMediumValue={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.utm_medium
                }
                onUTMMediumChange={(value) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: value,
                    type: 'utm_medium',
                  });
                }}
                UTMCampaignValue={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.utm_campaign
                }
                onUTMCampaignChange={(value) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: value,
                    type: 'utm_campaign',
                  });
                }}
                UTMContentValue={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.utm_content
                }
                onUTMContentChange={(value) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: value,
                    type: 'utm_content',
                  });
                }}
                tagList={
                  (
                    message.parameters.find(
                      (d) =>
                        d.key ===
                        (action as ImageMapActionUrlType).linkUri
                          .replace('<$var:', '')
                          .replace('>', ''),
                    ) as ImageMapUrlParameterType
                  ).data.tag_list
                }
                onTagChange={(tags) => {
                  dispatch('setImageMapActiveBlockUrlData', {
                    rowIndex,
                    text: '',
                    type: 'tag_list',
                    tagList: tags,
                    index,
                  });
                }}
                editorType="ImageMapEditor"
                data-test={`imagemap-settings-action-block-${index + 1}-uri-input`}
              ></UrlUTMModule>
            </Box>
          );
        }
      })}
      <S.PopOverFieldTitle>
        {message.data.actions[message.activeBlockIndex - 1].type === 'message' ? (
          <TriggerCodeModule
            currentChannel={currentChannel}
            triggerCode={
              (message.data.actions[message.activeBlockIndex - 1] as ImageMapActionMessageType).text
            }
          />
        ) : null}
      </S.PopOverFieldTitle>
    </>
  ) : null;
});
