export const ApiSvg = () => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 96 96" version="1.1">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Artboard" transform="translate(-576.000000, -385.000000)" fillRule="nonzero">
          <g id="Icon_API-parameter" transform="translate(576.000000, 385.000000)">
            <rect
              id="Rectangle"
              fill="#000000"
              opacity="0"
              x="0"
              y="0"
              width="96"
              height="96"
            ></rect>
            <path
              d="M41.1,79.603125 L61.978125,18.975 C61.996875,18.928125 62.00625,18.871875 62.015625,18.825 L41.1,79.603125 Z M61.9875,15.815625 L55.659375,15.815625 C55.340625,15.815625 55.05,16.021875 54.946875,16.321875 L33.253125,79.3125 C33.225,79.3875 33.215625,79.471875 33.215625,79.55625 C33.215625,79.96875 33.553125,80.30625 33.965625,80.30625 L40.321875,80.30625 C40.640625,80.30625 40.93125,80.1 41.034375,79.8 L41.1,79.603125 L62.015625,18.825 L62.709375,16.81875 C62.7375,16.74375 62.746875,16.659375 62.746875,16.575 C62.7375,16.153125 62.409375,15.815625 61.9875,15.815625 L61.9875,15.815625 Z M89.49375,47.071875 C89.41875,46.978125 89.334375,46.89375 89.240625,46.81875 L68.709375,30.759375 C68.38125,30.50625 67.9125,30.5625 67.659375,30.890625 C67.55625,31.021875 67.5,31.18125 67.5,31.35 L67.5,38.971875 C67.5,39.20625 67.603125,39.421875 67.790625,39.5625 L78.571875,48 L67.790625,56.4375 C67.6125,56.578125 67.5,56.79375 67.5,57.028125 L67.5,64.65 C67.5,65.0625 67.8375,65.4 68.25,65.4 C68.41875,65.4 68.578125,65.34375 68.709375,65.240625 L89.240625,49.18125 C89.8875,48.675 90.009375,47.728125 89.49375,47.071875 L89.49375,47.071875 Z M27.290625,30.759375 L6.759375,46.81875 C6.665625,46.89375 6.58125,46.978125 6.50625,47.071875 C6,47.728125 6.1125,48.665625 6.759375,49.18125 L27.290625,65.240625 C27.421875,65.34375 27.58125,65.4 27.75,65.4 C28.1625,65.4 28.5,65.0625 28.5,64.65 L28.5,57.028125 C28.5,56.79375 28.396875,56.578125 28.209375,56.4375 L17.428125,48 L28.209375,39.5625 C28.3875,39.421875 28.5,39.20625 28.5,38.971875 L28.5,31.35 C28.5,31.18125 28.44375,31.021875 28.340625,30.890625 C28.0875,30.5625 27.61875,30.50625 27.290625,30.759375 L27.290625,30.759375 Z"
              id="Shape"
              fill="#A1A8B3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
