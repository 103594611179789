// eslint-disable-next-line no-restricted-imports
import AntPopover from 'antd/es/popover';

// eslint-disable-next-line no-restricted-imports
import type { PopoverProps } from 'antd/es/popover';

export type { PopoverProps };

export const Popover = (props: PopoverProps) => {
  return (
    <AntPopover
      /*
       * Make the Popover content fresh to fix the following issues:
       * 1) This is for react-hook-form (RHF). In the case we put RHF Controller in Ant Design Popover.
       *    The cached content leads RHF to cache some data that should be deleted after the Popover is removed from the DOM.
       * 2) We need to keep the input component inside Popover up to date so that validation works fine with the latest input value.
       */
      fresh={true}
      destroyTooltipOnHide={true}
      {...props}
    />
  );
};
