import { css } from 'shared/utils/styled';

export const hideScrollBar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const flexCenter = css`
  justify-content: center;
  align-items: center;
`;
