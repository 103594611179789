import CameraFilled from '@ant-design/icons/CameraFilled';
import EnvironmentFilled from '@ant-design/icons/EnvironmentFilled';
import PictureFilled from '@ant-design/icons/PictureFilled';
import { useTranslation } from 'react-i18next';

import type { QuickReplyItems } from '../components/QuickReplyEditor';

import { Box } from 'components';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { styled } from 'shared/utils/styled';
import { lowerFirst } from 'utils/string/changeCase';

import * as S from '../components/QuickReplyEditor/Styled';

import { CarrouselScrollWrapper } from './Styled';

const QuickReplyPreviewWrapper = styled(CarrouselScrollWrapper)`
  margin-left: 0;
  margin-top: 40px;
  > div {
    min-width: auto;
  }
`;

export const QuickReplyPreview = ({
  quickReplyItems,
  onAddUserMessageText,
}: {
  quickReplyItems: QuickReplyItems;
  onAddUserMessageText: (text: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box data-test="quickReply-preview" zIndex={2}>
      <QuickReplyPreviewWrapper>
        {quickReplyItems.map((item, index) => {
          let hintMessage = '';
          if (item.action.type === 'message') {
            hintMessage = t('common.textsWithColon', {
              textBeforeColon: t('message.send'),
              textAfterColon: item.action.text,
            });
          } else {
            if (item.action.type === 'camera') hintMessage = t('message.openCamera');
            if (item.action.type === 'cameraRoll')
              hintMessage = t('common.uploadObject', { object: lowerFirst(t('glossary.image')) });
            if (item.action.type === 'location') hintMessage = t('common.shareLocation');
          }

          return (
            <Tooltip
              key={`QuickReplyPreviewTooltip_${index}`}
              title={<TooltipDescription>{hintMessage}</TooltipDescription>}
            >
              <S.QuickReplyPill
                previewMode={true}
                onClick={() => {
                  if (item.action.type === 'message') onAddUserMessageText(item.action.text);
                }}
              >
                {item.action.type === 'camera' ? <CameraFilled /> : null}
                {item.action.type === 'cameraRoll' ? <PictureFilled /> : null}
                {item.action.type === 'location' ? <EnvironmentFilled /> : null}
                {item.action.type === 'message' && item.imageUrl ? (
                  <S.QuickReplyPillImage src={item.imageUrl} />
                ) : null}
                {item.action.label}
              </S.QuickReplyPill>
            </Tooltip>
          );
        })}
      </QuickReplyPreviewWrapper>
    </Box>
  );
};
