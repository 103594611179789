import { memo } from 'react';

import type { Dispatch, SetStateAction } from 'react';
import type { PopoverProps } from 'shared/components/Popover';

import { Popover } from 'shared/components/Popover';
import { styled } from 'shared/utils/styled';

interface ParamImagePopoverProps extends PopoverProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const ParamPopoverContent = styled.div`
  width: 350px;
  max-height: 540px;
  overflow: auto;
`;

export const ParamPopover = memo(function ParamPopover({
  isOpen,
  setIsOpen,
  title,
  content,
  children,
}: ParamImagePopoverProps) {
  return (
    <Popover
      placement="right"
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      destroyTooltipOnHide={false}
      overlayStyle={{ zIndex: 999 }}
      // This ensures the content is rendered so that the validator can check all fields.
      forceRender={true}
      title={title}
      content={content}
    >
      {children}
    </Popover>
  );
});
