import { forwardRef, memo, useMemo } from 'react';

import type { ProgressBarProps } from 'components/ProgressBar';
import type { ComponentPropsWithRef } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { ProgressBar, ProgressBarState } from 'components/ProgressBar';
import { styled } from 'shared/utils/styled';
import { css, cx, overrideCss } from 'shared/utils/styled/override';

type ProgressProps = {
  /**
   * 0 ~ 1.
   * 0.01 for 1%.
   * The value will be rounded to the nearest 0.01.
   */
  progress: number;
  progressBarProps?: Omit<ProgressBarProps, 'progress'>;
} & ComponentPropsWithRef<'div'> &
  OverrideComponentCssProps;

const PercentPlaceHolder = styled.div`
  opacity: 0;
  pointer-events: none;
  user-select: none;
`;

const PercentBlock = styled.div`
  position: relative;
  white-space: nowrap;
`;

const PercentDisplay = styled.div`
  position: absolute;
  right: 0;
`;

export const Progress = styled(
  memo(
    forwardRef<HTMLDivElement, ProgressProps>(function Progress(
      { progress, progressBarProps, ...props },
      ref,
    ) {
      const roundedProgressPercent = useMemo(() => Math.round(progress * 100), [progress]);
      return (
        <div {...props} ref={ref}>
          <ProgressBar
            progress={progress === 1 ? ProgressBarState.Success : progress}
            {...progressBarProps}
            styledCss={cx(
              css`
                --progress-bar-height: 4px;
              `,
              progressBarProps?.styledCss,
            )}
          />
          <PercentBlock>
            <PercentDisplay>{roundedProgressPercent}%</PercentDisplay>
            {/* This will make the progress bar always in the same width */}
            <PercentPlaceHolder>100%</PercentPlaceHolder>
          </PercentBlock>
        </div>
      );
    }),
  ),
)`
  align-items: center;
  display: flex;
  gap: 8px;
  ${overrideCss}
`;
