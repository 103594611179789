import { useCallback, useContext } from 'react';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import type { Feed } from '../types';
import type { Utm } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { AbstractState, AsyncState } from 'react-async';

import { Context } from 'components/LineMessageEditor/models';
import { isAxiosError } from 'lib/axios';
import { openGraph as openGraphParser } from 'lib/parser/open-graph';
import { useMessage } from 'shared/hooks/ui/useMessage';

import { deferHtml } from '../utils';

export const useNewsEditorState = (
  rowIndex: number,
): {
  ogLoader: AsyncState<Document, AbstractState<Document>>;
  setHeadlineImageUrl: (payload: { rowIndex: number; url: string }) => void;
  setHeadlineTitle: (payload: { rowIndex: number; text: string }) => void;
  setUrlData: (payload: {
    rowIndex: number;
    text?: string;
    type?: 'url' | 'utm_campaign' | 'utm_medium' | 'utm_source' | 'utm_content';
    tagList?: string[];
    openExternal?: boolean;
  }) => void;
  setNewsFeeds: (payload: { rowIndex: number; feeds: Feed[] }) => void;
  setNotificationText: (payload: { rowIndex: number; notificationText: string }) => void;
  setRssUrl: (payload: { rowIndex: number; rss: string }) => void;
  setUrlAndUTM: (payload: { rowIndex: number; url: string; utmFields: Utm }) => void;
  setLabelText: (payload: { rowIndex: number; text: string }) => void;
  setLabelBackgroundColor: (payload: { rowIndex: number; backgroundColor: string }) => void;
} => {
  const { t } = useTranslation();

  const { dispatch } = useContext(Context);
  const { message } = useMessage();

  const setHeadlineImageUrl = useCallback(
    (payload: { rowIndex: number; url: string }) => {
      dispatch('setNewsHeadlineImageUrl', payload);
    },
    [dispatch],
  );

  const setHeadlineTitle = useCallback(
    (payload: { rowIndex: number; text: string }) => {
      dispatch('setNewsHeadlineTitle', payload);
    },
    [dispatch],
  );

  const setNotificationText = useCallback(
    (payload: { rowIndex: number; notificationText: string }) => {
      dispatch('setNewsNotificationText', payload);
    },
    [dispatch],
  );

  const ogLoader = useAsync({
    deferFn: deferHtml,
    onResolve: useCallback(
      (document: XMLDocument) => {
        const { title, image } = openGraphParser.parse(document);
        if (title) {
          dispatch('setNewsHeadlineTitle', { rowIndex, text: title });
        }
        if (image) {
          dispatch('setNewsHeadlineImageUrl', { rowIndex, url: image });
        }
      },
      [dispatch, rowIndex],
    ),
    onReject: useCallback(
      (e) => {
        if (isAxiosError(e)) {
          message.error(t('Fail to get page'));
        }
      },
      [message, t],
    ),
  });

  const setUrlData = useCallback(
    (payload: {
      rowIndex: number;
      text?: string;
      type?: 'url' | 'utm_campaign' | 'utm_medium' | 'utm_source' | 'utm_content';
      tagList?: string[];
      openExternal?: boolean;
    }) => {
      dispatch('setNewsUrlData', payload);
    },
    [dispatch],
  );

  const setLabelText = useCallback(
    (payload: { rowIndex: number; text: string }) => {
      dispatch('setNewsLabelText', payload);
    },
    [dispatch],
  );

  const setNewsFeeds = useCallback(
    (payload: { rowIndex: number; feeds: Feed[] }) => {
      dispatch('setNewsFeeds', payload);
    },
    [dispatch],
  );

  const setLabelBackgroundColor = useCallback(
    (payload: { rowIndex: number; backgroundColor: string }) => {
      dispatch('setNewsLabelBackgroundColor', payload);
    },
    [dispatch],
  );

  const setUrlAndUTM = useCallback(
    (payload: { rowIndex: number; url: string; utmFields: Utm }) => {
      dispatch('setNewsUrlAndUTM', payload);
    },
    [dispatch],
  );

  const setRssUrl = useCallback(
    (payload: { rowIndex: number; rss: string }) => {
      dispatch('setRssUrl', payload);
    },
    [dispatch],
  );

  return {
    ogLoader,
    setHeadlineImageUrl,
    setHeadlineTitle,
    setUrlData,
    setNewsFeeds,
    setNotificationText,
    setRssUrl,
    setUrlAndUTM,
    setLabelText,
    setLabelBackgroundColor,
  };
};
