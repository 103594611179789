import qs from 'query-string';

import { pluckSpaces } from 'utils/string/whiteSpaces';

const PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dict = { [key: string]: any };

/**
 * Accepts the fragment portion of a URL and returns an object containing any
 * extracted campaign params as well as the new fragment string with the params
 * removed.
 */
function extractParamsFromFragment(fragment: string) {
  const extractedFragmentParams: Dict = {};
  const bareFragment = fragment.replace(
    /&?(utm_(?:campaign|source|medium|term|content))(?:=([^&]+))?/g,
    (_, name, value) => {
      extractedFragmentParams[name] = value;
      return '';
    },
  );

  return { bareFragment, extractedFragmentParams };
}

/**
 * Accepts the query portion of a URL and returns an object containing any
 * extracted campaign params as well as the new query string with the params
 * removed.
 */
function extractParamsFromQuery(query: string) {
  const queryParams = qs.parse(query);
  const extractedQueryParams: Dict = {};
  const nonQueryParams: Dict = {};

  for (const param of Object.keys(queryParams)) {
    if (PARAMS.includes(param)) {
      extractedQueryParams[param] = queryParams[param];
    } else {
      nonQueryParams[param] = queryParams[param];
    }
  }

  const bareQuery = qs.stringify(nonQueryParams);

  return { bareQuery, extractedQueryParams };
}

/**
 * Accepts a URL and returns an object containing any extracted campaign params
 * (from either the query or fragment parts)
 */
export function extractParamsFromWebsiteUrl(websiteUrl: string): {
  params: {
    [x: string]: string;
  };
} {
  const websiteUrlObj = qs.parseUrl(websiteUrl, { parseFragmentIdentifier: true });

  const { extractedQueryParams } = extractParamsFromQuery(qs.stringify(websiteUrlObj.query));

  const { extractedFragmentParams } = extractParamsFromFragment(
    websiteUrlObj.fragmentIdentifier ?? '',
  );

  return { params: { ...extractedQueryParams, ...extractedFragmentParams } };
}

/**
 * Accepts an object of params and returns a new object only containing keys
 * of known campaign params.
 */
export function sanitizeParams(params: Dict): Dict {
  const sanitizedParams: Dict = {};

  if (params && typeof params == 'object') {
    for (const param of PARAMS) {
      const value = params[param];

      // The param value can only be a string.
      if (typeof value != 'string') continue;

      sanitizedParams[param] = pluckSpaces(value);
    }
  }
  return sanitizedParams;
}
