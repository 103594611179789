import get from 'lodash/get';

import { PRIZE_NAME_KEY } from 'shared/components/Editor/constants';

import type { FlexBoxClasses, FlexBoxStyles } from './types';
import type { FlexBox, FlexBubble, FlexText } from '@line/bot-sdk';
import type { CSSProperties } from 'react';
import type { PrizeModule } from 'shared/components/Editor/LineMessageEditor/types';

export function toArray<T>(maybeArr: T | T[]): T[] {
  return Array.isArray(maybeArr) ? maybeArr : [maybeArr];
}

export const absoluteClassName = (position: FlexBoxClasses['position']): { absolute: boolean } => {
  return { absolute: position === 'absolute' };
};

const flexStyleMap = {
  background: 'background',
  backgroundColor: 'backgroundColor',
  borderColor: 'borderColor',
  width: 'width',
  height: 'height',
  padding: 'paddingAll',
  paddingTop: 'paddingTop',
  paddingBottom: 'paddingBottom',
  paddingLeft: 'paddingStart',
  paddingRight: 'paddingEnd',
  top: 'offsetTop',
  bottom: 'offsetBottom',
  left: 'offsetStart',
  right: 'offsetEnd',
};

export const parseFlexBoxStyle = (boxProps: FlexBoxStyles): CSSProperties => {
  const style: CSSProperties = {};
  Object.entries(flexStyleMap)
    .filter(([, flexKey]) => get(boxProps, flexKey))
    .forEach(([cssKey, flexKey]) => {
      // @ts-expect-error ignore css key type
      style[cssKey] = get(boxProps, flexKey);
    });
  if (boxProps.cornerRadius && boxProps.cornerRadius.indexOf('px') !== -1) {
    style['borderRadius'] = boxProps.cornerRadius;
  }
  if (boxProps.background) {
    style['background'] =
      `linear-gradient(${boxProps.background.angle}, ${boxProps.background.startColor} 0%, ${boxProps.background.endColor} 100%)`;
  }
  return style;
};

const addPrefix = (input: string | undefined, prefix: string) =>
  input ? prefix + input : undefined;

export const sizeClassName = (size: string | undefined): string | undefined =>
  addPrefix(size, 'size_');

/**
 * type:
 * - a: all
 * - t: top
 * - b: bottom
 * - l: left
 * - r: right
 */
export const marginClassName = (
  margin: string | undefined,
  type: 'a' | 't' | 'b' | 'l' | 'r',
): string | undefined => addPrefix(margin, `margin_${type}_`);

export const spacingClassName = (spacing: string | undefined): string | undefined =>
  addPrefix(spacing, 'spacing_');

export const flexClassName = (flex: number | undefined): string | undefined => {
  if (flex !== undefined && flex >= 0 && flex <= 3) {
    return `flex${flex}`;
  }
};

export const flexJustifyContentsClassName = (
  justifyContent: FlexBox['justifyContent'],
): string | undefined => {
  switch (justifyContent) {
    case 'flex-start':
      return addPrefix('start', 'justify_contents_');
    case 'flex-end':
      return addPrefix('end', 'justify_contents_');
    case 'center':
      return addPrefix('center', 'justify_contents_');
    case 'space-between':
      return addPrefix('SB', 'justify_contents_');
    case 'space-around':
      return addPrefix('SA', 'justify_contents_');
    case 'space-evenly':
      return addPrefix('SE', 'justify_contents_');
    default:
      return '';
  }
};

export const flexAlignItemsClassName = (alignItems: FlexBox['alignItems']): string | undefined => {
  switch (alignItems) {
    case 'flex-start':
      return addPrefix('start', 'align_items_');
    case 'flex-end':
      return addPrefix('end', 'align_items_');
    case 'center':
      return addPrefix('center', 'align_items_');
    default:
      return '';
  }
};

export const alignClassName = (align: 'start' | 'end' | 'center' | undefined): string | undefined =>
  addPrefix(align, 'align_');

export const buttonStyleClassName = (
  style: 'link' | 'primary' | 'secondary' | undefined,
): string | undefined => addPrefix(style, 'button_style_');

export const cornerRadiusClassName = (
  cornerRadius: string | 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | undefined,
): string | undefined => {
  if (cornerRadius === undefined) {
    return;
  }
  if (['none', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(cornerRadius)) {
    return addPrefix(cornerRadius, 'corner_radius_');
  }
};

export const replacePrizeRedeemWithKey = (
  content: FlexBubble,
  message: PrizeModule,
): FlexBubble => {
  const prizeTarget = content.body?.contents[0] as FlexBox;
  const targetText = (prizeTarget.contents[0] as FlexText).text;

  if (targetText === PRIZE_NAME_KEY) {
    const value = message.format.prizeName;
    const rest = content.body?.contents.slice(1) ?? [];

    return {
      ...content,
      body: {
        ...content.body,
        contents: [
          {
            ...prizeTarget,
            contents: [{ ...prizeTarget.contents[0], text: value }],
          },
          ...rest,
        ],
      },
    } as FlexBubble;
  }
  return content;
};
