import type { ZendeskWidget } from 'features/customerSupport/zendesk/types';

import { logError } from 'lib/logger';

export const show = (): void => {
  try {
    (window.zE as ZendeskWidget)?.('messenger', 'show');
  } catch (error) {
    logError(error);
  }
};
