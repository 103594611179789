import { style, system } from 'styled-system';

import type { BoxProps } from 'components/layoutUtils';
import type { ComponentProps, DOMAttributes } from 'react';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { superBoxStyle } from 'components/layoutUtils';
import { styled } from 'shared/utils/styled';
import { overrideCss } from 'shared/utils/styled/override';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

const overflow = style({
  /* stylelint-disable-next-line property-no-unknown -- Allow overflow */
  prop: 'overflow',
});

export interface TextProps
  extends BoxProps,
    OverrideComponentCssProps,
    DOMAttributes<HTMLSpanElement> {
  sentenceCase?: boolean;
}

/** @deprecated - Styled System components are deprecated; use `shared/components/Text`  and style prop instead */
export const Text = styled.span.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps('sentenceCase', 'styledCss'),
})<TextProps>`
  ${system({
    whiteSpace: true,
    textDecoration: true,
    textTransform: true,
    cursor: true,
  })}
  ${overflow};
  ${superBoxStyle};
  ${overrideCss}
  ${({ sentenceCase }) =>
    sentenceCase &&
    `
    display: inline-block;
    :first-letter {
      text-transform: capitalize;
    }
  `}
`;

Text.defaultProps = {
  fontSize: 14,
  lineHeight: 1.4,
};

/**
 * Note: the `as` prop worked as expected in SC 5 but throws an error in SC 6
 * We will refactor typography soon so the type workaround (using string) is temporary
 * TODO: refactor the small number of uses of this prop!
 * @deprecated - use `shared/components/Typography` instead
 */
export const Body = styled(function Body({
  as = 'div',
  ...props
}: ComponentProps<typeof Text> & { as?: string }) {
  return <Text as={as} {...props} />;
})`
  color: ${theme.colors.neutral010};
`;

/** @deprecated - use `shared/components/Typography` instead */
export const BodyBold = styled(Body)`
  font-weight: bold;
`;

// TODO(eric): rename and rename the zeplin as well
/** @deprecated - use `shared/components/Typography` instead */
export const TextStyle = styled(Body)`
  /* Empty by design */
`;

TextStyle.defaultProps = {
  fontSize: 16,
};

/** @deprecated - use `shared/components/Typography` instead */
export const SubText = styled(Text)`
  /* Empty by design */
`;

SubText.defaultProps = {
  color: theme.colors.neutral008,
};

/** @deprecated - use `shared/components/Typography` instead */
export const SubTitle = styled(Text)`
  /* Empty by design */
`;

SubTitle.defaultProps = {
  fontSize: 24,
  fontWeight: 500,
  lineHeight: '28px',
  color: theme.colors.neutral010,
};

/**
 * Limit the width of a `span` element containing text to a specified value
 * Note: this only applies to block-level elements e.g. display property `block` or `inline-block`
 * But this also works if it's used in a flexbox context
 * @deprecated - use `shared/components/Typography` instead
 */
export const TextWidthLimited = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
