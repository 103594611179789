import { define } from '@chatbotgang/etude/util/define';

import type { BaseFeatureFlagConfigs } from 'features/featureFlag/baseTypes';
import type { Types } from 'features/featureFlag/createFeatureFlagApi';

const defineConfig = define<BaseFeatureFlagConfigs>();

/**
 * Define a test account for a feature flag.
 *
 * @example
 *
 * ```ts
 * const featureFlags = defineConfig({ ... });
 * const testAccounts = defineTestAccount(featureFlags)({
 *   email: "demo@cresclab.com",
 *   feature: "feature-flag-key",
 * });
 *
 * export { featureFlags, testAccounts };
 * ```
 */
const defineTestAccount = <C extends BaseFeatureFlagConfigs>(_config: C) => {
  type ToggleFeatureFlagKey = Types<C>['ToggleKey'] | Array<Types<C>['ToggleKey']>;
  type Config = {
    email: string | Array<string> | ((email: string) => boolean);
    feature: ToggleFeatureFlagKey | Array<ToggleFeatureFlagKey>;
  };
  return function define(config: Config | Array<Config>) {
    return config;
  };
};

export { defineConfig, defineTestAccount };
