import { memo } from 'react';
import { SketchPicker } from 'react-color';

import { Box } from 'components/layoutUtils';
import { Popover } from 'shared/components/Popover';
import { theme } from 'theme';

interface ColorPickerProps {
  /** hex color string */
  color: string;
  onColorChange: (color: string) => void;
}

export const ColorPicker = memo(function ColorPicker({ color, onColorChange }: ColorPickerProps) {
  return (
    <Popover
      content={
        <Box m="-12px -16px">
          <SketchPicker
            onChange={(draftColor) => {
              onColorChange(draftColor.hex);
            }}
            color={color}
          />
        </Box>
      }
      trigger="click"
    >
      <Box
        width="32px"
        height="20px"
        border={`1px solid ${theme.colors.neutral003}`}
        borderRadius="4px"
        cursor="pointer"
        backgroundColor={color}
      />
    </Popover>
  );
});
