import { createContext } from 'react';

import { RICH_EDITOR_ID_NOT_FOUND } from 'components/LineMessageEditor/constants';

import type { TextDataType } from './templateDataAndTypes/richtext';
import type {
  EditorDataType,
  ImageData,
  ImageMapDataType,
  State,
  VideoDataType,
} from './templateDataAndTypes/types';

import { findFirstRichEditorId } from 'components/LineMessageEditor/utils/richEditorId';
import { createStore } from 'lib/stamen';

import { state } from './templateDataAndTypes/types';
import { cardStore } from './card';
import { carrouselStore } from './carrousel';
import { imageStore } from './image';
import { imageCarouselStore } from './imageCarousel';
import { imageMapStore } from './imageMap';
import { imageMapCarouselStore } from './imageMapCarousel';
import { newsStore } from './news';
import { prizeRedeemStore } from './prizeRedeem';
import { richTextStore } from './richText';
import { videoStore } from './video';

export const store = {
  state,
  reducers: {
    ...cardStore.reducers,
    ...richTextStore.reducers,
    ...imageStore.reducers,
    ...imageMapStore.reducers,
    ...imageMapCarouselStore.reducers,
    ...carrouselStore.reducers,
    ...videoStore.reducers,
    ...imageCarouselStore.reducers,
    ...newsStore.reducers,
    ...prizeRedeemStore.reducer,
    setEditingRow(
      preState: State,
      payload: {
        rowIndex: number;
        richEditorId?: number;
      },
    ): void {
      preState.editorEditingRow = payload.rowIndex;
      preState.richEditorId = payload.richEditorId ?? RICH_EDITOR_ID_NOT_FOUND;
    },
    setIsDragMode(preState: State, payload: { isDragMode: boolean }): void {
      preState.isDragMode = payload.isDragMode;
    },
    addEditorData(
      preState: State,
      payload: { data: TextDataType | ImageData | ImageMapDataType | VideoDataType },
    ): void {
      preState.editorData = [...preState.editorData, payload.data];
    },
    sortEditorData(preState: State, payload: { oldIndex: number; newIndex: number }): void {
      [preState.editorData[payload.oldIndex], preState.editorData[payload.newIndex]] = [
        preState.editorData[payload.newIndex],
        preState.editorData[payload.oldIndex],
      ];
    },
    setEditorData(preState: State, payload: { editorData: EditorDataType[]; id?: number }): void {
      preState.editorData = payload.editorData;
      if (payload.id) preState.richEditorId = payload.id;
      if (payload.editorData.length !== 0) preState.editorEditingRow = 0;
    },
    deleteEditorData(preState: State, payload: { rowIndex: number }): void {
      preState.editorData = [
        ...preState.editorData.filter((rowData, index) => {
          return index !== payload.rowIndex;
        }),
      ];
      preState.richEditorId = findFirstRichEditorId(preState.editorData);
    },
  },
  effects: {
    ...cardStore.effects,
    ...richTextStore.effects,
    ...imageStore.effects,
    ...imageMapStore.effects,
    ...imageMapCarouselStore.effects,
    ...carrouselStore.effects,
    ...videoStore.effects,
    ...imageCarouselStore.effects,
    ...newsStore.effects,
    ...prizeRedeemStore.effects,
  },
};
export const { useStore, dispatch } = createStore(store);

export const Context = createContext({ dispatch, store: state });
