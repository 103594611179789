/**
 * Compose the key with '<$var:' and '>'.
 */
export const composeKey = <T extends string>(key: T): `<$var:${T}>` => {
  return `<$var:${key}>`;
};

/**
 * Remove the '<$var:' and '>' from the key.
 */
export const transformKey = (key: string) => {
  return key.replace('<$var:', '').replace('>', '');
};
